import React, { useImperativeHandle } from 'react';
import DataGrid, { FilterRow, HeaderFilter, Toolbar, Item, ColumnChooser } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Button } from 'devextreme-react/button';
import PropTypes from 'prop-types';
import moment from 'moment';
import './index.scss';

const CommonDataGrid = React.forwardRef((props, ref) => {
  const {
    className,
    dataSource,
    children,
    isHeader,
    headerBefore,
    headerAfter,
    gridRef,
    isExcel,
    excelOptions,
    isNotFilterRow,
    isColumnSelector,
    isFilterSelection,
    ...options
  } = props;
  // const [headerText, setHeaderText] = useState([]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const onExporting = e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelOptions.sheetName);
    exportDataGrid({
      component: gridRef.current.instance,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (
          excelOptions &&
          excelOptions.customizeCell &&
          excelOptions.customizeCell.date &&
          excelOptions.customizeCell.date.length > 0
        ) {
          if (gridCell.rowType === 'data') {
            excelOptions.customizeCell.date.forEach(column => {
              if (gridCell.column.dataField === column) {
                excelCell.value = gridCell.value === null ? '' : moment(gridCell.value).format('YYYY-MM-DD HH:mm:ss');
              }
            });
          }
        }
      },
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), excelOptions.fileName);
      });
    });
  };

  const onOptionChanged = e => {
    if (isFilterSelection && e.name === 'columns' && e.value) {
      if (typeof e.value === 'object') {
        (async () => {
          await gridRef.current.instance.clearSelection();
        })();
      }
    }
  };

  return (
    <div className="datagrid-selector">
      <DataGrid
        ref={gridRef}
        className={className}
        dataSource={dataSource}
        {...options}
        allowColumnResizing={true}
        onOptionChanged={onOptionChanged}
        allowColumnReordering={true}
      >
        {isHeader && (
          <Toolbar>
            {headerBefore.map((content, ind) => (
              <Item location={'before'} key={ind}>
                {content}
              </Item>
            ))}
            {headerAfter.map((content, ind) => (
              <Item location={'after'} key={ind}>
                <div className="datagrid-toolbar">{content}</div>
              </Item>
            ))}
            {isColumnSelector && <Item location={'after'} name="columnChooserButton" />}
            {isExcel && <Item location={'after'}>{<Button icon="exportxlsx" onClick={onExporting} />}</Item>}
          </Toolbar>
        )}
        {!isNotFilterRow && <FilterRow visible={true} />}
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} mode="select" title={'열 선택기'} height={500} allowSearch={true}></ColumnChooser>
        {children}
      </DataGrid>
    </div>
  );
});

CommonDataGrid.defaultProps = {
  isHeader: false,
  headerBefore: [],
  headerAfter: [],
  isExcel: true,
  excelOptions: {
    buttonName: null,
    sheetName: 'Sheet',
    fileName: 'sample.xlsx',
    customizeCell: { date: [] },
  },
  isColumnSelector: true,
};

CommonDataGrid.propTypes = {
  isHeader: PropTypes.bool,
  headerBefore: PropTypes.oneOfType([PropTypes.array]),
  headerAfter: PropTypes.oneOfType([PropTypes.array]),
  isExcel: PropTypes.bool,
  isColumnSelector: PropTypes.bool,
};

export default React.memo(CommonDataGrid);
