import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import CancelConfirmTicket from 'pages/sales-mng/cancel-sales-confirm/component/cancel-confirm-ticket';
import { parking } from 'api';
import CancelEditForm from './popup/CancelEditForm';
import './cancelHistory.scss';

const CancelHistory = props => {
  const { cancelHistoryRef, setLoadPanelVisible } = props;
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const [refundReason, setRefundReason] = useState([]);
  const [refundPaymentType, setRefundPaymentType] = useState([]);

  // 요청 목록 수정팝업
  const [editPopup, setEditPopup] = useState({ save: false, visible: false });
  const [editRowdata, setEditRowdata] = useState([]);

  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SalesYear: '2022',
  });

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const onSearch = () => {
    setSearchData({
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      SalesYear: '2022',
    });
  };

  const onClose = () => {
    if (cancelHistoryRef.current.instance) cancelHistoryRef.current.instance.clearSelection();
    setEditRowdata({ ...editPopup, visible: false });
  };

  useEffect(() => {
    api();
  }, []);
  const api = useCallback(async () => {
    const RefundPaymentType = [];
    const RefundReason = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['RefundPaymentType', 'RefundReason'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'RefundPaymentType') {
          RefundPaymentType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'RefundReason') {
          RefundReason.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
      setRefundPaymentType(RefundPaymentType);
      setRefundReason(RefundReason);
    }
  }, []);

  //요청목록 리프레쉬
  const refreshCancelConfirmTicket = () => {
    if (cancelHistoryRef.current.instance) cancelHistoryRef.current.instance.refresh();
  };
  return (
    <div>
      <CancelConfirmTicket
        headerBefore={[
          <div className="label">접수일 :</div>,
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="FromDate"
            onValueChanged={handleFromDate}
            value={dateValue.fromDate}
          />,
          <span> ~ </span>,
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="ToDate"
            min={dateValue.fromDate}
            value={dateValue.toDate}
            onValueChanged={handleToDate}
            dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
          />,
        ]}
        headerAfter={[<Button icon="search" text="조회" width={120} onClick={onSearch} />]}
        height={950}
        cancelTicketRef={cancelHistoryRef}
        setPopup={setEditPopup}
        setEditRowdata={setEditRowdata}
        searchData={searchData}
        isHistory
        isColor
        isInputSalesCancel
      />
      <Popup
        onHiding={onClose}
        visible={editPopup.visible}
        showCloseButton={true}
        showTitle={false}
        width="75%"
        height={660}
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <CancelEditForm
              setPopup={setEditPopup}
              editRowdata={editRowdata}
              refundReason={refundReason}
              refundPaymentType={refundPaymentType}
              refresh={refreshCancelConfirmTicket}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
};

export default CancelHistory;
