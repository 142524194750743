export const columns = [
  '카드사',	'결제일','카드번호','승인번호','금액','수수료',
];

export const sampleData = [
  {
    카드사: '외환',
    결제일: '2023-02-28',
    카드번호: '5531-84**-****-6723',
    승인번호: '21869651',
    금액: 5000,
    수수료: 114,
  },
  {
    카드사: '삼성',
    결제일: '2023-02-25 17:25:23',
    카드번호: '379183******119',
    승인번호: '34180317',
    금액: 5000,
    수수료: 102,
  },
  {
    카드사: '신한',
    결제일: '2023-02-12',
    카드번호: '6243 - **** - **** - 723*',
    승인번호: '03943988',
    금액: 5000,
    수수료: 102,
  },
  {
    카드사: '페이앱',
    결제일: '2023-02-28 19:25:55',
    카드번호: '아브뉴프랑판교 / 8217',
    승인번호: '30075587',
    금액: 5000,
    수수료: 0,
  },
];
