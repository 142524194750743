import React, { useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { COMPANYCODE } from 'constants/codes';
import { sampleData, columns } from './sample';
import { ticket, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';

const SmartroPGPaymentUploadPage = () => {
  const dataGridRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();
      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = () => {
    if (dataSource.length === 0) {
      alert('엑셀 파일을 업로드 해주세요.');
    } else {
      const result = confirm('<i>등록하시겠습니까?</i>', '등록');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          ticket
            .uploadSmartroPGExcel({
              ItemListJson: JSON.stringify(dataSource),
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '처리되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const handleImport = ExcelOrganization => {
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <h5>
            ★&nbsp; 스마트로 PG 엑셀 파일 업로드 순서 : 거래내역 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀
            파일 업로드 &nbsp; ▷ &nbsp; 등록
          </h5>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <ExcelImport
            fieldColumn={[
              'A',
              'B',
              'C',
              'D',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K',
              'L',
              'M',
              'N',
              'O',
              'P',
              'Q',
              'R',
              'S',
              'T',
              'U',
              'V',
              'W',
              'X',
              'Y',
              'Z',
            ]}
            handleImport={e => handleImport(e.map(importMapFunc))}
            // fieldColumnType={[
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            // ]}
          />
        </div>
        <CommonDataGrid
          gridRef={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          height={700}
          isHeader={true}
          isExcel={true}
          excelOptions={{
            sheetName: 'sheet',
            fileName: '스마트로PG업로드.xlsx',
          }}
          headerAfter={[
            <Button
              icon="check"
              text="등록"
              width={80}
              type="normal"
              stylingMode="contained"
              onClick={() => saveExcel()}
            />,
            <ExcelExport
              excelFields={headerText}
              excelData={sampleData}
              sheetName="Sheet"
              fileName="smartroPG_sample.xls"
              buttonName="샘플파일 다운로드"
            />,
          ]}
          allowColumnReordering={true}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <LoadPanel enabled showPane={showPane} />
          <HeaderFilter visible={true} />
          <Column dataField={'가맹점상호'} caption={'가맹점상호'} hidingPriority={4} />
          <Column dataField={'MID'} caption={'MID*'} hidingPriority={4} />
          <Column dataField={'MID명'} caption={'MID명*'} hidingPriority={4} />
          <Column dataField={'거래상태'} caption={'거래상태*'} hidingPriority={4} />
          <Column dataField={'거래 일시'} caption={'거래 일시'} hidingPriority={4} />
          <Column dataField={'거래 시간'} caption={'거래 시간'} hidingPriority={4} />
          <Column dataField={'금액'} caption={'금액'} hidingPriority={4} />
          <Column dataField={'부가세'} caption={'부가세'} hidingPriority={4} />
          <Column dataField={'합계'} caption={'합계*'} hidingPriority={4} />

          <Column dataField={'포인트사용'} caption={'포인트사용'} hidingPriority={4} />
          <Column dataField={'카드명'} caption={'카드명*'} hidingPriority={4} />
          <Column dataField={'카드번호'} caption={'카드번호*'} hidingPriority={4} />
          <Column dataField={'매입사명'} caption={'매입사명*'} hidingPriority={4} />
          <Column dataField={'신용/체크'} caption={'신용/체크*'} hidingPriority={4} />
          <Column dataField={'가맹점번호'} caption={'가맹점번호'} hidingPriority={4} />
          <Column dataField={'승인번호'} caption={'승인번호'} hidingPriority={4} />
          <Column dataField={'할부'} caption={'할부'} hidingPriority={4} />
          <Column dataField={'구매자명'} caption={'구매자명'} hidingPriority={4} />

          <Column dataField={'상품명'} caption={'상품명'} hidingPriority={4} />
          <Column dataField={'주문번호'} caption={'주문번호*'} hidingPriority={4} />
          <Column dataField={'인증'} caption={'인증'} hidingPriority={4} />
          <Column dataField={'TID'} caption={'TID*'} hidingPriority={4} />
          <Column dataField={'승인일시'} caption={'승인일시*'} hidingPriority={4} />
          <Column dataField={'승인시간'} caption={'승인시간*'} hidingPriority={4} />
          <Column dataField={'취소일시'} caption={'취소일시*'} hidingPriority={4} />
          <Column dataField={'취소시간'} caption={'취소시간*'} hidingPriority={4} />

          <Summary>
            <TotalItem column="MID명" summaryType="count" displayFormat="총 {0}건" />
            <TotalItem column="합계" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          </Summary>
        </CommonDataGrid>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default SmartroPGPaymentUploadPage;
