import React, { useEffect, useState, useRef } from 'react';
import { Column, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { ticket } from '../../../api';
import { COMPANYCODE } from 'constants/codes';
import CommonDataGrid from 'common/default-data-grid';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import './season-ticket-history-mng.scss';

const SeasonTicketHistoryMng = () => {
  const [autoText, setAutoText] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [autoDataSource, setAutoDataSource] = useState([]);
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchParam, setSearchParam] = useState({
    CostCenterCode: '',
    FromDate: '',
    Todate: '',
  });
  const gridRef = useRef({});
  const autoCompleteRef = useRef({});

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        setAutoDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await ticket.getSeasonTicketIssueHistoryHistory(searchParam);
      if (result.isOk) {
        setDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, [searchParam]);

  const onEnterKey = e => {
    setSearchParam({
      ...searchParam,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
    });
  };

  const onSearch = e => {
    setSearchParam({
      ...searchParam,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
    });
  };

  const onItemClick = e => {
    const { CostCenterCode } = e.itemData;
    const { FromDate, ToDate } = dateValue;
    setSearchParam({ CostCenterCode, FromDate, ToDate });
    autoCompleteRef.current.instance.blur();
  };
  const onValueChanged = e => {
    setAutoText(e.value);
  };
  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };
  return (
    <div>
      <h2 className={'content-block'}>정기권 발행 이력 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={true}
            height={650}
            stylingMode="outlined"
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '정기권 발행이력.xlsx',
            }}
            isHeader={true}
            headerBefore={[
              <div className="label">등록일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span>~</span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <Autocomplete
                ref={autoCompleteRef}
                labelMode="static"
                stylingMode="outlined"
                dataSource={autoDataSource}
                value={autoText}
                valueExpr="CostCenterName"
                searchExpr="CostCenterName"
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
                showClearButton={true}
                width={200}
                placeholder="P-코드/사업소명"
                onItemClick={onItemClick}
                mode="search"
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Paging defaultPageSize={15} />
            <HeaderFilter visible={true} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column
              dataField={'LastUpdateTime'}
              caption={'처리일시'}
              customizeText={customizeText}
              hidingPriority={18}
            />
            <Column dataField={'ActionTypeName'} caption={'처리유형'} hidingPriority={18} />
            <Column dataField={'MemberID'} caption={'회원ID'} hidingPriority={18} />
            <Column dataField={'VehicleRegistration'} caption={'차량번호'} hidingPriority={17} />
            <Column dataField={'SalesDate'} caption={'판매일'} hidingPriority={16} />
            <Column dataField={'FromDate'} caption={'시작일'} hidingPriority={15} />
            <Column dataField={'ToDate'} caption={'종료일'} hidingPriority={14} />
            <Column dataField={'RemainDay'} caption={'남은일수'} hidingPriority={13} />
            <Column dataField={'SalesPrice'} caption={'판매금액'} hidingPriority={12} />
            <Column dataField={'MemberTypeName'} caption={'정기권 유형'} hidingPriority={11} />
            <Column dataField={'MemberName'} caption={'회원명'} hidingPriority={10} />
            <Column dataField={'GroupName'} caption={'그룹명'} hidingPriority={9} />
            {/* <Column dataField={'PaymentStatusName'} caption={'결제상태'} hidingPriority={8} /> */}
            <Column dataField={'PaymentTypeName'} caption={'결제유형'} hidingPriority={7} />
            <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
            <Column dataField={'RegisterDate'} caption={'등록일시'} customizeText={customizeText} hidingPriority={8} />
            <Column dataField={'RegisterUser'} caption={'등록자'} hidingPriority={7} />
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default SeasonTicketHistoryMng;
