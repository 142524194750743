import React, { useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import ExcelExport from 'common/excel-export';
import { Button } from 'devextreme-react/button';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import CsstiAddTaxInvoice from './component/cssti-add-tax-invoice';
import hideToasts from 'devextreme/ui/toast/hide_toasts';
import { btob, error } from 'api';
import { csstiUrls } from 'api/btob';
import ExcelImportInDataGrid from './excel-import-in-data-grid';
import { notifyDelayS, notifyS } from 'common/popup/sales-common-util';
import './pcm-sales-tax-invoice-issuance.scss';

/*
  B2B 매출 세금계산서 발행 - 카 쉐어링 매출 세금계산서 발행
*/
const PCMSalesTaxInvoiceIssuance = () => {
  const dataGridRef = useRef({});

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY/MM/DD'),
  });
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);

  // 팝업
  const [isOpen, setIsOpen] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [uploadKeyIDListStr, setUploadKeyIDListStr] = useState('');

  // 컬럼 정의
  const columns = ['CostCenterCode', 'Remark', 'SalesDate', 'Quantity', 'SalesPrice'];

  useEffect(() => {
    result();
  }, []);

  const sampleData = [
    {
      CostCenterCode: 'C0145',
      Remark: '쏘카 테스트지점',
      SalesDate: '2023-01-30',
      Quantity: 15,
      SalesPrice: '2600000',
    },
    {
      CostCenterCode: 'K3334',
      Remark: '쏘카 지웰홈스교대점',
      SalesDate: '2023-01-31',
      Quantity: 4,
      SalesPrice: 1000000,
    },
  ];

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();

      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const refresh = () => {
    setSelectData([]);
    setDataSource([]);
    setConfirmDisabled(true);
    setUploadKeyIDListStr('');
    // hideToasts();
  };

  const handleSalesMonth = e => {
    setSearchData({
      ...searchData,
      SalesMonth: e.value,
    });
  };

  const importMapFunc = a => {
    const returnValue = {};
    console.log(columns);
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const handleImport = async excelDataSource => {
    console.log(excelDataSource);
    setLoadPanelVisible(true);
    setDataSource(excelDataSource);
    setSelectData(excelDataSource);
    setConfirmDisabled(true);

    const params = {
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      // SalesMonth: searchData.SalesMonth,
      ItemListJson: JSON.stringify(excelDataSource),
    };
    const res = await btob.doApi(csstiUrls.check, params);

    console.log('res...');
    console.log(res);
    setLoadPanelVisible(false);
    if (!res.isOk) {
      console.log('check error...');
      notifyDelayS(error.errorMsgCheck(res.error.detail));
      setConfirmDisabled(true);
      return;
    }
    setConfirmDisabled(false);
    console.log('check success...');
    hideToasts();
  };

  const confirmUplodedData = async () => {
    hideToasts();
    setLoadPanelVisible(true);

    const params = {
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      // SalesMonth: searchData.SalesMonth,
      ItemListJson: JSON.stringify(dataSource),
      SalesCategory: 'PCM',
    };
    const res = await btob.doApi(csstiUrls.upload, params);
    setLoadPanelVisible(false);
    if (!res.isOk) {
      console.log('check error...');
      notifyDelayS(error.errorMsgCheck(res.error.detail));
      return;
    }

    const keyIDListStr = res.data[0][0].KeyIDList;
    console.log(keyIDListStr);
    setUploadKeyIDListStr(keyIDListStr);

    setIsOpen(true);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onHiding = () => {};

  return (
    <div className="pcm-sales">
      <h2 className={'content-block'}>카드무료주차 세금계산서 발행</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings pcm-sales'} style={{ paddingTop: '10px' }}>
          <h5 style={{ marginBottom: '1em' }}>
            {' '}
            ★엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀 파일 업로드
            &nbsp; ▷ &nbsp; 확정
          </h5>

          <CommonDataGrid
            gridRef={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={500}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '카셰어링 매출 세금계산서.xlsx',
            }}
            headerBefore={[
              <div style={{ width: '100px', paddingBottom: '3px' }}>매출 등록월</div>,
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                // dataField="date"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
              />,
              <ExcelImportInDataGrid
                fieldColumn={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']}
                handleImport={e => handleImport(e.map(importMapFunc))}
                fieldColumnType={[
                  'string',
                  'string',
                  'date',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                ]}
              />,
            ]}
            headerAfter={[
              <Button
                icon="check"
                text="확정"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => confirmUplodedData()}
                disabled={confirmDisabled}
              />,
              <ExcelExport
                excelFields={headerText}
                sheetName="Sheet"
                fileName="sample.xlsx"
                excelData={sampleData}
                buttonName="샘플파일 다운로드"
              />,
            ]}
            // headerAfter={[<Button icon="plus" text="매출 수기 등록" onClick={onOpen} />]}
            allowColumnReordering={true}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <LoadPanel enabled showPane={showPane} />
            <HeaderFilter visible={true} />
            <Column dataField={'CostCenterCode'} caption={'영업점코드'} hidingPriority={4} />
            <Column dataField={'Remark'} caption={'적요'} hidingPriority={4} />
            <Column dataField={'SalesDate'} caption={'매출 일자'} hidingPriority={4} />
            <Column dataField={'Quantity'} caption={'매출 수량'} hidingPriority={4} />
            <Column dataField={'SalesPrice'} caption={'매출액'} hidingPriority={3} />
            <Summary>
              <TotalItem column="Remark" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="70%" height={900}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <CsstiAddTaxInvoice
              setIsOpen={setIsOpen}
              selectData={selectData}
              uploadKeyIDListStr={uploadKeyIDListStr}
              setLoadPanelVisible={setLoadPanelVisible}
              salesMonth={searchData.SalesMonth}
              refresh={refresh}
            />
          </div>
          카드무료주차 세금계산서 관리
        </ScrollView>
      </Popup>{' '}
    </div>
  );
};

export default PCMSalesTaxInvoiceIssuance;
