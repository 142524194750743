import React, { useRef, useMemo, useState, useEffect } from 'react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { TagBox } from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { common, error } from 'api';

import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import axios from 'axios';

import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import RadioGroup from 'devextreme-react/radio-group';
import moment from 'moment';

const SalesConfirmDataView = () => {
  const gridRef = useRef({});
  const radioRef = useRef({});
  //검색
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  // 조회할 사업소 목록 array
  const [costCenterType, setCostCenterType] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState('SalesDate');
  const [selectedTagbox, setSelectedTagbox] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const radioData = [
    { id: 'SalesDate', text: '매출일 기준' },
    { id: 'PaymentDate', text: '입금일 기준(전체)' },
    { id: 'PaymentInvoice', text: '입금일 기준(세금계산서)' },
    { id: 'PaymentCMS', text: '입금일 기준(CMS)' },
  ];
  useEffect(() => {
    (async () => {
      const result = await common.costcenterDataAll();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);

  const dataSource = useMemo(() => {
    const params = { ...dateValue, InquiryType: selectedRadio, CostCenterCode: selectedTagbox.join(',') };
    console.log(params);
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/report/getSAPCofirmationData`, params);
          return result.data[0];
        } catch (e) {
          notify(
            {
              message: error.errorMsgCheck(e.response.data.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchButton]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onSearch = () => {
    setSearchButton(!searchButton);
  };

  const onRadioValueChanged = e => {
    setSelectedRadio(e.value);
  };

  const onTagboxChanged = e => {
    const result = [...selectedTagbox];
    const add = e.addedItems.map(a => a.CostCenterCode);
    const remove = e.removedItems.map(a => a.CostCenterCode);
    if (add.length > 0) {
      result.push(...add);
      setSelectedTagbox(result);
    }
    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setSelectedTagbox(newArr);
    }
  };

  return (
    <div>
      <h2 className={'content-block detail-title'}>매출/입금 전표 데이터 레포트</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <RadioGroup
                ref={radioRef}
                items={radioData}
                defaultValue={selectedRadio}
                displayExpr="text"
                valueExpr="id"
                layout="horizontal"
                onValueChanged={onRadioValueChanged}
              />
            </ToolbarItem>
          </Toolbar>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '매출/입금 전표 데이터 레포트.xlsx',
            }}
            headerBefore={[
              <div className="label">일자 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TagBox
                dataSource={costCenterType}
                showSelectionControls={false}
                columnAutoWidth={true}
                allowColumnResizing={true}
                maxDisplayedTags={2}
                displayExpr="CostCenterName"
                valueExpr="CostCenterCode"
                placeholder="조회할 사업소를 모두 선택해주세요"
                stylingMode="outlined"
                onSelectionChanged={onTagboxChanged}
                searchEnabled={true}
                width={330}
                selectAllText={'전체'}
                multiline={false}
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default SalesConfirmDataView;
