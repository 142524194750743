import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';

export default function UnauthenticatedContent() {
  const isUnderMaintenance = false; // 시스템 점검 상태 여부에 따라 bool 수정해주세요
  const allowedEmailUnderMaintenance = ['sjpark5@humaxit.com', 'sywoo@humaxit.com'];
  return (
    <Switch>
      <Route exact path="/login">
        <SingleCard
          title={<span style={{ fontWeight: 'bold' }}>{isUnderMaintenance ? '시스템 점검 안내' : '로그인'}</span>}
        >
          {isUnderMaintenance ? (
            <>
              <p>
                Hi-DMS 홈페이지 시스템 점검으로 인해 <br />
                사이트 접속이 일시적으로 중단됩니다. <br />
                이용에 불편을 드려 죄송합니다.
              </p>
              <b style={{ fontSize: '14px' }}>점검일시: 2024.01.27(토) 15:00 ~ 18:00</b>
              <br />
              <p>
                점검이 완료되면 페이지를 다시 열거나 <br />
                F5키를 사용해 새로고침 해주세요.
              </p>
            </>
          ) : (
            <>
              <div>
                <span style={{ color: 'red' }}>[알림]</span> 사용자 등록 후 로그인 가능합니다.
                <br />
                신규 등록은 관리자에게 문의해 주세요.
                <br />
              </div>
              <div>
                <span style={{ color: 'red' }}>[알림]</span> 로그인은 현재 브라우저에서 <br />
                12시간 유지 됩니다. <br />
              </div>{' '}
              <br />
            </>
          )}
          <LoginForm isUnderMaintenance={isUnderMaintenance} admin={allowedEmailUnderMaintenance} />
        </SingleCard>
      </Route>
      <Route exact path="/create-account">
        <SingleCard title="Sign Up">
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path="/reset-password">
        <SingleCard
          title="Reset Password"
          description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path="/change-password/:recoveryCode">
        <SingleCard title="Change Password">
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}
