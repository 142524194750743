import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
// import { system } from 'api';
// import { confirm } from 'devextreme/ui/dialog';
// import notify from 'devextreme/ui/notify';

import CommonDataGrid from 'common/default-data-grid';
// import CostcenterMaster from 'common/popup/costcenter-master';
import { master } from 'api';
import UserList from './userList/userList';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';

const SettingUser = () => {
  const gridRef = useRef({});
  const userListRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [SearchText, setSearchText] = useState('');
  const [text, setText] = useState('');
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  useEffect(() => {
    (async () => {
      const result = await master.searchCostCenterMaster(SearchText);
      if (result.isOk) {
        const data = [...result.data];
        setDataSource(data);
      }
    })();
  }, [SearchText]);

  const onEnterKey = () => {
    setSearchText({ SearchText: text ? text.trim() : '' });
  };

  const onValueChanged = e => {
    setText(e.value);
  };

  const onSave = useCallback(async () => {}, []);

  const onSetting = e => {
    const { CostCenterCode } = e.data;
    return <Button icon="edit" onClick={() => userListRef.current.open(CostCenterCode)} />;
  };
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };
  return (
    <div>
      <h2 className={'content-block'}>사업소별 사용자 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            allowColumnResizing={true}
            hoverStateEnabled={true}
            height={700}
            isHeader={true}
            isExcel={true}
            headerBefore={[
              <TextBox
                mode="search"
                labelMode="static"
                width={200}
                stylingMode="outlined"
                placeholder="관리 ID/ 사업소 명"
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
              />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'HiParkingID'} caption={'관리 ID'} />
            <Column dataField={'CostCenterCode'} caption={'코스트센터코드'} visible={false} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} />
            <Column dataField={'ManagerName'} caption={'담당자'} />
            <Column
              dataField={'ManagerName2'}
              caption={'설정'}
              width={'5%'}
              cellRender={onSetting}
              allowExporting={false}
            />
          </CommonDataGrid>
        </div>
      </div>
      <UserList ref={userListRef} setLoadPanelVisible={setLoadPanelVisible} />
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default SettingUser;
