import { React, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import { Column, Pager, Paging, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { TextBox } from 'devextreme-react/text-box';
import { aggregation } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './van-sale-aggregation.scss';
import { costcenterData } from 'api/common';

const ViewVANSalesAggregationPage = () => {
  const gridRef = useRef({});
  
  const [searchCostCenter, setSearchCostCenter] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });
  const [searchParams, setSearchParams] = useState(null);

  const dataSource = useMemo(() => {
    if (searchParams) {
      const customDataSource = new CustomStore({
        key: 'KeyID',
        loadMode: 'raw',
        load: async () => {
          const result = await aggregation.getVANAggregationDetailData(searchParams);
          return result.data;
        },
      });

      return customDataSource;
    }
  }, [searchParams]);

  const onSearch = () => {
    setSearchParams({
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      CostCenter : searchCostCenter,
      ApprovalNo : searchApprovalNo,
    });
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const onChangeSearchCostCenter = e => {
    setSearchCostCenter(e.value);
  };
  const onChangeSearchApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };
  return (
    <div>
      <h2 className={'content-block'}>PG&VAN 매출 집계 데이터 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <Item location={'before'}>
              <div className="label">결제일 :</div>
            </Item>
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />
            </Item>
            <Item location={'before'} text="~" />
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </Item>
            <Item location={'before'}>
              <TextBox
                labelMode="static"
                stylingMode="outlined"
                placeholder="사업소코드"
                width={180}
                onValueChanged={onChangeSearchCostCenter}
                mode="search"
              />
            </Item>
            <Item location={'before'}>
            <TextBox
              labelMode="static"
              stylingMode="outlined"
              placeholder="승인번호"
              width={180}
              onValueChanged={onChangeSearchApprovalNo}
              mode="search"
            />
            </Item>
          </Toolbar>
          {/* <PivotGrid
            ref={pivotGridRef}
            className={'dx-card wide-card van-pivot'}
            dataSource={pivotDataSource}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            showRowGrandTotals={false}
            hoverStateEnabled={true}
            height={500}
            onCellClick={onCellClick}
          >
            <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
            <FieldChooser enabled={false} />
          </PivotGrid> */}
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: 'PGVAN결제데이터.xlsx',
            }}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Column dataField={'TransactionDate'} caption={'결제승인일'} customizeText={customizeText} />
            <Column dataField={'CardAgencyType'} caption={'PG/VAN'} />
            <Column dataField={'CardTypeName'} caption={'결제유형'} />
            <Column dataField={'CardAgencyListName'} caption={'결제사'} alignment={'center'} />
            <Column dataField={'ApprovalTypeName'} caption={'승인유형'} />
            <Column dataField={'HiParkingID'} caption={'P코드'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} />
            <Column dataField={'TerminalID'} caption={'단말기ID'} />
            <Column dataField={'SalesPrice'} caption={'결제금액'} format="#,##0 원" />
            <Column dataField={'ApprovalNo'} caption={'승인번호'} />
            <Column dataField={'CreditCardNo'} caption={'카드번호'} />
            <Column dataField={'IssueCreditCard'} caption={'카드사'} />
            <Column dataField={'StatusName'} caption={'대사상태'} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} />
            <Column dataField={'VANIDName'} caption={'VAN가맹점명'} />
            <Column dataField={'VANSubID'} caption={'VAN가맹점번호'} />
            <Column dataField={'ApprovalCode'} caption={'응답코드'} />
            {/* <Column dataField={'Commission'} caption={'Commission'} /> */}
            
            {/* <Column dataField={'TransactionDateTime'} caption={'Transaction Date Time'} customizeText={customizeText} /> */}
            {/* <Column dataField={'DepositDueDate'} caption={'Deposit Due Date'} /> */}
            {/* <Column dataField={'DepositDuePrice'} caption={'Deposit Due Price'} alignment="left" format="#,##0 원" /> */}
            {/* <Column dataField={'MonthlyInstallmentsType'} caption={'Monthly Installments Type'} /> */}
            {/* <Column dataField={'RegisterDate'} caption={'Register Date'} customizeText={customizeText} /> */}
            <Summary>
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default ViewVANSalesAggregationPage;
