import React, { useMemo, useRef } from 'react';
import { Column, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';
import { aggregation } from 'api';

const GeneralTicketSummary = props => {
  const { searchData } = props;
  const gridRef = useRef({});
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        if (searchData.FromDate !== null && searchData.FromDate !== '') {
          const result = await aggregation.getParkingSalesSummaryDataByDay({ ...searchData });
          return result.data;
        } else {
          return [];
        }
      },
    });
    return customDataSource;
  }, [searchData]);

  // const customizeText = cellInfo => {
  //   const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  //   return res;
  // };

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={600}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '전체매출조회 일반권 요약.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Column dataField={'HiParkingID'} caption={'P코드'} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} />
        <Column dataField={'SalesDate'} caption={'매출일'} />
        <Column dataField={'StatusName'} caption={'매출상태'} />
        {/* <Column dataField={'PaymentTypeName'} caption={'수금구분'} /> */}
        <Column dataField={'TotalCount'} caption={'매출건수'} alignment="right" />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" alignment="right" />
        <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" alignment="right" />
        <Summary>
          <TotalItem column="CostCenterName" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(GeneralTicketSummary);
