import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect, useContext, useRef } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { salesPayment, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import { cellStatus } from '../common/common';
import { AuthContext } from 'contexts/auth';
import { ROLE_AUTH_FOR_SALES_CLOSEPENDING } from 'constants/roleAuth';

import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import ContextMenu from 'devextreme-react/context-menu';
import CommonDataGrid from '../common/index';
import notify from 'devextreme/ui/notify';
import RemarkPopup from 'common/popup/remark-popup';

/*
isSalesPayment : 매출 대사 관리
isTaxinvoice, isSearch : 세금계산서 발행
isClosePending : 미처리 매출/결제 마감
*/

const EtcSales = React.forwardRef((props, ref) => {
  const {
    eRowdata,
    setERowdata,
    inquiryDate,
    costCenter,
    etcSalesRef,
    isClosePending,
    isTaxinvoice,
    searchData,
    isSearch,
    isSalesPayment,
    onCancelClick,
    setSelectedID,
    setSelectedAccountingNo,
    isInquiryMonthOnly,
  } = props;
  const clearPopupRef = useRef({});
  const { user } = useContext(AuthContext);
  const [selectSum, setSelectSum] = useState(0);
  const [customizedColumns, setCustomizedColumns] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));
  // 권한 구분
  const userRoleArr = user.RoleCodeStr.split('|');
  const isAuthorized = ROLE_AUTH_FOR_SALES_CLOSEPENDING.some(role => userRoleArr.includes(role));

  const focusChange = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    etcSalesRef.current.instance.selectRows(KeyIDs);
  };

  useEffect(() => {
    const customizedEtc = localStorage.getItem('customizedEtc');
    if (customizedEtc) {
      const tmp_columns = JSON.parse(customizedEtc);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = etcSalesRef.current.instance.getSelectedRowsData();
    setERowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.RemainAmount;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      const response = { CostCenter: costCenter, IsPostponed: 'Y' };
      response[isInquiryMonthOnly ? 'InquiryMonthOnly' : 'InquiryMonth'] = inquiryDate;
      return response;
    } else if (isTaxinvoice) {
      return isSearch ? searchData : {};
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || isSearch) {
          etcSalesRef.current.instance.clearSelection();
          const result = await salesPayment.getEtcSalesDataDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData, isInquiryMonthOnly]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { KeyID, AccountingNo } = data;
      setSelectedID(AccountingNo ? KeyID : 'N/A');
      setSelectedAccountingNo('');
      if (AccountingNo) {
        focusChange(AccountingNo);
      }
    }
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedEtc', JSON.stringify(dataFieldsArr));
      });
    }
  };
  const refresh = () => {
    etcSalesRef.current.instance.refresh();
  };

  const handleClear = () => {
    if (eRowdata.length === 0) {
      return alert('매출을 선택해주세요');
    } else {
      clearPopupRef.current.open();
    }
  };

  const onClearSubmit = ConfirmDate => {
    // api 호출
    const selectedItems = eRowdata;
    const keyIDList = selectedItems.map(d => d.KeyID);
    const KeyIDList = keyIDList.join(';');

    setLoadPanelVisible(true);
    salesPayment
      .registerSalesClear({
        KeyIDList,
        AccountingDate: ConfirmDate,
      })
      .then(res => {
        if (res.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '처리되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          refresh();
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
  };

  return (
    <>
      <CommonDataGrid
        gridRef={etcSalesRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        width={'100%'}
        onCellPrepared={onCellPrepared}
        onContentReady={onContentReady}
        onSelectionChanged={e => onSelectionChanged(e)}
        onCellClick={onCellClick}
        // loadPanel={{ enabled: fals }s}
        isHeader={true}
        headerAfter={[
          <Button
            text="완료처리"
            width={90}
            type="normal"
            stylingMode="contained"
            visible={isAuthorized}
            onClick={handleClear}
          />,
        ]}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '기타매출.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        {(isTaxinvoice || isClosePending) && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        {isClosePending && <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />}
        <Column
          dataField={'SalesDate'}
          caption={'매출일'}
          customizeText={customizeText}
          width={'102'}
          visible={!customizedColumns.includes('SalesDate')}
        />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'86'}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('StatusName')}
        />
        {isTaxinvoice && <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={'110'} />}
        <Column
          dataField={'EtcSalesTypeName'}
          caption={'매출 상세 유형'}
          width={'115'}
          visible={!customizedColumns.includes('EtcSalesTypeName')}
        />
        <Column
          dataField={'RemainAmount'}
          caption={'잔액'}
          visible={!customizedColumns.includes('RemainAmount')}
          format="#,##0 원"
          width={'104'}
        />
        <Column
          dataField={'SalesPrice'}
          caption={'매출금액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('SalesPrice')}
        />
        <Column
          dataField={'CustomerCorpName'}
          caption={'사업자명'}
          width={'90'}
          visible={!customizedColumns.includes('CustomerCorpName')}
        />
        <Column
          dataField={'CorpRegNumber'}
          caption={'사업자번호'}
          width={'100'}
          visible={!customizedColumns.includes('CorpRegNumber')}
        />
        <Column
          dataField={'PaymentTypeName'}
          caption={'결제유형'}
          width={'100'}
          visible={!customizedColumns.includes('PaymentTypeName')}
        />
        <Column
          dataField={'CustomerName'}
          caption={'고객명'}
          width={'100'}
          visible={!customizedColumns.includes('CustomerName')}
        />
        <Column dataField={'VehicleRegistrationNo'} caption={'시간권차량번호'} width={'100'} visible={!customizedColumns.includes('VehicleRegistrationNo')} />
        <Column dataField={'Remark'} caption={'비고'} width={'100'} visible={!customizedColumns.includes('Remark')} />
        <Column
          dataField={'RegisterDate'}
          caption={'등록일'}
          customizeText={customizeText}
          width={'150'}
          visible={!customizedColumns.includes('RegisterDate')}
        />
        <Summary>
          <TotalItem column="SalesPrice" displayFormat="선택합계 :" showInColumn="SalesDate" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="SalesDate" />
          <TotalItem column="EtcSalesTypeName" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" displayFormat="전체합계 :" showInColumn="SalesPrice" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
          <TotalItem column="RemainAmount" displayFormat="전체합계 :" showInColumn="RemainAmount" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
      <RemarkPopup
        ref={clearPopupRef}
        title={'완료처리'}
        setRemark={onClearSubmit}
        cRowdata={eRowdata}
        actionType="clear"
      />
    </>
  );
});

export default React.memo(EtcSales);
