import React, { useRef, useCallback } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { ticket, error } from 'api';

const MultiEditTicketForm = props => {
  const {
    setIsOpenbyMultiEdit,
    ticketIDs,
    setIsRefresh,
    selectMemberType,
    isRefresh,
    ticketMemberNames,
    setLoadPanelVisible,
  } = props;
  const formData = useRef({});

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { MemberType, SalesPrice } = formData.current;
      setLoadPanelVisible(true);
      await ticket
        .updateSeasonTicketIssueHistoryMulti({
          TicketID: ticketIDs,
          MemberType,
          SalesPrice,
          RegisterUser: '',
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '수정 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            setIsRefresh(!isRefresh);
            onClose();
          } else {
            // error
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [ticketIDs, setIsRefresh, isRefresh],
  );

  const renderMemberNames = () => {
    const count = ticketMemberNames.length;
    // const addSpace = ticketMemberNames.join(',');
    return <>{count + '건'}</>;
  };

  const onClose = () => {
    setIsOpenbyMultiEdit(false);
    formData.current = {};
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          formData={formData.current}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="정기권 일괄변경">
            <SimpleItem dataField="MemberName" component={renderMemberNames} editorOptions={{ readOnly: true }}>
              <Label text="선택건수" />
            </SimpleItem>
            <SimpleItem
              dataField="MemberType"
              editorType="dxSelectBox"
              editorOptions={{
                items: selectMemberType,
                displayExpr: 'text',
                valueExpr: 'code',
              }}
            >
              <Label text="정기권 유형" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="SalesPrice"
              editorType="dxNumberBox"
              colSpan={2}
              helpText="숫자만 입력가능합니다."
              editorOptions={{
                showSpinButtons: true,
                step: 1000,
                min: 0,
                format: '#,##0 원',
              }}
            >
              <Label text="결제금액" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'80%'} type={'default'} useSubmitBehavior={true} text="확인"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'80%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
};

export default MultiEditTicketForm;
