import React, { useEffect, useState, useRef } from 'react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { master } from '../../../api';
import './vendor.scss';
import CommonDataGrid from 'common/default-data-grid';

const VendorMasterPage = () => {
  const [text, setText] = useState('');
  const [searchParam, setSearchParam] = useState('');
  const [dataSource, setDataSource] = useState();
  const gridRef = useRef({});

  useEffect(() => {
    (async () => {
      const result = await master.getVender(searchParam);
      if (result.isOk && result.data.length > 0) {
        setDataSource(result.data);
      }
    })();
  }, [searchParam]);

  const onEnterKey = () => {
    onSearch();
  };

  const onValueChanged = e => {
    setText(e.value);
  };

  const onSearch = () => {
    setSearchParam({ SearchText: text.trim() });
  };

  const useYNcellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'Y' ? '예' : '아니오';
  };

  return (
    <div>
      <h2 className={'content-block'}>주차관제시스템 공급사 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            stylingMode="outlined"
            height={650}
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '주차관제시스템 공급사 조회.xlsx',
            }}
            isHeader={true}
            headerBefore={[
              <TextBox
                mode="search"
                labelMode="static"
                stylingMode="outlined"
                placeholder="공급사코드/공급사명"
                width={200}
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column dataField={'VendorCode'} width={190} caption={'공급사코드'} hidingPriority={3} />
            <Column dataField={'VendorName'} caption={'공급사명'} hidingPriority={2} />
            <Column dataField={'UseYN'} caption={'사용여부'} hidingPriority={1} cellRender={useYNcellRender} />
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default VendorMasterPage;
