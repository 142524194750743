export const cellStatus = (data, displayValue, cellElement) => {
  if (data) {
    const { StatusName, Status } = data;
    const tr = cellElement;
    if (displayValue === StatusName) {
      if (Status === 'A') {
        cellElement.style.backgroundColor = 'rgb(229 248 240)';
        cellElement.style.color = 'rgb(51 202 139)';
      }  else if (Status === 'O') {
        cellElement.style.backgroundColor = 'rgba(0, 207, 232,0.12)';
        cellElement.style.color = 'rgb(29 74 74)';
      } else if (Status === 'P') {
        cellElement.style.backgroundColor = 'rgb(252 231 231)';
        cellElement.style.color = 'rgb(236 74 74)';
      } else if (Status === 'N') {
        cellElement.style.backgroundColor = 'rgba(247,247,247,1)';
        cellElement.style.color = 'rgba(33,33,33,1)';
      } else if (Status === 'C') {
        tr.classList.add('status-cancel');
      }else if (Status === 'E') {
        tr.classList.add('status-cancel');
      } else if (Status === 'S') {
        tr.classList.add('status-cancel');
      }
    } else {
      // 취소의 경우 한줄을 강조한다.
      if (Status === 'C' && tr) {
        tr.classList.add('status-cancel');
      }
      if (Status === 'E' && tr) {
        tr.classList.add('status-cancel');
      }
    }
  }
};

export const statusType = [
  { label: '대사 전', code: false },
  { label: '대사완료', code: true },
];

export const statusCode = code => {
  if (code === 'A') {
    return '대사완료';
  } else if (code === 'N') {
    return '대사 전';
  } else if (code === 'O') {
    return '대사 중';
  } else if (code === 'F') {
    return '정산완료';
  } else if (code === 'S') {
    return '결산완료';
  } else {
    return '코드 error';
  }
};
