import React, { useState, useEffect } from 'react';
import { Popup } from 'devextreme-react/popup';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DropDownButton, { Item as DropItem } from 'devextreme-react/drop-down-button';
import notify from 'devextreme/ui/notify';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import ScrollView from 'devextreme-react/scroll-view';
import TileView from 'devextreme-react/tile-view';
import User from 'components/user-panel/component/user';
import './header.scss';
import { salesPayment } from 'api';

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const [isPopup, setIsPopup] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const onClose = () => {
    setIsPopup(false);
  };

  useEffect(() => {
    (async () => {
      const result = await salesPayment.getTaxInvoiceErrorList({});
      if (result.isOk) {
        setDataSource(result.data);
      } else {
        notify(
          {
            message: JSON.parse(result.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  }, []);

  const TileViewItem = ({ data }) => {
    return (
      <div>
        <div className="dx-tile-content">
          {/* <div style={{ textAlign: 'right' }}>
            <Button className="delete-button" icon="close" stylingMode="text" hint="삭제"></Button>
          </div> */}
          <div style={{ margin: '10px', fontWeight: 'bold' }}>{data.CostCenterName + ' ' + data.SalesDate}</div>
          <div style={{ margin: '10px' }}>{data.InvoiceID + '(' + data.TaxIssueTypeName + ')'}</div>
          <div style={{ margin: '10px' }}>{data.CustomerName}</div>
          <div style={{ margin: '10px', color: 'red' }}>[{data.ErrorTypeName}] </div>
          <div style={{ margin: '10px', color: 'red' }}>{data.ErrorMessage}</div>
        </div>
      </div>
    );
  };

  const onTileItemClick = e => {
    const itemData = e.itemData;
    const className = e.event.originalEvent.currentTarget.className;
    const isDeleteButton = className.includes('delete-button');
    if (!isDeleteButton) {
      const InvoiceID = itemData.InvoiceID;
      navigator.clipboard.writeText(InvoiceID).then(() => {
        notify(
          {
            message: '클립보드에 저장되었습니다.',
            maxWidth: 100,
            width: 100,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
            displayTime: 1000,
          },
          'success',
        );
      });
    } else {
      // (async () => {
      //   const result = await salesPayment.updateTaxInvoiceListError();
      //   if (result.isOk) {
      //     notify(
      //       {
      //         message: '삭제 되었습니다.',
      //         width: 230,
      //         position: {
      //           at: 'top',
      //           my: 'top',
      //           of: '#container',
      //         },
      //       },
      //       'success',
      //     );
      //   } else {
      //     notify(
      //       {
      //         message: JSON.parse(result.error.detail).sqlMessage,
      //         width: 230,
      //         position: {
      //           at: 'top',
      //           my: 'top',
      //           of: '#container',
      //         },
      //       },
      //       'error',
      //     );
      //   }
      // })();
    }
  };
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />
        <Item location={'after'} className="header-bell-dropdown">
          <DropDownButton
            icon="fa fa-exclamation-circle"
            width={320}
            stylingMode={'text'}
            text={`세금계산서 오류: ${dataSource.length}건`}
            visible={dataSource.length > 0 ? true : false}
            hoverStateEnabled={false}
            hint="닫기"
          >
            <DropItem>
              <TileView
                items={dataSource}
                hint="세금계산서 번호 복사하기"
                height={dataSource.length * 150 > 500 ? 500 : dataSource.length * 150}
                baseItemHeight={140}
                baseItemWidth={280}
                itemMargin={8}
                direction="vertical"
                itemComponent={TileViewItem}
                onItemClick={onTileItemClick}
              ></TileView>
            </DropItem>
          </DropDownButton>
        </Item>
        <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
          <Button className={'user-button authorization'} width={210} height={'100%'} stylingMode={'text'}>
            <UserPanel menuMode={'context'} setIsPopup={setIsPopup} />
          </Button>
        </Item>
      </Toolbar>
      <Popup onHiding={onClose} visible={isPopup} showTitle={false} width="22%" height="65%">
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <User setIsPopup={setIsPopup} />
          </div>
        </ScrollView>
      </Popup>
    </header>
  );
}
