import { React, useEffect, useMemo, useState, useRef } from 'react';
import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';
import { Button } from 'devextreme-react/button';
import { salesPayment } from 'api';
import { SelectBox } from 'devextreme-react/select-box';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';

const DMSInterfaceReportPage = () => {
  const selectBoxRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      selectBoxRef.current?.instance?.focus();
    }, 500);
  }, []);

  const [searchParams, setSearchParams] = useState({
    TransactionDateFrom: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    TransactionDateTo: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [isQty, setIsQty] = useState(true);
  const pivotDataSource = useMemo(() => {
    const customDataSource = new PivotGridDataSource({
      fields: [
        {
          caption: '결제대행사유형',
          dataField: 'PaymentAgencyGroup',
          width: 100,
          area: 'row',
          expanded: true,
        },
        {
          caption: '결제대행사',
          dataField: 'PaymentAgencyName',
          width: 100,
          area: 'row',
          expanded: true,
        },
        {
          caption: '조회기준일',
          dataField: 'TransactionDate',
          area: 'column',
        },
        {
          caption: '결제내역 집계',
          dataField: isQty ? 'Qty' : 'TotalSalesPrice',
          dataType: 'string',
          format: '#,##0',
          summaryType: 'sum',
          area: 'data',
        },
      ],

      store: new CustomStore({
        key: 'CompanyName',
        loadMode: 'raw',
        load: async () => {
          const result = await salesPayment.getSalesAggregationSummaryByDayType(searchParams);
          return result.data[0];
        },
      }),
    });
    return customDataSource;
  }, [searchParams, isQty]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const onSearch = () => {
    setSearchParams({
      TransactionDateFrom: moment(dateValue.fromDate).format('YYYY-MM-DD'),
      TransactionDateTo: moment(dateValue.toDate).format('YYYY-MM-DD'),
    });
  };

  const onClickButton = e => {
    e.itemData.name === '수량' ? setIsQty(true) : setIsQty(false);
  };

  return (
    <div>
      <h2 className={'content-block'}>결제 내역 일별 집계</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <Item location={'before'}>
              <div className="label">조회 기간 :</div>
            </Item>
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />
            </Item>
            <Item location={'before'} text="~" />
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </Item>
            <Item location={'after'}>
              <SelectBox
                ref={selectBoxRef}
                labelMode="static"
                placeholder="select"
                dropDownButtonTemplate="dropDownButton"
                stylingMode="outlined"
                defaultValue={'수량'}
                dataSource={[{ name: '수량' }, { name: '금액' }]}
                onItemClick={onClickButton}
                valueExpr="name"
                displayExpr="name"
                width={100}
              />
            </Item>
            <Item location={'after'}>
              <Button type="normal" stylingMode="contained" icon="search" text="조회" width={120} onClick={onSearch} />
            </Item>
          </Toolbar>

          <PivotGrid
            className={'dx-card wide-card payment-pivot'}
            dataSource={pivotDataSource}
            showBorders={true}
            showColumnTotals={false}
            showRowTotals={true}
            showRowGrandTotals={true}
            hoverStateEnabled={true}
            height={650}
          >
            <Scrolling mode="virtual" showScrollbar="always" />
            <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
            <FieldChooser enabled={false} />
          </PivotGrid>
        </div>
      </div>
    </div>
  );
};

export default DMSInterfaceReportPage;
