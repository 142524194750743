import React, { useCallback, useState, useRef, useImperativeHandle } from 'react';
import moment from 'moment';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';
import { salesPayment, error } from 'api';

const RegisterSalesSetOffForm = React.forwardRef((props, ref) => {
  const formData = useRef({});
  const formRef = useRef({});
  const { refresh, costCenterCode, setIsCarryOverPopup, setLoadPanelVisible } = props;
  const [addParams, setAddParams] = useState({ SalesType: '', PaymentType: '', KeyIDList: [], SalesPrice: 0 });

  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = data => {
    if (data) {
      const keyIDList = data.map(d => d.KeyID);
      const sales = data.reduce((a, c) => {
        return a + c.SalesPrice;
      }, 0);
      const transactionAmount = data.reduce((a, c) => {
        return a + c.TransactionAmount;
      }, 0);
      setAddParams({
        SalesPrice: sales || transactionAmount,
        SalesType: data[0].SalesType,
        PaymentType: data[0].PaymentType,
        KeyIDList: keyIDList.join(';'),
      });
    }
  };

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { Remark, SalesDate } = formData.current;
      const { SalesType, KeyIDList, SalesPrice, PaymentType } = addParams;

      // 매출 잡손실 처리 등록
      setLoadPanelVisible(true);
      await salesPayment
        .registerSalesSetOff({
          CostCenterCode: costCenterCode,
          SalesType,
          KeyIDList,
          SalesPrice,
          SalesDate: SalesDate ? moment(SalesDate).format('YYYY-MM-DD') : '',
          Remark: Remark,
          RegisterUser: '',
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '처리되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            onClose();
            refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [costCenterCode, addParams],
  );

  const onClose = () => {
    setIsCarryOverPopup(false);
    formRef.current.instance.resetValues();
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData.current}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="매출 이월 처리">
            <SimpleItem
              colSpan={2}
              dataField="SalesPrice"
              editorType="dxNumberBox"
              editorOptions={{
                format: '#,##0',
                readOnly: true,
                value: addParams.SalesPrice,
              }}
            >
              <Label text="총금액" location="top" />
            </SimpleItem>
            <SimpleItem
              dataField="SalesDate"
              editorType="dxDateBox"
              colSpan={2}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
                placeholder: '매출일을 선택해주세요',
                // onValueChanged: handleDateFrom,
              }}
            >
              <RequiredRule message="필수 값입니다." />
              <Label text="매출일" location="top" />
            </SimpleItem>
            <SimpleItem dataField="Remark" editorType="dxTextArea">
              <Label text="비고" location="top" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'저장'}></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default RegisterSalesSetOffForm;
