import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from 'devextreme-react/button';
import { Toolbar as MainToolbar, Item as ToolbarItem } from 'devextreme-react/toolbar';
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload, Item } from 'devextreme-react/html-editor';
import { mainURL } from 'utils/common';
import { Popup } from 'devextreme-react/popup';
import { system } from '../../../api';
import { markup } from './component/default-markup';
import ScrollView from 'devextreme-react/scroll-view';
import PreviewPopup from './component/preview-popup';
import axios from 'axios';

const NoticeMngPage = () => {
  const htmlEditorRef = useRef({});
  const previewBtnName = '미리보기';
  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const headerValues = [false, 1, 2, 3, 4, 5];
  const [tmpNoticeFileInfo, setTmpNoticeFileInfo] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const onHiding = useCallback(() => {
    setPopupVisible(false);
  }, []);

  const handlePreviewBtn = async htmlContent => {
    let formData = new FormData();
    formData.append('storageType', 's3');
    formData.append('companyID', 2);
    formData.append('bizCode', 'B02');
    formData.append('fileCategoryCode', 'TMP_FC_001');
    formData.append('file_ACL', 'private');
    formData.append('version', '0.01');
    formData.append('verkey', '');
    formData.append('fileID', '');

    const htmlBlob = new Blob([htmlContent], { type: 'text/html' });
    formData.append('files', htmlBlob, 'htmlContent.html');

    try {
      const response = await axios.post('file/upload', formData);
      if (response.status === 200) {
        const { files, FileID } = response.data;
        const FileKey = files[0].FileKey;
        if (FileKey && FileID) {
          setTmpNoticeFileInfo({ FileKey, FileID });
        } else {
          setTmpNoticeFileInfo({});
        }
        setPopupVisible(!popupVisible);
      }
    } catch (e) {
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  };

  const onBtnClick = e => {
    const HtmlContent = htmlEditorRef.current.instance.option('value');
    handlePreviewBtn(HtmlContent);
  };

  return (
    <div>
      <div className="content-block">
        <h2>공지사항 관리</h2>
      </div>
      <div className="content-block">
        <div className="dx-card responsive-paddings" style={{ padding: '30px' }}>
          <MainToolbar>
            <ToolbarItem location={'before'}>
              <h6>아래 내용을 입력하고 [미리보기]를 클릭하면 공지사항 팝업창을 미리볼 수 있습니다.</h6>
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="search" text={previewBtnName} onClick={onBtnClick} />
            </ToolbarItem>
          </MainToolbar>
          <div className="widget-container" style={{ marginTop: '10px' }}>
            <HtmlEditor ref={htmlEditorRef} height={800} width="100%" valueType={'html'} defaultValue={markup}>
              <MediaResizing enabled={true} />
              <ImageUpload tabs={['file']} fileUploadMode="base64" />
              <Toolbar multiline={true}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item name="size" acceptedValues={sizeValues} />
                <Item name="header" acceptedValues={headerValues} />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="deleteTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="deleteRow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>
          </div>
        </div>
      </div>
      <Popup onHiding={onHiding} visible={popupVisible} showTitle={false} resizeEnabled={true} width="60%" height={610}>
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ padding: 3 }}>
            <PreviewPopup setPopupVisible={setPopupVisible} tmpNoticeFileInfo={tmpNoticeFileInfo} />
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
};

export default NoticeMngPage;
