import React, { useState, useRef, useMemo, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';

import { cellStatus4Cashbill } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { Column, Summary, TotalItem, Selection, Scrolling, LoadPanel } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const CashbillListViewAll = () => {
  const detailHistoryRef = useRef({});
  const searchIdentifierRef = useRef({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    IdentifierNo: '',
  });
  const [text, setText] = useState({
    IdentifierNo: '',
  });
  const [refresh, setRefresh] = useState(false);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchData({
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      IdentifierNo: text.IdentifierNo,
    });
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'ConfirmID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getCashBillInfoListAll`, {
            ...searchData,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData, refresh]);

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus4Cashbill(data, displayValue, cellElement);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onRefreshClick = () => {
    const searchIdentifierNoValue = searchIdentifierRef.current.instance._changedValue;
    setSearchData({ ...searchData, IdentifierNo: searchIdentifierNoValue });
    setRefresh(!refresh);
  };

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsCount') options.totalValue = selectedRows.length;
    else if (options.name === 'SelectedRowsSum') {
      const sum = selectedRows.reduce((a, c) => {
        return a + c.금액;
      }, 0);
      options.totalValue = sum;
    }
  };
  const onSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    setSelectedRows(selectRows);
  };
  const memoizedDatagrid = useMemo(() => {
    return (
      <CommonDataGrid
        gridRef={detailHistoryRef}
        className={'dx-card wide-card grid-with-status native-scroll-header'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={670}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '현금영수증 발행 전체 목록.xlsx',
          customizeCell: {
            date: ['RegisterDate', 'LastUpdateDate'],
          },
        }}
        headerBefore={[
          <div className="label"> 매출일 :</div>,
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="FromDate"
            onValueChanged={handleFromDate}
            value={dateValue.fromDate}
          />,
          <div className="label">~</div>,
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="ToDate"
            min={dateValue.fromDate}
            value={dateValue.toDate}
            onValueChanged={handleToDate}
            dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
          />,
          <TextBox
            ref={searchIdentifierRef}
            label="IdentifierNo"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="식별번호/현금영수증ID"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
        ]}
        headerAfter={[<Button icon="refresh" type="normal" stylingMode="contained" onClick={onRefreshClick} />]}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" useNative={true} />
        <Selection mode="multiple" selectAllMode="pages" />
        <LoadPanel enabled={false} />
        <Column dataField="채널구분" width={90} />
        <Column dataField="승인번호" width={110} />
        <Column dataField="매출일" width={110} />
        <Column dataField="문서형태" width={90} />
        <Column dataField="구분" width={70} />
        <Column dataField="거래처명" width={120} />
        <Column dataField="식별번호일부" width={120} alignment="right" />
        <Column dataField="DMS식별번호" width={140} alignment="right" />
        <Column dataField="금액" width={120} format="#,##0 원" />
        <Column
          dataField="공급가액"
          width={120}
          format={cell => parseInt(cell).toLocaleString() + ' 원'}
          alignment="right"
        />
        <Column dataField="세액" width={120} format="#,##0 원" />
        <Column dataField="매출일시" width={150} cellRender={customizeText} />
        <Column dataField="DMS상품명" caption="DMS상품명" width={300} />
        <Column dataField="ConfirmID" caption="신고번호" width={150} />
        <Column dataField="rcprTin" width={150} visible={false} />
        <Summary calculateCustomSummary={calculateSelectedRow}>
          <TotalItem displayFormat="선택 합계:" showInColumn="DMS식별번호" alignment="right" />
          <TotalItem
            name="SelectedRowsSum"
            summaryType="custom"
            showInColumn="금액"
            valueFormat="#,##0 원"
            alignment="left"
          />

          <TotalItem displayFormat="총 합계:" showInColumn="DMS식별번호" alignment="right" />
          <TotalItem column="금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" alignment="left" />
          <TotalItem name="SelectedRowsCount" summaryType="custom" displayFormat="선택: {0}건" showInColumn="채널구분" />
          <TotalItem summaryType="count" showInColumn="채널구분" displayFormat="총: {0}건" />
        </Summary>
      </CommonDataGrid>
    );
  }, [refresh, searchData, text, selectedRows]);

  return (
    <React.Fragment>
      {' '}
      <h2 className={'content-block detail-title'}>
        현금영수증 전체 조회
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <h3 style={{ margin: '1px 5px 7px 7px' }}>현금영수증 발행목록</h3>
          {memoizedDatagrid}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(CashbillListViewAll);
