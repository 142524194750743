import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';

/*

- 현재는 첫번쨰 시트만 읽어옵니다.
- fieldColumn : 컬럼 개수 대로 ['A', 'B', 'C',...'N']

*/

const ExcelUpload = () => {
  const dataGridRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);

  // 컬럼 정의
  const columns = ['CostCenterCode', 'CostCenterName', 'VANID', 'VANName'];

  useEffect(() => {
    result();
  }, [dataGridRef]);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();

      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const handleImport = ExcelOrganization => {
    console.log('ExcelOrganization >>', ExcelOrganization);
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };
  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);
  const cellStatus = (data, displayValue, cellElement) => {
    if (data) {
      const { StatusName, Status } = data;
      const tr = cellElement;
      if (displayValue === StatusName) {
        if (Status === 'A') {
          cellElement.style.backgroundColor = 'rgb(229 248 240)';
          cellElement.style.color = 'rgb(51 202 139)';
        }  else if (Status === 'O') {
          cellElement.style.backgroundColor = 'rgba(0, 207, 232,0.12)';
          cellElement.style.color = 'rgb(29 74 74)';
        } else if (Status === 'P') {
          cellElement.style.backgroundColor = 'rgb(252 231 231)';
          cellElement.style.color = 'rgb(236 74 74)';
        } else if (Status === 'N') {
          cellElement.style.backgroundColor = 'rgba(247,247,247,1)';
          cellElement.style.color = 'rgba(33,33,33,1)';
        } else if (Status === 'C') {
          tr.classList.add('status-cancel');
        } else if (Status === 'S') {
          tr.classList.add('status-cancel');
        }
      } else {
        // 취소의 경우 한줄을 강조한다.
        if (Status === 'C' && tr) {
          tr.classList.add('status-cancel');
        }
      }
    }
  };

  const sampleData = useMemo(() => {
    const testData = [];
    const keys = headerText.map(k => k.key);
    for (let i = 0; i < 20; i++) {
      const result = keys.reduce((acc, curr) => {
        acc[curr] = 'test' + i;
        return acc;
      }, {});
      testData.push(result);
    }
    return testData;
  }, []);

  return (
    <div>
      <h2 className={'content-block'}>Excel File upload 샘플</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flexGrow: 1, display: 'flex' }}>
              <ExcelImport fieldColumn={['A', 'B', 'C', 'D']} handleImport={e => handleImport(e.map(importMapFunc))} />
            </div>
          </div>
          <CommonDataGrid
            gridRef={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            onCellPrepared={onCellPrepared}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '정기권업로드.xlsx',
            }}
            headerAfter={[
              <ExcelExport
                excelFields={headerText}
                sheetName="Sheet"
                fileName="sample.xlsx"
                excelData={sampleData}
                buttonName="샘플파일 다운로드"
              />,
            ]}
            allowColumnReordering={true}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <HeaderFilter visible={true} />
            <LoadPanel enabled showPane={showPane} />
            {/* <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" /> */}

            <Column dataField={'CostCenterCode'} caption={'사업소코드'} hidingPriority={4} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} hidingPriority={3} />
            <Column dataField={'VANID'} caption={'단말기번호'} hidingPriority={4} />
            <Column dataField={'VANName'} caption={'가맹점번호'} hidingPriority={4} />
            <Summary>
              {/* <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="ApprovalDate" /> */}
              <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default ExcelUpload;
