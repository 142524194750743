import React from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { read } from 'xlsx';
import moment from 'moment/moment';
import { excelSerialDateToJSDate, secToHHmmss, excelSerialDateToJSDatewTime } from '../utils/common';

const ExcelImport = ({ fieldColumn, handleImport, fieldColumnType }) => {
  const fileExtensions = ['.xlsx', '.xls'];
  const getLastNumber = value => {
    const regex = /[0-9]+/gm;
    let m;
    let lastNumber;

    while ((m = regex.exec(value)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      // The result can be accessed through the `m`-variable.
      m.forEach(match => {
        lastNumber = match;
      });
    }

    return lastNumber;
  };

  const uploadFile = fileData => {
    const file = fileData;
    const reader = new FileReader();
    reader.onload = e => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const table = workbook.Sheets[sheetName];

      let currRow = 1;

      const rangeArr = table['!ref'].split(':');
      const endCell = rangeArr[1];
      const lastNumber = getLastNumber(endCell);

      const tableJSON = [];
      while (currRow++ < lastNumber) {
        // const newRow = fieldColumn.map(a => (table[a + currRow] ? table[a + currRow].v : null));

        const newRow = fieldColumn.map((a, index) => {
          if (
            fieldColumnType &&
            fieldColumnType[index] === 'date' &&
            table[a + currRow] &&
            typeof table[a + currRow].v === 'number'
          ) {
            return moment(excelSerialDateToJSDate(table[a + currRow].v)).format('YYYY-MM-DD');
          } else if (
            fieldColumnType &&
            fieldColumnType[index] === 'time' &&
            table[a + currRow] &&
            typeof table[a + currRow].v === 'number'
          ) {
            return secToHHmmss(table[a + currRow].v);
          } else if (
            fieldColumnType &&
            fieldColumnType[index] === 'datewTime' &&
            table[a + currRow] &&
            typeof table[a + currRow].v === 'number'
          ) {
            const YMD = moment(excelSerialDateToJSDatewTime(table[a + currRow].v)).format('YYYY-MM-DD');
            const HMS = secToHHmmss(table[a + currRow].v % 1);
            return YMD + ' ' + HMS;
          } else {
            return table[a + currRow] ? table[a + currRow].v : null;
          }
        });

        const isEmptyValue = newRow.every(list => list === null);

        // 입력 로우의 모든 값이 null일 경우 제외
        if (!isEmptyValue) tableJSON.push(newRow);
      }
      handleImport(tableJSON);
    };
    reader.readAsBinaryString(file);
  };

  const onUploadStarted = () => {
    console.log('onUploadStarted...');
  };
  const onValueChanged = () => {
    console.log('onValueChanged...');
  };
  const onBeforeSend = () => {
    console.log('onBeforeSend...');
  };
  const contentReady = () => {
    console.log('contentReady...');
  };
  const onClickFileUploader = () => {
    console.log('onClickFileUploader...');
  };
  const onDropZoneEnter = () => {
    console.log('onDropZoneEnter...');
  };
  const onInitialized = () => {
    console.log('onInitialized...');
  };
  const onProgress = () => {
    console.log('onProgress...');
  };
  return (
    <FileUploader
      onClick={onClickFileUploader}
      onUploadStarted={onUploadStarted}
      onValueChanged={onValueChanged}
      onBeforeSend={onBeforeSend}
      contentReady={contentReady}
      onDropZoneEnter={onDropZoneEnter}
      onInitialized={onInitialized}
      onProgress={onProgress}
      multiple={false}
      allowedFileExtensions={fileExtensions}
      uploadMode={'instantly'}
      selectButtonText="엑셀 파일 업로드"
      labelText="엑셀 파일을 선택하거나 드래그 하세요"
      // uploadUrl="http://localhost:3001/api/sample/file"
      uploadFile={uploadFile}
      showFileList={true}
    />
  );
};

export default ExcelImport;
