import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';

/* 히스토리 : 세금계산서 대사에서는 van 사만 필요해서 새로 생성 */
const Van = props => {
  const { setvRowdata, vanRef, searchData } = props;
  const [selectSum, setSelectSum] = useState(0);

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = e => {
    const rowdata = vanRef.current.instance.getSelectedRowsData();
    setvRowdata(rowdata);

    // 합계
    const sum = rowdata.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        if (searchData && searchData.CostCenterCode) {
          const { FromDate, ToDate, CostCenterCode } = searchData;
          const result = await salesPayment.getVanDataDetail({
            FromDate,
            ToDate,
            CostCenter: CostCenterCode,
            IsforTaxAccounting: 'Y',
          });
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return '선택 합계 : ' + selectSum.toLocaleString('ko-KR') + ' 원';
  };

  return (
    <>
      <CommonDataGrid
        gridRef={vanRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={775}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        headerBefore={[
          <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
            <i className="dx-icon-info dx-icon-customicon"></i>
            <p>사업소명을 먼저 검색해주세요</p>
          </div>,
        ]}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'VAN.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'TransactionDate'} caption={'결제시간'} customizeText={customizeText} width={'160'} />
        <Column dataField={'CardTypeName'} caption={'결제방식'} width={'80'} />
        <Column dataField={'ApprovalTypeName'} caption={'결제유형'} width={'80'} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
        <Column dataField={'ApprovalNo'} caption={'승인번호'} width={'100'} />
        <Column dataField={'SalesPrice'} caption={'결제금액'} format="#,##0 원" width={'150'} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={'150'} />
        <Column dataField={'Remark1'} caption={'비고'} width={'100'} />
        <Column dataField={'CardAgencyListName'} caption={'결제사'} width={'100'} />
        <Summary>
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="TransactionDate" />
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(Van);
