import React, { useState } from 'react';
import SelectBox from 'devextreme-react/select-box';
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Grid,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
} from 'devextreme-react/chart';

const Line = () => {
  const [type, setType] = useState('spline');

  const sharingStatisticsInfo = [
    {
      year: 1997,
      smp: 263,
      mmp: 208,
      cnstl: 9,
      cluster: 1,
    },
    {
      year: 1999,
      smp: 169,
      mmp: 270,
      cnstl: 61,
      cluster: 7,
    },
    {
      year: 2001,
      smp: 57,
      mmp: 261,
      cnstl: 157,
      cluster: 45,
    },
    {
      year: 2003,
      smp: 0,
      mmp: 154,
      cnstl: 121,
      cluster: 211,
    },
    {
      year: 2005,
      smp: 0,
      mmp: 97,
      cnstl: 39,
      cluster: 382,
    },
    {
      year: 2007,
      smp: 0,
      mmp: 83,
      cnstl: 3,
      cluster: 437,
    },
  ];

  const architectureSources = [
    { value: 'smp', name: 'SMP' },
    { value: 'mmp', name: 'MMP' },
    { value: 'cnstl', name: 'Cnstl' },
    { value: 'cluster', name: 'Cluster' },
  ];

  const handleChange = e => {
    setType(e.value);
  };
  return (
    <div>
      <div className="options" style={{ marginBottom: '20px' }}>
        <div className="option">
          <span> 타입 선택 </span>
          <SelectBox
            stylingMode="outlined"
            dataSource={['spline', 'stackedspline', 'fullstackedspline']}
            value={type}
            onValueChanged={handleChange}
          />
        </div>
      </div>
      <Chart palette="Violet" dataSource={sharingStatisticsInfo} title="차트 제목">
        <CommonSeriesSettings argumentField="year" type={type} />
        <CommonAxisSettings>
          <Grid visible={true} />
        </CommonAxisSettings>
        {architectureSources.map(item => (
          <Series key={item.value} valueField={item.value} name={item.name} />
        ))}
        <Margin bottom={20} />
        <ArgumentAxis allowDecimals={false} axisDivisionFactor={60}>
          <Label>
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <Legend verticalAlignment="top" horizontalAlignment="right" />
        <Export enabled={true} />
        <Tooltip enabled={true} />
      </Chart>
    </div>
  );
};

export default Line;
