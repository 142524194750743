import React, { useCallback, useState, useEffect, useRef } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  NumericRule,
  PatternRule,
} from 'devextreme-react/form';
import Validator from 'devextreme/ui/validator';
import { salesPayment, error, parking } from 'api';
import { issueCreditCard } from 'constants/bank';
import { Toast, Position } from 'devextreme-react/toast';
import { Popup } from 'devextreme-react/popup';
import DetailHistoryToCancel from './popup/DetailHistoryToCancel';
import AddTaxinvoice from './popup/AddTaxinvoice';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

const CancelSalesForm = props => {
  const {
    refresh,
    popup,
    setPopup,
    gRowdata,
    sRowdata,
    eRowdata,
    tRowdata,
    srRowdata,
    tSalesRowdata,
    refundReason,
    refundPaymentType,
    setLoadPanelVisible,
  } = props;

  const cancelSalesFormRef = useRef({});
  const detailHistoryRef = useRef({});
  const addTaxinvoiceRef = useRef({});

  const [isCash, setIsCash] = useState(false);
  const [isOpenTaxIssue, setIsOpenTaxIssue] = useState(false);
  const [isOpenTaxDetail, setIsOpenTaxDetail] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);

  const [cancelInvoiceInfo, setCancelInvoiceInfo] = useState(null);
  const [issueInvoiceInfo, setIssueInvoiceInfo] = useState(null);

  const [taxDataSource, setTaxDataSource] = useState([]);
  const [itemDataSource, setItemDataSource] = useState([]);

  const [isReadOnly, setIsReadOnly] = useState({
    CreditCardApprovalNo: false,
    IssueCreditCard: false,
    RefundPaymentType: false,
  });
  const [tempDateValue, setTempDateValue] = useState({});
  const [formData, setFormData] = useState({
    CostCenterCode: '',
    CostCenterName: '',
    SalesType: '',
    SalesKeyID: '',
    CardAgencyListName: '',
    IssueCreditCard: '',
    CreditCardApprovalNo: '',
    TransactionDate: '',
    SalesPrice: '',
    RefundPaymentType: '',
    RefundStatusName: '',
    CustomerName: '',
    TelephoneNo: '',
    VehicleRegistrationNo: '',
    RefundPrice: '',
    RefundReason: '',
    BankName: '',
    BankAccountNo: '',
    AccountHolder: '',
    CustomerInfo: '',
    Remark: '',
    RegisterUser: '',
    FromDate: '',
    ToDate: '',

    // 참고 정보
    ApprovalDate: '',
    ApprovedDate: '',
    ConfirmIMMDED: false,
  });
  useEffect(() => {
    if (!popup.visible) return;
    const hasGRowdata = gRowdata.length > 0;
    const hasSRowdata = sRowdata.length > 0;
    const hasERowdata = eRowdata.length > 0;
    const hasTRowdata = tRowdata.length > 0;
    const hasSRRowdata = srRowdata.length > 0;
    const selectedInfo = [...gRowdata, ...sRowdata, ...eRowdata, ...tRowdata, ...srRowdata];

    const { CardAgencyListName, IssueCreditCard, CreditCardApprovalNo } = selectedInfo[0];

    const isEasyPayment =
      CardAgencyListName === 'KGInicis' &&
      (IssueCreditCard === '페이코포인트' || IssueCreditCard === '카카오머니(포인트)');

    // 카드사, 승인번호 데이터
    const issueCreditCard = isEasyPayment ? '간편결제' : IssueCreditCard;
    const creditCardApprovalNo = isEasyPayment ? IssueCreditCard : CreditCardApprovalNo;

    // 카드사, 승인번호 비활성화
    isEasyPayment
      ? setIsReadOnly({ ...isReadOnly, IssueCreditCard: true, CreditCardApprovalNo: true })
      : setIsReadOnly({ ...isReadOnly, IssueCreditCard: issueCreditCard, CreditCardApprovalNo: creditCardApprovalNo });

    // 환불 기간 추가로 입력받는 경우
    if (hasSRowdata || (hasERowdata && eRowdata[0]?.EtcSalesType === '007')) {
      const salesFromDate = selectedInfo[0].FromDate;
      const salesToDate = selectedInfo[0].ToDate;
      setIsPeriod(true);
      setTempDateValue({
        TempFromDate: salesFromDate,
        TempToDate: salesToDate,
        FromDate: salesFromDate,
        ToDate: salesToDate,
      });
    }

    // 세금계산서 환불
    if (hasTRowdata) {
      const isPeriod = tSalesRowdata.data.some(a => a.SalesType === 'SeasonTicket' || a.EtcSalesType === '007');
      const refundPrice = tSalesRowdata.data.reduce((acc, obj) => acc + obj.SalesPrice, 0);
      const salesFromDate = tSalesRowdata.data[0].FromDate;
      const salesToDate = tSalesRowdata.data[0].ToDate;
      const transactionDate = selectedInfo[0].PaymentDate;

      setTempDateValue({
        TempFromDate: salesFromDate,
        TempToDate: salesToDate,
        FromDate: salesFromDate,
        ToDate: salesToDate,
      });
      setFormData({
        ...formData,
        ...selectedInfo[0],
        RefundPrice: refundPrice,
        TransactionDate: transactionDate,
        FromDate: salesFromDate,
        ToDate: salesToDate,
        RefundPaymentType: 'Cash',
        ConfirmIMMDED: false,
      });
      setIsPeriod(isPeriod);
      setIsCash(true);
      setIsReadOnly({ ...isReadOnly, CreditCardApprovalNo: false, RefundPaymentType: true });
    }

    // 가수금 환불
    else if (hasSRRowdata) {
      const refundPrice = selectedInfo[0].RemainAmount;
      const salesPrice = selectedInfo[0].RemainAmount;
      const paymentCode = selectedInfo[0].PaymentCode;
      if (paymentCode === 'Cash') {
        setIsCash(true);
        setIsReadOnly({ ...isReadOnly, CreditCardApprovalNo: false, RefundPaymentType: true });
        setFormData({
          ...formData,
          ...selectedInfo[0],
          RefundPrice: refundPrice,
          SalesPrice: salesPrice,
          IssueCreditCard: issueCreditCard,
          CreditCardApprovalNo: creditCardApprovalNo,
          RefundPaymentType: 'Cash',
        });
      } else {
        setFormData({
          ...formData,
          ...selectedInfo[0],
          RefundPrice: refundPrice,
          SalesPrice: salesPrice,
          IssueCreditCard: issueCreditCard,
          CreditCardApprovalNo: creditCardApprovalNo,
        });
      }
    }
    // 일반권, 정기권, 기타 매출 환불
    else {
      console.log(selectedInfo[0]);
      const refundPrice = selectedInfo[0].SalesPrice;
      const transactionDate = selectedInfo[0].PaymentDate;
      const paymentCode = selectedInfo[0].PaymentCode;
      if (paymentCode === 'Cash') {
        setIsCash(true);
        setIsReadOnly({ ...isReadOnly, CreditCardApprovalNo: false, RefundPaymentType: true });
        setFormData({
          ...formData,
          ...selectedInfo[0],
          RefundPrice: refundPrice,
          TransactionDate: transactionDate,
          ConfirmIMMDED: false,
          IssueCreditCard: issueCreditCard,
          CreditCardApprovalNo: creditCardApprovalNo,
          RefundPaymentType: 'Cash',
        });
      } else {
        setFormData({
          ...formData,
          ...selectedInfo[0],
          RefundPrice: refundPrice,
          TransactionDate: transactionDate,
          ConfirmIMMDED: false,
          IssueCreditCard: issueCreditCard,
          CreditCardApprovalNo: creditCardApprovalNo,
        });
      }
    }
  }, [gRowdata, sRowdata, eRowdata, tRowdata, srRowdata, tSalesRowdata, popup.visible]);

  useEffect(() => {
    const hasTRowdata = tRowdata.length > 0;
    if (hasTRowdata) {
      (async () => {
        var tmpTaxDataSource = [];
        const today = moment().format('YYYY-MM-DD');
        await salesPayment.getTaxInvoiceListByID({ InvoiceID: tRowdata[0].InvoiceID }).then(res => {
          if (res.isOk) {
            res.data[0].ChangeCode = '04';
            setTaxDataSource({ ...res.data[0], SalesDate: today });
            tmpTaxDataSource = res.data[0];
          }
        });
        await salesPayment
          .getTaxInvoiceItems({
            TaxType: tmpTaxDataSource.TaxType,
            InvoiceID: tRowdata[0].InvoiceID,
          })
          .then(res => {
            if (res.isOk) {
              setItemDataSource(res.data[0]);
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      })();
    }
  }, [tRowdata]);

  const onClose = () => {
    const hasTRowdata = tRowdata.length > 0;
    setPopup({ save: true, visible: false });
    cancelSalesFormRef.current?.instance?.resetValues();
    if (hasTRowdata > 0) {
      setCancelInvoiceInfo(null);
      setIssueInvoiceInfo(null);
      addTaxinvoiceRef.current.close();
    }
    setTempDateValue({});
    setIsPeriod(false);
    onHiding();
    setIsReadOnly({ CreditCardApprovalNo: false, IssueCreditCard: false, RefundPaymentType: false });
  };

  const contentRender = () => {
    return (
      <div style={{ color: 'black' }}>
        <i className="dx-icon-info icon-style"></i>
        <span>
          {' '}
          100만원 이상 현금 환불 건은 <br /> <u style={{ color: 'red' }}>그룹포탈에 기안 상신 후 </u>매출 취소 요청 부탁
          드립니다.
        </span>
      </div>
    );
  };

  const onHiding = () => {
    setIsToast(false);
  };
  const CheckRefundInfoWithTaxInvoice = async ({ InvoiceInfo, RefundInfo }) => {
    const checkResult = await salesPayment
      .checkRefundTaxInvoiceInfo({
        RefundPaymentType: RefundInfo.RefundPaymentType,
        RefundPrice: RefundInfo.RefundPrice,
        RefundReason: RefundInfo.RefundReason,
        Remark: RefundInfo.Remark,
        InvoiceID: RefundInfo.SalesKeyID,
        SalesPrice: RefundInfo.SalesPrice,
        InvoiceTaxInvoiceType: InvoiceInfo.TaxInvoiceType,
        InvoiceTaxIssueType: InvoiceInfo.TaxIssueType,
        InvoiceSalesKeyIDList: InvoiceInfo.SalesKeyIDList,
        InvoiceTaxType: InvoiceInfo.TaxType,
        InvoiceSalesDate: InvoiceInfo.SalesDate,
        InvoiceSalesPrice: InvoiceInfo.SalesPrice,
        InvoiceTaxPrice: InvoiceInfo.TaxPrice,
        InvoiceUnitPrice: InvoiceInfo.UnitPrice,
        InvoiceItems: InvoiceInfo.ItemListJson,
      })
      .then(res => {
        if (res.isOk) {
        } else {
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
          return Promise.reject('error');
        }
      });
    return checkResult;
  };
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        CostCenterCode,
        SalesType,
        KeyID,
        CardAgencyListName,
        IssueCreditCard,
        CreditCardApprovalNo,
        TransactionDate,
        SalesPrice,
        RefundPaymentType,
        CustomerName,
        TelephoneNo,
        VehicleRegistrationNo,
        RefundPrice,
        RefundReason,
        BankName,
        BankAccountNo,
        AccountHolder,
        CustomerInfo,
        FromDate,
        ToDate,
        Remark,
        RegisterUser,
        ConfirmIMMDED,
      } = formData;
      let invoiceID = '';
      let refundinfo = {
        CostCenterCode,
        SalesType,
        SalesKeyID: KeyID,
        CardAgencyListName,
        IssueCreditCard,
        CreditCardApprovalNo,
        TransactionDate: moment(TransactionDate).format('YYYY-MM-DD'),
        SalesPrice,
        RefundPaymentType,
        CustomerName,
        TelephoneNo,
        VehicleRegistrationNo,
        RefundPrice,
        RefundReason,
        BankName: BankName.trim(),
        BankAccountNo: BankAccountNo.trim(),
        AccountHolder: AccountHolder.trim(),
        CustomerInfo,
        Remark,
        RegisterUser,
        WithConfirm: ConfirmIMMDED,
        RefundInvoiceID: invoiceID,
      };

      if (formData.SalesPrice < formData.RefundPrice) {
        return alert('환불금액을 확인해주세요.');
      }
      // 환불유형이 세금계산서인 경우
      if (tRowdata.length > 0) {
        const isPartialRefund = formData.SalesPrice !== formData.RefundPrice;
        // 전체취소인 경우 (수정세금계산서 발행)
        if (!isPartialRefund) {
          if (formData.RefundPrice !== cancelInvoiceInfo.SalesPrice) {
            return alert('환불금액과 세금계산서 금액이 다릅니다.');
          }
          const checkResult = await CheckRefundInfoWithTaxInvoice({
            InvoiceInfo: { ...cancelInvoiceInfo },
            RefundInfo: { ...refundinfo },
          });
          if (checkResult === 'error') return;

          setLoadPanelVisible(true);
          const insertChangeTaxInvoiceResult = await salesPayment
            .insertChangeTaxInvoice({ ...cancelInvoiceInfo })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                invoiceID = res.data[0][0].InvoiceID;
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message:
                      '취소 세금계산서가 발행되지 않았습니다. 다시 요청해주세요.' +
                      error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
                return Promise.reject('error');
              }
            });
          if (insertChangeTaxInvoiceResult === 'error') return;
        }
        // 부분취소인 경우 (-세금계산서 발행)
        else {
          if (formData.RefundPrice !== issueInvoiceInfo.SalesPrice) {
            return alert('환불금액과 세금계산서 금액이 다릅니다.');
          }
          const { TaxIssueType } = tRowdata[0];
          const checkResult = await CheckRefundInfoWithTaxInvoice({
            InvoiceInfo: { ...issueInvoiceInfo, TaxIssueType },
            RefundInfo: { ...refundinfo },
          });
          if (checkResult === 'error') return;
          setLoadPanelVisible(true);
          if (TaxIssueType === 'A') {
            const insertTaxInvoiceResult = await salesPayment
              .insertTaxInvoice({ ...issueInvoiceInfo, TaxIssueType })
              .then(res => {
                if (res.isOk) {
                  setLoadPanelVisible(false);
                  invoiceID = res.data[0][0].InvoiceID;
                } else {
                  setLoadPanelVisible(false);
                  notify(
                    {
                      message:
                        '취소 세금계산서가 발행되지 않았습니다. 다시 요청해주세요.' +
                        error.errorMsgCheck(res.error.detail),
                      width: 230,
                      position: {
                        at: 'top',
                        my: 'top',
                        of: '#container',
                      },
                    },
                    'error',
                  );
                  return Promise.reject('error');
                }
                onClose();
                refresh();
              });
            if (insertTaxInvoiceResult === 'error') return;
          } else if (TaxIssueType === 'B') {
            const insertTaxInvoiceResult = await salesPayment
              .insertTaxInvoiceReceiving({ ...issueInvoiceInfo, TaxIssueType })
              .then(res => {
                if (res.isOk) {
                  setLoadPanelVisible(false);
                  invoiceID = res.data[0][0].InvoiceID;
                } else {
                  setLoadPanelVisible(false);
                  notify(
                    {
                      message:
                        '취소 세금계산서가 발행되지 않았습니다. 다시 요청해주세요.' +
                        error.errorMsgCheck(res.error.detail),
                      width: 230,
                      position: {
                        at: 'top',
                        my: 'top',
                        of: '#container',
                      },
                    },
                    'error',
                  );
                  return Promise.reject('error');
                }
                onClose();
                refresh();
              });
            if (insertTaxInvoiceResult === 'error') return;
          }
        }
      }
      setLoadPanelVisible(true);
      await salesPayment.insertRefundInfo({ ...refundinfo, RefundInvoiceID: invoiceID, FromDate, ToDate }).then(res => {
        if (res.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '요청 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
          return;
        }
        onClose();
        refresh();
      });
    },
    [formData, cancelInvoiceInfo, issueInvoiceInfo, tSalesRowdata.isPartial, tRowdata],
  );

  const onTaxClick = async () => {
    const keyIDList = tSalesRowdata.data.map(d => d.KeyID);
    const checkApi = await salesPayment.getTaxInvoiceItems({
      SalesKeyIDList: keyIDList.join(';'),
      TaxType: '',
      RefundPrice: formData.RefundPrice,
      InvoiceID: '',
    });
    if (!checkApi.isOk) return alert('오류가 발생했습니다.');
    if (formData.SalesPrice > formData.RefundPrice) {
      addTaxinvoiceRef.current.open(formData.RefundPrice);
    } else if (formData.SalesPrice < formData.RefundPrice) {
      return alert('환불금액을 확인해주세요.');
    } else {
      setIsOpenTaxDetail(true);
    }
  };

  const onFocusIn = e => {
    e.event.target.blur();
  };
  const onOptionChanged = e => {
    if (!formData || e.name !== 'value') return;
    const isCashPayment = formData.RefundPaymentType === 'Cash';
    const isAmountExceedsLimit = e.value >= 1000000;
    setIsToast(isCashPayment && isAmountExceedsLimit);
  };

  const onClickRefundPaymentType = e => {
    const isCash = e.itemData.value === 'Cash';
    setIsCash(isCash);
    setIsToast(isCash && formData.RefundPrice >= 1000000);
  };

  const onFromValueChanged = e => {
    const tempFromDate = e.value;
    setTempDateValue({ ...tempDateValue, TempFromDate: tempFromDate });
  };

  const onToValueChanged = e => {
    const tempToDate = e.value;
    setTempDateValue({ ...tempDateValue, TempToDate: tempToDate });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div id="form-container" className="sales-refund-form">
          <Form
            ref={cancelSalesFormRef}
            formData={formData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem colCount={isCash && isPeriod ? 7 : isCash ? 6 : 4}>
              {/* 매출정보 */}
              <GroupItem colSpan={2}>
                <GroupItem caption="매출 정보">
                  <SimpleItem dataField="CostCenterName" editorOptions={{ readOnly: true }}>
                    <Label text="사업소명" />
                  </SimpleItem>
                  {formData.SalesPrice !== '' ? (
                    <SimpleItem
                      dataField="SalesPrice"
                      editorType="dxNumberBox"
                      editorOptions={{ readOnly: true, format: '#,##0' }}
                    >
                      <Label text="결제금액" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  ) : null}
                  {!tRowdata.length > 0 ? (
                    <SimpleItem
                      dataField="IssueCreditCard"
                      editorType="dxSelectBox"
                      editorOptions={{
                        items: issueCreditCard,
                        acceptCustomValue: true,
                        readOnly: isReadOnly.IssueCreditCard,
                      }}
                    >
                      <Label text="카드사" />
                    </SimpleItem>
                  ) : null}
                  <SimpleItem dataField="CardAgencyListName" editorOptions={{ readOnly: true }}>
                    <Label text="결제사" />
                  </SimpleItem>
                  {!tRowdata.length > 0 && (
                    <SimpleItem
                      dataField="CreditCardApprovalNo"
                      editorOptions={{
                        readOnly: isReadOnly.CreditCardApprovalNo,
                        maxLength: 10,
                      }}
                    >
                      <Label text="카드승인번호" />
                      {formData.PaymentCode === 'Card' && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                  )}
                  <SimpleItem
                    dataField="TransactionDate"
                    editorType="dxDateBox"
                    editorOptions={{
                      type: 'date',
                      dropDownButtonTemplate: 'dropDownButton',
                      displayFormat: 'yyyy-MM-dd',
                      readOnly: formData.TransactionDate ? true : false,
                      icon: true,
                    }}
                  >
                    <Label text="결제일자" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem dataField="CostCenterCode" visible={false} />
                  <SimpleItem dataField="SalesType" visible={false} />
                  <SimpleItem dataField="SalesKeyID" visible={false} />
                </GroupItem>
              </GroupItem>
              {/* 환불정보 */}
              <GroupItem colSpan={isCash && isPeriod ? 3 : 2}>
                <GroupItem caption="환불 정보" colCount={2}>
                  <SimpleItem
                    colSpan={2}
                    dataField="RefundPaymentType"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: refundPaymentType,
                      placeholder: '환불 유형을 선택해주세요',
                      valueExpr: 'value',
                      displayExpr: 'id',
                      onItemClick: onClickRefundPaymentType,
                      readOnly: isReadOnly.RefundPaymentType,
                    }}
                  >
                    <Label text="환불수단" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="CustomerName">
                    <Label text="고객명" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="TelephoneNo">
                    <Label text="고객연락처" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="VehicleRegistrationNo">
                    <Label text="차량번호" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  {isPeriod && (
                    <SimpleItem
                      dataField="FromDate"
                      editorType="dxDateBox"
                      editorOptions={{
                        type: 'date',
                        min: tempDateValue.FromDate,
                        max: tempDateValue.ToDate,
                        dropDownButtonTemplate: 'dropDownButton',
                        displayFormat: 'yyyy-MM-dd',
                        icon: true,
                        isValid: tempDateValue.TempFromDate < tempDateValue.TempToDate,
                        onValueChanged: onFromValueChanged,
                      }}
                    >
                      <Label text="환불대상기간" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  )}
                  {isPeriod && (
                    <SimpleItem
                      label={{ visible: false }}
                      dataField="ToDate"
                      editorType="dxDateBox"
                      editorOptions={{
                        width: '70%',
                        type: 'date',
                        dropDownButtonTemplate: 'dropDownButton',
                        min: tempDateValue.FromDate,
                        max: tempDateValue.ToDate,
                        useMaskBehavior: true,
                        dateOutOfRangeMessage: '시작일보다 빠를 수 없습니다.',
                        displayFormat: 'yyyy-MM-dd',
                        icon: true,
                        isValid: tempDateValue.TempFromDate < tempDateValue.TempToDate,
                        onValueChanged: onToValueChanged,
                      }}
                    >
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  )}
                  <SimpleItem
                    colSpan={2}
                    dataField="RefundPrice"
                    editorType="dxNumberBox"
                    editorOptions={{
                      format: '#,##0',
                      onOptionChanged: onOptionChanged,
                    }}
                  >
                    <Label text="환불금액" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  {tRowdata.length > 0 && (
                    <SimpleItem
                      colSpan={2}
                      editorType="dxTextBox"
                      editorOptions={{
                        onFocusIn: onFocusIn,
                        placeholder: '발행하기를 클릭하여 취소 세금계산서를 작성해 주세요',
                        readOnly: false,
                        buttons: [
                          {
                            name: 'trash',
                            location: 'after',
                            options: {
                              stylingMode: 'text',
                              text: cancelInvoiceInfo || issueInvoiceInfo ? '저장됨' : '발행하기',
                              icon: cancelInvoiceInfo || issueInvoiceInfo ? 'check' : '',
                              type: cancelInvoiceInfo || issueInvoiceInfo ? 'success' : 'normal',
                              onClick: onTaxClick,
                            },
                          },
                        ],
                        value:
                          cancelInvoiceInfo || issueInvoiceInfo
                            ? '매출 취소요청을 완료하면 세금계산서가 수정/취소됩니다.'
                            : '',
                      }}
                    >
                      <Label text="취소 세금계산서" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  )}
                  <SimpleItem
                    colSpan={2}
                    dataField="RefundReason"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: refundReason,
                      placeholder: '환불 사유를 선택해주세요',
                      valueExpr: 'value',
                      displayExpr: 'id',
                    }}
                  >
                    <Label text="환불사유" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="Remark" editorType="dxTextArea">
                    <Label text="비고" />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              {/* 계좌정보 */}
              {isCash ? (
                <GroupItem colSpan={2}>
                  <GroupItem caption="계좌 정보">
                    <SimpleItem dataField="BankName">
                      <Label text="은행명" />
                      <PatternRule
                        pattern={/^[ㄱ-ㅎ가-힣a-zA-Z]+(\s+)?$/}
                        message={'공백/특수문자/숫자는 입력이 불가합니다'}
                      />
                      {isCash && <RequiredRule message="필수값 입니다" />}
                    </SimpleItem>
                    <SimpleItem dataField="BankAccountNo">
                      <Label text="입금계좌" />
                      <PatternRule pattern={/^[\d-]+(\s+)?$/} message={'숫자와 - 기호만 입력 가능합니다.'} />
                      {isCash && <RequiredRule message="필수값 입니다" />}
                    </SimpleItem>
                    <SimpleItem dataField="AccountHolder">
                      <Label text="예금주" />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="CustomerInfo">
                      <Label text="고객과의 관계" />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
              ) : null}
            </GroupItem>
            <GroupItem>
              <ButtonItem>
                <ButtonOptions type={'default'} useSubmitBehavior={true} text="요청" />
              </ButtonItem>
            </GroupItem>
            <GroupItem></GroupItem>
          </Form>
          <Popup visible={isOpenTaxDetail} showCloseButton={true} showTitle={false} width="50%" height={890}>
            <ScrollView>
              <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
                <DetailHistoryToCancel
                  ref={detailHistoryRef}
                  refresh={refresh}
                  setIsOpen={setIsOpenTaxDetail}
                  setLoadPanelVisible={setLoadPanelVisible}
                  setCancelInvoiceInfo={setCancelInvoiceInfo}
                  formData={taxDataSource}
                  dataSource={itemDataSource}
                />
              </div>
            </ScrollView>
          </Popup>
          <Popup visible={isOpenTaxIssue} showCloseButton={true} showTitle={false} width="50%" height={840}>
            <ScrollView>
              <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
                <AddTaxinvoice
                  ref={addTaxinvoiceRef}
                  setIsOpen={setIsOpenTaxIssue}
                  selectData={tSalesRowdata.data}
                  refresh={refresh}
                  setLoadPanelVisible={setLoadPanelVisible}
                  setIssueInvoiceInfo={setIssueInvoiceInfo}
                  tRowdata={tRowdata}
                  popup={popup}
                />
              </div>
            </ScrollView>
          </Popup>
        </div>
        <Toast
          visible={isToast}
          width={230}
          height={50}
          type="custom"
          contentRender={contentRender}
          onHiding={onHiding}
          displayTime={7000}
        >
          <Position my="top" at="top" of="#form-container" offset="0 -15" />
        </Toast>
      </form>
    </>
  );
};

export default React.memo(CancelSalesForm);
