import React from 'react';
import ExcelImport from 'common/excel-import';

const ExcelImportInDataGrid = ({ fieldColumn, handleImport, fieldColumnType }) => {
  return (
    <div style={{ height: '60px', width: '360px' }}>
      <ExcelImport fieldColumn={fieldColumn} handleImport={handleImport} fieldColumnType={fieldColumnType} />
    </div>
  );
};

export default ExcelImportInDataGrid;
