import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { Button } from 'devextreme-react/button';
import { aggregation, error } from '../../../api';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';

import CommonDataGrid from 'common/default-data-grid';
import { Column, Scrolling, Selection, TotalItem, Summary } from 'devextreme-react/data-grid';
import DataGrid, { FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { getNormalDateFormat } from 'utils/common';

const MonthlyConfirmDataView = () => {
  const thisMonth = moment(new Date()).format('yyyy-MM');
  const [salesMonth, setDateValue] = useState(thisMonth);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchData, setSearchData] = useState({
    SalesMonth: '',
  });

  const [selectedIDSeq, setSelectedIDSeq] = useState('');
  const gridRef = useRef({});

  useEffect(() => {
    (async () => {
      setLoadPanelVisible(true);
      const result = await aggregation.getSAPCofirmationDataMonthlyDetail(searchData);
      if (result.isOk) {
        setDataSource(result.data[0]);
      }
      setLoadPanelVisible(false);
    })();
  }, [searchData]);

  const callSearchAPI = () => {
    setSearchData({
      SalesMonth: salesMonth,
    });
  };

  const onSearch = e => {
    callSearchAPI();
  };

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const handleSalesMonth = e => {
    const salesMonth = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setDateValue(salesMonth);
  };
  
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    // cellStatus(data, displayValue, cellElement);
  }, []);

  return (
    <React.Fragment>
      <div className="content-block detail-title left-right-header">
        <h2> Hi-DMS 월마감 데이터 상세 조회</h2>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div style={{ paddingTop: '5px' }}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            onCellPrepared={onCellPrepared}
            loadPanel={{
              enabled: true,
              position: {
                of: '#test',
                // at: 'center',
                // my: 'center',
              },
            }}
            stylingMode="outlined"
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: 'Hi-DMS 월매출 상세.xlsx',
            }}
            headerBefore={[
              <div className="label">매출월 :</div>,
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                dataField="SalesMonth"
                value={salesMonth}
                width={100}
                onValueChanged={handleSalesMonth}
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            {/* <Editing
              mode="batch"
              allowUpdating={true}
              selectTextOnEditStart={true}
              startEditAction={'click'}
              texts={{
                saveAllChanges: '저장하기',
                cancelAllChanges: '되돌리기',
              }}
            /> */}
            <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
            <Selection mode="single" />
            <HeaderFilter visible={true} />
            <Column dataField={'DocumentType'} caption={'회계매출유형'} alignment={'center'} />
            <Column dataField={'DocumentNo'} caption={'SAPDocumentNo'} alignment={'center'} />
            <Column dataField={'P코드'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'사업소명'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'수금일'} caption={'수금일'} alignment={'center'} />
            <Column dataField={'매출일'} caption={'매출일'} alignment={'center'} />
            <Column dataField={'원매출일'} caption={'원매출일'} alignment={'center'} />
            <Column dataField={'매출항목'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'매출항목상세'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'정상/반품'} caption={'정상/반품'} alignment={'center'} />
            <Column dataField={'수금구분'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'결제사'} caption={'결제사'} alignment={'center'} />
            <Column dataField={'매출수량'} caption={'매출수량'} alignment={'right'} />
            <Column dataField={'합계금액'} caption={'합계금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'대사날짜'} caption={'대사날짜'} alignment={'center'} />
            <Column dataField={'대사ID'} caption={'대사ID'} alignment={'center'} />

            <Column dataField={'매출대상기간From'} caption={'매출대상기간From'} alignment={'center'} />
            <Column dataField={'매출대상기간To'} caption={'매출대상기간To'} alignment={'center'} />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'차량/고객'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'SAP사업자코드'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'사업자번호'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'사업자명'} caption={'사업자명'} alignment={'left'} />
              <Column dataField={'업종'} caption={'업종'} alignment={'center'} />
              <Column dataField={'업태'} caption={'업태'} alignment={'center'} />
            </Column>
            <Column caption={'세금계산서 정보'} alignment={'center'}>
              <Column
                dataField={'TaxInvoiceStatusName'}
                caption={'처리상태'}
                width={110}
              />
              <Column dataField={'계산서유형'} caption={'계산서유형'} alignment={'center'} />
              <Column dataField={'발행요청일'} caption={'발행요청일'} alignment={'center'} />
              <Column dataField={'과세유형'} caption={'과세유형'} alignment={'center'} />
              <Column dataField={'발행유형'} caption={'발행유형'} width={100} />
              <Column dataField={'HiDMS 계산서번호'} caption={'HiDMS 계산서번호'} alignment={'center'} />
              <Column dataField={'세금계산서번호'} caption={'세금계산서번호'} alignment={'center'} />
              <Column dataField={'계산서 SAP사업자코드'} caption={'계산서 SAP사업자코드'} alignment={'left'} />
              <Column dataField={'계산서 사업자번호'} caption={'계산서 사업자번호'} alignment={'left'} />
              <Column dataField={'계산서 사업자명'} caption={'계산서 사업자명'} alignment={'left'} />
              <Column dataField={'계산서 업종'} caption={'계산서 업종'} alignment={'center'} />
              <Column dataField={'계산서 업태'} caption={'계산서 업태'} alignment={'center'} />
            </Column>

            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}

            <Column dataField={'등록자'} caption={'등록자'} alignment={'center'} />
            <Column dataField={'비고'} caption={'비고'} alignment={'center'} />
            <Summary>
              <TotalItem column="매출수량" summaryType="sum" displayFormat="총 {0}건" />
              <TotalItem column="합계금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              <TotalItem column="잔여금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
          </div>
          <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.content' }} visible={loadPanelVisible} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthlyConfirmDataView;
