export const columns = [
  '사이트코드',
  '구분',
  '분류',
  '승인일자',
  '원거래일자',
  '주문번호',
  '주문자',
  '카드사',
  '승인번호',
  '거래금액',
  '수수료',
  '가상계좌수수료',
  '수익분배',
  '지급예정액',
  '거래상태',
  '거래번호',
  '상품명',
  '파일링주차장명',
];

export const sampleData = [
  {
    사이트코드: 'A0001',
    구분: 'PG',
    분류: 'KCP',
    승인일자: '2023-01-01',
    원거래일자: '2023-01-01',
    주문번호: '00104747005003939111',
    주문자: '123가1234',
    카드사: '삼성카드',
    승인번호: '48711353',
    거래금액: 5000,
    수수료: 209,
    가상계좌수수료: 0,
    수익분배: 0,
    지급예정액: 14373,
    거래상태: '매입요청',
    거래번호: '23251267089100',
    상품명: '아이파킹 주차비',
    파일링주차장명: '서울복합물류센터',
  },
  {
    사이트코드: 'A0001',
    구분: 'PG',
    분류: 'KCP',
    승인일자: '2023-01-02',
    원거래일자: '2023-01-02',
    주문번호: '00104747005003939112',
    주문자: '567가5678',
    카드사: '국민카드',
    승인번호: '48711350',
    거래금액: 2000,
    수수료: 84,
    가상계좌수수료: 0,
    수익분배: 0,
    지급예정액: 1916,
    거래상태: '매입요청',
    거래번호: '23251267089200',
    상품명: '아이파킹 정기권',
    파일링주차장명: '서울복합물류센터',
  },
];
