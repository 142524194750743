import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const TaxInvoicePayment = props => {
  const { dataSource } = props;
  const gridRef = useRef({});

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  useEffect(() => {}, []);
  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        allowSelectAll={true}
        height={580}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'SAP연동 세금계산서수금.xlsx',
        }}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" /> <HeaderFilter visible={true} />
        <Column dataField={'Hi-DMS계산서번호'} caption={'Hi-DMS계산서번호'} />
        <Column dataField={'수금SEQ'} caption={'수금SEQ'} />
        <Column dataField={'SAP회계연도'} caption={'SAP회계연도'} />
        <Column dataField={'SAP전표번호'} caption={'SAP전표번호'} />
        <Column dataField={'발행요청일'} caption={'발행요청일'} />
        <Column dataField={'코객코드'} caption={'코객코드'} />
        <Column dataField={'고객명'} caption={'고객명'} />
        <Column dataField={'SAP코스트센터'} caption={'SAP코스트센터'} />
        <Column dataField={'P코드'} caption={'P코드'} />
        <Column dataField={'코스트센터명'} caption={'코스트센터명'} />
        <Column dataField={'결제사'} caption={'결제사'} />
        <Column dataField={'결제일'} caption={'결제일'} />
        <Column dataField={'결제시간'} caption={'결제시간'} />
        <Column dataField={'금액'} caption={'금액'} format="#,##0 원" alignment="right" />
        <Column dataField={'은행코드'} caption={'은행코드'} />
        <Column dataField={'은행계좌번호'} caption={'은행계좌번호'} />
        <Column dataField={'실물계좌()'} caption={'실물계좌()'} />
        <Column dataField={'수금완료(X)'} caption={'수금완료(X)'} />
        <Summary>
          <TotalItem column="Hi-DMS계산서번호" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(TaxInvoicePayment);
