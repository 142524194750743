import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import {
  Column,
  Scrolling,
  HeaderFilter,
  Selection,
  TotalItem,
  Summary,
  Editing,
  Paging,
  StringLengthRule,
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { aggregation, error } from '../../../api';
import { CheckBox } from 'devextreme-react/check-box';
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { suspenseReceiptManualLink } from 'constants/hyperlink';
import { cellStatus } from './common';

import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import axios from 'axios';

import './suspense-receipts-mng.scss';

const SuspenseReceiptsMng = () => {
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [searchCenter, setSearchCenter] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');

  const [searchData, setSearchData] = useState({
    FromDate: dateValue.FromDate,
    ToDate: dateValue.ToDate,
    SearchCostCenter: '',
    SearchApprovalNo: '',
  });
  const [selectedID, setSelectedID] = useState('');
  const [dataSourcePayment, SetDataSourcePayment] = useState('');
  const [isInquiryOnlyRemain, setIsInquiryOnlyRemain] = useState(false);
  const gridRef = useRef({});

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'ReceiptID',
      loadMode: 'raw',
      update: async (key, values) => {
        setLoadPanelVisible(true);
        try {
          const result = await axios.post(`aggregation/updateSuspenseReceiptByID`, {
            TaxInvoiceNo: values.TaxInvoiceNo,
            ReceiptID: key,
            SupervisorRemark: values.SupervisorRemark,
          });
          notify(
            {
              message: '변경 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setLoadPanelVisible(false);
          return result.data;
        } catch (e) {
          notify(
            {
              message: error.errorMsgCheck(e.response.data.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      },
      load: async () => {
        const result = await aggregation.getSuspenseReceiptsList(searchData);
        return result.data;
      },
    });

    return customDataSource;
  }, [searchData]);

  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  const onChangeApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };

  const onEnterKey = e => {
    callAPI();
  };

  const callAPI = () => {
    const { FromDate, ToDate, OnlyRemain } = isInquiryOnlyRemain
      ? { FromDate: '', ToDate: '', OnlyRemain: 'Y' }
      : dateValue;
    setSearchData({
      ...searchData,
      FromDate,
      ToDate,
      OnlyRemain,
      SearchCostCenter: searchCenter.trim(),
      SearchApprovalNo: searchApprovalNo.trim(),
    });
  };

  const onSearch = e => {
    callAPI();
  };

  const onCancel = useCallback(
    async e => {
      // 취소 버튼 클릭 시
      const selectedItems = gridRef.current.instance.getSelectedRowsData();
      const { ReceiptID } = selectedItems[0];
      const result = confirm('<i> 가수금 등록을 취소하시겠습니까? </i>', '확인');
      result.then(async dialogResult => {
        if (dialogResult) {
          await aggregation.cancelSuspenseReceiptByID({ ReceiptID: ReceiptID }).then(res => {
            if (res.isOk) {
              notify(
                {
                  message: '취소되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              callAPI();
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
        }
      });
    },
    [dateValue, isInquiryOnlyRemain],
  );

  useEffect(() => {
    SetDataSourcePayment(null);
    (async () => {
      if (selectedID !== '') {
        await axios
          .post(`aggregation/history/getPaymentHistoryByKeyID`, {
            KeyID: selectedID,
            AccountingDate: '',
          })
          .then(res => {
            SetDataSourcePayment(res.data[0] ? res.data[0] : null);
          });
      }
    })();
  }, [selectedID]);

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onSelectionChanged = e => {
    if (e.selectedRowsData[0]) {
      setSelectedID(e.selectedRowsData[0].ReceiptID);
    }
  };
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onCheckValueChanged = e => {
    setIsInquiryOnlyRemain(e.value);
  };

  return (
    <div>
      <div className="content-block detail-title left-right-header">
        <h2> 가수금 관리</h2>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={suspenseReceiptManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
        </div>
      </div>

      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings column-fix-grid'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            onSelectionChanged={onSelectionChanged}
            onCellPrepared={onCellPrepared}
            loadPanel={{
              enabled: true,
            }}
            stylingMode="outlined"
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '가수금 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">결제일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
                disabled={isInquiryOnlyRemain}
              />,
              <span>~</span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                disabled={isInquiryOnlyRemain}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TextBox
                name="SearchCenter"
                labelMode="static"
                stylingMode="outlined"
                placeholder="사업소명/P-코드/사업소코드"
                onValueChanged={onChangeCenter}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchApprovalNo"
                labelMode="static"
                stylingMode="outlined"
                placeholder="승인번호/카드번호/계좌번호"
                onValueChanged={onChangeApprovalNo}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
            ]}
            headerAfter={[
              <CheckBox
                text="미처리 가수금 전체 조회 "
                onValueChanged={onCheckValueChanged}
                hint="기간에 상관없이 미처리 가수금을 전체 조회합니다."
              />,
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
              <Button
                icon="minus"
                text="등록 취소"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={onCancel}
              />,
              <Button
                icon="refresh"
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  onSearch();
                }}
              />,
            ]}
          >
            <Editing
              mode="batch"
              allowUpdating={true}
              selectTextOnEditStart={true}
              startEditAction={'click'}
              texts={{
                saveAllChanges: '저장하기',
                cancelAllChanges: '되돌리기',
              }}
            />
            <Paging enabled={false} />
            <Scrolling mode="standard" showScrollbar="always" useNative={true} />
            <Selection mode="single" />
            <HeaderFilter visible={true} />
            <Column dataField={'ReceiptID'} caption={'가수금ID'} visible={false} />
            <Column
              dataField={'HiParkingID'}
              caption={'P코드'}
              width={88}
              allowEditing={false}
              fixed={true}
              alignment="center"
            />
            <Column
              dataField={'CostCenterName'}
              caption={'사업소명'}
              width={115}
              allowEditing={false}
              fixed={true}
              alignment={'center'}
            />
            <Column
              dataField={'TransactionDate'}
              caption={'결제일'}
              customizeText={customizeText}
              width={150}
              allowEditing={false}
            />
            <Column dataField={'PaymentCodeName'} caption={'수금구분'} width={84} allowEditing={false} />
            <Column dataField={'PaymentAgencyName'} caption={'결제사'} width={82} allowEditing={false} />
            <Column
              dataField={'StatusName'}
              caption={'상태'}
              width={73}
              allowEditing={false}
              fixed={true}
              alignment="left"
              // cellRender={statusCellRender}
            />
            <Column
              dataField={'TransactionAmount'}
              caption={'가수금금액'}
              format="#,##0 원"
              width={112}
              allowEditing={false}
              fixed={true}
            />
            <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" width={112} allowEditing={false} />
            <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={112} allowEditing={false} />
            <Column
              dataField={'RefundPrice'}
              caption={'환불금액'}
              format="#,##0 원"
              width={112}
              alignment={'right'}
              allowEditing={false}
            />
            <Column
              dataField={'SapHandledPrice'}
              caption={'SAP처리비'}
              format="#,##0 원"
              width={112}
              allowEditing={false}
            />
            <Column dataField={'Remark'} caption={'비고'} width={200} allowEditing={false} />
            <Column dataField={'SupervisorRemark'} caption={'관리자 비고'} width={200} allowEditing={true}>
              <StringLengthRule max={200} message={'입력값의 허용 길이를 초과했습니다.'} />
            </Column>
            <Column dataField={'CardTypeName'} caption={'카드유형'} width={86} allowEditing={false} />
            <Column dataField={'BankAccountNo'} caption={'계좌번호'} width={125} allowEditing={false} />
            <Column dataField={'BankRemark'} caption={'계좌입금자명'} width={125} allowEditing={false} />
            <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={93} allowEditing={false} />
            <Column dataField={'CreditCardNo'} caption={'카드번호'} width={125} allowEditing={false} />

            <Column
              dataField={'RegisterDate'}
              caption={'등록일'}
              customizeText={customizeText}
              width={140}
              allowEditing={false}
            />
            <Column dataField={'RegisterUserName'} caption={'등록자'} width={87} allowEditing={false} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} allowEditing={false} />
            <Summary>
              <TotalItem column="TransactionDate" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="TransactionAmount" valueFormat="합계:" />
              <TotalItem column="TransactionAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
              <TotalItem column="RemainAmount" valueFormat="합계:" />
              <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
              <TotalItem column="SalesPrice" valueFormat="합계:" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
              <TotalItem column="RefundPrice" valueFormat="합계:" />
              <TotalItem column="RefundPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
            </Summary>
          </CommonDataGrid>
          <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
            <h3 style={{ margin: '0px 5px' }}>매출 연결 정보</h3>
            <CommonDataGrid
              className={'dx-card wide-card'}
              dataSource={dataSourcePayment}
              showBorders={true}
              columnAutoWidth={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              height={350}
              isNotFilterRow
              isInfiniteScroll={false}
              isHeader={false}
              isExcel={false}
              excelOptions={{
                sheetName: 'Sheet',
                fileName: '가수금 매출 이력.xlsx',
              }}
            >
              <Scrolling showScrollbar="always" useNative={true} />
              {/* <Selection mode="single" selectAllMode="pages" /> */}
              {/* <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" /> */}
              <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
              <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
              {/* <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} /> */}
              <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} customizeText={customizeText} />
              <Column dataField={'PaymentAmount'} caption={'반제금액'} alignment={'center'} format="#,##0 원" />
              <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
              <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
              <Column dataField={'AccountingType'} caption={'대사구분'} alignment={'center'} />
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
              <Column
                dataField={'RegisterDate'}
                caption={'대사등록일'}
                alignment={'center'}
                customizeText={customizeText}
              />
              <Summary>
                <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
                <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                <TotalItem column="PaymentAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>{' '}
        <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.content' }} visible={loadPanelVisible} />
      </div>
    </div>
  );
};

export default SuspenseReceiptsMng;
