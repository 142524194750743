import { React, useMemo, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { PivotGrid, FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import { Column, Pager, Paging, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { aggregation } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './view-sales-lambda-history.scss';
import Bullet, { Font, Margin, Size, Tooltip } from 'devextreme-react/bullet';
import {
  Chart,
  Series,
  SeriesTemplate,
  Label,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Export,
  Tooltip as ChartTooltip,
} from 'devextreme-react/chart';

const ViewSalesLambdaHistory = () => {
  const gridRef = useRef({});
  const maingridRef = useRef({});
  const allgridRef = useRef({});
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });
  const [search, setSerch] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
  });
  const [sumData, setSumData] = useState();
  const [chartData, setChartData] = useState();
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useMemo(async () => {
    try {
      setLoadPanelVisible(true);
      const result = await aggregation.getParkingInOutLambdaLogSummary(search);
      setSumData(result.data[0]);
      setChartData(result.data[1]);
      setLoadPanelVisible(false);
    } catch (e) {
      setLoadPanelVisible(false);
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  }, [search]);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        const result = await aggregation.getParkingInOutLambdaLog(search);
        return result.data[0];
      },
    });

    return customDataSource;
  }, [search]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const dataSourcedetail = useMemo(() => {
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        const result = await aggregation.getParkingInOutLambdaLogDetail(search);
        return result.data[0];
      },
    });

    return customDataSource;
  }, [search]);

  const onSearch = () => {
    setSerch({
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
    });
  };
  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    if (data) {
      const { Result } = data;
      const tr = cellElement;
      if (Result.includes('실패') && tr) {
        cellElement.style.backgroundColor = 'rgb(252 231 231)';
        cellElement.style.color = 'rgb(236 74 74)';
      }
    }
  }, []);

  const onCellPreparedDetail = useCallback(({ data, displayValue, cellElement }) => {
    if (data) {
      const { Result } = data;
      const tr = cellElement;
      if (Result.includes('실패') && tr) {
        cellElement.style.backgroundColor = 'rgb(252 231 231)';
        cellElement.style.color = 'rgb(236 74 74)';
      }
    }
  }, []);

  const editCellRender = e => {
    return (
      <Bullet
        showTarget={true}
        showZeroLevel={false}
        value={e.data.연동율}
        startScaleValue={0}
        endScaleValue={100}
        target={100}
      >
        <Size width={80} height={30} />
        <Margin top={5} bottom={0} left={5} />
        {/* <Tooltip enabled={true} paddingTopBottom={2} zIndex={5}>
          <Font size={18} />
        </Tooltip> */}
      </Bullet>
    );
  };

  function customizeSeries(e) {
    console.log(e);
    return e === '성공'
      ? { type: 'bar', label: { visible: true }, color: '#10B5D6' }
      : { type: 'bar', label: { visible: true }, color: '#FB7511' };
  }
  return (
    <div>
      <h2 className={'content-block'}>사업소 관제 연동 이력</h2>
      <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
        <div className={'dx-card responsive-paddings'} style={{ marginBottom: '10px', width: '65%' }}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={sumData}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={450}
            focusedRowEnabled={false}
            errorRowEnabled={false}
            // keyExpr="KeyID"
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '관제 연동 이력 집계.xlsx',
            }}
            headerBefore={[
              <div className="label">대상날짜 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                onValueChanged={handleToDate}
                value={dateValue.toDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
            ]}
            headerAfter={[
              // <CheckBox defaultValue={false} text="미결 포함    " onValueChanged={onincludeAllCheckValueChanged} />,
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'CalDate'} caption={'날짜'} alignment={'center'} />
            <Column dataField={'연결대상'} caption={'연결대상사업소'} alignment={'right'} />
            <Column dataField={'성공'} caption={'성공'} alignment={'right'} />
            <Column dataField={'실패(에러)'} caption={'실패(에러)'} alignment={'right'} />
            <Column dataField={'실패(접속불가)'} caption={'실패(접속불가)'} alignment={'right'} />
            <Column dataField={'연동율(%)'} caption={'연동율(%)'} alignment={'right'} format="#.00 %" />
            <Column
              dataField="연동율(%)"
              caption="연동율"
              dataType="number"
              format="percent"
              alignment="right"
              allowGrouping={false}
              cellRender={editCellRender}
              cssClass="bullet"
            />
          </CommonDataGrid>
        </div>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '32%' }}>
          <div className="caption">관제 별 집계</div>
          <div style={{ padding: '20px' }}>
            <Chart id="chart" dataSource={chartData} rotated={true}>
              <Title text="마지막 대상 날짜 기준">{/* <Subtitle text="(in millions tonnes)" /> */}</Title>
              <Size width={400} height={500} />
              <SeriesTemplate
                nameField="Result"
                type="bar"
                color="#79cac4"
                customizeSeries={customizeSeries}
              ></SeriesTemplate>
              <CommonSeriesSettings
                argumentField="Vendor"
                valueField="Cnt"
                type="stackedBar"
                label={{
                  visible: true,
                  backgroundColor: 'rgba(0,0,0,0)',
                  font: { color: 'rgb(118,118,118)', size: 12 },
                  showForZeroValues: true,
                }}
              />
              <ValueAxis position="right">
                <Title text="관제 구분" />
              </ValueAxis>
              <Legend visible={true} verticalAlignment="center" horizontalAlignment="right" itemTextPosition="right" />
              <Export enabled={true} />
              {/* <Tooltip enabled={true} location="edge" customizeTooltip={this.customizeTooltip} /> */}
            </Chart>
          </div>
        </div>
      </div>
      <div className={'content-block'}>
        <h3 className={'content-block'}>상세 이력</h3>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <TabPanel>
            <Item title="사업소 별">
              <div className={'dx-card'}>
                <CommonDataGrid
                  gridRef={maingridRef}
                  className={'dx-card wide-card lambda-history'}
                  dataSource={dataSource}
                  showBorders={true}
                  allowColumnResizing={true}
                  columnAutoWidth={false}
                  columnHidingEnabled={false}
                  hoverStateEnabled={true}
                  height={650}
                  isHeader={true}
                  isExcel={true}
                  excelOptions={{
                    sheetName: 'sheet',
                    fileName: '관제연동이력_최종.xlsx',
                  }}
                  onCellPrepared={onCellPrepared}
                >
                  <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
                  <Column dataField={'TargetDate'} caption={'연동일'} width={100} />
                  <Column dataField={'HiParkingID'} caption={'P코드'} width={80} />
                  <Column dataField={'CostCenterName'} caption={'사업소명'} width={180} />
                  <Column dataField={'VendorName'} caption={'관제시스템공급사'} />
                  <Column dataField={'SWVersionName'} caption={'SW버전'} width={100} />
                  <Column dataField={'Result'} caption={'결과'} width={100} />
                  <Column dataField={'InterfaceRemark'} caption={'메세지'} width={500} />
                  <Column dataField={'LambdaFunctionName'} caption={'AWS람다함수명'} />
                  <Summary>
                    <TotalItem column="Result" summaryType="count" displayFormat="총 {0}건" />
                  </Summary>
                </CommonDataGrid>
              </div>
            </Item>
            <Item title="전체 이력">
              <div className={'dx-card'}>
                <CommonDataGrid
                  gridRef={allgridRef}
                  className={'dx-card wide-card'}
                  dataSource={dataSourcedetail}
                  showBorders={true}
                  columnAutoWidth={true}
                  columnHidingEnabled={true}
                  hoverStateEnabled={true}
                  height={650}
                  isHeader={true}
                  isExcel={true}
                  excelOptions={{
                    sheetName: 'sheet',
                    fileName: '관제연동이력_전체.xlsx',
                  }}
                  onCellPrepared={onCellPreparedDetail}
                >
                  <Scrolling mode="infinite" />
                  <Column dataField={'TargetDate'} caption={'연동일'} />
                  <Column dataField={'StartDateTime'} caption={'연동시작시간'} customizeText={customizeText} />
                  <Column dataField={'EndDateTime'} caption={'연동종료시간'} customizeText={customizeText} />
                  <Column dataField={'HiParkingID'} caption={'P코드'} />
                  <Column dataField={'CostCenterName'} caption={'사업소명'} />
                  <Column dataField={'VendorName'} caption={'관제시스템공급사'} />
                  <Column dataField={'SWVersionName'} caption={'SW버전'} />
                  <Column dataField={'Result'} caption={'결과'} />
                  <Column dataField={'InterfaceRemark'} caption={'메세지'} />
                  <Column dataField={'LambdaFunctionName'} caption={'AWS람다함수명'} />
                </CommonDataGrid>
              </div>
            </Item>
          </TabPanel>
        </div>
      </div>
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.dx-popup-wrapper' }} visible={loadPanelVisible} />
    </div>
  );
};

export default ViewSalesLambdaHistory;
