import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import Form from 'devextreme-react/form';

const Save = () => {
  const history = useHistory();

  const [notes, setNotes] = useState(
    'Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.',
  );
  const employee = {
    ID: 7,
    FirstName: 'Sandra',
    LastName: 'Johnson',
    Prefix: 'Mrs.',
    Position: 'Controller',
    Picture: 'images/employees/06.png',
    BirthDate: new Date('1974/11/5'),
    HireDate: new Date('2005/05/11'),
    Notes: notes,
    Address: '4600 N Virginia Rd.',
  };

  return (
    <>
      <h3 className={'content-block'}>
        <div style={{ display: 'flex' }}>
          <div style={{ fontWeight: 'bold' }}>규제 등록</div>
        </div>
      </h3>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Form
            id={'form'}
            defaultFormData={employee}
            onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
            labelLocation={'top'}
            colCountByScreen={colCountByScreen}
          />
        </div>
        <Button
          text="리스트로 돌아가기"
          onClick={() => {
            history.push('/compliance');
          }}
        />
      </div>
    </>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

export default Save;
