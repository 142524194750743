import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { makePaysysStatusCellRender } from 'common/popup/ales-common-util';
import { Column, Selection, Scrolling, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Button } from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { autoDataSource } from '../api/costCenterData';
import { master, ticket, salesPayment, error } from 'api';
import { etcSalesMngManualLink } from 'constants/hyperlink';
import { CheckBox } from 'devextreme-react';
import DateBox from 'devextreme-react/date-box';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import CommonDataGrid from 'common/default-data-grid';
import PaymentForm from 'common/popup/payment-form';
import PaymentDetailForm from 'common/popup/payment-detail-form';
import './view-paysys-requests.scss';

const ViewPaysysRequestStatusPage = () => {
  const gridRef = useRef({});
  const addSaleMasterRef = useRef({});
  const paymentRef = useRef({});
  const paymentDetailRef = useRef({});
  const [autoText, setAutoText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [onlyExpired, setOnlyExpired] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [paymentPopup, setPaymentPopup] = useState({
    save: false,
    visible: false,
  });
  const [paymentDetailPopup, setPaymentDetailPopup] = useState({
    save: false,
    visible: false,
  });
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchParams, setSearchParams] = useState({
    CostCenterCode: '',
    CostCenterName: '',
    PaysysGoodsType: '',
    SearchText: '',
    FromDate: dateValue.FromDate,
    ToDate: dateValue.ToDate,
    OnlyExpired: onlyExpired,
  });

  const [etcType, setEtcType] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const [paymentType, setPaymentType] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    PaysysGoodsType();
  }, []);

  const handleResend = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    const ticketPayInfo = [];
    selectedItems.forEach(r => {
      ticketPayInfo.push(r.PayRequestStatusName);
    });
    const payedticket = ticketPayInfo.some(val => ['결제완료', '환불', '요청취소'].includes(val));
    const payRequestTicket = ticketPayInfo.some(val => ['요청완료', '요청대기'].includes(val));
    if (selectedItems.length === 0) {
      alert('재요청할 대상을 선택해주세요.');
    } else if (payedticket) {
      alert('이미 결제/취소된 매출이 포함되었습니다.');
    } else if (payRequestTicket) {
      alert('이미 결제 요청 완료된 매출이 포함되었습니다.');
    } else {
      const result = confirm('<i>요청하시겠습니까?</i>', '확인');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          const retryRequest = async (params, retryCount = 0) => {
            try {
              const res = await ticket.registerPayRequestInfo(params);
              if (res.isOk) {
                return { success: true, data: res };
              }
              // else if (retryCount < 2) {
              //   return retryRequest(params, retryCount + 1);
              // }
              else {
                return { success: false, error: res };
              }
            } catch (error) {
              // console.log('error1', error);
              return { success: false, error };
            }
          };

          const requests = selectedItems.map(item =>
            retryRequest({
              TicketID: item.TicketID,
              RegisterUserMail: item.RegisterUserMail,
              CostCenterCode: item.CostCenterCode,
              CustomerName: item.CustomerName,
              MobilePhoneNo: item.MobilePhoneNo,
              EmailAddress: item.EmailAddress,
              PayType: item.PayType,
              GoodsType: item.GoodsType,
              GoodsName: item.GoodsName,
              VehicleRegistrationNo: item.VehicleRegistrationNo,
              FromDate: item.FromDate,
              ToDate: item.ToDate,
              UnitPrice: item.UnitPrice,
              Unit: item.Unit,
              TotalPrice: item.TotalPrice,
              FixAccountBankCode: item.FixAccountBankCode,
              FixAccountStartDate: item.FixAccountStartDate,
              FixAccountEndDate: item.FixAccountEndDate,
            }),
          );

          Promise.allSettled(requests)
            .then(results => {
              setLoadPanelVisible(false);
              const successCount = results.filter(r => r.value && r.value.success).length;
              const failCount = selectedItems.length - successCount;
              const failedResults = results.filter(r => !r.value || !r.value.success);

              notify(
                {
                  message: `처리 완료: ${successCount}건 성공, ${failCount}건 실패`,
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                successCount > 0 ? 'success' : 'error',
              );

              if (failCount > 0) {
                console.log('Failed requests:', failedResults);
                // 여기에 실패한 요청에 대한 추가 처리 로직을 추가할 수 있습니다.
              }

              if (successCount > 0) {
                refresh();
              }
            })
            .catch(error => {
              console.log('Unexpected error:', error);
              setLoadPanelVisible(false);
              notify(
                {
                  message: '처리 중 예기치 않은 오류가 발생했습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            });
        }
      });
    }
  };

  const payRequestStatusCellRender = useCallback(e => {
    return makePaysysStatusCellRender(e);
  }, []);

  const PaysysGoodsType = async () => {
    await master.searchCodeMaster({ CodeGroup: ['PaysysGoodsType', 'CustomerType', 'PaymentType'] }).then(res => {
      if (res.isOk) {
        const etcType = [];
        const customerType = [];
        const paymentType = [];

        res.data?.forEach(t => {
          if (t.CodeGroup === 'PaysysGoodsType') {
            etcType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          } else if (t.CodeGroup === 'CustomerType') {
            customerType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          } else if (t.CodeGroup === 'PaymentType') {
            paymentType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          }
        });
        setEtcType(etcType);
        setCustomerType(customerType);
        setPaymentType(paymentType);
      }
    });
  };

  const onCheckValueChanged = e => {
    setOnlyExpired(e.value);
    setSearchParams({
      ...searchParams,
      OnlyExpired: e.value,
    });
  };
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'TicketID',
      loadMode: 'raw',
      load: async () => {
        const { CostCenterCode, PaysysGoodsType, SearchText, FromDate, ToDate, OnlyExpired } = searchParams;
        const result = await ticket.getPaysysRequestByDate({
          CostCenterCode,
          PaysysGoodsType,
          SearchText,
          FromDate,
          ToDate,
          OnlyExpired,
        });
        return result.data;
      },
    });

    return customDataSource;
  }, [searchParams]);

  const onValueChanged = e => {
    setAutoText(e.value);
    setSearchParams({
      ...searchParams,
      CostCenterCode: e.value,
    });
  };
  const onItemClick = e => {
    const { CostCenterCode, CostCenterName } = e.itemData;
    setSearchParams({
      ...searchParams,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      CostCenterCode,
      CostCenterName,
      OnlyExpired: onlyExpired,
    });
  };

  const onChangeCustomer = e => {
    setSearchText(e.value);
    setSearchParams({
      ...searchParams,
      SearchText: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchParams({
      ...searchParams,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      CostCenterCode: autoText === null ? '' : searchParams.CostCenterCode,
      CostCenterName: autoText === null ? '' : searchParams.CostCenterName,
      SearchText: searchText.trim(),
      OnlyExpired: onlyExpired,
    });
  };

  const onClickGoodsType = e => {
    const { value } = e.itemData;
    setSearchParams({
      ...searchParams,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      PaysysGoodsType: value,
      OnlyExpired: onlyExpired,
    });
  };

  const customizeText = cellInfo => {
    const data = cellInfo.value;
    return data === '' || data === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onHiding = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
    addSaleMasterRef.current.open(null);
  };

  const refresh = () => {
    gridRef.current.instance.refresh();
  };

  const onCancel = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    if (selectedItems.length === 0) {
      alert('취소할 대상을 선택해주세요.');
    } else {
      const ticketPayInfo = [];
      selectedItems.forEach(r => {
        ticketPayInfo.push(r.PayRequestStatusName);
      });
      const payRequestTicket = ticketPayInfo.some(val => ['결제완료', '요청완료'].includes(val));
      if (payRequestTicket) return alert('결제 요청된 매출은 취소할 수 없습니다.');
      const result = confirm('<i>취소하시겠습니까?</i>', '확인');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          const params = selectedItems.filter(i => i.Status !== 'C').map(i => i.TicketID);
          salesPayment
            .cancelEtcSalesHistory({
              TicketID: params.length === 1 ? params[0] : params,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '취소되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                refresh();
                gridRef.current.instance.clearSelection();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const onEdit = TicketID => {
    setIsOpen(true);
    addSaleMasterRef.current.open(TicketID);
  };

  const editCellRender = useCallback(e => {
    const { TicketID, Status, isEditable } = e.data.data;
    return (
      Status !== 'C' && (
        <Button
          className={isEditable === 'N' ? 'sales-confirm' : ''}
          icon="edit"
          type="normal"
          stylingMode="text"
          onClick={() => onEdit(TicketID)}
        />
      )
    );
  }, []);

  const detailCellRender = e => {
    const status = e.data.PayRequestStatusName;
    const isPaysys = status !== null && status !== '' && status !== undefined;
    return (
      <Button
        icon="search"
        width={30}
        type="normal"
        visible={isPaysys}
        stylingMode="text"
        onClick={() => {
          showPaymentDetailPopup(e.data);
        }}
      />
    );
  };

  const showPaymentDetailPopup = data => {
    setPaymentDetailPopup({ save: false, visible: !paymentDetailPopup.visible });
    paymentDetailRef.current.open(data.UID);
  };

  const onCellPrepared = useCallback(({ data, cellElement, rowType, columnIndex }) => {
    if (rowType === 'data' && columnIndex === 0) {
      cellElement.classList.add('sticky-cell'); // 스타일 클래스를 추가
    }
    if (rowType === 'header' && columnIndex === 0) {
      cellElement.classList.add('sticky-header-cell');
    }
    if (rowType === 'filter' && columnIndex === 0) {
      cellElement.classList.add('sticky-filter-cell');
    }
    if (rowType === 'totalFooter' && columnIndex === 0) {
      cellElement.classList.add('sticky-footer-cell');
    }

    if (data) {
      const { Status } = data;
      const tr = cellElement.parentNode;
      if (Status === 'C' && tr) {
        tr.classList.add('sales-cancel');
        tr.classList.remove('dx-selection');
      }
    }
  }, []);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
    setSearchParams({
      ...searchParams,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
    setSearchParams({
      ...searchParams,
      ToDate: e.value,
    });
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const showPaymentPopup = () => {
    const selectedRows = gridRef.current.instance.getSelectedRowsData();
    if (selectedRows.length === 0) {
      return alert('결제 요청할 매출을 선택해 주세요');
    } else if (selectedRows.length > 1) {
      return alert('결제 요청은 일괄 처리할 수 없습니다. 1건만 선택 해주세요.');
    }

    const { PaymentType, CustomerType, CustomerCorpName, CustomerTelephoneNo } = selectedRows[0];
    const isCorp = CustomerType === 'B';
    const memberName = isCorp && CustomerCorpName;
    const customerTelephoneNo = isCorp && CustomerTelephoneNo;

    const ticketPayInfo = [];
    selectedRows.forEach(r => {
      ticketPayInfo.push(r.PayRequestStatusName);
    });
    const payedticket = ticketPayInfo.some(val => ['결제완료', '환불', '요청취소'].includes(val));
    const payRequestTicket = ticketPayInfo.some(val => ['요청완료', '요청대기'].includes(val));
    if (payedticket) {
      alert('이미 결제/취소된 매출이 포함되었습니다.');
    } else if (payRequestTicket) {
      alert('이미 결제 요청 완료된 매출이 포함되었습니다.');
    } else {
      setPaymentPopup({ save: false, visible: !paymentPopup.visible });
      paymentRef.current.open({
        ...selectedRows[0],
        FromDate: null,
        ToDate: null,
        MemberName: memberName || '',
        MobilePhoneNo: customerTelephoneNo || '',
      });
    }
  };

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsSummary') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      const isRowSelected = options.component.isRowSelected(options.value?.TicketID);
      if (options.summaryProcess === 'calculate' && isRowSelected) {
        options.totalValue += options.value.RefundPrice;
      }
    }
    if (options.name === 'SelectedRowsCount') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      const isRowSelected = options.component.isRowSelected(options.value?.TicketID);
      if (options.summaryProcess === 'calculate' && isRowSelected) {
        options.totalValue += 1;
      }
    }
  };
  const customizeSelectCount = e => {
    const result = gridRef.current.instance.getSelectedRowsData();
    const formattedTotal = result.length + ' 건';
    return formattedTotal;
  };
  const customizeSelectTotal = e => {
    const result = gridRef.current.instance.getSelectedRowsData();
    const sum = result.reduce((total, item) => total + item.TotalPrice, 0);
    const formattedTotal = sum.toLocaleString() + ' 원';
    return formattedTotal;
  };

  const formatCurrency = value => {
    return value.toLocaleString('ko-KR') + '원';
  };
  const formatCount = value => {
    return value + '건';
  };

  const onSelectionChanged = e => {
    const selectedRows = e.selectedRowsData;
    setSelectedRowsData(selectedRows);
  };
  return (
    <React.Fragment>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>통합 결제 요청 목록</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={etcSalesMngManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>

      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div style={{ paddingTop: '5px' }}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card sales-history form-with-status column-fixed'}
              dataSource={dataSource}
              showBorders={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              onSelectionChanged={onSelectionChanged}
              loadPanel={{ enabled: false }}
              height={700}
              width="100%"
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '통합결제연동_요청목록.xlsx',
                customizeCell: { date: ['RegisterDate'] },
              }}
              headerBefore={[
                <div className="label">요청일 :</div>,
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  width={120}
                  value={dateValue.FromDate}
                />,
                <span>~</span>,
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="ToDate"
                  min={dateValue.FromDate}
                  value={dateValue.ToDate}
                  onValueChanged={handleToDate}
                  width={120}
                  dateOutOfRangeMessage="매출시작일보다 빠를 수 없습니다."
                />,
                <TextBox
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="사업소명"
                  onValueChanged={onValueChanged}
                  onEnterKey={onEnterKey}
                  width={180}
                  mode="search"
                />,
                // <Autocomplete
                //   labelMode="static"
                //   stylingMode="outlined"
                //   dataSource={autoDataSource}
                //   value={autoText}
                //   valueExpr="CostCenterName"
                //   searchExpr="CostCenterName"
                //   onValueChanged={onValueChanged}
                //   onEnterKey={onEnterKey}
                //   showClearButton={true}
                //   width={220}
                //   placeholder="사업소명을 입력하세요"
                //   onItemClick={onItemClick}
                //   mode="search"
                // />,
                <SelectBox
                  labelMode="static"
                  placeholder="매출유형을 선택해주세요"
                  dropDownButtonTemplate="dropDownButton"
                  stylingMode="outlined"
                  dataSource={[
                    {
                      key: '전체',
                      items: [{ value: '', id: '매출유형 전체' }],
                    },
                    {
                      key: '매출유형',
                      items: etcType,
                    },
                  ]}
                  onItemClick={onClickGoodsType}
                  valueExpr="value"
                  displayExpr="id"
                  width={130}
                  grouped={true}
                />,
                <TextBox
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="고객명/핸드폰번호"
                  onValueChanged={onChangeCustomer}
                  onEnterKey={onEnterKey}
                  width={180}
                  mode="search"
                />,
                <CheckBox defaultValue={false} text="만료 요청만" onValueChanged={onCheckValueChanged} />,
              ]}
              headerAfter={[
                <Button icon="fas fa-comments-dollar" text="재요청(일괄)" mode="default" onClick={handleResend} />,
                <Button icon="refresh" onClick={refresh} />,
              ]}
              onCellPrepared={onCellPrepared}
            >
              <Scrolling mode="standard" showScrollbar="always" />
              <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
              <Paging pageSize={20} />
              <Column dataField={'TicketID'} visible={false} />
              <Column dataField={'RegisterUserMail'} visible={false} />
              <Column dataField={'CostCenterCode'} visible={false} />
              <Column dataField={'HiParkingID'} width={85} caption={'P코드'} cssClass="sticky-column-1" />
              <Column dataField={'CostCenterName'} width={150} caption={'사업소'} cssClass="sticky-column-2" />
              {/* <Column dataField={'SalesTypeName'} width={100} caption={'매출유형'} fixed={true} />
              <Column dataField={'SalesDate'} width={100} caption={'매출일'} fixed={true} /> */}
              <Column dataField={'GoodsType'} visible={false} />
              <Column dataField={'GoodsName'} width={200} caption={'상품명'} cssClass="sticky-column-3" />
              {/* <Column dataField={'CustomerTypeName'} width={90} caption={'고객유형'} /> */}
              <Column dataField={'CustomerName'} width={120} caption={'고객명'} />
              <Column dataField={'VehicleRegistrationNo'} width={100} caption={'차량번호'} />
              <Column dataField={'PaymentTypeName'} width={90} caption={'결제유형'} />
              {/* <Column dataField={'SalesPrice'} width={150} caption={'매출금액'} format="#,##0 원" /> */}
              <Column dataField={'TotalPrice'} width={120} caption={'요청금액'} format="#,##0 원" />
              {/* <Column dataField={'StatusName'} width={90} caption={'상태'} /> */}
              {/* <Column dataField={'SalesStatusName'} width={100} caption={'매출상태'} /> */}
              <Column dataField={'PayType'} visible={false} />
              <Column
                dataField={'PayRequestStatusName'}
                width={100}
                caption={'결제요청상태'}
                cellRender={payRequestStatusCellRender}
              />
              <Column dataField={'PayExpireDate'} width={100} caption={'결제만료일'} />
              <Column dataField={'RegisterDate'} width={100} caption={'요청일'} cellRender={customizeText} />
              <Column
                dataField={'상세보기'}
                width={100}
                caption={'상세보기'}
                cssClass="cell-remove-padding"
                cellRender={detailCellRender}
              />
              <Column dataField={'MobilePhoneNo'} width={120} caption={'결제링크 수신 전화번호'} />
              <Column dataField={'EmailAddress'} width={150} caption={'결제링크 수신 Email'} />
              <Column dataField={'PaysysGoodsTypeName'} width={130} caption={'상품유형'} />
              <Column dataField={'FromDate'} width={100} caption={'시작일'} />
              <Column dataField={'ToDate'} width={100} caption={'종료일'} />
              <Column dataField={'CustomerCorpName'} width={150} caption={'사업자명'} />

              <Column dataField={'RegisterUserName'} width={100} caption={'요청자'} />
              <Column dataField={'PaymentDate'} width={100} caption={'결제 날짜'} cellRender={customizeText} />
              <Column dataField={'AccountingDate'} width={150} caption={'대사일'} alignment={'left'} />
              <Column dataField={'Remark'} width={150} caption={'비고'} />
              <Column dataField={'Quantity'} width={80} caption={'수량'} alignment={'left'} />
              <Column dataField={'Unit'} visible={false} />
              <Column dataField={'UnitPrice'} width={110} caption={'단가'} format="#,##0 원" />
              <Column dataField={'FixAccountBankCode'} caption={'고정 계좌 은행 코드'} />
              <Column dataField={'BankName'} caption={'은행 이름'} />

              <Column dataField={'PayAccountName'} caption={'결제 계좌 이름'} width={100} />
              <Column dataField={'PayAccountNumber'} caption={'결제 계좌 번호'} width={100} />
              <Column dataField={'FixAccountStartDateR'} caption={'고정 계좌 시작 날짜(실제)'} width={100} />
              <Column dataField={'FixAccountEndDateR'} caption={'고정 계좌 종료 날짜(실제)'} width={100} />
              <Column dataField={'FixAccountTotalCntR'} caption={'고정 계좌 총 횟수(실제)'} width={100} />
              <Column dataField={'FixAccountCurrentCntR'} caption={'고정 계좌 현재 횟수(실제)'} width={100} />
              <Column dataField={'FixAccountStartDate'} caption={'고정 계좌 시작 날짜'} width={100} />
              <Column dataField={'FixAccountEndDate'} caption={'고정 계좌 종료 날짜'} width={100} />
              <Summary calculateCustomSummary={calculateSelectedRow}>
                <TotalItem column="CostCenterName" displayFormat="선택 건수: " />
                <TotalItem customizeText={customizeSelectCount} column="CostCenterName" showInColumn="CostCenterName" />
                <TotalItem column="GoodsName" displayFormat="선택 합계: " />
                <TotalItem customizeText={customizeSelectTotal} column="TotalPrice" showInColumn="GoodsName" />

                <TotalItem column="PaymentTypeName" displayFormat="조회 건수: " />
                <TotalItem
                  summaryType="count"
                  valueFormat={formatCount}
                  displayFormat="{0}"
                  showInColumn="PaymentTypeName"
                />

                <TotalItem column="TotalPrice" displayFormat="조회 합계: " />
                <TotalItem
                  column="TotalPrice"
                  summaryType="sum"
                  valueFormat={formatCurrency}
                  displayFormat="{0}"
                  showInColumn="TotalPrice"
                />
              </Summary>
            </CommonDataGrid>
            <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="40%" height={790}>
              <ScrollView>
                <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}></div>
              </ScrollView>
            </Popup>
          </div>
        </div>
      </div>
      <Popup visible={paymentPopup.visible} showTitle={false} width="40%" height={700}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <PaymentForm
            ref={paymentRef}
            setPopup={setPaymentPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
      <Popup visible={paymentDetailPopup.visible} showTitle={false} width="40%" height={800}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <PaymentDetailForm
            ref={paymentDetailRef}
            setPopup={setPaymentDetailPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>

      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </React.Fragment>
  );
};
export default ViewPaysysRequestStatusPage;
