import React, { useState, useRef } from 'react';
import DataSource from 'devextreme/data/data_source';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { TagBox } from 'devextreme-react/tag-box';
import { Column, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { ticket } from '../../../api';

import { autoDataSourceWithAll } from 'pages/sales-mng/api/costCenterData';
import CommonDataGrid from 'common/default-data-grid';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import notify from 'devextreme/ui/notify';

import './view-season-ticket-list.scss';

const ViewSeasonTicketList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchCenter, setSearchCenter] = useState('');

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const gridRef = useRef({});

  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  // const onEnterKey = e => {
  //   callAPI();
  // };

  const costData = new DataSource({
    store: autoDataSourceWithAll,
    key: 'CostCenterCode',
  });

  const callAPI = () => {
    const SearchCenter = searchCenter.length > 0 ? searchCenter.map(a => a.trim()).join(',') : '';
    const search = {
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      SearchCenter,
    };
    (async () => {
      setLoadPanelVisible(true);
      try {
        const result = await ticket.getSeasonTicketList(search);
        const dataSource = result.data;
        setDataSource(dataSource);
        setLoadPanelVisible(false);
      } catch (e) {
        setLoadPanelVisible(false);
        const { type, title, status, detail, instance } = e.response.data;
        notify(
          {
            message: JSON.parse(e.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    })();
  };

  const onSearch = e => {
    callAPI();
  };

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onClickButton = e => {
    const result = [...searchCenter];
    const add = e.addedItems.map(a => a.CostCenterCode);
    const remove = e.removedItems.map(a => a.CostCenterCode);
    if (add.length > 0) {
      result.push(...add);
      setSearchCenter(result);
    }
    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setSearchCenter(newArr);
    }
  };

  return (
    <div>
      <h2 className={'content-block'}>정기권 목록 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            stylingMode="outlined"
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '정기권 목록.xlsx',
            }}
            isHeader={true}
            headerBefore={[
              <div className="label">기준일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span>~</span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                onValueChanged={handleToDate}
                value={dateValue.ToDate}
              />,
              <TagBox
                dataSource={costData}
                showSelectionControls={false}
                columnAutoWidth={true}
                allowColumnResizing={true}
                maxDisplayedTags={2}
                displayExpr="CostCenterName"
                valueExpr="CostCenterCode"
                placeholder="조회할 사업소를 모두 선택해주세요"
                stylingMode="outlined"
                onSelectionChanged={onClickButton}
                searchEnabled={true}
                width={330}
                selectAllText={'전체'}
                multiline={false}
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
            <HeaderFilter visible={true} />
            <Column dataField={'HiParkingID'} caption={'P코드'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} width={200} />
            <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={120} />
            <Column dataField={'MemberName'} caption={'회원명'} width={120} />
            <Column dataField={'MemberDivisionName'} caption={'회원구분'} width={90} />
            <Column dataField={'MemberTypeName'} caption={'회원유형'} width={90} />
            <Column dataField={'FromDate'} caption={'시작일'} width={100} />
            <Column dataField={'ToDate'} caption={'종료일'} width={100} />
            <Column dataField={'SalesPrice'} caption={'판매금액'} format="#,##0 원" />
            <Column dataField={'StatusName'} caption={'매출상태'} width={120} />
            <Column dataField={'ApprovedDate'} caption={'확정일'} customizeText={customizeText} />
            <Column dataField={'MemberGroupName'} caption={'회원그룹명'} />
            <Column dataField={'CorpRegNumber'} caption={'사업자번호'} />
            <Column dataField={'CustomerCorpName'} caption={'사업자명'} />
            <Column dataField={'Remark'} caption={'비고'} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} />
            <Column dataField={'PaymentCodeName'} caption={'수금구분'} />
            <Column dataField={'PaymentAgency'} caption={'결제사'} />
            <Column dataField={'PaymentDate'} caption={'수금일'} customizeText={customizeText} />
            <Column dataField={'AccountingDate'} caption={'대사일'} />
            <Column dataField={'SettlementNo'} caption={'세금계산서번호'} />
            <Column dataField={'ExtendStatus'} caption={'정기권상태'} />
            <Column dataField={'RegisterUser'} caption={'매출 등록자'} />
            <Summary>
              <TotalItem column="VehicleRegistrationNo" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
          <PSLoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: '.content' }}
            // onHiding={hideLoadPanel}
            visible={loadPanelVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewSeasonTicketList;
