import React, { useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';

export default function UserPanel({ menuMode, setIsPopup }) {
  const { user, signOut } = useAuth();
  // const history = useHistory();

  const menuItems = useMemo(
    () => [
      // {
      //   text: 'Profile',
      //   icon: 'user',
      //   onClick: () => {
      //     history.push('/example/profile');
      //   },
      // },
      {
        text: 'Logout',
        icon: 'runner',
        onClick: signOut,
      },
      {
        text: '내정보',
        icon: 'user',
        onClick: () => setIsPopup(true),
      },
    ],
    [signOut],
  );

  return (
    <div className={'user-panel'}>
      <div className={'user-info'} style={{ height: '30px', justifyContent: 'center' }}>
        <div className={'user-name'}>
          <div>{user.Email}</div>
        </div>
      </div>
      {menuMode === 'context' && (
        <ContextMenu items={menuItems} target={'.user-button'} showEvent={'dxclick'} width={210} cssClass={'user-menu'}>
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && <List className={'dx-toolbar-menu-action'} items={menuItems} />}
    </div>
  );
}
