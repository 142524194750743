import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, {
  ButtonItem,
  ButtonOptions,
  EmailRule,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';

import { salesPayment } from 'api';
import { resendTaxInner, resendTaxOut } from 'api/salesPayment';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { notifyS } from '../../../../common/popup/sales-common-util';

const EmailResendPopup = forwardRef(({}, ref) => {
  const formRef = useRef({});

  const [enteredEmails, setEnteredEmails] = useState({
    email1: '',
    email2: '',
    EmailResend: '',
    EmailResend2: '',
  });
  const [taxInvoiceForm, setTaxInvoiceForm] = useState({});

  const [visible, setVisible] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    visiblePopup,
  }));

  const visiblePopup = params => {
    // console.log('^^^ visiblePopup... ', params);
    setEnteredEmails({
      email1: params.email1,
      email2: params.email2,
      EmailResend: params.email1,
      EmailResend2: params.email2,
    });
    setTaxInvoiceForm(params.taxInvoiceForm);
    setVisible(true);
  };

  const inVisiblePopup = () => {
    setVisible(false);
  };

  const onClose = () => {
    setEnteredEmails({
      email1: '',
      email2: '',
      EmailResend: '',
      EmailResend2: '',
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    setLoadPanelVisible(true);

    const outParams = {
      InvoiceID: taxInvoiceForm.InvoiceID,
      TaxInvoiceNo: taxInvoiceForm.TaxInvoiceNo,
      CustomerEmail: enteredEmails.EmailResend,
    };
    const resOut = await salesPayment.resendTaxOut(outParams);
    if (enteredEmails.EmailResend2 > '') {
      console.log('Email2', enteredEmails.EmailResend2);
      const outParams2 = {
        InvoiceID: taxInvoiceForm.InvoiceID,
        TaxInvoiceNo: taxInvoiceForm.TaxInvoiceNo,
        CustomerEmail: enteredEmails.EmailResend2,
      };
      const resOut2 = await salesPayment.resendTaxOut(outParams2);
    }
    // if (!resOut.isOk) {
    //   notifyS('이메일재전송이 실패하였습니다.');
    //   setLoadPanelVisible(false);
    //   return;
    // }
    // const inParams = {
    //   InvoiceID: taxInvoiceForm.InvoiceID,
    //   CustomerEmail: enteredEmails.EmailResend,
    //   Result: resOut.data,
    // };
    // console.log(inParams);
    // const resInner = await salesPayment.resendTaxInner(inParams);
    // console.log(resInner);

    if (resOut.isOk) {
      notifyS('이메일이 재전송되었습니다.', 'success');
      inVisiblePopup();
    } else {
      notifyS('이메일재전송이 실패하였습니다.');
    }
    setLoadPanelVisible(false);
  };

  const hideLoadPanel = () => {};
  return (
    <Popup onHiding={onClose} visible={visible} showTitle={false} width="60%" height={450}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={inVisiblePopup}></i>
        </div>
        <div className="popup-header">세금계산서 메일 재전송</div>
        <div className="popup-content">
          <form onSubmit={onSubmit}>
            <div className="form-container">
              <Form
                ref={formRef}
                formData={enteredEmails}
                showColonAfterLabel={false}
                // labelLocation="left"
                labelMode="outside"
                optionalMark="optional"
                stylingMode="outlined"
              >
                <GroupItem>
                  <SimpleItem
                    dataField="email1"
                    editorType="dxTextBox"
                    editorOptions={{
                      readOnly: true,
                      // value: enteredEmails.email1,
                    }}
                  >
                    <Label text="Email" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="email2"
                    editorType="dxTextBox"
                    editorOptions={{
                      readOnly: true,
                    }}
                  >
                    <Label text="Email2" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="EmailResend"
                    editorType="dxTextBox"
                    editorOptions={{
                      inputAttr: {
                        autocomplete: 'new-email',
                      },
                    }}
                  >
                    <Label text="재전송 이메일 주소1" />
                    <RequiredRule message="Email is required" />
                    {/* <EmailRule message="Email is invalid" /> */}
                  </SimpleItem>
                  <SimpleItem
                    dataField="EmailResend2"
                    editorType="dxTextBox"
                    editorOptions={{
                      inputAttr: {
                        autocomplete: 'new-email',
                      },
                    }}
                  >
                    <Label text="재전송 이메일 주소2" />
                    {/* <EmailRule message="Email is invalid" /> */}
                  </SimpleItem>
                </GroupItem>
                <GroupItem colCount={2}>
                  <ButtonItem>
                    <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem horizontalAlignment="left">
                    <ButtonOptions width={'60%'} type={'normal'} text="닫기" onClick={inVisiblePopup}></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </Form>
            </div>
          </form>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </Popup>
  );
});

export default EmailResendPopup;
