import React, { useState, useRef, useCallback } from 'react';
import moment from 'moment';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import DateBox from 'devextreme-react/date-box';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { taxinvoiceManualLink } from 'constants/hyperlink';
import { Help } from 'components';
import ScrollView from 'devextreme-react/scroll-view';
import GeneralTicket from './generalTicket';
import SeasonTicket from './seasonTicket';
import EtcSales from './etcSales';
import TexinvoiceHistory from './texinvoice-history';
import AddTaxinvoice from './popup/AddTaxinvoice';

import '../check-sales-payment/detail.scss';

const SalesTaxinvoice = () => {
  const [gRowdata, setGRowdata] = useState([]);
  const [sRowdata, setSRowdata] = useState([]);
  const [eRowdata, setERowdata] = useState([]);

  const [selectData, setSelectData] = useState([]);

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [helpVisible, setHelpVisible] = useState(false);

  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCardNo: '',
    IsforTaxAccounting: 'Y',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCardNo: '',
    IsforTaxAccounting: 'Y',
  });
  const [isSearch, setIsSearch] = useState(false);

  // 팝업
  const [isOpen, setIsOpen] = useState(false);

  //ref
  const generalTicketRef = useRef({});
  const seasonTicketRef = useRef({});
  const etcSalesRef = useRef({});
  const texinvoiceHistoryRef = useRef({});
  const helpRef = useRef({});

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    if (!isSearch) {
      setIsSearch(true);
    }
    setSearchData({
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      SearchApprovalNo: text.SearchApprovalNo,
      SearchVehicleNumber: text.SearchVehicleNumber,
      SearchCardNo: text.SearchCardNo,
      IsforTaxAccounting: 'Y',
    });
  };

  // const handleSeachPanel = () => {
  //   const searchPanel = document.getElementsByClassName('search-panel')[0];
  //   const toggle = document.getElementById('search-panel-toggle');
  //   if (searchPanel && searchPanel.style.display === 'block') {
  //     searchPanel.style.display = 'none';
  //     toggle.childNodes[0].className = 'dx-icon-spindown';
  //   } else {
  //     searchPanel.style.display = 'block';
  //     toggle.childNodes[0].className = 'dx-icon-spinup';
  //   }
  // };

  // const defaultVisible = useCallback(() => {
  //   setTooltipVisible(!tooltipVisible);
  // }, [tooltipVisible]);

  const onHiding = () => {
    setIsOpen(false);
    setHelpVisible(false);
  };

  // 세금계산서 발행
  const addTaxinvoice = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    if (g === 0 && s === 0 && e === 0) {
      alert('매출 내역을 선택해주세요');
    } else {
      setIsOpen(true);
      const data = [];
      if (g > 0) data.push(...gRowdata);
      if (s > 0) data.push(...sRowdata);
      if (e > 0) data.push(...eRowdata);
      setSelectData(data);
      // addSaleMasterRef.current.open(null, data);
    }
  };

  // 리프레쉬 및 선택 해제
  const refresh = useCallback(() => {
    if (generalTicketRef.current.instance) {
      generalTicketRef.current.instance.refresh();
      generalTicketRef.current.instance.clearSelection();
    }

    if (seasonTicketRef.current.instance) {
      seasonTicketRef.current.instance.refresh();
      seasonTicketRef.current.instance.clearSelection();
    }

    if (etcSalesRef.current.instance) {
      etcSalesRef.current.instance.refresh();
      etcSalesRef.current.instance.clearSelection();
    }

    texinvoiceHistoryRef.current.refresh();
  }, []);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onHelpClick = () => {
    setHelpVisible(true);
    helpRef.current.open('taxinvoice');
  };
  return (
    <div>
      <div className="content-block detail-title left-right-header">
        <h2>청구 세금계산서 발행</h2>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={taxinvoiceManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
          <Button icon="help" text="세금계산서 처리절차" onClick={onHelpClick} />
        </div>
      </div>
      <div className={'content-block'}>
        {/* <div className={'dx-card'} style={{ padding: '20px 40px' }}> */}
        <div style={{ display: 'flex', marginTop: '3px' }}>
          <div
            className={'dx-card'}
            style={{ marginRight: '5px', maxWidth: '50%', padding: '20px 20px', borderRadius: '5px' }}
          >
            <Toolbar>
              <ToolbarItem location={'before'}>
                <div className="label">매출일/확정일 :</div>
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  value={dateValue.fromDate}
                />
              </ToolbarItem>
              <ToolbarItem location={'before'} text="~" />
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="ToDate"
                  min={dateValue.fromDate}
                  value={dateValue.toDate}
                  onValueChanged={handleToDate}
                  dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button text="조회" onClick={onEnterKey} />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button text="세금 계산서 발행" onClick={addTaxinvoice} />
              </ToolbarItem>
            </Toolbar>
            <Toolbar>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchCostCenter"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="P-코드/사업소코드/사업소명"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={220}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchApprovalNo"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="승인번호/계좌번호"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={150}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchCardNo"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="카드번호"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={150}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  label="SearchVehicleNumber"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="차량번호/이름"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={150}
                  mode="search"
                />
              </ToolbarItem>
            </Toolbar>
            <TabPanel>
              <Item title="일반권" badge={gRowdata.length}>
                <GeneralTicket
                  generalTicketRef={generalTicketRef}
                  isTaxinvoice
                  setGRowdata={setGRowdata}
                  searchData={searchData}
                  isSearch={isSearch}
                />
              </Item>
              <Item title="정기권" badge={sRowdata.length}>
                <SeasonTicket
                  seasonTicketRef={seasonTicketRef}
                  isTaxinvoice
                  setSRowdata={setSRowdata}
                  searchData={searchData}
                  isSearch={isSearch}
                />
              </Item>
              <Item title="기타" badge={eRowdata.length}>
                <EtcSales
                  etcSalesRef={etcSalesRef}
                  isTaxinvoice
                  setERowdata={setERowdata}
                  searchData={searchData}
                  isSearch={isSearch}
                />
              </Item>
            </TabPanel>
          </div>
          <div className={'dx-card'} style={{ maxWidth: '50%', padding: '20px 20px', borderRadius: '5px' }}>
            <TexinvoiceHistory ref={texinvoiceHistoryRef} refresh={refresh} setLoadPanelVisible={setLoadPanelVisible} />
          </div>
        </div>
      </div>
      {/* </div> */}
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="50%" height={850}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <AddTaxinvoice
              setIsOpen={setIsOpen}
              selectData={selectData}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onHiding={onHiding} visible={helpVisible} showTitle={false} width="60%" height="84%">
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <Help setHelpVisible={setHelpVisible} ref={helpRef} />
          </div>
        </ScrollView>
      </Popup>
      <div style={{ zIndex: '1000001' }}>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ top: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </div>
    </div>
  );
};

export default SalesTaxinvoice;
