import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';

const Remain = props => {
  const { inquiryDate, costCenterCode, isRefresh } = props;
  const [pgData, setPgData] = useState([]);
  const [vanData, setVanData] = useState([]);
  const [gData, setGData] = useState([]);
  const gGridRef = useRef({});
  const pGridRef = useRef({});
  const vGridRef = useRef({});

  useEffect(() => {
    (async () => {
      await salesPayment
        .getAccountingOutstandingGeneralSales({
          CostCenterCode: costCenterCode,
          InquiryDate: inquiryDate,
        })
        .then(res => {
          if (res.isOk) {
            setGData(res.data);
          }
        });
      await salesPayment
        .getAccountingOutstandingPG({
          CostCenterCode: costCenterCode,
          InquiryDate: inquiryDate,
        })
        .then(res => {
          if (res.isOk) {
            setPgData(res.data);
          }
        });
      await salesPayment
        .getAccountingOutstandingVAN({
          CostCenterCode: costCenterCode,
          InquiryDate: inquiryDate,
        })
        .then(res => {
          if (res.isOk) {
            setVanData(res.data);
          }
        });
    })();
  }, [isRefresh]);

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  return (
    <React.Fragment>
      <div style={{ padding: '17px 0' }}>
        <div className="dx-card">
          <div className="caption">{'매출 > 일반권'}</div>
          <div style={{ padding: '10px' }}>
            <div className="caption">{'매출 > 일반권'}</div>
            <CommonDataGrid
              gridRef={gGridRef}
              dataSource={gData}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
              height={150}
              isHeader={false}
              isExcel={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <HeaderFilter visible={true} />
              <Column dataField={'미처리마감사용자'} caption={'미처리마감사용자'} alignment={'center'} />
              <Column dataField={'미처리마감날짜'} caption={'미처리마감날짜'} alignment={'center'} />
              <Column dataField={'CostCenterCode'} caption={'사업소코드'} alignment={'center'} />
              <Column dataField={'UniqueID'} caption={'고유번호'} alignment={'center'} />
              <Column dataField={'IdxNo'} caption={'일련번호'} alignment={'center'} />
              <Column dataField={'ParkingType'} caption={'주차 유형'} alignment={'center'} />
              <Column dataField={'IsDetected'} caption={'IsDetected'} alignment={'center'} />
              <Column dataField={'VehicleRegistrationNo'} caption={'차량 번호'} alignment={'center'} />
              <Column dataField={'ParkingInDate'} caption={'입차 일시'} alignment={'center'} />
              <Column dataField={'ParkingOutDate'} caption={'출차 일시'} alignment={'center'} />
              <Column dataField={'ParkingFee'} caption={'주차요금'} alignment={'center'} />
              <Column dataField={'SalesPrice'} caption={'판매 금액'} format="#,##0 원" alignment={'center'} />
              <Column dataField={'DiscountPrice'} caption={'할인 금액'} format="#,##0 원" alignment={'center'} />
              <Column dataField={'DiscountType'} caption={'할인 유형'} alignment={'center'} />
              <Column dataField={'PaymentType'} caption={'결제 유형'} alignment={'center'} />
              <Column dataField={'CreditCardNo'} caption={'카드 번호'} alignment={'center'} />
              <Column dataField={'CreditCardApprovalNo'} caption={'승인 번호'} alignment={'center'} />
              <Column dataField={'Remark'} caption={'비고'} alignment={'center'} />
              <Column
                dataField={'ApprovalDate'}
                caption={'처리일시'}
                alignment={'center'}
                customizeText={customizeText}
              />
              <Column
                dataField={'RegisterDate'}
                caption={'등록일시'}
                alignment={'center'}
                customizeText={customizeText}
              />
              <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} />
            </CommonDataGrid>
          </div>
          <div style={{ padding: '10px' }}>
            <div className="caption">{'결제 > PG'}</div>
            <CommonDataGrid
              gridRef={pGridRef}
              dataSource={pgData}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
              height={150}
              isHeader={false}
              isExcel={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <HeaderFilter visible={true} />
              <Column dataField={'미처리마감사용자'} caption={'미처리마감사용자'} alignment={'center'} />
              <Column dataField={'미처리마감날짜'} caption={'미처리마감날짜'} alignment={'center'} />
              <Column dataField={'TransactionDate'} caption={'처리 일시'} customizeText={customizeText} />
              <Column dataField={'CardType'} caption={'카드유형'} />
              <Column dataField={'VANID'} caption={'단말기 번호'} />
              <Column dataField={'ApprovalType'} caption={'승인유형'} />
              <Column dataField={'TransactionID'} caption={'처리번호'} />
              <Column dataField={'OrderID'} caption={'주문번호'} />
              <Column dataField={'ApprovalNo'} caption={'승인번호'} />
              <Column dataField={'SalesPrice'} caption={'결제 금액'} format="#,##0 원" />
              <Column dataField={'IssueCreditCard'} caption={'카드발행사'} />
              <Column dataField={'SettleCreditCard'} caption={'카드결제사'} />
              <Column dataField={'MonthlyInstallmentsType'} caption={'할부유형'} />
              <Column dataField={'CreditCardNo'} caption={'카드 번호'} />
              <Column dataField={'Remark1'} caption={'비고1'} />
              <Column dataField={'Remark2'} caption={'비고2'} />
              <Column dataField={'RegisterUser'} caption={'등록인'} />
              <Column dataField={'RegisterDate'} caption={'등록일시'} />
              {/* <Summary>
              <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
            </Summary> */}
            </CommonDataGrid>
          </div>
          <div style={{ padding: '10px' }}>
            <div className="caption">{'결제 > VAN'}</div>
            <CommonDataGrid
              gridRef={vGridRef}
              dataSource={vanData}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
              height={150}
              isHeader={false}
              isExcel={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <HeaderFilter visible={true} />
              <Column dataField={'미처리마감사용자'} caption={'미처리마감사용자'} alignment={'center'} />
              <Column dataField={'미처리마감날짜'} caption={'미처리마감날짜'} alignment={'center'} />
              <Column dataField={'CompanyCode'} caption={'CompanyCode'} />
              <Column dataField={'VanType'} caption={'VAN사'} />
              <Column dataField={'VANID'} caption={'단말기 번호'} />
              <Column dataField={'VANIDName'} caption={'단말기 명'} />
              <Column dataField={'VANSubID'} caption={'가맹점 ID'} />
              <Column dataField={'ApprovalType'} caption={'처리 유형'} />
              <Column dataField={'TransactionDate'} caption={'처리 일시'} customizeText={customizeText} />
              <Column dataField={'SalesPrice'} caption={'결제 금액'} format="#,##0 원" />
              <Column dataField={'ApprovalNo'} caption={'승인 번호'} />
              <Column dataField={'ApprovalCode'} caption={'승인 코드'} />
              <Column dataField={'OrgApprovalDate'} caption={'승인 날짜'} />
              <Column dataField={'DepositDueDate'} caption={'납기일'} customizeText={customizeText} />
              <Column dataField={'Commission'} caption={'수수료'} />
              <Column dataField={'DepositDuePrice'} caption={'DepositDuePrice'} />
              <Column dataField={'CreditCardNo'} caption={'카드 번호'} />
              <Column dataField={'CardType'} caption={'카드 유형'} />
              <Column dataField={'IssueCreditCard'} caption={'카드발행사'} />
              <Column dataField={'SettleCreditCard'} caption={'카드결제사'} />
              <Column dataField={'MonthlyInstallmentsType'} caption={'할부유형'} />
              <Column dataField={'RegisterUser'} caption={'등록인'} />
              <Column dataField={'RegisterDate'} caption={'등록일시'} />
              {/* <Summary>
              <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
            </Summary> */}
            </CommonDataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Remain;
