import React, { useRef, useEffect, useState } from 'react';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import './CodeMngFom.scss';
// import 'devextreme-react/autocomplete';

const CodeMngForm = ({
  addToDataSource,
  updateDataSource,
  setPopupVisibility,
  codeMng,
  isUpdate,
}) => {
  const formRef = useRef({});
  const [data, setData] = useState({});

  useEffect(() => {
    // 개발시 수정버튼 누르면 pk값으로 재호출 개선
    if (isUpdate) {
      setData(codeMng);
    } else {
      setData({});
    }
  }, [isUpdate, codeMng]);

  const onSubmit = (e) => {
    // 여기서 API연결
    // id 값이 null 이면 저장, id 값이 있으면 수정
    // 저장과 동시에 전체 리프레쉬
    const { id } = formRef.current.props.formData;
    // 실제 코드에서는 api 연결
    if (id) {
      updateDataSource(formRef.current.props.formData);
    } else {
      addToDataSource(formRef.current.props.formData);
      setData({});
    }
    setPopupVisibility(false);
  };

  const onClose = () => {
    setPopupVisibility(false);
    if (!isUpdate) {
      setData({});
    }
  };

  return (
    <>
      <div className="form-container">
        <Form
          ref={formRef}
          formData={data}
          showColonAfterLabel={false}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
          id="form"
        >
          <GroupItem caption={isUpdate ? '코드 수정' : '코드 등록'}>
            <SimpleItem dataField="groupCode">
              <Label text="그룹 코드" />
            </SimpleItem>
            <SimpleItem dataField="codeNameByKor">
              <Label text="코드명(kor)" />
            </SimpleItem>
            <SimpleItem dataField="codeNameByEng">
              <Label text="코드명(eng)" />
            </SimpleItem>
            <SimpleItem dataField="attribute1">
              <Label text="속성1" />
            </SimpleItem>
            <SimpleItem dataField="attribute2">
              <Label text="속성2" />
            </SimpleItem>
            <SimpleItem dataField="parentCode">
              <Label text="상위 코드" />
            </SimpleItem>
            <SimpleItem dataField="blank">
              <Label text="비고" />
            </SimpleItem>
            <SimpleItem dataField="sort">
              <Label text="정렬순서" />
            </SimpleItem>
            <SimpleItem dataField="isUsed">
              <Label text="사용여부" />
            </SimpleItem>
          </GroupItem>
        </Form>
      </div>
      <div className="form-footer">
        <Button
          width={120}
          type="default"
          stylingMode="contained"
          text="저장"
          onClick={onSubmit}
        />
        <Button
          width={120}
          type="normal"
          stylingMode="contained"
          text="닫기"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default CodeMngForm;
