//추후 코스마스터로 관리되면 API 호출 예정
export const issueCreditCard = [
  '비씨카드',
  '국민카드',
  '신한카드',
  '현대카드',
  '롯데카드',
  '하나카드',
  '삼성카드',
  '농협카드',
];
