import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const BtoBSales = props => {
  const { dataSource, tB2BRef, selectTax } = props;

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  useEffect(() => {
    if (dataSource && tB2BRef.current && selectTax) {
      const gridInstance = tB2BRef.current.instance;
      gridInstance.selectAll();
    }
  }, []);

  return (
    <>
      <CommonDataGrid
        gridRef={tB2BRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        allowSelectAll={true}
        isNotFilterRow={false}
        allowColumnReordering={true}
      >
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
        <Scrolling mode="standard" rowRenderingMode="standard" />
        <HeaderFilter visible={true} />
        <Column dataField={'SalesCategory'} caption={'구분'} />
        <Column dataField={'DetailSalesTypeName'} caption={'상세매출유형'} />
        <Column dataField={'HiparkingID'} caption={'P코드'} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} />
        <Column dataField={'AccountingDate'} caption={'대사날짜'} />
        <Column dataField={'SalesMonth'} caption={'매출월'} />
        <Column dataField={'SalesDate'} caption={'매출일'} customizeText={customizeText} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} />
        <Column dataField={'StatusName'} caption={'매출상태'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
        <Column dataField={'UnitPrice'} caption={'기준금액'} format="#,##0 원" />
        <Column dataField={'Commission'} caption={'수수료'} format="#,##0 원" />
        <Column dataField={'Quantity'} caption={'수량'} />
        <Column dataField={'SettlementNo'} caption={'세금계산서번호'} />
        <Column dataField={'PaymentCode'} caption={'수금구분'} />
        <Column dataField={'PaymentAgency'} caption={'결제사'} />
        <Column dataField={'PaymentDate'} caption={'결제시간'} customizeText={customizeText} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} />
      </CommonDataGrid>
    </>
  );
};

export default React.memo(BtoBSales);
