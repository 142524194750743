import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { salesPayment, error } from 'api';
import notify from 'devextreme/ui/notify';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import CommonDataGrid from 'common/default-data-grid';
import AddGeneralForm from './popup/AddGeneralForm';

/*
일반권/ 정기권/ 기타 항목에대해서는 공통으로 가져가고 있었으나 관리 항목이 많아져 
'영수 세금계산서'페이지는 따로 관리하도록 세페이지 추가
*/

const GeneralTicket = props => {
  const { dataSource, generalTicketRef, setGRowdata } = props;
  const [selectSum, setSelectSum] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = generalTicketRef.current.instance.getSelectedRowsData();
    setGRowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const onHiding = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  const refresh = () => {
    generalTicketRef.current.instance.refresh();
  };

  return (
    <>
      <CommonDataGrid
        gridRef={generalTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={595}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '일반권.xlsx',
        }}
        // headerAfter={[<Button icon="plus" text="매출 수기 등록" onClick={onOpen} />]}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
        <Column dataField={'PaymentDate'} caption={'수금일'} customizeText={customizeText} width={'100'} />
        <Column dataField={'ApprovalDate'} caption={'매출시간'} customizeText={customizeText} width={'160'} />
        <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
        <Column dataField={'CashBillID'} caption={'현금영수증ID'} width={90} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={'100'} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={'100'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={'150'} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={'100'} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={'100'} />
        <Column dataField={'Remark'} caption={'비고'} width={'150'} />
        <Summary>
          <TotalItem column="CostCenterName" displayFormat="선택 합계: " />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="CostCenterName" />
          <TotalItem column="ApprovalDate" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem
            column="SalesPrice"
            showInColumn="ApprovalDate"
            summaryType="sum"
            valueFormat="#,##0 원"
            displayFormat="{0}"
          />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(GeneralTicket);
