import React, { useState, useRef, useMemo, useImperativeHandle, useCallback } from 'react';
import { Column, Summary, TotalItem, Selection, Scrolling } from 'devextreme-react/data-grid';
import { makeTaxInvoiceStatusCellRender } from '../../../common/popup/sales-common-util';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { confirm } from 'devextreme/ui/dialog';
import { salesPayment, error } from 'api';

import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import ScrollView from 'devextreme-react/scroll-view';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
import DetailHistory from './popup/DetailHistory';

import moment from 'moment';
import axios from 'axios';

import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const TexinvoiceHistory = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  const detailHistoryRef = useRef({});
  const searchCostCenterRef = useRef({});
  const searchCompanyRef = useRef({});
  const searchGroupRef = useRef({});
  const searchMemberNameRef = useRef({});

  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    SearchCompany: '',
    MemberGroupName: '',
    CustomerName: '',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    SearchCompany: '',
    MemberGroupName: '',
    CustomerName: '',
  });

  const [allCheck, setAllCheck] = useState('N');

  //팝업
  const [isOpen, setIsOpen] = useState(false);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    refresh,
  }));

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = () => {
    setSearchData({
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      SearchCompany: text.SearchCompany,
      MemberGroupName: text.MemberGroupName,
      CustomerName: text.CustomerName,
    });
  };

  const editCellRender = e => {
    return <Button icon="search" type="normal" stylingMode="contained" onClick={() => togglePopup(e.data)} />;
  };

  const togglePopup = data => {
    detailHistoryRef.current.open(data.InvoiceID);
  };

  const refresh = () => {
    const searchCostCenter = searchCostCenterRef.current.instance._changedValue;
    const searchCompany = searchCompanyRef.current.instance._changedValue;
    const searchGroup = searchGroupRef.current.instance._changedValue;
    const searchMemberName = searchMemberNameRef.current.instance._changedValue;
    setSearchData({
      ...searchData,
      SearchCostCenter: searchCostCenter,
      SearchCompany: searchCompany,
      MemberGroupName: searchGroup,
      CustomerName: searchMemberName,
    });
    gridRef.current.instance.refresh();
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'InvoiceID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getTaxInvoiceList`, {
            ...searchData,
            TaxIssueType: 'B',
            IsALL: allCheck,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData, allCheck]);

  const onHiding = () => {
    setIsOpen(false);
  };

  const onCancel = useCallback(async e => {
    // 취소 버튼 클릭 시
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    const { InvoiceID } = selectedItems[0];
    const result = confirm('<i> 세금계산서 발행을 취소하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        props.setLoadPanelVisible(true);
        await salesPayment.cancelTaxInvoiceListByID({ InvoiceID: InvoiceID, RegisterUser: '' }).then(res => {
          if (res.isOk) {
            props.setLoadPanelVisible(false);
            notify(
              {
                message: '취소되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            gridRef.current.instance.refresh();
            props.refresh();
          } else {
            props.setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      }
    });
  }, []);

  const onCheckValueChanged = e => {
    e.value ? setAllCheck('Y') : setAllCheck('N');
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarItem location={'before'}>
          <div className="label">발행요청일 :</div>
        </ToolbarItem>
        <ToolbarItem location={'before'}>
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="FromDate"
            onValueChanged={handleFromDate}
            value={dateValue.fromDate}
          />
        </ToolbarItem>
        <ToolbarItem location={'before'} text="~" />
        <ToolbarItem location={'before'}>
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="ToDate"
            min={dateValue.fromDate}
            value={dateValue.toDate}
            onValueChanged={handleToDate}
            dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
          />
        </ToolbarItem>
        <ToolbarItem location={'before'}>
          <CheckBox
            defaultValue={false}
            text="전체 이력 표시"
            hint="수정세금계산서 발행 전 이력 표시"
            onValueChanged={onCheckValueChanged}
          />
        </ToolbarItem>
        <ToolbarItem location={'after'}>
          <Button text="취소" onClick={onCancel} />
        </ToolbarItem>
      </Toolbar>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card grid-with-status minimal-padding'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={670}
        onCellPrepared={onCellPrepared}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '세금계산서 발행 목록.xlsx',
          customizeCell: {
            date: ['RegisterDate', 'LastUpdateDate'],
          },
        }}
        headerBefore={[
          <TextBox
            ref={searchCostCenterRef}
            label="SearchCostCenter"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="P-코드/사업소코드/사업소명"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
          <TextBox
            ref={searchCompanyRef}
            label="SearchCompany"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="회사"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={150}
            mode="search"
          />,
          <TextBox
            ref={searchGroupRef}
            label="MemberGroupName"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="정기권 > 그룹명"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={150}
            mode="search"
          />,
          <TextBox
            ref={searchMemberNameRef}
            label="CustomerName"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="기타매출 > 고객명"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={150}
            mode="search"
          />,
        ]}
        headerAfter={[
          <Button
            icon="refresh"
            type="normal"
            stylingMode="contained"
            onClick={() => {
              refresh();
            }}
          />,
        ]}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <Selection mode="single" selectAllMode="pages" />
        <Column dataField={'detail'} caption={'상세'} cellRender={editCellRender} width={50} allowExporting={false} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} />
        <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
        <Column
          dataField={'TaxInvoiceStatusName'}
          caption={'처리상태'}
          width={110}
          cellRender={taxInvoiceStatusCellRender}
        />
        <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
        <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={80} />
        <Column dataField={'SalesPrice'} caption={'금액'} width={100} format="#,##0 원" />
        <Column dataField={'CustomerName'} caption={'사업자명'} width={200} />
        <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
        <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
        <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
        <Column dataField={'SalesCount'} caption={'품목 수'} width={80} />
        <Column dataField={'TaxPrice'} caption={'부가세'} width={80} />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} />
        <Column dataField={'Remark'} caption={'적요'} width={200} />
        <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} />
        <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} />
        <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
        <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
        <Column dataField={'RegisterUser'} caption={'등록자'} width={80} />
        <Column dataField={'RegisterDate'} caption={'등록일'} width={120} cellRender={customizeText} />
        <Column dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} cellRender={customizeText} />
        <Summary>
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="50%" height={890}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
            <DetailHistory
              setLoadPanelVisible={props.setLoadPanelVisible}
              ref={detailHistoryRef}
              setIsOpen={setIsOpen}
              refresh={refresh}
            />
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
});

export default React.memo(TexinvoiceHistory);
