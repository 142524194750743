import React, { useCallback, useState, useImperativeHandle, useEffect, useRef } from 'react';
import moment from 'moment';
import Form, { SimpleItem, GroupItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import { Column, Summary, TotalItem, Editing } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import { salesPayment } from 'api';
import { cellStatus } from './common/common';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import ExternalSalesForm from './externalSales/externalSalesForm';
import CommonDataGrid from 'common/default-data-grid';

const PartialPaymentForm = React.forwardRef((props, ref) => {
  const {
    setIsOpenbyPartialPayment,
    setLoadPanelVisible,
    gRowdata,
    sRowdata,
    eRowdata,
    tRowdata,
    pRowdata,
    cRowdata,
    date,
    costCenter,
    refresh,
    setStatus,
  } = props;
  const [formData, setFormData] = useState({
    SaleTotal: 0,
    PaymentTotal: 0,
    SalesType: '',
    PaymentType: '',
    Diff: 0,
    GeneralSalesKeyID: '',
    SeasonTicketKeyID: '',
    EtcSalesKeyID: '',
    PGVANPaymentKeyID: '',
    CMSPaymentKeyID: '',
    TaxInvoiceKeyID: '',
  });
  const [popupExternalSales, setPopupExternalSales] = useState({
    save: false,
    visible: false,
    type: 'g',
  });
  const [gdata, setGData] = useState([]);
  const [sdata, setSData] = useState([]);
  const [edata, setEData] = useState([]);
  const [tdata, setTData] = useState([]);

  const gGridRef = useRef({});
  const sGridRef = useRef({});
  const eGridRef = useRef({});
  const tGridRef = useRef({});
  const [externalGSales, setExternalGSales] = useState([]);
  const [externalSSales, setExternalSSales] = useState([]);
  const [externalESales, setExternalESales] = useState([]);
  const [externalTSales, setExternalTSales] = useState([]);

  const [isRental, setIsRental] = useState({ value: false, disabled: false });
  const externalSalesFormRef = useRef({});

  useEffect(() => {
    // 타 사업소 저장 클릭할때마다
    if (popupExternalSales.save) {
      // 초기 값 셋팅
      let saleTotal = 0;
      let remainpaymentTotal = formData.PaymentTotal;

      const GeneralSalesKeyID = [];
      const SeasonTicketKeyID = [];
      const EtcSalesKeyID = [];
      const TaxInvoiceKeyID = [];

      // 일반권 타 사업소 매출이 있을 경우
      if (externalGSales && externalGSales.length > 0) {
        const gNowData = [...gdata, ...externalGSales];
        // 중복제거
        const checkDuplication = [...new Set(gNowData.map(JSON.stringify))].map(JSON.parse);
        checkDuplication.forEach(g => {
          g.PaymentPrice = remainpaymentTotal > 0 ? Math.min(g.RemainAmount, remainpaymentTotal) : 0;
          remainpaymentTotal = remainpaymentTotal - g.RemainAmount;
          GeneralSalesKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
        const totalData = [...checkDuplication, ...sdata, ...edata, ...tdata];
        const sales = totalData.reduce((a, c) => {
          return a + c.RemainAmount;
        }, 0);
        saleTotal += sales;
        setGData(checkDuplication);
        setExternalGSales([]);
      }

      // 정기권 타 사업소 매출이 있을 경우
      if (externalSSales && externalSSales.length > 0) {
        const sNowData = [...sdata, ...externalSSales];
        // 중복제거
        const checkDuplication = [...new Set(sNowData.map(JSON.stringify))].map(JSON.parse);
        checkDuplication.forEach(g => {
          g.PaymentPrice = remainpaymentTotal > 0 ? Math.min(g.RemainAmount, remainpaymentTotal) : 0;
          remainpaymentTotal = remainpaymentTotal - g.RemainAmount;
          SeasonTicketKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
        const totalData = [...checkDuplication, ...gdata, ...edata, ...tdata];
        const sales = totalData.reduce((a, c) => {
          return a + c.RemainAmount;
        }, 0);
        saleTotal += sales;
        setSData(checkDuplication);
        setExternalSSales([]);
      }

      // 기타 타 사업소 매출이 있을 경우
      if (externalESales && externalESales.length > 0) {
        const eNowData = [...edata, ...externalESales];
        // 중복제거
        const checkDuplication = [...new Set(eNowData.map(JSON.stringify))].map(JSON.parse);
        checkDuplication.forEach(g => {
          g.PaymentPrice = remainpaymentTotal > 0 ? Math.min(g.RemainAmount, remainpaymentTotal) : 0;
          remainpaymentTotal = remainpaymentTotal - g.RemainAmount;
          EtcSalesKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
        const totalData = [...checkDuplication, ...gdata, ...sdata, ...tdata];
        const sales = totalData.reduce((a, c) => {
          return a + c.RemainAmount;
        }, 0);
        saleTotal += sales;
        setEData(checkDuplication);
        setExternalESales([]);
      }

      // 세금계산서 타 사업소 매출이 있을 경우
      if (externalTSales && externalTSales.length > 0) {
        const tNowData = [...tdata, ...externalTSales];
        // 중복제거
        const checkDuplication = [...new Set(tNowData.map(JSON.stringify))].map(JSON.parse);
        checkDuplication.forEach(g => {
          g.PaymentPrice = remainpaymentTotal > 0 ? Math.min(g.RemainAmount, remainpaymentTotal) : 0;
          remainpaymentTotal = remainpaymentTotal - g.RemainAmount;
          TaxInvoiceKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
        const totalData = [...checkDuplication, ...gdata, ...sdata, ...edata];
        const sales = totalData.reduce((a, c) => {
          return a + c.RemainAmount;
        }, 0);
        saleTotal += sales;
        setTData(checkDuplication);
        setExternalTSales([]);
      }

      if (gdata.length > 0) {
        gdata.forEach(g => {
          GeneralSalesKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
      }

      if (sdata.length > 0) {
        sdata.forEach(g => {
          SeasonTicketKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
      }

      if (edata.length > 0) {
        edata.forEach(g => {
          EtcSalesKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
      }

      if (tdata.length > 0) {
        tdata.forEach(g => {
          TaxInvoiceKeyID.push(g.KeyID + ':' + g.PaymentPrice);
        });
      }

      // // 일반권 타 사업소 매출이 있을 경우
      // if (externalGSales && externalGSales.length > 0) {
      //   externalGSales.forEach(g => {
      //     GeneralSalesKeyID.push(g.KeyID+":"+g.PaymentPrice);
      //   });
      // }
      // console.log(GeneralSalesKeyID);
      // // 정기권 타 사업소 매출이 있을 경우
      // if (externalSSales && externalSSales.length > 0) {
      //   externalSSales.forEach(g => {
      //     SeasonTicketKeyID.push(g.KeyID+":"+g.PaymentPrice);
      //   });
      // }

      // // 기타 타 사업소 매출이 있을 경우
      // if (externalESales && externalESales.length > 0) {
      //   externalESales.forEach(g => {
      //     EtcSalesKeyID.push(g.KeyID+":"+g.PaymentPrice);
      //   });
      // }
      // // 세금계산서 타 사업소 매출이 있을 경우
      // if (externalTSales && externalTSales.length > 0) {
      //   externalTSales.forEach(g => {
      //     TaxInvoiceKeyID.push(g.KeyID+":"+g.PaymentPrice);
      //   });
      // }

      // 합계 계산후 form 반영
      setFormData({
        ...formData,
        GeneralSalesKeyID: GeneralSalesKeyID.length > 0 ? GeneralSalesKeyID.join(';') : '',
        SeasonTicketKeyID: SeasonTicketKeyID.length > 0 ? SeasonTicketKeyID.join(';') : '',
        EtcSalesKeyID: EtcSalesKeyID.length > 0 ? EtcSalesKeyID.join(';') : '',
        TaxInvoiceKeyID: TaxInvoiceKeyID.length > 0 ? TaxInvoiceKeyID.join(';') : '',
        SaleTotal: saleTotal,
        Diff: saleTotal - formData.PaymentTotal,
      });
    }
  }, [popupExternalSales.save]);

  const CheckSaveData = () => {
    if (gGridRef.current && gGridRef.current.instance) gGridRef.current.instance.saveEditData();
    if (sGridRef.current && sGridRef.current.instance) sGridRef.current.instance.saveEditData();
    if (eGridRef.current && eGridRef.current.instance) eGridRef.current.instance.saveEditData();
    if (tGridRef.current && tGridRef.current.instance) tGridRef.current.instance.saveEditData();

    const GeneralSalesKeyID = [];
    const SeasonTicketKeyID = [];
    const EtcSalesKeyID = [];
    const TaxInvoiceKeyID = [];

    if (gdata && gdata.length > 0) {
      gdata.forEach(g => {
        GeneralSalesKeyID.push(g.KeyID + ':' + g.PaymentPrice);
      });
    }
    if (sdata && sdata.length > 0) {
      sdata.forEach(s => {
        SeasonTicketKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
    }
    if (edata && edata.length > 0) {
      edata.forEach(s => {
        EtcSalesKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
    }
    if (tdata && tdata.length > 0) {
      tdata.forEach(s => {
        TaxInvoiceKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
    }
    // 합계 계산후 form 반영
    setFormData({
      ...formData,
      GeneralSalesKeyID: GeneralSalesKeyID.length > 0 ? GeneralSalesKeyID.join(';') : '',
      SeasonTicketKeyID: SeasonTicketKeyID.length > 0 ? SeasonTicketKeyID.join(';') : '',
      EtcSalesKeyID: EtcSalesKeyID.length > 0 ? EtcSalesKeyID.join(';') : '',
      TaxInvoiceKeyID: TaxInvoiceKeyID.length > 0 ? TaxInvoiceKeyID.join(';') : '',
      // SaleTotal: saleTotal,
      // Diff: saleTotal - formData.PaymentTotal,
    });
  };
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  // 팝업이 open되면 매출금액, 결제금액, 차이 계산
  const open = (gRowdata, sRowdata, eRowdata, tRowdata, pRowdata, cRowdata) => {
    let saleTotal = 0;
    const GeneralSalesKeyID = [];
    const SeasonTicketKeyID = [];
    const EtcSalesKeyID = [];
    const PGVANPaymentKeyID = [];
    const CMSPaymentKeyID = [];
    const TaxInvoiceKeyID = [];

    let paymentTotal = 0;
    let remainpaymentTotal = 0;
    if (pRowdata && pRowdata.length > 0) {
      pRowdata.forEach(p => {
        paymentTotal += p.SalesPrice;
        PGVANPaymentKeyID.push(p.KeyID);
      });
    }
    if (cRowdata && cRowdata.length > 0) {
      cRowdata.forEach(c => {
        paymentTotal += c.TransactionAmount;
        CMSPaymentKeyID.push(c.KeyID);
      });
    }
    remainpaymentTotal = paymentTotal;

    if (gRowdata && gRowdata.length > 0) {
      gRowdata.forEach(g => {
        saleTotal += g.RemainAmount;
        g.PaymentPrice = remainpaymentTotal > 0 ? Math.min(g.RemainAmount, remainpaymentTotal) : 0;
        remainpaymentTotal = remainpaymentTotal - g.RemainAmount;
        GeneralSalesKeyID.push(g.KeyID + ':' + g.PaymentPrice);
      });
      setGData(gRowdata);
    }
    if (sRowdata && sRowdata.length > 0) {
      sRowdata.forEach(s => {
        saleTotal += s.RemainAmount;
        s.PaymentPrice = remainpaymentTotal > 0 ? Math.min(s.RemainAmount, remainpaymentTotal) : 0;
        remainpaymentTotal = remainpaymentTotal - s.RemainAmount;
        SeasonTicketKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
      setSData(sRowdata);
    }
    if (eRowdata && eRowdata.length > 0) {
      eRowdata.forEach(s => {
        saleTotal += s.RemainAmount;
        s.PaymentPrice = remainpaymentTotal > 0 ? Math.min(s.RemainAmount, remainpaymentTotal) : 0;
        remainpaymentTotal = remainpaymentTotal - s.RemainAmount;
        EtcSalesKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
      setEData(eRowdata);
    }
    if (tRowdata && tRowdata.length > 0) {
      tRowdata.forEach(s => {
        saleTotal += s.RemainAmount;
        s.PaymentPrice = remainpaymentTotal > 0 ? Math.min(s.RemainAmount, remainpaymentTotal) : 0;
        remainpaymentTotal = remainpaymentTotal - s.RemainAmount;
        TaxInvoiceKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
      setTData(tRowdata);
      setIsRental({ value: true, disabled: true });
    }
    if (tRowdata.length === 0) {
      setTData([]);
    }

    if (gRowdata.length === 0 && sRowdata.length === 0 && eRowdata.length === 0 && tRowdata.length === 0) {
      setIsRental({ value: true, disabled: true });
    }

    setFormData({
      SaleTotal: saleTotal,
      PaymentTotal: paymentTotal,
      Diff: saleTotal - paymentTotal,
      GeneralSalesKeyID: GeneralSalesKeyID.length > 0 ? GeneralSalesKeyID.join(';') : '',
      SeasonTicketKeyID: SeasonTicketKeyID.length > 0 ? SeasonTicketKeyID.join(';') : '',
      EtcSalesKeyID: EtcSalesKeyID.length > 0 ? EtcSalesKeyID.join(';') : '',
      TaxInvoiceKeyID: TaxInvoiceKeyID.length > 0 ? TaxInvoiceKeyID.join(';') : '',
      PGVANPaymentKeyID: PGVANPaymentKeyID.length > 0 ? PGVANPaymentKeyID.join(';') : '',
      CMSPaymentKeyID: CMSPaymentKeyID.length > 0 ? CMSPaymentKeyID.join(';') : '',
    });
  };

  // 확정 클릭시
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        SaleTotal,
        GeneralSalesKeyID,
        SeasonTicketKeyID,
        EtcSalesKeyID,
        TaxInvoiceKeyID,
        PGVANPaymentKeyID,
        CMSPaymentKeyID,
        Diff,
        Remark,
      } = formData;
      // if (Diff <= 0) {
      //   alert('입력한 반제금액 총액이 결제 금액과 일치하지 않습니다..');
      // }
      if (Diff <= 0) {
        alert('결제금액이 매출금액보다 적어야 합니다.');
      } else {
        // console.log({
        //   CostCenter: costCenter,
        //   InquiryDate: date,
        //   SalesPrice: SaleTotal,
        //   GeneralSalesKeyID,
        //   SeasonTicketKeyID,
        //   EtcSalesKeyID,
        //   TaxInvoiceKeyID,
        //   PGVANPaymentKeyID,
        //   CMSPaymentKeyID,
        //   Remark,
        //   RegisterUser: '',
        // });
        setLoadPanelVisible(true);
        await salesPayment
          .confirmAccountInfoPartial({
            CostCenter: costCenter,
            InquiryDate: date,
            SalesPrice: SaleTotal,
            GeneralSalesKeyID,
            SeasonTicketKeyID,
            EtcSalesKeyID,
            TaxInvoiceKeyID,
            PGVANPaymentKeyID,
            CMSPaymentKeyID,
            Remark,
            RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            setStatus('O');
            if (res.isOk) {
              notify(
                {
                  message: '확정되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              //닫기 및 전체 그리드 리프레쉬
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: JSON.parse(res.error.detail).sqlMessage,
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },
    [formData, costCenter, date, refresh],
  );

  // 닫기
  const onClose = () => {
    setGData([]);
    setSData([]);
    setEData([]);
    setTData([]);
    setIsRental({ value: false, disabled: false });
    setIsOpenbyPartialPayment(false);
    if (gGridRef.current && gGridRef.current.instance) {
      gGridRef.current.instance.refresh();
      gGridRef.current.instance.clearFilter();
    }

    if (sGridRef.current && sGridRef.current.instance) {
      sGridRef.current.instance.refresh();
      sGridRef.current.instance.clearFilter();
    }

    if (eGridRef.current && eGridRef.current.instance) {
      eGridRef.current.instance.refresh();
      eGridRef.current.instance.clearFilter();
    }

    if (tGridRef.current && tGridRef.current.instance) {
      tGridRef.current.instance.refresh();
      tGridRef.current.instance.clearFilter();
    }
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const handlePopupExternalSales = str => {
    //타 사업소 매출 가져오기 오픈 -> 일반권, 정기권 분리
    if (str === 'g') {
      setPopupExternalSales({
        save: false,
        visible: true,
        type: 'g',
      });
    } else if (str === 's') {
      setPopupExternalSales({
        save: false,
        visible: true,
        type: 's',
      });
    } else if (str === 'e') {
      setPopupExternalSales({
        save: false,
        visible: true,
        type: 'e',
      });
    } else {
      setPopupExternalSales({
        save: false,
        visible: true,
        type: 't',
      });
    }
  };

  const onCloseExternalSales = () => {
    setPopupExternalSales({
      ...popupExternalSales,
      visible: false,
    });
  };

  const onChangesChange = e => {
    if (e.length > 0) {
      CheckSaveData();
    }
  };

  const handleSwitch = e => {
    if (e.event && e.event.type === 'dxclick') {
      setIsRental({ ...isRental, value: e.value });
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            formData={formData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption="매출/결제 내역 부분대사" alignItemLabels colCount={5}>
              <GroupItem colSpan={4}>
                {gRowdata && gRowdata.length > 0 && (
                  <GroupItem>
                    <CommonDataGrid
                      gridRef={gGridRef}
                      className={'dx-card wide-card'}
                      dataSource={gdata}
                      showBorders={true}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      hoverStateEnabled={true}
                      keyExpr="KeyID"
                      height={200}
                      onCellPrepared={onCellPrepared}
                      isHeader={true}
                      isExcel={false}
                      isNotFilterRow
                      isInfiniteScroll={false}
                      headerBefore={[<span>{'매출 > 일반권'}</span>]}
                      headerAfter={[
                        <Button text="타사업소 매출 가져오기" onClick={() => handlePopupExternalSales('g')} />,
                      ]}
                    >
                      <Editing
                        mode="cell"
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}
                        onChangesChange={onChangesChange}
                      />
                      <Column
                        allowEditing={false}
                        dataField={'ApprovalDate'}
                        caption={'매출시간'}
                        customizeText={customizeText}
                      />
                      <Column allowEditing={false} dataField={'VehicleRegistrationNo'} caption={'차량번호'} />
                      <Column allowEditing={false} dataField={'StatusName'} caption={'매출상태'} />
                      <Column allowEditing={false} dataField={'CreditCardApprovalNo'} caption={'승인번호'} />
                      <Column allowEditing={false} dataField={'CreditCardNo'} caption={'카드번호'} />
                      <Column allowEditing={false} dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
                      <Column allowEditing={false} dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                      <Column allowEditing={true} dataField={'PaymentPrice'} caption={'반제금액'} format="#,##0 원" />
                      <Summary>
                        <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="RemainAmount"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                        <TotalItem column="PaymentPrice" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="PaymentPrice"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                      </Summary>
                    </CommonDataGrid>
                  </GroupItem>
                )}
                {sRowdata && sRowdata.length > 0 && (
                  <GroupItem>
                    <CommonDataGrid
                      gridRef={sGridRef}
                      className={'dx-card wide-card'}
                      dataSource={sdata}
                      showBorders={true}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      hoverStateEnabled={true}
                      keyExpr="KeyID"
                      height={200}
                      onCellPrepared={onCellPrepared}
                      isHeader={true}
                      isExcel={false}
                      isNotFilterRow
                      isInfiniteScroll={false}
                      headerBefore={[<span>{'매출 > 정기권'}</span>]}
                      headerAfter={[
                        <Button text="타사업소 매출 가져오기" onClick={() => handlePopupExternalSales('s')} />,
                      ]}
                    >
                      <Editing
                        mode="cell"
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}
                        onChangesChange={onChangesChange}
                      />
                      <Column
                        allowEditing={false}
                        dataField={'ApprovedDate'}
                        caption={'승인시간'}
                        customizeText={customizeText}
                      />
                      <Column allowEditing={false} dataField={'VehicleRegistrationNo'} caption={'차량번호'} />
                      <Column allowEditing={false} dataField={'StatusName'} caption={'매출상태'} />
                      <Column allowEditing={false} dataField={'FromDate'} caption={'시작일'} />
                      <Column allowEditing={false} dataField={'ToDate'} caption={'종료일'} />
                      <Column allowEditing={false} dataField={'PaymentTypeName'} caption={'매출유형'} />
                      <Column allowEditing={false} dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
                      <Column allowEditing={false} dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                      <Column allowEditing={true} dataField={'PaymentPrice'} caption={'반제금액'} format="#,##0 원" />
                      <Summary>
                        <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="RemainAmount"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                        <TotalItem column="PaymentPrice" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="PaymentPrice"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                      </Summary>
                    </CommonDataGrid>
                  </GroupItem>
                )}
                {eRowdata && eRowdata.length > 0 && (
                  <GroupItem>
                    <CommonDataGrid
                      gridRef={eGridRef}
                      className={'dx-card wide-card'}
                      dataSource={edata}
                      showBorders={true}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      hoverStateEnabled={true}
                      keyExpr="KeyID"
                      height={200}
                      onCellPrepared={onCellPrepared}
                      isHeader={true}
                      isExcel={false}
                      isNotFilterRow
                      isInfiniteScroll={false}
                      headerBefore={[<span>{'매출 > 기타'}</span>]}
                      headerAfter={[
                        <Button text="타사업소 매출 가져오기" onClick={() => handlePopupExternalSales('e')} />,
                      ]}
                    >
                      <Editing
                        mode="cell"
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}
                        onChangesChange={onChangesChange}
                      />
                      <Column
                        allowEditing={false}
                        dataField={'SalesDate'}
                        caption={'매출일'}
                        customizeText={customizeText}
                      />
                      <Column allowEditing={false} dataField={'StatusName'} caption={'매출상태'} />
                      <Column allowEditing={false} dataField={'EtcSalesTypeName'} caption={'고객유형'} />
                      <Column allowEditing={false} dataField={'CustomerName'} caption={'고객명'} />
                      <Column allowEditing={false} dataField={'PaymentTypeName'} caption={'결제유형'} />
                      <Column allowEditing={false} dataField={'Remark'} caption={'비고'} />
                      <Column
                        allowEditing={false}
                        dataField={'RegisterDate'}
                        caption={'등록일'}
                        customizeText={customizeText}
                      />
                      <Column allowEditing={false} dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
                      <Column allowEditing={false} dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                      <Column allowEditing={true} dataField={'PaymentPrice'} caption={'반제금액'} format="#,##0 원" />
                      <Summary>
                        <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="RemainAmount"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                        <TotalItem column="PaymentPrice" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="PaymentPrice"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                      </Summary>
                    </CommonDataGrid>
                  </GroupItem>
                )}

                {((tRowdata && tRowdata.length > 0) || isRental.value) && (
                  <GroupItem>
                    <CommonDataGrid
                      gridRef={tGridRef}
                      className={'dx-card wide-card'}
                      dataSource={tdata}
                      showBorders={true}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      hoverStateEnabled={true}
                      keyExpr="KeyID"
                      height={200}
                      onCellPrepared={onCellPrepared}
                      isHeader={true}
                      isExcel={false}
                      isNotFilterRow
                      isInfiniteScroll={false}
                      headerBefore={[<span>{'매출 > 세금계산서'}</span>]}
                      headerAfter={[
                        <Button text="타사업소 매출 가져오기" onClick={() => handlePopupExternalSales('t')} />,
                      ]}
                    >
                      <Editing
                        mode="cell"
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}
                        onChangesChange={onChangesChange}
                      />
                      <Column allowEditing={false} dataField={'SalesDate'} caption={'발행요청일'} width={100} />
                      <Column
                        allowEditing={false}
                        dataField={'TaxInvoiceStatusName'}
                        caption={'처리상태'}
                        width={100}
                      />
                      <Column allowEditing={false} dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
                      <Column allowEditing={false} dataField={'StatusName'} caption={'매출상태'} width={80} />
                      <Column
                        allowEditing={false}
                        dataField={'SalesPrice'}
                        caption={'금액'}
                        width={160}
                        format="#,##0 원"
                      />
                      <Column allowEditing={false} dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                      <Column allowEditing={true} dataField={'PaymentPrice'} caption={'반제금액'} format="#,##0 원" />
                      <Column allowEditing={false} dataField={'CustomerName'} caption={'사업자명'} width={200} />
                      <Column
                        allowEditing={false}
                        dataField={'CustomerCorpRegNumber'}
                        caption={'사업자번호'}
                        width={120}
                      />
                      <Column allowEditing={false} dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
                      <Column allowEditing={false} dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
                      <Column allowEditing={false} dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
                      <Column allowEditing={false} dataField={'SalesCount'} caption={'품목 수'} width={80} />
                      <Column allowEditing={false} dataField={'TaxPrice'} caption={'부가세'} width={80} />
                      <Column allowEditing={false} dataField={'UnitPrice'} caption={'공급가액'} width={80} />
                      <Column allowEditing={false} dataField={'CustomerCode'} caption={'회사코드'} width={120} />
                      <Column
                        allowEditing={false}
                        dataField={'CustomerManagerName'}
                        caption={'매입처 담당자명'}
                        width={100}
                      />
                      <Column allowEditing={false} dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
                      <Column allowEditing={false} dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
                      <Column allowEditing={false} dataField={'RegisterUser'} caption={'등록자'} width={80} />
                      <Column allowEditing={false} dataField={'RegisterDate'} caption={'등록일'} width={120} />
                      <Column allowEditing={false} dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} />
                      <Column allowEditing={false} dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
                      <Summary>
                        <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="RemainAmount"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                        <TotalItem column="PaymentPrice" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="PaymentPrice"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                      </Summary>
                    </CommonDataGrid>
                  </GroupItem>
                )}
                <SimpleItem
                  dataField="RentalSwitch"
                  editorType="dxSwitch"
                  location="right"
                  editorOptions={{
                    defaultValue: false,
                    readOnly: isRental.disabled,
                    onValueChanged: handleSwitch,
                  }}
                >
                  <Label text="타사업소 계산서 가져오기" location="left" />
                </SimpleItem>

                <SimpleItem itemType="empty" cssClass="division" />
                {pRowdata && pRowdata.length > 0 && (
                  <GroupItem>
                    <CommonDataGrid
                      // gridRef={gridRef} 엑셀기능 필요시 생성
                      className={'dx-card wide-card'}
                      dataSource={pRowdata}
                      showBorders={true}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      hoverStateEnabled={true}
                      keyExpr="KeyID"
                      height={200}
                      onCellPrepared={onCellPrepared}
                      isHeader={true}
                      isExcel={false}
                      isNotFilterRow
                      isInfiniteScroll={false}
                      headerBefore={[<span>{'결제 > PG & VAN 사'}</span>]}
                    >
                      <Column
                        allowEditing={false}
                        dataField={'TransactionDate'}
                        caption={'결제시간'}
                        customizeText={customizeText}
                      />
                      <Column allowEditing={false} dataField={'CardTypeName'} caption={'결제방식'} />
                      <Column allowEditing={false} dataField={'ApprovalTypeName'} caption={'결제유형'} />
                      <Column allowEditing={false} dataField={'StatusName'} caption={'매출상태'} />
                      <Column allowEditing={false} dataField={'ApprovalNo'} caption={'승인번호'} />
                      <Column allowEditing={false} dataField={'CreditCardNo'} caption={'카드번호'} />
                      <Column allowEditing={false} dataField={'SalesPrice'} caption={'결제금액'} format="#,##0 원" />
                      <Column allowEditing={false} dataField={'Remark1'} caption={'비고'} />
                      <Summary>
                        <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="SalesPrice"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                      </Summary>
                    </CommonDataGrid>
                  </GroupItem>
                )}
                {cRowdata && cRowdata.length > 0 && (
                  <GroupItem>
                    <CommonDataGrid
                      // gridRef={gridRef} 엑셀기능 필요시 생성
                      className={'dx-card wide-card'}
                      dataSource={cRowdata}
                      showBorders={true}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      hoverStateEnabled={true}
                      keyExpr="KeyID"
                      height={200}
                      onCellPrepared={onCellPrepared}
                      isHeader={true}
                      isExcel={false}
                      isNotFilterRow
                      isInfiniteScroll={false}
                      headerBefore={[<span>{'결제 > CMS 사'}</span>]}
                    >
                      <Column
                        allowEditing={false}
                        dataField={'TransactionDate'}
                        caption={'입금시간'}
                        customizeText={customizeText}
                      />
                      <Column allowEditing={false} dataField={'StatusName'} caption={'상태'} />
                      <Column allowEditing={false} dataField={'TransactionSummary'} caption={'Summary'} />
                      <Column
                        allowEditing={false}
                        dataField={'TransactionAmount'}
                        caption={'입금금액'}
                        format="#,##0 원"
                      />
                      <Column allowEditing={false} dataField={'BankName'} caption={'거래은행'} />
                      <Column allowEditing={false} dataField={'Branch'} caption={'Branch'} />
                      <Column allowEditing={false} dataField={'GiroCode'} caption={'지로'} />
                      <Column allowEditing={false} dataField={'ExtraField1'} caption={'비고'} />
                      <Column allowEditing={false} dataField={'BankAccountNo'} caption={'계좌'} />
                      <Summary>
                        <TotalItem column="TransactionAmount" summaryType="count" displayFormat="총 {0}건" />
                        <TotalItem
                          column="TransactionAmount"
                          summaryType="sum"
                          valueFormat="#,##0 원"
                          displayFormat="합계: {0}"
                        />
                      </Summary>
                    </CommonDataGrid>
                  </GroupItem>
                )}
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  dataField="SaleTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="매출금액" location="top" />
                </SimpleItem>
                <SimpleItem
                  dataField="PaymentTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="결제금액" location="top" />
                </SimpleItem>
                <SimpleItem
                  dataField="Diff"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="차이" location="top" />
                </SimpleItem>
                <SimpleItem dataField="Remark" editorType="dxTextBox">
                  <Label text="비고" location="top" />
                </SimpleItem>
                <GroupItem>
                  {/* <GroupItem colSpan={2}></GroupItem> */}
                  <ButtonItem>
                    <ButtonOptions
                      //   width={'100%'}
                      type={'default'}
                      useSubmitBehavior={true}
                      text="확정"
                    ></ButtonOptions>
                  </ButtonItem>
                  {/* <ButtonItem horizontalAlignment="left">
                    <ButtonOptions
                      width={'100%'}
                      type={'normal'}
                      text="취소"
                      onClick={onClose}
                    ></ButtonOptions>
                  </ButtonItem> */}
                </GroupItem>
              </GroupItem>
            </GroupItem>
          </Form>
          <Popup
            onHiding={onCloseExternalSales}
            visible={popupExternalSales.visible}
            showTitle={false}
            width="80%"
            height={870}
          >
            <ScrollView>
              <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
                <ExternalSalesForm
                  externalSalesFormRef={externalSalesFormRef}
                  popupExternalSales={popupExternalSales}
                  setPopupExternalSales={setPopupExternalSales}
                  setExternalGSales={setExternalGSales}
                  setExternalSSales={setExternalSSales}
                  setExternalESales={setExternalESales}
                  setExternalTSales={setExternalTSales}
                  date={date}
                />
              </div>
            </ScrollView>
          </Popup>
        </div>
      </form>
    </>
  );
});

export default React.memo(PartialPaymentForm);
