import React, { useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { COMPANYCODE } from 'constants/codes';
import { sampleData, columns } from './sample';
import { ticket, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';

const BlueWalnutPaymentPage = () => {
  const dataGridRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();
      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = () => {
    if (dataSource.length === 0) {
      alert('엑셀 파일을 업로드 해주세요.');
    } else {
      const result = confirm('<i>등록하시겠습니까?</i>', '등록');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          ticket
            .uploadVanCarPayExcel({
              ItemListJson: JSON.stringify(dataSource),
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '처리되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const handleImport = ExcelOrganization => {
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <h5>
            ★&nbsp; Blue Walnut 엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷
            &nbsp;엑셀 파일 업로드 &nbsp; ▷ &nbsp; 등록
          </h5>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <ExcelImport
            fieldColumn={[
              'A',
              'B',
              'C',
              'D',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K',
              'L',
              'M',
              'N',
              'O',
              'P',
              'Q',
              'R',
              'S',
              'T',
              'U',
              'V',
              'W',
              'X',
              'Y',
              'Z',
              'AA',
            ]}
            handleImport={e => handleImport(e.map(importMapFunc))}
            fieldColumnType={[
              'string',
              'string',
              'date',
              'time',
              'date',
              'time',
              'date',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
              'string',
            ]}
          />
        </div>
        <CommonDataGrid
          gridRef={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          height={700}
          isHeader={true}
          isExcel={true}
          excelOptions={{
            sheetName: 'sheet',
            fileName: 'bluewalnut업로드.xlsx',
          }}
          headerAfter={[
            <Button
              icon="check"
              text="등록"
              width={80}
              type="normal"
              stylingMode="contained"
              onClick={() => saveExcel()}
            />,
            <ExcelExport
              excelFields={headerText}
              excelData={sampleData}
              sheetName="Sheet"
              fileName="bluewalnut_sample.xls"
              buttonName="샘플파일 다운로드"
            />,
          ]}
          allowColumnReordering={true}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <LoadPanel enabled showPane={showPane} />
          <HeaderFilter visible={true} />
          <Column dataField={'가맹점명'} caption={'가맹점명'} hidingPriority={4} />
          <Column dataField={'M I D'} caption={'MID'} hidingPriority={4} />
          <Column dataField={'거래일자'} caption={'거래일자'} hidingPriority={4} />
          <Column dataField={'거래시간'} caption={'거래시간'} hidingPriority={4} />
          <Column dataField={'취소일자'} caption={'취소일자'} hidingPriority={4} />
          <Column dataField={'취소시간'} caption={'취소시간'} hidingPriority={4} />
          <Column dataField={'매입일자'} caption={'매입일자'} hidingPriority={4} />
          <Column dataField={'거래금액'} caption={'거래금액'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'원거래금액'} caption={'원거래금액'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'부분취소'} caption={'부분취소'} hidingPriority={4} />
          <Column dataField={'컵보증금'} caption={'컵보증금'} hidingPriority={4} />
          <Column dataField={'매입사'} caption={'매입사'} hidingPriority={4} />
          <Column dataField={'발급사'} caption={'발급사'} hidingPriority={4} />
          <Column dataField={'카드번호'} caption={'카드번호'} hidingPriority={4} />
          <Column dataField={'승인번호'} caption={'승인번호'} hidingPriority={4} />
          <Column dataField={'무이자구분'} caption={'무이자구분'} hidingPriority={4} />
          <Column dataField={'할부'} caption={'할부'} hidingPriority={4} />
          <Column dataField={'구매자명'} caption={'구매자명'} hidingPriority={4} />
          <Column dataField={'사용자ID'} caption={'사용자ID'} hidingPriority={4} />
          <Column dataField={'상품명'} caption={'상품명'} hidingPriority={4} />
          <Column dataField={'주문번호'} caption={'주문번호'} hidingPriority={4} />
          <Column dataField={'인 증'} caption={'인증'} hidingPriority={4} />
          <Column dataField={'TID'} caption={'TID'} hidingPriority={4} />
          <Column dataField={'거래상태'} caption={'거래상태'} hidingPriority={4} />
          <Column dataField={'매입상태'} caption={'매입상태'} hidingPriority={4} />
          <Column dataField={'팩토링구분'} caption={'팩토링구분'} hidingPriority={4} />
          <Column dataField={'서브몰아이디'} caption={'서브몰아이디'} hidingPriority={4} />
          <Summary>
            <TotalItem column="가맹점명" summaryType="count" displayFormat="총 {0}건" />
            <TotalItem column="거래금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          </Summary>
        </CommonDataGrid>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default BlueWalnutPaymentPage;
