import React, { useImperativeHandle, useRef, useCallback } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';

const RemarkPopup = React.forwardRef((props, ref) => {
  const { setPopupVisible, handleRemarkSubmit } = props;
  const formData = useRef({});

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const close = () => {
    setPopupVisible(false);
    formData.current = {};
  };

  const open = () => {
    setPopupVisible(true);
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const remark = formData.current.Remark;
      handleRemarkSubmit(remark);
      formData.current = {};
    },
    [handleRemarkSubmit],
  );

  return (
    <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
      <form className="add-sales-master-form" onSubmit={onSubmit}>
        <Form
          formData={formData.current}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={close}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={'가수금 등록'}>
            <SimpleItem dataField="Remark" editorType="dxTextArea">
              <Label text="비고" location="top" visible={true} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'확인'}></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={close}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
});

export default RemarkPopup;
