import React, { useEffect, useState, useCallback, useRef } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import CommonDataGrid from 'common/default-data-grid';
import { getNormalDateFormat } from 'utils/common';
import { parking, salesPayment, error } from 'api';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import ComplexPaymentForm from './component/complex-payment-form';
import PartialPaymentForm from './component/partial-payment-form';
import ScrollView from 'devextreme-react/scroll-view';
import DateBox from 'devextreme-react/date-box';
import CMS from './component/cms';
import SuspenseReceipt from './component/suspenseReceipt';
import Sales from './component/sales';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import './check-sales-payment.scss';
import axios from 'axios';
import $ from 'jquery';

const AcquiredSalesChecker = () => {
  const [isOpenbyComplexPayment, setIsOpenbyComplexPayment] = useState(false);
  const [isOpenbyPartialPayment, setIsOpenbyPartialPayment] = useState(false);
  const [sRowdata, setSRowdata] = useState([]);
  const [cRowdata, setCRowdata] = useState([]);
  const [srRowdata, setSRRowdata] = useState([]);
  const [salesType, setSalesType] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [status, setStatus] = useState('');

  //검색조건
  const thisMonth = moment(new Date()).format('yyyy-MM');
  const [dateValue, setDateValue] = useState(thisMonth);
  const costCenter = 'BtoB';

  const forwardSalesRef = useRef({});
  const forwardCmsRef = useRef({});
  const forwardSusprcptRef = useRef({});

  const [selectedID, setSelectedID] = useState('');
  const [selectedAccountingNo, setSelectedAccountingNo] = useState('');

  useEffect(() => {
    api();
  }, []);

  const complexPaymentRef = useRef({});
  const partialPaymentRef = useRef({});
  const SalesRef = useRef({});
  const cmsRef = useRef({});
  const susprcptRef = useRef({});

  const [dataSourcePayment, SetDataSourcePayment] = useState('');

  //   복합 대사
  const handleComplexPayment = () => {
    const s = sRowdata.length;
    const c = cRowdata.length;
    const isSState = sRowdata.filter(s => s.Status === 'A');
    const isCState = cRowdata.filter(c => c.Status === 'A');

    if (c === 0 && s === 0) {
      alert('매출/결제 내역을 선택해주세요');
    } else if (s === 0) {
      alert('매출 내역을 선택해주세요');
    } else if (c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (isSState.length > 0 || isCState.length > 0) {
      alert('매출 상태가 대사 전인 매출만 선택해주세요.');
    } else {
      setIsOpenbyComplexPayment(true);
      complexPaymentRef.current.open(sRowdata, cRowdata);
    }
  };

  // 부분 대사
  const handlePartialPayment = () => {
    const s = sRowdata.length;
    const c = cRowdata.length;
    const isSState = sRowdata.filter(s => s.Status === 'A');
    const isCState = cRowdata.filter(c => c.Status === 'A');

    if (c === 0 && s === 0) {
      alert('매출/결제 내역을 선택해주세요');
    } else if (s === 0) {
      alert('매출 내역을 선택해주세요');
    } else if (c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (isSState.length > 0 || isCState.length > 0) {
      alert('매출 상태가 대사 전인 매출만 선택해주세요.');
    } else {
      setIsOpenbyPartialPayment(true);
      partialPaymentRef.current.open(sRowdata, cRowdata);
    }
  };
  useEffect(() => {
    SetDataSourcePayment(null);
    SalesRef?.current?.instance?.clearSelection();
    (async () => {
      if (selectedID !== '') {
        await axios
          .post(`aggregation/history/getPaymentHistoryByKeyID`, {
            KeyID: selectedID,
            AccountingDate: dateValue,
          })
          .then(res => {
            SetDataSourcePayment(res.data[0]);
            const accountingNoList = res.data[0].map(a => a.AccountingNo);
            if (accountingNoList.length > 0) {
              forwardCmsRef.current.focusChange(accountingNoList);
            } else {
              forwardCmsRef.current.focusChange([]);
            }
          });
      }
    })();
  }, [selectedID]);

  useEffect(() => {
    cmsRef?.current?.instance?.clearSelection();
    if (selectedAccountingNo !== '') {
      forwardSalesRef.current.focusChange(selectedAccountingNo);
    }
  }, [selectedAccountingNo]);

  const api = useCallback(async () => {
    const salesType = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['SalesType'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'SalesType') {
          salesType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
      setSalesType(salesType);
    }
  }, []);

  const refresh = () => {
    if (SalesRef.current.instance) {
      SalesRef.current.instance.refresh();
      SalesRef.current.instance.clearSelection();
    }

    if (cmsRef.current.instance) {
      cmsRef.current.instance.refresh();
      cmsRef.current.instance.clearSelection();
    }
    if (susprcptRef.current.instance) {
      susprcptRef.current.instance.refresh();
      susprcptRef.current.instance.clearSelection();
    }

    setSelectedID('');
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onClose = () => {
    setIsOpenbyComplexPayment(false);
    setIsOpenbyPartialPayment(false);
  };

  const handleToDate = e => {
    const salesMonth = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setDateValue(salesMonth);
  };

  const onCellPrepared = useCallback(e => {
    cellStatus(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'KeyID';
  const mergeColumn = ['HiParkingID', 'CostCenterName', 'SalesTypeName', 'DetailSalesTypeName'];
  const cellStatus = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };

  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const onCancelClick = useCallback(e => {
    const { value } = e.itemData;
    const result = confirm('<i>선택한 대사 이력을 취소하시겠습니까?</i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        // 대사 취소
        await salesPayment
          .cancelCostCenterAccountInfo({
            AccountingNo: value,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              notify(
                {
                  message: '취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
              setStatus('O');
            } else {
              // error
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  }, []);

  return (
    <div id="sp-loading">
      <h2 className={'content-block detail-title'}>매입 매출 대사</h2>
      <div className={'content-block aquired-service-check'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div className="label">매출월 :</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                dataField="date"
                value={dateValue}
                onValueChanged={handleToDate}
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="plus" text="매출/결제 부분 대사" onClick={handlePartialPayment} disabled={status === 'A'} />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button
                icon="plus"
                text="매출/결제 내역 복합 대사"
                onClick={handleComplexPayment}
                disabled={status === 'A'}
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button
                icon="refresh"
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  refresh();
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '50%' }}>
              <TabPanel>
                <Item title="매입매출" badge={sRowdata.length}>
                  <Sales
                    ref={forwardSalesRef}
                    SalesRef={SalesRef}
                    setSRowdata={setSRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                  />
                </Item>
              </TabPanel>
            </div>
            <div style={{ maxWidth: '50%' }}>
              <TabPanel>
                <Item title="CMS" badge={cRowdata.length}>
                  <CMS
                    ref={forwardCmsRef}
                    cmsRef={cmsRef}
                    setCRowdata={setCRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    SetDataSourcePayment={SetDataSourcePayment}
                    refresh={refresh}
                  />
                </Item>
                <Item title="가수금" badge={srRowdata.length}>
                  <SuspenseReceipt
                    ref={forwardSusprcptRef}
                    susprcptRef={susprcptRef}
                    SalesRef={SalesRef}
                    cmsRef={cmsRef}
                    setSRRowdata={setSRRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    SetDataSourcePayment={SetDataSourcePayment}
                    refresh={refresh}
                  />
                </Item>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
      <div className={'content-block'}>
        <div>
          <div className={'dx-card'} style={{ padding: '20px 40px' }}>
            <div className="label" style={{ padding: '5px 0px' }}>
              매출 수금 내역 상세 조회
            </div>
            <CommonDataGrid
              className={'dx-card wide-card accounting-payment-report'}
              dataSource={dataSourcePayment}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              // onCellPrepared={onCellPrepared}
              height={350}
              isNotFilterRow
              isInfiniteScroll={false}
              isHeader={false}
              isExcel={false}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '수금 내역 상세 조회.xlsx',
              }}
            >
              {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
              {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
              <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
              <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
              {/* <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} /> */}
              <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} customizeText={customizeText} />
              <Column dataField={'PaymentAmount'} caption={'반제금액'} alignment={'center'} format="#,##0 원" />
              <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
              <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
              <Column dataField={'AccountingType'} caption={'대사구분'} alignment={'center'} />
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
              <Column
                dataField={'RegisterDate'}
                caption={'대사등록일'}
                alignment={'center'}
                customizeText={customizeText}
              />
              <Summary>
                <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
                <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                <TotalItem column="PaymentAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>
      </div>
      <Popup onHiding={onClose} visible={isOpenbyComplexPayment} showTitle={false} width="80%" height={750} id="test">
        <ScrollView>
          <div id="complex-popup" className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <ComplexPaymentForm
              ref={complexPaymentRef}
              setIsOpenbyComplexPayment={setIsOpenbyComplexPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              cRowdata={cRowdata}
              sRowdata={sRowdata}
              salesType={salesType}
              date={dateValue}
              costCenter={costCenter}
              refresh={refresh}
              setStatus={setStatus}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onHiding={onClose} visible={isOpenbyPartialPayment} showTitle={false} width="80%" height={750} id="test">
        <ScrollView>
          <div id="partial-popup" className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <PartialPaymentForm
              ref={partialPaymentRef}
              setIsOpenbyPartialPayment={setIsOpenbyPartialPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              sRowdata={sRowdata}
              cRowdata={cRowdata}
              salesType={salesType}
              date={dateValue}
              costCenter={costCenter}
              refresh={refresh}
              setStatus={setStatus}
            />
          </div>
        </ScrollView>
      </Popup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={
          isOpenbyComplexPayment
            ? {
                of: '.dx-popup-wrapper',
              }
            : { of: '.content' }
        }
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default AcquiredSalesChecker;
