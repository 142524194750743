// 메뉴얼 문서 링크
export const manualLink = 'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#';
// 사업소 주차장 정보 관리
export const parkingMngManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.4d34og8';
// 정기권 회원 관리
export const seasonticketMemberMngManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.17dp8vu';
// 정기권 발행 관리
export const seasonticketMngManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.3rdcrjn';
// 정기권 엑셀 업로드
export const seasonticketUploadManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.26in1rg';
// 기태매출 관리
export const etcSalesMngManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.1ksv4uv';
export const rentalSalesMngManualLink =
  'https://docs.google.com/presentation/d/1iPaN0uyW5qKGJ_chMye70GL6pssXTL3R91O0z1-x3u4/edit?usp=sharing';
// 매출 대사
export const checkSalesPaymentManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.35nkun2';
// 청구 세금계산서
export const taxinvoiceManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.44sinio';
// 영수 세금계산서
export const taxinvoiceReceiptManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.2jxsxqh';
// 미처리매출/결제마감
export const salesClosePendingManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.z337ya';
// 매출 취소 요청
export const cancelSalesManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.3j2qqm3';
// 매출 취소 확정
export const cancelSalesConfirmManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.1y810tw';
// 현금영수증 발행
export const cashbillManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.b0fglvb8d35x';
// 가수금 관리
export const suspenseReceiptManualLink =
  'https://docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#heading=h.2heekzmjzdtx';
