import axios from 'axios';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');

export async function getSummaryByCostCenter(params) {
  try {
    const result = await axios.post(`aggregation/searchSalesAggregationSummaryByCostCenter`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일반권 매출 대사관리
export async function getGSalesDataDetail(params) {
  try {
    const result = await axios.post(`aggregation/getParkingSalesDataDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 일반권 매출 대사관리
export async function getGSalesDataDetailWOLock(params) {
  try {
    const result = await axios.post(`aggregation/getParkingSalesDataDetailWOLock`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 매출 대사관리
export async function getSSalesDataDetail(params) {
  try {
    const result = await axios.post(`aggregation/getSeasonTicketSalesDataDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 대사관리
export async function getEtcSalesDataDetail(params) {
  try {
    const result = await axios.post(`aggregation/getOtherTicketSalesDataDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금계산서 발행 대사관리
export async function getTaxInvoiceListForAccounting(params) {
  try {
    const result = await axios.post(`aggregation/getTaxInvoiceListForAccounting`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// PG & VAN사 결제이력
export async function getPaymentDataDetail(params) {
  try {
    const result = await axios.post(`aggregation/getPGVANAggregationDataByCostCenterDayDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// VAN사 결제이력
export async function getVanDataDetail(params) {
  try {
    const result = await axios.post(`aggregation/getVANAggregationDataCostCenterDayDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// CMS 결제이력
export async function getCPaymentDataDetail(params) {
  try {
    const result = await axios.post(`aggregation/getCMSAggregationDataByCostCenterDayDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 자동 대사
export async function processAutoAccounting(params) {
  try {
    const result = await axios.post(`aggregation/processAutoAccounting`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 결제 내역 복합 대사
export async function confirmAccountInfoDetail(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 결제 내역 부분 대사
export async function confirmAccountInfoPartial(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoPartial`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 결제 내역 수기 대사
export async function confirmManualAccountInfo(params) {
  try {
    const result = await axios.post(`aggregation/confirmManualAccountInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일 마감
export async function confirmDailyAccountInfo(params) {
  try {
    const result = await axios.post(`aggregation/confirmDailyAccountInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일 마감 취소
export async function cancelDailyAccountInfo(params) {
  try {
    const result = await axios.post(`aggregation/cancelDailyAccountInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일 마감 취소
export async function cancelDailyAccountStatus(params) {
  try {
    const result = await axios.post(`aggregation/cancelDailyAccountStatus`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 대사 작업 요약
export async function getAccountingSummary(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일반권 처리 결과 > 대사 작업 이력
export async function getAccountingGeneralSalesSummary(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingGeneralSalesSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일반권 처리 결과 > 상세 조회 > PG
export async function getAccountingGeneralSalesDetailPG(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingGeneralSalesDetailPG`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일반권 처리 결과 > 상세 조회 > VAN
export async function getAccountingGeneralSalesDetailVAN(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingGeneralSalesDetailVAN`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일반권 처리 결과 > 상세 조회 > CMS
export async function getAccountingGeneralSalesDetailCMS(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingGeneralSalesDetailCMS`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 처리 결과 > 대사 작업 이력
export async function getAccountingSeasonTicketSalesSummary(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingSeasonTicketSalesSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 정기권 처리 결과 > 대사 작업 이력
export async function getAccountingOtherTicketSalesSummary(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingOtherTicketSalesSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 처리 결과 > 대사 작업 이력
export async function getAccountingTaxInvoiceSummary(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingTaxInvoiceSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 처리 결과 > 상세 조회 > PG
export async function getAccountingSeasonTicketDetailPG(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingSeasonTicketDetailPG`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 처리 결과 > 상세 조회 > VAN
export async function getAccountingSeasonTicketDetailVAN(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingSeasonTicketDetailVAN`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 처리 결과 > 상세 조회 > CMS
export async function getAccountingSeasonTicketDetailCMS(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingSeasonTicketDetailCMS`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 미처리 > 일반권
export async function getAccountingOutstandingGeneralSales(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingOutstandingGeneralSales`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 미처리 > pg
export async function getAccountingOutstandingPG(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingOutstandingPG`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 미처리 > van
export async function getAccountingOutstandingVAN(params) {
  try {
    const result = await axios.post(`aggregation/history/getAccountingOutstandingVAN`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 환불 처리
export async function cancelPayment(params) {
  try {
    const result = await axios.post(`aggregation/cancelPaymentByKeyID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 유형 관리
export async function getEtcSalesHistory(params) {
  try {
    const result = await axios.post(`seasonticket/searchOtherTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 등록
export async function addEtcSalesHistory(params) {
  try {
    const result = await axios.post(`seasonticket/insertOtherTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 등록 후
export async function addEtcSalesTicketID(params) {
  try {
    const result = await axios.post(`aggregation/registerSalesChangeOver`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 취소
export async function cancelEtcSalesHistory(params) {
  try {
    const result = await axios.post(`seasonticket/cancelOtherTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 수정
export async function updateEtcSalesHistory(params) {
  try {
    const result = await axios.post(`seasonticket/updateOtherTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 1개 조회
export async function searchOtherTicketIssueHistoryByID(params) {
  try {
    const result = await axios.post(`seasonticket/searchOtherTicketIssueHistoryByID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 확정
export async function confirmOtherTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/confirmOtherTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 매출 대사 관리 > 일반권 매출 등록
export async function insertGeneralSalesInfo(params) {
  try {
    const result = await axios.post(`aggregation/insertGeneralSalesInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 미처리 매출 > 매출 잡손실 처리
export async function registerSalesLoss(params) {
  try {
    const result = await axios.post(`aggregation/registerSalesLoss`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 미처리 매출 > 결제 잡이익 처리
export async function registerSalesProfit(params) {
  try {
    const result = await axios.post(`aggregation/registerSalesProfit`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 미처리 매출 > 결제 잡이익 처리
export async function registerSalesSetOff(params) {
  try {
    const result = await axios.post(`aggregation/registerSalesSetOff`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 미처리 매출 > 매출 처리 완료
export async function registerSalesClear(params) {
  try {
    const result = await axios.post(`aggregation/registerSalesClear`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 발행
export async function insertTaxInvoice(params) {
  try {
    const result = await axios.post(`aggregation/insertTaxInvoice`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 세금 계산서 발행
export async function insertChangeTaxInvoice(params) {
  try {
    const result = await axios.post(`aggregation/insertChangeTaxInvoice`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 영수 세금 계산서 발행
export async function insertTaxInvoiceReceiving(params) {
  try {
    const result = await axios.post(`aggregation/insertTaxInvoiceReceiving`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function checkRefundTaxInvoiceInfo(params) {
  try {
    const result = await axios.post(`aggregation/checkRefundTaxInvoiceInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 발행 조회
export async function getTaxInvoiceListByID(params) {
  try {
    const result = await axios.post(`aggregation/getTaxInvoiceListByID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 매출 취소 - 매출 취소 요청
export async function insertRefundInfo(params) {
  try {
    const result = await axios.post(`aggregation/insertRefundInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 2022 매출 취소 - 매출 취소 요청
export async function insertRefundInfo2022(params) {
  try {
    const result = await axios.post(`aggregation/insertRefundInfo2022`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 매출 취소 확정 - 매출 취소 요청 조회
export async function getRefundInfo(params) {
  try {
    const result = await axios.post(`/aggregation/getRefundInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 매출 취소 확정 - 매출 취소 요청 확정
export async function confirmRefundInfo(params) {
  try {
    const result = await axios.post(`/aggregation/confirmRefundInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 매출 취소 확정 - 매출 취소 요청 수정
export async function updateRefundInfo(params) {
  try {
    const result = await axios.post(`/aggregation/updateRefundInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 매출 취소 확정 - 매출 취소 요청 검토
export async function reviewRefundInfo(params) {
  try {
    const result = await axios.post(`/aggregation/reviewRefundInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금계산서 발행 대사
export async function confirmAccountInfoTaxInvoice(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoTaxInvoice`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금계산서 발행  > 취소
export async function cancelTaxInvoiceListByID(params) {
  try {
    const result = await axios.post(`aggregation/cancelTaxInvoiceListByID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 매출 대사 관리 > 대사 취소
export async function cancelCostCenterAccountInfo(params) {
  try {
    const result = await axios.post(`aggregation/cancelCostCenterAccountInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 발행 > 매입자 정보
export async function getCustomerMaster() {
  try {
    const result = await axios.post(`master/searchCustomerMaster`, {
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 상세보기 > 수정
export async function updateTaxInvoiceListByID(params) {
  try {
    const result = await axios.post(`aggregation/updateTaxInvoiceListByID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 > 매출 내역..
export async function getTaxInvoiceItems(params) {
  try {
    const result = await axios.post(`aggregation/getTaxInvoiceItems`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 발행 > 사업자 체크
export async function getTaxInvoiceCustomerBySales(params) {
  try {
    const result = await axios.post(`aggregation/getTaxInvoiceCustomerBySales`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금 계산서 > 매출 내역..
export async function getEmptyTaxInvoiceItems(params) {
  try {
    const result = await axios.post(`aggregation/getEmptyTaxInvoiceItems`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금계산서 오류 내역 조회
export async function getTaxInvoiceErrorList(params) {
  try {
    const result = await axios.post(`aggregation/getTaxInvoiceErrorList`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 일반권 매출 취소
export async function getGSalesDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getParkingSalesDataDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 매출 취소
export async function getSSalesDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getSeasonTicketSalesDataDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 기타 매출 취소
export async function getEtcSalesDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getOtherTicketSalesDataDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 기타 매출 현금영수증 발행 현금수금
export async function getEtcSalesDataDetailCashConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getOtherTicketSalesDataDetailCashConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 세금계산서 취소
export async function getTaxDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getTaxInvoiceListConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 영수 세금계산서 발행 > 일반권
export async function getParkingSalesDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getParkingSalesDataDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 현금영수증 발행 > 일반권
export async function getParkingSalesDataDetaiCashConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getParkingSalesDataDetailCashConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 영수 세금계산서 발행 > 정기권
export async function getSeasonTicketSalesDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getSeasonTicketSalesDataDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 현금영수증 발행 > 정기권
export async function getSeasonTicketSalesDataDetailCashConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getSeasonTicketSalesDataDetailCashConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 영수 세금계산서 발행 > 기타
export async function getOtherTicketSalesDataDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getOtherTicketSalesDataDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 청구 세금계산서 발행 - 금액 일괄 수정
export async function updateSeasonTicketPriceMulti(params) {
  try {
    const result = await axios.post(`seasonticket/updateSeasonTicketPriceMulti`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// b2b 결제 내역 복합 대사
export async function confirmAccountInfoDetailBtoB(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoDetailBtoB`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// b2b 결제 내역 복합 대사
export async function confirmAccountInfoPartialBtoB(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoPartialBtoB`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

//b2b 공유서비스 매출대사
export async function getBtoBAcquiredSalesDetail(params) {
  try {
    const result = await axios.post(`aggregation/btob/getBtoBAcquiredSalesDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 청구 세금계산서 메일 재전송 - 외부용
export async function resendTaxOut(params) {
  console.log(params);
  try {
    const result = await axios.post(`aggregation/resendTaxInvoiceEmail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 청구 세금계산서 메일 재전송 - 내부용
export async function resendTaxInner(params) {
  console.log(params);
  try {
    const result = await axios.post(`aggregation/resendTaxInvoiceEmail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSalesAggregationSummaryByDayType(params) {
  try {
    const result = await axios.post(`aggregation/getSalesAggregationSummaryByDayType`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSalesLockMasterList(params) {
  try {
    const result = await axios.post(`aggregation/setting/getSalesLockMasterList`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateSalesLockMaster(params) {
  try {
    const result = await axios.post(`aggregation/setting/updateSalesLockMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function registerInvoiceSetOff(params) {
  try {
    const result = await axios.post(`aggregation/registerInvoiceSetOff`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getCostReportSampleData(params) {
  try {
    const result = await axios.post(`aggregation/getCostReportSampleData`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getCostReportRawData(params) {
  try {
    const result = await axios.post(`aggregation/getCostReportRawData`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 임시 추가
export async function deleteSalesAPI(params) {
  try {
    const result = await axios.post(`aggregation/deleteSalesAPI`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function deletePaymentByKeyID(params) {
  try {
    const result = await axios.post(`aggregation/deletePaymentByKeyID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function registerCostCenterAccountSetOff(params) {
  try {
    const result = await axios.post(`aggregation/registerCostCenterAccountSetOff`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getRentalSalesMaster(params) {
  try {
    const result = await axios.post(`aggregation/getRentalSalesMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getRentalSalesInvoiceHistory(params) {
  try {
    const result = await axios.post(`aggregation/getRentalSalesInvoiceHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertRentalMaster(params) {
  try {
    const result = await axios.post(`aggregation/rental/insertRentalMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function updateRentalMaster(params) {
  try {
    const result = await axios.post(`aggregation/rental/updateRentalMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function deleteRentalMaster(params) {
  try {
    const result = await axios.post(`aggregation/rental/deleteRentalMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertNewRentalInvoice(params) {
  try {
    const result = await axios.post(`aggregation/rental/insertNewRentalInvoice`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertCMSReplacementDataJson(params) {
  try {
    const result = await axios.post(`aggregation/insertCMSReplacementDataJson`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function checkCMSReplacementDataJson(params) {
  try {
    const result = await axios.post(`aggregation/checkCMSReplacementDataJson`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function checkNewRentalInvoice(params) {
  try {
    const result = await axios.post(`aggregation/rental/checkNewRentalInvoice`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
