import React, { useCallback, useState, useEffect } from 'react';
import Form, { GroupItem, SimpleItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { system } from '../../../../api';
import notify from 'devextreme/ui/notify';
import ScrollView from 'devextreme-react/scroll-view';
import CheckBox from 'devextreme-react/check-box';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';

const PreviewPopup = props => {
  const { setPopupVisible, tmpNoticeFileInfo } = props;
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    if (tmpNoticeFileInfo && tmpNoticeFileInfo.FileKey) {
      (async () => {
        const result = await system.readFiles({ FileKey: tmpNoticeFileInfo.FileKey });
        if (result.isOk) {
          const blob = new Blob([result.data], { type: 'text/html' });
          const blobUrl = URL.createObjectURL(blob);
          setBlobUrl(blobUrl);
        }
      })();
    }
  }, [tmpNoticeFileInfo]);

  const onClose = () => {
    setPopupVisible(false);
  };

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      const { FileKey } = tmpNoticeFileInfo;
      (async () => {
        const result = await system.updateFileCategoryCode({
          FileKey,
          FileCategoryCode: 'FC_001',
        });
        if (result.isOk) {
          onClose();
          notify(
            {
              message: '적용 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
        } else {
          alert(JSON.parse(result.error.detail).sqlMessage);
        }
      })();
    },
    [tmpNoticeFileInfo],
  );
  const onIframeLoad = () => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const paragraphs = iframeDocument.getElementsByTagName('p');
      for (let i = 0; i < paragraphs.length; i++) {
        paragraphs[i].style.margin = '2px';
      }
      const unorderedLists = iframeDocument.getElementsByTagName('ul');
      for (let i = 0; i < unorderedLists.length; i++) {
        unorderedLists[i].style.margin = '0';
      }
      const orderedLists = iframeDocument.getElementsByTagName('ol');
      for (let i = 0; i < orderedLists.length; i++) {
        orderedLists[i].style.margin = '0';
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form labelLocation="left" labelMode="outside" optionalMark="optional" stylingMode="outlined">
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={'공지사항 미리보기'}>
            <ScrollView height="100%" width="100%" direction="both">
              <iframe
                title="공지사항"
                src={blobUrl}
                style={{ width: '100%', height: '390px', border: 'none' }}
                onLoad={onIframeLoad}
              ></iframe>
            </ScrollView>
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem>
              <CheckBox defaultValue={false} text="오늘 하루 보지 않음" />
            </SimpleItem>
            <ButtonItem>
              <ButtonOptions width={'30%'} type={'default'} useSubmitBehavior={true} text={'적용하기'}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
};

export default PreviewPopup;
