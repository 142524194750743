export const cellStatus = (data, displayValue, cellElement) => {
  if (data) {
    const { StatusName, Status } = data;
    const tr = cellElement;
    if (displayValue === StatusName) {
      cellElement.classList.add('badge-box');
      const innerBox = document.createElement('div');
      cellElement.appendChild(innerBox);
      if (Status === 'A' || Status === 'C') {
        innerBox.classList.add('badge-green');
        cellElement.style.color = 'rgb(51 202 139)';
        cellElement.style.textAlign = 'center';
      } else if (Status === 'O') {
        innerBox.classList.add('badge-blue');
        cellElement.style.color = '#629bdc';
        cellElement.style.textAlign = 'center';
      } else if (Status === 'P') {
        cellElement.style.color = '#f05b41';
        cellElement.style.textAlign = 'center';
      } else if (Status === 'N') {
        innerBox.classList.add('badge-red');
        cellElement.style.color = '#f05b41';
        cellElement.style.textAlign = 'center';
      } else if (Status === 'E') {
        cellElement.style.color = '#bdbdbd';
        cellElement.style.textAlign = 'center';
      }
    } else {
      // 등록 취소인 경우 회색 처리
      if (Status === 'E' && tr) {
        tr.classList.remove('dx-selection');
        tr.classList.add('custom-hover');
      }
    }
  }
};
