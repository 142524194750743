import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';

import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import CustomStore from 'devextreme/data/custom_store';
import { TextBox } from 'devextreme-react/text-box';
import ScrollView from 'devextreme-react/scroll-view';

import CommonDataGrid from 'common/default-data-grid';
import AddCompany from './component/addCompany';

const Companymng = () => {
  const [popupVisible, setPopupVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchParams, setSearchParams] = useState({
    CompanyBrand: '',
    RepresentativeName: '',
    PhoneNumber: '',
    Email: '',
  });
  const [text, setText] = useState({
    CompanyBrand: '',
    RepresentativeName: '',
    PhoneNumber: '',
    Email: '',
  });

  const gridRef = useRef({});
  const addCompanyRef = useRef({});

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'CompanyID',
      loadMode: 'raw',
      load: async () => {
        try {
          // 이제 searchParams 추가해줘여지..
          const result = await axios.post(`system/company/list`, {});
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams]);

  const togglePopup = (type, data) => {
    // 일단 회사등록은 가려두었는데, 차후에 추가될 수 도 있다.
    setIsUpdate(!!(type === 'update'));
    setPopupVisibility(true);
    addCompanyRef.current.open(type, data);
  };

  const editCellRender = e => {
    return <Button icon="edit" type="normal" stylingMode="contained" onClick={() => togglePopup('update', e.data)} />;
  };

  const onChangeSearchText = e => {
    console.log('e >', e.component._label._props.text);
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    console.log('component >', e.component._label._props.text);
    console.log('text >>', text);
    setSearchParams(text);
  };

  const onClose = () => {
    setPopupVisibility(false);
  };

  console.log('gridRef >', gridRef.current.instance);

  return (
    <>
      <div>
        <h2 className={'content-block'}>회사 관리</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              height={700}
              isHeader={true}
              isExcel={false}
              isInfiniteScroll={false}
              headerBefore={[
                <TextBox
                  label="CompanyBrand"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="회사명"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
                <TextBox
                  label="RepresentativeName"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="대표자명"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
                <TextBox
                  label="PhoneNumber"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="연락처"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
                <TextBox
                  label="Email"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="이메일"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
              ]}
              // headerAfter={[<Button text="회사 등록" onClick={() => togglePopup('add')} />]}
            >
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
              <Column dataField={'CompanyBrand'} caption={'회사명'} />
              <Column dataField={'CompanyName'} caption={'회사명(한글)'} />
              <Column dataField={'CompanyEnName'} caption={'회사명(영어)'} />
              <Column dataField={'RepresentativeName'} caption={'대표자명'} />
              <Column dataField={'PhoneNumber'} alignment="left" caption={'연락처'} />
              <Column dataField={'Email'} caption={'이메일'} />
              <Column dataField={'CreateDate'} caption={'생성일'} dataType={'date'} format="yyyy-MM-dd HH:MM:ss" />
              <Column dataField={'UpdateDate'} caption={'수정일'} dataType={'date'} format="yyyy-MM-dd HH:MM:ss" />
              <Column
                dataField={'edit'}
                caption={'관리'}
                width={'80'}
                cellRender={editCellRender}
                allowExporting={false}
              />
            </CommonDataGrid>
          </div>
        </div>
        <Popup onHiding={onClose} visible={popupVisible} showTitle={false} width="30%" height={700}>
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <AddCompany ref={addCompanyRef} setPopupVisibility={setPopupVisibility} isUpdate={isUpdate} />
            </div>
          </ScrollView>
        </Popup>
      </div>
    </>
  );
};

export default Companymng;
