import React, { useState, useMemo } from 'react';
import { Toolbar as MainToolbar, Item as ToolbarItem } from 'devextreme-react/toolbar';
import { salesPayment, error } from 'api';
import { Button } from 'devextreme-react/button';
import { getNormalDateFormat } from 'utils/common';

import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import notify from 'devextreme/ui/notify';

import './sales-lock-management.scss';

const SalesLockManagement = () => {
  const fromDate = moment().subtract(3, 'month').startOf('month').format('yyyy-MM-DD');
  const toDate = moment().add(2, 'month').endOf('month').format('yyyy-MM-DD');
  const [isRefresh, setIsRefresh] = useState(false);
  const [dateValue, setDateValue] = useState({ FromDate: fromDate, ToDate: toDate });

  const dataSource = useMemo(() => {
    if (dateValue.FromDate !== null && dateValue.FromDate !== '') {
      const customDataSource = new CustomStore({
        key: 'SalesMonth',
        loadMode: 'raw',
        load: async () => {
          const rawResult = await salesPayment.getSalesLockMasterList({ ...dateValue });
          const utcToKst = utc => {
            const utcDate = moment.utc(utc);
            const kstDate = utcDate.tz('Asia/Seoul');
            return kstDate;
          };
          const result = rawResult.data.map(a => {
            const lockDateTimeInvoice =
              a.LockDateTimeInvoice === null
                ? a.LockDateTimeInvoice
                : utcToKst(a.LockDateTimeInvoice).format('YYYY-MM-DD HH:mm:ss');
            const lockDateTimeInvoiceR =
              a.LockDateTimeInvoiceR === null
                ? a.LockDateTimeInvoiceR
                : utcToKst(a.LockDateTimeInvoiceR).format('YYYY-MM-DD HH:mm:ss');
            const lockDateTimeSales =
              a.LockDateTimeSales === null
                ? a.LockDateTimeSales
                : utcToKst(a.LockDateTimeSales).format('YYYY-MM-DD HH:mm:ss');
            const isLockInvoice = a.IsLockInvoice.data[0] === 1;
            const isLockSales = a.IsLockSales.data[0] === 1;
            return {
              ...a,
              LockDateTimeInvoice: lockDateTimeInvoice,
              LockDateTimeInvoiceR: lockDateTimeInvoiceR,
              LockDateTimeSales: lockDateTimeSales,
              IsLockInvoice: isLockInvoice,
              IsLockSales: isLockSales,
            };
          });
          return result;
        },
        update: async (key, values) => {
          const rawData = await customDataSource.byKey(key);
          const { RegisterUser, ...updateData } = {
            ...rawData,
            ...values,
          };
          const result = await salesPayment.updateSalesLockMaster(updateData);
          if (result.isOk) {
            notify(
              {
                message: '저장 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
          } else {
            notify(
              {
                message: error.errorMsgCheck(result.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        },
      });
      return customDataSource;
    }
  }, [dateValue, isRefresh]);

  const handleFromDate = e => {
    const fromDate = moment(getNormalDateFormat(e.value)).startOf('month').format('yyyy-MM');
    setDateValue({ ...dateValue, FromDate: fromDate });
  };

  const handleToDate = e => {
    const toDate = moment(getNormalDateFormat(e.value)).endOf('month').format('yyyy-MM');
    setDateValue({ ...dateValue, ToDate: toDate });
  };

  const refresh = () => {
    setIsRefresh(!isRefresh);
  };

  return (
    <div className="lock-management">
      <React.Fragment>
        <h2 className={'content-block detail-title '}>
          월 마감 Lock 관리
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
        </h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <MainToolbar>
              <ToolbarItem location={'before'}>
                <div className="label">조회기간 :</div>
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  pickerType="calendar"
                  displayFormat="yyyy-MM"
                  dataField="date"
                  value={dateValue.FromDate}
                  onValueChanged={handleFromDate}
                  stylingMode="outlined"
                  icon={true}
                  calendarOptions={{
                    maxZoomLevel: 'year',
                  }}
                />
              </ToolbarItem>
              <ToolbarItem location={'before'} text="~" />
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  pickerType="calendar"
                  displayFormat="yyyy-MM"
                  dataField="date"
                  min={dateValue.FromDate}
                  value={dateValue.ToDate}
                  onValueChanged={handleToDate}
                  stylingMode="outlined"
                  icon={true}
                  calendarOptions={{
                    maxZoomLevel: 'year',
                  }}
                />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button
                  icon="refresh"
                  text="새로고침"
                  type="normal"
                  stylingMode="contained"
                  onClick={() => {
                    refresh();
                  }}
                />
              </ToolbarItem>
            </MainToolbar>
            <div style={{ paddingTop: '15px' }}>
              <DataGrid dataSource={dataSource} showBorders={true} allowColumnResizing={true} height={550}>
                <Paging enabled={false} />
                <Editing mode="row" confirmDelete={false} allowUpdating={true} />
                <Column dataField="SalesMonth" caption="대상 월" allowEditing={false} width={100} />
                <Column dataField="IsLockInvoice" caption="세금계산서 마감 여부" width={150} />
                <Column
                  dataField="LockDateTimeInvoice"
                  caption="세금계산서 마감일(청구)"
                  dataType="datetime"
                  width={200}
                  format="yyyy-MM-dd HH:mm:ss"
                />{' '}
                <Column
                  dataField="LockDateTimeInvoiceR"
                  caption="세금계산서 마감일(영수)"
                  dataType="datetime"
                  width={200}
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <Column dataField="IsLockSales" caption="매출 마감 여부" width={150} />
                <Column
                  dataField="LockDateTimeSales"
                  caption="매출 마감일"
                  dataType="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <Column
                  dataField="IgnoreCostCenterList"
                  caption="제외사업소 리스트"
                  dataType="string"
                  allowColumnResizing={true}
                />
              </DataGrid>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default SalesLockManagement;
