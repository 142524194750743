import CsstiHistory from './component/common/cssti-history';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import React, { useState } from 'react';

const CarSharingSalesTaxInvoiceViewer = () => {
  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const refresh = () => {};

  return (
    <div>
      <h2 className={'content-block'}>카셰어링 세금계산서 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CsstiHistory refreshTaxInvoice={refresh} setLoadPanelVisible={setLoadPanelVisible}></CsstiHistory>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ top: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default CarSharingSalesTaxInvoiceViewer;
