import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';

import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import CustomStore from 'devextreme/data/custom_store';
import { TextBox } from 'devextreme-react/text-box';
import ScrollView from 'devextreme-react/scroll-view';
import CommonDataGrid from 'common/default-data-grid';

import { parking } from 'api';
import ParkingSystemForm from './components/parking-system-form';
import './parking-system.scss';

const userId = localStorage.getItem('userId');

const ParkingSystem = () => {
  const [popupVisible, setPopupVisibility] = useState({
    save: false,
    close: false,
  });
  const [costCenter, setCostCenter] = useState(null);
  const [searchParams, setSearchParams] = useState({
    SearchText: '',
  });
  const [serverOS, setServerOS] = useState([]);
  const [iPType, setIPType] = useState([]);
  const [vendorMaster, setVendorMaster] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [text, setText] = useState('');
  const gridRef = useRef({});

  useEffect(() => {
    parkingInfoSelectType();
  }, []);

  const parkingInfoSelectType = useCallback(async () => {
    const vendorMaster = await parking.getParkingSystemVendorMaster();
    setVendorMaster(vendorMaster.data);
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['ServerOS', 'IPType'],
    });
    const serverOS = [];
    const ipType = [];
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'ServerOS') {
          return serverOS.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'IPType') {
          return ipType.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
    }

    setServerOS(serverOS);
    setIPType(ipType);
  }, []);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'CostCenterCode',
      loadMode: 'raw',
      load: async () => {
        try {
          const { SearchText } = searchParams;
          const result = await axios.post(`master/searchCostCenterDivisionMasterA`, {
            SearchText,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams, refresh]);

  const togglePopup = data => {
    const { CostCenterCode } = data;
    setCostCenter(CostCenterCode);
    setPopupVisibility({ save: false, close: !popupVisible.close });
  };

  const systemCellRender = e => {
    return (
      <Button
        icon="search"
        width={120}
        text="보기"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup(e.data)}
      />
    );
  };

  const onChangeSearchText = e => {
    setText(e.value);
  };

  const onEnterKey = e => {
    setSearchParams({
      ...searchParams,
      SearchText: text.trim(),
    });
  };

  const onSearch = e => {
    setSearchParams({
      ...searchParams,
      SearchText: text.trim(),
    });
  };

  const onClose = () => {
    setPopupVisibility({ ...popupVisible, close: false });
  };

  const useYNcellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'Y' ? '예' : '아니오';
  };
  return (
    <>
      <div>
        <h2 className={'content-block'}>사업소 관제시스템 정보 관리</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              hoverStateEnabled={true}
              focusedRowEnabled={true}
              isHeader={true}
              height={650}
              isExcel={true}
              isInfiniteScroll={false}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '사업소 관제시스템 정보 관리.xlsx',
              }}
              headerBefore={[
                <TextBox
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="P코드나 사업소명을 입력하세요"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  width={250}
                  mode="search"
                />,
              ]}
              headerAfter={[
                <Button
                  icon="search"
                  text="조회"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={onSearch}
                />,
                <Button
                  icon="refresh"
                  type="normal"
                  stylingMode="contained"
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                />,
              ]}
            >
              <Paging defaultPageSize={15} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
              <Column dataField={'HiParkingID'} caption={'P코드'} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} />
              <Column dataField={'VendorName'} caption={'관제시스템 공급사명'} />
              <Column dataField={'SWVersionName'} caption={'소프트웨어 버전'} />
              <Column dataField={'IP'} caption={'IP'} />
              <Column dataField={'DBPort'} caption={'DB 포트'} />
              <Column dataField={'UseYN'} caption={'관제DB 연동 여부'} cellRender={useYNcellRender} />
              <Column
                dataField={'system'}
                caption={'관제시스템 정보'}
                cellRender={systemCellRender}
                allowExporting={false}
              />
            </CommonDataGrid>
          </div>
        </div>
        <Popup onHiding={onClose} visible={popupVisible.close} showTitle={false} width="40%" height={590}>
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <ParkingSystemForm
                costCenter={costCenter}
                setPopupVisibility={setPopupVisibility}
                setRefresh={setRefresh}
                refresh={refresh}
                popupVisible={popupVisible}
                serverOS={serverOS}
                iPType={iPType}
                vendorMaster={vendorMaster}
              />
            </div>
          </ScrollView>
        </Popup>
      </div>
    </>
  );
};

export default ParkingSystem;
