export const applyBorderAllToRange = (worksheet, startRow, endRow, startCol, endCol, borderType) => {
  const border = {
    top: { style: borderType, color: { argb: 'FF000000' } },
    left: { style: borderType, color: { argb: 'FF000000' } },
    bottom: { style: borderType, color: { argb: 'FF000000' } },
    right: { style: borderType, color: { argb: 'FF000000' } },
  };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).border = border;
    }
  }
};

export const applyBorderTopToRange = (worksheet, startRow, endRow, startCol, endCol, borderType) => {
  const border = {
    top: { style: borderType, color: { argb: 'FF000000' } },
  };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).border = border;
    }
  }
};

export const applyBorderBottomToRange = (worksheet, startRow, endRow, startCol, endCol, borderType) => {
  const border = {
    bottom: { style: borderType, color: { argb: 'FF000000' } },
  };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).border = border;
    }
  }
};

export const applyBorderLeftToRange = (worksheet, startRow, endRow, startCol, endCol, borderType) => {
  const border = {
    left: { style: borderType, color: { argb: 'FF000000' } },
  };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).border = border;
    }
  }
};

export const applyBorderRightToRange = (worksheet, startRow, endRow, startCol, endCol, borderType) => {
  const border = {
    right: { style: borderType, color: { argb: 'FF000000' } },
  };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).border = border;
    }
  }
};

export const applyFillGreyToRange = (worksheet, startRow, endRow, startCol, endCol) => {
  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).fill = fillGrey;
    }
  }
};

export const applyFillLightBlueToRange = (worksheet, startRow, endRow, startCol, endCol) => {
  const fillLightBlue = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddebf7' } };
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      worksheet.getCell(cellRef).fill = fillLightBlue;
    }
  }
};

export const applyColorRedToRange = (worksheet, startRow, endRow, startCol, endCol) => {
  const redFont = { color: { argb: 'FFFF0000' } }; // ARGB color code for red
  for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
    for (let colNum = startCol.charCodeAt(0); colNum <= endCol.charCodeAt(0); colNum++) {
      const cellRef = `${String.fromCharCode(colNum)}${rowNum}`;
      const cell = worksheet.getCell(cellRef);
      if (cell.value < 0) {
        cell.font = redFont;
      }
    }
  }
};

export const setCellFormulasForVAT = (worksheet, row, resRow, lastColName) => {
  const columns = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
  const numFmt = '#,##0';

  columns.forEach(column => {
    if(column >= lastColName)
      return;

    const formula = `${column}${row}/1.1/10`;
    worksheet.getCell(`${column}${resRow}`).value = { formula };
    worksheet.getCell(`${column}${resRow}`).numFmt = numFmt;
  });
};

export const setCellFormulasForIncome = (worksheet, row1, row2, resRow, lastColName) => {
  const columns = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
  const numFmt = '#,##0';

  columns.forEach(column => {
    if(column >= lastColName)
      return;

    const formula = `${column}${row1}-${column}${row2}`;
    worksheet.getCell(`${column}${resRow}`).value = { formula };
    worksheet.getCell(`${column}${resRow}`).numFmt = numFmt;
  });
};

export const setCellFormulasForProfit = (worksheet, row1, row2, resRow, lastColName) => {
  const columns = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
  const numFmt = '#,##0';
  const redFont = { color: { argb: 'FFFF0000' } };

  columns.forEach(column => {
    if(column >= lastColName)
      return;
    const val1 = worksheet.getCell(`${column}${row1 - 2}`).value; // 매출소계
    const val2 = worksheet.getCell(`${column}${row1 - 2}`).value / 1.1 / 10; // 부가세
    const val3 = val1 - val2; // 순수입
    const val4 = worksheet.getCell(`${column}${row2}`).value; // 비용합계

    const formula = `${column}${row1}-${column}${row2}`;
    worksheet.getCell(`${column}${resRow}`).value = { formula };
    worksheet.getCell(`${column}${resRow}`).numFmt = numFmt;

    if (val3 < val4) {
      worksheet.getCell(`${column}${resRow}`).font = redFont;
      worksheet.getCell(`${column}${resRow + 1}`).font = redFont;
      worksheet.getCell(`${column}${resRow + 2}`).font = redFont;
    }
  });
};

export const setCellFormulasForProfitShare = (worksheet, profitRow, row1, row2, ratio, lastColName) => {
  const columns = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
  const numFmt = '#,##0';

  columns.forEach(column => {
    if(column >= lastColName)
      return;
    
    const formula1 = `ROUND(${column}${profitRow}*${ratio},0)`;
    const formula2 = `${column}${profitRow}-${column}${row1}`;
    worksheet.getCell(`${column}${row1}`).value = { formula: formula1 };
    worksheet.getCell(`${column}${row2}`).value = { formula: formula2 };
    worksheet.getCell(`${column}${row1}`).numFmt = numFmt;
    worksheet.getCell(`${column}${row2}`).numFmt = numFmt;
  });
};
