import React, { useCallback, useEffect, useState } from 'react';
import {
  Chart,
  Label,
  Series,
  ValueAxis,
  Format,
  CommonSeriesSettings,
  Legend,
  CommonAxisSettings,
} from 'devextreme-react/chart';
import { Button } from 'devextreme-react/button';
import './drill-down-chart-by-sales-date.scss';
import PieChart, { Connector, Export, Font, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { chartAxisComp, getChartAxisSort } from './sales-charts';
import { isEmptyObject } from '../../../utils/common';

const colors = ['#6babac', '#e55253'];

const DrillDownChartBySalesDate = ({ dataSource, chartWidth }) => {
  const [chartDataSource, setChartDataSource] = useState([]);
  const [chartInfo, setChartInfo] = useState({
    chartData: [],
    isFirstLevel: true,
    selectDate: '',
  });

  useEffect(() => {
    const sortDataSource = [...dataSource];
    const aggregate = {};
    sortDataSource.forEach(item => {
      if (aggregate[item.SalesDate]) {
        aggregate[item.SalesDate].SumSalesPrice = item.SumSalesPrice;
      } else {
        aggregate[item.SalesDate] = item;
      }
    });

    const summary = Object.values(aggregate);
    const tmpChartDataSource = [...dataSource];
    summary.forEach(item => {
      tmpChartDataSource.push({
        SalesDate: '',
        CostCenterName: item.SalesDate,
        SumSalesPrice: item.DateSumSalesPrice,
      });
    });

    setChartDataSource(tmpChartDataSource);
    const filtered = filterData(tmpChartDataSource, '');
    setChartInfo({ ...chartInfo, chartData: filtered });
  }, [dataSource]);

  const filterData = useCallback((arr, salesDate) => {
    // console.log('filterData:filterData.....................................');
    // console.log('filterData:arr',arr);
    if (isEmptyObject(arr)) {
      return [];
    }
    const filterArr = [...arr.filter(item => item.SalesDate === salesDate)];
    filterArr.sort(getChartAxisSortCenter());
    // console.log('filterData:filterArr',filterArr);
    return filterArr;
  }, []);

  const getChartAxisSortCenter = () => (a, b) => {
    if (a.hasOwnProperty('CostCenterName')) {
      if (a['CostCenterName'] < b['CostCenterName']) {
        return -1;
      }
      if (a['CostCenterName'] > b['CostCenterName']) {
        return 1;
      }
      return 0;
    }
  };

  const customizePoint = () => {
    return {
      color: colors[Number(chartInfo.isFirstLevel)],
      hoverStyle: !chartInfo.isFirstLevel
        ? {
            hatching: 'none',
          }
        : {},
    };
  };

  const onPointClick = e => {
    // console.log('onPointClick',e.target.originalArgument);
    if (chartInfo.isFirstLevel) {
      setChartInfo({
        isFirstLevel: false,
        chartData: filterData(chartDataSource, e.target.originalArgument),
        selectDate: e.target.originalArgument,
      });
    }
  };

  const onButtonClick = () => {
    if (!chartInfo.isFirstLevel) {
      setChartInfo({
        isFirstLevel: true,
        chartData: filterData(chartDataSource, ''),
        selectDate: '',
      });
    }
  };

  const customizePrice = arg => `${arg.value.toLocaleString()}원`;

  const customizePiePercent = arg => {
    return `${arg.value.toLocaleString()}원 (${arg.percentText})`;
  };

  return (
    <div>
      <Toolbar visible={!chartInfo.isFirstLevel}>
        <Item location={'before'}>
          <Button className="button-container" text="Back" icon="chevronleft" onClick={onButtonClick} />
        </Item>
        <Item location={'after'}>
          <div>
            <span style={{ fontSize: 20, color: colors[0] }}>■</span> {chartInfo.selectDate} 일 매출금액
          </div>
        </Item>
      </Toolbar>
      <Chart
        id="chart"
        // title="The Most Populated Countries by Continents"
        customizePoint={customizePoint}
        onPointClick={onPointClick}
        className={chartInfo.isFirstLevel ? 'pointer-on-bars' : ''}
        dataSource={chartInfo.chartData}
      >
        <Size height={400} width={'80%'} />
        <CommonAxisSettings aggregatedPointsPosition="betweenTicks" name="일자">
          {/*<Label staggeringSpacing={10} displayMode="stagger" />*/}
          <Label rotationAngle={45} overlappingBehavior="rotate" />
        </CommonAxisSettings>
        <CommonSeriesSettings
          argumentField="CostCenterName"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <Label visible={true}>
            <Format type="fixedPoint" precision={0} />
          </Label>
        </CommonSeriesSettings>

        <Series name="매출금액" type="bar" valueField="SumSalesPrice" />
        <ValueAxis name="SumSalesPrice" position="left" showZero={true} title={'금액'} valueMarginsEnabled={false}>
          <Label customizeText={customizePrice} />
        </ValueAxis>
        <Legend visible={false} />
        <Export enabled={true} />
      </Chart>
      <PieChart
        id="pie"
        type="pie"
        // title="Top Internet Languages"
        palette="Soft Pastel"
        dataSource={chartInfo.chartData}
      >
        <Size height={400} width={'90%'} />
        <Series argumentField="CostCenterName" valueField="SumSalesPrice">
          <SmallValuesGrouping mode="topN" topCount={5} />
          <Label visible={true} format="fixedPoint" customizeText={customizePiePercent}>
            <Connector visible={true} width={1} />
          </Label>
        </Series>
        <Export enabled={true} />
        <Legend horizontalAlignment="center" verticalAlignment="bottom" />
      </PieChart>{' '}
    </div>
  );
};

export default DrillDownChartBySalesDate;

const internetLanguages = [
  {
    CostCenterName: 'English',
    SumSalesPrice: 55.5,
  },
  {
    CostCenterName: 'Chinese',
    SumSalesPrice: 4.0,
  },
  {
    CostCenterName: 'Spanish',
    SumSalesPrice: 4.3,
  },
  {
    CostCenterName: 'Japanese',
    SumSalesPrice: 4.9,
  },
  {
    CostCenterName: 'Portuguese',
    SumSalesPrice: 2.3,
  },
  {
    CostCenterName: 'German',
    SumSalesPrice: 5.6,
  },
  {
    CostCenterName: 'French',
    SumSalesPrice: 3.8,
  },
  {
    CostCenterName: 'Russian',
    SumSalesPrice: 6.3,
  },
  {
    CostCenterName: 'Italian',
    SumSalesPrice: 1.6,
  },
  {
    CostCenterName: 'Polish',
    SumSalesPrice: 1.8,
  },
];
