import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { makeTaxInvoiceStatusCellRender, notifyS } from 'common/popup/sales-common-util';
import { TagBox } from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { common } from 'api';
import DateBox from 'devextreme-react/date-box';
import jQuery from 'jquery';
import $ from 'jquery';

const userId = localStorage.getItem('userId');

const UnpayedSalesReport = () => {
  const maingridRef = useRef({});
  const invoicegridRef = useRef({});
  //검색
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [selectedID, setSelectedID] = useState('');
  const [dataSourcePayment, SetDataSourcePayment] = useState('');

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const [costcenterCode, setCostCenterCode] = useState([]);
  const [costCenterType, setCostCenterType] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [includeAllCheck, setincludeAllCheck] = useState('N');
  const [byPaymentDate, setPaymentDateCheck] = useState('N');

  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  useEffect(() => {
    (async () => {
      const result = await common.costcenterDataAll();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      // key: 'HiParkingID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/history/getUnpayedSalesReport`, {
            ...dateValue,
            CostCenterCode: costcenterCode.join(','),
            LoginID: userId,
            isALL: includeAllCheck,
            byPaymentDate: byPaymentDate,
          });
          return result.data[0];
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchButton]);

  useEffect(() => {
    SetDataSourcePayment(null);
    (async () => {
      if (selectedID !== '') {
        await axios
          .post(`aggregation/history/getPaymentHistoryByKeyID`, {
            KeyID: selectedID,
            AccountingDate: dateValue,
          })
          .then(res => {
            SetDataSourcePayment(res.data[0]);
          });
      }
    })();
  }, [selectedID]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onClickButton = e => {
    const result = [...costcenterCode];
    const add = e.addedItems.map(a => a.CostCenterCode);
    const remove = e.removedItems.map(a => a.CostCenterCode);
    if (add.length > 0) {
      result.push(...add);
      setCostCenterCode(result);
    }
    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setCostCenterCode(newArr);
    }
  };
  const onincludeAllCheckValueChanged = e => {
    e.value ? setincludeAllCheck('Y') : setincludeAllCheck('N');
  };

  const onPaymentDateCheckValueChanged = e => {
    e.value ? setPaymentDateCheck('Y') : setPaymentDateCheck('N');
  };

  const onSearch = () => {
    setSearchButton(!searchButton);
  };
  const onCellPrepared = useCallback(e => {
    cellStatus(e);
  }, []);

  const onCellClick = e => {
    if (e.row.data && e.row.data.KeyID) {
      setSelectedID(e.row.data.KeyID);
    }
  };
  var mergelements = {};
  const mergeStandardColumn = 'KeyID';
  const mergeColumn = ['HiParkingID', 'CostCenterName', 'SalesTypeName', 'DetailSalesTypeName'];
  const cellStatus = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };
  return (
    <div>
      <h2 className={'content-block detail-title'}>사업소 외상 매출금 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            gridRef={maingridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={550}
            focusedRowEnabled={true}
            onFocusedRowChanged={onCellClick}
            selectedRowKeys={[selectedID]}
            errorRowEnabled={false}
            keyExpr="KeyID"
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 외상 매출금 조회 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">매출일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TagBox
                dataSource={costCenterType}
                showSelectionControls={false}
                columnAutoWidth={true}
                allowColumnResizing={true}
                maxDisplayedTags={2}
                displayExpr="CostCenterName"
                valueExpr="CostCenterCode"
                placeholder="조회할 사업소를 모두 선택해주세요"
                stylingMode="outlined"
                onSelectionChanged={onClickButton}
                searchEnabled={true}
                width={330}
                selectAllText={'전체'}
                multiline={false}
              />,
            ]}
            headerAfter={[
              // <CheckBox defaultValue={false} text="미결 포함    " onValueChanged={onincludeAllCheckValueChanged} />,
              <CheckBox
                defaultValue={false}
                visible={false}
                text="계산서 상세 조회   "
                onValueChanged={onPaymentDateCheckValueChanged}
              />,

              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesDate'} caption={'매출일'} alignment={'center'} />
            <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'FromDate'} caption={'매출대상기간From'} alignment={'center'} />
            <Column dataField={'ToDate'} caption={'매출대상기간To'} alignment={'center'} />
            <Column dataField={'SalesPrice'} caption={'매출금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'PaymentAmount'} caption={'반제금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'RemainAmount'} caption={'잔액'} alignment={'right'} format="#,##0 원" />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'CustomerName'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'CustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'CorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'CustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              <Column dataField={'TypeOfBusiness'} caption={'업종'} alignment={'center'} />
              <Column dataField={'TypeOfIndustry'} caption={'업태'} alignment={'center'} />
            </Column>
            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}
            <Summary>
              <TotalItem column="SalesCnt" summaryType="sum" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>

      <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.content' }} visible={loadPanelVisible} />
      <h3 className={'content-block detail-title'}>수금 내역 상세 조회</h3>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '5px 40px' }}>
          <CommonDataGrid
            gridRef={invoicegridRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={dataSourcePayment}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            isInfiniteScroll={false}
            onCellPrepared={onCellPrepared}
            height={600}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '수금 내역 상세 조회.xlsx',
            }}
          >
            {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} customizeText={customizeText} />
            <Column dataField={'PaymentAmount'} caption={'반제금액'} alignment={'center'} format="#,##0 원" />
            <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
            <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
            <Column
              dataField={'RegisterDate'}
              caption={'대사등록일'}
              alignment={'center'}
              customizeText={customizeText}
            />
            <Summary>
              <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default UnpayedSalesReport;
