import React, { useState, useCallback, useEffect } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import GeneralTicket from './component/generalTicket';
import SeasonTicket from './component/seasonTicket';
import EtcSales from './component/etcSales';
import BtoBSales from './component/btobSales';
import moment from 'moment';
import './view-sales-all.scss';
import GeneralTicketSummary from './component/generalTicketSummary';
import { SelectBox } from 'devextreme-react/select-box';
import { master } from 'api';

const ViewSalesAllPage = () => {
  const [salesStatusCodes, setSalesStatusCodes] = useState([]);
  const [searchCenter, setSearchCenter] = useState('');
  const [searchCardNo, setSearchCardNo] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');
  const [searchVehicleNumber, setSearchVehicleNumber] = useState('');

  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: '',
    ToDate: '',
    SearchCenter: '',
    SearchApprovalNo: '',
    SearchCardNo: '',
    SearchVehicleNumber: '',
    Status: '',
  });
  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  const onChangeCardNo = e => {
    setSearchCardNo(e.value);
  };
  const onChangeApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };
  const onChangeVehicleNumber = e => {
    setSearchVehicleNumber(e.value);
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };
  const onSearch = () => {
    setSearchData({
      ...searchData,
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      SearchCenter: searchCenter,
      SearchCardNo: searchCardNo,
      SearchApprovalNo: searchApprovalNo,
      SearchVehicleNumber: searchVehicleNumber,
    });
  };

  useEffect(() => {
    getCodes();
  }, []);

  const handleSearchStatusType = e => {
    console.log(e);
    const { value } = e.itemData;
    setSearchData({ ...searchData, Status: value });
  };

  const getCodes = useCallback(async () => {
    const tmpSalesStatusType = [];
    const result = await master.searchCodeMaster({
      CodeGroup: ['SalesStatus'],
    });
    console.log(result);
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
          tmpSalesStatusType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
      });
      console.log(tmpSalesStatusType);
      setSalesStatusCodes(tmpSalesStatusType);
    }
  }, []);
  return (
    <>
      <React.Fragment>
        <h2 className={'content-block detail-title'}>
          전체 매출 조회
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
        </h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <Toolbar>
              <ToolbarItem location={'before'}>
                <div className="label">매출일/확정일 :</div>
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  value={dateValue.fromDate}
                />
              </ToolbarItem>
              <ToolbarItem location={'before'} text="~" />
              <ToolbarItem location={'before'}>
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  dataField="ToDate"
                  min={dateValue.fromDate}
                  value={dateValue.toDate}
                  onValueChanged={handleToDate}
                  dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
              <SelectBox
                labelMode="static"
                placeholder="매출 상태 전체"
                // label={'매출유형'}
                dropDownButtonTemplate="dropDownButton"
                stylingMode="outlined"
                dataSource={[
                  {
                    key: '전체',
                    items: [{ value: '', id: '매출 상태 전체' }],
                  },
                  {
                    key: '매출상태',
                    items: salesStatusCodes,
                  },
                ]}
                onItemClick={handleSearchStatusType}
                valueExpr="value"
                displayExpr="id"
                width={200}
                grouped={true}
              />
              </ToolbarItem>
              <ToolbarItem location={'after'}>
                <Button icon="search" text="조회" width={120} onClick={onSearch} />
              </ToolbarItem>
            </Toolbar>
            <Toolbar>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchCenter"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="사업소명/P-코드/사업소코드"
                  onValueChanged={onChangeCenter}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchApprovalNo"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="승인번호"
                  onValueChanged={onChangeApprovalNo}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchCardNo"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="카드번호"
                  onValueChanged={onChangeCardNo}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
              <ToolbarItem location={'before'}>
                <TextBox
                  name="SearchVehicleNumber"
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="차량번호"
                  onValueChanged={onChangeVehicleNumber}
                  width={160}
                  mode="search"
                />
              </ToolbarItem>
            </Toolbar>
            <div style={{ paddingTop: '5px' }}>
              <TabPanel>
                <Item title="일반권">
                  <GeneralTicket searchData={searchData} />
                </Item>
                <Item title="정기권">
                  <SeasonTicket searchData={searchData} />
                </Item>
                <Item title="기타">
                  <EtcSales searchData={searchData} />
                </Item>
                <Item title="B2B">
                  <BtoBSales searchData={searchData} />
                </Item>
                <Item title="일반권 요약">
                  <GeneralTicketSummary searchData={searchData} />
                </Item>
              </TabPanel>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ViewSalesAllPage;
