import axios from 'axios';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');

export async function getVANAggregationData(params) {
  try {
    const result = await axios.post(`aggregation/getVANAggregationDataByCostCenterDay`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getVANAggregationDetailData(params) {
  try {
    const result = await axios.post(`aggregation/getVANAggregationDataByCostCenterDayDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSalesDataByDay(params) {
  const dayDiff = (new Date(params.ToDate) - new Date(params.FromDate)) / (1000 * 3600 * 24);
  if (
    dayDiff > 31 &&
    !params.Status &&
    !params.SearchCenter &&
    !params.SearchApprovalNo &&
    !params.SearchCardNo &&
    !params.SearchVehicleNumber
  ) {
    throw new Error('1개월 이하의 기간을 설정하거나. 조회 조건을 입력해 주세요');
  }

  try {
    const result = await axios.post(`aggregation/getParkingSalesDataByDay`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getBtoBSalesDetail(params) {
  try {
    const result = await axios.post(`aggregation/getBtoBSalesDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSeasonTicketSalesDataByDay(params) {
  try {
    const result = await axios.post(`aggregation/getSeasonTicketSalesDataByDay`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getOtherTicketSalesDataByDay(params) {
  try {
    const result = await axios.post(`aggregation/getOtherTicketSalesDataByDay`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingInOutLambdaLog(params) {
  try {
    const result = await axios.post(`aggregation/history/getParkingInOutLambdaLog`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getParkingInOutLambdaLogSummary(params) {
  try {
    const result = await axios.post(`aggregation/history/getParkingInOutLambdaLogSummary`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingInOutLambdaLogDetail(params) {
  try {
    const result = await axios.post(`aggregation/history/getParkingInOutLambdaLogDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getRPAScheduleJobHistory(params) {
  try {
    const result = await axios.post(`aggregation/history/getRPAScheduleJobHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function cancelRefundInfo(params) {
  try {
    const result = await axios.post(`aggregation/cancelRefundInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getParkingSalesSummaryDataByDay(params) {
  try {
    const result = await axios.post(`aggregation/getParkingSalesSummaryDataByDay`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getAccountingDashboardDetail(params) {
  try {
    const result = await axios.post(`aggregation/report/getAccountingDashboardDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSuspenseReceiptsList(params) {
  try {
    const result = await axios.post(`aggregation/getSuspenseReceiptsList`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function cancelSuspenseReceiptByID(params) {
  try {
    const result = await axios.post(`aggregation/removeSuspenseReceipt`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function registerSuspenseReceipts(params) {
  try {
    const result = await axios.post(`aggregation/registerSuspenseReceipts`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSuspenseReceiptsDetail(params) {
  try {
    const result = await axios.post(`aggregation/getSuspenseReceiptsDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function confirmAccountInfoByReceipt(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoByReceipt`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function confirmAccountInfoByReceiptBtoB(params) {
  try {
    const result = await axios.post(`aggregation/confirmAccountInfoByReceiptBtoB`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSuspenseReceiptsDetailConfirmed(params) {
  try {
    const result = await axios.post(`aggregation/getSuspenseReceiptsDetailConfirmed`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function issueCashBillInfo(params) {
  try {
    const result = await axios.post(`aggregation/cashbill/issueCashBillInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getCashbillDetailUrl(params) {
  try {
    const result = await axios.post(`aggregation/cashbill/getDetailURL`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getCashbillPdfUrl(params) {
  try {
    const result = await axios.post(`aggregation/cashbill/getPdfURL`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function issueRevokeCashBillInfo(params) {
  try {
    const result = await axios.post(`aggregation/cashbill/issueRevokeCashBillInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function checkIfCashbillRegistered(params) {
  try {
    const result = await axios.post(`aggregation/checkIfCashbillRegistered`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function cancelCashbillByBillID(params) {
  try {
    const result = await axios.post(`aggregation/cashbill/cancelCashbillByBillID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function registerCashBillRegisterDummy(params) {
  try {
    const result = await axios.post(`aggregation/cashbill/registerCashBillRegisterDummy`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getConfirmHeaderHistory(params) {
  try {
    const result = await axios.post(`aggregation/history/getConfirmHeaderHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSAPCofirmationDataMonthly(params) {
  try {
    const result = await axios.post(`aggregation/getSAPCofirmationDataMonthly`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getSAPCofirmationDataMonthlyDetail(params) {
  try {
    const result = await axios.post(`aggregation/getSAPCofirmationDataMonthlyDetail`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function GenerateSalesSummaryForSAP(params) {
  try {
    const result = await axios.post(`aggregation/generateSalesSummaryForSAP`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getRentalSalesInvoicePredicted(params) {
  try {
    const result = await axios.post(`aggregation/getRentalSalesInvoicePredicted`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getRentalSalesMasterManagementName(params) {
  try {
    const result = await axios.post(`aggregation/getRentalSalesMasterManagementName`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
