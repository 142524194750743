import React, { useMemo, useState, useRef } from 'react';
import moment from 'moment';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { aggregation } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';

const ResultHistory = props => {
  const { inquiryDate, costCenterCode, isRefresh } = props;

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        const result = await aggregation.getConfirmHeaderHistory({
          InquiryDate: inquiryDate,
          CostCenterCode: costCenterCode,
        });
        return result.data;
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenterCode, isRefresh]);
  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <React.Fragment>
      <div style={{ padding: '17px 0' }}>
        <div className="dx-card">
          <CommonDataGrid
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            hoverStateEnabled={true}
            height={250}
            isNotFilterRow
            isHeader={true}
            isExcel={false}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'center'} />
            <Column
              dataField={'LastUpdateDate'}
              caption={'확정일'}
              width={200}
              alignment={'center'}
              cellRender={customizeText}
            />
            <Column dataField={'CurrentStatusName'} caption={'대사상태'} alignment={'center'} />
            <Column dataField={'LastUpdateUserName'} caption={'대사 처리자'} alignment={'center'} />
            <Summary>
              <TotalItem column="LastUpdateUserName" summaryType="count" displayFormat="전체 {0}건" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResultHistory;
