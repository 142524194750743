import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Button } from 'devextreme-react/button';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { salesPayment } from 'api';
import Form, { GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import './external-sales.scss';
import { Column, Selection, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react';
import { cellStatus } from '../common/common';
import CommonDataGrid from 'common/default-data-grid';

const ExternalSalesForm = props => {
  const {
    externalSalesFormRef,
    setPopupExternalSales,
    setExternalGSales,
    popupExternalSales,
    setExternalSSales,
    setExternalESales,
    setExternalTSales,
    // date,
  } = props;
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [searchParams, setSearchParams] = useState({
    FromDate: today,
    ToDate: today,
    SearchText: '',
    UserID: '',
  });
  const [gridData, setGridData] = useState([]);
  const [generalDataSource, setGeneralDataSource] = useState([]);
  const [seasonDataSource, setSeasonDataSource] = useState([]);
  const [etcDataSource, setEtcDataSource] = useState([]);
  const [taxDataSource, setTaxDataSource] = useState([]);

  const [checkedGSales, setCheckedGSales] = useState([]);
  const [checkedSSales, setCheckedSSales] = useState([]);
  const [checkedESales, setCheckedESales] = useState([]);
  const [checkedTSales, setCheckedTSales] = useState([]);
  const [text, setText] = useState('');
  const dataGridRef = useRef({});

  useEffect(() => {
    if (popupExternalSales.visible) {
      api(searchParams);
    }
  }, [popupExternalSales, searchParams]);

  const api = searchParams => {
    (async () => {
      const result = await salesPayment.getSummaryByCostCenter(searchParams);
      if (result.isOk) {
        result.data.push({"CostCenterCode":"*","CompanyName":"전체사업소","HiParkingID":"*","CostCenterName":"전체사업소","SalesDate":"","CurrentStatus":"N","CurrentStatusName":"","RegisterUser":null,"RegisterDate":null});
        setGridData(result.data);
      }
    })();
  };
  const onSelectionChanged = useCallback(
    e => {
      if (e.selectedRowsData.length > 0) {
        if (popupExternalSales.type === 'g') {
          (async () => {
            const result = await salesPayment.getGSalesDataDetail({
              // InquiryDate: e.selectedRowsData[0].SalesDate,
              CostCenter: e.selectedRowsData[0].CostCenterCode,
              IsPostponed: 'Y',
            });
            if (result.isOk) {
              setGeneralDataSource(result.data);
            }
          })();
        } else if (popupExternalSales.type === 's') {
          (async () => {
            const result = await salesPayment.getSSalesDataDetail({
              // InquiryDate: e.selectedRowsData[0].SalesDate,
              CostCenter: e.selectedRowsData[0].CostCenterCode,
              IsPostponed: 'Y',
            });
            if (result.isOk) {
              setSeasonDataSource(result.data);
            }
          })();
        } else if (popupExternalSales.type === 'e') {
          (async () => {
            const result = await salesPayment.getEtcSalesDataDetail({
              // InquiryDate: e.selectedRowsData[0].SalesDate,
              CostCenter: e.selectedRowsData[0].CostCenterCode,
              IsPostponed: 'Y',
            });
            if (result.isOk) {
              setEtcDataSource(result.data);
            }
          })();
        } else if (popupExternalSales.type === 't') {
          (async () => {
            const result = await salesPayment.getTaxInvoiceListForAccounting({
              CostCenter: e.selectedRowsData[0].CostCenterCode,
              IsPostponed: 'Y',
            });
            if (result.isOk) {
              setTaxDataSource(result.data);
            }
          })();
        }
      }
    },
    [popupExternalSales],
  );
  const onSearch = () => {
    setSearchParams({
      ...searchParams,
      SearchText: text,
      UserID: '',
    });
    setGeneralDataSource([]);
    setSeasonDataSource([]);
    setEtcDataSource([]);
    setTaxDataSource([]);
    dataGridRef.current.instance.clearSelection();
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onCheckGSelectionChanged = e => {
    setCheckedGSales(e.selectedRowsData);
  };

  const onCheckSSelectionChanged = e => {
    setCheckedSSales(e.selectedRowsData);
  };
  const onCheckESelectionChanged = e => {
    setCheckedESales(e.selectedRowsData);
  };

  const onCheckTSelectionChanged = e => {
    setCheckedTSales(e.selectedRowsData);
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onSave = () => {
    setPopupExternalSales({ save: true, visible: false });
    if (checkedGSales.length > 0) {
      setExternalGSales(checkedGSales);
    } else if (checkedSSales.length > 0) {
      setExternalSSales(checkedSSales);
    } else if (checkedESales.length > 0) {
      setExternalESales(checkedESales);
    } else if (checkedTSales.length > 0) {
      setExternalTSales(checkedTSales);
    }
    setSeasonDataSource([]);
    setGeneralDataSource([]);
    setEtcDataSource([]);
    setTaxDataSource([]);
    setCheckedGSales([]);
    setCheckedSSales([]);
    setCheckedESales([]);
    setCheckedTSales([]);
    setText('');
    dataGridRef.current.instance.clearSelection();
    dataGridRef.current.instance.clearFilter();
  };

  const onClose = () => {
    setPopupExternalSales({ save: false, visible: false });
    externalSalesFormRef.current.instance.refresh();
    externalSalesFormRef.current.instance.clearSelection();
    externalSalesFormRef.current.instance.clearFilter();
    setSeasonDataSource([]);
    setGeneralDataSource([]);
    setEtcDataSource([]);
    setTaxDataSource([]);
    setCheckedGSales([]);
    setCheckedSSales([]);
    setCheckedESales([]);
    setCheckedTSales([]);
    setText('');
    setSearchParams({
      ...searchParams,
      SearchText: '',
    });
    dataGridRef.current.instance.refresh();
    dataGridRef.current.instance.clearSelection();
    dataGridRef.current.instance.clearFilter();
  };

  const onValueChanged = e => {
    setText(e.value);
  };
  return (
    <>
      <div className="external-sales-form">
        <Form id="form" labelLocation="left" labelMode="outside" optionalMark="optional" stylingMode="outlined">
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="타 사업소 선택하기">
            <GroupItem>
              <CommonDataGrid
                gridRef={dataGridRef}
                className={'dx-card wide-card'}
                dataSource={gridData}
                showBorders={true}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                hoverStateEnabled={true}
                height={250}
                onSelectionChanged={onSelectionChanged}
                isHeader={true}
                isExcel={false}
                headerBefore={[
                  <TextBox
                    stylingMode="outlined"
                    mode="search"
                    placeholder="사업소코드/사업소명/P코드"
                    value={text}
                    onValueChanged={onValueChanged}
                    onEnterKey={onSearch}
                    width={220}
                  />,
                ]}
                headerAfter={[
                  <Button
                    icon="search"
                    text="조회"
                    width={120}
                    type="normal"
                    stylingMode="contained"
                    onClick={onSearch}
                  />,
                ]}
              >
                <Selection mode="single" />
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Column dataField={'CompanyName'} caption={'회사이름'} />
                <Column dataField={'HiParkingID'} caption={'P코드'} />
                <Column dataField={'CostCenterName'} caption={'사업소명'} />
                {/* <Column dataField={'CurrentStatusName'} caption={'상태'} /> */}
                {/* <Summary>
                  <TotalItem column="CurrentStatusName" summaryType="count" displayFormat="총 {0}건" />
                </Summary> */}
              </CommonDataGrid>
            </GroupItem>
          </GroupItem>
          <GroupItem caption="매출내역 선택하기">
            <TabPanel>
              {popupExternalSales.type === 'g' && (
                <Item title="일반권" badge={checkedGSales.length}>
                  <CommonDataGrid
                    gridRef={externalSalesFormRef}
                    className={'dx-card wide-card'}
                    dataSource={generalDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    hoverStateEnabled={true}
                    height={250}
                    onCellPrepared={onCellPrepared}
                    onSelectionChanged={onCheckGSelectionChanged}
                    isHeader={false}
                    isExcel={false}
                  >
                    <Scrolling mode="virtual" rowRenderingMode="virtual" />
                    <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
                    <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
                    <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />
                    <Column dataField={'ApprovalDate'} caption={'매출시간'} customizeText={customizeText} />
                    <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} />
                    <Column dataField={'StatusName'} caption={'매출상태'} />
                    <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} />
                    <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
                    <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                    <Summary>
                    <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                    <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                    </Summary>
                  </CommonDataGrid>
                </Item>
              )}
              {popupExternalSales.type === 's' && (
                <Item title="정기권" badge={checkedSSales.length}>
                  <CommonDataGrid
                    gridRef={externalSalesFormRef}
                    className={'dx-card wide-card'}
                    dataSource={seasonDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    hoverStateEnabled={true}
                    height={250}
                    onCellPrepared={onCellPrepared}
                    onSelectionChanged={onCheckSSelectionChanged}
                    isHeader={false}
                    isExcel={false}
                  >
                    <Scrolling mode="virtual" rowRenderingMode="virtual" />
                    <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
                    <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
                    <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />
                    <Column dataField={'ApprovedDate'} caption={'승인시간'} customizeText={customizeText} />
                    <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} />
                    <Column dataField={'StatusName'} caption={'매출상태'} />
                    <Column dataField={'FromDate'} caption={'시작일'} />
                    <Column dataField={'ToDate'} caption={'종료일'} />
                    <Column dataField={'PaymentTypeName'} caption={'매출유형'} />
                    <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
                    <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                    <Column dataField={'Remark'} caption={'비고'} />
                    <Summary>
                    <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                    <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                    </Summary>
                  </CommonDataGrid>
                </Item>
              )}
              {popupExternalSales.type === 'e' && (
                <Item title="기타" badge={checkedESales.length}>
                  <CommonDataGrid
                    gridRef={externalSalesFormRef}
                    className={'dx-card wide-card'}
                    dataSource={etcDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    hoverStateEnabled={true}
                    height={250}
                    onCellPrepared={onCellPrepared}
                    onSelectionChanged={onCheckESelectionChanged}
                    isHeader={false}
                    isExcel={false}
                  >
                    <Scrolling mode="virtual" rowRenderingMode="virtual" />
                    <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
                    <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
                    <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />
                    <Column dataField={'SalesDate'} caption={'매출일'} customizeText={customizeText} />
                    <Column dataField={'StatusName'} caption={'매출상태'} />
                    <Column dataField={'EtcSalesTypeName'} caption={'고객유형'} />
                    <Column dataField={'CustomerName'} caption={'고객명'} />
                    <Column dataField={'PaymentTypeName'} caption={'결제유형'} />
                    <Column dataField={'VehicleRegistrationNo'} caption={'시간권차량번호'} />
                    <Column dataField={'Remark'} caption={'비고'} />
                    <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} />
                    <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
                    <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                    <Summary>
                    <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                    <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                    </Summary>
                  </CommonDataGrid>
                </Item>
              )}
              {popupExternalSales.type === 't' && (
                <Item title="세금계산서" badge={checkedTSales.length}>
                  <CommonDataGrid
                    gridRef={externalSalesFormRef}
                    className={'dx-card wide-card'}
                    dataSource={taxDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    hoverStateEnabled={true}
                    height={250}
                    onCellPrepared={onCellPrepared}
                    onSelectionChanged={onCheckTSelectionChanged}
                    isHeader={false}
                    isExcel={false}
                  >
                    <Scrolling mode="virtual" rowRenderingMode="virtual" />
                    <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
                    <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
                    <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />
                    <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
                    <Column dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
                    <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
                    <Column dataField={'StatusName'} caption={'매출상태'} width={80} />
                    <Column dataField={'SalesPrice'} caption={'금액'} width={160} format="#,##0 원" />
                    <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                    <Column dataField={'CustomerName'} caption={'사업자명'} width={200} />
                    <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} />
                    <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
                    <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
                    <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
                    <Column dataField={'SalesItemType'} caption={'매출품목'} width={100} />
                    <Column dataField={'SalesCount'} caption={'품목 수'} width={80} />
                    <Column dataField={'TaxPrice'} caption={'부가세'} width={80} />
                    <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} />
                    <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} />
                    <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} />
                    <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
                    <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
                    <Column dataField={'RegisterUser'} caption={'등록자'} width={80} />
                    <Column dataField={'RegisterDate'} caption={'등록일'} width={120} />
                    <Column dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} />
                    <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
                    <Summary>
                      <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                    </Summary>
                  </CommonDataGrid>
                </Item>
              )}
            </TabPanel>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions
                width={'20%'}
                type={'default'}
                // useSubmitBehavior={true}
                text="저장"
                onClick={onSave}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'20%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </>
  );
};

export default ExternalSalesForm;
