import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useAuth } from 'contexts/auth';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import { useHistory, useLocation } from 'react-router-dom';
import './side-navigation-menu.scss';
import $ from 'jquery';

import * as events from 'devextreme/events';

export default function SideNavigationMenu(props) {
  const { menu } = useAuth();
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props;

  const { isLarge } = useScreenSize();
  function normalizePath() {
    return menu?.map?.(item => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLarge };
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    navigationData: { currentPath },
    isDocked,
    setIsDocked,
  } = useNavigation();

  const treeViewRef = useRef({});
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    element => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, 'dxclick');
      }

      wrapperRef.current = element;
      events.on(element, 'dxclick', e => {
        openMenu(e);
      });
    },
    [openMenu],
  );

  const history = useHistory();
  const location = useLocation();

  const handleItemClick = useCallback(
    e => {
      if (currentPath === e.itemData.path) {
        // setIsRender(!isRender);
        history.push('/loading');
        setTimeout(() => {
          history.replace(location.pathname);
        }, 50);
      } else {
        selectedItemChanged(e);
      }
    },
    [currentPath, history, location.pathname, selectedItemChanged],
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }

    const $input = $('.menu-container .dx-texteditor-input-container .dx-placeholder');
    $input.attr('data-dx_placeholder', '메뉴를 입력해주세요');
  }, [currentPath, compactMode]);

  const onPinClick = e => {
    setIsDocked(!isDocked);
  };
  return (
    <div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
      {children}
      <div style={{ textAlign: 'right' }}>
        <i className={`pin-icon ${isDocked ? 'pinned' : ''} fas fa-thumbtack`} onClick={onPinClick}></i>
      </div>
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          searchMode={'contains'}
          searchEnabled={!compactMode}
          onItemClick={handleItemClick}
          onContentReady={onMenuReady}
          width={'100%'}
          height={'95%'}
        />
      </div>
    </div>
  );
}
