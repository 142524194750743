import React, { useState, useCallback, useImperativeHandle, useRef, useEffect } from 'react';
import { Column, Pager, Selection, HeaderFilter, Scrolling, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { ticket } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import 'devextreme-react/text-area';
import '../season-ticket-mng.scss';
const DeleteTickets = React.forwardRef((props, ref) => {
  const { refresh, dtdataSource, gridDefault } = props;
  // 그리드 레프
  const ticketgridRef = useRef({});

  const [ticketID, setTicketID] = useState([]);

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    ticketgridRef.current.instance.clearFilter();
    ticketgridRef.current.instance.clearSelection();
  }, [gridDefault]);

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsCount') options.totalValue = ticketID.length;
    else if (options.name === 'SelectedRowsSummary') {
      const selectedRows = options.component.getSelectedRowsData();
      const sum = selectedRows.reduce((a, c) => a + c.SalesPrice, 0);
      options.totalValue = sum;
    } else if (options.name === 'TotalCount') options.totalValue = dtdataSource.length;
  };

  // 정기권 발행 취소, 만료된 부분 cell style 변경
  const onCellPrepared = useCallback(({ data, cellElement }) => {
    if (data) {
      const { RemainDay } = data;
      const tr = cellElement.parentNode;
      if (RemainDay === '만료' && tr) {
        tr.classList.add('ticket-expiration');
      } else if (RemainDay.includes('취소') && tr) {
        tr.classList.add('ticket-cancel');
      }
    }
  }, []);

  const onSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    if (selectRows.length > 0) {
      const ticketIds = [];
      selectRows.forEach(r => {
        ticketIds.push(r.TicketID);
      });
      setTicketID(ticketIds);
    } else {
      setTicketID([]);
    }
  };

  return (
    <>
      <CommonDataGrid
        gridRef={ticketgridRef}
        width="100%"
        className={'dx-card wide-card ticket-history'}
        dataSource={dtdataSource}
        keyExpr="TicketID"
        showBorders={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        onSelectionChanged={onSelectionChanged}
        allowColumnResizing={true}
        height={663}
        onCellPrepared={onCellPrepared}
        allowColumnReordering={true}
        isExcel={true}
        isFilterSelection
        excelOptions={{
          sheetName: 'Sheet',
          fileName: '정기권 취소목록.xlsx',
        }}
        isHeader={true}
        headerAfter={[
          <Button
            icon="refresh"
            type="normal"
            stylingMode="text"
            onClick={() => {
              refresh();
            }}
          />,
        ]}
      >
        <HeaderFilter visible={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
        <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />
        <Column dataField={'VehicleRegistration'} width={100} caption={'차량번호'} fixed={true} />
        <Column dataField={'FromDate'} caption={'시작일'} dataType="date" width={100} alignment={'left'} fixed={true} />
        <Column dataField={'ToDate'} caption={'종료일'} dataType="date" width={100} alignment={'left'} fixed={true} />
        <Column dataField={'SalesPrice'} caption={'판매금액'} width={100} alignment={'left'} format="#,##0 원" />
        {/* <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={100} alignment={'left'} /> */}
        <Column
          dataField={'SalesStatusName'}
          caption={'매출상태'}
          width={100}
          alignment={'left'}
          cellRender={e => {
            return e.value;
          }}
        />
        <Column dataField={'PaymentCodeName'} caption={'수금구분'} width={100} alignment={'left'} />
        <Column dataField={'MemberName'} caption={'회원명'} width={80} alignment={'left'} />
        <Column dataField={'GroupCode'} width={80} visible={false} alignment={'left'} />
        <Column dataField={'GroupName'} caption={'회원그룹'} width={100} alignment={'left'} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={150} />
        <Column dataField={'RemainDay'} caption={'남은일수'} width={100} alignmen t={'left'} />
        {/* <Column dataField={'PaymentStatusName'} caption={'결제상태'} width={100} alignment={'left'} /> */}
        <Column dataField={'MemberTypeName'} caption={'정기권유형'} width={110} alignment={'left'} />
        <Column dataField={'MemberDivisionName'} caption={'회원구분'} width={110} alignment={'left'} />
        <Column dataField={'SalesDate'} caption={'판매일'} dataType="date" width={110} alignment={'left'} />
        <Column dataField={'Remark'} caption={'비고'} width={80} alignment={'left'} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={80} alignment={'left'} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={80} alignment={'left'} />
        <Column dataField={'IssueCreditCard'} caption={'발급사'} width={80} alignment={'left'} />
        <Column dataField={'SettleCreditCard'} caption={'매입사'} width={80} alignment={'left'} />
        <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
        <Column
          dataField={'RegisterDate'}
          caption={'등록일시'}
          width={100}
          dataType="date"
          alignment={'left'}
          // customizeText={customizeText}
        />
        <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'left'} />
        <Column dataField={'TicketID'} visible={false} />
        <Summary calculateCustomSummary={calculateSelectedRow}>
          <TotalItem
            name="SelectedRowsCount"
            summaryType="custom"
            displayFormat="선택 {0}건"
            showInColumn="VehicleRegistration"
          />
          <TotalItem showInColumn="FromDate" valueFormat="선택합계:" />
          <TotalItem
            name="SelectedRowsSummary"
            summaryType="custom"
            showInColumn="FromDate"
            displayFormat="{0}"
            valueFormat="#,##0 원"
          />
          <TotalItem name="TotalCount" summaryType="custom" showInColumn="ToDate" displayFormat="총 {0}건" />
          <TotalItem showInColumn="SalesPrice" valueFormat="전체합계:" />
          <TotalItem
            column="SalesPrice"
            summaryType="sum"
            displayFormat="{0}"
            showInColumn="SalesPrice"
            valueFormat="#,##0 원"
          />
        </Summary>
      </CommonDataGrid>
    </>
  );
});

export default React.memo(DeleteTickets);
