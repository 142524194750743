import moment from 'moment';

export const operationTime = [
  { id: '00', value: 0 },
  { id: '01', value: 1 },
  { id: '02', value: 2 },
  { id: '03', value: 3 },
  { id: '04', value: 4 },
  { id: '05', value: 5 },
  { id: '06', value: 6 },
  { id: '07', value: 7 },
  { id: '08', value: 8 },
  { id: '09', value: 9 },
  { id: '10', value: 10 },
  { id: '11', value: 11 },
  { id: '12', value: 12 },
  { id: '13', value: 13 },
  { id: '14', value: 14 },
  { id: '15', value: 15 },
  { id: '16', value: 16 },
  { id: '17', value: 17 },
  { id: '18', value: 18 },
  { id: '19', value: 19 },
  { id: '20', value: 20 },
  { id: '21', value: 21 },
  { id: '22', value: 22 },
  { id: '23', value: 23 },
  { id: '24', value: 24 },
];

const thisYear = moment().format('YYYY');
const nextYear = moment().add(1, 'year').format('YYYY');
export const year = [thisYear, nextYear];

export const month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const date = [];

export const days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '말일',
];
