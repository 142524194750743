import React, { useCallback, useState, useImperativeHandle, useRef } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import moment from 'moment';

const EditPopup = React.forwardRef((props, ref) => {
  const today = moment().format('YYYY-MM-DD');
  const resolveSubmitRef = useRef({});
  const { setPopup } = props;
  const [formData, setFormData] = useState({
    IssueDate: moment().format('YYYY-MM-DD'),
  });

  useImperativeHandle(ref, () => ({
    open,
    submit,
  }));

  const open = rowCount => {
    setFormData({
      ...formData,
      RowCount: `${rowCount} 건`,
    });
    setPopup(true);
  };

  const submit = () => {
    return new Promise(resolve => {
      resolveSubmitRef.current = resolve;
    });
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const issueDate = moment(formData.IssueDate).format('YYYY-MM-DD');
      if (resolveSubmitRef.current) {
        resolveSubmitRef.current(issueDate);
      }
      setPopup(false);
    },
    [formData],
  );

  const onClose = () => {
    setPopup(false);
    setFormData({});
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="세금계산서 일괄 수정">
            <SimpleItem
              dataField="RowCount"
              colSpan={2}
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text="선택 건수" />
            </SimpleItem>
            <SimpleItem
              dataField="IssueDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
                // min: moment().startOf('month').format('YYYY-MM-DD'),
                // max: moment().endOf('month').format('YYYY-MM-DD'),
                dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
              }}
            >
              <Label text="발행일" location="left" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions
                colSpan={1}
                width={'100%'}
                type={'default'}
                text="수정"
                useSubmitBehavior={true}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions colSpan={1} width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default EditPopup;
