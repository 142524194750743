import React from 'react';
import PieChart, { Legend, Series, Label, Connector } from 'devextreme-react/pie-chart';

const Doughnut = () => {
  const populationByRegions = [
    {
      region: 'Asia',
      val: 4119626293,
    },
    {
      region: 'Africa',
      val: 1012956064,
    },
    {
      region: 'Northern America',
      val: 344124520,
    },
    {
      region: 'Latin America and the Caribbean',
      val: 590946440,
    },
    {
      region: 'Europe',
      val: 727082222,
    },
    {
      region: 'Oceania',
      val: 35104756,
    },
  ];

  return (
    <PieChart
      id="pie"
      type="doughnut"
      //   title="The Population of Continents and Regions"
      palette="Soft Pastel"
      dataSource={populationByRegions}
      width={820}
    >
      <Series argumentField="region">
        <Label visible={true} format="millions">
          <Connector visible={true} />
        </Label>
      </Series>
      <Legend margin={0} horizontalAlignment="right" verticalAlignment="top" />
    </PieChart>
  );
};

export default Doughnut;
