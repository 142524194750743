import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { ticket, error } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';

const EtcSalesUpload = () => {
  const dataGridRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  // 저장버튼 비활성화
  const [saveDisabled, setSaveDisabled] = useState(true);

  // 컬럼 정의
  const columns = [
    'P코드',
    '매출유형',
    '결제유형',
    '판매금액',
    '매출일',
    '비고',
    '고객유형',
    '고객명',
    '사업자등록번호',
    '시작일',
    '종료일',
    '차고지면적',
    '장비항목명',
    '차량번호',
  ];

  useEffect(() => {
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();

      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = async () => {
    setLoadPanelVisible(true);
    const res = await ticket.insertOtherTicketJson({
      ItemListJson: JSON.stringify(dataSource),
    });
    if (res.isOk) {
      setLoadPanelVisible(false);
      setSaveDisabled(true);
      notify(
        {
          message: '저장 되었습니다.',
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'success',
      );
    } else {
      setLoadPanelVisible(false);
      setSaveDisabled(false);
      notify(
        {
          message: error.errorMsgCheck(res.error.detail),
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const checkExcel = async ExcelOrganization => {
    const res = await ticket.checkOtherTicketJson({
      ItemListJson: JSON.stringify(ExcelOrganization),
    });
    if (res.isOk) {
      setSaveDisabled(false);
      setDataSource(ExcelOrganization);
    } else {
      setSaveDisabled(true);
      setDataSource([]);
      notify(
        {
          message: error.errorMsgCheck(res.error.detail),
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const handleImport = ExcelOrganization => {
    setShowPane(true);
    checkExcel(ExcelOrganization);
    setShowPane(false);
  };

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);
  const cellStatus = (data, displayValue, cellElement) => {
    if (data) {
      const { StatusName, Status } = data;
      const tr = cellElement;
      if (displayValue === StatusName) {
        if (Status === 'A') {
          cellElement.style.backgroundColor = 'rgb(229 248 240)';
          cellElement.style.color = 'rgb(51 202 139)';
        } else if (Status === 'O') {
          cellElement.style.backgroundColor = 'rgba(0, 207, 232,0.12)';
          cellElement.style.color = 'rgb(29 74 74)';
        } else if (Status === 'P') {
          cellElement.style.backgroundColor = 'rgb(252 231 231)';
          cellElement.style.color = 'rgb(236 74 74)';
        } else if (Status === 'N') {
          cellElement.style.backgroundColor = 'rgba(247,247,247,1)';
          cellElement.style.color = 'rgba(33,33,33,1)';
        } else if (Status === 'C') {
          tr.classList.add('status-cancel');
        } else if (Status === 'S') {
          tr.classList.add('status-cancel');
        }
      } else {
        // 취소의 경우 한줄을 강조한다.
        if (Status === 'C' && tr) {
          tr.classList.add('status-cancel');
        }
      }
    }
  };
  const sampleData = [
    {
      P코드: 'C9999',
      매출유형: '일반권(시간권)',
      결제유형: '카드',
      판매금액: 16000,
      매출일: '2023-03-15',
      비고: '비고 기입',
      고객유형: '개인',
      고객명: '고객명',
      사업자등록번호: '',
      시작일: '',
      종료일: '',
      차고지면적: null,
      장비항목명: '',
      차량번호: '',
    },
    {
      P코드: 'C9999',
      매출유형: '차고지',
      결제유형: '카드',
      판매금액: 10000000,
      매출일: '2023-03-15',
      비고: '',
      고객유형: '법인',
      고객명: '',
      사업자등록번호: '등록번호기입',
      시작일: '2023-04-01',
      종료일: '2024-04-01',
      차고지면적: 10,
      장비항목명: '',
      차량번호: '',
    },
    {
      P코드: 'C9999',
      매출유형: '장비-소모품판매',
      결제유형: '현금',
      판매금액: 2000000,
      매출일: '2024-03-15',
      비고: '',
      고객유형: '법인',
      고객명: '',
      사업자등록번호: '등록번호기입',
      시작일: '',
      종료일: '',
      차고지면적: null,
      장비항목명: '장비소모품 항목명(최대100자리)',
      차량번호: '',
    },
  ];

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <h2 className={'content-block'}>기타 매출 엑셀 업로드</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h5>
              {' '}
              ★엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀 파일 업로드
              &nbsp; ▷ &nbsp; 저장
            </h5>
            <div style={{ flexGrow: 1 }}>&nbsp;</div>
            <ExcelImport
              fieldColumn={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']}
              handleImport={e => handleImport(e.map(importMapFunc))}
              fieldColumnType={[
                'string',
                'string',
                'string',
                'string',
                'date',
                'string',
                'string',
                'string',
                'string',
                'date',
                'date',
                'number',
                'string',
                'string',
              ]}
            />
          </div>
          <CommonDataGrid
            gridRef={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            onCellPrepared={onCellPrepared}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '기타매출업로드.xlsx',
            }}
            headerAfter={[
              <Button
                icon="check"
                text="저장"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => saveExcel()}
                disabled={saveDisabled}
              />,
              <ExcelExport
                excelFields={headerText}
                sheetName="Sheet"
                fileName="sample.xlsx"
                excelData={sampleData}
                buttonName="샘플파일 다운로드"
                test
              />,
            ]}
            // headerAfter={[<Button icon="plus" text="매출 수기 등록" onClick={onOpen} />]}
            allowColumnReordering={true}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <LoadPanel enabled showPane={showPane} />
            <HeaderFilter visible={true} />
            <Column dataField={'P코드'} caption={'P코드'} hidingPriority={4} />
            <Column dataField={'매출유형'} caption={'매출유형'} hidingPriority={4} />
            <Column dataField={'결제유형'} caption={'결제유형'} hidingPriority={4} />
            <Column dataField={'판매금액'} caption={'판매금액'} hidingPriority={4} format="#,##0 원" />
            <Column dataField={'매출일'} caption={'매출일'} hidingPriority={4} />
            <Column dataField={'비고'} caption={'비고'} hidingPriority={4} />
            <Column dataField={'고객유형'} caption={'고객유형'} hidingPriority={4} />
            <Column dataField={'고객명'} caption={'고객명'} hidingPriority={4} />
            <Column dataField={'사업자등록번호'} caption={'사업자등록번호'} hidingPriority={4} />
            <Column dataField={'시작일'} caption={'시작일'} hidingPriority={4} />
            <Column dataField={'종료일'} caption={'종료일'} hidingPriority={4} />
            <Column dataField={'차고지면적'} caption={'차고지면적(㎡)'} hidingPriority={4} />
            <Column dataField={'장비항목명'} caption={'장비항목명'} hidingPriority={4} />
            <Column dataField={'차량번호'} caption={'차량번호'} hidingPriority={14} />
            <Summary>
              <TotalItem column="매출유형" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="판매금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default EtcSalesUpload;
