export const activeSelect = [
  {
    label: '전체',
    value: -1,
  },
  {
    label: '사용',
    value: 1,
  },
  {
    label: '미사용',
    value: 0,
  },
];

export const activRadioSelect = [
  {
    label: '사용',
    value: 1,
  },
  {
    label: '미사용',
    value: 0,
  },
];

export const useYNRadioSelect = [
  {
    label: '사용',
    value: 'Y',
  },
  {
    label: '미사용',
    value: 'N',
  },
];

export const cashbillTypeRadioSelect = [
  {
    label: '소득공제용',
    value: 'A',
  },
  {
    label: '지출증빙용',
    value: 'B',
  },
];

export const cashbillTradeTypeSelect = [
  {
    text: '승인거래',
    code: 'A',
  },
  {
    text: '취소거래',
    code: 'C',
  },
];

export const useDefaultInfo = [
  { label: '사용 안 함', value: 0 },
  { label: '사용함', value: 1 },
];

export const tradeUsageType = [
  {
    label: '소득공제용',
    value: 'A',
  },
  {
    label: '지출증빙용',
    value: 'B',
  },
];
