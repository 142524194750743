import { React, useEffect, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { COMPANYCODE } from 'constants/codes';
import { master } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './account.scss';

const AccountMasterPage = () => {
  const [text, setText] = useState('');
  const [searchParam, setSearchParam] = useState({ CompanyCode: COMPANYCODE });
  const [dataSource, setDataSource] = useState();
  const gridRef = useRef({});
  useEffect(() => {
    (async () => {
      const result = await master.getAccountMaster(searchParam);
      if (result.isOk) {
        setDataSource(result.data);
      }
    })();
  }, [searchParam]);

  const onEnterKey = () => {
    onSearch();
  };

  const onValueChanged = e => {
    setText(e.value);
  };

  const onSearch = () => {
    setSearchParam({ CompanyCode: COMPANYCODE, SearchText: text.trim() });
  };

  const useYNcellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'Y' ? '예' : '아니오';
  };

  return (
    <div>
      <h2 className={'content-block'}>계정 마스터 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={false}
            isExcel={true}
            height={650}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '계정마스터 조회.xlsx',
            }}
            isHeader={true}
            isInfiniteScroll={false}
            headerBefore={[
              <TextBox
                mode="search"
                labelMode="static"
                stylingMode="outlined"
                placeholder="계정코드/계정명"
                width={200}
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Paging defaultPageSize={10} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column dataField={'AccountCode'} caption={'계정코드'} hidingPriority={3} />
            <Column dataField={'AccountName'} caption={'계정명'} hidingPriority={2} />
            <Column dataField={'UseYN'} caption={'사용여부'} cellRender={useYNcellRender} hidingPriority={1} />
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default AccountMasterPage;
