import React, { useImperativeHandle } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import DataGrid, { FilterRow, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Button } from 'devextreme-react/button';

const CommonDataGrid = React.forwardRef((props, ref) => {
  const {
    className,
    dataSource,
    children,
    isHeader,
    headerBefore,
    headerAfter,
    gridRef,
    isExcel,
    excelOptions,
    isNotFilterRow,
    isFilterSelection,
    isInfiniteScroll,
    ...options
  } = props;

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const onExporting = async e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelOptions.sheetName);

    const selectedRowsData = gridRef.current.instance.getSelectedRowsData();

    if (selectedRowsData.length > 0) {
      exportDataGrid({
        component: gridRef.current.instance,
        worksheet,
        selectedRowsOnly: true,
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'totalFooter') {
            if (gridCell.column.caption !== '환불금액') {
              excelCell.value = '';
            }
          }
          if (
            excelOptions &&
            excelOptions.customizeCell &&
            excelOptions.customizeCell.date &&
            excelOptions.customizeCell.date.length > 0
          ) {
            if (gridCell.rowType === 'data') {
              excelOptions.customizeCell.date.forEach(column => {
                if (gridCell.column.dataField === column) {
                  excelCell.value = gridCell.value === null ? '' : moment(gridCell.value).format('YYYY-MM-DD HH:mm:ss');
                }
              });
            }
          }
        },
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), excelOptions.fileName);
        });
      });
    } else {
      alert('엑셀 다운로드할 항목을 선택해주세요.');
    }
  };

  const onOptionChanged = e => {
    if (isFilterSelection && e.name === 'columns' && e.value) {
      if (typeof e.value === 'object') {
        (async () => {
          await gridRef.current.instance.clearSelection();
        })();
      }
    }
  };

  return (
    <div>
      {isHeader && (
        <Toolbar>
          {headerBefore.map((content, ind) => (
            <Item location={'before'} key={ind}>
              {content}
            </Item>
          ))}
          {headerAfter.map((content, ind) => (
            <Item location={'after'} key={ind}>
              {content}
            </Item>
          ))}
          {isExcel && (
            <Item location={'after'}>
              <Button icon="exportselected" onClick={onExporting} stylingMode="text" />
            </Item>
          )}
        </Toolbar>
      )}
      <DataGrid
        ref={gridRef}
        className={className}
        dataSource={dataSource}
        noDataText="조회된 데이터가 없습니다."
        {...options}
        allowColumnResizing={true}
        onOptionChanged={onOptionChanged}
        allowColumnReordering={true}
      >
        {!isNotFilterRow && <FilterRow visible={true} />}
        {isInfiniteScroll && <Scrolling mode="infinite" />}
        <HeaderFilter visible={true} />
        {children}
      </DataGrid>
    </div>
  );
});

CommonDataGrid.defaultProps = {
  isInfiniteScroll: true,
  isHeader: false,
  headerBefore: [],
  headerAfter: [],
  isExcel: true,
  excelOptions: {
    buttonName: null,
    sheetName: 'Sheet',
    fileName: 'sample.xlsx',
    customizeCell: { date: [] },
  },
};

CommonDataGrid.propTypes = {
  isInfiniteScroll: PropTypes.bool,
  isHeader: PropTypes.bool,
  headerBefore: PropTypes.oneOfType([PropTypes.array]),
  headerAfter: PropTypes.oneOfType([PropTypes.array]),
  isExcel: PropTypes.bool,
};

export default React.memo(CommonDataGrid);
