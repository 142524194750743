import React, { useRef, useEffect, useState, useCallback } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
  StringLengthRule,
  AsyncRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';

import './code-master-form.scss';
import { master } from 'api';

const CodeMasterForm = ({ setPopupVisibility, isUpdate, codeMng, searchParams, popupVisible }) => {
  const formRef = useRef({});
  const [codeMngData, setCodeMngData] = useState({});

  useEffect(() => {
    if (popupVisible.close) {
      if (isUpdate) {
        updateInfo(codeMng);
      } else {
        setCodeMngData({
          CodeGroupName: searchParams.CodeGroupName,
          CodeGroup: searchParams.CodeGroup,
        });
      }
    }
  }, [codeMng, popupVisible]);

  const updateInfo = useCallback(async codeMng => {
    const { CodeGroup, Code } = codeMng;
    const result = await master.getCodeMasterByCodeAndCodeGroup(CodeGroup, Code);
    if (result.isOk) {
      setCodeMngData(result.data[0]);
    }
  }, []);

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { CodeGroup, Code, CodeDesc, Attribute1, Attribute2, Attribute3, Remark, UseYN, SortOrder } =
        formRef.current.props.formData;
      if (isUpdate) {
        const result = await master.updateCodeMaster({
          CodeGroup,
          Code,
          CodeDesc,
          Attribute1,
          Attribute2,
          Attribute3,
          Remark,
          UseYN,
          SortOrder,
        });

        if (result.isOk) {
          setPopupVisibility({ save: true, close: false });
        }
      } else {
        const result = await master.saveCodeMaster({
          CodeGroup,
          Code,
          CodeDesc,
          Attribute1,
          Attribute2,
          Attribute3,
          Remark,
          UseYN: 'Y',
          SortOrder,
        });
        if (result.isOk) {
          setPopupVisibility({ save: true, close: false });
        }
      }
    },
    [isUpdate],
  );

  const onClose = e => {
    setPopupVisibility({ save: false, close: false });
  };

  const checkValue = (result, data) => {
    if (result.isOk) {
      if (result.data.length > 0) {
        //이전 코드 값이 있을때 - 기존 코드와 value 체크
        return !result.data.some(res => res.Code === data.value);
      } else {
        //초기 값일때
        return true;
      }
    } else {
      return false;
    }
  };

  const asyncValidation = useCallback(async data => {
    const { CodeGroup } = formRef.current.props.formData;
    const result = await master.searchCodeMaster({ Code: data.value, CodeGroup });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(checkValue(result, data));
      }, 1000);
    });
  }, []);

  const renderLabelItem = e => {
    const label = e.data.dataField === 'Code' ? codeMng.Code : searchParams.CodeGroupName;
    return <div style={{ margin: '5px' }}>{label}</div>;
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <Form
            id="form"
            ref={formRef}
            formData={codeMngData}
            showColonAfterLabel={false}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={isUpdate ? '코드 수정' : '코드 등록'}>
              <SimpleItem dataField="CodeGroup" component={renderLabelItem}>
                <Label text="코드 종류" />
                <RequiredRule message="필수값 입니다." />
              </SimpleItem>
              {isUpdate ? (
                <SimpleItem dataField="Code" component={renderLabelItem}>
                  <Label text="코드" />
                </SimpleItem>
              ) : (
                <SimpleItem dataField="Code">
                  <Label text="코드" />
                  <RequiredRule message="필수값 입니다." />
                  <AsyncRule message="코드가 중복되었습니다." validationCallback={asyncValidation} />
                  <StringLengthRule max={40} message="코드명은 40자를 초과할 수 없습니다." />
                </SimpleItem>
              )}
              <SimpleItem dataField="CodeDesc">
                <Label text="코드명" />
                <RequiredRule message="필수값 입니다." />
              </SimpleItem>
              <SimpleItem dataField="Attribute1">
                <Label text="속성1" />
              </SimpleItem>
              <SimpleItem dataField="Attribute2">
                <Label text="속성2" />
              </SimpleItem>
              <SimpleItem dataField="Attribute3">
                <Label text="속성3" />
              </SimpleItem>
              <Item
                dataField="Remark"
                editorType="dxTextArea"
                editorOptions={{
                  height: 90,
                }}
              >
                <Label text="비고" />
              </Item>
              {isUpdate && (
                <Item
                  dataField="UseYN"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: [
                      { text: '사용', value: 'Y' },
                      { text: '사용안함', value: 'N' },
                    ],
                    valueExpr: 'value',
                    displayExpr: 'text',
                  }}
                >
                  <Label text="사용여부" />
                </Item>
              )}
              <SimpleItem dataField="SortOrder" editorType="dxNumberBox" helpText="숫자만 입력가능합니다.">
                <Label text="정렬순서" />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'40%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'40%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
};

export default React.memo(CodeMasterForm);
