import React, { useRef, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Column } from 'devextreme-react/data-grid';
import { TagBox } from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { common } from 'api';
import DateBox from 'devextreme-react/date-box';
import './sales-payment-summary.scss';

const userId = localStorage.getItem('userId');

const SalesPaymentSummary = () => {
  const gridRef = useRef({});

  //검색
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [costcenterCode, setCostCenterCode] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  // 사업소 List
  const [costCenterType, setCostCenterType] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await common.costcenterData();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'HiParkingID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/history/getSalesAndAccountSummary`, {
            ...dateValue,
            CostCenterCode: costcenterCode.join(','),
            LoginID: userId,
          });
          return result.data[0];
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [dateValue, costcenterCode, searchButton]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onClickButton = e => {
    const result = [...costcenterCode];
    const add = e.addedItems.map(a => a.CostCenterCode);
    const remove = e.removedItems.map(a => a.CostCenterCode);
    if (add.length > 0) {
      result.push(...add);
      setCostCenterCode(result);
    }
    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setCostCenterCode(newArr);
    }
  };

  const onSearch = () => {
    setSearchButton(!searchButton);
  };

  return (
    <div>
      <h2 className={'content-block detail-title'}>사업소 매출 정산 현황</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 매출 정산 현황 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">매출일/확정일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TagBox
                dataSource={costCenterType}
                showSelectionControls={false}
                maxDisplayedTags={3}
                displayExpr="CostCenterName"
                valueExpr="CostCenterCode"
                placeholder="조회할 사업소를 모두 선택해주세요"
                stylingMode="outlined"
                onSelectionChanged={onClickButton}
                searchEnabled={true}
                width={330}
                selectAllText={'전체'}
                multiline={false}
                // mode="search"
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Column dataField={'HiParkingID'} caption={'P코드'} width={'90'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} alignment={'center'} />
            <Column dataField={'SalesDate'} caption={'매출일'} width={120} alignment={'center'} />
            <Column dataField={'SalesTypeName'} caption={'매출유형'} width={100} alignment={'center'} />
            <Column dataField={'DetailSalesTypeName'} caption={'상세매출유형'} width={80} alignment={'center'} />
            <Column dataField={'TotalSalesCnt'} caption={'매출건수'} width={80} alignment={'center'} />
            <Column
              dataField={'TotalSalesPrice'}
              caption={'매출합계'}
              format="#,##0 원"
              width={'120'}
              alignment={'center'}
            />
            <Column caption={'전체'} alignment={'center'}>
              <Column dataField={'TotalAccountCnt'} caption={'정산건수'} width={80} alignment={'center'} />
              <Column
                dataField={'TotalAccountPrice'}
                caption={'정산합계'}
                format="#,##0 원"
                width={'120'}
                alignment={'center'}
              />
            </Column>
            <Column caption={'카드'} alignment={'center'}>
              <Column dataField={'CardAccountCnt'} caption={'정산건수'} width={80} alignment={'center'} />
              <Column
                dataField={'CardAccountPrice'}
                caption={'정산합계'}
                format="#,##0 원"
                width={'120'}
                alignment={'center'}
              />
            </Column>
            <Column caption={'현금'} alignment={'center'}>
              <Column dataField={'CashAccountCnt'} caption={'정산건수'} width={80} alignment={'center'} />
              <Column
                dataField={'CashAccountPrice'}
                caption={'정산합계'}
                format="#,##0 원"
                width={'120'}
                alignment={'center'}
              />
            </Column>
            <Column caption={'세금계산서'} alignment={'center'}>
              <Column dataField={'TaxInvoiceAccountCnt'} caption={'정산건수'} width={80} alignment={'center'} />
              <Column
                dataField={'TaxInvoiceAccountPrice'}
                caption={'정산합계'}
                format="#,##0 원"
                width={'120'}
                alignment={'center'}
              />
            </Column>
            <Column caption={'기타'} alignment={'center'}>
              <Column dataField={'EtcAccountCnt'} caption={'정산건수'} width={80} alignment={'center'} />
              <Column
                dataField={'EtcAccountPrice'}
                caption={'정산합계'}
                format="#,##0 원"
                width={'120'}
                alignment={'center'}
              />
            </Column>
            <Column caption={'잡손실'} alignment={'center'}>
              <Column dataField={'LossccountCnt'} caption={'정산건수'} width={80} alignment={'center'} />
              <Column
                dataField={'LossAccountPrice'}
                caption={'정산합계'}
                format="#,##0 원"
                width={'120'}
                alignment={'center'}
              />
            </Column>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default SalesPaymentSummary;
