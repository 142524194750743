import { React, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Column, Pager, Summary, GroupItem, Scrolling } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { DateBox } from 'devextreme-react/date-box';
import CommonDataGrid from 'common/default-data-grid';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import './cms-sale-aggregation.scss';

const userId = localStorage.getItem('userId');

const ViewCMSSalesAggregationPage = () => {
  const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'));
  const [fromdate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [todate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const gridRef = useRef({});

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        try {
          const apiResult = await axios.post(`aggregation/getCMSAggregationDataByCostCenterDay`, {
            FromDate: searchDate.FromDate,
            ToDate: searchDate.ToDate,
            LoginID: userId,
          });
          return apiResult.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchDate]);

  const handleFromDate = e => {
    setFromDate(dateFormet(e.value));
  };
  const handleToDate = e => {
    setToDate(dateFormet(e.value));
  };

  const onSearch = () => {
    setSearchDate({FromDate:fromdate, ToDate:todate});
  };

  const dateFormet = date => {
    return moment(date).format('YYYY-MM-DD');
  };
  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  // const dateTimeCellRender = e => {
  //   const { TransactionTime } = e;
  //   const hour = TransactionTime.substring(0, 2);
  //   const minute = TransactionTime.substring(2, 4);
  //   const second = TransactionTime.substring(4, 6);
  //   return hour + ':' + minute + ':' + second;
  // };

  return (
    <div>
      <h2 className={'content-block'}>CMS 매출 집계 데이터 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={true}
            height={650}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: 'CMS 매출 집계 데이터.xlsx',
            }}
            headerBefore={[
              <Toolbar>
                <ToolbarItem>
                  <div className="label">입금일 :</div>
                </ToolbarItem>
                <ToolbarItem>
                  <DateBox
                    type="date"
                    displayFormat="yyyy-MM-dd"
                    stylingMode="outlined"
                    dropDownButtonTemplate={'dropDownButton'}
                    useMaskBehavior={true}
                    icon={true}
                    dataField="FromDate"
                    onValueChanged={handleFromDate}
                    value={fromdate}
                  />
                </ToolbarItem>
                <ToolbarItem>
                  <span> ~ </span>
                </ToolbarItem>
                <ToolbarItem>
                  <DateBox
                    type="date"
                    displayFormat="yyyy-MM-dd"
                    stylingMode="outlined"
                    dropDownButtonTemplate={'dropDownButton'}
                    useMaskBehavior={true}
                    icon={true}
                    dataField="ToDate"
                    min={fromdate}
                    value={todate}
                    onValueChanged={handleToDate}
                    dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                  />
                </ToolbarItem>
              </Toolbar>
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            {/* <Scrolling mode="infinite" /> */}
            {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
            {/* <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" /> */}
            <Column dataField={'KeyID'} caption={'KeyID'} visible={false} />
            <Column dataField={'HiparkingID'} caption={'P코드'} />
            <Column dataField={'CompanyName'} caption={'법인명'} groupIndex={0} />
            <Column dataField={'SiteNo'} caption={'SiteNo'} />
            <Column dataField={'BankCode'} caption={'은행코드'} />
            <Column dataField={'BankName'} caption={'은행명'} />
            <Column dataField={'BankAccountNo'} caption={'계좌번호'} />
            <Column dataField={'SeqNo'} caption={'Seq번호'} alignment="left" width={80} />
            <Column dataField={'TransactionDate'} caption={'전송일'} customizeText={customizeText} />
            {/* <Column dataField={'TransactionTime'} caption={'매출시간'} calculateCellValue={dateTimeCellRender} /> */}
            <Column dataField={'TransactionSummary'} caption={'적요'} />
            <Column dataField={'TransactionTypeName'} caption={'구분'} groupIndex={1} />
            <Column dataField={'TransactionAmount'} caption={'금액'} format="#,##0 원" />
            <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} />
            {/* <Column dataField={'AccountBalance'} caption={'AccountBalance'} format="#,##0 원" /> */}
            <Column dataField={'Remark'} caption={'비고'} />
            <Column dataField={'Branch'} caption={'Branch'} />
            {/* <Column dataField={'InsertUserID'} caption={'InsertUserID'} /> */}
            {/* <Column dataField={'InsertDateTime'} caption={'InsertDateTime'} /> */}
            <Column dataField={'GiroCode'} caption={'지로번호'} />
            {/* <Column dataField={'LinkedAccount'} caption={'연결계좌'} /> */}
            <Column dataField={'ExtraField1'} caption={'비고1'} />
            {/* <Column dataField={'ExtraField2'} caption={'비고2'} />
            <Column dataField={'ExtraField3'} caption={'비고3'} />
            <Column dataField={'ExtraField4'} caption={'비고4'} />
            <Column dataField={'ExtraField5'} caption={'비고5'} /> */}
            <Column dataField={'CNAC_DPSR_NM'} caption={'설명'} />

            <Summary>
              <GroupItem column="SiteNo" summaryType="count" displayFormat="총 {0}건" />
              <GroupItem
                column="TransactionAmount"
                summaryType="sum"
                valueFormat="#,##0 원"
                showInGroupFooter={false}
                displayFormat="매출 합계: {0}"
              />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default ViewCMSSalesAggregationPage;
