import React from 'react';
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export } from 'devextreme-react/chart';

const sideBySideBar = () => {
  const grossProductData = [
    {
      state: 'Illinois',
      year2016: 803,
      year2017: 823,
      year2018: 863,
    },
    {
      state: 'Indiana',
      year2016: 316,
      year2017: 332,
      year2018: 332,
    },
    {
      state: 'Michigan',
      year2016: 452,
      year2017: 459,
      year2018: 470,
    },
    {
      state: 'Ohio',
      year2016: 621,
      year2017: 642,
      year2018: 675,
    },
    {
      state: 'Wisconsin',
      year2016: 290,
      year2017: 294,
      year2018: 301,
    },
  ];

  const onPointClick = e => {
    e.target.select();
  };
  return (
    <Chart id="chart" dataSource={grossProductData} onPointClick={onPointClick} width={900}>
      <CommonSeriesSettings
        argumentField="state"
        type="bar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
      >
        <Label visible={true}>
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>
      <Series argumentField="state" valueField="year2018" name="2018" />
      <Series valueField="year2017" name="2017" />
      <Series valueField="year2016" name="2016" />
      <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
      <Export enabled={true} />
    </Chart>
  );
};

export default sideBySideBar;
