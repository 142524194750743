import React, { useCallback, useState, useRef } from 'react';
import moment from 'moment';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { taxinvoiceReceiptManualLink } from 'constants/hyperlink';
import { Help } from 'components';
import { cancelCashbillAPI, checkCashbillAPI, confirmCancellation } from 'common/validators/validators';
import { salesPayment } from 'api';

import notify from 'devextreme/ui/notify';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import DateBox from 'devextreme-react/date-box';
import ScrollView from 'devextreme-react/scroll-view';
import GeneralTicket from './salesTab/generalTicket';
import SeasonTicket from './salesTab/seasonTicket';
import EtcSales from './salesTab/etcSales';
import TexinvoiceHistory from './texinvoice-history';
import AddTaxinvoice from './popup/AddTaxinvoice';
import '../check-sales-payment/detail.scss';

const SalesTaxinvoice = () => {
  const addTaxinvoiceRef = useRef({});
  const [gRowdata, setGRowdata] = useState([]);
  const [sRowdata, setSRowdata] = useState([]);
  const [eRowdata, setERowdata] = useState([]);

  const [gDataSource, setGDataSource] = useState([]);
  const [sDataSource, setSDataSource] = useState([]);
  const [eDataSource, setEDataSource] = useState([]);

  const [tabIdx, setTabIdx] = useState(0);
  const [tabIdxes, setTabIdxes] = useState([]);

  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCardNo: '',
    IsforRecieveTaxAccounting: 'Y',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCardNo: '',
    IsforRecieveTaxAccounting: 'Y',
  });

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [gridLoadPanelVisible, setGridLoadPanelVisible] = useState(false);

  // 팝업
  const [isOpen, setIsOpen] = useState(false);
  const [helpVisible, setHelpVisible] = useState(false);

  //ref
  const generalTicketRef = useRef({});
  const seasonTicketRef = useRef({});
  const etcSalesRef = useRef({});
  const texinvoiceHistoryRef = useRef({});
  const helpRef = useRef({});

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    callAPI();
  };

  // refreshIdx : 일반권 (0), 정기권(1), 기타(2)
  const callAPI = refreshIdx => {
    const idx = refreshIdx ? refreshIdx : tabIdx;
    const searchParam = {
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      SearchApprovalNo: text.SearchApprovalNo,
      SearchVehicleNumber: text.SearchVehicleNumber,
      SearchCardNo: text.SearchCardNo,
      IsforRecieveTaxAccounting: 'Y',
    };
    if (idx === 0) {
      getGDataSource(searchParam);
    } else if (idx === 1) {
      getSDataSource(searchParam);
    } else if (idx === 2) {
      getEDataSource(searchParam);
    }
  };

  const getGDataSource = searchParam => {
    (async () => {
      setGridLoadPanelVisible(true);
      const result = await salesPayment.getParkingSalesDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setGridLoadPanelVisible(false);
        setGDataSource(result.data);
      } else {
        setGridLoadPanelVisible(false);
        setGDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };
  const getSDataSource = searchParam => {
    (async () => {
      setGridLoadPanelVisible(true);
      const result = await salesPayment.getSeasonTicketSalesDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setGridLoadPanelVisible(false);
        setSDataSource(result.data);
      } else {
        setGridLoadPanelVisible(false);
        setSDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };
  const getEDataSource = searchParam => {
    (async () => {
      setGridLoadPanelVisible(true);
      const result = await salesPayment.getEtcSalesDataDetailConfirmed(searchParam);
      if (result.isOk) {
        setGridLoadPanelVisible(false);
        setEDataSource(result.data);
      } else {
        setGridLoadPanelVisible(false);
        setEDataSource([]);
        notify(
          {
            message: result.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const onHiding = () => {
    setIsOpen(false);
    setHelpVisible(false);
  };

  // 세금계산서 발행
  const addTaxinvoice = async () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    if (g === 0 && s === 0 && e === 0) {
      alert('매출 내역을 선택해주세요');
    } else {
      const data = [];
      const refreshTabs = [];
      if (g > 0) {
        data.push(...gRowdata);
        refreshTabs.push(0);
      }
      if (s > 0) {
        data.push(...sRowdata);
        refreshTabs.push(1);
      }
      if (e > 0) {
        data.push(...eRowdata);
        refreshTabs.push(2);
      }

      const keyIDList = data.map(a => a.KeyID);
      setLoadPanelVisible(true);
      const invoker = 'RegisterTaxInvoiceR';
      const confirmIdArr = await checkCashbillAPI(keyIDList, invoker);
      const confirmIdLength = confirmIdArr.length;

      if (!confirmIdArr) {
        setLoadPanelVisible(false);
        return;
      }
      if (confirmIdLength > 0) {
        setLoadPanelVisible(false);
        const isConfirmed = await confirmCancellation(confirmIdArr);
        if (!isConfirmed) {
          return;
        }
        setLoadPanelVisible(true);
        const cancelResult = await cancelCashbillAPI(confirmIdArr);
        if (!cancelResult) {
          setLoadPanelVisible(false);
          return;
        } else {
          refresh();
        }
      }
      setLoadPanelVisible(false);
      setTabIdxes(refreshTabs);
      setIsOpen(true);
      addTaxinvoiceRef.current.open(data);
    }
  };

  const refresh = useCallback(async () => {
    await Promise.all(tabIdxes.map(a => callAPI(a)));
    texinvoiceHistoryRef.current.refresh();
  }, [tabIdxes]);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onHelpClick = () => {
    setHelpVisible(true);
    helpRef.current.open('taxinvoice');
  };

  const onSelectedIndexChange = e => {
    setTabIdx(e);
  };
  return (
    <div>
      <div>
        <div className="content-block detail-title left-right-header">
          <h2>영수 세금계산서 발행</h2>
          <div>
            <a
              style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
              href={taxinvoiceReceiptManualLink}
              target="blank"
            >
              메뉴얼 바로가기
            </a>
            <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
            <Button icon="help" text="세금계산서 처리절차" onClick={onHelpClick} />
          </div>
        </div>

        <div className={'content-block'}>
          {/* <div className={'dx-card'} style={{ padding: '20px 40px' }}> */}
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div
              className={'dx-card'}
              style={{
                marginRight: '5px',
                maxWidth: '50%',
                padding: '20px 20px',
                borderRadius: '5px',
              }}
            >
              <Toolbar>
                <ToolbarItem location={'before'}>
                  <div className="label">수금일 :</div>
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <DateBox
                    type="date"
                    displayFormat="yyyy-MM-dd"
                    stylingMode="outlined"
                    dropDownButtonTemplate={'dropDownButton'}
                    useMaskBehavior={true}
                    icon={true}
                    dataField="FromDate"
                    onValueChanged={handleFromDate}
                    value={dateValue.fromDate}
                  />
                </ToolbarItem>
                <ToolbarItem location={'before'} text="~" />
                <ToolbarItem location={'before'}>
                  <DateBox
                    type="date"
                    displayFormat="yyyy-MM-dd"
                    stylingMode="outlined"
                    dropDownButtonTemplate={'dropDownButton'}
                    useMaskBehavior={true}
                    icon={true}
                    dataField="ToDate"
                    min={dateValue.fromDate}
                    value={dateValue.toDate}
                    onValueChanged={handleToDate}
                    dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                  />
                </ToolbarItem>
                <ToolbarItem location={'after'}>
                  <Button text="조회" onClick={onEnterKey} />
                </ToolbarItem>
                <ToolbarItem location={'after'}>
                  <Button text="세금 계산서 발행" onClick={addTaxinvoice} />
                </ToolbarItem>
              </Toolbar>

              <Toolbar>
                <ToolbarItem location={'before'}>
                  <TextBox
                    label="SearchCostCenter"
                    labelMode="hidden"
                    stylingMode="outlined"
                    placeholder="P-코드/사업소코드/사업소명"
                    onValueChanged={onChangeSearchText}
                    onEnterKey={onEnterKey}
                    width={220}
                    mode="search"
                  />
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <TextBox
                    label="SearchApprovalNo"
                    labelMode="hidden"
                    stylingMode="outlined"
                    placeholder="승인번호/계좌번호"
                    onValueChanged={onChangeSearchText}
                    onEnterKey={onEnterKey}
                    width={150}
                    mode="search"
                  />
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <TextBox
                    label="SearchCardNo"
                    labelMode="hidden"
                    stylingMode="outlined"
                    placeholder="카드번호"
                    onValueChanged={onChangeSearchText}
                    onEnterKey={onEnterKey}
                    width={150}
                    mode="search"
                  />
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <TextBox
                    label="SearchVehicleNumber"
                    labelMode="hidden"
                    stylingMode="outlined"
                    placeholder="차량번호/이름"
                    onValueChanged={onChangeSearchText}
                    onEnterKey={onEnterKey}
                    width={150}
                    mode="search"
                  />
                </ToolbarItem>
              </Toolbar>
              {/* <TabPanel onTitleClick={onTabClick}> */}
              <TabPanel onSelectedIndexChange={onSelectedIndexChange}>
                <Item title="일반권" badge={gRowdata.length}>
                  <GeneralTicket
                    generalTicketRef={generalTicketRef}
                    setGRowdata={setGRowdata}
                    dataSource={gDataSource}
                  />
                </Item>
                <Item title="정기권" badge={sRowdata.length}>
                  <SeasonTicket seasonTicketRef={seasonTicketRef} setSRowdata={setSRowdata} dataSource={sDataSource} />
                </Item>
                <Item title="기타" badge={eRowdata.length}>
                  <EtcSales etcSalesRef={etcSalesRef} setERowdata={setERowdata} dataSource={eDataSource} />
                </Item>
              </TabPanel>
              <PSLoadPanel
                shadingColor="rgba(0,0,0,0)"
                position={{ of: '.dx-card', at: 'center', my: 'center' }}
                visible={gridLoadPanelVisible}
              />
            </div>
            <div className={'dx-card'} style={{ maxWidth: '50%', padding: '20px 20px', borderRadius: '5px' }}>
              <TexinvoiceHistory
                setLoadPanelVisible={setLoadPanelVisible}
                ref={texinvoiceHistoryRef}
                refresh={refresh}
              />
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="50%" height={850}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <AddTaxinvoice
              ref={addTaxinvoiceRef}
              setIsOpen={setIsOpen}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onHiding={onHiding} visible={helpVisible} showTitle={false} width="60%" height="84%">
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <Help setHelpVisible={setHelpVisible} ref={helpRef} />
          </div>
        </ScrollView>
      </Popup>
      <div style={{ zIndex: '1000001' }}>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ top: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </div>
    </div>
  );
};

export default SalesTaxinvoice;
