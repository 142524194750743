import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import ContextMenu from 'devextreme-react/context-menu';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { cellStatus } from '../check-sales-payment/component/common/common';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import EditPrice from './popup/editPrice';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';

const SeasonTicket = props => {
  const {
    setSRowdata,
    inquiryDate,
    costCenter,
    seasonTicketRef,
    isClosePending,
    isTaxinvoice,
    searchData,
    isSearch,
    isSalesPayment,
    onCancelClick,
  } = props;
  const [selectSum, setSelectSum] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [ticketID, setTicketID] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    setSRowdata(rowdata);

    // 합계
    const sum = rowdata.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const onHiding = () => {
    setIsOpen(false);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      return { CostCenter: costCenter, IsPostponed: 'Y', InquiryMonth: inquiryDate };
    } else if (isTaxinvoice) {
      return isSearch ? searchData : {};
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || isSearch) {
          const result = await salesPayment.getSSalesDataDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O'? (
      <>
        <div id={'status-cancel-' + AccountingNo}>{StatusName}</div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onOpen = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    const sales = rowdata.map(r => r.SalesPrice);
    const set = new Set(sales);
    const result = [...set];

    if (rowdata.length === 0) {
      alert('대상을 선택해주세요');
    } else if (result.length > 1) {
      alert('동일한 금액에 한해서 일괄 수정 가능합니다');
    } else {
      const TicketIDs = rowdata.map(r => r.KeyID);
      setTicketID(TicketIDs);
      setCurrentPrice(Number(result));
      setIsOpen(true);
    }
  };

  const refresh = () => {
    seasonTicketRef.current.instance.refresh();
  };
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };
  return (
    <>
      <CommonDataGrid
        gridRef={seasonTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={595}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '정기권.xlsx',
        }}
        allowColumnReordering={true}
        headerAfter={
          isTaxinvoice
            ? [
                // 서버 개발 후 적용
                <Button text="금액 일괄 수정" onClick={onOpen} />,
              ]
            : []
        }
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={'100'} />\
        <Column dataField={'MemberName'} caption={'고객명'} width={'100'} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={'100'} />
        {isTaxinvoice && <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={'110'} />}
        {isTaxinvoice && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        <Column dataField={'ApprovedDate'} caption={'확정일시'} customizeText={customizeText} width={'100'} />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'100'}
          cellRender={isSalesPayment ? editCellRender : ''}
        />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={'140'} />
        <Column dataField={'FromDate'} caption={'시작일'} width={'100'} />
        <Column dataField={'ToDate'} caption={'종료일'} width={'100'} />
        <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={'100'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} width={'100'} />
        <Column dataField={'Remark'} caption={'비고'} width={'100'} />
        <Summary>
          <TotalItem showInColumn="CustomerCorpName" displayFormat="선택 합계:" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="CustomerCorpName" />
          <TotalItem
            column="SalesPrice"
            showInColumn="VehicleRegistrationNo"
            summaryType="count"
            displayFormat="총 {0}건"
          />
          <TotalItem
            column="SalesPrice"
            showInColumn="VehicleRegistrationNo"
            summaryType="sum"
            valueFormat="#,##0 원"
            displayFormat="{0}"
          />
        </Summary>
      </CommonDataGrid>
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="22%" height={300}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <EditPrice
              setIsOpen={setIsOpen}
              ticketID={ticketID}
              currentPrice={currentPrice}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </>
  );
};

export default React.memo(SeasonTicket);
