import React, { useEffect, useRef, useState } from 'react';
import TreeList, { Column } from 'devextreme-react/tree-list';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { SelectBox } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import { system, common } from 'api';
import { activeSelect } from 'common/select/Types';
import AddMenu from './component/addMenu';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import '../system.scss';

const Menumng = () => {
  const addMenuRef = useRef({});
  const treeListRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [popupVisible, setPopupVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  // 권한
  useEffect(() => {
    (async () => {
      await common.commonData().then(res => {
        if (res.isOk) {
          setRoleList(res.data.role);
        }
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await system
        .getMenuList({
          IsActive: isActive,
        })
        .then(res => {
          if (res.isOk) {
            setDataSource(res.data);
          }
        });
    })();
  }, [isActive, isRefresh]);

  const isActiveCellRender = e => {
    const { IsActive } = e.data;
    return IsActive === 0 ? '미사용' : '사용';
  };

  const roleCellRender = e => {
    const { RoleCodeList } = e.data;

    return RoleCodeList.map(roleCode => {
      const RoleNameListTextArr = [];
      const { text: roleName, color: roleColor } = roleList.filter(a => a.key === roleCode)[0];
      if (roleName) {
        RoleNameListTextArr.push(roleName);
      }
      return (
        <div key={roleCode} style={{ width: 'auto' }} className={`Badge bg-${roleColor}`}>
          {roleName}
        </div>
      );
    });
  };

  const handleIsActive = e => {
    const { value } = e.itemData;
    setIsActive(value);
  };

  const onClose = () => {
    setPopupVisibility(false);
  };

  const onOpenPopup = (type, data) => {
    setPopupVisibility(true);
    setIsUpdate(!!(type === 'update'));
    addMenuRef.current.open(type, data);
  };

  const editCellRender = e => {
    return <Button icon="edit" onClick={() => onOpenPopup('update', e.data)} />;
  };

  const refresh = () => {
    setIsRefresh(!isRefresh);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };
  return (
    <div>
      <h2 className={'content-block'}>메뉴 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <SelectBox
                labelMode="static"
                placeholder="사용여부"
                dropDownButtonTemplate="dropDownButton"
                stylingMode="outlined"
                dataSource={activeSelect}
                value={isActive}
                onItemClick={handleIsActive}
                valueExpr="value"
                displayExpr="label"
                width={150}
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button text="메뉴 등록" onClick={() => onOpenPopup('add')} />
            </ToolbarItem>
          </Toolbar>
          <TreeList
            ref={treeListRef}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            itemsExpr="subData"
            showRowLines={true}
            height={700}
            allowColumnResizing={true}
            keyExpr="MenuID"
            dataStructure="tree"
          >
            <Column dataField="MenuName" caption={'메뉴명'} />
            <Column dataField="MenuEnName" caption={'메뉴명(영문)'} />
            <Column dataField="RoleCodeList" caption={'권한'} cellRender={roleCellRender} width={800} />
            <Column dataField="SortOrder" caption={'순서'} />
            <Column dataField="IsActive" caption={'사용여부'} cellRender={isActiveCellRender} />
            <Column dataField="edit" caption={'관리'} cellRender={editCellRender} allowExporting={false} />
          </TreeList>
        </div>
      </div>
      <Popup onHiding={onClose} visible={popupVisible} showTitle={false} width="32%" height={640}>
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <AddMenu
              ref={addMenuRef}
              setPopupVisibility={setPopupVisibility}
              roleList={roleList}
              isUpdate={isUpdate}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default Menumng;
