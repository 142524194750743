import React, { useImperativeHandle, useRef, useState } from 'react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import moment from 'moment';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';

const DetailSalesInfo = React.forwardRef((props, ref) => {
  const { setIsPopup } = props;
  const [pgData, setPgData] = useState([]);
  const [vanData, setVanData] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const gridRef = useRef({});

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = async (AccountingNo, type) => {
    // 결제 이력 조회해서 뿌려주기

    if (type === 'generalTicket') {
      await salesPayment.getAccountingGeneralSalesDetailPG({ AccountingNo }).then(res => {
        if (res.isOk) {
          setPgData(res.data);
        }
      });
      await salesPayment.getAccountingGeneralSalesDetailVAN({ AccountingNo }).then(res => {
        if (res.isOk) {
          setVanData(res.data);
        }
      });
      await salesPayment.getAccountingGeneralSalesDetailCMS({ AccountingNo }).then(res => {
        if (res.isOk) {
          setCmsData(res.data);
        }
      });
    } else {
      await salesPayment.getAccountingSeasonTicketDetailPG({ AccountingNo }).then(res => {
        if (res.isOk) {
          setPgData(res.data);
        }
      });
      await salesPayment.getAccountingSeasonTicketDetailVAN({ AccountingNo }).then(res => {
        if (res.isOk) {
          setVanData(res.data);
        }
      });
      await salesPayment.getAccountingSeasonTicketDetailCMS({ AccountingNo }).then(res => {
        if (res.isOk) {
          setCmsData(res.data);
        }
      });
    }
  };

  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onClose = () => {
    setIsPopup(false);
  };
  return (
    <div>
      <div className="popup-close-button">
        <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={onClose}></i>
      </div>
      <div className="popup-header">결제이력 상세</div>
      <div className="popup-content">
        <div className="dx-fieldset">
          <div className="dx-fieldset-header" style={{ margin: '0px' }}>
            PG 사
          </div>
          <div style={{ padding: '0px' }}>
            <CommonDataGrid
              gridRef={gridRef}
              dataSource={pgData}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              height={150}
              isHeader={false}
              isExcel={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column dataField={'MatchingType'} caption={'유형'} />
              <Column dataField={'TransactionDate'} caption={'처리 일시'} customizeText={customizeText} />
              <Column dataField={'CardType'} caption={'카드유형'} />
              <Column dataField={'VANID'} caption={'단말기 번호'} />
              <Column dataField={'ApprovalType'} caption={'승인유형'} />
              <Column dataField={'TransactionID'} caption={'처리번호'} />
              <Column dataField={'OrderID'} caption={'주문번호'} />
              <Column dataField={'ApprovalNo'} caption={'승인번호'} />
              <Column dataField={'SalesPrice'} caption={'결제 금액'} format="#,##0 원" />
              <Column dataField={'IssueCreditCard'} caption={'카드발행사'} />
              <Column dataField={'SettleCreditCard'} caption={'카드결제사'} />
              <Column dataField={'MonthlyInstallmentsType'} caption={'할부유형'} />
              <Column dataField={'CreditCardNo'} caption={'카드 번호'} />
              <Column dataField={'Remark1'} caption={'비고1'} />
              <Column dataField={'Remark2'} caption={'비고2'} />
              <Column dataField={'RegisterUser'} caption={'등록인'} />
              <Column dataField={'RegisterDate'} caption={'등록일시'} />
            </CommonDataGrid>
          </div>
        </div>

        <div className="dx-fieldset">
          <div className="dx-fieldset-header" style={{ margin: '0px' }}>
            VAN 사
          </div>
          <div style={{ padding: '0px' }}>
            <CommonDataGrid
              gridRef={gridRef}
              dataSource={vanData}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              height={150}
              isHeader={false}
              isExcel={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column dataField={'MatchingType'} caption={'유형'} />
              <Column dataField={'CompanyCode'} caption={'CompanyCode'} />
              <Column dataField={'VanType'} caption={'VAN사'} />
              <Column dataField={'VANID'} caption={'단말기 번호'} />
              <Column dataField={'VANIDName'} caption={'단말기 명'} />
              <Column dataField={'VANSubID'} caption={'가맹점 ID'} />
              <Column dataField={'ApprovalType'} caption={'처리 유형'} />
              <Column dataField={'TransactionDate'} caption={'처리 일시'} customizeText={customizeText} />
              <Column dataField={'SalesPrice'} caption={'결제 금액'} format="#,##0 원" />
              <Column dataField={'ApprovalNo'} caption={'승인 번호'} />
              <Column dataField={'ApprovalCode'} caption={'승인 코드'} />
              <Column dataField={'OrgApprovalDate'} caption={'승인 날짜'} />
              <Column dataField={'DepositDueDate'} caption={'납기일'} customizeText={customizeText} />
              <Column dataField={'Commission'} caption={'수수료'} />
              <Column dataField={'DepositDuePrice'} caption={'DepositDuePrice'} />
              <Column dataField={'CreditCardNo'} caption={'카드 번호'} />
              <Column dataField={'CardType'} caption={'카드 유형'} />
              <Column dataField={'IssueCreditCard'} caption={'카드발행사'} />
              <Column dataField={'SettleCreditCard'} caption={'카드결제사'} />
              <Column dataField={'MonthlyInstallmentsType'} caption={'할부유형'} />
              <Column dataField={'RegisterUser'} caption={'등록인'} />
              <Column dataField={'RegisterDate'} caption={'등록일시'} />
            </CommonDataGrid>
          </div>
        </div>

        <div className="dx-fieldset">
          <div className="dx-fieldset-header" style={{ margin: '0px' }}>
            CMS 사
          </div>
          <div style={{ padding: '0px' }}>
            <CommonDataGrid
              gridRef={gridRef}
              dataSource={cmsData}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              height={150}
              isHeader={false}
              isExcel={false}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column dataField={'MatchingType'} caption={'유형'} />
              <Column dataField={'SiteNo'} caption={'사업자번호'} />
              <Column dataField={'BankCode'} caption={'은행번호'} />
              <Column dataField={'BankAccountNo'} caption={'계좌번호'} />
              <Column dataField={'SeqNo'} caption={'일련번호'} />
              <Column dataField={'TransactionDate'} caption={'처리 일시'} customizeText={customizeText} />
              <Column dataField={'TransactionSummary'} caption={'비고'} />
              <Column dataField={'TransactionAmount'} caption={'거래 금액'} format="#,##0 원" />
              <Column dataField={'GiroCode'} caption={'지로 번호'} />
              <Column dataField={'LinkedAccount'} caption={'연결 계좌'} />
              <Column dataField={'ExtraField1'} caption={'기타1'} />
              <Column dataField={'ExtraField2'} caption={'기타2'} />
              <Column dataField={'RegisterDate'} caption={'등록일시'} customizeText={customizeText} />
              <Column dataField={'DBInsertDate'} caption={'데이터 등록일시'} customizeText={customizeText} />
            </CommonDataGrid>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DetailSalesInfo;
