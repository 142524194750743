import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect, useRef } from 'react';
import moment from 'moment';
import ContextMenu from 'devextreme-react/context-menu';

import EditForm from 'common/popup/season-ticket-mng-edit-form';
import CustomStore from 'devextreme/data/custom_store';
import ScrollView from 'devextreme-react/scroll-view';
import CommonDataGrid from '../common/index';
import notify from 'devextreme/ui/notify';
import EditPrice from './popup/editPrice';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { cellStatus } from '../common/common';
import { ticket, salesPayment, error } from 'api';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';

/*
isSalesPayment : 매출 대사 관리
isTaxinvoice, isSearch : 세금계산서 발행 --- 컴포넌트 분리됨. 미사용
isClosePending : 미처리 매출/결제 마감 --- 컴포넌트 분리됨. 미사용
*/

const SeasonTicket = React.forwardRef((props, ref) => {
  const {
    setSRowdata,
    inquiryDate,
    costCenter,
    seasonTicketRef,
    isClosePending,
    isTaxinvoice,
    searchData,
    isSearch,
    isSalesPayment,
    onCancelClick,
    setSelectedID,
    setSelectedAccountingNo,
  } = props;
  const [selectSum, setSelectSum] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [editPopup, setEditPopup] = useState({
    save: false,
    visible: false,
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectPayType, setSelectPayType] = useState([]);
  const [selectMemberType, setSelectMemberType] = useState([]);
  const [ticketID, setTicketID] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const editRef = useRef({});
  const isRefreshRef = useRef(isRefresh);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));

  const focusChange = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    seasonTicketRef.current.instance.selectRows(KeyIDs);
  };

  const [customizedColumns, setCustomizedColumns] = useState([]);
  useEffect(() => {
    if (isRefresh) {
      isRefreshRef.current = isRefresh;
      seasonTicketRef.current.instance.refresh();
    }
  }, [isRefresh]);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberType', 'PaymentType'],
      });
      const MemberType = [];
      const PaymentType = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentType') {
            PaymentType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectMemberType(MemberType);
      setSelectPayType(PaymentType);
    })();
  }, []);

  useEffect(() => {
    const customizedSeason = localStorage.getItem('customizedSeason');
    if (customizedSeason) {
      const tmp_columns = JSON.parse(customizedSeason);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    setSRowdata(rowdata);

    // 합계
    const sum = rowdata.reduce((a, c) => {
      return a + c.RemainAmount;
    }, 0);
    setSelectSum(sum);
  };

  const onHiding = () => {
    setIsOpen(false);
    setEditPopup({ ...editPopup, visible: false });
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      return { CostCenter: costCenter, IsPostponed: 'Y', InquiryMonth: inquiryDate };
    } else if (isTaxinvoice) {
      return isSearch ? searchData : {};
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || isSearch) {
          if (!isRefreshRef.current) {
            seasonTicketRef.current.instance.clearSelection();
          }
          const result = await salesPayment.getSSalesDataDetail(params);
          setForceRender(prev => !prev);
          setIsRefresh(false);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status, KeyID } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
              keyId: KeyID,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const refresh = () => {
    setIsRefresh(true);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { KeyID, AccountingNo } = data;
      setSelectedID(AccountingNo ? KeyID : 'N/A');
      setSelectedAccountingNo('');
      if (AccountingNo) {
        focusChange(AccountingNo);
      }
    }
  };

  // 정기권 삭제
  const onDeleteClick = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    const status = rowdata.map(a => a.Status);
    const allStatusW = status.every(a => a === 'W');

    if (rowdata.length === 0) {
      return alert('대상을 선택해주세요');
    }
    if (!allStatusW) {
      return alert('대기중인 항목만 수정 가능합니다.');
    }
    const result = confirm('<i>수집된 정기권 결제 건을 취소하시겠습니까?</i>', '확인');
    const TicketIDs = rowdata.map(r => r.KeyID);
    result.then(dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        ticket
          .cancelSeasonTicketIssueHistory({
            TicketID: TicketIDs,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '발행 취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedSeason', JSON.stringify(dataFieldsArr));
      });
    }
  };

  // 정기권 수정 (발행)
  const showEditPopup = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    const ticketIDs = rowdata.map(a => a.KeyID);
    const status = rowdata.map(a => a.Status);
    const allStatusW = status.every(a => a === 'W');
    if (!allStatusW) {
      return alert('대기 중인 정기권만 수정 가능합니다.');
    }
    if (rowdata.length >= 1) {
      setEditPopup({ save: false, visible: !editPopup.visible });
      editRef.current.open(ticketIDs);
    } else {
      return alert('수정할 정기권을 선택해 주세요.');
    }
  };

  return (
    <>
      <CommonDataGrid
        gridRef={seasonTicketRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        focusedRowEnabled={false}
        hoverStateEnabled={true}
        onContentReady={onContentReady}
        height={480}
        width={'100%'}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '정기권.xlsx',
        }}
        allowColumnReordering={true}
        headerAfter={[
          <Button
            icon="edit"
            text="수정"
            width={80}
            type="normal"
            stylingMode="contained"
            onClick={() => showEditPopup()}
          />,
          <Button icon="minus" text="취소" width={80} type="danger" stylingMode="contained" onClick={onDeleteClick} />,
        ]}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        {(isTaxinvoice || isClosePending) && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        {isClosePending && <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />}
        <Column
          dataField={'ApprovedDate'}
          caption={'확정일시'}
          customizeText={customizeText}
          width={'97'}
          visible={!customizedColumns.includes('ApprovalDate')}
        />
        <Column
          dataField={'VehicleRegistrationNo'}
          caption={'차량번호'}
          width={'87'}
          visible={!customizedColumns.includes('VehicleRegistrationNo')}
        />
        {isTaxinvoice && <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={'110'} />}
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'85'}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('StatusName')}
        />
        <Column
          dataField={'RemainAmount'}
          caption={'잔액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('RemainAmount')}
        />
        <Column
          dataField={'SalesPrice'}
          caption={'매출금액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('SalesPrice')}
        />
        <Column
          dataField={'CustomerCorpName'}
          caption={'사업자명'}
          width={'90'}
          visible={!customizedColumns.includes('CustomerCorpName')}
        />
        <Column
          dataField={'FromDate'}
          caption={'시작일'}
          width={'100'}
          visible={!customizedColumns.includes('FromDate')}
        />
        <Column dataField={'ToDate'} caption={'종료일'} width={'100'} visible={!customizedColumns.includes('ToDate')} />
        <Column
          dataField={'PaymentTypeName'}
          caption={'결제유형'}
          width={'100'}
          visible={!customizedColumns.includes('PaymentTypeName')}
        />
        <Column
          dataField={'CreditCardApprovalNo'}
          caption={'승인번호'}
          width={'100'}
          visible={!customizedColumns.includes('CreditCardApprovalNo')}
        />
        <Column
          dataField={'CreditCardNo'}
          caption={'카드번호'}
          width={'100'}
          visible={!customizedColumns.includes('CreditCardNo')}
        />
        <Column
          dataField={'CorpRegNumber'}
          caption={'사업자번호'}
          width={'100'}
          visible={!customizedColumns.includes('CorpRegNumber')}
        />

        <Column
          dataField={'MemberName'}
          caption={'고객명'}
          width={'100'}
          visible={!customizedColumns.includes('MemberName')}
        />
        <Column
          dataField={'MemberGroupName'}
          caption={'그룹명'}
          width={'100'}
          visible={!customizedColumns.includes('MemberGroupName')}
        />
        <Column
          dataField={'IssueCreditCard'}
          caption={'카드사'}
          width={'100'}
          visible={!customizedColumns.includes('IssueCreditCard')}
        />
        {isClosePending && <Column dataField={'CurrentVehicleRegistrationNo'} caption={'현차량번호'} width={'100'} />}
        {isClosePending && <Column dataField={'CurrentMemberName'} caption={'현사용자명'} width={'100'} />}
        <Column dataField={'Remark'} caption={'비고'} width={'100'} visible={!customizedColumns.includes('Remark')} />
        <Column
          dataField={'AccountingNo'}
          caption={'대사ID'}
          width={'160'}
          visible={!customizedColumns.includes('AccountingNo')}
        />

        <Summary>
          <TotalItem column="SalesPrice" displayFormat="선택합계 :" showInColumn="ApprovedDate" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="ApprovedDate" />
          <TotalItem column="StatusName" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" displayFormat="전체합계 :" showInColumn="SalesPrice" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
          <TotalItem column="RemainAmount" displayFormat="전체합계 :" showInColumn="RemainAmount" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
      {/* 정기권 개별+일괄 수정 팝업 */}
      <Popup
        onHiding={onHiding}
        visible={editPopup.visible}
        showTitle={true}
        width="30%"
        height={580}
        dragOutsideBoundary={true}
        dragEnabled={true}
        showCloseButton={true}
        title="정기권 정보 수정"
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <EditForm
              ref={editRef}
              setPopup={setEditPopup}
              refresh={refresh}
              selectMemberType={selectMemberType}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      {/* 세금계산서 화면 내 정기권 금액 수정 */}
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="22%" height={300}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <EditPrice
              setIsOpen={setIsOpen}
              ticketID={ticketID}
              currentPrice={currentPrice}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </>
  );
});

export default React.memo(SeasonTicket);
