import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import 'common/popup/popup.scss';

const CustomInfoPopup = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
    close,
  }));

  useEffect(() => {
    (async () => {
      const result = await salesPayment.getCustomerMaster();
      if (result.isOk) {
        setDataSource(result.data);
      }
    })();
  }, [isRefresh]);

  const open = () => {
    setIsPopup(true);
    setIsRefresh(!isRefresh);
  };

  const close = () => {
    setIsPopup(false);
    gridRef.current.instance.refresh();
    gridRef.current.instance.clearSelection();
    gridRef.current.instance.clearFilter();
  };

  const onSave = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    props.setSelectedCustomInfo(selectedItems);
    setIsPopup(false);

    gridRef.current.instance.refresh();
    gridRef.current.instance.clearSelection();
    gridRef.current.instance.clearFilter();
  };

  const onCancel = () => {
    // 빈 값 넘겨주기
    const selectedItems = [
      {
        City: '',
        CorpRegNumber: '',
        CountryCode: '',
        CustomerCorpName: '',
        CustomerManagerGroup: '',
        CustomerManagerName: '',
        CustomerNo: '',
        CustomerTelephoneNo: '',
        // 대표이메일
        Email: '',
        FaxNo: '',
        NameOfRepresentative: '',
        PostalCode: '',
        Region: '',
        RegionDesc: '',
        TelephoneNo: '',
        TypeOfBusiness: '',
        TypeOfIndustry: '',
        VatNo: '',
        // 추가됨
        TaxInvoiceEmail1: '',
        TaxInvoiceEmail2: '',
        Street: '',
      },
    ];
    props.setSelectedCustomInfo(selectedItems);
    setIsPopup(false);

    gridRef.current.instance.refresh();
    gridRef.current.instance.clearSelection();
    gridRef.current.instance.clearFilter();
  };

  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="80%" height={730}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <div className="popup-header">사업자 선택</div>
        <div className="popup-content">
          <div className="dx-fieldset">
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isHeader={false}
              height={500}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Selection mode="single" selectAllMode="pages" />
              <Column dataField={'CorpRegNumber'} caption={'사업자등록번호'} width={'120'} />
              <Column dataField={'CustomerCorpName'} caption={'회사명'} width={'200'} />
              <Column dataField={'NameOfRepresentative'} caption={'대표자 성명'} width={'120'} />
              <Column dataField={'City'} caption={'소재지'} width={'120'} />
              <Column dataField={'Street'} caption={'주소'} width={'250'} />
              <Column dataField={'CustomerManagerGroup'} caption={'담당부서'} width={'120'} />
              <Column dataField={'CustomerManagerName'} caption={'담당자'} width={'120'} />
              <Column dataField={'CustomerTelephoneNo'} caption={'담당자 전화번호'} width={'120'} />
              <Column dataField={'TaxInvoiceEmail1'} caption={'담당자 Email1'} width={'120'} />
              <Column dataField={'TelephoneNo'} caption={'대표전화번호'} width={'120'} />
              <Column dataField={'Email'} caption={'대표Email'} width={'120'} />
              <Column dataField={'TypeOfBusiness'} caption={'업종'} width={'200'} />
              <Column dataField={'TypeOfIndustry'} caption={'업태'} width={'200'} />
              <Column dataField={'TaxInvoiceEmail2'} caption={'담당자 Email2'} width={'120'} />
            </CommonDataGrid>
          </div>
        </div>
        <div className="popup-footer">
          <div>
            <Button text="선택" width={'120'} onClick={onSave} />
          </div>
          <div>
            <Button text="선택 안함" width={'120'} onClick={onCancel} />
          </div>
          <div>
            <Button text="닫기" width={'120'} onClick={close} />
          </div>
        </div>
      </div>
    </Popup>
  );
});

export default CustomInfoPopup;
