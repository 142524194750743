import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const EtcSales = props => {
  const { dataSource, tEtcRef, selectTax } = props;

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };
  useEffect(() => {
    if (dataSource && tEtcRef.current && selectTax) {
      const gridInstance = tEtcRef.current.instance;
      gridInstance.selectAll();
    }
  }, [selectTax]);

  return (
    <>
      <CommonDataGrid
        gridRef={tEtcRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        allowSelectAll={true}
        isNotFilterRow={false}
        allowColumnReordering={true}
      >
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
        <Scrolling mode="standard" rowRenderingMode="standard" />
        <HeaderFilter visible={true} />
        <Column dataField={'HiParkingID'} caption={'P코드'} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} />
        <Column dataField={'AccountingDate'} caption={'대사날짜'} />
        <Column dataField={'EtcSalesTypeName'} caption={'고객유형'} />
        <Column dataField={'CustomerName'} caption={'고객명'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" />
        <Column dataField={'SalesDate'} caption={'매출일'} customizeText={customizeText} />
        <Column dataField={'VehicleRegistrationNo'} caption={'시간권차량번호'} width={100} />
        <Column dataField={'PaymentTypeName'} caption={'결제유형'} />
        <Column dataField={'PaymentDate'} caption={'결제시간'} customizeText={customizeText} />
        <Column dataField={'PaymentAgency'} caption={'결제사'} />
        <Column dataField={'PaymentCode'} caption={'수금구분'} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} />
        <Column dataField={'StatusName'} caption={'매출상태'} />
        <Column dataField={'SettlementNo'} caption={'세금계산서번호'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} />
        <Column dataField={'Remark'} caption={'비고'} />
        <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} />
      </CommonDataGrid>
    </>
  );
};

export default React.memo(EtcSales);
