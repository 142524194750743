import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';

import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import CustomStore from 'devextreme/data/custom_store';
import { TextBox } from 'devextreme-react/text-box';
import { SelectBox } from 'devextreme-react/select-box';
import { TagBox } from 'devextreme-react/tag-box';
import ScrollView from 'devextreme-react/scroll-view';

import CommonDataGrid from 'common/default-data-grid';
import AddUser from './component/addUser';
import { activeSelect } from 'common/select/Types';
import { common } from 'api';
import { useAuth } from 'contexts/auth';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import '../system.scss';

const Usermng = () => {
  const { role } = useAuth();
  const [popupVisible, setPopupVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchParams, setSearchParams] = useState({
    EmployeeName: '',
    EmployeeEnName: '',
    NickName: '',
    IsActive: 1,
    RoleCodeList: role,
  });
  const [text, setText] = useState({
    EmployeeName: '',
    EmployeeEnName: '',
    NickName: '',
  });
  const [roleList, setRoleList] = useState([]);

  const gridRef = useRef({});
  const addCompanyRef = useRef({});
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await common.commonData();
      if (result.isOk) {
        setRoleList(result.data.role);
      }
    })();
  }, []);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'UserID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`system/user/list`, {
            ...searchParams,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams]);

  const togglePopup = (type, data) => {
    setIsUpdate(!!(type === 'update'));
    setPopupVisibility(true);
    addCompanyRef.current.open(type, data);
  };

  const editCellRender = e => {
    return <Button icon="edit" type="normal" stylingMode="contained" onClick={() => togglePopup('update', e.data)} />;
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchParams({
      ...searchParams,
      EmployeeName: text.EmployeeName,
      NickName: text.NickName,
    });
  };

  const onClose = () => {
    setPopupVisibility(false);
  };

  const isActiveCellRender = e => {
    const { IsActive } = e.data;
    return IsActive === 0 ? '미사용' : '사용';
  };

  const roleCellRender = e => {
    const { RoleCodeList } = e.data;

    return RoleCodeList.map(roleCode => {
      const RoleNameListTextArr = [];
      const { text: roleName, color: roleColor } = roleList.filter(a => a.key === roleCode)[0];
      if (roleName) {
        RoleNameListTextArr.push(roleName);
      }
      return (
        <div key={roleCode} style={{ width: 'auto' }} className={`Badge bg-${roleColor}`}>
          {roleName}
        </div>
      );
    });
  };

  const onClickButton = e => {
    const result = searchParams.RoleCodeList;
    const add = e.addedItems.map(a => a.key);
    const remove = e.removedItems.map(a => a.key);
    if (add.length > 0) {
      result.push(...add);
      setSearchParams({
        ...searchParams,
        EmployeeName: text.EmployeeName,
        NickName: text.NickName,
        RoleCodeList: result,
      });
    }

    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setSearchParams({
        ...searchParams,
        EmployeeName: text.EmployeeName,
        NickName: text.NickName,
        RoleCodeList: newArr,
      });
    }
  };

  const handleIsActive = e => {
    const { value } = e.itemData;
    setSearchParams({
      ...searchParams,
      EmployeeName: text.EmployeeName,
      NickName: text.NickName,
      IsActive: value,
    });
  };

  const refresh = () => {
    gridRef.current.instance.refresh();
  };
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };
  return (
    <>
      <div>
        <h2 className={'content-block'}>유저 관리</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              height={700}
              isHeader={true}
              isExcel={true}
              isInfiniteScroll={false}
              headerBefore={[
                <TagBox
                  items={roleList}
                  defaultValue={searchParams.RoleCodeList}
                  showSelectionControls={true}
                  displayExpr="text"
                  valueExpr="key"
                  selectAllMode="allPages"
                  placeholder="권한"
                  stylingMode="outlined"
                  onSelectionChanged={onClickButton}
                  width={400}
                  selectAllText={'전체'}
                  multiline={false}
                />,
                <SelectBox
                  labelMode="static"
                  placeholder="사용여부"
                  dropDownButtonTemplate="dropDownButton"
                  stylingMode="outlined"
                  dataSource={activeSelect}
                  value={searchParams.IsActive}
                  onItemClick={handleIsActive}
                  valueExpr="value"
                  displayExpr="label"
                  width={150}
                />,
                <TextBox
                  label="EmployeeName"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="이름"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
                <TextBox
                  label="NickName"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="표기명"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
              ]}
              headerAfter={[<Button text="신규 유저" onClick={() => togglePopup('add')} />]}
            >
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
              <Column dataField={'Email'} caption={'로그인ID'} />
              <Column dataField={'EmployeeName'} caption={'이름'} />
              <Column dataField={'NickName'} caption={'표기명'} />
              <Column dataField={'OfficeMobilePhoneNumber'} alignment="left" caption={'휴대폰번호'} />
              <Column
                dataField={'RoleCodeList'}
                alignment="left"
                caption={'권한'}
                cellRender={roleCellRender}
                width={'400'}
              />
              <Column dataField={'IsActive'} alignment="left" caption={'사용여부'} cellRender={isActiveCellRender} />
              <Column dataField={'CreateDate'} alignment="left" caption={'생성일'} />
              <Column dataField={'UpdateDate'} alignment="left" caption={'수정일'} />
              <Column
                dataField={'edit'}
                caption={'관리'}
                width={'80'}
                cellRender={editCellRender}
                allowExporting={false}
              />
            </CommonDataGrid>
          </div>
        </div>
        <Popup onHiding={onClose} visible={popupVisible} showTitle={false} width="32%" height={640}>
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <AddUser
                ref={addCompanyRef}
                setPopupVisibility={setPopupVisibility}
                isUpdate={isUpdate}
                roleList={roleList}
                refresh={refresh}
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </ScrollView>
        </Popup>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </div>
    </>
  );
};

export default Usermng;
