import React, { useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { Button } from 'devextreme-react/button';
import axios from 'axios';
import { StorageType, readFile, mainURL } from 'utils/common';
import { v4 as uuidv4 } from 'uuid';

const testCompanyID = 2;
const testBizCode = 'B02';
const testFile_ACL = 'private';

const Comp = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [storageType, setStorageType] = useState(StorageType.NODE);

  const [apiResult1, setApiResult1] = useState('');
  const [apiResult2, setApiResult2] = useState('');
  const [apiResult3, setApiResult3] = useState('');
  const [apiResult4, setApiResult4] = useState('');
  const [apiResult5, setApiResult5] = useState('');
  const [newestVer, setNewestVer] = useState(false);
  const [inputFileKeyArr, setInputFileKeyArr] = useState('');
  const [inputCompanyID, setInputCompanyID] = useState('');
  const [inputFileKey1, setInputFileKey1] = useState('');
  const [inputFileKey2, setInputFileKey2] = useState('');
  const [inputFileKey3, setInputFileKey3] = useState('');
  const [inputFileID1, setInputFileID1] = useState('');
  const [inputFileID2, setInputFileID2] = useState('');
  const [inputFileVerKey1, setInputFileVerKey1] = useState('');
  const [inputFileVerKey2, setInputFileVerKey2] = useState('');
  const [inputFileVer, setInputFileVer] = useState('');

  const newFileID = uuidv4().replace(/-/gi, '');

  return (
    <>
      <h2 className={'content-block'}>파일 업로드 (Node, S3)</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div className="widget-container">
            <div>
              <select
                value={storageType}
                onChange={e => {
                  setStorageType(e.target.value);
                }}
              >
                <option value={StorageType.NODE}>파일, Express 내에 저장</option>
                <option value={StorageType.S3}>파일, AWS S3에 저장</option>
              </select>
              <br />
              <br />
              1) 만일 기존의 FileID 에 신규 파일들을 추가할 때 사전에 입력 (여러 파일 가능) :&nbsp;
              <input
                onChange={e => {
                  setInputFileID1(e.target.value);
                }}
                type="text"
                placeholder={`FileID (기존 ID 에 파일 추가할때)`}
                value={inputFileID1}
                style={{ width: '300px' }}
              />
              <br />
              ** DevExtreme 컴포넌트에서는, 여러파일을 선택하더라도 각 파일 1건당 ajax 가 호출됨. 같은 FileID 가
              필요하면 Client 에서 미리 만들어야 함! (Sample : {newFileID})
              <br />
              <br />
              2) 만일 기존의 FileVerKey 에 새로운 버전의 파일을 추가할때, 사전 입력 (한개 파일 업로드만 가능) :&nbsp;
              <input
                onChange={e => {
                  setInputFileVerKey2(e.target.value);
                }}
                type="text"
                placeholder={`FileVerKey (한개의 파일만 입력)`}
                value={inputFileVerKey2}
                style={{ width: '300px' }}
              />
              <input
                onChange={e => {
                  setInputFileVer(e.target.value);
                }}
                type="text"
                placeholder={`FileVer 입력 ('0.01')`}
                value={inputFileVer}
              />
              <FileUploader
                multiple
                accept={'*'}
                // { name: 'Images', value: 'image/*' },
                // { name: 'Videos', value: 'video/*' },
                uploadMode="instantly" // instantly, useButtons
                uploadUrl={mainURL + 'file/upload'}
                name="files"
                uploadCustomData={{
                  storageType,
                  companyID: testCompanyID,
                  bizCode: testBizCode,
                  file_ACL: testFile_ACL,
                  version: inputFileVer || '0.01',
                  verkey: inputFileVerKey2,
                  fileID: inputFileID1,
                }}
                onUploaded={e => {
                  const result = JSON.parse(e.request.response);
                  const FileKey = result.files[0].FileKey;
                  const FileID = result.FileID;
                  const newItem = { file: e.file, FileKey, FileID };
                  setUploadedFiles(prev => [...prev, newItem]);
                }}
              />
              <div style={{ display: uploadedFiles.length > 0 ? 'block' : 'none' }}>
                <div>
                  <h4>Uploaded Files : {uploadedFiles.length}</h4>
                  {uploadedFiles.map((item, i) => {
                    const { file, FileKey, FileID } = item;
                    const { name, type, size, lastModifiedDate } = file;
                    return (
                      <div key={i}>
                        <div>{`Name: ${name}`}</div>
                        <div>{`Size: ${size}`}</div>
                        <div>{`Type: ${type}`}</div>
                        <div>{`Last Modified Date: ${lastModifiedDate}`}</div>
                        <div>{`FileKey: ${FileKey}`}</div>
                        <div>{`FileID: ${FileID}`}</div>
                        <div>
                          <Button
                            text={'Download'}
                            onClick={() => {
                              readFile(FileKey);
                            }}
                          />
                          <Button
                            text={'Delete'}
                            onClick={async () => {
                              const result = await axios.post(`/file/delete`, { FileKey });
                              alert(JSON.stringify(result.data));
                            }}
                          />
                          <Button
                            text={'Tag_Update'}
                            onClick={async () => {
                              const result = await axios.post(`/file/tagging`, {
                                FileKeyArr: [FileKey],
                                companyID: testCompanyID,
                              });
                              alert(JSON.stringify(result.data));
                            }}
                          />
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
              <>
                <hr />
                <input
                  onChange={e => {
                    setInputFileKey3(e.target.value);
                  }}
                  type="text"
                  placeholder={`FileKey 입력`}
                  value={inputFileKey3}
                  style={{ width: '300px' }}
                />
                <button
                  onClick={async e => {
                    readFile(inputFileKey3);
                  }}
                >
                  파일 다운로드 테스트
                </button>
                <hr />
                <input
                  onChange={e => {
                    setInputFileKeyArr(e.target.value);
                  }}
                  type="text"
                  placeholder={`FileKeyArr 입력 (',' 로 구분, 여러개 입력 공란없이)`}
                  value={inputFileKeyArr}
                />
                <input
                  onChange={e => {
                    setInputCompanyID(e.target.value);
                  }}
                  type="text"
                  placeholder={`companyID 입력`}
                  value={inputCompanyID}
                />
                <button
                  onClick={async e => {
                    const result = await axios.post(`/file/tagging`, {
                      FileKeyArr: inputFileKeyArr.split(','),
                      companyID: inputCompanyID,
                    });
                    setApiResult1(JSON.stringify(result.data));
                  }}
                >
                  파일 Tag 업데이트 테스트 (S3 인 경우)
                </button>
                <div>{apiResult1}</div>
                <hr />
                <input
                  onChange={e => {
                    setInputFileKey2(e.target.value);
                  }}
                  type="text"
                  placeholder={`FileKey 입력`}
                  value={inputFileKey2}
                  style={{ width: '300px' }}
                />
                <button
                  onClick={async e => {
                    const result = await axios.post(`/file/delete`, {
                      FileKey: inputFileKey2,
                    });
                    setApiResult2(JSON.stringify(result.data));
                  }}
                >
                  파일 삭제 테스트
                </button>
                <div>{apiResult2}</div>
                <hr />
                <input
                  onChange={e => {
                    setInputFileID2(e.target.value);
                  }}
                  type="text"
                  placeholder={`FileID 입력`}
                  value={inputFileID2}
                  style={{ width: '300px' }}
                />
                <select
                  value={newestVer}
                  onChange={e => {
                    setNewestVer(e.target.value);
                  }}
                >
                  <option value={false}>전체</option>
                  <option value={true}>최신버전만</option>
                </select>
                <button
                  onClick={async e => {
                    const result = await axios.post(`/file/list`, {
                      FileID: inputFileID2,
                      NewestVer: JSON.parse(newestVer),
                    });
                    setApiResult3(JSON.stringify(result.data));
                  }}
                >
                  파일ID 리스트 조회
                </button>
                <div>{apiResult3}</div>
                <hr />
                <input
                  onChange={e => {
                    setInputFileKey1(e.target.value);
                  }}
                  type="text"
                  placeholder={`FileKey 입력`}
                  value={inputFileKey1}
                  style={{ width: '300px' }}
                />
                <button
                  onClick={async e => {
                    const result = await axios.post(`/file/info`, {
                      FileKey: inputFileKey1,
                    });
                    setApiResult4(JSON.stringify(result.data));
                  }}
                >
                  파일Key 정보 조회
                </button>
                <div>{apiResult4}</div>
                <hr />
                <input
                  onChange={e => {
                    setInputFileVerKey1(e.target.value);
                  }}
                  type="text"
                  placeholder={`FileVerKey 입력`}
                  value={inputFileVerKey1}
                  style={{ width: '300px' }}
                />
                <button
                  onClick={async e => {
                    const result = await axios.post(`/file/listver`, {
                      FileVerKey: inputFileVerKey1,
                    });
                    setApiResult5(JSON.stringify(result.data));
                  }}
                >
                  FileVerKey 정보 조회
                </button>
                <div>{apiResult5}</div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comp;
