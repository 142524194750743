import React, { useRef, useImperativeHandle, useState, useCallback, useEffect } from 'react';
import { isWithin3Months } from 'common/validators/validators';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { ticket } from 'api';

const EditForm = React.forwardRef((props, ref) => {
  const { setPopup, refresh, setLoadPanelVisible, selectMemberType } = props;
  const [formData, setFormData] = useState({});
  const [isUpdatePrice, setIsUpdatePrice] = useState(false);
  const [isUpdatePeriod, setIsUpdatePeriod] = useState(true);
  const [isUpdateRemark, setIsUpdateRemark] = useState(false);
  const [dateValue, setDateValue] = useState();
  const formRef = useRef({});

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    open,
  }));

  useEffect(() => {
    if (!isUpdatePeriod && !isUpdatePrice && !isUpdateRemark) {
      notify(
        {
          message: '수정할 항목을 체크해주세요.',
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  }, [isUpdatePeriod, isUpdatePrice, isUpdateRemark]);

  // 팝업 오픈
  const open = ticketID => {
    if (ticketID.length === 1) {
      (async () => {
        try {
          setLoadPanelVisible(true);
          const result = await ticket.searchSeasonTicketIssueHistoryByID({
            TicketID: ticketID[0],
          });
          setLoadPanelVisible(false);
          setFormData({
            ...result.data[0],
            IsUpdatePeriod: true,
            IsUpdatePrice: false,
            IsUpdateRemark: false,
            TicketID: ticketID,
          });
        } catch (e) {
          setLoadPanelVisible(false);
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      })();
    } else {
      (async () => {
        try {
          setLoadPanelVisible(true);
          const result = await ticket.searchSeasonTicketIssueHistoryByIDs({
            TicketID: ticketID,
          });
          setLoadPanelVisible(false);
          setFormData({
            ...result.data[0],
            VehicleRegistration: ticketID.length + '건 선택됨',
            IsUpdatePeriod: true,
            IsUpdatePrice: false,
            IsUpdateRemark: false,
            TicketID: ticketID,
          });
        } catch (e) {
          setLoadPanelVisible(false);
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      })();
    }
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        TicketID,
        IsUpdatePeriod,
        IsUpdatePrice,
        IsUpdateRemark,
        MemberType,
        SalesPrice,
        FromDate,
        ToDate,
        Remark,
      } = formRef.current.props.formData;
      const fromDate = dateFormat(FromDate);
      const toDate = dateFormat(ToDate);
      const isConfirmed = await isWithin3Months(fromDate, toDate);
      if (!isConfirmed) {
        return;
      }
      setLoadPanelVisible(true);
      const result = await ticket.updateSeasonTicketIssueHistoryMultiOption({
        TicketID,
        IsUpdatePeriod: IsUpdatePeriod ? 'Y' : 'N',
        IsUpdatePrice: IsUpdatePrice ? 'Y' : 'N',
        IsUpdateRemark: IsUpdateRemark ? 'Y' : 'N',
        MemberType,
        SalesPrice,
        FromDate: fromDate,
        ToDate: toDate,
        Remark,
      });
      if (result.isOk) {
        setLoadPanelVisible(false);
        notify(
          {
            message: '수정 되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        setPopup({ save: true, visible: false });
        refresh();
      } else {
        setLoadPanelVisible(false);
        notify(
          {
            message: JSON.parse(result.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
        refresh();
      }
    },
    [formData],
  );

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setIsUpdatePeriod(true);
    setIsUpdatePrice(true);
  };

  const handleDateFrom = e => {
    setDateValue(e.value);
  };

  const dateFormat = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onPeriodCheckValueChanged = e => {
    e.value ? setIsUpdatePeriod(true) : setIsUpdatePeriod(false);
  };
  const onPriceCheckValueChanged = e => {
    e.value ? setIsUpdatePrice(true) : setIsUpdatePrice(false);
  };
  const onRemarkCheckValueChanged = e => {
    e.value ? setIsUpdateRemark(true) : setIsUpdateRemark(false);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container season-ticket-edit-form">
          <Form
            id="form"
            ref={formRef}
            formData={formData}
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <GroupItem>
              <GroupItem colCount={4}>
                <SimpleItem
                  dataField="IsUpdatePeriod"
                  editorType="dxCheckBox"
                  colSpan={1}
                  editorOptions={{ value: isUpdatePeriod, onValueChanged: onPeriodCheckValueChanged }}
                >
                  <Label text="계약일 수정" location="right" />
                </SimpleItem>
                <SimpleItem
                  dataField="IsUpdatePrice"
                  editorType="dxCheckBox"
                  colSpan={1}
                  editorOptions={{ value: isUpdatePrice, onValueChanged: onPriceCheckValueChanged }}
                >
                  <Label text="금액 수정" location="right" />
                </SimpleItem>
                <SimpleItem
                  dataField="IsUpdateRemark"
                  editorType="dxCheckBox"
                  colSpan={1}
                  editorOptions={{ value: isUpdateRemark, onValueChanged: onRemarkCheckValueChanged }}
                >
                  <Label text="비고 수정" location="right" />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem dataField="VehicleRegistration" colSpan={2} editorOptions={{ readOnly: true }}>
                  <Label text="차량번호" />
                </SimpleItem>
                <Item
                  dataField="FromDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    type: 'date',
                    dropDownButtonTemplate: 'dropDownButton',
                    displayFormat: 'yyyy-MM-dd',
                    useMaskBehavior: true,
                    icon: true,
                    disabled: !isUpdatePeriod,
                    onValueChanged: handleDateFrom,
                  }}
                >
                  <Label text="계약시작일" />
                  {isUpdatePeriod && <RequiredRule message="필수값 입니다." />}
                </Item>
                <Item
                  dataField="ToDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    type: 'date',
                    useMaskBehavior: true,
                    displayFormat: 'yyyy-MM-dd',
                    min: dateValue,
                    disabled: !isUpdatePeriod,
                    dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                  }}
                >
                  <Label text="계약종료일" />
                  {isUpdatePeriod && <RequiredRule message="필수값 입니다." />}
                </Item>
                <SimpleItem
                  dataField="MemberType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: selectMemberType,
                    displayExpr: 'text',
                    valueExpr: 'code',
                    disabled: !isUpdatePrice,
                  }}
                >
                  <Label text="정기권 유형" />
                  {isUpdatePrice && <RequiredRule message="필수값 입니다." />}
                </SimpleItem>

                <Item
                  dataField="SalesPrice"
                  colSpan={1}
                  editorType="dxNumberBox"
                  // helpText="발행시에는 개인 기준금액 혹은 그룹에 설정된 금액으로 자동 적용 됩니다."
                  editorOptions={{
                    showSpinButtons: true,
                    step: 1000,
                    min: 0,
                    disabled: !isUpdatePrice,
                    format: '#,##0 원',
                  }}
                >
                  <Label text="결제금액" />
                  {isUpdatePrice && <RequiredRule message="필수값 입니다." />}
                </Item>
                <SimpleItem
                  dataField="Remark"
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{ disabled: !isUpdateRemark }}
                >
                  <Label text="비고" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'40%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'40%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
});

export default EditForm;
