import React, { useMemo, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Column, Selection } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import TerminalForm from './terminal-form';
import { parking } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './terminal-grid.scss';

const userId = localStorage.getItem('userId');

const TerminalGrid = props => {
  const terminalRef = useRef({});
  const [uniqueKey, setUniqueKey] = useState(null);
  const [isPopup, setPopup] = useState(false);
  const [deleteList, setDeleteList] = useState([]);

  useEffect(() => {
    const { key } = props.data;
    setUniqueKey(key);
  }, [props]);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'TerminalID',
      loadMode: 'raw',
      load: async () => {
        try {
          const { CostCenterCode, CardAgencyList, CardAgencyType } = props.data.data;
          const result = await axios.post(`master/parkinginfo/searchSalesAggregationTerminalInfo`, {
            CostCenter: CostCenterCode,
            CardAgencyType,
            CardAgencyList,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [uniqueKey]);

  const refresh = () => {
    terminalRef.current.instance.refresh();
    terminalRef.current.instance.clearSelection();
  };

  const onDelete = () => {
    const result = confirm('<i>삭제하시겠습니까?</i>', '삭제');
    result.then(dialogResult => {
      if (dialogResult) {
        const { CostCenterCode, CardAgencyList, CardAgencyType } = props.data.data;
        const del = deleteList.map(d => {
          return {
            CostCenter: CostCenterCode,
            CardAgencyType: CardAgencyType,
            CardAgencyList: CardAgencyList,
            TerminalID: d.TerminalID,
          };
        });

        parking.deleteTerminalInfo(del).then(res => {
          if (res.isOk) {
            refresh();
          }
        });
      }
    });
  };

  //체크박스 클릭
  const onSelectionChanged = ({ selectedRowsData }) => {
    setDeleteList(selectedRowsData);
  };

  const onClose = () => {
    setPopup(false);
  };

  return (
    <React.Fragment>
      <CommonDataGrid
        gridRef={terminalRef}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={false}
        headerAfter={[
          <Button icon="plus" text="등록" type="normal" stylingMode="contained" onClick={() => setPopup(true)} />,
          <Button icon="trash" text="삭제" type="normal" stylingMode="contained" onClick={onDelete} />,
        ]}
      >
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField="TerminalID" caption={'단말기 ID'} />
        <Column dataField="TerminalName" caption={'단말기 명'} />
      </CommonDataGrid>
      <Popup onHiding={onClose} visible={isPopup} showTitle={false} width="40%" height="35%">
        <div className={'dx-card responsive-paddings'}>
          <TerminalForm setPopup={setPopup} isPopup={isPopup} rowData={props.data.data} refresh={refresh} />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default TerminalGrid;
