import React, { useRef, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  PatternRule,
} from 'devextreme-react/form';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { salesPayment, parking, error } from 'api';
import 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';
import CostcenterMaster from 'common/popup/costcenter-master';
import CancelHistory from './cancelHistory';

const InputSalesCancel = () => {
  const formRef = useRef({});
  const cancelHistoryRef = useRef({});
  const costcenterMasterRef = useRef({});
  const [formData, setFormData] = useState({
    CostCenterCode: '',
    CostCenterName: '',
    SalesType: '',
    SalesKeyID: '',
    CardAgencyListName: '',
    IssueCreditCard: '',
    CreditCardApprovalNo: '',
    TransactionDate: '',
    SalesPrice: '',
    RefundPaymentType: '',
    CustomerName: '',
    TelephoneNo: '',
    VehicleRegistrationNo: '',
    RefundPrice: '',
    RefundReason: '',
    BankName: '',
    BankAccountNo: '',
    AccountHolder: '',
    CustomerInfo: '',
    Remark: '',
    RegisterUser: '',
  });

  const [isCash, setIsCash] = useState(true);
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);

  // select items
  const [SalesAggregation, setSalesAggregation] = useState([]);
  const [salesType, setSalesType] = useState([]);
  const [refundReason, setRefundReason] = useState([]);
  const [refundPaymentType, setRefundPaymentType] = useState([]);
  const [etcSalesType, setEtcSalesType] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  // 세부 매출
  const [isEtcSales, setIsEtcSales] = useState(false);

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        CostCenterCode,
        SalesType,
        KeyID,
        CardAgencyListName,
        IssueCreditCard,
        EtcSalesType,
        CreditCardApprovalNo,
        TransactionDate,
        SalesPrice,
        RefundPaymentType,
        CustomerName,
        TelephoneNo,
        VehicleRegistrationNo,
        RefundPrice,
        RefundReason,
        BankName,
        BankAccountNo,
        AccountHolder,
        CustomerInfo,
        Remark,
        RegisterUser,
      } = formData;
      setLoadPanelVisible(true);
      await salesPayment
        .insertRefundInfo2022({
          CostCenterCode,
          SalesType,
          EtcSalesType,
          SalesKeyID: KeyID,
          CardAgencyListName,
          IssueCreditCard,
          CreditCardApprovalNo,
          TransactionDate: moment(TransactionDate).format('YYYY-MM-DD'),
          SalesPrice,
          RefundPaymentType,
          CustomerName,
          TelephoneNo,
          VehicleRegistrationNo,
          RefundPrice,
          RefundReason,
          BankName: BankName.trim(),
          BankAccountNo: BankAccountNo.trim(),
          AccountHolder: AccountHolder.trim(),
          CustomerInfo,
          Remark,
          RegisterUser,
        })

        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '요청 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            onClose();
            cancelHistoryRef.current.instance.refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
          // onClose();
        });
    },
    [formData],
  );

  useEffect(() => {
    (async () => {
      const SalesType = [];
      const RefundPaymentType = [];
      const RefundReason = [];
      const EtcSalesType = [];
      const result = await parking.getParkingInfoSelectType({
        CodeGroup: ['SalesType', 'RefundPaymentType', 'RefundReason', 'EtcSalesType'],
      });
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(obj => {
          if (obj.CodeGroup === 'SalesType') {
            SalesType.push({
              id: obj.CodeDesc,
              value: obj.Code,
            });
          } else if (obj.CodeGroup === 'RefundPaymentType') {
            RefundPaymentType.push({
              id: obj.CodeDesc,
              value: obj.Code,
            });
          } else if (obj.CodeGroup === 'RefundReason') {
            RefundReason.push({
              id: obj.CodeDesc,
              value: obj.Code,
            });
          } else if (obj.CodeGroup === 'EtcSalesType') {
            EtcSalesType.push({
              id: obj.CodeDesc,
              value: obj.Code,
            });
          }
        });
        setSalesType(SalesType);
        setRefundPaymentType(RefundPaymentType);
        setRefundReason(RefundReason);
        setEtcSalesType(EtcSalesType);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      setFormData({
        ...formData,
        CostCenterCode: selectedCostCenter[0].CostCenterCode,
        CostCenterName: selectedCostCenter[0].CostCenterName,
      });
      (async () => {
        await parking
          .searchDistinctSalesAggregationByCostCenter({
            CostCenterCode: selectedCostCenter[0].CostCenterCode,
          })
          .then(res => {
            if (res.isOk) {
              const type = res.data.map(c => c.CardAgencyType);
              setSalesAggregation(type);
            }
          });
      })();
    }
  }, [selectedCostCenter]);

  const onClose = () => {
    formRef.current.instance.resetValues();
  };

  const onClickRefundPaymentType = e => {
    e.itemData.value === 'Cash' ? setIsCash(false) : setIsCash(true);
  };

  // 사업소 변경시 버튼을 통해서만 가능하도록
  const onFocusIn = e => {
    e.event.target.blur();
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const handleEtcTypeChanged = e => {
    if (e.value === 'EtcSales') {
      setIsEtcSales(true);
    } else {
      setIsEtcSales(false);
    }
  };

  return (
    <div id="sp-loading">
      <h2 className={'content-block detail-title'}>매출 취소 수기 등록</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <div style={{ display: 'flex', marginTop: '3px', gap: '30px' }}>
            <div className={'dx-card'} style={{ padding: '35px', maxWidth: '50%' }}>
              {/* 입력 - 페이지 */}
              <form onSubmit={onSubmit}>
                <div className="">
                  <Form
                    ref={formRef}
                    formData={formData}
                    labelMode="outside"
                    optionalMark="optional"
                    stylingMode="outlined"
                  >
                    <GroupItem caption={'매출 정보'} colCount={3}>
                      <SimpleItem
                        colSpan={1}
                        dataField="CostCenterName"
                        editorType="dxTextBox"
                        editorOptions={{
                          onFocusIn: onFocusIn,
                          buttons: [
                            {
                              name: 'trash',
                              location: 'after',
                              options: {
                                stylingMode: 'text',
                                text: '사업소 선택',
                                onClick: () => {
                                  costcenterMasterRef.current.open();
                                },
                              },
                            },
                          ],
                          value: formData.CostCenterName,
                        }}
                      >
                        <Label text="사업소명" />
                      </SimpleItem>
                      <SimpleItem
                        dataField="SalesType"
                        editorType="dxSelectBox"
                        editorOptions={{
                          items: salesType,
                          placeholder: '매출 유형을 선택해주세요',
                          valueExpr: 'value',
                          displayExpr: 'id',
                          onValueChanged: handleEtcTypeChanged,
                        }}
                      >
                        <Label text="매출 유형" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      {isEtcSales && (
                        <SimpleItem
                          dataField="EtcSalesType"
                          editorType="dxSelectBox"
                          editorOptions={{
                            items: etcSalesType,
                            placeholder: '세부 매출 유형을 선택해주세요',
                            valueExpr: 'value',
                            displayExpr: 'id',
                          }}
                        >
                          <Label text="세부 매출 유형" />
                          <RequiredRule message="필수값 입니다." />
                        </SimpleItem>
                      )}

                      {/* {formData.ApprovalDate || formData.ApprovedDate ? ( */}
                      <SimpleItem
                        dataField="TransactionDate"
                        editorType="dxDateBox"
                        editorOptions={{
                          type: 'date',
                          dropDownButtonTemplate: 'dropDownButton',
                          displayFormat: 'yyyy-MM-dd',
                          icon: true,
                          useMaskBehavior: true,
                          placeholder: '결제일자를 선택해주세요',
                        }}
                      >
                        <Label text="결제일자" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="SalesPrice" editorType="dxNumberBox" editorOptions={{ format: '#,##0' }}>
                        <Label text="결제금액" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem
                        dataField="CardAgencyListName"
                        editorType="dxSelectBox"
                        editorOptions={{ items: SalesAggregation, placeholder: '사업소 선택 후 선택해주세요' }}
                      >
                        <Label text="결제사" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="IssueCreditCard">
                        <Label text="카드사" />
                      </SimpleItem>
                      <SimpleItem dataField="CreditCardApprovalNo" editorOptions={{ maxLength: 10 }}>
                        <Label text="카드승인번호" />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem caption="환불 정보" colCount={3}>
                      <SimpleItem
                        dataField="RefundPaymentType"
                        editorType="dxSelectBox"
                        editorOptions={{
                          items: refundPaymentType,
                          placeholder: '환불 유형을 선택해주세요',
                          valueExpr: 'value',
                          displayExpr: 'id',
                          onItemClick: onClickRefundPaymentType,
                        }}
                      >
                        <Label text="환불수단" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="CustomerName">
                        <Label text="고객명" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="TelephoneNo">
                        <Label text="고객연락처" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="VehicleRegistrationNo">
                        <Label text="차량번호" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="RefundPrice" editorType="dxNumberBox" editorOptions={{ format: '#,##0' }}>
                        <Label text="환불금액" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem
                        dataField="RefundReason"
                        editorType="dxSelectBox"
                        editorOptions={{
                          items: refundReason,
                          placeholder: '환불 사유를 선택해주세요',
                          valueExpr: 'value',
                          displayExpr: 'id',
                        }}
                      >
                        <Label text="환불사유" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="Remark" colSpan={3}>
                        <Label text="비고" editorType="dxTextArea" />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem caption={'계좌 정보 (환불수단 "현금"선택시에만 입력가능)'} colCount={3}>
                      <SimpleItem dataField="BankName" editorOptions={{ disabled: isCash }}>
                        <PatternRule
                          pattern={/^[ㄱ-ㅎ가-힣a-zA-Z]+(\s+)?$/}
                          message={'공백/특수문자/숫자는 입력이 불가합니다'}
                        />
                        <Label text="은행명" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="BankAccountNo" editorOptions={{ disabled: isCash }}>
                        <PatternRule pattern={/^[\d-]+(\s+)?$/} message={'숫자와 - 기호만 입력 가능합니다.'} />
                        <Label text="입금계좌" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="AccountHolder" editorOptions={{ disabled: isCash }}>
                        <Label text="예금주" />
                        <RequiredRule message="필수값 입니다." />
                      </SimpleItem>
                      <SimpleItem dataField="CustomerInfo" editorOptions={{ disabled: isCash }}>
                        <Label text="고객과의 관계" />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={2}>
                      <ButtonItem>
                        <ButtonOptions
                          width={'60%'}
                          type={'default'}
                          useSubmitBehavior={true}
                          text={'저장'}
                        ></ButtonOptions>
                      </ButtonItem>
                      <ButtonItem horizontalAlignment="left">
                        <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
                      </ButtonItem>
                    </GroupItem>
                  </Form>
                </div>
              </form>
            </div>
            <div style={{ maxWidth: '50%' }}>
              <CancelHistory cancelHistoryRef={cancelHistoryRef} setLoadPanelVisible={setLoadPanelVisible} />
            </div>
          </div>
        </div>
      </div>
      <CostcenterMaster ref={costcenterMasterRef} setSelectedCostCenter={setSelectedCostCenter} />
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default InputSalesCancel;
