import React, { useCallback, useState, useRef, useImperativeHandle } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { activRadioSelect } from 'common/select/Types';
import { system, error } from 'api';

const AddUser = React.forwardRef((props, ref) => {
  const { setPopupVisibility, isUpdate, roleList, refresh, setLoadPanelVisible } = props;
  const [formData, setFormData] = useState({
    EmployeeName: '',
    EmployeeEnName: '',
    NickName: '',
    Password: '',
    Email: '',
    OfficeMobilePhoneNumber: '',
    IsExternal: 0,
    IsActive: 1,
    RoleCodeList: '',
  });
  const formRef = useRef({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  const open = (type, data) => {
    if (type === 'update') {
      const newObj = Object.assign({}, data);
      setFormData(newObj);
    } else {
      setFormData({
        EmployeeName: '',
        EmployeeEnName: '',
        NickName: '',
        Password: '',
        Email: '',
        OfficeMobilePhoneNumber: '',
        IsExternal: 0,
        IsActive: 1,
        RoleCodeList: '',
      });
    }
  };

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (isUpdate) {
        // 수정
        setLoadPanelVisible(true);
        const { Password, ...rest } = formData;
        let result;
        if (Password) {
          // 패스워드를 입력한 경우
          result = await system.updateUser({
            ...formData,
          });
        } else {
          result = await system.updateUser({
            ...rest,
          });
        }

        if (result.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '수정되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          onClose();
          refresh();
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: '오류가 발생했습니다. 관리자에게 문의하세요',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      } else {
        setLoadPanelVisible(true);
        await system
          .addUser({
            ...formData,
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '등록되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              //닫기 및 전체 그리드 리프레쉬
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '오류가 발생했습니다. 관리자에게 문의하세요',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },
    [formData, isUpdate],
  );

  const onClose = () => {
    setPopupVisibility(false);
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={isUpdate ? '유저 수정' : '신규 유저'}>
            {/* 현재 하이파킹만 이용하므로 차후 회사가 추가될 경우에 개발 */}
            <SimpleItem dataField="EmployeeName" editorType="dxTextBox">
              <Label text="이름(한글)" />
            </SimpleItem>
            <SimpleItem dataField="EmployeeEnName" editorType="dxTextBox">
              <Label text="이름(영문)" />
            </SimpleItem>
            <SimpleItem dataField="NickName" editorType="dxTextBox">
              <Label text="표기명" />
            </SimpleItem>
            <SimpleItem
              dataField="Password"
              editorType="dxTextBox"
              editorOptions={{
                placeholder: isUpdate ? '미 입력시 변경되지 않습니다.' : '',
                mode: 'password',
              }}
            >
              {!isUpdate && <RequiredRule message="필수값 입니다." />}
              <Label text="비밀번호" />
            </SimpleItem>
            <SimpleItem dataField="Email" editorType="dxTextBox">
              <RequiredRule message="필수값 입니다." />
              <Label text="이메일" />
            </SimpleItem>
            <SimpleItem dataField="OfficeMobilePhoneNumber" editorType="dxTextBox">
              <Label text="휴대폰 번호" />
            </SimpleItem>
            {/* <SimpleItem>
              <Label text="권한" location="top" />
            </SimpleItem> */}
            <SimpleItem
              dataField="RoleCodeList"
              editorType="dxTagBox"
              editorOptions={{
                showSelectionControls: true,
                selectAllMode: 'allPages',
                selectAllText: '전체',
                items: roleList,
                displayExpr: 'text',
                valueExpr: 'key',
                placeholder: '권한',
              }}
            >
              <RequiredRule message="필수값 입니다." />
              <Label text="권한" />
            </SimpleItem>
            <SimpleItem
              dataField="IsActive"
              editorType="dxRadioGroup"
              editorOptions={{
                items: activRadioSelect,
                valueExpr: 'value',
                displayExpr: 'label',
                layout: 'horizontal',
              }}
            >
              <Label text="사용여부" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default AddUser;
