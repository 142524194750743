import { confirm } from 'devextreme/ui/dialog';
import { aggregation, error } from 'api';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

export const identifierNoCheck = e => {
  const numberStr = e.value.replaceAll('-', '').slice(3);
  const isNumeric = n => !isNaN(n);
  const isAllZeros = str => str.trim().replaceAll('0', '') === '';

  if (isAllZeros(numberStr)) {
    e.rule.message = '자진발급번호는 010-000-1234로 발행해주세요.';
    return false;
  }

  if (!isNumeric(numberStr)) {
    e.rule.message = '숫자와 - 기호만 입력 가능합니다.';
    return false;
  }

  return true;
};

export const isWithin3Months = async (fromDate, toDate, text) => {
  const expiredFromDate = moment().subtract(3, 'month').format('YYYY-MM-DD');
  const expiredToDate = moment().add(3, 'month').format('YYYY-MM-DD');
  const message = text || '입력한 날짜는 최근 3개월을 벗어납니다.';
  if ((fromDate && fromDate < expiredFromDate) || (toDate && toDate > expiredToDate)) {
    const result = await confirm(`<i> ${message} <br />계속 진행하시겠습니까?</i>`, '확인');
    return result;
  }
  return true;
};

// 사용자 입력값 ASCII OR 유니코드 한글 OR 엔터키만 허용
// export const isASCIIorKorean = input => {
//   const regEx = /^[\x20-\x7E\uAC00-\uD7A3\u3131-\u3163\u119E-\u11A2\u11A8-\u11F9\n\r\u00a0]*$/;
//   return regEx.test(input);
// };

// ;&nbsp 공백 불허
export const validateInput = input => {
  //불허할 특수 문자를 뒤에 추가
  const regEx = /^[^\u00A0•]*$/;
  return regEx.test(input);
};

export const checkCashbillAPI = async (keyIDArr, invoker) => {
  try {
    const res = await aggregation.checkIfCashbillRegistered({ SalesKeyID: keyIDArr.join(';'), MenuName: invoker });
    const { data } = res;
    const confirmIDArr = getValidIDs(data, 'ConfirmID');
    const invoiceIDArr = getValidIDs(data, 'InvoiceID');
    if (!res.isOk) {
      notifyError(res.error.detail);
      return false;
    }
    if (invoiceIDArr.length > 0) {
      notifyAlert('이미 영수 세금계산서로 처리된 매출입니다.');
      return false;
    } else {
      return confirmIDArr;
    }
  } catch (error) {
    notifyError(error);
    return false;
  }
};

export const cancelCashbillAPI = async confirmIDArr => {
  const res = await aggregation.cancelCashbillByBillID({ ConfirmID: confirmIDArr.join(';') });
  if (res.isOk) {
    notifySuccess('발행된 현금영수증이 취소 처리 되었습니다.');
    return true;
  } else {
    notifyError(res.error.detail);
    return false;
  }
};

export const confirmCancellation = async confirmIDArr => {
  const confirmationMessage = `<i>이미 발행된 현금영수증이 ${confirmIDArr.length}건 있습니다. 취소 처리 후 발행 시도 하시겠습니까?(YES 클릭 시 현금영수증이 취소 접수되어 1시간 내 자동 신고됩니다.)</i>`;
  return confirm(confirmationMessage, '확인');
};

export const confirmManualRegistration = async () => {
  const confirmationMessage = `<i>동일한 금액의 세금계산서 발행 매출이 존재합니다. 수기 등록 하시겠습니까?</i>`;
  return confirm(confirmationMessage, '확인');
};

const getValidIDs = (data, key) => {
  return data[0].filter(item => item[key]).map(item => item[key]);
};

const notifyAlert = message => {
  alert(message);
};

const notifySuccess = message => {
  notify({ message, width: 230, position: { at: 'top', my: 'top', of: '#container' } }, 'success');
};

const notifyError = message => {
  notify({ message, width: 230, position: { at: 'top', my: 'top', of: '#container' } }, 'error');
};
