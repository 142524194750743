import React from 'react';
import PieChart, { Series, Label, Legend } from 'devextreme-react/pie-chart';
import '../chart.scss';

const Pie = () => {
  const waterLandRatio = [
    {
      name: 'Land',
      area: 0.29,
    },
    {
      name: 'Water',
      area: 0.71,
    },
  ];

  const countries = [
    {
      name: 'Russia',
      area: 0.12,
    },
    {
      name: 'Canada',
      area: 0.07,
    },
    {
      name: 'USA',
      area: 0.07,
    },
    {
      name: 'China',
      area: 0.07,
    },
    {
      name: 'Brazil',
      area: 0.06,
    },
    {
      name: 'Australia',
      area: 0.05,
    },
    {
      name: 'India',
      area: 0.02,
    },
    {
      name: 'Others',
      area: 0.55,
    },
  ];

  const pieCharts = [
    {
      title: 'Area of Countries',
      palette: 'Soft',
      dataSource: countries,
    },
    {
      title: 'Water/Land Ratio',
      palette: 'Soft Pastel',
      dataSource: waterLandRatio,
    },
  ];
  const pies = pieCharts.map((options, i) => (
    <PieChart
      className="pie"
      key={i}
      title={options.title}
      palette={options.palette}
      sizeGroup="piesGroup"
      dataSource={options.dataSource}
    >
      <Series argumentField="name" valueField="area">
        <Label visible={true} format="percent" />
      </Series>
      <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" rowCount={2} />
    </PieChart>
  ));

  return <div className="pies-container">{pies}</div>;
};

export default Pie;
