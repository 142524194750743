import React, { useEffect, useState, useRef } from 'react';
import { Column, Pager, Paging, Scrolling } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { salesPayment } from '../../../api';
import { Popup } from 'devextreme-react/popup';

import ScrollView from 'devextreme-react/scroll-view';
import CommonDataGrid from 'common/default-data-grid';
import CustomerMasterForm from './components/customer-master-form';

const CustomerCodeMasterPage = () => {
  const formRef = useRef({});
  const [isRefresh, setIsRefresh] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [popupVisible, setPopupVisibility] = useState({
    save: false,
    close: false,
  });
  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const gridRef = useRef({});

  useEffect(() => {
    (async () => {
      const result = await salesPayment.getCustomerMaster();
      if (result.isOk) {
        setDataSource(result.data);
      }
    })();
  }, [isRefresh]);

  const editCellRender = e => {
    return (
      <Button
        icon="edit"
        width={120}
        text="수정"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup(e.data)}
      />
    );
  };

  const togglePopup = data => {
    setPopupVisibility({ save: false, close: !popupVisible.close });
    formRef.current.open(data);
  };

  const onClose = () => {
    setPopupVisibility({ ...popupVisible, close: false });
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <h2 className={'content-block'}>사업자 목록 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            stylingMode="outlined"
            height={650}
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '사업자 목록 조회.xlsx',
            }}
            isHeader={true}
            headerAfter={[
              <Button
                icon="refresh"
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  setIsRefresh(!isRefresh);
                }}
              />,
            ]}
          >
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Scrolling showScrollbar="always" />
            <Column
              dataField={'edit'}
              alignment="left"
              width={'150'}
              cellRender={editCellRender}
              allowExporting={false}
            />
            <Column dataField={'CorpRegNumber'} caption={'사업자등록번호'} width={'120'} />
            <Column dataField={'CustomerCorpName'} caption={'회사명'} width={'230'} />
            <Column dataField={'NameOfRepresentative'} caption={'대표자 성명'} width={'120'} />
            <Column dataField={'City'} caption={'소재지'} width={'120'} />
            <Column dataField={'Street'} caption={'주소'} width={'250'} />
            <Column dataField={'CustomerManagerGroup'} caption={'담당부서'} width={'120'} />
            <Column dataField={'CustomerManagerName'} caption={'담당자'} width={'120'} />
            <Column dataField={'CustomerTelephoneNo'} caption={'담당자 전화번호'} width={'120'} />
            <Column dataField={'TaxInvoiceEmail1'} caption={'담당자 Email1'} width={'120'} />
            <Column dataField={'TelephoneNo'} caption={'대표전화번호'} width={'120'} />
            <Column dataField={'Email'} caption={'대표Email'} width={'120'} />
            <Column dataField={'TypeOfBusiness'} caption={'업종'} width={'200'} />
            <Column dataField={'TypeOfIndustry'} caption={'업태'} width={'200'} />
            <Column dataField={'TaxInvoiceEmail2'} caption={'담당자 Email2'} width={'120'} />
          </CommonDataGrid>
          <Popup onHiding={onClose} visible={popupVisible.close} showTitle={false} width="35%" height={480}>
            <ScrollView width="100%" height="100%">
              <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
                <CustomerMasterForm
                  ref={formRef}
                  setPopupVisibility={setPopupVisibility}
                  setLoadPanelVisible={setLoadPanelVisible}
                  setIsRefresh={setIsRefresh}
                  isRefresh={isRefresh}
                />
              </div>
            </ScrollView>
          </Popup>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default CustomerCodeMasterPage;
