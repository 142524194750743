import React from 'react';
import { Column, Selection, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import moment from 'moment';
import CommonDataGrid from 'common/default-data-grid';

const EtcSales = props => {
  const { setERowdata, etcSalesRef, dataSource } = props;
  const onSelectionChanged = e => {
    const result = etcSalesRef.current.instance.getSelectedRowsData();
    setERowdata(result);
  };

  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };
  return (
    <>
      <CommonDataGrid
        gridRef={etcSalesRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={500}
        onSelectionChanged={e => onSelectionChanged(e)}
        isHeader={true}
        isExcel={true}
        loadPanel={{ enabled: true }}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소요청 기타매출.xlsx',
        }}
        allowColumnReordering={true}
      >
        {/* <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" /> */}
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={150} />
        <Column dataField={'SalesDate'} caption={'매출일'} customizeText={customizeText} width={100} />
        <Column dataField={'PaymentDate'} caption={'결제일'} customizeText={customizeText} width={100} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} width={120} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={90} />
        <Column dataField={'EtcSalesTypeName'} caption={'고객유형'} width={90} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={90} />
        <Column dataField={'CustomerName'} caption={'고객명'} width={90} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={100} />
        <Column dataField={'PaymentCodeName'} caption={'결제유형'} width={90} />
        <Column dataField={'VehicleRegistrationNo'} caption={'시간권차량번호'} width={100} />
        <Column dataField={'Remark'} caption={'비고'} width={90} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} width={90} />
        <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} width={100} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={90} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={150} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={90} />
        <Column dataField={'CardAgencyListName'} caption={'결제사'} width={90} />
        <Column dataField={'FromDate'} caption={'시작일'} width={90} />
        <Column dataField={'ToDate'} caption={'종료일'} />
        <Summary>
          <TotalItem column="IssueCreditCard" summaryType="count" displayFormat="총 {0}건" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(EtcSales);
