import React, { useState, useCallback, useRef } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
  AsyncRule,
  ButtonItem,
  ButtonOptions,
} from 'devextreme-react/form';

import { parking } from 'api';

const TerminalForm = ({ setPopup, rowData, refresh }) => {
  const formRef = useRef({});
  const [formData, setFormData] = useState({
    TerminalID: '',
    TerminalName: '',
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { TerminalID, TerminalName } = formData;
      await parking
        .insertTerminalInfo({
          CostCenter: rowData.CostCenterCode,
          CardAgencyType: rowData.CardAgencyType,
          CardAgencyList: rowData.CardAgencyList,
          TerminalID,
          TerminalName,
          UserID: '',
        })
        .then((res) => {
          if (res.isOk) {
            setPopup(false);
            setFormData({
              TerminalID: '',
              TerminalName: '',
            });
          }
          refresh();
          formRef.current.instance.resetValues();
        });
    },
    [formData]
  );

  const asyncValidation = useCallback(async (formData) => {
    const { value } = formData;
    const { CostCenterCode, CardAgencyList, CardAgencyType } = rowData;
    const result = await parking.getTerminalInfo({
      CostCenter: CostCenterCode,
      CardAgencyType,
      CardAgencyList,
    });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          result.data.length === 0
            ? true
            : !result.data.some((res) => res.TerminalID === value)
        );
      }, 1000);
    });
  }, []);

  const onClose = (e) => {
    setPopup(false);
    formRef.current.instance.resetValues();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions
              width={'3%'}
              icon="close"
              onClick={onClose}
            ></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="단말기 등록">
            <SimpleItem dataField="TerminalID">
              <Label text="단말기 ID" />
              <RequiredRule message="필수값 입니다." />
              <AsyncRule
                message="단말기 ID가 중복되었습니다."
                validationCallback={asyncValidation}
              />
            </SimpleItem>
            <SimpleItem dataField="TerminalName">
              <Label text="단말기 명" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions
                width={'40%'}
                type={'default'}
                useSubmitBehavior={true}
                text="저장"
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions
                width={'40%'}
                type={'normal'}
                text="닫기"
                onClick={onClose}
              ></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
};

export default TerminalForm;
