import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';

/*
일반권/ 정기권/ 기타 항목에대해서는 공통으로 가져가고 있었으나 관리 항목이 많아져 
'영수 세금계산서'페이지는 따로 관리하도록 세페이지 추가
*/

const SeasonTicket = props => {
  const { dataSource, setSRowdata, seasonTicketRef } = props;
  const [selectSum, setSelectSum] = useState(0);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    setSRowdata(rowdata);

    // 합계
    const sum = rowdata.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  return (
    <>
      <CommonDataGrid
        gridRef={seasonTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={595}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '정기권.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
        <Column dataField={'PaymentDate'} caption={'수금일'} customizeText={customizeText} width={'100'} />
        <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
        <Column dataField={'CashBillID'} caption={'현금영수증ID'} width={90} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={'100'} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={'140'} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={'100'} />
        <Column dataField={'FromDate'} caption={'시작일'} width={'100'} />
        <Column dataField={'ToDate'} caption={'종료일'} width={'100'} />
        <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={'100'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} width={'100'} />
        <Column dataField={'MemberName'} caption={'고객명'} width={'100'} />
        <Column dataField={'ApprovedDate'} caption={'승인시간'} customizeText={customizeText} width={'100'} />
        <Column dataField={'Remark'} caption={'비고'} width={'100'} />
        <Summary>
          <TotalItem showInColumn="CostCenterName" displayFormat="선택 합계:" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="CostCenterName" />
          <TotalItem column="SalesPrice" showInColumn="ApprovedDate" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem
            column="SalesPrice"
            showInColumn="ApprovedDate"
            summaryType="sum"
            valueFormat="#,##0 원"
            displayFormat="{0}"
          />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(SeasonTicket);
