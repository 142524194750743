import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const CMSInterestData = props => {
  const { dataSource } = props;

  const gridRef = useRef({});
  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  useEffect(() => {}, []);
  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        allowSelectAll={true}
        height={580}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'SAP연동 CMS 이자수익.xlsx',
        }}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" /> <HeaderFilter visible={true} />
      </CommonDataGrid>
    </>
  );
};

export default React.memo(CMSInterestData);
