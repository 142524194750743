import React, { useCallback, useState, useRef, useImperativeHandle, useEffect } from 'react';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  NumericRule,
  StringLengthRule,
  EmptyItem,
  EmailRule,
} from 'devextreme-react/form';

import { Column, CustomRule, Editing } from 'devextreme-react/data-grid';
import { parking, salesPayment, error } from 'api';
import { Button } from 'devextreme-react/button';
import { validateInput } from 'common/validators/validators';

import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

const DetailHistoryToCancel = React.forwardRef((props, ref) => {
  const { setIsOpen, setCancelInvoiceInfo, formData, dataSource } = props;
  const formRef = useRef({});
  const gridRef = useRef({});
  // 라디오 버튼
  const [taxType, setTaxType] = useState([]);
  const [sapChangeCode, setSapChangeCode] = useState([]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  useEffect(() => {
    if (taxType.length === 0) {
      api();
    }
  }, [taxType]);

  const open = () => {};

  const onClose = () => {
    setIsOpen(false);
  };

  const api = useCallback(async () => {
    const taxType = [];
    const sapChangeCode = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['TaxType', 'SapTaxInvoiceChangeCode'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'TaxType') {
          taxType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxType',
          });
        } else if (obj.CodeGroup === 'SapTaxInvoiceChangeCode') {
          sapChangeCode.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxType',
          });
        }
      });
      setTaxType(taxType);
      setSapChangeCode(sapChangeCode);
    }
  }, []);

  const handleNotify = message => {
    notify(
      {
        message: message,
        width: 230,
        position: {
          at: 'top',
          my: 'top',
          of: '#container',
        },
      },
      'error',
    );
  };

  // 제출시 api 호출하지 않고 최종 데이터만 전달
  const onSubmit = useCallback(() => {
    gridRef.current.instance.saveEditData();
    var {
      TaxInvoiceType,
      TaxIssueType,
      TaxType,
      ChangeCode,
      SalesKeyIDList,
      CostCenterCode,
      CustomerCode,
      CustomerCorpRegNumber,
      CustomerManagerGroup,
      CustomerManagerName,
      CustomerEmail1,
      CustomerEmail2,
      CustomerTelephoneNo,
      SalesPrice,
      UnitPrice,
      TaxPrice,
      SalesDate,
      Remark,
      LoginID,
      DerivedCorpRegNumber,
      InvoiceID,
    } = formRef.current.props.formData;
    var submitData = {
      TaxInvoiceType,
      TaxIssueType,
      TaxType,
      ChangeCode,
      SalesKeyIDList,
      CostCenterCode,
      CustomerCode,
      CustomerCorpRegNumber,
      CustomerManagerGroup,
      CustomerManagerName,
      CustomerEmail1,
      CustomerEmail2,
      CustomerTelephoneNo,
      SalesPrice,
      UnitPrice,
      TaxPrice,
      SalesDate,
      Remark,
      LoginID,
      DerivedCorpRegNumber,
      InvoiceID,
      ItemListJson: JSON.stringify(dataSource),
      // RegisterUser: '',
      IsTemp: 'Y',
    };
    const isValidRemark = validateInput(Remark);
    const isValidDetailRemark = dataSource.every(a => validateInput(a.Remark));
    const isValid = isValidRemark && isValidDetailRemark;
    if (!isValid) {
      return handleNotify('적요/비고 값에 인식할 수 없는 문자가 포함되어 있습니다.');
    }
    if (
      !CustomerTelephoneNo ||
      !CustomerEmail1 ||
      !SalesDate ||
      CustomerTelephoneNo.includes(' ') ||
      CustomerEmail1.includes(' ')
    ) {
      return handleNotify('필수 입력값을 확인해주세요');
    }

    const isValidNo = validatePhoneNo(CustomerTelephoneNo);
    if (!isValidNo) {
      return handleNotify('전화번호 형식을 확인해주세요');
    }

    const isValidEmail = validateEmail(CustomerEmail1);
    if (!isValidEmail) {
      return handleNotify('이메일 형식을 확인해주세요');
    }

    setCancelInvoiceInfo(submitData);
    setIsOpen(false);
  }, [dataSource]);

  const onFocusIn = e => {
    e.event.target.blur();
  };

  const onContentReady = e => {
    const button = e.element.getElementsByClassName('dx-button-content')[0];
    if (formData.SapStatus) {
      if (formData.SapStatus === 'BC') {
        button.id = 'taxInvoice-status-green';
      } else if (formData.SapStatus === 'W') {
        button.id = 'taxInvoice-status-gray';
      } else if (formData.SapStatus === 'NC') {
        button.id = 'taxInvoice-status-red';
      } else if (formData.SapStatus === 'Y') {
        button.id = 'taxInvoice-status-blue';
      } else if (formData.SapStatus === 'AC') {
        button.id = 'taxInvoice-status-blue';
      } else if (formData.SapStatus === 'N') {
        button.id = 'taxInvoice-status-red';
      } else if (formData.SapStatus === 'C') {
        button.id = 'taxInvoice-status-purple';
      }
    }
  };

  const phonenumberCheck = ({ value }) => {
    let numberStr = value.replaceAll('-', '').replaceAll(',', '');
    const isNumeric = n => !isNaN(n);
    const result = isNumeric(numberStr);
    if (result) return true;
  };

  const validatePhoneNo = phoneNo => {
    let numberStr = phoneNo.replaceAll('-', '').replaceAll(',', '');
    const isNumeric = n => !isNaN(n);
    const result = isNumeric(numberStr);
    if (result) return true;
  };

  const validateEmail = email => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(227, 227, 227, 1)',
          paddingTop: '10px',
          paddingBottom: '5px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <div style={{ flex: '1', fontSize: '16px' }}>세금계산서 전체 취소</div>
        <Button width={'3%'} icon="close" onClick={onClose} style={{ marginBottom: '10px' }}></Button>
      </div>
      <form className={`tax-detail-form`}>
        <Form
          ref={formRef}
          className={'add-tax-invoice detail-history'}
          formData={formData}
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
          labelLocation="left"
        >
          <GroupItem colCount={2}>
            <GroupItem>
              <SimpleItem dataField="TaxInvoiceNo" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                <Label text="국세청 승인번호" />
              </SimpleItem>
              <SimpleItem dataField="SAPInvoiceNo" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                <Label text="SAP 전표번호" />
              </SimpleItem>
              <EmptyItem dataField="SapStatus" editorType="dxTextBox" visible={false}></EmptyItem>
              <SimpleItem
                dataField="ChangeCode"
                editorType="dxSelectBox"
                visible={formData.SapStatus === 'Y' || formData.SapStatus === 'AC' || formData.TaxInvoiceType === 'B'}
                editorOptions={{
                  items: sapChangeCode,
                  valueExpr: 'value',
                  displayExpr: 'label',
                  readOnly: true,
                  placeholder: '국세청 승인번호가 있을때만 선택가능합니다.',
                }}
              >
                <Label text="수정 코드" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxInvoiceType"
                editorType="dxTextBox"
                visible={false}
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행 유형 코드" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxInvoiceTypeName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행 유형" />
              </SimpleItem>
              <SimpleItem
                dataField="CostCenterName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="사업소" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxIssueType"
                editorType="dxTextBox"
                visible={false}
                editorOptions={{
                  readOnly: true,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="TaxIssueTypeName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행유형" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxType"
                editorType="dxRadioGroup"
                editorOptions={{
                  items: taxType,
                  valueExpr: 'value',
                  displayExpr: 'label',
                  layout: 'horizontal',
                  readOnly: formData.TaxInvoiceType !== 'B',
                }}
              >
                <Label text="과세 유형" />
              </SimpleItem>
              <SimpleItem
                dataField="UnitPrice"
                editorType="dxNumberBox"
                editorOptions={{
                  min: 0,
                  format: '#,##0',
                  readOnly: formData.TaxInvoiceType !== 'B',
                }}
              >
                <Label text="공급가액" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxPrice"
                editorType="dxNumberBox"
                editorOptions={{
                  min: 0,
                  format: '#,##0',
                  readOnly: formData.TaxInvoiceType !== 'B',
                }}
              >
                <Label text="부가세" />
              </SimpleItem>
              <SimpleItem
                dataField="SalesPrice"
                editorType="dxNumberBox"
                editorOptions={{
                  min: 0,
                  format: '#,##0',
                  readOnly: true,
                }}
              >
                <Label text="계산서 금액" />
              </SimpleItem>
              <SimpleItem
                dataField="SalesDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  readOnly: true,
                }}
              >
                <RequiredRule message="필수 값입니다." />
                <Label text="발행요청일" />
              </SimpleItem>
              <SimpleItem dataField="Remark" editorType="dxTextArea">
                <Label text="적요" />
              </SimpleItem>
            </GroupItem>

            <GroupItem>
              <SimpleItem
                dataField="LastUpdateDate"
                editorType="dxTextBox"
                editorOptions={{
                  onFocusIn: onFocusIn,
                  buttons: [
                    {
                      name: 'trash',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        text: formData.TaxInvoiceStatusName,
                        onContentReady: onContentReady,
                      },
                    },
                  ],
                  value: moment(formData.LastUpdateDate).format('YYYY-MM-DD HH:mm:ss'),
                }}
              >
                <Label text="최근 수정일" />
              </SimpleItem>

              <SimpleItem
                dataField="RegisterUser"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행인" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerCorpRegNumber"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="사업자등록번호" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="회사명" />
              </SimpleItem>
              <SimpleItem
                dataField="NameOfRepresentative"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="매입자 대표자 성명" />
              </SimpleItem>
              <SimpleItem dataField="CustomerManagerGroup" editorType="dxTextBox">
                {/* <RequiredRule message="필수 값입니다." /> */}
                <Label text="담당부서" />
              </SimpleItem>
              <SimpleItem dataField="CustomerManagerName" editorType="dxTextBox">
                {/* <RequiredRule message="필수 값입니다." /> */}
                <Label text="담당자" />
              </SimpleItem>
              <SimpleItem dataField="CustomerEmail1" editorType="dxTextBox">
                <EmailRule message="메일 형식이 올바르지 않습니다." />
                <RequiredRule message="필수 값입니다." />
                <Label text="Email" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerEmail2"
                editorType="dxTextBox"
                editorOptions={{
                  name: 'CustomerEmail2',
                  valueExpr: 'value',
                  // onValueChanged: onValueChanged,
                }}
              >
                <EmailRule message="메일 형식이 올바르지 않습니다." />
                <Label text="Email2" />
              </SimpleItem>
              <SimpleItem dataField="CustomerTelephoneNo" editorType="dxTextBox">
                <RequiredRule message="필수 값입니다." />
                <CustomRule message={'숫자만 입력 가능합니다.'} validationCallback={phonenumberCheck} />
                <Label text="전화번호" />
              </SimpleItem>
              <SimpleItem
                dataField="DerivedCorpRegNumber"
                editorType="dxTextBox"
                editorOptions={{
                  maxLength: 4,
                }}
              >
                <Label text="종사업장" />
                <NumericRule message="숫자만 입력가능합니다." />
                <StringLengthRule max={4} message="종사업장은 4글자를 초과할 수 없습니다." />
              </SimpleItem>
            </GroupItem>
            <GroupItem colSpan={2}>
              <CommonDataGrid
                gridRef={gridRef}
                dataSource={dataSource}
                showBorders={true}
                allowColumnResizing={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={150}
                isHeader={true}
                isExcel={false}
                isNotFilterRow
                headerBefore={[<span> 매출 내역</span>]}
              >
                {formData && formData.SapStatus !== 'C' && (
                  <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={true} />
                )}
                <Column
                  dataField={'CostCenterName'}
                  caption={'사업소명'}
                  width={120}
                  alignment={'center'}
                  allowEditing={false}
                />
                <Column
                  dataField={'HiParkingID'}
                  caption={'P코드'}
                  width={'90'}
                  alignment={'center'}
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField={'SalesTypeName'}
                  caption={'매출유형'}
                  width={80}
                  alignment={'center'}
                  allowEditing={false}
                />
                <Column
                  dataField={'DetailSalesTypeName'}
                  caption={'상세매출유형'}
                  width={80}
                  alignment={'center'}
                  allowEditing={false}
                />
                  <Column
                    dataField={'ItemName'}
                    caption={'항목명'}
                    width={120}
                    alignment={'center'}
                    allowEditing={false}
                    visible={true}
                  />
                <Column dataField={'Cnt'} caption={'수량'} width={80} alignment={'center'} />
                <Column
                  dataField={'SalesUnitPrice'}
                  caption={'단가'}
                  width={100}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={formData.TaxInvoiceType === 'B'}
                />
                <Column
                  dataField={'UnitPrice'}
                  caption={'공급가액'}
                  width={100}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={formData.TaxInvoiceType === 'B'}
                />
                <Column
                  dataField={'TaxPrice'}
                  caption={'세액'}
                  width={80}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={formData.TaxInvoiceType === 'B'}
                />
                <Column
                  dataField={'SalesPrice'}
                  caption={'합계금액'}
                  width={100}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={false}
                />
                <Column dataField={'Remark'} width={200} caption={'비고'} alignment={'left'} />
              </CommonDataGrid>
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={1} alignment="right">
            <ButtonItem horizontalAlignment="right">
              <ButtonOptions width={'10%'} type={'default'} onClick={onSubmit} text={'저장'}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </React.Fragment>
  );
});

export default React.memo(DetailHistoryToCancel);
