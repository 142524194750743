import React, { useRef, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { TagBox } from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { common } from 'api';
import DateBox from 'devextreme-react/date-box';
import './sales-report.scss';

const userId = localStorage.getItem('userId');

const SalesReportPage = () => {
  const gridRef = useRef({});
  //검색
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  // 조회할 사업소 목록 array
  const [costcenterCode, setCostCenterCode] = useState([]);
  const [costCenterType, setCostCenterType] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [detailCheck, setDetailCheck] = useState('N');
  const [byPaymentDate, setPaymentDateCheck] = useState('N');

  useEffect(() => {
    (async () => {
      const result = await common.costcenterDataAll();
      if (result.isOk) {
        console.log(result.data);
        setCostCenterType(result.data);
      }
    })();
  }, []);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      // key: 'HiParkingID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/history/getSalesAndAccountReportDetail`, {
            ...dateValue,
            CostCenterCode: costcenterCode.join(','),
            LoginID: userId,
            isDetail: detailCheck,
            byPaymentDate: byPaymentDate,
          });
          return result.data[0];
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchButton]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onClickButton = e => {
    const result = [...costcenterCode];
    const add = e.addedItems.map(a => a.CostCenterCode);
    const remove = e.removedItems.map(a => a.CostCenterCode);
    if (add.length > 0) {
      result.push(...add);
      setCostCenterCode(result);
    }
    if (remove.length > 0) {
      const newArr = result.filter(a => !remove.includes(a));
      setCostCenterCode(newArr);
    }
  };
  const onDetailCheckValueChanged = e => {
    e.value ? setDetailCheck('Y') : setDetailCheck('N');
  };

  const onPaymentDateCheckValueChanged = e => {
    e.value ? setPaymentDateCheck('Y') : setPaymentDateCheck('N');
  };

  const onSearch = () => {
    setSearchButton(!searchButton);
  };

  return (
    <div>
      <h2 className={'content-block detail-title'}>사업소 확정 매출 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 확정 매출 조회 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">매출일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TagBox
                dataSource={costCenterType}
                showSelectionControls={false}
                columnAutoWidth={true}
                allowColumnResizing={true}
                maxDisplayedTags={2}
                displayExpr="CostCenterName"
                valueExpr="CostCenterCode"
                placeholder="조회할 사업소를 모두 선택해주세요"
                stylingMode="outlined"
                onSelectionChanged={onClickButton}
                searchEnabled={true}
                width={330}
                selectAllText={'전체'}
                multiline={false}
              />,
            ]}
            headerAfter={[
              <CheckBox defaultValue={false} text="일반권 상세    " onValueChanged={onDetailCheckValueChanged} />,
              <CheckBox defaultValue={false} text="수금일 기준 조회   " onValueChanged={onPaymentDateCheckValueChanged} />,

              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} />
            <Column dataField={'SalesDate'} caption={'매출일'} alignment={'center'} />
            <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'ApprovalType'} caption={'정상/반품'} alignment={'center'} />
            <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
            <Column dataField={'SalesCnt'} caption={'매출수량'} alignment={'right'} />
            <Column dataField={'SalesPrice'} caption={'합계금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />

            <Column dataField={'FromDate'} caption={'매출대상기간From'} alignment={'center'} />
            <Column dataField={'ToDate'} caption={'매출대상기간To'} alignment={'center'} />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'VehicleRegistrationNo'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'CustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'CorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'CustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              <Column dataField={'TypeOfBusiness'} caption={'업종'} alignment={'center'} />
              <Column dataField={'TypeOfIndustry'} caption={'업태'} alignment={'center'} />
            </Column>
            <Column caption={'세금계산서 정보'} alignment={'center'}>
              <Column dataField={'TaxIssueTypeName'} caption={'계산서유형'} alignment={'center'} />
              <Column dataField={'TaxSaleDate'} caption={'발행요청일'} alignment={'center'} />
              <Column dataField={'TaxTypeName'} caption={'과세유형'} alignment={'center'} />
              <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
              <Column dataField={'InvoiceID'} caption={'HiDMS 계산서번호'} alignment={'center'} />
              <Column dataField={'TaxInvoiceNo'} caption={'세금계산서번호'} alignment={'center'} />
              <Column dataField={'TaxCustomerNo'} caption={'계산서 SAP사업자코드'} alignment={'left'} />
              <Column dataField={'TaxCorpRegNumber'} caption={'계산서 사업자번호'} alignment={'left'} />
              <Column dataField={'TaxCustomerCorpName'} caption={'계산서 사업자명'} alignment={'left'} />
              <Column dataField={'TaxTypeOfBusiness'} caption={'계산서 업종'} alignment={'center'} />
              <Column dataField={'TaxTypeOfIndustry'} caption={'계산서 업태'} alignment={'center'} />
            </Column>

            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}

            <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} />
            <Column dataField={'Remark'} caption={'비고'} alignment={'center'} />
            <Summary>
              <TotalItem column="SalesCnt" summaryType="sum" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default SalesReportPage;
