import React, { useRef, useState, useCallback, useEffect } from 'react';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { parking, salesPayment, error } from 'api';
import { cancelSalesConfirmManualLink } from 'constants/hyperlink';
import notify from 'devextreme/ui/notify';
import ScrollView from 'devextreme-react/scroll-view';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import CancelEditForm from './component/cancel-edit-form';
import CancelConfirmTicket from './component/cancel-confirm-ticket';
import RemarkPopup from 'common/popup/remark-popup';
import './cancel-sales-confirm.scss';

const CancelSalesConfirmPage = () => {
  const cancelTicketRef = useRef({});
  const confirmPopupRef = useRef({});
  const rejectPopupRef = useRef({});
  const reviewPopupRef = useRef({});

  const [cRowdata, setCRowdata] = useState([]);
  const [editRowdata, setEditRowdata] = useState([]);

  const [refundReason, setRefundReason] = useState([]);
  const [refundPaymentType, setRefundPaymentType] = useState([]);

  const [searchCenter, setSearchCenter] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');
  const [searchVehicleNumber, setSearchVehicleNumber] = useState('');
  const [searchCustomerName, setSearchCustomerName] = useState('');
  const [searchCustomerInfo, setSearchCustomerInfo] = useState('');

  const [popup, setPopup] = useState({ save: false, visible: false });
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: '',
    ToDate: '',
    SearchCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCustomerName: '',
    SearchCustomerInfo: '',
  });

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    api();
  }, []);

  const api = useCallback(async () => {
    const RefundPaymentType = [];
    const RefundReason = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['RefundPaymentType', 'RefundReason'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'RefundPaymentType') {
          RefundPaymentType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'RefundReason') {
          RefundReason.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
      setRefundPaymentType(RefundPaymentType);
      setRefundReason(RefundReason);
    }
  }, []);
  const onClose = () => {
    setPopup({ ...popup, visible: false });
    clearSelection();
  };
  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  const onChangeApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };
  const onChangeVehicleNumber = e => {
    setSearchVehicleNumber(e.value);
  };
  const onChangeCustomerName = e => {
    setSearchCustomerName(e.value);
  };
  const onChangeCustomerInfo = e => {
    setSearchCustomerInfo(e.value);
  };
  const clearSelection = () => {
    if (cancelTicketRef.current.instance) cancelTicketRef.current.instance.clearSelection();
  };
  const clearHeaderFilter = () => {
    if (cancelTicketRef.current.instance) cancelTicketRef.current.instance.clearFilter();
  };
  const handleConfirm = () => {
    if (cRowdata.length === 0) {
      return alert('매출을 선택해주세요');
    } else {
      confirmPopupRef.current.open();
    }
  };

  const onSearch = () => {
    callAPI();
  };
  const refresh = () => {
    callAPI();
  };
  const onEnterKey = () => {
    callAPI();
  };

  const callAPI = () => {
    setSearchData({
      ...searchData,
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      SearchCenter: searchCenter.trim(),
      SearchApprovalNo: searchApprovalNo.trim(),
      SearchVehicleNumber: searchVehicleNumber.trim(),
      SearchCustomerName: searchCustomerName.trim(),
      SearchCustomerInfo: searchCustomerInfo.trim(),
    });
    clearSelection();
  };

  const parseCustomMsg = errorMsg => {
    // const successCnt = errorMsg.match(/성공:\s*(\d+)건/)[1];
    // const targetCnt = errorMsg.match(/대상:\s*(\d+)건/)[1];
    // const midRecallCnt = errorMsg.match(/중도회수:\s*(\d+)건/)[1];
    const errorIdx = errorMsg.match(/에러발생 Idx (.*?):에러메세지/)[1];
    const orgErrorMsg = errorMsg.split('에러메세지 :')[1];
    // 하위 sp에서 에러 발생 (실행 중간 에러이므로 무조건 fullMessage)
    if (errorIdx !== ' ') {
      return { type: 'fullMessage', detail: errorMsg };
    } else {
      return { type: 'simpleMessage', detail: orgErrorMsg };
    }
  };

  const confirmPopupSave = (remark, cRowdata, ConfirmDate) => {
    const confirmStatus = [];
    const RefundID = [];
    cRowdata.forEach(r => {
      confirmStatus.push(r.IsEditable);
      RefundID.push(r.Idx);
    });
    setLoadPanelVisible(true);
    salesPayment
      .confirmRefundInfo({
        RefundID: RefundID,
        ConfirmYN: 'Y',
        ApprovalComment: remark,
        ConfirmDate,
      })
      .then(res => {
        if (res.isOk && res.data) {
          const { SuccessCnt, FailCnt } = res.data[0][0];
          FailCnt !== 0 && alert('성공: ' + SuccessCnt + '건 ' + '실패: ' + FailCnt + '건 ');
          setLoadPanelVisible(false);
          notify(
            {
              message: '확정이 완료되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          refresh();
        } else {
          /*
          SERVER 실패 CASE
          1. 상위 SP - 확정하려는 상태에 이미 확정/반려가 포함되어 있을 때>> noti
          2. 상위 SP - 확정하려는 상태에 검토중/대기중이 하나도 없을 때(요청회수만 존재) >> noti
          3. 하위 SP - cur 반복문을 실행하다가 실패했을 때 (ex. 805 에러입니다.) >> alert
          */
          setLoadPanelVisible(false);
          const jsonObject = JSON.parse(res.error.detail);
          const errorMsg = jsonObject.sqlMessage;
          const isCustomFormat = errorMsg.includes('성공');
          if (isCustomFormat) {
            const res = parseCustomMsg(errorMsg);
            res.type === 'fullMessage' && alert(res.detail) && refresh();
            res.type === 'simpleMessage' &&
              notify(
                {
                  message: res.detail,
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
          }
          // 그 외 에러
          else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        }
      });
  };
  const rejectPopupSave = useCallback(
    async (remark, cRowdata) => {
      const confirmStatus = [];
      const RefundID = [];
      cRowdata.forEach(r => {
        confirmStatus.push(r.IsEditable);
        RefundID.push(r.Idx);
      });
      setLoadPanelVisible(true);
      await salesPayment
        .confirmRefundInfo({
          RefundID: RefundID,
          ConfirmYN: 'N',
          ApprovalComment: remark,
        })
        .then(res => {
          if (res.isOk && res.data) {
            const { SuccessCnt, FailCnt } = res.data[0][0];
            FailCnt !== 0 && alert('성공: ' + SuccessCnt + '건 ' + '실패: ' + FailCnt + '건 ');
            setLoadPanelVisible(false);
            notify(
              {
                message: '반려 처리되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            refresh();
          } else {
            /*
          SERVER 실패 CASE
          1. 상위 SP - 확정하려는 상태에 이미 확정/반려가 포함되어 있을 때>> noti
          2. 상위 SP - 확정하려는 상태에 검토중/대기중이 하나도 없을 때(요청회수만 존재) >> noti
          3. 하위 SP - cur 반복문을 실행하다가 실패했을 때 (ex. 805 에러입니다.) >> alert
          */
            setLoadPanelVisible(false);
            const jsonObject = JSON.parse(res.error.detail);
            const errorMsg = jsonObject.sqlMessage;
            const isCustomFormat = errorMsg.includes('성공');
            if (isCustomFormat) {
              const res = parseCustomMsg(errorMsg);
              res.type === 'fullMessage' && alert(res.detail) && refresh();
              res.type === 'simpleMessage' &&
                notify(
                  {
                    message: res.detail,
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
            }
            // 그 외 에러
            else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          }
        });
    },
    [cRowdata],
  );

  const reviewPopupSave = useCallback(
    async (remark, cRowdata) => {
      const confirmStatus = [];
      const RefundID = [];
      cRowdata.forEach(r => {
        confirmStatus.push(r.IsEditable);
        RefundID.push(r.Idx);
      });
      setLoadPanelVisible(true);
      await salesPayment
        .reviewRefundInfo({
          RefundIDList: RefundID.join(';'),
          ConfirmYN: 'N',
          ApprovalComment: remark,
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '취소 진행 처리 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [cRowdata],
  );

  const handleReject = () => {
    if (cRowdata.length === 0) {
      return alert('매출을 선택해주세요');
    } else {
      rejectPopupRef.current.open();
    }
  };

  const handleReview = () => {
    if (cRowdata.length === 0) {
      return alert('매출을 선택해주세요');
    } else {
      reviewPopupRef.current.open();
    }
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };
  return (
    <>
      <div className="content-block detail-title left-right-header">
        <h2> 매출 취소 확정</h2>
        <div className="left-right-header">
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={cancelSalesConfirmManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
          <div style={{ marginRight: 10 }}>
            <Button text="취소 확정" width={100} onClick={handleConfirm} />
          </div>
          <div style={{ marginRight: 10 }}>
            <Button text="취소 반려" width={100} onClick={handleReject} />
          </div>
          <div style={{ marginRight: 10 }}>
            <Button text="취소 진행" width={100} onClick={handleReview} />
          </div>
        </div>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CancelConfirmTicket
            headerBefore={[
              <div className="label">접수일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TextBox
                name="SearchCenter"
                labelMode="static"
                stylingMode="outlined"
                placeholder="사업소명/P-코드/사업소코드"
                onValueChanged={onChangeCenter}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchApprovalNo"
                labelMode="static"
                stylingMode="outlined"
                placeholder="승인번호/카드번호/계좌번호"
                onValueChanged={onChangeApprovalNo}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchVehicleNumber"
                labelMode="static"
                stylingMode="outlined"
                placeholder="차량번호"
                onValueChanged={onChangeVehicleNumber}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchCustomerName"
                labelMode="static"
                stylingMode="outlined"
                placeholder="고객명"
                onValueChanged={onChangeCustomerName}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
            ]}
            headerAfter={[<Button icon="search" text="조회" width={120} onClick={onSearch} />]}
            setCRowdata={setCRowdata}
            setEditRowdata={setEditRowdata}
            setPopup={setPopup}
            cancelTicketRef={cancelTicketRef}
            searchData={searchData}
            isCancelSalesConfirm
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </div>
      <Popup
        onHiding={onClose}
        visible={popup.visible}
        showCloseButton={true}
        showTitle={false}
        width="75%"
        height={690}
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <CancelEditForm
              setPopup={setPopup}
              editRowdata={editRowdata}
              refundReason={refundReason}
              refundPaymentType={refundPaymentType}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <RemarkPopup
        ref={confirmPopupRef}
        title={'취소 확정'}
        setRemark={confirmPopupSave}
        cRowdata={cRowdata}
        actionType="confirm"
      />
      <RemarkPopup
        ref={rejectPopupRef}
        title={'취소 반려'}
        setRemark={rejectPopupSave}
        cRowdata={cRowdata}
        actionType="reject"
      />
      <RemarkPopup
        ref={reviewPopupRef}
        title={'취소 진행'}
        setRemark={reviewPopupSave}
        cRowdata={cRowdata}
        actionType="review"
      />
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </>
  );
};

export default CancelSalesConfirmPage;
