import React, { useEffect, useState, useRef } from 'react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { master } from '../../../api';
import './costcenter.scss';
import CommonDataGrid from 'common/default-data-grid';

const CostcenterMasterPage = () => {
  const [text, setText] = useState('');
  const [searchParam, setSearchParam] = useState({
    SearchText: '',
  });
  const [dataSource, setDataSource] = useState([]);
  const gridRef = useRef({});

  useEffect(() => {
    (async () => {
      const result = await master.searchCostCenterMaster(searchParam);
      if (result.isOk) {
        setDataSource(result.data);
      }
    })();
  }, [searchParam]);
  const onEnterKey = () => {
    onSearch();
  };

  const onValueChanged = e => {
    setText(e.value);
  };

  const onSearch = () => {
    setSearchParam({ ...searchParam, SearchText: text.trim() });
  };

  const useYNcellRender = e => {
    const { UseYN } = e.data;
    return UseYN === 'Y' ? '예' : '아니오';
  };

  return (
    <div>
      <h2 className={'content-block'}>코스트센터 마스터 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            stylingMode="outlined"
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '코스트센터 조회.xlsx',
            }}
            isHeader={true}
            headerBefore={[
              <TextBox
                mode="search"
                labelMode="static"
                width={200}
                stylingMode="outlined"
                placeholder="코스트센터코드/코스트센터명"
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column dataField={'CostCenterName'} caption={'코스트센터명'} hidingPriority={3} />
            <Column dataField={'HiParkingID'} caption={'관리 ID'} hidingPriority={2} />
            <Column dataField={'SAPCostCenterCode'} caption={'SAP 코스트센터'} width={'20%'} />
            <Column dataField={'UseYN'} caption={'사용여부'} cellRender={useYNcellRender} hidingPriority={1} />
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default CostcenterMasterPage;
