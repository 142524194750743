import React, { useCallback, useState } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { ticket, error } from 'api';

const ExtendTicketForm = props => {
  const { setIsOpenbyExtendSTicket, ticketIDs, setIsRefresh, selectPayType, isRefresh, setLoadPanelVisible } = props;
  const nextMonth = moment().add(1, 'M').format('YYYY-MM-DD');
  const [formData, setFormData] = useState({
    FromDate: moment(nextMonth).startOf('month').format('YYYY-MM-DD'),
    ToDate: moment(nextMonth).endOf('month').format('YYYY-MM-DD'),
    PaymentType: '',
    SalesPrice: 0,
  });

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { PaymentType, SalesPrice, FromDate, ToDate } = formData;
      setLoadPanelVisible(true);
      await ticket
        .extendSeasonTicketIssueHistory({
          TicketID: ticketIDs,
          FromDate: FromDate,
          ToDate: ToDate,
          PaymentType,
          SalesPrice,
          RegisterUser: '',
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '연장 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            setIsRefresh(!isRefresh);
            onClose();
          } else {
            // error
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [ticketIDs, setIsRefresh, isRefresh, formData],
  );

  const onClose = () => {
    const nextMonth = moment().add(1, 'M').format('YYYY-MM-DD');
    setIsOpenbyExtendSTicket(false);
    setFormData({
      FromDate: moment(nextMonth).startOf('month').format('YYYY-MM-DD'),
      ToDate: moment(nextMonth).endOf('month').format('YYYY-MM-DD'),
      // PaymentType: null,
      SalesPrice: 0,
    });
  };

  const handleDateFrom = e => {
    // 계약 시작일 선택 시 해당 월의 마지막 날을 계약 종료일로 자동 선택
    if (e.value) {
      setFormData({
        ...formData,
        ToDate: moment(e.value).endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="정기권 연장">
            <SimpleItem
              dataField="FromDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
                onValueChanged: handleDateFrom,
              }}
            >
              <Label text="계약시작일" location="left" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="ToDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
                min: formData.FromDate,
                dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
              }}
            >
              <Label text="계약종료일" location="left" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="PaymentType"
              editorType="dxSelectBox"
              colSpan={2}
              editorOptions={{
                items: selectPayType,
                displayExpr: 'text',
                valueExpr: 'code',
                placeholder: '결제수단을 선택해주세요',
              }}
              visible={false}
            >
              <RequiredRule message="결제수단을 선택해주세요" />
              <Label text="결제수단" location="top" />
            </SimpleItem>
            <SimpleItem
              dataField="SalesPrice"
              colSpan={2}
              editorType="dxNumberBox"
              helpText="숫자만 입력가능합니다."
              editorOptions={{
                showSpinButtons: true,
                step: 1000,
                min: 0,
                format: '#,##0 원',
              }}
              visible={false}
            >
              <Label text="결제금액" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'80%'} type={'default'} useSubmitBehavior={true} text="확인"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'80%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
};

export default ExtendTicketForm;
