import React, { useRef, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  NumericRule,
  StringLengthRule,
} from 'devextreme-react/form';
import { salesPayment, parking , error} from 'api';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import CommonDataGrid from 'common/default-data-grid';
import { Column, Editing } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';

import CostcenterMaster from 'common/popup/costcenter-master';
import CustomInfoPopup from '../sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import History from './history';
import 'styles/CommonStyles.scss'

const TaxinvoiceUploadData = () => {
  const texinvoiceHistoryRef = useRef({});
  const formRef = useRef({});
  const gridRef = useRef({});
  const formData = useRef({});
  const costcenterMasterRef = useRef({});
  const customInfoRef = useRef({});

  const [taxInvoiceType, setTaxInvoiceType] = useState([]);
  const [taxIssueType, setTaxIssueType] = useState([]);
  const [taxType, setTaxType] = useState([]);
  const [salesType, setSalesType] = useState([]);
  const [etcType, setEtcType] = useState([]);

  const [selectedCostCenter, setSelectedCostCenter] = useState([{}]);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);

  // 매출 내역 grid
  const [dataSource, setDataSource] = useState([]);

  const [addParams, setAddParams] = useState({
    SalesType: '',
    SalesKeyIDList: '',
    CostCenterCode: '',
    CostCenterName: '',
    SalesPrice: 0,
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerManagerGroup: '',
    CustomerManagerName: '',
    CustomerEmail1: '',
    CustomerEmail2: '',
    CustomerTelephoneNo: '',
    TaxPrice: '',
    UnitPrice: '',
    ItemListJson: '',
    DerivedCorpRegNumber: '',
  });

  const [isEtc, setIsEtc] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const handleData = useCallback(async CostCenterCode => {
    const { SalesType, EtcSalesType } = formData.current;

    await salesPayment
      .getEmptyTaxInvoiceItems({
        CostCenterCode,
        SalesType,
        EtcSalesType,
      })
      .then(res => {
        if (res.isOk) {
          console.log(res.data[0]);
          setDataSource(res.data[0]);
        }
      });
  }, []);

  useEffect(() => {
    if (taxInvoiceType.length === 0) {
      api();
    }
  }, [taxInvoiceType]);

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      setAddParams({
        ...addParams,
        CostCenterCode: selectedCostCenter[0].CostCenterCode,
        CostCenterName: selectedCostCenter[0].CostCenterName,
      });
      handleData(selectedCostCenter[0].CostCenterCode);
    }
  }, [selectedCostCenter]);

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setAddParams({
        ...addParams,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerManagerGroup: selectedCustomInfo[0].CustomerManagerGroup,
        CustomerManagerName: selectedCustomInfo[0].CustomerManagerName,
        CustomerEmail1: selectedCustomInfo[0].Email,
        CustomerEmail2: '',
        CustomerTelephoneNo: selectedCustomInfo[0].CustomerTelephoneNo,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);

  const onClickSaleType = e => {
    const { value } = e.itemData;
    if (value === 'EtcSales') {
      setIsEtc(true);
    } else {
      setIsEtc(false);
    }
  };
  // 팝업에 들어가는 유형 select items
  const api = useCallback(async () => {
    const taxInvoiceType = [];
    const taxType = [];
    const taxIssueType = [];
    const salesType = [];
    const etcType = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['SalesType', 'EtcSalesType', 'TaxType', 'TaxInvoiceType', 'TaxIssueType'],
    });
    if (result.isOk && result.data.length > 0) {
      // taxType.clear();
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'TaxInvoiceType') {
          taxInvoiceType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxInvoiceType',
          });
        } else if (obj.CodeGroup === 'SalesType') {
          salesType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'SalesType',
          });
        } else if (obj.CodeGroup === 'EtcSalesType') {
          etcType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'EtcSalesType',
          });
        } else if (obj.CodeGroup === 'TaxType') {
          taxType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxType',
          });
        } else if (obj.CodeGroup === 'TaxIssueType') {
          taxIssueType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxIssueType',
          });
        }
      });
      setTaxInvoiceType(taxInvoiceType);
      setTaxIssueType(taxIssueType);
      setTaxType(taxType);
      setSalesType(salesType);
      setEtcType(etcType);
    }
  }, []);

  // 저장
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      gridRef.current.instance.saveEditData();
      const {
        SalesDate,
        TaxInvoiceType,
        TaxType,
        TaxIssueType,
        TaxPrice,
        UnitPrice,
        CustomerEmail1,
        CustomerEmail2,
        SalesType,
        DerivedCorpRegNumber,
        ...customInfo
      } = formData.current;
      const {
        EtcSalesType,
        SalesKeyIDList,
        CostCenterCode,
        CostCenterName,
        SalesPrice,
        CustomerCode,
        CustomerCorpRegNumber,
        CustomerManagerGroup,
        CustomerManagerName,
        CustomerTelephoneNo,
      } = addParams;
      if (SalesPrice !== TaxPrice + UnitPrice) {
        return alert('금액을 확인해주세요.');
      } else {
        setLoadPanelVisible(true);
        await salesPayment
          .insertTaxInvoice({
            CostCenterCode,
            CostCenterName,
            TaxInvoiceType, // 일반
            TaxIssueType: 'A', // 청구
            SalesType,
            SalesKeyIDList,
            SalesPrice,
            SalesDate: SalesDate ? moment(SalesDate).format('YYYY-MM-DD') : '',
            DerivedCorpRegNumber,
            RegisterUser: '',
            CustomerCode,
            CustomerCorpRegNumber,
            CustomerManagerGroup,
            CustomerManagerName,
            CustomerEmail1,
            CustomerEmail2,
            CustomerTelephoneNo,
            TaxType,
            TaxPrice,
            UnitPrice,
            ItemListJson: JSON.stringify(dataSource),
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '처리되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              texinvoiceHistoryRef.current.refresh();
              onClose();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
              texinvoiceHistoryRef.current.refresh();
              onClose();
            }
          });
      }
    },
    [formData, addParams],
  );

  // 사업소 변경시 버튼을 통해서만 가능하도록
  const onFocusIn = e => {
    e.event.target.blur();
  };

  const onClose = () => {
    formRef.current.instance.resetValues();
    setAddParams({
      TaxInvoiceType: '',
      TaxIssueType: '',
      SalesType: '',
      SalesKeyIDList: '',
      CostCenterCode: '',
      CostCenterName: '',
      SalesPrice: 0,
      CustomerCorpRegNumber: '',
      CustomerCorpName: '',
      NameOfRepresentative: '',
      CustomerManagerGroup: '',
      CustomerManagerName: '',
      CustomerEmail1: '',
      CustomerEmail2: '',
      CustomerTelephoneNo: '',
      TaxPrice: 0,
      UnitPrice: 0,
      ItemListJson: '',
      DerivedCorpRegNumber: '',
    });
    setDataSource([]);
  };

  const handleSelectPrice = e => {
    const value = e.component._changedValue.replaceAll(',', '');
    const unitPrice = Math.round(value / 1.1);
    const taxPrice = Math.round(value - unitPrice);
    setAddParams({
      ...addParams,
      SalesPrice: Number(value),
      UnitPrice: unitPrice,
      TaxPrice: taxPrice,
    });
  };

  const onTaxTypeChanged = e => {
    const { SalesPrice } = formData.current;
    if (e.value === '000' || e.value === '020') {
      setAddParams({
        ...addParams,
        TaxPrice: 0,
        UnitPrice: SalesPrice,
      });
    } else {
      const unitPrice = Math.round(SalesPrice / 1.1);
      const taxPrice = Math.round(SalesPrice - unitPrice);
      setAddParams({
        ...addParams,
        TaxPrice: taxPrice,
        UnitPrice: unitPrice,
      });
    }
  };

  return (
    <div id="sp-loading">
      <h2 className={'content-block detail-title'}>세금 계산서 데이터 입력</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <div style={{ display: 'flex', marginTop: '3px', gap: '30px' }}>
            <div className={'dx-card'} style={{ padding: '15px 20px', maxWidth: '45%', height: '1040px' }}>
              {/* 입력 - 페이지 */}
              <form onSubmit={onSubmit}>
                <div className="">
                  <Form
                    className="add-tax-invoice"
                    ref={formRef}
                    formData={formData.current}
                    labelMode="outside"
                    optionalMark="optional"
                    stylingMode="outlined"
                    // labelLocation="left"
                  >
                    <GroupItem caption={'세금 계산서 수기 발행'} colCount={2}>
                      <GroupItem>
                        <SimpleItem
                          dataField="TaxInvoiceType"
                          editorType="dxSelectBox"
                          editorOptions={{
                            items: taxInvoiceType,
                            valueExpr: 'value',
                            displayExpr: 'label',
                            layout: 'horizontal',
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="발행 유형" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="SalesType"
                          editorType="dxSelectBox"
                          editorOptions={{
                            items: salesType,
                            valueExpr: 'value',
                            displayExpr: 'label',
                            onItemClick: onClickSaleType,
                            placeholder: '매출 유형을 선택해주세요',
                          }}
                        >
                          <RequiredRule message="매출 유형을 선택해주세요" />
                          <Label text="매출유형" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="EtcSalesType"
                          editorType="dxSelectBox"
                          editorOptions={{
                            items: etcType,
                            disabled: !isEtc,
                            valueExpr: 'value',
                            displayExpr: 'label',
                            placeholder: '결제 유형을 선택해주세요',
                          }}
                        >
                          <RequiredRule message="결제 유형을 선택해주세요" />
                          <Label text="매출 세부 유형" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="CostCenterName"
                          editorType="dxTextBox"
                          editorOptions={{
                            onFocusIn: onFocusIn,
                            buttons: [
                              {
                                name: 'trash',
                                location: 'after',
                                options: {
                                  stylingMode: 'text',
                                  text: '사업소 변경',
                                  onClick: () => {
                                    costcenterMasterRef.current.open();
                                    handleData();
                                  },
                                },
                              },
                            ],
                            value: addParams.CostCenterName,
                          }}
                        >
                          <Label text="사업소" />
                        </SimpleItem>
                        {/* <SimpleItem
                          dataField="TaxInvoiceType"
                          editorType="dxSelectBox"
                          editorOptions={{
                            items: taxInvoiceType,
                            valueExpr: 'value',
                            displayExpr: 'label',
                            placeholder: '발행 유형을 선택해주세요.',
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="발행 유형" />
                        </SimpleItem> */}
                        <SimpleItem
                          dataField="TaxIssueType"
                          editorType="dxSelectBox"
                          editorOptions={{
                            items: taxIssueType,
                            readOnly: true,
                            value: 'A',
                            valueExpr: 'value',
                            displayExpr: 'label',
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="대금 유형" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="SalesPrice"
                          editorType="dxNumberBox"
                          editorOptions={{
                            format: '#,##0',
                            value: addParams.SalesPrice,
                            onFocusOut: handleSelectPrice,
                          }}
                        >
                          <Label text="계산서 금액" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="TaxType"
                          editorType="dxRadioGroup"
                          editorOptions={{
                            items: taxType,
                            valueExpr: 'value',
                            displayExpr: 'label',
                            layout: 'horizontal',
                            onValueChanged: onTaxTypeChanged,
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="과세 유형" />
                        </SimpleItem>

                        <SimpleItem
                          dataField="UnitPrice"
                          editorType="dxNumberBox"
                          editorOptions={{
                            format: '#,##0',
                            value: addParams.UnitPrice,
                            readOnly: false,
                          }}
                        >
                          <Label text="공급가액" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="TaxPrice"
                          editorType="dxNumberBox"
                          editorOptions={{
                            format: '#,##0',
                            value: addParams.TaxPrice,
                            readOnly: false,
                          }}
                        >
                          <Label text="부가세" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="SalesDate"
                          editorType="dxDateBox"
                          editorOptions={{
                            type: 'date',
                            dropDownButtonTemplate: 'dropDownButton',
                            displayFormat: 'yyyy-MM-dd',
                            useMaskBehavior: true,
                            icon: true,
                            placeholder: '요청일을 선택해주세요',
                            value: moment().format('YYYY-MM-DD'),
                            // onValueChanged: handleDateFrom,
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="발행 요청일" />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem>
                        <SimpleItem
                          dataField="CustomerCorpRegNumber"
                          editorType="dxTextBox"
                          editorOptions={{
                            onFocusIn: onFocusIn,
                            buttons: [
                              {
                                name: 'trash',
                                location: 'after',
                                options: {
                                  stylingMode: 'text',
                                  text: '사업자 선택',
                                  onClick: () => {
                                    customInfoRef.current.open();
                                  },
                                },
                              },
                            ],
                            value: addParams.CustomerCorpRegNumber,
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="사업자등록번호" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="CustomerCorpName"
                          editorType="dxTextBox"
                          editorOptions={{
                            readOnly: true,
                            value: addParams.CustomerCorpName,
                          }}
                        >
                          <Label text="회사명" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="NameOfRepresentative"
                          editorType="dxTextBox"
                          editorOptions={{
                            readOnly: true,
                            value: addParams.NameOfRepresentative,
                          }}
                        >
                          <Label text="매입자 대표자 성명" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="CustomerManagerGroup"
                          editorType="dxTextBox"
                          editorOptions={{
                            value: addParams.CustomerManagerGroup,
                          }}
                        >
                          {/* <RequiredRule message="필수 값입니다." /> */}
                          <Label text="담당부서" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="CustomerManagerName"
                          editorType="dxTextBox"
                          editorOptions={{
                            value: addParams.CustomerManagerName,
                          }}
                        >
                          {/* <RequiredRule message="필수 값입니다." /> */}
                          <Label text="담당자" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="CustomerEmail1"
                          editorType="dxTextBox"
                          editorOptions={{
                            value: addParams.CustomerEmail1,
                          }}
                        >
                          <RequiredRule message="필수 값입니다." />
                          <Label text="Email" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="CustomerEmail2"
                          editorType="dxTextBox"
                          editorOptions={{
                            value: addParams.CustomerEmail2,
                          }}
                        >
                          <Label text="Email2" />
                        </SimpleItem>
                        <SimpleItem dataField="CustomerTelephoneNo" editorType="dxTextBox">
                          <RequiredRule message="필수 값입니다." />
                          <Label text="전화번호" />
                        </SimpleItem>
                        <SimpleItem
                          dataField="DerivedCorpRegNumber"
                          editorType="dxTextBox"
                          editorOptions={{
                            maxLength: 4,
                          }}
                        >
                          <Label text="종사업장" />
                          <NumericRule message="숫자만 입력가능합니다." />
                          <StringLengthRule max={4} message="종사업장은 4글자를 초과할 수 없습니다." />
                        </SimpleItem>
                        <SimpleItem dataField="Remark" editorType="dxTextArea" colSpan={2}>
                          <Label text="적요" />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem colSpan={2}>
                        <CommonDataGrid
                          gridRef={gridRef}
                          className={'dx-card wide-card'}
                          dataSource={dataSource}
                          showBorders={true}
                          allowColumnResizing={true}
                          columnHidingEnabled={false}
                          hoverStateEnabled={true}
                          height={150}
                          isHeader={true}
                          isExcel={false}
                          isNotFilterRow
                          headerBefore={[<span> 매출 내역</span>]}
                        >
                          {formData && formData.SapStatus !== 'C' && (
                            <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={true} />
                          )}
                          <Column
                            dataField={'CostCenterName'}
                            caption={'사업소명'}
                            width={120}
                            alignment={'center'}
                            allowEditing={false}
                          />
                          <Column
                            dataField={'HiParkingID'}
                            caption={'P코드'}
                            width={'90'}
                            alignment={'center'}
                            allowEditing={false}
                            visible={false}
                          />
                          <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} alignment={'center'} />
                          <Column
                            dataField={'DetailSalesTypeName'}
                            caption={'상세매출유형'}
                            width={80}
                            alignment={'center'}
                          />
                          <Column
                            dataField={'ItemName'}
                            caption={'항목명'}
                            width={180}
                            alignment={'center'}
                            allowEditing={false}
                            visible={false}
                          />
                          <Column
                            dataField={'Cnt'}
                            caption={'수량'}
                            width={80}
                            alignment={'center'}
                            allowEditing={false}
                          />
                          <Column
                            dataField={'SalesUnitPrice'}
                            caption={'단가'}
                            width={100}
                            alignment={'center'}
                            format="#,##0 원"
                          />
                          <Column
                            dataField={'UnitPrice'}
                            caption={'공급가액'}
                            width={100}
                            alignment={'center'}
                            format="#,##0 원"
                          />
                          <Column
                            dataField={'TaxPrice'}
                            caption={'세액'}
                            width={80}
                            alignment={'center'}
                            format="#,##0 원"
                          />
                          <Column
                            dataField={'SalesPrice'}
                            caption={'합계금액'}
                            width={100}
                            alignment={'center'}
                            format="#,##0 원"
                          />
                          <Column dataField={'Remark'} caption={'비고'} width={80} alignment={'left'} />
                        </CommonDataGrid>
                      </GroupItem>
                    </GroupItem>

                    <GroupItem colCount={2}>
                      <ButtonItem>
                        <ButtonOptions
                          width={'60%'}
                          type={'default'}
                          useSubmitBehavior={true}
                          text={'저장'}
                        ></ButtonOptions>
                      </ButtonItem>
                      <ButtonItem horizontalAlignment="left">
                        <ButtonOptions width={'55%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
                      </ButtonItem>
                    </GroupItem>
                  </Form>
                </div>
              </form>
            </div>
            <div style={{ maxWidth: '55%' }}>
              <History ref={texinvoiceHistoryRef} />
            </div>
          </div>
        </div>
      </div>
      <CostcenterMaster ref={costcenterMasterRef} setSelectedCostCenter={setSelectedCostCenter} />
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default TaxinvoiceUploadData;
