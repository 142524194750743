import React, { useCallback, useState, useImperativeHandle, useEffect } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, Item } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { ticket, error } from 'api';
import { makePaysysStatus } from './ales-common-util';
import { useAuth } from '../../contexts/auth';
import { confirm } from 'devextreme/ui/dialog';
import moment from 'moment';

const PaymentDetailForm = React.forwardRef((props, ref) => {
  const { user } = useAuth();
  const { setPopup, refresh, setLoadPanelVisible } = props;
  const [formData, setFormData] = useState({});
  const paysysPaymentType = [
    {
      label: '신용카드',
      value: '0',
    },
    {
      label: '일회가상계좌',
      value: '1',
    },
    {
      label: '고정가상계좌',
      value: '2',
    },
  ];

  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = UID => {
    (async () => {
      try {
        await ticket.checkPaymentStatus({
          UID,
        });
        const result = await ticket.getPaysysRequestByID({
          UID,
        });

        const formData = result.data[0];
        const RegisterDate = formData.RegisterDate && moment(formData.RegisterDate).format('YYYY-MM-DD');
        const PaymentDate = formData.PaymentDate && moment(formData.PaymentDate).format('YYYY-MM-DD');
        const IsRequested = formData.PayRequestStatusName === '요청완료';
        setFormData({ ...formData, RegisterDate, PaymentDate, IsRequested });
      } catch (e) {
        notify(
          {
            message: error.errorMsgCheck(e.error.detail),
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
        onClose();
        const { type, title, status, detail, instance } = e.response.data;
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    })();
  };
  // 결제 요청 취소
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const RegisterUserMail = user.Email.split('@')[0];
      const { UID } = formData;
      const params = { RegisterUserMail, UID };
      const result = confirm('<i> 결제 요청을 취소 하시겠습니까? </i>', '확인');
      result.then(async dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          await ticket.cancelPayRequestInfo(params).then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '결제 요청이 취소 되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
              onClose();
            } else {
              // error
              setLoadPanelVisible(false);
              notify(
                {
                  message: res.error.detail,
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
        }
      });
    },
    [formData],
  );

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setFormData({});
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="detail-history form-with-status">
        <Form
          formData={formData}
          labelLocation="top"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
          readOnly={true}
          height={750}
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem>
            <div>{makePaysysStatus(formData.PayRequestStatusName)}</div>
          </GroupItem>
          <GroupItem caption="통합결제시스템 처리 상태" colCount={10}>
            <SimpleItem
              dataField="PayExpireDate"
              editorType="dxTextBox"
              colSpan={4}
              editorOptions={{ stylingMode: 'underlined' }}
            >
              <Label text="결제유효일시" location="left" />
            </SimpleItem>
            <SimpleItem
              dataField="RegisterDate"
              editorType="dxTextBox"
              colSpan={3}
              displayFormat="yyyy-mm-dd"
              editorOptions={{ stylingMode: 'underlined' }}
            >
              <Label text="요청일" location="left" />
            </SimpleItem>
            <SimpleItem
              dataField="PaymentDate"
              editorType="dxTextBox"
              colSpan={3}
              editorOptions={{ stylingMode: 'underlined' }}
            >
              <Label text="결제일" location="left" />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption="결제 요청 상세보기" colCount={2}>
            <SimpleItem dataField="GoodsName" editorType="dxTextBox" colSpan={2}>
              <Label text="상품명" />
            </SimpleItem>
            <SimpleItem dataField="CostCenterName" editorType="dxTextBox" colSpan={1}>
              <Label text="사업소" />
            </SimpleItem>
            <SimpleItem dataField="CustomerName" editorType="dxTextBox" colSpan={1}>
              <Label text="회원명" />
            </SimpleItem>
            <SimpleItem dataField="VehicleRegistrationNo" editorType="dxTextBox" colSpan={1}>
              <Label text="차량번호" />
            </SimpleItem>
            <SimpleItem dataField="Unit" editorType="dxTextBox" colSpan={1}>
              <Label text="수량" />
            </SimpleItem>
            <SimpleItem
              dataField="UnitPrice"
              editorType="dxNumberBox"
              colSpan={1}
              editorOptions={{ format: '#,##0 원' }}
            >
              <Label text="단가" />
            </SimpleItem>
            <SimpleItem
              dataField="TotalPrice"
              editorType="dxNumberBox"
              colSpan={1}
              editorOptions={{ format: '#,##0 원' }}
            >
              <Label text="금액" />
            </SimpleItem>
            <SimpleItem
              dataField="FromDate"
              editorType="dxTextBox"
              colSpan={1}
              // editorOptions={{
              //   type: 'date',
              //   dropDownButtonTemplate: 'dropDownButton',
              //   displayFormat: formData.GoodsType === '0' ? 'yyyy-MM-dd' : 'yyyy-MM-dd hh:mm:ss',
              //   useMaskBehavior: true,
              //   icon: true,
              // }}
            >
              <Label text={formData.GoodsType === '0' ? '정기권 시작일' : '입차일시'} />
            </SimpleItem>
            <SimpleItem
              dataField="ToDate"
              editorType="dxTextBox"
              colSpan={1}
              // editorOptions={{
              //   type: 'date',
              //   dropDownButtonTemplate: 'dropDownButton',
              //   displayFormat: formData.GoodsType === '0' ? 'yyyy-MM-dd' : 'yyyy-MM-dd hh:mm:ss',
              //   useMaskBehavior: true,
              //   icon: true,
              // }}
            >
              <Label text={formData.GoodsType === '0' ? '정기권 종료일' : '출차일시'} />
            </SimpleItem>
            <SimpleItem dataField="MobilePhoneNo" editorType="dxTextBox" colSpan={1}>
              <Label text="결제링크 수신 전화번호" />
            </SimpleItem>
            <SimpleItem dataField="EmailAddress" editorType="dxTextBox" colSpan={1}>
              <Label text="결제링크 수신 email" />
            </SimpleItem>
            <SimpleItem
              dataField="PayType"
              editorType="dxSelectBox"
              colSpan={1}
              editorOptions={{ items: paysysPaymentType, displayExpr: 'label', valueExpr: 'value' }}
            >
              <Label text="결제방식" />
            </SimpleItem>
            <SimpleItem dataField="BankName" editorType="dxTextBox" colSpan={1}>
              <Label text="은행코드" />
            </SimpleItem>
            <SimpleItem
              dataField="FixAccountStartDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
              }}
            >
              <Label text="결제시작일" />
            </SimpleItem>
            <SimpleItem
              dataField="FixAccountEndDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
              }}
            >
              <Label text="결제마지막일" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions
                colSpan={1}
                width={'50%'}
                type="danger"
                text="결제 요청 취소"
                disabled={!formData.IsRequested}
                useSubmitBehavior={true}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="top">
              <ButtonOptions colSpan={1} width={'50%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default PaymentDetailForm;
