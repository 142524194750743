import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect } from 'react';
import moment from 'moment';
import ContextMenu from 'devextreme-react/context-menu';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { salesPayment } from 'api';
import { cellStatus } from '../common/common';
import CommonDataGrid from '../common/index';
import AddGeneralForm from './popup/AddGeneralForm';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';

/*
isSalesPayment : 매출 대사 관리
isTaxinvoice, isSearch : 세금계산서 발행
isClosePending : 미처리 매출/결제 마감
*/

const GeneralTicket = React.forwardRef((props, ref) => {
  const {
    setGRowdata,
    inquiryDate,
    costCenter,
    generalTicketRef,
    isClosePending,
    isTaxinvoice,
    searchData,
    isSearch,
    isSalesPayment,
    onCancelClick,
    setSelectedID,
    setSelectedAccountingNo,
  } = props;
  const [selectSum, setSelectSum] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [customizedColumns, setCustomizedColumns] = useState([]);
  const [forceRender, setForceRender] = useState(false);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));

  const focusChange = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    generalTicketRef.current.instance.selectRows(KeyIDs);
  };

  useEffect(() => {
    const customizedGeneral = localStorage.getItem('customizedGeneral');
    if (customizedGeneral) {
      const tmp_columns = JSON.parse(customizedGeneral);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = generalTicketRef.current.instance.getSelectedRowsData();
    setGRowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.RemainAmount;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      return { CostCenter: costCenter, IsPostponed: 'Y', InquiryMonth: inquiryDate };
    } else if (isTaxinvoice) {
      return isSearch ? searchData : {};
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || isSearch) {
          generalTicketRef.current.instance.clearSelection();
          const result = await salesPayment.getGSalesDataDetail(params);
          setForceRender(prev => !prev);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const onHiding = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  const refresh = () => {
    generalTicketRef.current.instance.refresh();
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;
    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { KeyID, AccountingNo } = data;
      setSelectedID(AccountingNo ? KeyID : 'N/A');
      setSelectedAccountingNo('');
      if (AccountingNo) {
        focusChange(AccountingNo);
      }
    }
  };
  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedGeneral', JSON.stringify(dataFieldsArr));
      });
    }
  };

  return (
    <>
      <CommonDataGrid
        gridRef={generalTicketRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        width={'100%'}
        onCellPrepared={onCellPrepared}
        onContentReady={onContentReady}
        onSelectionChanged={onSelectionChanged}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '일반권.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        {(isTaxinvoice || isClosePending) && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        {isClosePending && <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />}

        <Column
          dataField={'ApprovalDate'}
          caption={'매출시간'}
          customizeText={customizeText}
          width={'134'}
          visible={!customizedColumns.includes('ApprovalDate')}
        />

        <Column
          dataField={'VehicleRegistrationNo'}
          caption={'차량번호'}
          width={'90'}
          visible={!customizedColumns.includes('VehicleRegistrationNo')}
        />
        {isTaxinvoice && <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={'110'} />}
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'87'}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('StatusName')}
        />
        <Column
          dataField={'CreditCardApprovalNo'}
          caption={'승인번호'}
          width={'90'}
          visible={!customizedColumns.includes('CreditCardApprovalNo')}
        />
        <Column
          dataField={'RemainAmount'}
          caption={'잔액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('RemainAmount')}
        />
        <Column
          dataField={'SalesPrice'}
          caption={'매출금액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('SalesPrice')}
        />
        <Column
          dataField={'CreditCardNo'}
          caption={'카드번호'}
          width={'85'}
          visible={!customizedColumns.includes('CreditCardNo')}
        />
        <Column
          dataField={'IssueCreditCard'}
          caption={'카드사'}
          width={'100'}
          visible={!customizedColumns.includes('IssueCreditCard')}
        />
        <Column dataField={'Remark'} caption={'비고'} width={'100'} visible={!customizedColumns.includes('Remark')} />
        <Column
          dataField={'AccountingNo'}
          caption={'대사ID'}
          width={'160'}
          visible={!customizedColumns.includes('AccountingNo')}
        />
        <Summary>
          <TotalItem column="SalesPrice" displayFormat="선택합계 :" showInColumn="ApprovalDate" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="ApprovalDate" />
          <TotalItem column="CreditCardApprovalNo" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" displayFormat="전체합계 :" showInColumn="SalesPrice" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
          <TotalItem column="RemainAmount" displayFormat="전체합계 :" showInColumn="RemainAmount" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="22%" height={420}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <AddGeneralForm
              setIsOpen={setIsOpen}
              costCenterCode={costCenter}
              inquiryDate={inquiryDate}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </>
  );
});

export default React.memo(GeneralTicket);
