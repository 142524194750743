import React, { useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import TreeList, { HeaderFilter, FilterRow } from 'devextreme-react/tree-list';

import Toolbar, { Item } from 'devextreme-react/toolbar';
import { exportTreeList } from 'helper/treelist/export-treelist';
import { Button } from 'devextreme-react/button';

const CommonTreeList = React.forwardRef((props, ref) => {
  const {
    className,
    dataSource,
    children,
    headerBefore,
    isHeader,
    isExcel,
    headerAfter,
    treeRef,
    excelOptions,
    ...options
  } = props;

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const exportToExcel = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelOptions.sheetName);
    if (treeRef.current !== null) {
      exportTreeList({
        component: treeRef.current.instance,
        worksheet,
      })
        .then(() => {
          workbook.xlsx
            .writeBuffer()
            .then(buffer => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), excelOptions.fileName);
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  };

  return (
    <div>
      {isHeader && (
        <Toolbar>
          {headerBefore.map((content, ind) => (
            <Item location={'before'} key={ind}>
              {content}
            </Item>
          ))}
          {headerAfter.map((content, ind) => (
            <Item location={'after'} key={ind}>
              {content}
            </Item>
          ))}
          {isExcel && (
            <Item location={'after'}>{<Button icon="exportxlsx" onClick={exportToExcel} stylingMode="text" />}</Item>
          )}
        </Toolbar>
      )}
      <TreeList
        ref={treeRef}
        className={className}
        dataSource={dataSource}
        {...options}
        allowColumnResizing={true}
        allowColumnReordering={true}
      >
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        {children}
      </TreeList>
    </div>
  );
});

CommonTreeList.defaultProps = {
  isInfiniteScroll: true,
  isHeader: false,
  headerBefore: [],
  headerAfter: [],
  isExcel: true,
  excelOptions: {
    buttonName: null,
    sheetName: 'Sheet',
    fileName: 'sample.xlsx',
    customizeCell: { date: [] },
  },
};

CommonTreeList.propTypes = {
  isInfiniteScroll: PropTypes.bool,
  isHeader: PropTypes.bool,
  headerBefore: PropTypes.oneOfType([PropTypes.array]),
  headerAfter: PropTypes.oneOfType([PropTypes.array]),
  isExcel: PropTypes.bool,
};

export default React.memo(CommonTreeList);
