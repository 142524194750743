import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { salesPayment, error } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';
import queryString from 'query-string';

const CMSReplacementUpload = props => {
  const query = queryString.parse(decodeURIComponent(props.location.search));
  
  const dataGridRef = useRef({});
  const [cmsKeyValue, setCmsKeyValue] = useState(query.data);
  
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  // 저장버튼 비활성화
  const [saveDisabled, setSaveDisabled] = useState(true);

  // 컬럼 정의
  const columns = [
    '결제일시',
    '입금금액',
    '입금자명',
    '비고',
    // '계좌번호',
  ];

  useEffect(() => {
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();

      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = async () => {
    setLoadPanelVisible(true);
    console.log(cmsKeyValue);
    if (cmsKeyValue === null)
    {
      return alert('유효하지 않은 접근입니다.');
    }

    const res = await salesPayment.insertCMSReplacementDataJson({
      KeyID : cmsKeyValue.replaceAll(`'`,''),
      ItemListJson: JSON.stringify(dataSource),
    });
    if (res.isOk) {
      setLoadPanelVisible(false);
      setSaveDisabled(true);
      notify(
        {
          message: '저장 되었습니다.',
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'success',
      );
    } else {
      setLoadPanelVisible(false);
      setSaveDisabled(false);
      notify(
        {
          message: error.errorMsgCheck(res.error.detail),
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const checkExcel = async ExcelOrganization => {
    console.log(cmsKeyValue);
    const res = await salesPayment.checkCMSReplacementDataJson({
      KeyID : cmsKeyValue.replaceAll(`'`,''),
      ItemListJson: JSON.stringify(ExcelOrganization),
    });
    if (res.isOk) {
      setSaveDisabled(false);
      setDataSource(ExcelOrganization);
    } else {
      setSaveDisabled(true);
      setDataSource([]);
      notify(
        {
          message: error.errorMsgCheck(res.error.detail),
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const handleImport = ExcelOrganization => {
    setShowPane(true);
    checkExcel(ExcelOrganization);
    setShowPane(false);
  };

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);
  const cellStatus = (data, displayValue, cellElement) => {
    if (data) {
      const { StatusName, Status } = data;
      const tr = cellElement;
      if (displayValue === StatusName) {
        if (Status === 'A') {
          cellElement.style.backgroundColor = 'rgb(229 248 240)';
          cellElement.style.color = 'rgb(51 202 139)';
        } else if (Status === 'O') {
          cellElement.style.backgroundColor = 'rgba(0, 207, 232,0.12)';
          cellElement.style.color = 'rgb(29 74 74)';
        } else if (Status === 'P') {
          cellElement.style.backgroundColor = 'rgb(252 231 231)';
          cellElement.style.color = 'rgb(236 74 74)';
        } else if (Status === 'N') {
          cellElement.style.backgroundColor = 'rgba(247,247,247,1)';
          cellElement.style.color = 'rgba(33,33,33,1)';
        } else if (Status === 'C') {
          tr.classList.add('status-cancel');
        } else if (Status === 'S') {
          tr.classList.add('status-cancel');
        }
      } else {
        // 취소의 경우 한줄을 강조한다.
        if (Status === 'C' && tr) {
          tr.classList.add('status-cancel');
        }
      }
    }
  };
  const sampleData = [
    {
      결제일시: '2024-01-01 01:01:01',
      입금금액: 16000,
      입금자명: 'CMS공동망대체',
      비고: '비고 기입',
      // 계좌번호: '000011100000',
    },
    {
      결제일시: '2024-01-01 01:01:01',
      입금금액: 34000,
      입금자명: 'CMS공동망대체',
      비고: '',
      // 계좌번호: '000011100000',
    },
  ];

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <h2 className={'content-block'}>CMS 결제내역 대체</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h5>
              {' '}
              ★엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀 파일 업로드
              &nbsp; ▷ &nbsp; 저장
            </h5>
            <div style={{ flexGrow: 1 }}>&nbsp;</div>
            <ExcelImport
              fieldColumn={['A', 'B', 'C', 'D', 'E']}
              handleImport={e => handleImport(e.map(importMapFunc))}
              fieldColumnType={[
                'date',
                'number',
                'string',
                'string',
                'string',
                ]}
            />
          </div>
          <CommonDataGrid
            gridRef={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            onCellPrepared={onCellPrepared}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: 'CMS결제업로드.xlsx',
            }}
            headerAfter={[
              <Button
                icon="check"
                text="저장"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => saveExcel()}
                disabled={saveDisabled}
              />,
              <ExcelExport
                excelFields={headerText}
                sheetName="Sheet"
                fileName="sample.xlsx"
                excelData={sampleData}
                buttonName="샘플파일 다운로드"
                test
              />,
            ]}
            // headerAfter={[<Button icon="plus" text="매출 수기 등록" onClick={onOpen} />]}
            allowColumnReordering={true}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <LoadPanel enabled showPane={showPane} />
            <HeaderFilter visible={true} />
            <Column dataField={'결제일시'} caption={'결제일시'} hidingPriority={4} />
            <Column dataField={'입금금액'} caption={'입금금액'} hidingPriority={4} />
            <Column dataField={'입금자명'} caption={'입금자명'} hidingPriority={4} />
            <Column dataField={'비고'} caption={'비고'} hidingPriority={4} format="#,##0 원" />
            {/* <Column dataField={'계좌번호'} caption={'계좌번호'} hidingPriority={4} /> */}
            <Summary>
              <TotalItem column="결제일시" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="입금금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default CMSReplacementUpload;
