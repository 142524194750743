import React, { useRef, useEffect, useState, useCallback } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
  CustomRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './season-ticket-mng-member-form.scss';
import { ticket } from '../../../../api';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';

const SeasonTicketMemberForm = ({
  setPopup,
  isUpdate,
  formData,
  setIsRefresh,
  selectItemCode,
  selectPayType,
  selectPayStatus,
  groupType,
  popup,
  isRefresh,
  setLoadPanelVisible,
}) => {
  const formRef = useRef({});
  const customInfoRef = useRef({});
  const [selectType, setSelectType] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [selectDivision, setSelectDivision] = useState();
  const [selectUseYN, setSelectUseYN] = useState();
  const [selectMemberGroup, setSelectMemberGroup] = useState();
  const [isTagActive, setIsTagActive] = useState(false);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);
  const [updateForm, setUpdateForm] = useState({
    CarType: '',
    CostCenterCode: '',
    CostCenterName: '',
    EmailAddress: '',
    GroupCode: '',
    GroupName: '',
    MemberDivision: '',
    MemberDivisionName: '',
    MemberID: '',
    MemberName: '',
    MemberStatusName: '',
    MemberType: '',
    MemberTypeName: '',
    MobliePhoneNo: '',
    RegisterDate: '',
    RegisterUser: '',
    Status: '',
    TagRentPrice: 0,
    TelephoneNo: '',
    UseTag: '',
    UseTagName: '',
    VehicleRegistration: '',
    PaymentType: '',
    PaymentStatus: '',
    FromDate: '',
    ToDate: '',
    Remark: '',
    SalesPrice: '',
    StandardPrice: '',
    // 사업자 정보 추가
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerCode: '',
  });
  const [dateValue, setDateValue] = useState();

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setUpdateForm({
        ...updateForm,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);
  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberType', 'MemberStatus', 'UseYN', 'MemberDivision'],
      });
      const MemberType = [];
      const MemberStatus = [];
      const UseYN = [];
      const MemberDivision = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'MemberStatus') {
            MemberStatus.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'UseYN') {
            UseYN.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'MemberDivision') {
            return MemberDivision.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectType(MemberType);
      setSelectStatus(MemberStatus);
      setSelectUseYN(UseYN);
      setSelectDivision(MemberDivision);
    })();
  }, []);

  useEffect(() => {
    if (popup.visible) {
      const rawData = groupType.map(a => {
        return {
          text: a.GroupName,
          codeDesc: a.GroupName,
          code: a.GroupCode,
        };
      });
      rawData.unshift('');
      setSelectMemberGroup(rawData);
      // 부모 데이터와 분리, 팝업 그려질때마다 새롭게 셋팅
      const newObj = Object.assign({}, formData);
      setUpdateForm(newObj);
    }
    if (formData) {
      formData.UseTag === 'N' ? setIsTagActive(false) : setIsTagActive(true);
    }
  }, [popup.visible]);
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        CostCenterCode,
        MemberID,
        MemberName,
        MemberType,
        Status,
        VehicleRegistration,
        TelephoneNo,
        MobliePhoneNo,
        CarType,
        EmailAddress,
        RegisterUser,
        GroupCode,
        MemberDivision,
        UseTag,
        TagRentPrice,
        // 추가됨
        PaymentType,
        PaymentStatus,
        FromDate,
        ToDate,
        Remark,
        // 추가됨
        StandardPrice,
        CustomerCode,
      } = formRef.current.props.formData;
      const splitVehicleNumber = (fullnameStr, type) => {
        var result = '';
        if (type === 'prefix') result = fullnameStr.substr(0, fullnameStr.length - 4);
        else result = fullnameStr.substr(fullnameStr.length - 4, 4);
        return result.trim();
      };
      setLoadPanelVisible(true);
      if (isUpdate) {
        const result = await ticket.updateSeasonTicketMemberMaster({
          CostCenterCode,
          MemberID,
          MemberName,
          MemberType,
          Status,
          VehicleRegistrationPlatePrefix: splitVehicleNumber(VehicleRegistration, 'prefix'),
          VehicleRegistrationPlateNo: splitVehicleNumber(VehicleRegistration, 'plate'),
          TelephoneNo,
          MobliePhoneNo,
          CarType,
          EmailAddress,
          RegisterUser,
          GroupCode,
          MemberDivision,
          UseTag,
          TagRentPrice,
          StandardPrice,
          CustomerCode,
        });
        if (result.isOk) {
          setLoadPanelVisible(false);
          setPopup({ save: true, visible: false });
        } else {
          setLoadPanelVisible(false);
          alert(JSON.parse(result.error.detail).sqlMessage);
        }
      } else {
        const result = await ticket.insertSeasonTicketIssueHistoryWithNewMember({
          CostCenterCode: selectItemCode.CostCenterCode,
          MemberName,
          MemberType,
          Status,
          VehicleRegistrationPlatePrefix: splitVehicleNumber(VehicleRegistration, 'prefix'),
          VehicleRegistrationPlateNo: splitVehicleNumber(VehicleRegistration, 'plate'),
          TelephoneNo,
          MobliePhoneNo,
          CarType,
          EmailAddress,
          RegisterUser,
          GroupCode,
          MemberDivision,
          UseTag,
          TagRentPrice,
          PaymentType,
          PaymentStatus,
          FromDate: dateFormat(FromDate),
          ToDate: dateFormat(ToDate),
          Remark,
          StandardPrice,
          CustomerCode,
        });
        if (result.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '신규발행 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setPopup({ save: true, visible: false });
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: JSON.parse(result.error.detail).sqlMessage,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      }
      setIsRefresh(!isRefresh);
      setSelectedCustomInfo([]);
    },
    [isUpdate, selectItemCode, isRefresh],
  );

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setIsTagActive(false);
  };

  const onItemClick = e => {
    e.itemData.code === 'N' ? setIsTagActive(false) : setIsTagActive(true);
  };

  const onGroupItemClick = e => {
    if (e.itemData) {
      (async () => {
        const groupCode = e.itemData.code;
        const result = await ticket.searchSeasonTicketMemberGroupByGroupID({ GroupCode: groupCode });
        if (result.isOk > 0 && result.data) {
          const code = result.data[0].MemberDivision;
          setUpdateForm({ ...updateForm, MemberDivision: code });
        }
      })();
    } else {
      setUpdateForm({ ...updateForm, MemberDivision: '' });
    }
  };

  const digitCheck = useCallback(({ value }) => {
    var digitPart = value.substr(value.length - 4, 4).trim();
    if (!isNaN(digitPart) && digitPart.length === 4) {
      return true;
    }
  }, []);

  const handleDateFrom = e => {
    setDateValue(e.value);
    // 계약 시작일 선택 시 해당 월의 마지막 날을 계약 종료일로 자동 선택
    if (e.value) {
      setUpdateForm({
        ...updateForm,
        ToDate: moment(e.value).endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  const dateFormat = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onFocusIn = e => {
    e.event.target.blur();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <Form
            ref={formRef}
            formData={updateForm}
            showColonAfterLabel={false}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem colCount={2}>
              <GroupItem>
                <GroupItem caption={isUpdate ? '정기권 회원 변경' : '정기권 회원 추가'} colCount={6}>
                  <SimpleItem dataField="CostCenterName" colSpan={3} editorOptions={{ readOnly: true }}>
                    <Label text="사업소명" />
                  </SimpleItem>
                  <Item
                    dataField="Status"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectStatus,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                    colSpan={3}
                  >
                    <Label text="회원 상태" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <SimpleItem dataField="MemberName" colSpan={3}>
                    <Label text="회원명" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <Item
                    dataField="MemberType"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectType,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                    colSpan={3}
                  >
                    <Label text="정기권 유형" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <Item
                    dataField="GroupCode"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectMemberGroup,
                      displayExpr: 'text',
                      valueExpr: 'code',
                      onItemClick: onGroupItemClick,
                    }}
                    colSpan={3}
                  >
                    <Label text="회원 그룹" />
                  </Item>
                  <Item
                    dataField="MemberDivision"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectDivision,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                    colSpan={3}
                  >
                    <Label text="회원 구분" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  <SimpleItem editorType="dxTextBox" dataField="VehicleRegistration" colSpan={3}>
                    <Label text="차량번호" />
                    <RequiredRule message="필수값 입니다." />
                    <CustomRule message={'차량번호 네 자리를 확인해주세요.'} validationCallback={digitCheck} />
                  </SimpleItem>
                  <SimpleItem dataField="MobliePhoneNo" colSpan={3}>
                    <RequiredRule message="필수값 입니다." />
                    <Label text="핸드폰번호" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="StandardPrice"
                    colSpan={3}
                    editorType="dxNumberBox"
                    editorOptions={{
                      showSpinButtons: true,
                      step: 1000,
                      min: 0,
                      format: '#,##0 원',
                    }}
                  >
                    <Label text="기준금액" />
                  </SimpleItem>
                  <SimpleItem dataField="EmailAddress" colSpan={3}>
                    <Label text="이메일" />
                  </SimpleItem>
                  {/* <Item
                    dataField="UseTag"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: selectUseYN,
                      displayExpr: 'text',
                      valueExpr: 'code',
                      onItemClick: onItemClick,
                    }}
                    colSpan={3}
                  >
                    <Label text="태그사용유무" />
                  </Item>
                  <SimpleItem dataField="TagRentPrice" colSpan={3} editorOptions={{ disabled: !isTagActive }}>
                    <Label text="태그비용" />
                  </SimpleItem> */}
                  <SimpleItem dataField="TelephoneNo" colSpan={3}>
                    <Label text="전화번호" />
                  </SimpleItem>
                  <SimpleItem dataField="CarType" colSpan={3}>
                    <Label text="차종" />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              <GroupItem>
                <GroupItem caption={'사업자 정보'} colCount={6}>
                  <SimpleItem
                    dataField="CustomerCorpRegNumber"
                    editorType="dxTextBox"
                    colSpan={3}
                    editorOptions={{
                      onFocusIn: onFocusIn,
                      buttons: [
                        {
                          name: 'trash',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            text: '사업자 선택',
                            onClick: () => {
                              customInfoRef.current.open();
                            },
                          },
                        },
                      ],
                      value: updateForm.CustomerCorpRegNumber,
                    }}
                  >
                    <Label text="사업자등록번호" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="CustomerCorpName"
                    editorType="dxTextBox"
                    colSpan={3}
                    editorOptions={{
                      readOnly: true,
                      value: updateForm.CustomerCorpName,
                    }}
                  >
                    <Label text="회사명" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="NameOfRepresentative"
                    editorType="dxTextBox"
                    colSpan={3}
                    editorOptions={{
                      readOnly: true,
                      value: updateForm.NameOfRepresentative,
                    }}
                  >
                    <Label text="대표자 성명" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption={'정기권 발행 정보'} colCount={6}>
                  {/* 결제유형 추가 */}
                  <Item
                    dataField="PaymentType"
                    editorType="dxSelectBox"
                    colSpan={3}
                    editorOptions={{
                      items: selectPayType,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                  >
                    <Label text="결제유형" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  {/* 결제상태 추가 */}
                  {/* <Item
                    dataField="PaymentStatus"
                    editorType="dxSelectBox"
                    colSpan={3}
                    editorOptions={{
                      items: selectPayStatus,
                      displayExpr: 'text',
                      valueExpr: 'code',
                    }}
                  >
                    <Label text="결제상태" />
                    <RequiredRule message="필수값 입니다." />
                  </Item> */}
                  {/*  계약시작일 추가 */}
                  <Item
                    dataField="FromDate"
                    editorType="dxDateBox"
                    colSpan={3}
                    editorOptions={{
                      type: 'date',
                      dropDownButtonTemplate: 'dropDownButton',
                      displayFormat: 'yyyy-MM-dd',
                      useMaskBehavior: true,
                      icon: true,
                      onValueChanged: handleDateFrom,
                    }}
                  >
                    <Label text="계약시작일" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                  {/* 비고 */}
                  <SimpleItem dataField="Remark" colSpan={3} editorType="dxTextArea">
                    <Label text="비고" />
                  </SimpleItem>
                  {/*  계약종료일 추가 */}
                  <Item
                    dataField="ToDate"
                    editorType="dxDateBox"
                    colSpan={3}
                    editorOptions={{
                      type: 'date',
                      useMaskBehavior: true,
                      displayFormat: 'yyyy-MM-dd',
                      min: dateValue,
                      dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                      readOnly: isUpdate,
                    }}
                  >
                    <Label text="계약종료일" />
                    <RequiredRule message="필수값 입니다." />
                  </Item>
                </GroupItem>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'40%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'40%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </>
  );
};

export default React.memo(SeasonTicketMemberForm);
