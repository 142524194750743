import React, { useImperativeHandle, useCallback, useState } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import 'devextreme-react/text-area';
import { salesPayment, error } from 'api';

const CheckSalesTaxinvoiceForm = React.forwardRef((props, ref) => {
  const { setIsOpen, CostCenterCode, refresh } = props;
  const [formData, setFormData] = useState({
    SaleTotal: 0,
    PaymentTotal: 0,
    Diff: 0,
    InvoiceID: '',
    CMSPaymentKeyID: '',
    VANPaymentKeyID: '',
    Remark: '',
  });
  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = (tData, pData, cData) => {
    let saleTotal = 0;
    let paymentTotal = 0;
    const InvoiceID = [];
    const VANPaymentKeyID = [];
    const CMSPaymentKeyID = [];

    if (tData && tData.length > 0) {
      tData.forEach(t => {
        saleTotal += t.SalesPrice;
        InvoiceID.push(t.InvoiceID);
      });
    }
    if (pData && pData.length > 0) {
      pData.forEach(p => {
        paymentTotal += p.SalesPrice;
        VANPaymentKeyID.push(p.KeyID);
      });
    }

    if (cData && cData.length > 0) {
      cData.forEach(c => {
        paymentTotal += c.TransactionAmount;
        CMSPaymentKeyID.push(c.KeyID);
      });
    }
    setFormData({
      SaleTotal: saleTotal,
      PaymentTotal: paymentTotal,
      Diff: saleTotal - paymentTotal,
      InvoiceID: InvoiceID.length > 0 ? InvoiceID.join(';') : '',
      CMSPaymentKeyID: CMSPaymentKeyID.length > 0 ? CMSPaymentKeyID.join(';') : '',
      VANPaymentKeyID: VANPaymentKeyID.length > 0 ? VANPaymentKeyID.join(';') : '',
      Remark: '',
    });
  };
  // TODO : 매출금액, 결제금액 ,차이 label 확인하기

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { SaleTotal, Diff, InvoiceID, CMSPaymentKeyID, VANPaymentKeyID, Remark } = formData;
      if (Diff !== 0) {
        alert('매출금액과 결제금액은 같아야 합니다.');
      } else {
        await salesPayment
          .confirmAccountInfoTaxInvoice({
            CostCenterCode,
            SalesType: 'TaxInvoice',
            SalesPrice: SaleTotal,
            InvoiceID,
            CMSPaymentKeyID,
            VANPaymentKeyID,
            Remark,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              notify(
                {
                  message: '확정되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              onClose();
              refresh();
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },
    [formData, CostCenterCode],
  );

  const onClose = () => {
    setIsOpen(false);
    //   formRef.current.instance.resetValues();
  };

  return (
    <form onSubmit={onSubmit}>
      <Form
        //   ref={formRef}
        formData={formData}
        labelMode="outside"
        optionalMark="optional"
        stylingMode="outlined"
      >
        <ButtonItem>
          <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
        </ButtonItem>
        <GroupItem caption="세금계산서 대사">
          <SimpleItem
            dataField="SaleTotal"
            editorType="dxNumberBox"
            editorOptions={{
              readOnly: true,
              showSpinButtons: false,
              min: 0,
              format: '#,##0',
            }}
          >
            <Label text="매출금액" />
          </SimpleItem>
          <SimpleItem
            dataField="PaymentTotal"
            editorType="dxNumberBox"
            editorOptions={{
              readOnly: true,
              showSpinButtons: false,
              min: 0,
              format: '#,##0',
            }}
          >
            <Label text="결제금액" />
          </SimpleItem>
          <SimpleItem
            dataField="Diff"
            editorType="dxNumberBox"
            editorOptions={{
              readOnly: true,
              showSpinButtons: false,
              min: 0,
              format: '#,##0',
            }}
          >
            <Label text="차이" />
          </SimpleItem>
          <SimpleItem dataField="Remark" editorType="dxTextBox">
            <Label text="비고" location="top" />
          </SimpleItem>
        </GroupItem>
        <GroupItem colCount={2}>
          <ButtonItem>
            <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="확정"></ButtonOptions>
          </ButtonItem>
          <ButtonItem horizontalAlignment="left">
            <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
          </ButtonItem>
        </GroupItem>
      </Form>
    </form>
  );
});

export default CheckSalesTaxinvoiceForm;
