import React from 'react';
import moment from 'moment';
import { Column, Selection, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const SeasonTicket = props => {
  const { setSRowdata, seasonTicketRef, dataSource } = props;
  const onSelectionChanged = () => {
    const rowdata = seasonTicketRef.current.instance.getSelectedRowsData();
    setSRowdata(rowdata);
  };

  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };
  return (
    <>
      <CommonDataGrid
        gridRef={seasonTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        loadPanel={{ enabled: true }}
        hoverStateEnabled={true}
        height={500}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소요청 정기권.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={150} />
        <Column dataField={'ApprovedDate'} caption={'매출일(확정일)'} customizeText={customizeText} width={100} />
        <Column dataField={'PaymentDate'} caption={'결제일'} customizeText={customizeText} width={100} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={100} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={100} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} width={120} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={100} />
        <Column dataField={'FromDate'} caption={'시작일'} width={100} />
        <Column dataField={'ToDate'} caption={'종료일'} width={100} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={120} />
        <Column dataField={'PaymentCodeName'} caption={'결제유형'} width={90} />
        {/* <Column dataField={'PaymentTypeName'} caption={'매출유형'} /> */}
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} width={90} />
        <Column dataField={'MemberName'} caption={'고객명'} width={90} />
        <Column dataField={'Remark'} caption={'비고'} width={100} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={90} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={90} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={100} />
        <Column dataField={'CardAgencyListName'} caption={'결제사'} />
        <Summary>
          <TotalItem column="IssueCreditCard" summaryType="count" displayFormat="총 {0}건" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(SeasonTicket);
