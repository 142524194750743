import React, { useCallback, useImperativeHandle, useState } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';

import { ticket, error } from 'api';
import notify from 'devextreme/ui/notify';

const MultEditForm = React.forwardRef((props, ref) => {
  const { selectMemberType, setPopup, refresh, setLoadPanelVisible } = props;
  const [formData, setFormData] = useState({});
  useImperativeHandle(ref, () => ({
    open,
  }));
  const open = TicketID => {
    setFormData({ TicketID: TicketID, MemberType: 'A', SalesPrice: '0' });
  };
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { TicketID, MemberType, SalesPrice } = formData;
      setLoadPanelVisible(true);
      await ticket
        .updateSeasonTicketIssueHistoryMulti({
          TicketID,
          MemberType,
          SalesPrice,
          RegisterUser: '',
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '수정 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            refresh();
            onClose();
          } else {
            // error
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [formData],
  );

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setFormData({ TicketID: [], MemberType: '', SalesPrice: '' });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="금액 수정">
            <SimpleItem
              dataField="MemberType"
              editorType="dxSelectBox"
              editorOptions={{
                items: selectMemberType,
                displayExpr: 'text',
                valueExpr: 'code',
              }}
            >
              <Label text="정기권 유형" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="SalesPrice"
              editorType="dxNumberBox"
              colSpan={2}
              helpText="숫자만 입력가능합니다."
              editorOptions={{
                showSpinButtons: true,
                step: 1000,
                min: 0,
                format: '#,##0 원',
              }}
            >
              <Label text="결제금액" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'80%'} type={'default'} useSubmitBehavior={true} text="확인"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'80%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default MultEditForm;
