import React, { useCallback, useState, useImperativeHandle, useEffect, useRef } from 'react';
import Form, { SimpleItem, GroupItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { Column, Summary, TotalItem, Editing } from 'devextreme-react/data-grid';
import { salesPayment } from 'api';
import { cellStatus } from './common/common';
import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import './partial-payment-form.scss';

const PartialPaymentForm = React.forwardRef((props, ref) => {
  const { setIsOpenbyPartialPayment, setLoadPanelVisible, tRowdata, cRowdata, date, costCenter, refresh, setStatus } =
    props;
  const [formData, setFormData] = useState({
    SaleTotal: 0,
    PaymentTotal: 0,
    SalesType: '',
    PaymentType: '',
    Diff: 0,
    CMSPaymentKeyID: '',
    TaxInvoiceKeyID: '',
  });

  const [tdata, setTData] = useState([]);
  const tGridRef = useRef({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  const CheckSaveData = () => {
    if (tGridRef.current && tGridRef.current.instance) tGridRef.current.instance.saveEditData();

    const TaxInvoiceKeyID = [];

    if (tRowdata && tRowdata.length > 0) {
      tRowdata.forEach(s => {
        TaxInvoiceKeyID.push(s.KeyID + ':' + s.PaymentPrice);
      });
    }
    // 합계 계산후 form 반영
    setFormData({
      ...formData,
      TaxInvoiceKeyID: TaxInvoiceKeyID.length > 0 ? TaxInvoiceKeyID.join(';') : '',
      // SaleTotal: saleTotal,
      // Diff: saleTotal - formData.PaymentTotal,
    });
  };
  // 팝업이 open되면 매출금액, 결제금액, 차이 계산
  const open = (tRowdata, cRowdata) => {
    const CMSPaymentKeyID = [];
    const TaxInvoiceKeyID = [];
    let paymentTotal = 0;
    let saleTotal = 0;
    let remainpaymentTotal = 0;
    if (cRowdata && cRowdata.length > 0) {
      cRowdata.forEach(c => {
        paymentTotal += c.TransactionAmount;
        CMSPaymentKeyID.push(c.KeyID);
      });
    }
    remainpaymentTotal = paymentTotal;

    if (tRowdata && tRowdata.length > 0) {
      tRowdata.forEach(s => {
        saleTotal += s.RemainAmount;
        s.PaymentPrice = remainpaymentTotal > 0 ? Math.min(s.RemainAmount, remainpaymentTotal) : 0;
        remainpaymentTotal = remainpaymentTotal - s.RemainAmount;
        TaxInvoiceKeyID.push(s.KeyID + ':' + s.PaymentPrice);
        // TaxInvoiceKeyID.push(s.KeyID);
      });
      setTData(tRowdata);
    }

    setFormData({
      SaleTotal: saleTotal,
      PaymentTotal: paymentTotal,
      Diff: saleTotal - paymentTotal,
      TaxInvoiceKeyID: TaxInvoiceKeyID.length > 0 ? TaxInvoiceKeyID.join(';') : '',
      CMSPaymentKeyID: CMSPaymentKeyID.length > 0 ? CMSPaymentKeyID.join(';') : '',
    });
  };

  // 확정 클릭시
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { SaleTotal, TaxInvoiceKeyID, CMSPaymentKeyID, Diff, Remark } = formData;
      if (Diff <= 0) {
        alert('결제금액이 매출금액보다 적어야 합니다.');
      } else {
        const salesMonth = date.replace('-', '');
        setLoadPanelVisible(true);
        await salesPayment
          .confirmAccountInfoPartialBtoB({
            CostCenter: costCenter,
            SalesMonth: salesMonth,
            SalesPrice: SaleTotal,
            TaxInvoiceKeyID,
            CMSPaymentKeyID,
            Remark,
            // RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            setStatus('O');
            if (res.isOk) {
              notify(
                {
                  message: '확정되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              //닫기 및 전체 그리드 리프레쉬
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: JSON.parse(res.error.detail).sqlMessage,
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },
    [formData, costCenter, date, refresh],
  );

  // 닫기
  const onClose = () => {
    setIsOpenbyPartialPayment(false);
    setTData([]);
    if (tGridRef.current.instance) {
      tGridRef.current.instance.refresh();
      tGridRef.current.instance.clearFilter();
    }
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onChangesChange = e => {
    if (e.length > 0) {
      CheckSaveData();
    }
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            formData={formData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption="매출/결제 내역 부분대사" alignItemLabels colCount={5}>
              <GroupItem colSpan={4}>
                <GroupItem>
                  <CommonDataGrid
                    gridRef={tGridRef}
                    className={'dx-card wide-card'}
                    dataSource={tdata}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr="KeyID"
                    height={200}
                    onCellPrepared={onCellPrepared}
                    isHeader={true}
                    isExcel={false}
                    headerBefore={[<span>{'매출 > 세금계산서'}</span>]}
                  >
                    <Editing
                      mode="cell"
                      allowAdding={false}
                      allowDeleting={false}
                      allowUpdating={true}
                      onChangesChange={onChangesChange}
                    />
                    <Column allowEditing={false} dataField={'SalesDate'} caption={'발행요청일'} width={100} />
                    <Column allowEditing={false} dataField={'CustomerName'} caption={'사업자명'} width={120} />
                    <Column
                      allowEditing={false}
                      dataField={'CustomerCorpRegNumber'}
                      caption={'사업자번호'}
                      width={100}
                    />
                    <Column allowEditing={false} dataField={'StatusName'} caption={'매출상태'} width={'100'} />
                    <Column
                      allowEditing={false}
                      dataField={'SalesPrice'}
                      caption={'금액'}
                      width={120}
                      format="#,##0 원"
                    />
                    <Column allowEditing={false} dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                    <Column allowEditing={true} dataField={'PaymentPrice'} caption={'반제금액'} format="#,##0 원" />
                    <Column allowEditing={false} dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
                    <Column allowEditing={false} dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
                    <Column allowEditing={false} dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
                    <Column allowEditing={false} dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
                    <Column allowEditing={false} dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
                    <Column allowEditing={false} dataField={'SalesCount'} caption={'품목 수'} width={80} />
                    <Column
                      allowEditing={false}
                      dataField={'TaxPrice'}
                      caption={'부가세'}
                      width={80}
                      format="#,##0 원"
                    />
                    <Column
                      allowEditing={false}
                      dataField={'UnitPrice'}
                      caption={'공급가액'}
                      width={80}
                      format="#,##0 원"
                    />
                    <Column allowEditing={false} dataField={'CustomerCode'} caption={'회사코드'} width={120} />
                    <Column
                      allowEditing={false}
                      dataField={'CustomerManagerName'}
                      caption={'매입처 담당자명'}
                      width={100}
                    />
                    <Column allowEditing={false} dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
                    <Column allowEditing={false} dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
                    <Column allowEditing={false} dataField={'RegisterUser'} caption={'등록자'} width={80} />
                    <Column allowEditing={false} dataField={'RegisterDate'} caption={'등록일'} width={120} />
                    <Column allowEditing={false} dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} />
                    <Column allowEditing={false} dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
                    <Summary>
                      <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem
                        column="RemainAmount"
                        summaryType="sum"
                        valueFormat="#,##0 원"
                        displayFormat="합계: {0}"
                      />
                      <TotalItem column="PaymentPrice" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem
                        column="PaymentPrice"
                        summaryType="sum"
                        valueFormat="#,##0 원"
                        displayFormat="합계: {0}"
                      />
                    </Summary>
                  </CommonDataGrid>
                </GroupItem>

                <SimpleItem itemType="empty" cssClass="division" />

                <GroupItem>
                  <CommonDataGrid
                    // gridRef={gridRef} 엑셀기능 필요시 생성
                    className={'dx-card wide-card'}
                    dataSource={cRowdata}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr="KeyID"
                    height={200}
                    onCellPrepared={onCellPrepared}
                    isHeader={true}
                    isExcel={false}
                    headerBefore={[<span>{'결제 > CMS 사'}</span>]}
                  >
                    <Column
                      allowEditing={false}
                      dataField={'TransactionDate'}
                      caption={'입금시간'}
                      customizeText={customizeText}
                    />
                    <Column allowEditing={false} dataField={'StatusName'} caption={'상태'} />
                    <Column allowEditing={false} dataField={'TransactionSummary'} caption={'Summary'} />
                    <Column
                      allowEditing={false}
                      dataField={'TransactionAmount'}
                      caption={'입금금액'}
                      format="#,##0 원"
                    />
                    <Column allowEditing={false} dataField={'BankName'} caption={'거래은행'} />
                    <Column allowEditing={false} dataField={'Branch'} caption={'Branch'} />
                    <Column allowEditing={false} dataField={'GiroCode'} caption={'지로'} />
                    <Column allowEditing={false} dataField={'ExtraField1'} caption={'비고'} />
                    <Column allowEditing={false} dataField={'BankAccountNo'} caption={'계좌'} />
                    <Summary>
                      <TotalItem column="TransactionAmount" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem
                        column="TransactionAmount"
                        summaryType="sum"
                        valueFormat="#,##0 원"
                        displayFormat="합계: {0}"
                      />
                    </Summary>
                  </CommonDataGrid>
                </GroupItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  dataField="SaleTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="매출금액" location="top" />
                </SimpleItem>
                <SimpleItem
                  dataField="PaymentTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="결제금액" location="top" />
                </SimpleItem>
                <SimpleItem
                  dataField="Diff"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="차이" location="top" />
                </SimpleItem>
                <SimpleItem dataField="Remark" editorType="dxTextBox">
                  <Label text="비고" location="top" />
                </SimpleItem>
                <GroupItem>
                  <ButtonItem>
                    <ButtonOptions type={'default'} useSubmitBehavior={true} text="확정"></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
});

export default React.memo(PartialPaymentForm);
