import React, { useState, useImperativeHandle, useRef, useCallback } from 'react';
import { Popup } from 'devextreme-react/popup';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';

const RemarkRopup = React.forwardRef((props, ref) => {
  const { title, setRemark, cRowdata, actionType } = props;
  const formRef = useRef({});
  const formData = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [dateValue, setDateValue] = useState({
    ConfirmDate: moment().format('YYYY-MM-DD'),
  });

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
    close,
  }));

  const close = () => {
    setIsPopup(false);
    setDateValue({ ConfirmDate: moment().format('YYYY-MM-DD') });
  };

  const open = () => {
    setIsPopup(true);
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      setIsPopup(false);
      if (actionType === 'confirm') {
        setRemark(formData.current.Remark, cRowdata, dateValue.ConfirmDate);
      } else if (actionType === 'reject') {
        setRemark(formData.current.Remark, cRowdata);
      } else if (actionType === 'review') {
        setRemark(formData.current.Remark, cRowdata);
      } else if (actionType === 'clear') {
        setRemark(dateValue.ConfirmDate);
      }
      formRef.current.instance.resetValues();
      setDateValue({ ConfirmDate: moment().format('YYYY-MM-DD') });
    },
    [cRowdata, dateValue],
  );

  const handleConfirmDate = e => {
    setDateValue({ ConfirmDate: e.value });
  };

  return (
    <Popup
      onHiding={close}
      visible={isPopup}
      showTitle={false}
      width="22%"
      height={actionType === 'confirm' ? 440 : 375}
    >
      <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
        <form className="add-sales-master-form" onSubmit={onSubmit}>
          <Form
            ref={formRef}
            formData={formData.current}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={close}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={title}>
              <SimpleItem
                dataField="selection"
                editorType="dxTextBox"
                editorOptions={{ readOnly: true, value: `${cRowdata.length} 건` }}
              >
                <Label text="선택건수" location="top" visible={true} />
              </SimpleItem>
              {(actionType === 'confirm' || actionType === 'clear') && (
                <SimpleItem dataField="ConfirmDate">
                  <DateBox
                    type="date"
                    displayFormat="yyyy-MM-dd"
                    stylingMode="outlined"
                    dropDownButtonTemplate={'dropDownButton'}
                    useMaskBehavior={true}
                    icon={true}
                    dataField="FromDate"
                    onValueChanged={handleConfirmDate}
                    value={dateValue.ConfirmDate}
                  />
                  <Label text={actionType === 'clear' ? '대사일자' : '확정일'} location="top" />
                </SimpleItem>
              )}
              {actionType !== 'clear' && (
                <SimpleItem dataField="Remark" editorType="dxTextArea">
                  <Label text={'비고'} location="top" visible={true} />
                </SimpleItem>
              )}
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'확인'}></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={close}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </Popup>
  );
});

export default RemarkRopup;
