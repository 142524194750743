import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import GeneralTicket from './component/generalTicket';
import SeasonTicket from './component/seasonTicket';
import EtcSales from './component/etcSales';
import BtoBSales from './component/btobSales';

const CashbillDetailSales = props => {
  const { billID, useCheck, setCheckSales} = props;
  const [generalDataSource, setGeneralDataSource] = useState([]);
  const [seasonDataSource, setSeasonDataSource] = useState([]);
  const [etcDataSource, setEtcDataSource] = useState([]);
  const [b2bDataSource, setB2bDataSource] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const result = await axios.post(`aggregation/getCashbillSalesList`, {
          BillID: billID,
        });
        setGeneralDataSource(result.data[0]);
        setSeasonDataSource(result.data[1]);
        setEtcDataSource(result.data[2]);
        setB2bDataSource(result.data[3]);
        return result.data;
      } catch (e) {
        const { type, title, status, detail, instance } = e.response.data;
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    })();
  }, [billID]);

  return (
    <React.Fragment>
      <div style={{ paddingTop: '5px' }}>
        <TabPanel>
          <Item title="일반권" badge={generalDataSource.length}>
            <GeneralTicket dataSource={generalDataSource} />
          </Item>
          <Item title="정기권" badge={seasonDataSource.length}>
            <SeasonTicket dataSource={seasonDataSource} />
          </Item>
          <Item title="기타" badge={etcDataSource.length}>
            <EtcSales dataSource={etcDataSource} />
          </Item>
          {/* <Item title="B2B" badge={b2bDataSource.length}>
            <BtoBSales dataSource={b2bDataSource} />
          </Item> */}
        </TabPanel>
      </div>
    </React.Fragment>
  );
};

export default CashbillDetailSales;
