import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Column, Pager, Selection, HeaderFilter, Scrolling, Summary, TotalItem } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import './season-ticket-mng.scss';
import moment from 'moment';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { confirm } from 'devextreme/ui/dialog';
import { ticket, error } from 'api';
import { SelectBox } from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import ScrollView from 'devextreme-react/scroll-view';
import CommonDataGrid from 'common/default-data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';

import SeasonTicketMngForm from './components/season-ticket-mng-form';
import SeasonTicketMngEditForm from './components/season-ticket-mng-edit-form';
import ExtendTicketForm from './components/extend-ticket-form';
import MultiEditTicketForm from './components/multi-edit-ticket-form';

// 정기권 회원관리 + 발행관리 통합
import SeasonTicketMemberForm from './components/season-ticket-mng-member-form';

const SeasonTicketMng = () => {
  const [text, setText] = useState('');
  const [searchParam, setSearchParam] = useState({
    SearchText: '',
    MemberText: '',
  });
  const [memberParam, setMemberParam] = useState({
    CostCenter: '',
    CostCenterName: '',
    MemberText: '',
    SearchGroupCode: '*',
  });
  const [costcenters, setCostcenters] = useState([]);
  const [members, setMembers] = useState();
  const [selectMember, setSelectMember] = useState({
    MemberID: '',
    VehicleRegistration: '',
  });
  const [tickets, setTickets] = useState();
  const [popup, setPopup] = useState({
    save: false,
    visible: false,
  });

  const [isRefresh, setIsRefresh] = useState(false);
  // const [isUpdate, setIsUpdate] = useState(false);
  // const [formData, setFormData] = useState();

  // 팝업에 있던 select 부모에서 한번만 호출
  const [selectMemberType, setSelectMemberType] = useState([]);
  const [selectPayType, setSelectPayType] = useState([]);
  const [selectPayStatus, setSelectPayStatus] = useState([]);

  // 정기권 이력 상태구분 추가
  const [selectTicketStatus, setSelectTicketStatus] = useState([{ codeDesc: '전체', code: '' }]);
  const [ticketStatus, setTicketStatus] = useState({ codeDesc: '전체', code: '' });

  // 확정전만 표시 체크박스
  const [confirmCheck, setConfirmCheck] = useState('N');
  const [ticketConfirmStatus, setTicketConfirmStatus] = useState([]);

  //정기권 연장
  const [isOpenbyExtendSTicket, setIsOpenbyExtendSTicket] = useState(false);
  const [ticketIDs, setTicketIDs] = useState([]);

  // ref 추가 (팝업 제어)
  const seasonTicketFormRef = useRef({});
  const seasonTicketEditFormRef = useRef({});

  // Commongrid ref 추가
  const membergridRef = useRef({});
  const ticketgridRef = useRef({});

  // 정기권 일괄수정
  const [ticketMemberNames, setTicketMemberNames] = useState([]);
  const [isOpenbyMultiEdit, setIsOpenbyMultiEdit] = useState(false);

  // 검색조건 추가
  const [memberText, setMemberText] = useState('');

  // 그룹 필터 기능 추가
  const [groupTypeItems, setGroupTypeItems] = useState([]);

  // selectRef
  const selectRef = useRef({});

  // 정기권 회원관리 + 발행관리 통합
  const [groupType, setGroupType] = useState([]);

  const [selectItemCode, setSelectItemCode] = useState({
    CostCenterCode: '',
    CostCenterName: '',
  });
  // 정기권 회원관리 + 발행관리 통합
  const [isUpdate, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    CarType: '',
    CostCenterCode: '',
    CostCenterName: '',
    EmailAddress: '',
    GroupCode: '',
    GroupName: '',
    MemberDivision: '',
    MemberDivisionName: '',
    MemberID: '',
    MemberName: '',
    MemberStatusName: '',
    MemberType: '',
    MemberTypeName: '',
    MobliePhoneNo: '',
    RegisterDate: '',
    RegisterUser: '',
    Status: '',
    TagRentPrice: 0,
    TelephoneNo: '',
    UseTag: '',
    UseTagName: '',
    VehicleRegistration: '',
  });
  const [memberPopup, setMemberPopup] = useState({
    save: false,
    visible: false,
  });
  const [editPopup, setEditPopup] = useState({
    save: false,
    visible: false,
  });

  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchParkingInfoByCostCenterWithTicket(searchParam);
      if (result.isOk) {
        setCostcenters(result.data);
        setMemberParam({
          ...memberParam,
          CostCenter: result.data[0].CostCenterCode,
          CostCenterName: result.data[0].CostCenterName,
        });
        setSelectMember({ ...selectMember, MemberID: '', VehicleRegistration: '' });
        setSelectItemCode({
          CostCenterCode: result.data.length > 0 ? result.data[0].CostCenterCode : '',
          CostCenterName: result.data.length > 0 ? result.data[0].CostCenterName : '',
        });
      }
    })();
  }, [searchParam]);

  useEffect(() => {
    if (costcenters) {
      (async () => {
        const result = await ticket.searchSeasonTicketMemberMaster({
          CostCenter: memberParam.CostCenter,
          MemberText: memberParam.MemberText,
          SearchGroupCode: memberParam.SearchGroupCode,
        });
        if (result.isOk) {
          setMembers(result.data);
        }
      })();
    }
  }, [memberParam, isRefresh]);

  useEffect(() => {
    if (members) {
      (async () => {
        const result = await ticket.searchSeasonTicketIssueHistory({
          MemberID: selectMember.MemberID,
          Status: ticketStatus.code,
          OnlyWait: confirmCheck,
        });
        if (result.isOk) {
          setTickets(result.data);
        }
      })();
    }
  }, [selectMember, isRefresh, ticketStatus, confirmCheck]);

  // 팝업 select items 부모에서 한번만 호출 후 저장해서 팝업으로 보내줍니다.
  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberType', 'PaymentType', 'PaymentStatus', 'TicketStatus'],
      });
      const MemberType = [];
      const PaymentType = [];
      const PaymentStatus = [];
      const TicketStatus = [{ codeDesc: '전체', code: '' }];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentType') {
            PaymentType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentStatus') {
            PaymentStatus.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'TicketStatus') {
            TicketStatus.push({
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectMemberType(MemberType);
      setSelectPayType(PaymentType);
      setSelectPayStatus(PaymentStatus);
      setSelectTicketStatus(TicketStatus);
    })();
  }, []);

  // 회원 그룹 선택하기
  useEffect(() => {
    if (memberParam.CostCenter) {
      (async () => {
        await ticket
          .searchSeasonTicketMemberGroup({
            CostCenterCode: memberParam.CostCenter,
          })
          .then(res => {
            if (res.isOk) {
              setGroupType(res.data);
              const groupFree = { codeDesc: '(그룹없음)', code: '' };
              const group = res.data.map(a => {
                return {
                  codeDesc: a.GroupName,
                  code: a.GroupCode,
                };
              });
              const rawData = [groupFree, ...group];
              const result = [
                {
                  key: '전체',
                  items: [{ code: '*', codeDesc: '전체' }],
                },
                {
                  key: '회원그룹',
                  items: rawData,
                },
              ];
              setGroupTypeItems(result);
            }
          });
      })();
    } else {
      setGroupType([]);
      setGroupTypeItems([]);
    }
  }, [memberParam]);

  const onValueChanged = e => {
    setText(e.value);
  };

  const onSearch = () => {
    setSearchParam({
      SearchText: text.trim(),
      MemberText: memberText.trim(),
    });
    setMemberParam({
      ...memberParam,
      MemberText: memberText.trim(),
    });
  };

  const onSelectionChanged = e => {
    if (e.selectedRowsData.length > 0) {
      // 정기권 회원관리 + 발행관리 통합
      setSelectItemCode({
        CostCenterCode: e.selectedRowsData[0].CostCenterCode,
        CostCenterName: e.selectedRowsData[0].CostCenterName,
      });
      setMemberParam({
        ...memberParam,
        CostCenter: e.selectedRowsData[0].CostCenterCode,
        CostCenterName: e.selectedRowsData[0].CostCenterName,
        MemberText: memberText.trim(),
        SearchGroupCode: '*',
      });
      selectRef.current.instance.reset();
    }
  };

  const onMemberSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    if (selectRows.length > 0) {
      const memberIds = [];
      const vehicleRegistration = [];
      selectRows.forEach(r => {
        memberIds.push(r.MemberID);
        vehicleRegistration.push(r.VehicleRegistration);
      });
      setSelectMember({
        MemberID: memberIds,
        VehicleRegistration: vehicleRegistration.join(','),
      });
    } else {
      setTickets([]);
      setSelectMember({
        MemberID: '',
        VehicleRegistration: '',
      });
    }
  };

  const onTicketSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    const ticketIds = [];
    const ticketConfirmStatus = [];
    const ticketMemberNames = [];
    if (selectRows && selectRows.length > 0) {
      selectRows.forEach(r => {
        ticketIds.push(r.TicketID);
        ticketConfirmStatus.push(r.isEditable);
        ticketMemberNames.push(r.MemberName);
      });
    }
    setTicketIDs(ticketIds);
    setTicketConfirmStatus(ticketConfirmStatus);
    setTicketMemberNames(ticketMemberNames);
  };

  const editCellRender = useCallback(
    e => {
      const data = e.data;
      if (data.isEditable === 'Y') {
        return (
          <Button
            icon="edit"
            width={50}
            type="normal"
            stylingMode="contained"
            onClick={() => showEditPopup({ ...e.data, CostCenterName: memberParam.CostCenterName })}
          />
        );
      }
    },
    [memberParam],
  );

  // 정기권 발행
  const showPopup = data => {
    if (data.VehicleRegistration) {
      setPopup({ save: false, visible: !popup.visible });
      seasonTicketFormRef.current.open(data);
    } else {
      alert('차량번호를 선택해주세요');
    }
  };

  // 정기권 개별수정
  const showEditPopup = data => {
    setEditPopup({ save: false, visible: !popup.visible });
    seasonTicketEditFormRef.current.open(data);
  };
  // 정기권 연장
  const showExtendSTicketPopup = () => {
    if (ticketIDs.length === 0) {
      alert('연장할 정기권을 선택해 주세요');
    } else {
      setIsOpenbyExtendSTicket(true);
    }
  };

  // 정기권 발행 취소
  const cancelSTicket = () => {
    if (ticketIDs.length === 0) {
      return alert('정기권을 선택해 주세요.');
    }
    const result = confirm('<i>발행된 정기권을 취소하시겠습니까?</i>', '취소');
    result.then(dialogResult => {
      if (dialogResult) {
        ticket
          .cancelSeasonTicketIssueHistory({
            TicketID: ticketIDs,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              // 발행 취소 완료
              notify(
                {
                  message: '발행 취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              setIsRefresh(!isRefresh);
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };

  // 정기권 발행 취소, 만료된 부분 cell style 변경
  const onCellPrepared = useCallback(({ data, cellElement }) => {
    if (data) {
      const { RemainDay } = data;
      const tr = cellElement.parentNode;
      if (RemainDay === '만료' && tr) {
        tr.classList.add('ticket-expiration');
      } else if (RemainDay.includes('취소') && tr) {
        tr.classList.add('ticket-cancel');
      }
    }
  }, []);

  const onClose = () => {
    setIsOpenbyExtendSTicket(false);
    setPopup({ ...popup, visible: false });
    setIsOpenbyMultiEdit(false);
    // 정기권 회원관리 + 발행관리 통합
    setMemberPopup({ ...memberPopup, visible: false });
  };

  const onSelectItemClick = e => {
    setTicketStatus(e.itemData);
  };

  const confirmTicket = () => {
    if (ticketIDs.length === 0) {
      return alert('정기권을 선택해 주세요.');
    }
    if (ticketConfirmStatus.includes('N')) {
      alert('이미 확정된 정기권이 포함되어 있습니다.');
    } else {
      const result = confirm('<i>선택된 정기권을 확정하시겠습니까?</i>', '확정');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          ticket
            .confirmSeasonTicketIssueHistory({
              TicketID: ticketIDs,
              RegisterUser: '',
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '발행 확정이 완료되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                setIsRefresh(!isRefresh);
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  // 정기권 일괄수정
  const showMultiEditPopup = () => {
    if (ticketIDs.length === 0) {
      alert('정기권을 선택해 주세요');
    } else if (ticketConfirmStatus.includes('N')) {
      alert('이미 확정된 정기권이 포함되어 있습니다.');
    } else {
      setIsOpenbyMultiEdit(true);
    }
  };

  const onCheckValueChanged = e => {
    e.value ? setConfirmCheck('Y') : setConfirmCheck('N');
  };

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsCount') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (
          options.component.isRowSelected(options.value.MemberID) ||
          options.component.isRowSelected(options.value.TicketID)
        ) {
          options.totalValue += 1;
        }
      }
    }
  };

  const onMemberValueChanged = e => {
    setMemberText(e.value);
  };

  const onClickButton = e => {
    const { code } = e.itemData;
    setMemberParam({
      ...memberParam,
      SearchGroupCode: code,
    });
  };

  // 정기권 회원관리 + 발행관리 통합
  const showMemberPopup = (type, data) => {
    setIsUpdate(type === 'update' ? true : false);
    setFormData(data);
    setMemberPopup({ save: false, visible: !popup.visible });
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  // const onOptionChanged = e => {
  //   if (e.name === 'columns' && e.value) {
  //     const str = e.fullName;
  //     str.includes('filterValues') && membergridRef.current.instance.clearSelection();
  //   }
  // };

  return (
    <>
      <div>
        <h2 className={'content-block'}>정기권 발행 관리 [ {memberParam.CostCenterName} ]</h2>
        <div className={'content-block'}>
          <div className="widget-container">
            <div
              className={'dx-card'}
              style={{ maxWidth: '42%', height: '70%', padding: '20px 20px', borderRadius: '5px' }}
            >
              <div className="dataGrid">
                <CommonDataGrid
                  // gridRef={pgvanRef} 엑셀 추가시 설정
                  className={'dx-card wide-card'}
                  dataSource={costcenters}
                  showBorders={true}
                  columnAutoWidth={true}
                  columnHidingEnabled={true}
                  hoverStateEnabled={true}
                  selectedRowKeys={[memberParam.CostCenter]}
                  onSelectionChanged={onSelectionChanged}
                  keyExpr="CostCenterCode"
                  height={200}
                  allowColumnReordering={true}
                  isHeader={true}
                  isExcel={false}
                  headerBefore={[
                    <TextBox
                      labelMode="static"
                      mode="search"
                      stylingMode="outlined"
                      placeholder="P코드/사업소명"
                      onValueChanged={onValueChanged}
                      onEnterKey={onSearch}
                      width={220}
                    />,
                    <TextBox
                      labelMode="static"
                      mode="search"
                      stylingMode="outlined"
                      placeholder="차량번호/회원명/휴대전화"
                      onValueChanged={onMemberValueChanged}
                      onEnterKey={onSearch}
                      width={200}
                    />,
                  ]}
                  headerAfter={[
                    <Button
                      icon="search"
                      text="조회"
                      width={80}
                      type="normal"
                      stylingMode="contained"
                      onClick={onSearch}
                    />,
                  ]}
                >
                  <HeaderFilter visible={true} />
                  <Selection mode="single" />
                  <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
                  {/* visible false이기 때문에 사업소 코드 제거 안함 */}
                  <Column
                    dataField={'CostCenterCode'}
                    width={120}
                    visible={false}
                    caption={'사업소 코드'}
                    hidingPriority={6}
                  />
                  <Column dataField={'HiParkingID'} caption={'P코드'} width={120} hidingPriority={5} />
                  <Column dataField={'CostCenterName'} width={140} caption={'사업소명'} hidingPriority={5} />
                  <Column dataField={'OperatingTypeName'} caption={'운영유형'} width={100} hidingPriority={1} />
                  <Column dataField={'TotalCnt'} caption={'총 회원'} hidingPriority={4} />
                  {/* <Column dataField={'NoGroupCnt'} caption={'개인회원'} hidingPriority={2} />
                  <Column dataField={'GroupCnt'} caption={'그룹회원'} hidingPriority={2} /> */}
                </CommonDataGrid>
              </div>

              <div className="dataGrid">
                <CommonDataGrid
                  gridRef={membergridRef}
                  className={'dx-card wide-card'}
                  dataSource={members}
                  showBorders={true}
                  columnAutoWidth={true}
                  hoverStateEnabled={true}
                  onSelectionChanged={onMemberSelectionChanged}
                  // onContentReady={onContentReady}
                  // onOptionChanged={onOptionChanged}
                  keyExpr="MemberID"
                  height={500}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  isExcel={true}
                  isFilterSelection
                  excelOptions={{
                    sheetName: 'Sheet',
                    fileName: '정기권 발행관리_회원목록.xlsx',
                  }}
                  isHeader={true}
                  headerBefore={[
                    <SelectBox
                      ref={selectRef}
                      labelMode="static"
                      placeholder="회원그룹을 선택해주세요."
                      dropDownButtonTemplate="dropDownButton"
                      stylingMode="outlined"
                      dataSource={groupTypeItems}
                      onItemClick={onClickButton}
                      valueExpr="code"
                      displayExpr="codeDesc"
                      width={220}
                      grouped={true}
                    />,
                    <TextBox
                      labelMode="static"
                      mode="search"
                      stylingMode="outlined"
                      placeholder="차량번호/회원명/휴대전화"
                      onValueChanged={onMemberValueChanged}
                      onEnterKey={onSearch}
                      width={200}
                    />,
                  ]}
                  headerAfter={[
                    <Button
                      icon="user"
                      text="신규"
                      width={80}
                      type="normal"
                      stylingMode="contained"
                      onClick={() => {
                        showMemberPopup('add', {
                          CostCenterName: selectItemCode.CostCenterName,
                          UseTag: 'N',
                          Status: 'A',
                        });
                      }}
                    />,
                    <Button
                      icon="plus"
                      text="발행"
                      width={80}
                      type="normal"
                      stylingMode="contained"
                      onClick={() => {
                        const count = selectMember.MemberID.length + '건';
                        showPopup({
                          VehicleRegistration: selectMember.VehicleRegistration,
                          CostCenterName: memberParam.CostCenterName,
                          PaymentStatus: 'A',
                          Count: count,
                          TaxYN: 0,
                        });
                      }}
                    />,
                  ]}
                >
                  <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
                  <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
                  <HeaderFilter visible={true} />
                  <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />
                  <Column dataField={'VehicleRegistration'} width={120} caption={'차량번호'} />
                  <Column dataField={'MemberName'} width={120} caption={'회원명'} />
                  <Column dataField={'GroupCode'} width={120} visible={false} alignment={'left'} />
                  <Column dataField={'GroupName'} caption={'회원그룹'} width={120} alignment={'left'} />
                  <Column dataField={'MemberStatusName'} width={120} caption={'회원상태'} />
                  <Column dataField={'MemberTypeName'} caption={'정기권유형'} />
                  <Summary calculateCustomSummary={calculateSelectedRow}>
                    <TotalItem
                      name="SelectedRowsCount"
                      summaryType="custom"
                      displayFormat="선택 {0}건"
                      showInColumn="VehicleRegistration"
                    />
                    <TotalItem column="MemberName" summaryType="count" displayFormat="총 {0}건" />
                  </Summary>
                </CommonDataGrid>
              </div>
            </div>
            <div className={'dx-card'} style={{ maxWidth: '57%', padding: '20px 20px', borderRadius: '5px' }}>
              <CommonDataGrid
                gridRef={ticketgridRef}
                width="100%"
                className={'dx-card wide-card ticket-history'}
                dataSource={tickets}
                keyExpr="TicketID"
                showBorders={true}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                onSelectionChanged={onTicketSelectionChanged}
                allowColumnResizing={true}
                height={750}
                onCellPrepared={onCellPrepared}
                allowColumnReordering={true}
                isExcel={true}
                isFilterSelection
                excelOptions={{
                  sheetName: 'Sheet',
                  fileName: '정기권 발행관리_이력목록.xlsx',
                }}
                isHeader={true}
                headerBefore={[
                  <SelectBox
                    dataSource={selectTicketStatus}
                    defaultValue={selectTicketStatus[0].code}
                    labelMode="static"
                    dropDownButtonTemplate="dropDownButton"
                    stylingMode="outlined"
                    width={130}
                    displayExpr="codeDesc"
                    valueExpr="code"
                    onItemClick={onSelectItemClick}
                  />,
                  <CheckBox defaultValue={false} text="확정전만 표시" onValueChanged={onCheckValueChanged} />,
                ]}
                headerAfter={[
                  <Button icon="check" width={80} text="확정" onClick={() => confirmTicket()} />,
                  <Button
                    icon="edit"
                    text="수정"
                    width={80}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => showMultiEditPopup()}
                  />,
                  <Button
                    icon="minus"
                    text="취소"
                    width={80}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => cancelSTicket()}
                  />,
                  <Button
                    icon="plus"
                    text="연장"
                    width={80}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => showExtendSTicketPopup()}
                  />,
                  <Button
                    icon="refresh"
                    type="normal"
                    stylingMode="contained"
                    onClick={() => {
                      setIsRefresh(!isRefresh);
                    }}
                  />,
                ]}
              >
                <HeaderFilter visible={true} />
                <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
                <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
                <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />
                <Column
                  dataField={'edit'}
                  width={50}
                  cellRender={editCellRender}
                  alignment={'center'}
                  allowFiltering={false}
                  allowExporting={false}
                />
                <Column dataField={'isEditable'} visible={false} />
                <Column dataField={'VehicleRegistration'} width={100} caption={'차량번호'} />
                <Column dataField={'FromDate'} caption={'시작일'} width={100} alignment={'left'} />
                <Column dataField={'ToDate'} caption={'종료일'} width={100} alignment={'left'} />
                <Column
                  dataField={'SalesPrice'}
                  caption={'판매금액'}
                  width={100}
                  alignment={'left'}
                  format="#,##0 원"
                />
                <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={100} alignment={'left'} />
                <Column dataField={'SalesStatusName'} caption={'매출상태'} width={100} alignment={'left'} />
                <Column dataField={'AccountingDate'} caption={'대사일'} width={100} alignment={'left'} />
                <Column dataField={'MemberName'} caption={'회원명'} width={80} alignment={'left'} />
                <Column dataField={'GroupName'} caption={'회원그룹'} width={100} alignment={'left'} />
                <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={150} />
                <Column dataField={'GroupCode'} width={80} visible={false} alignment={'left'} />
                <Column dataField={'RemainDay'} caption={'남은일수'} width={100} alignment={'left'} />
                <Column dataField={'MemberTypeName'} caption={'정기권유형'} width={110} alignment={'left'} />
                {/* <Column dataField={'PaymentStatusName'} caption={'결제상태'} width={100} alignment={'left'} /> */}
                <Column dataField={'SalesDate'} caption={'판매일'} width={80} alignment={'left'} />
              <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
                <Column
                  dataField={'RegisterDate'}
                  caption={'등록일시'}
                  width={100}
                  alignment={'left'}
                  customizeText={customizeText}
                />
                <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'left'} />
                <Column dataField={'Remark'} caption={'비고'} width={80} alignment={'left'} />
                <Column dataField={'TicketID'} visible={false} />
                <Summary calculateCustomSummary={calculateSelectedRow}>
                  <TotalItem
                    name="SelectedRowsCount"
                    summaryType="custom"
                    displayFormat="선택 {0}건"
                    showInColumn="FromDate"
                  />
                  <TotalItem showInColumn="ToDate" summaryType="count" displayFormat="총 {0}건" />
                </Summary>
              </CommonDataGrid>
            </div>
          </div>
        </div>
        {/* 정기권 수정 팝업 */}
        <Popup
          onHiding={onClose}
          visible={editPopup.visible}
          showCloseButton={true}
          showTitle={false}
          width="40%"
          height={550}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <SeasonTicketMngEditForm
                ref={seasonTicketEditFormRef}
                setPopup={setEditPopup}
                setIsRefresh={setIsRefresh}
                isRefresh={isRefresh}
                selectMember={selectMember}
                selectMemberType={selectMemberType}
                selectPayType={selectPayType}
                selectPayStatus={selectPayStatus}
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </ScrollView>
        </Popup>
        {/* 정기권 연장 팝업 */}
        <Popup onHiding={onClose} visible={isOpenbyExtendSTicket} showTitle={false} width="22%" height={320}>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <ExtendTicketForm
              setIsOpenbyExtendSTicket={setIsOpenbyExtendSTicket}
              ticketIDs={ticketIDs}
              selectPayType={selectPayType}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </Popup>
        {/* 정기권 일괄수정 팝업 */}
        <Popup onHiding={onClose} visible={isOpenbyMultiEdit} showTitle={false} width="22%" height={370}>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <MultiEditTicketForm
              setIsOpenbyMultiEdit={setIsOpenbyMultiEdit}
              setIsRefresh={setIsRefresh}
              ticketIDs={ticketIDs}
              isRefresh={isRefresh}
              selectMemberType={selectMemberType}
              ticketMemberNames={ticketMemberNames}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </Popup>
        {/* 정기권 회원 팝업 */}
        <Popup
          onHiding={onClose}
          visible={memberPopup.visible}
          showCloseButton={true}
          showTitle={false}
          width={'70%'}
          height={650}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <SeasonTicketMemberForm
                setPopup={setMemberPopup}
                setIsRefresh={setIsRefresh}
                formData={formData}
                selectItemCode={selectItemCode}
                selectPayType={selectPayType}
                selectPayStatus={selectPayStatus}
                groupType={groupType}
                popup={memberPopup}
                isUpdate={isUpdate}
                isRefresh={isRefresh}
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </ScrollView>
        </Popup>
        {/* 정기권 발행 팝업 */}
        <Popup
          onHiding={onClose}
          visible={popup.visible}
          showCloseButton={true}
          showTitle={false}
          width="40%"
          height={600}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <SeasonTicketMngForm
                ref={seasonTicketFormRef}
                setPopup={setPopup}
                setIsRefresh={setIsRefresh}
                isRefresh={isRefresh}
                selectMember={selectMember}
                selectMemberType={selectMemberType}
                selectPayType={selectPayType}
                selectPayStatus={selectPayStatus}
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </ScrollView>
        </Popup>
        <PSLoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '.content' }}
          onHiding={hideLoadPanel}
          visible={loadPanelVisible}
        />
      </div>
    </>
  );
};

export default SeasonTicketMng;
