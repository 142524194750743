import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { makeTaxInvoiceStatusCellRender, notifyS } from 'common/popup/sales-common-util';
import { TagBox } from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { common, error } from 'api';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import jQuery from 'jquery';
import $ from 'jquery';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const AccountingPaymentAdminReport = () => {
  const maingridRef = useRef({});
  const invoicegridRef = useRef({});
  //검색
  const [dataSource, setDataSource] = useState({});
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  // 조회할 사업소 목록 array
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [seasonticktOnleyCheck, setSeasonticktOnleyCheck] = useState('N');
  // const [byPaymentDate, setPaymentDateCheck] = useState('N');

  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onClickButton = async () => {
    try {
      setLoadPanelVisible(true);
      const result = await axios.post(`aggregation/history/getAllAccountingReport`, {
        ...dateValue,
        CostCenterCode: '*',
        LoginID: userId,
        onlySeasonTicket: seasonticktOnleyCheck,
      });
      setDataSource(result.data[0]);
      setLoadPanelVisible(false);
    } catch (e) {
      setLoadPanelVisible(false);
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onSeasonTicketCheckValueChanged = e => {
    e.value ? setSeasonticktOnleyCheck('Y') : setSeasonticktOnleyCheck('N');
  };

  const onCellPreparedInvoice = useCallback(e => {
    cellStatusInvoice(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'InvoiceID';
  const mergeColumn = [
    'TaxInvoiceStatusName',
    'TaxIssueTypeName',
    'InvoiceID',
    'TaxSalesPrice',
    'TaxInvoiceNo',
    'TaxSaleDate',
    'TaxCustomerNo',
    'TaxCorpRegNumber',
    'TaxCustomerCorpName',
    'TaxCostCenterName',
    'TaxHiParkingID',
  ];

  const cellStatusInvoice = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };
  return (
    <div>
      <h2 className={'content-block detail-title'}>회계 매출 조회 (계산서 전체 반영)</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            gridRef={maingridRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={600}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '회계 매출 조회.xlsx',
            }}
            headerBefore={[
              <div className="label">매출일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              // <TagBox
              //   dataSource={costCenterType}
              //   showSelectionControls={false}
              //   columnAutoWidth={true}
              //   allowColumnResizing={true}
              //   maxDisplayedTags={2}
              //   displayExpr="CostCenterName"
              //   valueExpr="CostCenterCode"
              //   placeholder="조회할 사업소를 모두 선택해주세요"
              //   stylingMode="outlined"
              //   onSelectionChanged={onClickButton}
              //   searchEnabled={true}
              //   width={330}
              //   selectAllText={'전체'}
              //   multiline={false}
              // />,
            ]}
            headerAfter={[
              <CheckBox
                visible={true}
                defaultValue={false}
                text="정기권 조회    "
                onValueChanged={onSeasonTicketCheckValueChanged}
              />,

              <Button
                icon="search"
                text="조회"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={onClickButton}
              />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column dataField={'P코드'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'사업소명'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'수금일'} caption={'수금일'} alignment={'center'} />
            <Column dataField={'매출일'} caption={'매출일'} alignment={'center'} />
            <Column dataField={'원매출일'} caption={'원매출일'} alignment={'center'} />
            <Column dataField={'매출항목'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'매출항목상세'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'정상/반품'} caption={'정상/반품'} alignment={'center'} />
            <Column dataField={'수금구분'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'결제사'} caption={'결제사'} alignment={'center'} />
            <Column dataField={'매출수량'} caption={'매출수량'} alignment={'right'} />
            <Column dataField={'합계금액'} caption={'합계금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'대사날짜'} caption={'대사날짜'} alignment={'center'} />
            <Column dataField={'대사ID'} caption={'대사ID'} alignment={'center'} />

            <Column dataField={'매출대상기간From'} caption={'매출대상기간From'} alignment={'center'} />
            <Column dataField={'매출대상기간To'} caption={'매출대상기간To'} alignment={'center'} />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'차량/고객'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'SAP사업자코드'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'사업자번호'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'사업자명'} caption={'사업자명'} alignment={'left'} />
              {seasonticktOnleyCheck === 'N' ? <Column dataField={'업종'} caption={'업종'} alignment={'center'} /> : <></>}
              {seasonticktOnleyCheck === 'N' ? <Column dataField={'업태'} caption={'업태'} alignment={'center'} /> : <></>}        
           </Column>
            <Column caption={'세금계산서 정보'} alignment={'center'}>
              <Column
                dataField={'TaxInvoiceStatusName'}
                caption={'처리상태'}
                width={110}
                cellRender={taxInvoiceStatusCellRender}
              />
              <Column dataField={'계산서유형'} caption={'계산서유형'} alignment={'center'} />
              <Column dataField={'발행요청일'} caption={'발행요청일'} alignment={'center'} />
              <Column dataField={'과세유형'} caption={'과세유형'} alignment={'center'} />
              <Column dataField={'발행유형'} caption={'발행유형'} width={100} />
              <Column dataField={'HiDMS 계산서번호'} caption={'HiDMS 계산서번호'} alignment={'center'} />
              <Column dataField={'세금계산서번호'} caption={'세금계산서번호'} alignment={'center'} />
              <Column dataField={'계산서 SAP사업자코드'} caption={'계산서 SAP사업자코드'} alignment={'left'} />
              <Column dataField={'계산서 사업자번호'} caption={'계산서 사업자번호'} alignment={'left'} />
              <Column dataField={'계산서 사업자명'} caption={'계산서 사업자명'} alignment={'left'} />
              {seasonticktOnleyCheck === 'N' ? <Column dataField={'계산서 업종'} caption={'계산서 업종'} alignment={'center'} /> : <></>}
              {seasonticktOnleyCheck === 'N' ? <Column dataField={'계산서 업태'} caption={'계산서 업태'} alignment={'center'} /> : <></>}        
            </Column>

            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}

            <Column dataField={'등록자'} caption={'등록자'} alignment={'center'} />
            <Column dataField={'비고'} caption={'비고'} alignment={'center'} />
            {seasonticktOnleyCheck === 'Y' ? <Column dataField={'기간일'} caption={'기간일수'} alignment={'left'} /> : <></>}
            {seasonticktOnleyCheck === 'Y' ? <Column dataField={'일할계산금액'} caption={'일할계산금액'}  valueFormat="#,##0 원" /> : <></>}
            {seasonticktOnleyCheck === 'Y' ? <Column dataField={'잔여일'} caption={'잔여일'} /> : <></>}
            {seasonticktOnleyCheck === 'Y' ? <Column dataField={'잔여금액'} caption={'잔여금액'} /> : <></>}
            <Summary>
              <TotalItem column="매출수량" summaryType="sum" displayFormat="총 {0}건" />
              <TotalItem column="합계금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              {seasonticktOnleyCheck === 'Y' ? (<TotalItem column="잔여금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />) : <></>}
            </Summary>
          </CommonDataGrid>
        </div>
      </div>

      <PSLoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '.dx-popup-wrapper' }} visible={loadPanelVisible} />
      <h3 className={'content-block detail-title'}>세금계산서 상세 조회</h3>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '5px 40px' }}>
          <CommonDataGrid
            gridRef={invoicegridRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={null}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            isInfiniteScroll={false}
            onCellPrepared={onCellPreparedInvoice}
            height={600}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '세금계산서 상세 조회.xlsx',
            }}
          >
            {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column caption={'세금계산서 정보'} alignment={'center'}>
              <Column
                dataField={'TaxInvoiceStatusName'}
                caption={'처리상태'}
                width={110}
                cellRender={taxInvoiceStatusCellRender}
              />
              <Column dataField={'InvoiceID'} caption={'HiDMS 계산서번호'} alignment={'center'} />
              <Column dataField={'TaxInvoiceNo'} caption={'세금계산서번호'} alignment={'center'} />
              <Column dataField={'TaxHiParkingID'} caption={'P코드'} alignment={'center'} />
              <Column dataField={'TaxCostCenterName'} caption={'사업소명'} alignment={'center'} />
              <Column dataField={'TaxSalesPrice'} caption={'계산서금액'} alignment={'right'} format="#,##0 원" />
              <Column dataField={'TaxIssueTypeName'} caption={'계산서유형'} alignment={'center'} />
              <Column dataField={'TaxTypeName'} caption={'과세유형'} alignment={'center'} />
              <Column dataField={'TaxSaleDate'} caption={'발행요청일'} alignment={'center'} />
              <Column dataField={'TaxCustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'TaxCorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'TaxCustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              {/* <Column dataField={'TaxTypeOfBusiness'} caption={'계산서 업종'} alignment={'center'} />
              <Column dataField={'TaxTypeOfIndustry'} caption={'계산서 업태'} alignment={'center'} /> */}
            </Column>
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'SalesPrice'} caption={'원매출금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'SalesDate'} caption={'원매출일'} alignment={'center'} />
            <Column dataField={'FromDate'} caption={'시작일'} alignment={'center'} />
            <Column dataField={'ToDate'} caption={'종료일'} alignment={'center'} />
            <Column dataField={'Remark'} caption={'비고'} alignment={'center'} />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'VehicleRegistrationNo'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'CustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'CorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'CustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              <Column dataField={'TypeOfBusiness'} caption={'업종'} alignment={'center'} />
              <Column dataField={'TypeOfIndustry'} caption={'업태'} alignment={'center'} />
            </Column>

            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}

            <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} />
            <Summary>
              <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default AccountingPaymentAdminReport;
