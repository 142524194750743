import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { confirm } from 'devextreme/ui/dialog';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import { Column, Pager, Paging, Scrolling, Editing, Selection, Lookup } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { salesPayment, error } from '../../../../api';

import AddRentalMaster from '../popup/AddRentalMaster';
import CommonDataGrid from 'common/default-data-grid';
import './rental-sales-master.scss';
import notify from 'devextreme/ui/notify';

const RentalMasterPage = () => {
  const gridRef = useRef({});
  const customInfoRef = useRef({});
  const selectedRowRef = useRef(null);
  const addRentalMasterRef = useRef({});

  const [onlyIngCheck, setOnlyIngCheck] = useState(true);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);
  const [dataSource, setDataSource] = useState(null);
  // 로딩
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    callApi();
  }, [onlyIngCheck]);

  const callApi = useCallback(() => {
    (async () => {
      const result = await salesPayment.getRentalSalesMaster({
        IsIngOnly: onlyIngCheck,
      });
      if (result.isOk) {
        setDataSource(result.data[0]);
      }
    })();
  }, [onlyIngCheck]);
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const HelpText = () => {
    return (
      <>
        <i className="dx-icon-info" style={{ fontSize: '12px' }} id="title-help">
          &nbsp;마스터 정보를 기반으로 렌탈 세금계산서가 <b>시스템 자동 추천</b>됩니다.&nbsp;
          <u>한 사업소 내의 계산서 구분값이 서로 같으면 합산 발행됩니다.</u>
        </i>
      </>
    );
  };

  useEffect(() => {
    // 추후에는 사업소 선택 후 곧바로 수정 api 적용
    if (selectedCustomInfo.length > 0 && selectedRowRef.current !== null) {
      const updatedDataSource = dataSource.map((item, index) => {
        if (index === selectedRowRef.current) {
          return { ...item, CustomerCode: selectedCustomInfo[0].CustomerCode };
        }
        return item;
      });
      setDataSource(updatedDataSource);
    }
  }, [selectedCustomInfo]);

  const handleCreate = async () => {
    const res = await confirm('신규 렌탈 매출을 목록에 추가하시겠습니까?', '확인');
    if (res) {
      addRentalMasterRef.current.open(null, 'Create');
    }
  };
  const handleCopy = async () => {
    const res = await confirm('해당 사업소에 계산서 정보를 추가하시겠습니까?', '확인');
    const selectedData = gridRef.current.instance.getSelectedRowsData();
    if (res) {
      if (selectedData.length === 0) {
        return alert('복제할 항목을 선택해주세요');
      }
      const modifiedData = selectedData.map(item => ({
        ...item,
        ManagementID: '',
        ManagementName: '',
        ItemName: '',
        GrossPriceTotal: 0,
        StandardPriceTotal: 0,
        StandardPriceDeviceRental: 0,
        StandardPriceConstruction: 0,
        StandardPriceMaintenance: 0,
        StandardPriceCallCenter: 0,
        Remark: '',
        ProjectRemark: '',
      }));
      addRentalMasterRef.current.open(modifiedData, 'Copy');
    }
  };
  const handleUpdate = async () => {
    const selectedData = gridRef.current.instance.getSelectedRowsData();
    if (selectedData.length === 0) {
      return alert('수정할 항목을 선택해주세요');
    }
    addRentalMasterRef.current.open(selectedData, 'Update');
  };
  const handleDelete = () => {
    const selectedData = gridRef.current.instance.getSelectedRowsData();
    if (selectedData.length === 0) {
      return alert('삭제할 항목을 선택해주세요');
    }
    const result = confirm('렌탈 매출을 목록 삭제하시겠습니까?');
    result.then(dialogResult => {
      if (dialogResult) {
        const del = selectedData.map(d => {
          return {
            ManagementID: d.ManagementID,
          };
        });
        salesPayment.deleteRentalMaster(del[0]).then(res => {
          if (res.isOk) {
            notify(
              {
                message: '삭제되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            refresh();
          } else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      }
    });
  };

  const statusCellRender = e => {
    if (e.value === '발행') {
      return (
        <div style={{ width: 'auto', fontSize: '11px', textAlign: 'center' }} className={`badge-border-blue`}>
          {e.value}
        </div>
      );
    } else if (e.value === '중지') {
      return (
        <div style={{ width: 'auto', fontSize: '11px', textAlign: 'center' }} className={`badge-border-red`}>
          {e.value}
        </div>
      );
    } else {
      return (
        <div style={{ width: 'auto', fontSize: '11px', textAlign: 'center' }} className={`badge-border-gray`}>
          {e.value}
        </div>
      );
    }
  };

  const onCheckValueChanged = e => {
    setOnlyIngCheck(e.value);
  };

  const calculateIsStop = e => {
    const { IsStop, ContractStatusName } = e;
    if (IsStop === 0) {
      return '발행';
    } else if (IsStop === 1 && ContractStatusName === "해지" ) return '해지';
    else if (IsStop === 1) return '중지';
    else return 'N/A';
  };

  const refresh = () => {
    callApi();
  };

  const onRowPrepared = useCallback(({ data, rowElement }) => {
    if (data?.ContractStatus === 'D') {
      const tr = rowElement;
      tr.classList.add('status-terminated');
    }
  }, []);

  return (
    <div>
      <div className={'dx-card responsive-paddings native-scroll-header grid-with-status'}>
        <CommonDataGrid
          gridRef={gridRef}
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          stylingMode="outlined"
          height={650}
          onRowPrepared={onRowPrepared}
          isExcel={true}
          wordWrapEnabled={true}
          isInfiniteScroll={false}
          cssClass="cell-minimal-padding header-minimal-padding"
          excelOptions={{
            sheetName: 'Sheet',
            fileName: '사업자 목록 조회.xlsx',
          }}
          isHeader={true}
          headerBefore={[
            <CheckBox
              defaultValue={true}
              text="발행 중만     "
              hint="현재 계산서 발행 중인 마스터만 조회"
              onValueChanged={onCheckValueChanged}
            />,
            <HelpText />,
          ]}
          headerAfter={[
            <Button icon="plus" text="신규" type="normal" stylingMode="contained" onClick={handleCreate} />,
            <Button icon="plus" text="복제" type="normal" stylingMode="contained" onClick={handleCopy} />,
            <Button icon="edit" text="수정" type="normal" styli정gMode="contained" onClick={handleUpdate} />,
            <Button icon="trash" text="삭제" type="normal" stylingMode="contained" onClick={handleDelete} />,
            <Button
              icon="refresh"
              type="normal"
              stylingMode="contained"
              onClick={() => {
                refresh();
              }}
            />,
          ]}
        >
          {/* <Editing mode="row" allowAdding={true} allowDeleting={true} allowUpdating={true} /> */}
          <Paging defaultPageSize={15} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <Selection mode="single" />
          <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" useNative={true} />
          <Column
            dataField={'IsStop'}
            caption={'발행상태'}
            width={80}
            alignment="center"
            fixed={true}
            allowEditing={false}
            allowResizing={false}
            allowFiltering={true}
            cellRender={statusCellRender}
            calculateCellValue={calculateIsStop}
          />
          <Column
            dataField={'CostCenterCode'}
            caption={'P코드'}
            width={90}
            alignment="center"
            fixed={true}
            allowEditing={true}
          ></Column>
          <Column
            dataField={'ProjectRemark'}
            caption={'프로젝트정보'}
            width={120}
            fixed={true}
            allowEditing={false}
            wordWrapEnabled={false}
          />
          <Column dataField={'ContractStatusName'} caption={'계약상태'} width={80} alignment="center" fixed={true} />
          <Column dataField={'StopReason'} caption={'발행중지사유'} width={150} fixed={true} />
          <Column
            dataField={'LastSalesDate'}
            caption={'마지막발행일'}
            width={110}
            fixed={true}
            // calculateCellValue={rowData => `${rowData.LastSalesDate}`}
          />
          <Column dataField={'사업소계산서수'} caption={'사업소계산서수'} width={100} alignment="center" fixed={true} />
          <Column dataField={'CostCenterName'} caption={'사업소명'} width={200} wordWrapEnabled={false} fixed={true} />
          {/* <Column dataField={'CostCenterName'} caption={''} width={200} fixed={true} allowEditing={false} /> */}
          <Column dataField={'CustomerName'} caption={'사업자명'} width={160} fixed={true} wordWrapEnabled={false} />
          <Column dataField={'CustomerCode'} caption={'SAP사업자'} width={100} allowEditing={false} visible={true} />
          <Column
            dataField={'CorpRegNumber'}
            caption={'사업자번호'}
            width={110}
            fixed={false}
            allowEditing={false}
            cssClass="cell-minimal-padding"
            // cellRender={btnCellRender}
          ></Column>
          <Column dataField={'TaxEmailAddress'} caption={'이메일'} width={140} wordWrapEnabled={false} />
          <Column dataField={'SalesDate'} caption={'매출인식일'} width={100} dataType="date" />
          <Column dataField={'ContractStartDate'} caption={'계약시작일'} width={100} dataType="date" />
          <Column
            dataField={'ContractEndDate'}
            caption={'계약종료일'}
            width={100}
            allowEditing={false}
            dataType="date"
          />
          <Column dataField={'CanceledDate'} caption={'중도해지일'} width={100} dataType="date" />
          <Column caption={'회차 구분'} alignment={'center'}>
            <Column dataField={'LastSeq'} caption={'최근'} width={80} />
            <Column dataField={'ExpireSeq'} caption={'만기'} cssClass={''} width={80} />
          </Column>
          <Column
            dataField={'MonthlySalesDay'}
            caption={'정기발행일'}
            alignment={'center'}
            width={100}
            // calculateCellValue={rowData => `${rowData.MonthlySalesDay}`}
          />
          <Column dataField={'ManagementID'} caption={'ManagementID'} width={100} visible={false} />
          <Column dataField={'ManagementName'} caption={'계산서구분자'} width={110} allowEditing={true} />
          <Column dataField={'계산서항목수'} caption={'계산서 항목수'} width={80} alignment={'center'} />
          <Column dataField={'MergeInvoiceYN'} caption={'합산여부'} width={105} dataType="boolean" />
          <Column dataField={'ItemName'} caption={'계산서항목명'} width={120} allowEditing={true} />
          <Column dataField={'GrossPriceTotal'} caption={'총발행금액'} width={110} format="#,##0 원" />
          <Column dataField={'StandardPriceTotal'} caption={'총공급가'} width={100} format="#,##0 원" />
          {/* <Column dataField={'PriceTotal'} caption={'월총청구액'} width={100} format="#,##0 원" /> */}
          <Column dataField={'StandardPriceDeviceRental'} caption={'장비렌탈료'} width={120} format="#,##0 원" />
          <Column dataField={'StandardPriceConstruction'} caption={'공사료'} width={120} format="#,##0 원" />
          <Column dataField={'StandardPriceMaintenance'} caption={'유지보수료'} width={120} format="#,##0 원" />
          <Column dataField={'StandardPriceCallCenter'} caption={'콜센터료'} width={120} format="#,##0 원" />
          <Column dataField={'DepositPrice'} caption={'보증금'} width={120} format="#,##0 원" />
          <Column dataField={'CMSPayerNumber'} caption={'CMS납부자번호'} width={200} />
          <Column dataField={'CMSInfo'} caption={'CMS'} width={200} />
          <Column dataField={'PaymentInfo'} caption={'입금정보'} width={200} />
          <Column dataField={'StopDate'} caption={'발행중지일'} width={100} />
          <Column dataField={'InvoiceRemark'} caption={'계산서 적요'} width={200} />
          <Column dataField={'Remark'} caption={'비고'} width={450} />
        </CommonDataGrid>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
      <AddRentalMaster ref={addRentalMasterRef} setLoadPanelVisible={setLoadPanelVisible} refresh={refresh} />
    </div>
  );
};

export default RentalMasterPage;
