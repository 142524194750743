import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Column, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { ticket } from '../../../api';
import { COMPANYCODE } from 'constants/codes';
import CommonDataGrid from 'common/default-data-grid';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import './view-season-ticket-card-history.scss';

const ViewSeasonTicketCardHistory = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchCenter, setSearchCenter] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');
  const [searchVehicleNumber, setSearchVehicleNumber] = useState('');
  const [searchCustomerName, setSearchCustomerName] = useState('');

  const [searchData, setSearchData] = useState({
    FromDate: dateValue.FromDate,
    ToDate: dateValue.ToDate,
    SearchCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
    SearchCustomerName: '',
  });

  const gridRef = useRef({});

  useEffect(() => {
    (async () => {
      const result = await ticket.getSeasonTicketCardPaymentHistory(searchData);
      if (result.isOk) {
        setDataSource(result.data);
      }
    })();
  }, [searchData]);

  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  const onChangeApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };
  const onChangeVehicleNumber = e => {
    setSearchVehicleNumber(e.value);
  };
  const onChangeCustomerName = e => {
    setSearchCustomerName(e.value);
  };
  const onEnterKey = e => {
    callAPI();
  };

  const callAPI = () => {
    setSearchData({
      ...searchData,
      FromDate: dateValue.FromDate,
      ToDate: dateValue.ToDate,
      SearchCenter: searchCenter.trim(),
      SearchApprovalNo: searchApprovalNo.trim(),
      SearchVehicleNumber: searchVehicleNumber.trim(),
      SearchCustomerName: searchCustomerName.trim(),
    });
  };

  const onSearch = e => {
    callAPI();
  };

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };
  return (
    <div>
      <h2 className={'content-block'}>정기권 카드 결제 취합 데이터</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={650}
            stylingMode="outlined"
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'Sheet',
              fileName: '정기권 카드 결제 취합 데이터.xlsx',
            }}
            isHeader={true}
            headerBefore={[
              <div className="label">결제일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span>~</span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TextBox
                name="SearchCenter"
                labelMode="static"
                stylingMode="outlined"
                placeholder="사업소명/P-코드/사업소코드"
                onValueChanged={onChangeCenter}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchApprovalNo"
                labelMode="static"
                stylingMode="outlined"
                placeholder="승인번호/카드번호/계좌번호"
                onValueChanged={onChangeApprovalNo}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchVehicleNumber"
                labelMode="static"
                stylingMode="outlined"
                placeholder="차량번호"
                onValueChanged={onChangeVehicleNumber}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
              <TextBox
                name="SearchCustomerName"
                labelMode="static"
                stylingMode="outlined"
                placeholder="고객명"
                onValueChanged={onChangeCustomerName}
                onEnterKey={onEnterKey}
                width={160}
                mode="search"
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
            <HeaderFilter visible={true} />
            <Column dataField={'ReadYN'} caption={'반영여부'} width={90} />
            <Column dataField={'HiparkingID'} caption={'P코드'}/>
            <Column dataField={'CostCenterName'} caption={'사업소명'} width={180} />
            <Column dataField={'ApprovedDate'} caption={'결제일'} customizeText={customizeText} width={150} />
            <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'}/>
            <Column dataField={'FromDate'} caption={'시작일'} width={100}/>
            <Column dataField={'ToDate'} caption={'종료일'} width={100}/>
            <Column dataField={'SalesPrice'} caption={'결제금액'} format="#,##0 원"/>
            <Column dataField={'SeasonTicketUnitPrice'} caption={'기준금액'} format="#,##0 원"/>
            <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={90} />
            <Column dataField={'CreditCardNo'} caption={'카드번호'} width={90} />
            <Column dataField={'IssueCreditCard'} caption={'카드사'} width={100} />
            <Column dataField={'PaymentType'} caption={'결제유형'}/>

            <Column dataField={'Remark'} caption={'비고'} width={100} />
            <Column dataField={'OrderID'} caption={'주문ID'}/>
            <Column dataField={'ProductType'} caption={'상품유형'}/>
            <Column dataField={'ProductName'} caption={'상품명'}/>
            <Column dataField={'MemberID'} caption={'회원ID'}/>
            <Column dataField={'MemberName'} caption={'회원명'}/>
            <Column dataField={'GroupName'} caption={'그룹명'}/>
            <Column dataField={'GroupName'} caption={'그룹유형'}/>
            <Column dataField={'LastUpdateDate'} caption={'수정일'} customizeText={customizeText}/>
            <Column dataField={'RegisterDate'} caption={'등록일시'} customizeText={customizeText}/>
            <Column dataField={'RegisterUser'} caption={'등록자'}/>
          </CommonDataGrid>
        </div>
      </div>
    </div>
  );
};

export default ViewSeasonTicketCardHistory;
