import React, { useState, useRef, useMemo, useImperativeHandle, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Column, Summary, TotalItem, Selection, Scrolling, MasterDetail, Editing } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { error } from 'api';
import { CheckBox } from 'devextreme-react/check-box';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { makeTaxInvoiceStatusCellRender } from 'common/popup/sales-common-util';
import TaxinvoiceDetailHistory from './component/taxinvoice-detail-history.js';
import TaxinvoiceDetailSales from './component/taxinvoice-detail-sales.js';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const SalesTaxinvoiceView = () => {
  const detailHistoryRef = useRef({});
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    SearchCompany: '',
    MemberGroupName: '',
    CustomerName: '',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    SearchCompany: '',
    MemberGroupName: '',
    CustomerName: '',
  });
  const [allCheck, setAllCheck] = useState('N');
  const [invoiceID, setInvoiceID] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchData({
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      SearchCompany: text.SearchCompany,
      MemberGroupName: text.MemberGroupName,
      CustomerName: text.CustomerName,
    });
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'InvoiceID',
      loadMode: 'raw',
      update: async (key, values) => {
        try {
          const result = await axios.post(`aggregation/updateTaxInvoiceTaxInvoiceNo`, {
            InvoiceID: key,
            TaxInvoiceNo: values.TaxInvoiceNo,
            LoginID: userId,
          });
          notify(
            {
              message: '변경 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          return result.data;
        } catch (e) {
          notify(
            {
              message: error.errorMsgCheck(e.response.data.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      },
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getTaxInvoiceList`, {
            ...searchData,
            IsALL: allCheck,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData, allCheck, refresh]);

  const onCheckValueChanged = e => {
    e.value ? setAllCheck('Y') : setAllCheck('N');
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onSelectionChanged = e => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
    setInvoiceID(e.selectedRowsData[0].InvoiceID);
  };

  return (
    <React.Fragment>
      {' '}
      <h2 className={'content-block detail-title'}>
        청구/영수 세금계산서 상세조회
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <h3 style={{ margin: '1px 5px 7px 7px' }}>세금계산서 발행목록</h3>
          <CommonDataGrid
            gridRef={detailHistoryRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={dataSource}
            showBorders={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={670}
            onCellPrepared={onCellPrepared}
            onSelectionChanged={onSelectionChanged}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '세금계산서 발행 목록.xlsx',
              customizeCell: {
                date: ['RegisterDate', 'LastUpdateDate'],
              },
            }}
            headerBefore={[
              <div className="label">발행요청일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />,
              <div className="label">~</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <TextBox
                label="SearchCostCenter"
                labelMode="hidden"
                stylingMode="outlined"
                placeholder="P-코드/사업소코드/사업소명"
                onValueChanged={onChangeSearchText}
                onEnterKey={onEnterKey}
                width={220}
                mode="search"
              />,
              <CheckBox
                defaultValue={false}
                text="전체 이력 표시"
                hint="수정세금계산서 발행 전 이력 표시"
                onValueChanged={onCheckValueChanged}
              />,
            ]}
            headerAfter={[
              <Button
                icon="refresh"
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              />,
            ]}
          >
            <Editing
              mode="batch"
              allowUpdating={true}
              selectTextOnEditStart={true}
              startEditAction={'click'}
              texts={{
                saveAllChanges: '승인번호 저장하기',
                cancelAllChanges: '되돌리기',
              }}
            />
            <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
            <Selection mode="single" selectAllMode="pages" />
            <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} allowEditing={false} />
            <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} allowEditing={false} />
            <Column
              dataField={'TaxInvoiceStatusName'}
              caption={'처리상태'}
              width={110}
              cellRender={taxInvoiceStatusCellRender}
              allowEditing={false}
            />
            <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={110} allowEditing={false} />
            <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} allowEditing={true} />
            <Column dataField={'StatusName'} caption={'매출상태'} width={80} allowEditing={false} />
            <Column dataField={'AccountingDate'} caption={'대사일'} width={90} allowEditing={false} />
            <Column dataField={'AccountingNoList'} caption={'대사ID'} width={90} allowEditing={false} />
            <Column dataField={'SalesPrice'} caption={'금액'} width={100} format="#,##0 원" allowEditing={false} />
            <Column dataField={'CustomerName'} caption={'사업자명'} width={200} allowEditing={false} />
            <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} allowEditing={false} />
            <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} allowEditing={false} />
            <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} allowEditing={false} />
            <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} allowEditing={false} />
            <Column dataField={'SalesCount'} caption={'품목 수'} width={80} allowEditing={false} />
            <Column dataField={'TaxPrice'} caption={'부가세'} width={80} allowEditing={false} />
            <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} allowEditing={false} />
            <Column dataField={'Remark'} caption={'적요'} width={200} />
            <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} allowEditing={false} />
            <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} allowEditing={false} />
            <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} allowEditing={false} />
            <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} allowEditing={false} />
            <Column dataField={'RegisterUser'} caption={'최초등록자'} width={80} allowEditing={false} />
            <Column
              dataField={'RegisterDate'}
              caption={'최초등록일'}
              width={120}
              cellRender={customizeText}
              allowEditing={false}
            />
            <Column
              dataField={'LastUpdateDate'}
              caption={'마지막수정일'}
              width={120}
              cellRender={customizeText}
              allowEditing={false}
            />
            <MasterDetail enabled={true} component={TaxinvoiceDetailHistory} />
            <Summary>
              <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
          <div>
            <h3>{'세금계산서 매출 세부내역'}</h3>
          </div>
          <TaxinvoiceDetailSales invoiceID={invoiceID} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(SalesTaxinvoiceView);
