export const columns = [
  'No',
  '상점ID',
  '요청금액',
  '할인쿠폰',
  '승인일자',
  '취소일자',
  '구매자',
  '신용카드금액 (원)',
  '제휴포인트',
  '카드계열',
  '승인번호',
  '할부개월수',
  '무이자할부구분',
  '주문번호',
  'TID',
  '거래상태',
  '수취인주소',
  '수취인이름',
  '수취인전화번호',
];

export const sampleData = [
  {
    No: '1',
    상점ID: 'P코드',
    요청금액: 1000,
    할인쿠폰: '0',
    승인일자: '2023-01-31',
    취소일자: '2023-02-01',
    구매자: '23로0102',
    '신용카드금액 (원)': 187000,
    제휴포인트: '0',
    카드계열: '롯데',
    승인번호: '123456',
    할부개월수: '00',
    무이자할부구분: '일반',
    주문번호: '66954746bc3e4daca07357aecffb70d9',
    TID: 'INIMX_CARDhparkp055520230131221916594847',
    거래상태: '승인',
    수취인주소: '서울시',
    수취인이름: '홍길동',
    수취인전화번호: '010-000-0000',
  },
  {
    No: '2',
    상점ID: 'P코드',
    요청금액: 2000,
    할인쿠폰: '0',
    승인일자: '2023-01-31',
    취소일자: '2023-02-01',
    구매자: '23로0101',
    '신용카드금액 (원)': 187000,
    제휴포인트: '0',
    카드계열: '롯데',
    승인번호: '123459',
    할부개월수: '00',
    무이자할부구분: '일반',
    주문번호: '66954746bc3e4daca07357aecffb70d8',
    TID: 'INIMX_CARDhparkp055520230131221916594849',
    거래상태: '승인',
    수취인주소: '서울시',
    수취인이름: '홍길동',
    수취인전화번호: '010-000-0000',
  },
];
