import React, { useCallback, useState, useRef, useImperativeHandle, useEffect } from 'react';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  NumericRule,
  StringLengthRule,
  EmptyItem,
  EmailRule,
} from 'devextreme-react/form';

import { validateInput } from 'common/validators/validators';
import { Column, CustomRule, Editing } from 'devextreme-react/data-grid';
import { parking, salesPayment, error } from 'api';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';

import notify from 'devextreme/ui/notify';
import moment from 'moment';
import CommonDataGrid from 'common/default-data-grid';
import EmailResendPopup from 'pages/sales-mng/sales-taxinvoice/popup/EmailResendPopup';

import 'devextreme-react/text-area';
import './DetailHistory.scss';

const DetailHistory = React.forwardRef((props, ref) => {
  const { setIsOpen, refresh, setLoadPanelVisible } = props;
  const formRef = useRef({});
  const gridRef = useRef({});
  const resendEmailPopupRef = useRef({});

  const [formData, setFormData] = useState([]);

  // 라디오 버튼
  const [taxType, setTaxType] = useState([]);
  const [sapChangeCode, setSapChangeCode] = useState([]);

  // 매출 내역 grid
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (taxType.length === 0) {
      api();
    }
  }, [taxType]);

  const open = invoiceID => {
    if (invoiceID) {
      handleData(invoiceID);
    }
  };

  const handleData = useCallback(async invoiceID => {
    setLoadPanelVisible(true);
    await salesPayment.getTaxInvoiceListByID({ InvoiceID: invoiceID }).then(res => {
      if (res.isOk) {
        setLoadPanelVisible(false);
        setFormData(res.data[0]);
      }
    });
    await salesPayment
      .getTaxInvoiceItems({
        // SalesKeyIDList: '',
        TaxType: formRef.current.props.formData.TaxType,
        InvoiceID: invoiceID,
      })
      .then(res => {
        if (res.isOk) {
          setDataSource(res.data[0]);
        } else {
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
  }, []);
  const onClose = () => {
    setIsOpen(false);
    // formRef.current.instance.resetValues();
  };

  useImperativeHandle(ref, () => ({
    open,
  }));

  // 팝업에 들어가는 유형 select items
  const api = useCallback(async () => {
    const taxType = [];
    const sapChangeCode = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['TaxType', 'SapTaxInvoiceChangeCode'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'TaxType') {
          taxType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxType',
          });
        } else if (obj.CodeGroup === 'SapTaxInvoiceChangeCode') {
          sapChangeCode.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxType',
          });
        }
      });
      setTaxType(taxType);
      setSapChangeCode(sapChangeCode);
    }
  }, []);

  const onCancel = useCallback(
    async e => {
      // 취소 버튼 클릭 시
      gridRef.current.instance.saveEditData();
      var { InvoiceID } = formRef.current.props.formData;

      const result = confirm('<i> 세금계산서 발행을 취소하시겠습니까? </i>', '확인');
      result.then(async dialogResult => {
        if (dialogResult) {
          props.setLoadPanelVisible(true);
          await salesPayment.cancelTaxInvoiceListByID({ InvoiceID: InvoiceID, RegisterUser: '' }).then(res => {
            if (res.isOk) {
              props.setLoadPanelVisible(false);
              notify(
                {
                  message: '취소되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              gridRef.current.instance.refresh();
              props.refresh();
              onClose();
            } else {
              props.setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
        }
      });
    },
    [refresh],
  );

  // 수정
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      gridRef.current.instance.saveEditData();
      var {
        InvoiceID,
        TaxInvoiceType,
        TaxIssueType,
        TaxType,
        CostCenterCode,
        CustomerCode,
        CustomerCorpRegNumber,
        CustomerManagerGroup,
        CustomerManagerName,
        CustomerEmail1,
        CustomerEmail2,
        Remark,
        CustomerTelephoneNo,
        SalesPrice,
        SalesDate,
        TaxPrice,
        UnitPrice,
        ChangeCode,
        DerivedCorpRegNumber,
      } = formRef.current.props.formData;
      const isValidRemark = validateInput(Remark);
      const isValidDetailRemark = dataSource.every(a => validateInput(a.Remark));
      const isValid = isValidRemark && isValidDetailRemark;
      if (!isValid) {
        return notify(
          {
            message: '적요/비고 값에 인식할 수 없는 문자가 포함되어 있습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
      const trimSpace = str => {
        const resStr = str ? str.trim() : str;
        return resStr;
      };

      Remark = trimSpace(Remark);
      DerivedCorpRegNumber = trimSpace(DerivedCorpRegNumber);
      CustomerTelephoneNo = trimSpace(CustomerTelephoneNo);
      CustomerManagerName = trimSpace(CustomerManagerName);
      CustomerManagerGroup = trimSpace(CustomerManagerGroup);

      // 수정 불가일때
      if (formData && formData.SapStatus === 'C') {
        return false;
      }
      if (ChangeCode === '' && formData.SapStatus === 'Y') {
        return alert('수정코드를 입력해주세요.');
      }

      if (SalesPrice !== TaxPrice + UnitPrice) {
        return alert('금액을 확인해주세요.');
      } else {
        if (formData.SapStatus === 'Y') {
          setLoadPanelVisible(true);
          await salesPayment
            .insertChangeTaxInvoice({
              InvoiceID,
              TaxInvoiceType,
              TaxIssueType,
              TaxType,
              CostCenterCode,
              CustomerCode,
              CustomerCorpRegNumber,
              CustomerManagerGroup,
              CustomerManagerName,
              CustomerEmail1,
              CustomerEmail2,
              Remark,
              CustomerTelephoneNo,
              SalesPrice,
              SalesDate,
              RegisterUser: '',
              TaxPrice,
              UnitPrice,
              ItemListJson: JSON.stringify(dataSource),
              ChangeCode,
              DerivedCorpRegNumber,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '수정되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                refresh();
                onClose();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        } else {
          setLoadPanelVisible(true);
          await salesPayment
            .updateTaxInvoiceListByID({
              InvoiceID,
              TaxInvoiceType,
              TaxIssueType,
              TaxType,
              CostCenterCode,
              CustomerCode,
              CustomerCorpRegNumber,
              CustomerManagerGroup,
              CustomerManagerName,
              CustomerEmail1,
              CustomerEmail2,
              Remark,
              CustomerTelephoneNo,
              SalesPrice,
              SalesDate,
              RegisterUser: '',
              TaxPrice,
              UnitPrice,
              ItemListJson: JSON.stringify(dataSource),
              ChangeCode,
              DerivedCorpRegNumber,
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '수정되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                refresh();
                onClose();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      }
    },
    [dataSource],
  );

  const onFocusIn = e => {
    e.event.target.blur();
  };

  const onContentReady = e => {
    const button = e.element.getElementsByClassName('dx-button-content')[0];
    if (formData.SapStatus) {
      if (formData.SapStatus === 'BC') {
        button.id = 'taxInvoice-status-green';
      } else if (formData.SapStatus === 'W') {
        button.id = 'taxInvoice-status-gray';
      } else if (formData.SapStatus === 'NC') {
        button.id = 'taxInvoice-status-red';
      } else if (formData.SapStatus === 'Y') {
        button.id = 'taxInvoice-status-blue';
      } else if (formData.SapStatus === 'AC') {
        button.id = 'taxInvoice-status-blue';
      } else if (formData.SapStatus === 'N') {
        button.id = 'taxInvoice-status-red';
      } else if (formData.SapStatus === 'C') {
        button.id = 'taxInvoice-status-purple';
      }
    }
  };
  const phonenumberCheck = ({ value }) => {
    let numberStr = value.replaceAll('-', '').replaceAll(',', '');
    const isNumeric = n => !isNaN(n);
    const result = isNumeric(numberStr);
    if (result) return true;
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(227, 227, 227, 1)',
          paddingTop: '10px',
          paddingBottom: '5px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <div style={{ flex: '1', fontSize: '16px' }}>세금계산서 상세보기</div>
        <Button width={'3%'} icon="close" onClick={onClose} style={{ marginBottom: '10px' }}></Button>
      </div>
      <form onSubmit={onSubmit} className={`tax-detail-form`}>
        <Form
          ref={formRef}
          className={'add-tax-invoice detail-history'}
          formData={formData}
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
          labelLocation="left"
        >
          <GroupItem colCount={2}>
            <GroupItem>
              <SimpleItem dataField="TaxInvoiceNo" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                <Label text="국세청 승인번호" />
              </SimpleItem>
              <SimpleItem dataField="SAPInvoiceNo" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                <Label text="SAP 전표번호" />
              </SimpleItem>
              <EmptyItem dataField="SapStatus" editorType="dxTextBox" visible={false}></EmptyItem>
              <SimpleItem
                dataField="ChangeCode"
                editorType="dxSelectBox"
                visible={formData.SapStatus === 'Y' || formData.TaxInvoiceType === 'B'}
                editorOptions={{
                  items: sapChangeCode,
                  valueExpr: 'value',
                  displayExpr: 'label',
                  readOnly: !(formData.SapStatus === 'Y'),
                  placeholder: '국세청 승인번호가 있을때만 선택가능합니다.',
                }}
              >
                <Label text="수정 코드" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxInvoiceType"
                editorType="dxTextBox"
                visible={false}
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행 유형 코드" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxInvoiceTypeName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행 유형" />
              </SimpleItem>
              <SimpleItem
                dataField="CostCenterName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="사업소" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxIssueType"
                editorType="dxTextBox"
                visible={false}
                editorOptions={{
                  readOnly: true,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="TaxIssueTypeName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행유형" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxType"
                editorType="dxRadioGroup"
                editorOptions={{
                  items: taxType,
                  valueExpr: 'value',
                  displayExpr: 'label',
                  layout: 'horizontal',
                  readOnly: true,
                }}
              >
                <Label text="과세 유형" />
              </SimpleItem>
              <SimpleItem
                dataField="UnitPrice"
                editorType="dxNumberBox"
                editorOptions={{
                  min: 0,
                  format: '#,##0',
                  readOnly: true,
                }}
              >
                <Label text="공급가액" />
              </SimpleItem>
              <SimpleItem
                dataField="TaxPrice"
                editorType="dxNumberBox"
                editorOptions={{
                  min: 0,
                  format: '#,##0',
                  readOnly: true,
                }}
              >
                <Label text="부가세" />
              </SimpleItem>
              <SimpleItem
                dataField="SalesPrice"
                editorType="dxNumberBox"
                editorOptions={{
                  min: 0,
                  format: '#,##0',
                  readOnly: true,
                }}
              >
                <Label text="계산서 금액" />
              </SimpleItem>
              <SimpleItem
                dataField="SalesDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  placeholder: '요청일을 선택해주세요',
                }}
              >
                <RequiredRule message="필수 값입니다." />
                <Label text="발행요청일" />
              </SimpleItem>
              <SimpleItem dataField="Remark" editorType="dxTextArea">
                <Label text="적요" />
              </SimpleItem>
            </GroupItem>
            <GroupItem>
              <SimpleItem
                dataField="LastUpdateDate"
                editorType="dxTextBox"
                editorOptions={{
                  onFocusIn: onFocusIn,
                  buttons: [
                    {
                      name: 'trash',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        text: formData.TaxInvoiceStatusName,
                        onContentReady: onContentReady,
                      },
                    },
                  ],
                  value: moment(formData.LastUpdateDate).format('YYYY-MM-DD HH:mm:ss'),
                }}
              >
                <Label text="최근 수정일" />
              </SimpleItem>

              <SimpleItem
                dataField="RegisterUser"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="발행인" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerCorpRegNumber"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="사업자등록번호" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerName"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="회사명" />
              </SimpleItem>
              <SimpleItem
                dataField="NameOfRepresentative"
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="매입자 대표자 성명" />
              </SimpleItem>
              <SimpleItem dataField="CustomerManagerGroup" editorType="dxTextBox">
                <Label text="담당부서" />
              </SimpleItem>
              <SimpleItem dataField="CustomerManagerName" editorType="dxTextBox">
                <Label text="담당자" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerEmail1"
                editorType="dxTextBox"
                editorOptions={{
                  buttons: formData.SAPInvoiceNo
                    ? [
                        {
                          name: 'resend',
                          location: 'after',
                          options: {
                            stylingMode: 'text',
                            text: '재전송',
                            elementAttr: { class: 'resend-btn' },
                            visible: true,
                            onClick: () => {
                              resendEmailPopupRef.current.visiblePopup({
                                email1: formData.CustomerEmail1,
                                email2: formData.CustomerEmail2,
                                taxInvoiceForm: formData,
                              });
                            },
                          },
                        },
                      ]
                    : [],
                }}
              >
                <EmailRule message="메일 형식이 올바르지 않습니다." />
                <RequiredRule message="필수 값입니다." />
                <Label text="Email" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerEmail2"
                editorType="dxTextBox"
                editorOptions={{
                  name: 'CustomerEmail2',
                  valueExpr: 'value',
                  // onValueChanged: onValueChanged,
                }}
              >
                <EmailRule message="메일 형식이 올바르지 않습니다." />
                <Label text="Email2" />
              </SimpleItem>
              <SimpleItem dataField="CustomerTelephoneNo" editorType="dxTextBox">
                <CustomRule message={'숫자만 입력 가능합니다.'} validationCallback={phonenumberCheck} />
                <Label text="전화번호" />
              </SimpleItem>
              <SimpleItem
                dataField="DerivedCorpRegNumber"
                editorType="dxTextBox"
                editorOptions={{
                  maxLength: 4,
                }}
              >
                <Label text="종사업장" />
                <NumericRule message="숫자만 입력가능합니다." />
                <StringLengthRule max={4} message="종사업장은 4글자를 초과할 수 없습니다." />
              </SimpleItem>
            </GroupItem>
            <GroupItem colSpan={2}>
              <CommonDataGrid
                gridRef={gridRef}
                dataSource={dataSource}
                showBorders={true}
                allowColumnResizing={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={150}
                isHeader={true}
                isExcel={false}
                isNotFilterRow
                headerBefore={[<span> 매출 내역</span>]}
              >
                {formData && formData.SapStatus !== 'C' && (
                  <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={true} />
                )}
                <Column
                  dataField={'CostCenterName'}
                  caption={'사업소명'}
                  width={120}
                  alignment={'center'}
                  allowEditing={false}
                />
                <Column
                  dataField={'HiParkingID'}
                  caption={'P코드'}
                  width={'90'}
                  alignment={'center'}
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataField={'SalesTypeName'}
                  caption={'매출유형'}
                  width={80}
                  alignment={'center'}
                  allowEditing={false}
                />
                <Column
                  dataField={'DetailSalesTypeName'}
                  caption={'상세매출유형'}
                  width={80}
                  alignment={'center'}
                  allowEditing={false}
                />
                <Column
                  dataField={'ItemName'}
                  caption={'항목명'}
                  width={160}
                  alignment={'left'}
                  allowEditing={false}
                />
                <Column
                  dataField={'UnitPrice'}
                  caption={'공급가액'}
                  width={101}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={false}
                />
                <Column
                  dataField={'TaxPrice'}
                  caption={'세액'}
                  width={80}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={false}
                />
                <Column
                  dataField={'SalesPrice'}
                  caption={'합계금액'}
                  width={100}
                  alignment={'center'}
                  format="#,##0 원"
                  allowEditing={false}
                />
                <Column dataField={'Remark'} caption={'비고'} width={160} alignment={'left'} allowEditing={false} />
              </CommonDataGrid>
            </GroupItem>
          </GroupItem>

          <GroupItem colCount={3}>
            <ButtonItem>
              <ButtonOptions
                width={'60%'}
                type={'default'}
                useSubmitBehavior={true}
                disabled={formData && formData.SapStatus !== 'T' && formData.SapStatus !== 'W'}
                text={'수정'}
              />
            </ButtonItem>
            {formData.SapStatus === 'Y' ? (
              <ButtonItem horizontalAlignment="center">
                <ButtonOptions
                  width={'60%'}
                  type={'default'}
                  useSubmitBehavior={true}
                  visible={formData.SapStatus === 'Y'}
                  text={'수정세금계산서 발행'}
                ></ButtonOptions>
              </ButtonItem>
            ) : (
              <ButtonItem horizontalAlignment="center">
                <ButtonOptions
                  width={'60%'}
                  type={'default'}
                  onClick={onCancel}
                  disabled={formData && formData.SapStatus !== 'T' && formData.SapStatus !== 'W'}
                  text={'발행 취소'}
                ></ButtonOptions>
              </ButtonItem>
            )}
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'60%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
      <EmailResendPopup ref={resendEmailPopupRef}></EmailResendPopup>
    </React.Fragment>
  );
});

export default DetailHistory;
