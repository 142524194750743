import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';

import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import CustomStore from 'devextreme/data/custom_store';
import { TextBox } from 'devextreme-react/text-box';
import ScrollView from 'devextreme-react/scroll-view';
import { parking } from 'api';
import { parkingMngManualLink } from 'constants/hyperlink';

import ParkingMngForm from './components/parking-mng-form';
import CommonDataGrid from 'common/default-data-grid';
import './parking-mng.scss';

const userId = localStorage.getItem('userId');

const ParkingMng = () => {
  const [popupVisible, setPopupVisibility] = useState({
    save: false,
    close: false,
  });
  const [costCenter, setCostCenter] = useState(null);
  const [searchParams, setSearchParams] = useState({
    SearchText: '',
  });
  const [contractSalesType, setContractSalesType] = useState([]);
  const [contractGrade, setContractGrade] = useState([]);
  const [operatingType, setOperatingType] = useState([]);
  const [parkingLocationInfo, setParkingLocationInfo] = useState([]);
  const [text, setText] = useState('');
  const [refresh, setRefresh] = useState(false);
  const gridRef = useRef({});

  useEffect(() => {
    parkingInfoSelectType();
  }, []);

  const parkingInfoSelectType = useCallback(async () => {
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['ContractSalesType', 'ContractGrade', 'OperatingType', 'ParkingLocationInfo'],
    });
    const contractSalesType = [];
    const contractGrade = [];
    const operatingType = [];
    const parkingLocationInfo = [];
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'ContractSalesType') {
          contractSalesType.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'ContractGrade') {
          contractGrade.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'OperatingType') {
          operatingType.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'ParkingLocationInfo') {
          return parkingLocationInfo.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
    }
    setContractSalesType(contractSalesType);
    setContractGrade(contractGrade);
    setOperatingType(operatingType);
    setParkingLocationInfo(parkingLocationInfo);
  }, []);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'CostCenterCode',
      loadMode: 'raw',
      load: async () => {
        try {
          const { SearchText } = searchParams;
          const result = await axios.post(`master/searchCostCenterDivisionMasterA`, {
            SearchText,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams, refresh]);

  const togglePopup = data => {
    const { CostCenterCode } = data;
    setCostCenter(CostCenterCode);
    setPopupVisibility({ save: false, close: !popupVisible.close });
  };

  const infoCellRender = e => {
    return (
      <Button
        icon="search"
        width={120}
        text="보기"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup(e.data)}
      />
    );
  };

  const onChangeSearchText = e => {
    setText(e.value);
  };

  const onEnterKey = e => {
    setSearchParams({
      ...searchParams,
      SearchText: text.trim(),
    });
  };

  const onSearch = e => {
    setSearchParams({
      ...searchParams,
      SearchText: text.trim(),
    });
  };

  const onClose = () => {
    setPopupVisibility({ ...popupVisible, close: false });
  };

  return (
    <>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>사업소 주차장 정보 관리</h2>
        </div>
        <div>
          <a
            className="manual-link"
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={parkingMngManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={gridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={true}
            isHeader={true}
            height={650}
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 주차장 정보 관리.xlsx',
            }}
            headerBefore={[
              <TextBox
                labelMode="static"
                stylingMode="outlined"
                placeholder="P코드나 사업소명을 입력하세요"
                onValueChanged={onChangeSearchText}
                onEnterKey={onEnterKey}
                width={250}
                mode="search"
              />,
            ]}
            headerAfter={[
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
              <Button
                icon="refresh"
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              />,
            ]}
          >
            <Paging defaultPageSize={15} />
            <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
            <Column dataField={'CostCenterName'} caption={'사업소명'} />
            <Column dataField={'HiParkingID'} caption={'P코드'} />
            <Column dataField={'ContractValidDateFrom'} caption={'계약시작일'} />
            <Column dataField={'ContractValidDateTo'} caption={'계약종료일'} />
            <Column dataField={'ContractSalesTypeName'} caption={'계약형태'} />
            <Column dataField={'LocationInfoName'} caption={'권역'} />
            <Column
              dataField={'info'}
              caption={'주차장 정보'}
              hidingPriority={1}
              cellRender={infoCellRender}
              allowExporting={false}
            />
          </CommonDataGrid>
        </div>
      </div>
      <Popup onHiding={onClose} visible={popupVisible.close} showTitle={false} width="40%" height={860}>
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <ParkingMngForm
              text={text}
              costCenter={costCenter}
              setRefresh={setRefresh}
              refresh={refresh}
              setPopupVisibility={setPopupVisibility}
              popupVisible={popupVisible}
              contractSalesType={contractSalesType}
              contractGrade={contractGrade}
              operatingType={operatingType}
              parkingLocationInfo={parkingLocationInfo}
            />
          </div>
        </ScrollView>
      </Popup>
    </>
  );
};

export default ParkingMng;
