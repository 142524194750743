import React, { useImperativeHandle, useState } from 'react';
import Form, { GroupItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { base64forTaxInvoice, base64forCashbill } from './base64';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';

const Help = React.forwardRef((props, ref) => {
  const { setHelpVisible } = props;
  const [type, setType] = useState({ title: '', imageSrc: '' });

  useImperativeHandle(ref, () => ({
    open,
  }));
  // text : popup창의 title과 구분값으로 사용
  const open = type => {
    if (type === 'cashbill') {
      setType({ title: '현금영수증', imageSrc: base64forCashbill });
    } else if (type === 'taxinvoice') {
      setType({ title: '세금계산서', imageSrc: base64forTaxInvoice });
    } else {
    }
  };

  const onClose = () => {
    setHelpVisible(false);
  };

  return (
    <form>
      <Form labelLocation="left" labelMode="outside" optionalMark="optional" stylingMode="outlined" height="70%">
        <ButtonItem>
          <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
        </ButtonItem>
        <GroupItem caption={`${type.title} 처리절차`}>
          <img width="100%" height="100%" alt="이미지없음" src={type.imageSrc}></img>
        </GroupItem>
        <GroupItem colCount={1}></GroupItem>
      </Form>
    </form>
  );
});

export default Help;
