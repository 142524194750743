import React, { useMemo, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Column, Selection } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';

const userId = localStorage.getItem('userId');

const TaxinvoiceDetailHistory = props => {
  const detailGridRef = useRef({});
  const [invoiceID, setInvoiceID] = useState(null);

  useEffect(() => {
    const { InvoiceID } = props.data.data;
    setInvoiceID(InvoiceID);
  }, [props]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getTaxInvoiceHistory`, {
            InvoiceID: invoiceID,
            LoginID: userId,
          });
          return result.data[0];
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [invoiceID]);

  return (
    <React.Fragment>
      <div className="master-detail-caption">{`변경이력 상세:`}</div>
      <CommonDataGrid
        gridRef={detailGridRef}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        isNotFilterRow={true}
        isHeader={false}
        isExcel={false}
      >
        <Column dataField="LastUpdateDate" caption={'마지막수정일'} width={160} cellRender={customizeText} />
        <Column dataField="LastUpdateUserName" caption={'수정자'} />
        
       {/* <Column dataField="ChangeCode" caption={'ChangeCode'} /> */}
        <Column dataField="SalesDate" caption={'발행요청일'} />
        {/* <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} /> */}
        <Column dataField="TaxInvoiceStatusName" caption={'처리상태'} />
        <Column dataField="ChangeCodeName" caption={'수정코드'} />
        <Column dataField="TaxInvoiceNo" caption={'승인번호'} />
        <Column dataField="StatusName" caption={'매출상태'} />
        {/* <Column dataField="ContractNo" caption={'ContractNo'} /> */}

        <Column dataField="SalesPrice" caption={'금액'} />
        <Column dataField="TaxPrice" caption={'부가세'} />
        <Column dataField="UnitPrice" caption={'공급가액'} />

        <Column dataField="CustomerCode" caption={'회사코드'} />
        <Column dataField="CustomerName" caption={'사업자명'} />
        <Column dataField="CustomerCorpRegNumber" caption={'사업자번호'} />
        <Column dataField="CustomerManagerName" caption={'매입처 담당자명'} />
        <Column dataField="CustomerEmail1" caption={'이메일'} />
        <Column dataField="CustomerEmail2" caption={'이메일2'} />
        {/* <Column dataField="CustomerManagerGroup" caption={'CustomerManagerGroup'} /> */}
        {/* <Column dataField="CustomerTelephoneNo" caption={'CustomerTelephoneNo'} />
        <Column dataField="DerivedCorpRegNumber" caption={'DerivedCorpRegNumber'} />
        <Column dataField="InterfaceDate" caption={'InterfaceDate'} />
        <Column dataField="InterfaceFlag" caption={'InterfaceFlag'} />
        <Column dataField="InterfaceTime" caption={'InterfaceTime'} /> */}
        {/* <Column dataField="InvoiceID" caption={'세금계산서ID'} /> */}
        {/* <Column dataField="NameOfRepresentative" caption={'NameOfRepresentative'} />
        <Column dataField="PaymentAgency" caption={'PaymentAgency'} />
        <Column dataField="PaymentDate" caption={'PaymentDate'} /> */}
        {/* <Column dataField="SapInvoiceNo" caption={'SapInvoiceNo'} /> */}
        {/* <Column dataField="SapStatus" caption={'SapStatus'} /> */}
        {/* <Column dataField="Status" caption={'Status'} />  */}
      </CommonDataGrid>
    </React.Fragment>
  );
};

export default TaxinvoiceDetailHistory;
