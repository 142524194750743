import axios from 'axios';

export async function signIn(email, password) {
  try {
    // ID/PASS 인증
    // #TODO# 실제 입력값 적용
    const result = await axios.post(`auth/login`, {
      loginID: email,
      pass: password,
    });
    // const result = await axios.post(`auth/login`, {
    //   loginID: 'swgo4@humaxit.com', // email,
    //   pass: '1234', // password,
    // });

    const token = result?.data?.token;

    if (token) {
      localStorage.setItem('token', token);

      const userId = result?.data?.userInfo.UserID;
      localStorage.setItem('userId', userId);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      window.location.replace('/');
      return {
        isOk: true,
        data: result?.data,
      };
    } else {
      return { isOk: false, message: 'Authentication failed' };
    }
  } catch {
    return { isOk: false, message: 'Authentication failed' };
  }
}

export async function getUser() {
  // token 인증
  try {
    const result = await axios.post(`auth/loginWithToken`);
    const data = result?.data;
    if (data) return { isOk: true, data };
  } catch {}
  localStorage.removeItem('token');
  axios.defaults.headers.common.Authorization = `Bearer `;

  // 다른 인증 시도 (LDAP?)
  // ?

  // 실패
  return { isOk: false };
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return { isOk: true };
  } catch {
    return { isOk: false, message: 'Failed to create account' };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return { isOk: true };
  } catch {
    return { isOk: false, message: 'Failed to change password' };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return { isOk: true };
  } catch {
    return { isOk: false, message: 'Failed to reset password' };
  }
}
