import React, { useCallback, useState, useRef, useEffect } from 'react';
import moment from 'moment';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  StringLengthRule,
  NumericRule,
  EmailRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import { Column, CustomRule, Editing } from 'devextreme-react/data-grid';
import { validateInput } from 'common/validators/validators';
import { parking, salesPayment, error } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import CostcenterMaster from 'common/popup/costcenter-master';
import CustomInfoPopup from './CustomInfoPopup';
import notify from 'devextreme/ui/notify';
import 'styles/CommonStyles.scss';

const AddTaxinvoice = props => {
  const { setIsOpen, selectData, refresh, setLoadPanelVisible } = props;
  const formRef = useRef({});
  const gridRef = useRef({});
  const formData = useRef({ TaxType: '010' });
  const costcenterMasterRef = useRef({});
  const customInfoRef = useRef({});

  // 라디오 버튼
  const [taxIssueType, setTaxIssueType] = useState([]);
  const [taxInvoiceType, setTaxInvoiceType] = useState([]);
  const [taxType, setTaxType] = useState([]);

  // 사업소 & 사업자 선택
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);

  // 매출 내역 grid
  const [dataSource, setDataSource] = useState([]);

  const [addParams, setAddParams] = useState({
    SalesKeyIDList: [],
    CostCenterCode: '',
    CostCenterName: '',
    SalesPrice: 0,
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerManagerGroup: '',
    CustomerManagerName: '',
    CustomerEmail1: '',
    CustomerEmail2: '',
    CustomerTelephoneNo: '',
    TaxPrice: '',
    UnitPrice: '',
    SalesDate: moment().format('YYYY-MM-DD'),
    DerivedCorpRegNumber: '',
    // 추가됨
    Street: '',
  });

  const [isAPICalling, setIsAPICalling] = useState(false);

  useEffect(() => {
    if (taxIssueType.length === 0) {
      api();
    }
  }, [taxIssueType]);

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      setAddParams({
        ...addParams,
        CostCenterCode: selectedCostCenter[0].CostCenterCode,
        CostCenterName: selectedCostCenter[0].CostCenterName,
      });
    }
  }, [selectedCostCenter]);
  useEffect(() => {
    const { SalesDate } = formData.current;
    if (selectedCustomInfo.length > 0) {
      setAddParams({
        ...addParams,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerManagerGroup: selectedCustomInfo[0].CustomerManagerGroup,
        CustomerManagerName: selectedCustomInfo[0].CustomerManagerName,
        // 사업자 선택시 대표자이메일이 아닌 담당자 이메일로 수정
        CustomerEmail1: selectedCustomInfo[0].TaxInvoiceEmail1,
        // 사업자 선태깃 담당자 이메일2 불러옴
        CustomerEmail2: selectedCustomInfo[0].TaxInvoiceEmail2,
        // 주소 불러옴
        Street: selectedCustomInfo[0].Street,
        CustomerTelephoneNo: selectedCustomInfo[0].CustomerTelephoneNo,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
        SalesDate,
      });
    }
  }, [selectedCustomInfo]);

  useEffect(() => {
    if (selectData.length > 0) {
      const keyIDList = selectData.map(d => d.KeyID);
      const sales = selectData.reduce((a, c) => {
        return a + c.SalesPrice;
      }, 0);

      const taxInvoicItems = [];
      (async () => {
        try {
          const res1 = await salesPayment.getTaxInvoiceItems({
            SalesKeyIDList: keyIDList.join(';'),
            TaxType: '',
            InvoiceID: '',
          });
          if (res1.isOk) {
            setDataSource(res1.data[0]);
            taxInvoicItems.push(...res1.data[0]);
          } else {
            throw res1.error.detail;
          }
          const res2 = await salesPayment.getTaxInvoiceCustomerBySales({
            SalesKeyIDList: keyIDList.join(';'),
          });
          if (res2.isOk) {
            const taxPrice = taxInvoicItems.reduce((a, c) => {
              return a + c.TaxPrice;
            }, 0);
            const unitPrice = taxInvoicItems.reduce((a, c) => {
              return a + c.UnitPrice;
            }, 0);
            if (Array.isArray(res2.data) && res2.data[0].length > 0) {
              setAddParams({
                ...addParams,
                CustomerCorpRegNumber: res2.data[0][0].CorpRegNumber,
                CustomerCorpName: res2.data[0][0].CustomerName,
                NameOfRepresentative: res2.data[0][0].NameOfRepresentative,
                CustomerManagerGroup: res2.data[0][0].CustomerManagerGroup,
                CustomerManagerName: res2.data[0][0].CustomerManagerName,
                CustomerEmail1: res2.data[0][0].TaxInvoiceEmail1 === null ? '' : res2.data[0][0].TaxInvoiceEmail1,
                // 추가됨
                CustomerEmail2: res2.data[0][0].TaxInvoiceEmail2 === null ? '' : res2.data[0][0].TaxInvoiceEmail2,
                CustomerTelephoneNo: res2.data[0][0].CustomerTelephoneNo,
                Street: res2.data[0][0].Street,
                CustomerCode: res2.data[0][0].CustomerNo,
                SalesKeyIDList: keyIDList.join(';'),
                CostCenterCode: selectData[0].CostCenterCode,
                CostCenterName: selectData[0].CostCenterName,
                SalesPrice: sales,
                TaxPrice: taxPrice,
                UnitPrice: unitPrice,
                DerivedCorpRegNumber: '',
              });
            } else {
              setAddParams({
                ...addParams,
                CustomerCorpRegNumber: '',
                CustomerCorpName: '',
                NameOfRepresentative: '',
                CustomerManagerGroup: '',
                CustomerManagerName: '',
                CustomerEmail1: '',
                CustomerEmail2: '',
                Street: '',
                CustomerTelephoneNo: '',
                CustomerCode: '',
                SalesKeyIDList: keyIDList.join(';'),
                CostCenterCode: selectData[0].CostCenterCode,
                CostCenterName: selectData[0].CostCenterName,
                SalesPrice: sales,
                TaxPrice: taxPrice,
                UnitPrice: unitPrice,
                DerivedCorpRegNumber: '',
              });
            }
          } else {
            throw res2.error.detail;
          }
        } catch (detail) {
          onClose();
          notify(
            {
              message: error.errorMsgCheck(detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      })();
    }
  }, [selectData]);

  // 팝업에 들어가는 유형 select items
  const api = useCallback(async () => {
    const taxIssueType = [];
    const taxType = [];
    const taxInvoiceType = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['TaxIssueType', 'TaxType', 'TaxInvoiceType'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'TaxIssueType') {
          taxIssueType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxIssueType',
          });
        } else if (obj.CodeGroup === 'TaxType') {
          taxType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxType',
          });
        } else if (obj.CodeGroup === 'TaxInvoiceType') {
          taxInvoiceType.push({
            label: obj.CodeDesc,
            value: obj.Code,
            type: 'TaxInvoiceType',
          });
        }
      });
      setTaxIssueType(taxIssueType);
      setTaxInvoiceType(taxInvoiceType);
      setTaxType(taxType);
    }
  }, []);

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      var {
        SalesDate,
        DerivedCorpRegNumber,
        TaxIssueType,
        TaxType,
        TaxPrice,
        UnitPrice,
        CustomerTelephoneNo,
        TaxInvoiceType,
        CustomerEmail1,
        CustomerEmail2,
        Remark,
        ...customInfo
      } = formData.current;
      var { SalesKeyIDList, CostCenterCode, CostCenterName, SalesPrice, CustomerCode } = addParams;
      const isValidRemark = validateInput(Remark);
      const isValidDetailRemark = dataSource.every(a => validateInput(a.Remark));
      const isValid = isValidRemark && isValidDetailRemark;
      if (!isValid) {
        return notify(
          {
            message: '적요/비고 값에 인식할 수 없는 문자가 포함되어 있습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
      const trimSpace = str => {
        const resStr = str ? str.trim() : str;
        return resStr;
      };
      Remark = trimSpace(Remark);
      DerivedCorpRegNumber = trimSpace(DerivedCorpRegNumber);
      CustomerTelephoneNo = trimSpace(CustomerTelephoneNo);
      customInfo.CustomerManagerName = trimSpace(customInfo.CustomerManagerName);
      customInfo.CustomerManagerGroup = trimSpace(customInfo.CustomerManagerGroup);
      const itemSalesPrice = dataSource.reduce((a, c) => {
        return a + c.SalesPrice;
      }, 0);

      if (SalesPrice !== TaxPrice + UnitPrice || itemSalesPrice !== SalesPrice) {
        return alert('금액을 확인해주세요.');
      } else {
        setLoadPanelVisible(true);
        await salesPayment
          .insertTaxInvoice({
            CostCenterCode,
            CostCenterName,
            TaxInvoiceType,
            TaxIssueType: 'A', // 청구
            SalesKeyIDList,
            SalesPrice,
            SalesDate: SalesDate ? moment(SalesDate).format('YYYY-MM-DD') : '',
            DerivedCorpRegNumber,
            RegisterUser: '',
            CustomerCode,
            CustomerEmail1,
            CustomerEmail2,
            CustomerTelephoneNo,
            TaxType,
            TaxPrice,
            UnitPrice,
            Remark,
            ...customInfo,
            ItemListJson: JSON.stringify(dataSource),
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '처리되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },

    [formData, addParams, dataSource],
  );

  const onClose = () => {
    formRef.current.instance.resetValues();
    setSelectedCustomInfo([]);
    setIsOpen(false);
    setAddParams({
      TaxInvoiceType: '',
      TaxIssueType: '',
      SalesKeyIDList: [],
      CostCenterCode: '',
      CostCenterName: '',
      SalesPrice: 0,
      CustomerCorpRegNumber: '',
      CustomerCorpName: '',
      NameOfRepresentative: '',
      CustomerManagerGroup: '',
      CustomerManagerName: '',
      CustomerEmail1: '',
      CustomerEmail2: '',
      Street: '',
      CustomerTelephoneNo: '',
      TaxPrice: 0,
      UnitPrice: 0,
      SalesDate: moment().format('YYYY-MM-DD'),
      DerivedCorpRegNumber: '',
    });
    formRef.current.instance.updateData('TaxType', '010');
  };

  // 사업소 변경시 버튼을 통해서만 가능하도록
  const onFocusIn = e => {
    e.event.target.blur();
  };

  const onTaxTypeChanged = e => {
    const {
      SalesPrice,
      CustomerManagerGroup,
      CustomerManagerName,
      CustomerEmail1,
      CustomerTelephoneNo,
      CustomerEmail2,
      Street,
    } = formData.current;
    const keyIDList = selectData.map(d => d.KeyID);
    const taxInvoiceItems = [];
    setIsAPICalling(true);
    (async () => {
      await salesPayment
        .getTaxInvoiceItems({
          SalesKeyIDList: keyIDList.join(';'),
          TaxType: e.value,
          InvoiceID: '',
        })
        .then(res => {
          if (res.isOk) {
            setDataSource(res.data[0]);
            taxInvoiceItems.push(...res.data[0]);
            setIsAPICalling(false);
          }
        });

      if (e.value === '000' || e.value === '020') {
        setAddParams({
          ...addParams,
          CustomerManagerGroup,
          CustomerManagerName,
          CustomerEmail1,
          CustomerEmail2,
          CustomerTelephoneNo,
          Street,
          TaxPrice: 0,
          UnitPrice: SalesPrice,
        });
      } else {
        const taxPrice = taxInvoiceItems.reduce((a, c) => {
          return a + c.TaxPrice;
        }, 0);
        const unitPrice = taxInvoiceItems.reduce((a, c) => {
          return a + c.UnitPrice;
        }, 0);
        setAddParams({
          ...addParams,
          CustomerManagerGroup,
          CustomerManagerName,
          CustomerEmail1,
          CustomerEmail2,
          CustomerTelephoneNo,
          Street,
          TaxPrice: taxPrice,
          UnitPrice: unitPrice,
        });
      }
    })();
  };

  const handleDateFrom = e => {
    const {
      CustomerManagerGroup,
      CustomerManagerName,
      CustomerEmail1,
      CustomerCorpRegNumber,
      CustomerTelephoneNo,
      CustomerEmail2,
      Street,
    } = formData.current;
    setAddParams({
      ...addParams,
      CustomerCorpRegNumber,
      CustomerManagerGroup,
      CustomerManagerName,
      CustomerTelephoneNo,
      CustomerEmail1,
      CustomerEmail2,
      Street,
      SalesDate: e.value,
    });
  };

  const onChangesChange = e => {
    if (e.length > 0) {
      gridRef.current.instance.saveEditData();
      const { CustomerManagerGroup, CustomerManagerName, CustomerEmail1, CustomerTelephoneNo, CustomerEmail2 } =
        formData.current;

      const taxPrice = dataSource.reduce((a, c) => {
        return a + c.TaxPrice;
      }, 0);
      const unitPrice = dataSource.reduce((a, c) => {
        return a + c.UnitPrice;
      }, 0);
      setAddParams({
        ...addParams,
        CustomerManagerGroup,
        CustomerManagerName,
        CustomerTelephoneNo,
        CustomerEmail1,
        CustomerEmail2,
        UnitPrice: unitPrice,
        TaxPrice: taxPrice,
      });
    }
  };

  const phonenumberCheck = ({ value }) => {
    let numberStr = value.replaceAll('-', '').replaceAll(',', '');
    const isNumeric = n => !isNaN(n);
    const result = isNumeric(numberStr);
    if (result) return true;
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            ref={formRef}
            className={'add-tax-invoice'}
            formData={formData.current}
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
            labelLocation="left"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={'세금 계산서 발행'} colCount={2}>
              <GroupItem>
                <SimpleItem
                  dataField="TaxInvoiceType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: taxInvoiceType,
                    valueExpr: 'value',
                    displayExpr: 'label',
                    layout: 'horizontal',
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="발행 유형" />
                </SimpleItem>
                <SimpleItem
                  dataField="CostCenterName"
                  editorType="dxTextBox"
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업소 변경',
                          onClick: () => {
                            costcenterMasterRef.current.open();
                          },
                        },
                      },
                    ],
                    value: addParams.CostCenterName,
                  }}
                >
                  <Label text="사업소" />
                </SimpleItem>

                <SimpleItem
                  dataField="TaxType"
                  editorType="dxRadioGroup"
                  shouldRender="condition"
                  editorOptions={{
                    items: taxType,
                    valueExpr: 'value',
                    displayExpr: 'label',
                    layout: 'horizontal',
                    onValueChanged: onTaxTypeChanged,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="과세 유형" />
                </SimpleItem>
                <SimpleItem
                  dataField="UnitPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                    value: addParams.UnitPrice,
                  }}
                >
                  <Label text="공급가액" />
                </SimpleItem>
                <SimpleItem
                  dataField="TaxPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                    value: addParams.TaxPrice,
                  }}
                >
                  <Label text="부가세" />
                </SimpleItem>
                <SimpleItem
                  dataField="SalesPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                    value: addParams.SalesPrice,
                  }}
                >
                  <Label text="계산서 금액" />
                </SimpleItem>
                <SimpleItem
                  dataField="SalesDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    type: 'date',
                    dropDownButtonTemplate: 'dropDownButton',
                    displayFormat: 'yyyy-MM-dd',
                    useMaskBehavior: true,
                    icon: true,
                    placeholder: '요청일을 선택해주세요',
                    value: addParams.SalesDate,
                    onValueChanged: handleDateFrom,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="발행 요청일" />
                </SimpleItem>
                <SimpleItem dataField="Remark" editorType="dxTextArea">
                  <Label text="적요" />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  dataField="CustomerCorpRegNumber"
                  editorType="dxTextBox"
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업자 선택',
                          onClick: () => {
                            customInfoRef.current.open();
                          },
                        },
                      },
                    ],
                    value: addParams.CustomerCorpRegNumber,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="사업자등록번호" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerCorpName"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: addParams.CustomerCorpName,
                  }}
                >
                  <Label text="회사명" />
                </SimpleItem>
                <SimpleItem
                  dataField="NameOfRepresentative"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: addParams.NameOfRepresentative,
                  }}
                >
                  <Label text="매입자 대표자 성명" />
                </SimpleItem>
                <SimpleItem
                  dataField="Street"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    maxLength: 4,
                    value: addParams.Street,
                  }}
                >
                  <Label text="주소" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerManagerGroup"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerManagerGroup,
                  }}
                >
                  {/* <RequiredRule message="필수 값입니다." /> */}
                  <Label text="담당부서" />
                  <StringLengthRule max={20} message="허용 길이를 초과했습니다." />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerManagerName"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerManagerName,
                  }}
                >
                  {/* <RequiredRule message="필수 값입니다." /> */}
                  <Label text="담당자" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerEmail1"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerEmail1,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <EmailRule message="메일 형식이 올바르지 않습니다." />
                  <Label text="담당자 Email" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerEmail2"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerEmail2,
                  }}
                >
                  <EmailRule message="메일 형식이 올바르지 않습니다." />
                  <Label text="담당자 Email2" />
                </SimpleItem>
                {/* 누락되어 추가됨 */}
                <SimpleItem
                  dataField="CustomerTelephoneNo"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerTelephoneNo,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="담당자 전화번호" />
                  <CustomRule message={'숫자만 입력 가능합니다.'} validationCallback={phonenumberCheck} />
                </SimpleItem>
                <SimpleItem
                  dataField="DerivedCorpRegNumber"
                  editorType="dxTextBox"
                  editorOptions={{
                    maxLength: 4,
                  }}
                >
                  <Label text="종사업장" />
                  <NumericRule message="숫자만 입력가능합니다." />
                  <StringLengthRule max={4} message="종사업장은 4글자를 초과할 수 없습니다." />
                </SimpleItem>
              </GroupItem>
              <GroupItem colSpan={2}>
                <CommonDataGrid
                  gridRef={gridRef}
                  // className={'dx-card wide-card'}
                  dataSource={dataSource}
                  showBorders={true}
                  allowColumnResizing={true}
                  columnHidingEnabled={false}
                  hoverStateEnabled={true}
                  height={150}
                  isHeader={true}
                  isExcel={false}
                  isNotFilterRow
                  headerBefore={[<span> 매출 내역</span>]}
                >
                  <Editing
                    mode="cell"
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={true}
                    onChangesChange={onChangesChange}
                  />
                  <Column
                    dataField={'CostCenterName'}
                    caption={'사업소명'}
                    width={120}
                    alignment={'center'}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'HiParkingID'}
                    caption={'P코드'}
                    width={'90'}
                    alignment={'center'}
                    allowEditing={false}
                    visible={false}
                  />
                  <Column
                    dataField={'SalesTypeName'}
                    caption={'매출유형'}
                    width={80}
                    alignment={'center'}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'DetailSalesTypeName'}
                    caption={'상세매출유형'}
                    width={80}
                    alignment={'center'}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'ItemName'}
                    caption={'항목명'}
                    width={120}
                    alignment={'left'}
                    allowEditing={false}
                    visible={true}
                  />
                  <Column dataField={'FromDate'} visible={false} />
                  <Column dataField={'ToDate'} visible={false} />
                  <Column dataField={'Cnt'} caption={'수량'} width={70} alignment={'center'} />
                  <Column
                    dataField={'SalesUnitPrice'}
                    caption={'단가'}
                    width={80}
                    alignment={'center'}
                    format="#,##0 원"
                  />
                  <Column
                    dataField={'UnitPrice'}
                    caption={'공급가액'}
                    width={100}
                    alignment={'center'}
                    format="#,##0 원"
                  />
                  <Column dataField={'TaxPrice'} caption={'세액'} width={80} alignment={'center'} format="#,##0 원" />
                  <Column
                    dataField={'SalesPrice'}
                    caption={'합계금액'}
                    width={100}
                    alignment={'center'}
                    format="#,##0 원"
                    allowEditing={false}
                  />
                  <Column dataField={'Remark'} caption={'비고'} width={200} alignment={'left'} />
                </CommonDataGrid>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions
                  width={'60%'}
                  type={'default'}
                  useSubmitBehavior={!isAPICalling}
                  text={'저장'}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CostcenterMaster ref={costcenterMasterRef} setSelectedCostCenter={setSelectedCostCenter} />
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </React.Fragment>
  );
};

export default AddTaxinvoice;
