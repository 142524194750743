import React, { useCallback, useState, useImperativeHandle } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { activRadioSelect } from 'common/select/Types';
import { system, error } from 'api';

const AddMenu = React.forwardRef((props, ref) => {
  const { setPopupVisibility, isUpdate, roleList, refresh, setLoadPanelVisible } = props;
  const [menuGroupList, setMenuGroupList] = useState([]);
  const [formData, setFormData] = useState({
    ParentMenuID: 0,
    MenuName: '',
    MenuEnName: '',
    Link: '',
    Icon: '',
    SortOrder: 0,
    IsActive: 1,
    RoleCodeList: [],
  });

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  const open = (type, data) => {
    (async () => {
      await system.getMenuGroupSelect().then(res => {
        if (res.isOk) {
          setMenuGroupList(res.data);
        }
      });
    })();

    if (type === 'update') {
      const newObj = Object.assign({}, data);
      setFormData(newObj);
    } else {
      setFormData({
        ParentMenuID: 0,
        MenuName: '',
        MenuEnName: '',
        Link: '',
        Icon: '',
        SortOrder: 0,
        IsActive: 1,
        RoleCodeList: [],
      });
    }
  };

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { MenuID, ParentMenuID, MenuName, MenuEnName, Link, Icon, SortOrder, IsActive, RoleCodeList } = formData;
      if (isUpdate) {
        setLoadPanelVisible(true);
        await system
          .updateMenu({ MenuID, ParentMenuID, MenuName, MenuEnName, Link, Icon, SortOrder, IsActive, RoleCodeList })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '수정되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
              setTimeout(() => {
                refresh();
              }, 1000);
            }
          });
      } else {
        setLoadPanelVisible(true);
        await system
          .addMenu({ ParentMenuID, MenuName, MenuEnName, Link, Icon, SortOrder, IsActive, RoleCodeList })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '등록되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
            }
            onClose();
            refresh();
          });
      }
    },
    [formData, isUpdate],
  );

  const onClose = () => {
    setPopupVisibility(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          //   ref={formRef}
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={isUpdate ? '메뉴 수정' : '메뉴 등록'}>
            <SimpleItem
              dataField="ParentMenuID"
              editorType="dxSelectBox"
              editorOptions={{
                items: menuGroupList,
                valueExpr: 'key',
                displayExpr: 'text',
              }}
            >
              <Label text="상위 메뉴" />
            </SimpleItem>
            <SimpleItem dataField="MenuID" editorType="dxTextBox" visible={false}>
              <Label text="메뉴명" />
            </SimpleItem>
            <SimpleItem dataField="MenuName" editorType="dxTextBox">
              <RequiredRule message="필수값 입니다." />
              <Label text="메뉴명" />
            </SimpleItem>
            <SimpleItem dataField="MenuEnName" editorType="dxTextBox">
              <Label text="메뉴명(영문)" />
            </SimpleItem>
            <SimpleItem dataField="Link" editorType="dxTextBox">
              <Label text="Link" />
            </SimpleItem>
            <SimpleItem dataField="Icon" editorType="dxTextBox">
              <Label text="Icon" />
            </SimpleItem>
            <SimpleItem dataField="SortOrder" editorType="dxTextBox">
              <Label text="순서" />
            </SimpleItem>
            <SimpleItem
              dataField="RoleCodeList"
              editorType="dxTagBox"
              editorOptions={{
                showSelectionControls: true,
                selectAllMode: 'allPages',
                selectAllText: '전체',
                items: roleList,
                displayExpr: 'text',
                valueExpr: 'key',
                placeholder: '권한',
              }}
            >
              <Label text="권한" />
            </SimpleItem>
            <SimpleItem
              dataField="IsActive"
              editorType="dxRadioGroup"
              editorOptions={{
                items: activRadioSelect,
                valueExpr: 'value',
                displayExpr: 'label',
                layout: 'horizontal',
              }}
            >
              <Label text="사용여부" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default AddMenu;
