import React, { useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { COMPANYCODE } from 'constants/codes';
import { sampleData, columns } from './sample';
import { ticket, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import DataSource from 'devextreme/data/data_source';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';

const IparkingPaymentUploadPage = () => {
  const dataGridRef = useRef({});
  const [CostCenterCode, setCostCenterCode] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [autoText, setAutoText] = useState('');
  const [autoDataSource, setAutoDataSource] = useState([]);
  const [headerText, setHeaderText] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        setAutoDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();
      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = () => {
    if (autoText === '' || autoText === null) {
      alert('사업소를 입력해주세요');
    } else {
      const result = confirm('<i>등록하시겠습니까?</i>', '등록');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          ticket
            .uploadVanIparkingExcel({
              CostCenterCode,
              ItemListJson: JSON.stringify(dataSource),
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '처리되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const onItemClick = e => {
    setCostCenterCode(e.itemData.CostCenterCode);
  };
  const onValueChanged = e => {
    setAutoText(e.value);
  };
  const handleImport = ExcelOrganization => {
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const groupedData = new DataSource({
    store: autoDataSource,
    key: 'CostCenterCode',
  });

  return (
    <div>
      {/* <div className={'content-block'}> */}
      <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <h5>
            {' '}
            ★&nbsp; 아이파킹 엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀
            파일 업로드 &nbsp; ▷ &nbsp; 추가할 사업소명 검색 &nbsp; ▷ &nbsp; 등록
          </h5>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <ExcelImport
            fieldColumn={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R']}
            handleImport={e => handleImport(e.map(importMapFunc))}
          />
        </div>
        <CommonDataGrid
          gridRef={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          height={700}
          isHeader={true}
          isExcel={true}
          excelOptions={{
            sheetName: 'sheet',
            fileName: '아이파킹업로드.xlsx',
          }}
          headerBefore={[
            <Lookup
              labelMode="static"
              stylingMode="outlined"
              dataSource={groupedData}
              valueExpr="CostCenterName"
              displayExpr="CostCenterName"
              searchExpr="CostCenterName"
              searchEnabled={true}
              dropDownCentered={false}
              width={300}
              onValueChanged={onValueChanged}
              placeholder="P-코드/사업소명"
              onItemClick={onItemClick}
              mode="search"
            >
              <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
            </Lookup>,
          ]}
          headerAfter={[
            <Button
              icon="check"
              text="등록"
              width={80}
              type="normal"
              stylingMode="contained"
              onClick={() => saveExcel()}
            />,
            <ExcelExport
              excelFields={headerText}
              excelData={sampleData}
              sheetName="Sheet"
              fileName="iparking_sample.xls"
              buttonName="샘플파일 다운로드"
            />,
          ]}
          allowColumnReordering={true}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <LoadPanel enabled showPane={showPane} />
          <HeaderFilter visible={true} />
          <Column dataField={'사이트코드'} caption={'사이트코드'} hidingPriority={4} />
          <Column dataField={'구분'} caption={'구분'} hidingPriority={4} />
          <Column dataField={'분류'} caption={'분류'} hidingPriority={4} />
          <Column dataField={'승인일자'} caption={'승인일자'} hidingPriority={4} />
          <Column dataField={'원거래일자'} caption={'원거래일자'} hidingPriority={4} />
          <Column dataField={'주문번호'} caption={'주문번호'} hidingPriority={4} />
          <Column dataField={'주문자'} caption={'주문자'} hidingPriority={4} />
          <Column dataField={'카드사'} caption={'카드사'} hidingPriority={4} />
          <Column dataField={'승인번호'} caption={'승인번호'} hidingPriority={4} />
          <Column dataField={'거래금액'} caption={'거래금액'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'수수료'} caption={'수수료'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'가상계좌수수료'} caption={'가상계좌수수료'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'수익분배'} caption={'수익분배'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'지급예정액'} caption={'지급예정액'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'거래상태'} caption={'거래상태'} hidingPriority={4} />
          <Column dataField={'거래번호'} caption={'거래번호'} hidingPriority={4} />
          <Column dataField={'상품명'} caption={'상품명'} hidingPriority={4} />
          <Column dataField={'파일링주차장명'} caption={'파일링주차장명'} hidingPriority={4} />
          <Summary>
            <TotalItem column="거래번호" summaryType="count" displayFormat="총 {0}건" />
            <TotalItem column="거래금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          </Summary>
        </CommonDataGrid>
      </div>
      {/* </div> */}
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default IparkingPaymentUploadPage;
