import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import { COMPANYCODE } from 'constants/codes';

const userId = localStorage.getItem('userId');

export const autoDataSource = new CustomStore({
  key: 'CostCenterCode',
  loadMode: 'raw',
  load: async () => {
    try {
      const result = await axios.post(`master/searchCostCenterForAutoComplete`, {
        CompanyCode: COMPANYCODE,
        SearchText: '',
        LoginID: userId,
      });
      return result.data;
    } catch (e) {
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  },
});

export const autoDataSourceWithAll = new CustomStore({
  key: 'CostCenterCode',
  loadMode: 'raw',
  load: async () => {
    try {
      const result = await axios.post(`master/searchCostCenterForAutoComplete`, {
        CompanyCode: COMPANYCODE,
        SearchText: '',
        LoginID: userId,
        isWithALL : 'Y'
      });
      return result.data;
    } catch (e) {
      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  },
});