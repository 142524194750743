import React, { useCallback, useState, useRef, useImperativeHandle } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

const AddCompany = React.forwardRef((props, ref) => {
  const { setPopupVisibility, isUpdate } = props;
  const [formData, setFormData] = useState({
    CompanyBrand: '',
    CompanyName: '',
    CompanyEnName: '',
    RepresentativeName: '',
    PhoneNumber: '',
    Email: '',
  });
  const formRef = useRef({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  const open = (type, data) => {
    if (type === 'update') {
      console.log('data >', data);
      setFormData(data);
    }
  };

  // 저장
  const onSubmit = useCallback(async e => {
    e.preventDefault();
    //   const { SaleTotal, SalesType, PaymentType, Remark, PamentKeyID } = formData;
    //   await salesPayment
    //     .confirmAccountInfoDetail({
    //       CostCenter: costCenter,
    //       InquiryDate: inquiryDate,
    //       SalesPrice: SaleTotal,
    //       PaymentType,
    //       SalesType,
    //       PamentKeyID,
    //       Remark,
    //       SalesKeyID: '',
    //       RegisterUser: '',
    //     })
    //     .then(res => {
    //       if (res.isOk) {
    //         notify(
    //           {
    //             message: '확정되었습니다.',
    //             width: 230,
    //             position: {
    //               at: 'top',
    //               my: 'top',
    //               of: '#container',
    //             },
    //           },
    //           'success',
    //         );
    //       }
    //       //닫기 및 전체 그리드 리프레쉬
    //       onClose();
    //       props.refresh();
    //     });
  }, []);

  const onClose = () => {
    setPopupVisibility(false);
    setFormData({
      CompanyBrand: '',
      CompanyName: '',
      CompanyEnName: '',
      RepresentativeName: '',
      PhoneNumber: '',
      Email: '',
    });
    // formRef.current.instance.resetValues();
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={isUpdate ? '회사 수정' : '회사 등록'}>
            <SimpleItem dataField="CompanyBrand" editorType="dxTextBox">
              <Label text="회사명" location="top" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem dataField="CompanyName" editorType="dxTextBox">
              <Label text="회사명(한글)" location="top" />
            </SimpleItem>
            <SimpleItem dataField="CompanyEnName" editorType="dxTextBox">
              <Label text="회사명(영문)" location="top" />
            </SimpleItem>
            <SimpleItem dataField="RepresentativeName" editorType="dxTextBox">
              <Label text="대표자명" location="top" />
            </SimpleItem>
            <SimpleItem dataField="PhoneNumber" editorType="dxTextBox">
              <Label text="연락처" location="top" />
            </SimpleItem>
            <SimpleItem dataField="Email" editorType="dxTextBox">
              <Label text="이메일" location="top" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default AddCompany;
