import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import GeneralTicket from './component/taxGeneralTicket';
import SeasonTicket from './component/taxSeasonTicket';
import EtcSales from './component/taxEtcSales';
import BtoBSales from './component/taxBtobSales';

const TaxinvoiceDetailSales = props => {
  const { tGeneralTicketRef, tSeasonTicketRef, tEtcRef, tB2BRef, selectTax } = props;
  const [invoiceID, setInvoiceID] = useState(null);

  const [generalDataSource, setGeneralDataSource] = useState([]);
  const [seasonDataSource, setSeasonDataSource] = useState([]);
  const [etcDataSource, setEtcDataSource] = useState([]);
  const [b2bDataSource, setB2bDataSource] = useState([]);

  useEffect(() => {
    const { InvoiceID } = props.data.data;
    setInvoiceID(InvoiceID);
  }, []);

  useEffect(() => {
    (async () => {
      if (invoiceID) {
        try {
          const result = await axios.post(`aggregation/GetTaxInvoiceSalesList`, {
            InvoiceID: invoiceID,
          });
          setGeneralDataSource(result.data[0]);
          setSeasonDataSource(result.data[1]);
          setEtcDataSource(result.data[2]);
          setB2bDataSource(result.data[3]);
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      }
    })();
  }, [invoiceID]);

  return (
    <React.Fragment>
      <div style={{ paddingTop: '5px' }}>
        <TabPanel deferRendering={false}>
          {generalDataSource.length !== 0 && (
            <Item title="일반권" badge={generalDataSource.length}>
              <GeneralTicket
                tGeneralTicketRef={tGeneralTicketRef}
                dataSource={generalDataSource}
                selectTax={selectTax}
              />
            </Item>
          )}
          {seasonDataSource.length !== 0 && (
            <Item title="정기권" badge={seasonDataSource.length}>
              <SeasonTicket tSeasonTicketRef={tSeasonTicketRef} dataSource={seasonDataSource} selectTax={selectTax} />
            </Item>
          )}
          {etcDataSource.length !== 0 && (
            <Item title="기타" badge={etcDataSource.length}>
              <EtcSales tEtcRef={tEtcRef} dataSource={etcDataSource} selectTax={selectTax} />
            </Item>
          )}
          {b2bDataSource.length !== 0 && (
            <Item title="B2B" badge={b2bDataSource.length}>
              <BtoBSales tB2BRef={tB2BRef} dataSource={b2bDataSource} selectTax={selectTax} />
            </Item>
          )}
        </TabPanel>
      </div>
    </React.Fragment>
  );
};

export default TaxinvoiceDetailSales;
