import React, { useState, useMemo, useCallback, useImperativeHandle } from 'react';
import { Column, Summary, TotalItem, Selection, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { cellStatus } from './common/common';
import { salesPayment } from 'api';
import ContextMenu from 'devextreme-react/context-menu';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';
import moment from 'moment';

const Sales = React.forwardRef((props, ref) => {
  const { inquiryDate, costCenter, setSRowdata, SalesRef, onCancelClick, setSelectedID, setSelectedAccountingNo } =
    props;
  const [selectSum, setSelectSum] = useState(0);
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const inquirydate = inquiryDate.replace('-', '');
        var result = await salesPayment.getBtoBAcquiredSalesDetail({
          SalesMonth: inquirydate,
          SalesCategory : 'PCMSales',
          CostCenterCode: costCenter,
        });
        return result.data;
      },
    });
    return customDataSource;
  }, [inquiryDate, costCenter]);
  useImperativeHandle(ref, () => ({
    focusChange,
  }));
  const focusChange = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    SalesRef.current.instance.selectRows(KeyIDs);
  };
  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = SalesRef.current.instance.getSelectedRowsData();
    setSRowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return '선택 합계 : ' + selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { KeyID, AccountingNo } = data;
      setSelectedID(AccountingNo ? KeyID : 'N/A');
      setSelectedAccountingNo('');
      if (AccountingNo) {
        focusChange(AccountingNo);
      }
    }
  };
  return (
    <React.Fragment>
      <CommonDataGrid
        gridRef={SalesRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={e => onSelectionChanged(e)}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매입/매출.xlsx',
        }}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'KeyID'} caption={'키'} width={100} visible={false} />
        <Column dataField={'DetailSalesTypeName'} caption={'매출유형'} width={100} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={160} />
        <Column dataField={'CorpRegNumber'} caption={'사업자코드'} width={100} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={100} />
        <Column dataField={'SalesDate'} caption={'매출일'} width={100} customizeText={customizeText} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} cellRender={editCellRender} />
        <Column dataField={'RemainAmount'} caption={'잔액'} width={150} format="#,##0 원" />
        <Column dataField={'SalesPrice'} caption={'매출액(정산액)'} width={150} format="#,##0 원" />
        <Column dataField={'UnitPrice'} caption={'원매출'} width={100} format="#,##0 원" />
        <Column dataField={'Commission'} caption={'수수료'} width={100} format="#,##0 원" />
        <Column dataField={'Quantity'} caption={'매출수량'} width={100} />
        <Column dataField={'Remark'} caption={'비고'} width={100} />
        <Column dataField={'IsTaxFree'} caption={'부가세없음'} width={50} />
        <Column dataField={'HiparkingID'} caption={'P코드'} width={100} />
        <Summary>
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="CustomerCorpName" />
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="UnitPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="UnitPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    </React.Fragment>
  );
});

export default React.memo(Sales);
