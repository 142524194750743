import { React, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { LoadPanel as PSLoadPanel, To } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
// import { PivotGrid, FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import {
  Column,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  SearchPanel,
  Summary,
  TotalItem,
  Scrolling,
  Selection,
} from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import { aggregation } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import './view-accounting-status.scss';
import Bullet, { Font, Margin, Size, Tooltip } from 'devextreme-react/bullet';
import PieChart, { Legend, Series, Label, Connector } from 'devextreme-react/pie-chart';
import axios from 'axios';

const ViewAcountingStatusPage = () => {
  const allgridRef = useRef({});
  const gridRef = useRef({});
  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(3, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(3, 'day').format('YYYY-MM-DD'),
  });
  const [byCCCheck, setByCCCheck] = useState('N');
  const [byDateCheck, setByDateCheck] = useState('N');
  const [dataSource, setDataSource] = useState([]);
  const [dataChartSource, setDataChartSource] = useState([]);
  const [detailDataSource, setDetailDataSource] = useState({});

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const editCellRender = e => {
    return (
      <Bullet
        showTarget={true}
        showZeroLevel={true}
        value={e.data.AccountingRate}
        startScaleValue={0}
        endScaleValue={100}
      >
        <Size width={150} height={35} />
        <Margin top={5} bottom={0} left={5} />
        <Tooltip enabled={true} paddingTopBottom={2} zIndex={5} customizeTooltip={customizeTooltip}>
          <Font size={18} />
        </Tooltip>
      </Bullet>
    );
  };
  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };
  const onByCCCheckValueChanged = e => {
    e.value ? setByCCCheck('Y') : setByCCCheck('N');
  };
  const onByDateCheckValueChanged = e => {
    e.value ? setByDateCheck('Y') : setByDateCheck('N');
  };

  function customizeTooltip(data) {
    return {
      text: `${data.value}%`,
    };
  }

  const onSearch = () => {
    gridRef.current?.instance.clearSelection();
    setDetailDataSource({});
    callApi();
  };

  const callApi = () => {
    const search = {
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      ByCostCenter: byCCCheck,
      ByDate: byDateCheck,
    };
    (async () => {
      const userId = localStorage.getItem('userId');
      setLoadPanelVisible(true);
      try {
        const result = await axios.post(`aggregation/report/getAccountingDashboard`, {
          ...search,
          LoginID: userId,
        });
        const dataSource = result.data[0];
        setDataSource(dataSource);
        setDataChartSource(dataSource);
        setLoadPanelVisible(false);
      } catch (e) {
        setLoadPanelVisible(false);
        const { type, title, status, detail, instance } = e.response.data;
        notify(
          {
            message: JSON.parse(e.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    })();
  };

  const customizeText = cellInfo => {
    return `${cellInfo.argument}: ${cellInfo.valueText}`;
  };

  const onSelectionChanged = async ({ selectedRowsData }) => {
    const data = selectedRowsData[0];
    if (data) {
      const { CardAgencyList } = data;
      const { fromDate, toDate } = dateValue;
      const CostCenterCode = data.HiParkingID || '';
      const PaymentDate = data.PaymentDate || '';
      const PaymentCode = data.PaymentCode || '';
      const result = await aggregation.getAccountingDashboardDetail({
        CostCenterCode,
        PaymentDate,
        CardAgencyList,
        PaymentCode,
        FromDate: fromDate,
        ToDate: toDate,
      });
      const utcToKst = utc => {
        const utcDate = moment.utc(utc);
        const kstDate = utcDate.tz('Asia/Seoul');
        return kstDate;
      };

      if (result.isOk) {
        const kstResult = result.data[0].map(a => {
          const transactionDate = utcToKst(a.TransactionDate).format('YYYY-MM-DD HH:mm:ss');
          return { ...a, TransactionDate: transactionDate };
        });
        setDetailDataSource(kstResult);
      } else {
        notify(
          {
            message: JSON.parse(result.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
        setDetailDataSource({});
      }
    }
  };

  return (
    <div>
      <h2 className={'content-block'}>매출 대사 현황</h2>
      <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
        <div className={'dx-card responsive-paddings'} style={{ marginBottom: '10px', width: '60%' }}>
          <CommonDataGrid
            gridRef={gridRef}
            dataSource={dataSource}
            className={'dx-card wide-card lambda-history'}
            showBorders={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnAutoWidth={false}
            columnHidingEnabled={false}
            rowAlternationEnabled={false} // 수정
            hoverStateEnabled={true}
            onSelectionChanged={onSelectionChanged}
            height={480}
            isHeader={true}
            isExcel={true}
            width="100%"
            headerBefore={[
              <div className="label">대상날짜 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />,
              <div className="label">~</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                onValueChanged={handleToDate}
                value={dateValue.toDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
            ]}
            headerAfter={[
              <CheckBox defaultValue={false} text="일자별 조회   " onValueChanged={onByDateCheckValueChanged} />,
              <CheckBox defaultValue={false} text="사업소별 조회   " onValueChanged={onByCCCheckValueChanged} />,
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Selection mode="single" />
            <Scrolling mode="infinite" />
            {byDateCheck === 'Y' ? <Column dataField="PaymentDate" caption="결제일" dataType="date" /> : <></>}
            {byCCCheck === 'Y' ? <Column dataField="HiParkingID" caption="P코드" dataType="string" /> : <></>}
            {byCCCheck === 'Y' ? <Column dataField="CostCenterName" caption="사업소명" dataType="string" /> : <></>}
            <Column dataField="CardAgencyListName" caption="결제사" dataType="string" />
            <Column dataField="PaymentCodeName" caption="수금구분" dataType="string" />
            <Column dataField="SalesPrice" caption="결제금액" dataType="number" format="#,##0 원" alignment="right" />
            <Column
              dataField="AccountingPrice"
              caption="대사완료금액"
              dataType="number"
              format="#,##0 원"
              alignment="right"
            />
            <Column dataField="NotPayedCnt" caption="미처리건수" dataType="number" alignment="right" />
            <Column
              dataField="RemainAccountingPrice"
              caption="미처리금액"
              dataType="number"
              format="#,##0 원"
              alignment="right"
            />
            <Column
              dataField="AccountingRate"
              name="AccountingRateText"
              caption="미처리비율(%)"
              dataType="number"
              alignment="right"
              customizeText={cellInfo => `${cellInfo.value.toFixed(2)}%`}
            />
            <Column
              dataField="AccountingRate"
              caption="미처리비율"
              name="AccountingRateGraph"
              dataType="number"
              format="percent"
              alignment="right"
              width={150}
              allowGrouping={false}
              cellRender={editCellRender}
              cssClass="bullet"
            />
            <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />
            <Paging defaultPageSize={100} />
          </CommonDataGrid>
        </div>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '38%' }}>
          <div className="caption">미처리금액(결제사 별)</div>
          <div style={{ padding: '20px' }}>
            <PieChart
              id="pie"
              type="doughnut"
              palette="Material"
              dataSource={dataChartSource}
              resolveLabelOverlapping="shift"
            >
              <Size height={400} width={'90%'} />
              <Series argumentField="CardAgencyListName" valueField="RemainAccountingPrice">
                <Label visible={true} format="#,##0 원" customizeText={customizeText}>
                  <Font size={13} />
                  <Connector visible={true} width={0.5} />
                </Label>
              </Series>
              <Legend
                orientation="horizontal"
                itemTextPosition="right"
                horizontalAlignment="center"
                verticalAlignment="bottom"
                columnCount={4}
              />
            </PieChart>
          </div>
        </div>
      </div>
      <div className={'content-block'}>
        <div>
          <div className={'dx-card'} style={{ padding: '20px 40px' }}>
            <div className="label" style={{ padding: '5px 0px' }}>
              매출 대사 현황 상세 조회
            </div>
            <CommonDataGrid
              className={'dx-card wide-card accounting-payment-report'}
              dataSource={detailDataSource}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              height={450}
              isNotFilterRow
              isHeader={false}
              isExcel={false}
              isInfiniteScroll={false}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '매출 대사 현황 상세 조회.xlsx',
              }}
            >
              {/* <Column dataField={'rowid'} alignment={'center'} /> */}
              {/* <Column dataField={'KeyID'} alignment={'left'} /> */}
              <Column dataField={'CostCenterCode'} caption={'사업소코드'} alignment={'left'} width={90} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} width={90} />
              <Column dataField={'CardAgencyType'} caption={'결제사유형'} alignment={'left'} width={80} />
              <Column dataField={'CardAgencyListName'} caption={'결제사명'} alignment={'left'} width={80} />
              <Column dataField={'PaymentCodeName'} caption={'수금구분'} dataType="string" />
              <Column dataField={'TransactionDate'} caption={'결제시간'} alignment={'left'} width={150} />
              {/* <Column dataField={'TransactionID'} alignment={'center'} /> */}
              {/* <Column dataField={'CardType'} alignment={'center'} /> */}
              <Column dataField={'CardTypeName'} caption={'결제유형'} alignment={'left'} width={90} />
              {/* <Column dataField={'ApprovalType'} alignment={'center'} /> */}
              <Column dataField={'ApprovalTypeName'} caption={'승인유형'} alignment={'left'} width={90} />
              {/* <Column dataField={'Status'} alignment={'center'} /> */}
              <Column dataField={'StatusName'} caption={'매출상태'} alignment={'left'} width={90} />
              <Column dataField={'SalesPrice'} caption={'결제금액'} alignment={'right'} format="#,##0 원" width={110} />
              <Column
                dataField={'AccountingPrice'}
                caption={'대사금액'}
                alignment={'right'}
                format="#,##0 원"
                width={110}
              />
              <Column dataField="NotPayedCnt" caption="미처리건수" dataType="number" alignment="right" />
              <Column
                dataField={'RemainAccountingPrice'}
                caption={'미처리금액'}
                alignment={'right'}
                format="#,##0 원"
                width={110}
              />
              <Column dataField={'TerminalID'} caption={'단말기/계좌번호'} alignment={'left'} width={120} />
              <Column dataField={'ApprovalNo'} caption={'승인번호/입금자명'} alignment={'left'} width={90} />
              {/* <Column dataField={'OrderID'} alignment={'center'} /> */}
              <Column dataField={'CreditCardNo'} caption={'카드사/은행'} alignment={'left'} width={100} />
              <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'left'} />
              <Summary>
                <TotalItem column="SalesPrice" summaryType="sum" displayFormat="합계: " />
                <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
                <TotalItem column="AccountingPrice" summaryType="sum" displayFormat="합계: " />
                <TotalItem column="AccountingPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
                <TotalItem column="RemainAccountingPrice" summaryType="sum" displayFormat="합계: " />
                <TotalItem
                  column="RemainAccountingPrice"
                  summaryType="sum"
                  valueFormat="#,##0 원"
                  displayFormat="{0}"
                />
              </Summary>
            </CommonDataGrid>
            <PSLoadPanel
              shadingColor="rgba(0,0,0,0.4)"
              position={{ of: '.content' }}
              // onHiding={hideLoadPanel}
              visible={loadPanelVisible}
            />
          </div>
        </div>
      </div>
      {/* <div className={'content-block'}>
        <h3>전체 이력</h3>
        <div className={'dx-card responsive-paddings'}>
          <CommonDataGrid
            gridRef={allgridRef}
            className={'dx-card wide-card'}
            dataSource={dataSourcedetail}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={true}
            height={650}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '관제연동이력_전체.xlsx',
            }}
          >
            <Scrolling mode="infinite" />
            <Column dataField={'TargetDate'} caption={'연동일'} />
            <Column dataField={'StartDateTime'} caption={'연동시작시간'} customizeText={customizeText} />
            <Column dataField={'EndDateTime'} caption={'연동종료시간'} customizeText={customizeText} />
            <Column dataField={'HiParkingID'} caption={'P코드'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} />
            <Column dataField={'VendorName'} caption={'관제시스템공급사'} />
            <Column dataField={'SWVersionName'} caption={'SW버전'} />
            <Column dataField={'Result'} caption={'결과'} />
            <Column dataField={'InterfaceRemark'} caption={'메세지'} />
            <Column dataField={'LambdaFunctionName'} caption={'AWS람다함수명'} />
          </CommonDataGrid>
        </div>
      </div> */}
    </div>
  );
};

export default ViewAcountingStatusPage;
