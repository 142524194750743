import React, { useCallback, useEffect, useState, useRef } from 'react';
import Form, { SimpleItem, GroupItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import { Column, Selection, Scrolling } from 'devextreme-react/data-grid';
import { Toast, Position } from 'devextreme-react/toast';
import { ticket, error } from 'api';
import moment from 'moment';
import CommonDataGrid from 'common/default-data-grid';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import notify from 'devextreme/ui/notify';
import 'devextreme-react/text-area';
import './season-ticket-member-form.scss';

const SeasonTicketMemberGroupForm = ({
  setGroupPopup,
  selectItemCode,
  groupType,
  groupPopup,
  setGroupItemsUpdate,
  isGroupItemsUpdate,
  setLoadPanelVisible,
}) => {
  const formRef = useRef({});
  const selectRef = useRef({});
  const customInfoRef = useRef({});
  const [memberGroup, setMemberGroup] = useState();
  const [isCreate, setIsCreate] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectMemberDivision, setSelectMemberDivision] = useState();
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);
  const [formData, setFormData] = useState({
    GroupName: '',
    GroupPrice: '',
    MemberDivision: '',
    // 매입자 정보 추가
    CorpRegNumber: '',
    CustomerName: '',
    NameOfRepresentative: '',
    CustomerCode: '',
  });
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  });

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setFormData({
        ...formData,
        CorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberDivision'],
      });
      const MemberDivision = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberDivision') {
            return MemberDivision.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectMemberDivision(MemberDivision);
    })();
  }, []);

  useEffect(() => {
    if (groupPopup.visible) {
      const newArr = [];
      for (var i = 0; i < groupType.length; i++) {
        const newObj = copyObj(groupType[i]);
        newArr.push(newObj);
      }
      setMemberGroup(newArr);
    }
  }, [groupPopup.visible, isRefresh]);

  useEffect(() => {
    const newArr = [];
    for (var i = 0; i < groupType.length; i++) {
      const newObj = copyObj(groupType[i]);
      newArr.push(newObj);
    }
    setMemberGroup(newArr);
  }, [groupType]);

  const copyObj = obj => {
    let copy = {};

    if (typeof obj === 'object' && obj !== null) {
      for (let a in obj) {
        if (obj.hasOwnProperty(a)) {
          copy[a] = copyObj(obj[a]);
        }
      }
    } else {
      copy = obj;
    }
    return copy;
  };

  const clearData = () => {
    selectRef.current.instance.deselectAll();
    setFormData({});
    setIsCreate(true);
  };

  const onClose = () => {
    setGroupPopup({ save: false, visible: false });
    clearData();
    setIsRefresh(!isRefresh);
  };

  const onCreateButton = () => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      message: '새 그룹을 추가합니다.',
      type: 'info',
    });
    clearData();
  };
  const onDeleteButton = () => {
    if (formData.GroupName && formData.GroupCode && !isCreate) {
      const deleteParam = {
        CostCenterCode: selectItemCode.CostCenterCode,
        GroupCode: formData.GroupCode,
      };
      (async () => {
        setLoadPanelVisible(true);
        const result = await ticket.deleteSeasonTicketMemberGroup(deleteParam);
        if (result.isOk > 0 && result.data) {
          setLoadPanelVisible(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: '삭제 되었습니다.',
            type: 'success',
          });
          clearData();
          setGroupItemsUpdate(!isGroupItemsUpdate);
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: JSON.parse(result.error.detail).sqlMessage,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      })();
    }
  };

  const onSelectionChanged = e => {
    if (e.selectedRowsData.length > 0) {
      setIsRefresh(!isRefresh);
      setFormData(e.selectedRowsData[0]);
      setIsCreate(false);
    }
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onHiding = () => {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (formData.GroupName) {
        var groupPrice = formData.GroupPrice ? formData.GroupPrice : 0;
        var updateParam = {
          CostCenterCode: selectItemCode.CostCenterCode,
          GroupCode: formData.GroupCode,
          GroupName: formData.GroupName,
          GroupPrice: groupPrice,
          MemberDivision: formData.MemberDivision,
          RegisterUser: formData.RegisterUser,
          CustomerCode: formData.CustomerCode,
        };
        setLoadPanelVisible(true);
        isCreate
          ? await ticket.insertSeasonTicketMemberGroup(updateParam).then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                setToastConfig({
                  ...toastConfig,
                  isVisible: true,
                  message: '저장 되었습니다.',
                  type: 'success',
                });
                setGroupItemsUpdate(!isGroupItemsUpdate);
                clearData();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            })
          : await ticket.updateSeasonTicketMemberGroup(updateParam).then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                setToastConfig({
                  ...toastConfig,
                  isVisible: true,
                  message: '저장 되었습니다.',
                  type: 'success',
                });
                setGroupItemsUpdate(!isGroupItemsUpdate);
                clearData();
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
      }
    },
    [selectItemCode, formData, isCreate, isRefresh],
  );

  const onFocusIn = e => {
    e.event.target.blur();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container" id="test">
          <Form
            id="form"
            ref={formRef}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
            formData={formData}
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={'정기권 그룹 설정'}>
              <GroupItem>
                <CommonDataGrid
                  gridRef={selectRef}
                  dataSource={memberGroup}
                  showBorders={true}
                  columnAutoWidth={true}
                  columnHidingEnabled={true}
                  hoverStateEnabled={true}
                  onSelectionChanged={onSelectionChanged}
                  isHeader={false}
                  isExcel={false}
                  height={380}
                >
                  <Scrolling mode="virtual" rowRenderingMode="virtual" />
                  <Selection mode="single" />
                  <Column dataField={'CostCenterName'} caption={'사업소이름'} width={100} hidingPriority={5} />
                  <Column dataField={'GroupName'} caption={'그룹명'} width={120} hidingPriority={4} />
                  <Column
                    dataField={'GroupPrice'}
                    caption={'그룹금액'}
                    alignment={'left'}
                    width={100}
                    hidingPriority={4}
                    format="#,##0 원"
                  />
                  <Column
                    dataField={'MemberDivisionName'}
                    caption={'그룹구분'}
                    alignment={'left'}
                    width={80}
                    hidingPriority={3}
                  />
                  <Column
                    dataField={'CorpRegNumber'}
                    caption={'사업자등록번호'}
                    alignment={'left'}
                    width={100}
                    hidingPriority={1}
                  />
                  <Column
                    dataField={'CustomerCorpName'}
                    caption={'사업자명'}
                    alignment={'left'}
                    width={200}
                    hidingPriority={1}
                  />
                  <Column
                    dataField={'RegisterUser'}
                    caption={'등록자'}
                    alignment={'left'}
                    width={100}
                    hidingPriority={2}
                  />
                  <Column
                    dataField={'RegisterDate'}
                    caption={'등록일'}
                    alignment={'left'}
                    hidingPriority={1}
                    customizeText={customizeText}
                  />
                </CommonDataGrid>
              </GroupItem>
              <GroupItem colCount={13}>
                <SimpleItem dataField="GroupName" colSpan={5}>
                  <Label text="그룹명" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                {/* CustomerCorpRegNumber 사용하지 않음 */}
                <SimpleItem
                  dataField="CorpRegNumber"
                  editorType="dxTextBox"
                  colSpan={6}
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업자 선택',
                          onClick: () => {
                            customInfoRef.current.open();
                          },
                        },
                      },
                    ],
                    // value: formData.CorpRegNumber,
                  }}
                >
                  <Label text="사업자등록번호" />
                </SimpleItem>
                <ButtonItem colSpan={2}>
                  <ButtonOptions
                    type={'default'}
                    text="신규"
                    width={'100%'}
                    onClick={onCreateButton}
                    disabled={isCreate}
                  ></ButtonOptions>
                </ButtonItem>

                <SimpleItem
                  dataField="MemberDivision"
                  colSpan={5}
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: selectMemberDivision,
                    displayExpr: 'text',
                    valueExpr: 'code',
                  }}
                >
                  <Label text="그룹구분" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                {/* CustomerCorpRegNumber 사용하지 않음 */}
                <SimpleItem
                  dataField="CustomerCorpName"
                  editorType="dxTextBox"
                  colSpan={6}
                  editorOptions={{
                    readOnly: true,
                    // value: formData.CustomerName,
                  }}
                >
                  <Label text="회사명" />
                </SimpleItem>

                <ButtonItem colSpan={2}>
                  <ButtonOptions
                    type={'default'}
                    width={'100%'}
                    text="저장하기"
                    useSubmitBehavior={true}
                    // onClick={onSaveButton}
                    onClick={onSubmit}
                  ></ButtonOptions>
                </ButtonItem>

                <SimpleItem
                  dataField="GroupPrice"
                  colSpan={5}
                  editorType="dxNumberBox"
                  editorOptions={{
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0 원',
                  }}
                >
                  <Label text="그룹금액" />
                </SimpleItem>
                <SimpleItem
                  dataField="NameOfRepresentative"
                  editorType="dxTextBox"
                  colSpan={6}
                  editorOptions={{
                    readOnly: true,
                    // value: formData.NameOfRepresentative,
                  }}
                >
                  <Label text="대표자 성명" />
                </SimpleItem>
                <ButtonItem colSpan={2}>
                  <ButtonOptions
                    type={'default'}
                    width={'100%'}
                    text="삭제하기"
                    onClick={onDeleteButton}
                  ></ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
          <Toast
            visible={toastConfig.isVisible}
            message={toastConfig.message}
            type={toastConfig.type}
            width={'10px'}
            colSpan={3}
            onHiding={onHiding}
            displayTime={500}
          >
            <Position my="top" at="top" of="#test" />
          </Toast>
        </div>
      </form>
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </>
  );
};

export default React.memo(SeasonTicketMemberGroupForm);
