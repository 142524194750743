import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import './compliance.scss';

const Compliance = () => {
  const history = useHistory();

  return (
    <>
      <h3 className={'content-block'}>
        <div style={{ display: 'flex' }}>
          <div style={{ fontWeight: 'bold' }}>규제 관리</div>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <Button
            icon="plus"
            text="규제 등록"
            onClick={() => {
              history.push('/compliance/save');
            }}
          />
        </div>
      </h3>
      <div className={'content-block'}>
        <DataGrid
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={false}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
        >
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <Column dataField={'Task_ID'} caption={'#'} hidingPriority={9} />
          <Column dataField={'Task_Subject'} width={190} caption={'규제명'} hidingPriority={8} />
          <Column dataField={'Task_Priority'} caption={'버전'} hidingPriority={7} />
          <Column dataField={'Task_Priority'} caption={'규제항목'} hidingPriority={6} />
          <Column dataField={'Task_Priority'} caption={'사용'} hidingPriority={5} />
          <Column dataField={'Task_Status'} caption={'검토요청'} hidingPriority={4} />
          <Column dataField={'Task_Completion'} caption={'적용여부'} hidingPriority={3} />
          <Column dataField={'Task_Priority'} caption={'제개여부'} name={'Priority'} hidingPriority={2} />
          <Column dataField={'Task_Due_Date'} caption={'수정자'} dataType={'date'} hidingPriority={1} />
          <Column dataField={'Task_Start_Date'} caption={'수정일'} dataType={'date'} hidingPriority={0} />
        </DataGrid>
      </div>
    </>
  );
};

const dataSource = {
  store: {
    type: 'odata',
    key: 'Task_ID',
    url: 'https://js.devexpress.com/Demos/DevAV/odata/Tasks',
  },
  select: [
    'Task_ID',
    'Task_Subject',
    'Task_Start_Date',
    'Task_Due_Date',
    'Task_Status',
    'Task_Priority',
    'Task_Completion',
  ],
};

export default Compliance;
