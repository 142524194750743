import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import CommonDataGrid from 'common/default-data-grid';

/*
일반권/ 정기권/ 기타 항목에대해서는 공통으로 가져가고 있었으나 관리 항목이 많아져 
'영수 세금계산서'페이지는 따로 관리하도록 세페이지 추가
*/

const SeasonTicket = props => {
  const { dataSource, seasonTicketRef, setSRowdata } = props;
  const [selectSum, setSelectSum] = useState(0);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = e => {
    const selectedData = seasonTicketRef.current.instance.getSelectedRowsData();
    const sLength = selectedData.length;
    if (selectedData && sLength > 0) {
      const sumCashPrice = selectedData.reduce((sum, a) => sum + a.CashPrice, 0);
      const sumSalesPrice = selectedData.reduce((sum, a) => sum + a.SalesPrice, 0);
      setSelectSum([sumCashPrice, sumSalesPrice]);
    } else {
      setSelectSum([]);
    }
    setSRowdata(sLength);
  };
  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };

  const customizeSelectCashTotal = e => {
    const value = selectSum[0] || 0;
    return value.toLocaleString('ko-KR') + ' 원';
  };

  const customizeSelectSalesTotal = e => {
    const value = selectSum[1] || 0;
    return value.toLocaleString('ko-KR') + ' 원';
  };

  return (
    <>
      <CommonDataGrid
        gridRef={seasonTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={595}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        loadPanel={{ enabled: true }}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '정기권.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
        <Column dataField={'CashLastPayDate'} caption={'수금일'} customizeText={customizeText} width={'100'} />
        <Column dataField={'CashBillID'} caption={'현금영수증ID'} width={110} />
        {/* <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} /> */}
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={'100'} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
        <Column dataField={'CashPrice'} caption={'현금수금액'} format="#,##0 원" width={'140'} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} width={170} />
        <Column dataField={'AccountingRegisterDate'} caption={'대사날짜'} customizeText={customizeText} width={160} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={'140'} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={'100'} />
        <Column dataField={'FromDate'} caption={'시작일'} width={'100'} />
        <Column dataField={'ToDate'} caption={'종료일'} width={'100'} />
        <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={'100'} />
        <Column dataField={'MobliePhoneNo'} caption={'전화번호'} width={'100'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} width={'100'} />
        <Column dataField={'MemberName'} caption={'고객명'} width={'100'} />
        <Column dataField={'ApprovedDate'} caption={'승인시간'} customizeText={customizeText} width={'100'} />
        <Column dataField={'Remark'} caption={'비고'} width={'100'} />
        <Summary>
          <TotalItem showInColumn="CashLastPayDate" displayFormat="선택 매출액:" />
          <TotalItem customizeText={customizeSelectSalesTotal} column="SalesPrice" showInColumn="CashLastPayDate" />
          <TotalItem showInColumn="CashBillID" displayFormat="현금 수금액:" />
          <TotalItem customizeText={customizeSelectCashTotal} column="CashPrice" showInColumn="CashBillID" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(SeasonTicket);
