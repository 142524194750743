import React, { useEffect, useState, useRef } from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
// import axios from 'axios';
import './DataGridPage.scss';
import CodeMngForm from './components/form/CodeMngForm';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import ScrollView from 'devextreme-react/scroll-view';
// import CustomStore from 'devextreme/data/custom_store';

const testData = [];
for (let i = 1; i < 30; i++) {
  testData.push({
    id: i,
    groupCode: 'test' + i,
    codeNameByKor: 'test' + i,
    codeNameByEng: 'test' + i,
    parentCode: 'test' + i,
    blank: 'test' + i,
    sort: 'test' + i,
    isUsed: '사용',
  });
}

// 실 데이터 가져올때
// const customDataSource = new CustomStore({
//   key: 'id',
//   loadMode: 'raw',
//   load: async () => {
//     try {
//       const result = await axios.post(`master/codemaster/searchCodeGroup`);
//       return result.data;
//     } catch (e) {
//       // type: '/errors/server-error',
//       // title: 'API 500 Error',
//       // status: 500,
//       // detail: JSON.stringify(e),
//       // instance: '/master/codemaster/searchCodeGroup',
//       const { type, title, status, detail, instance } = e.response.data;
//       throw Error(JSON.stringify({ type, title, status, detail, instance }));
//     }
//   },
// });

const DataGridPage = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [item, setItem] = useState(testData);
  const [selectItem, setSelectItem] = useState([]);
  const [text, setText] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({});

  const codeMngRef = useRef(null);

  useEffect(() => {
    handleSelectItem();
  }, [item]);

  const togglePopup = (type, data) => {
    const _isUpdate = type === 'update' ? true : false;
    setFormData(data);
    setIsUpdate(_isUpdate);
    setPopupVisibility(!isPopupVisible);
  };

  // 실제 저장할땐 필요없는 코드
  const addDataSource = (data) => {
    data.id = item[item.length - 1].id + 1;
    return setItem([...item, data]);
  };

  // 실제 수정할땐 필요없는 코드
  const updateDataSource = (data) => {
    const result = item.map((i) => {
      return i.id === data.id ? data : i;
    });
    return setItem(result);
  };

  const editCellRender = (e) => {
    return (
      <Button
        icon="edit"
        width={120}
        text="수정"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup('update', e.data)}
      />
    );
  };

  const handleSelectItem = () => {
    let result = [];
    if (item.length > 0) {
      result = item.map((a) => a.groupCode);
      setSelectItem(result);
    }
  };

  // 검색 api
  // 차후 item을 클릭할때마다 item 기준으로 조회되야함.
  // 조회 버튼 클릭시
  const onSelect = (e) => {
    console.log('e.itemData >>', e.itemData);
  };

  // 차후 조회 버튼 클릭시 사용
  const onChange = (e) => {
    console.log('e.component._changedValue>>', e.component._changedValue);
    setText(e.component._changedValue);
  };

  // 검색 api
  //차후 text 검색시 엔터 누르면 조회 되도록
  const handleSearch = (e) => {
    console.log('e.component._changedValue>>', e.component._changedValue);
  };

  // 검색 api
  // 차후 text 입력후 조회버튼 클릭시 조회 되도록
  const onSearch = (e) => {
    console.log('text >', text);
  };
  return (
    <div>
      <h2 className={'content-block'}>grid 샘플</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flexGrow: 1, display: 'flex' }}>
              <div style={{ marginRight: '5px' }}>
                <SelectBox
                  labelMode="static"
                  placeholder="select"
                  dropDownButtonTemplate="dropDownButton"
                  stylingMode="outlined"
                  dataSource={selectItem}
                  onItemClick={onSelect}
                />
              </div>
              <TextBox
                labelMode="static"
                stylingMode="outlined"
                onChange={onChange}
                onEnterKey={handleSearch}
              />
            </div>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <div style={{ marginRight: '5px' }}>
                <Button
                  icon="search"
                  text="조회"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={onSearch}
                />
              </div>
              <Button
                icon="plus"
                text="등록"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={() => togglePopup('add')}
              />
            </div>
          </div>
          <DataGrid
            className={'dx-card wide-card'}
            dataSource={item}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            hoverStateEnabled={true}
            // stylingMode="outlined"
          >
            <Paging defaultPageSize={10} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Column dataField={'id'} caption={'#'} hidingPriority={9} />
            <Column
              dataField={'groupCode'}
              width={190}
              caption={'그룹 코드'}
              hidingPriority={8}
            />
            <Column
              dataField={'codeNameByKor'}
              caption={'코드명(kor)'}
              hidingPriority={7}
            />
            <Column
              dataField={'codeNameByEng'}
              caption={'코드명(eng)'}
              hidingPriority={6}
            />
            <Column dataField={'blank'} caption={'비고'} hidingPriority={4} />
            <Column
              dataField={'sort'}
              caption={'정렬순서'}
              hidingPriority={3}
            />
            <Column
              dataField={'isUsed'}
              caption={'사용여부'}
              hidingPriority={2}
            />
            <Column
              dataField={'edit'}
              caption={'수정'}
              hidingPriority={1}
              cellRender={editCellRender}
            />
            {/* <Column
            dataField={'Task_Due_Date'}
            caption={'수정자'}
            dataType={'date'}
            hidingPriority={1}
          />
          <Column
            dataField={'Task_Start_Date'}
            caption={'수정일'}
            dataType={'date'}
            hidingPriority={0}
          /> */}
          </DataGrid>
        </div>
      </div>
      <Popup
        ref={codeMngRef}
        visible={isPopupVisible}
        showTitle={false}
        width="40%"
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'}>
            <CodeMngForm
              codeMng={formData}
              isUpdate={isUpdate}
              addToDataSource={addDataSource}
              updateDataSource={updateDataSource}
              setPopupVisibility={setPopupVisibility}
            />
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
};

// const dataSource = {
//   store: {
//     type: 'odata',
//     key: 'Task_ID',
//     url: 'https://js.devexpress.com/Demos/DevAV/odata/Tasks',
//   },
//   select: [
//     'Task_ID',
//     'Task_Subject',
//     'Task_Start_Date',
//     'Task_Due_Date',
//     'Task_Status',
//     'Task_Priority',
//     'Task_Completion',
//   ],
// };

export default DataGridPage;
