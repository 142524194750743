import React, { useEffect, useState, useRef } from 'react';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { rentalSalesMngManualLink } from 'constants/hyperlink';
import './rental-sales-mgmt.scss';
// import RentalSaleMgmtWithInvoice from './rental-sales-view';
// import rentalSalesView from './rental-sales-view';
import RentalSalesMasterPage from './tabs/rental-sales-master';
import RentalSalesTaxInvoice from './tabs/rental-sales-taxInvoice';
import RentalSalesTaxInvoiceChecker from './tabs/check-sales-payment';
// import RentalSalesView from './rental-sales-view';
// import RentalSalesImportExcel from './rental-sales-upload';

const RentalSalesManagement = () => {
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const rentalSaleMgmtWithInvoiceRef = useRef({});

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onHiding = () => {};

  return (
    <div>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>렌탈 매출 관리</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={rentalSalesMngManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'rental-grid'}>
        <div className={'content-block'}>
          <TabPanel>
            <Item title={'렌탈 마스터 관리'}>
              <RentalSalesMasterPage />
            </Item>
            <Item title={'세금계산서 발행 관리'}>
              <RentalSalesTaxInvoice />
            </Item>
            <Item title={'렌탈 매출대사'}>
              <RentalSalesTaxInvoiceChecker />
            </Item>
          </TabPanel>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
      <Popup onHiding={onHiding} showTitle={false} width="70%" height={900}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}></div>
          렌탈 매출/세금계산서 관리
        </ScrollView>
      </Popup>
    </div>
  );
};

export default RentalSalesManagement;
