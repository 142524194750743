import React, { useCallback, useRef, useState } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import { salesPayment, error } from 'api';
import notify from 'devextreme/ui/notify';

const EditPrice = props => {
  const { setIsOpen, ticketID, currentPrice, refresh, setLoadPanelVisible } = props;
  const formRef = useRef({});
  const formData = useRef({});

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { SalesPrice } = formData.current;
      setLoadPanelVisible(true);
      await salesPayment.updateSeasonTicketPriceMulti({ TicketID: ticketID.join(','), SalesPrice }).then(res => {
        if (res.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '정기권 금액이 수정되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          refresh();
          formRef.current.instance.resetValues();
          close();
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
    },
    [ticketID],
  );

  const close = () => {
    setIsOpen(false);
    formRef.current.instance.resetValues();
  };

  return (
    <form className="add-sales-master-form" onSubmit={onSubmit}>
      <Form
        ref={formRef}
        formData={formData.current}
        labelLocation="left"
        labelMode="outside"
        optionalMark="optional"
        stylingMode="outlined"
      >
        <ButtonItem>
          <ButtonOptions width={'3%'} icon="close" onClick={close}></ButtonOptions>
        </ButtonItem>
        <GroupItem caption={'금액 일괄 수정'}>
          <SimpleItem
            dataField="SalesPrice"
            editorType="dxNumberBox"
            editorOptions={{
              showSpinButtons: true,
              step: 1000,
              min: 0,
              format: '#,##0',
              value: currentPrice,
              //   value: addParams.UnitPrice,
            }}
          >
            <Label text="금액" location="top" visible={false} />
            <RequiredRule message="필수값 입니다." />
          </SimpleItem>
        </GroupItem>
        <GroupItem colCount={2}>
          <ButtonItem>
            <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'확인'}></ButtonOptions>
          </ButtonItem>
          <ButtonItem horizontalAlignment="left">
            <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={close}></ButtonOptions>
          </ButtonItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default EditPrice;
