import React, { useRef, useEffect, useState, useCallback } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  EmptyItem,
  ButtonItem,
  ButtonOptions,
  RangeRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import moment from 'moment';
import './parking-mng-form.scss';
import { parking } from 'api';
import { operationTime } from 'constants/timeType';
import CustomInfoPopup from './CustomInfoPopup';

const ParkingMngForm = ({
  setPopupVisibility,
  costCenter,
  popupVisible,
  contractSalesType,
  contractGrade,
  operatingType,
  parkingLocationInfo,
  setRefresh,
  refresh,
}) => {
  const formRef = useRef({});
  const customInfoRef = useRef({});
  const [parkingData, setParkingData] = useState({});
  const [dateValue, setDateValue] = useState('');
  const [addParams, setAddParams] = useState({
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerCode: '',
  });

  // 사업자 선택
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);

  useEffect(() => {
    if (costCenter !== null && popupVisible.close) {
      updateInfo(costCenter);
    }
  }, [costCenter, popupVisible]);

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setAddParams({
        ...addParams,
        CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
        NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    }
  }, [selectedCustomInfo]);
  const onFocusIn = e => {
    e.event.target.blur();
  };

  const updateInfo = useCallback(async costCenter => {
    const result = await parking.getParkingInfo({
      CostCenter: costCenter,
    });
    if (result.isOk) {
      if (result.data[0]) {
        setParkingData(result.data[0]);
        setAddParams(result.data[0]);
        setDateValue(result.data[0].ContractValidDateFrom);
      } else {
        setParkingData({
          ContractValidDateFrom: '',
          ContractValidDateTo: '',
          ContractSalesType: '',
          ContractGrade: '',
          NumberOfPark: 0,
          MonthlyFee: 0,
          OperatingStartTime: 0,
          OperatingFinishTime: 24,
          OperatingType: '',
          LocationInfo: '',
          Address: '',
          ParkingManagerName: '',
          ParkingManagerMobilePhone: '',
          ParkingManagerEmailAccount: '',
          Remark: '',
          CostCenterCode: costCenter,
        });
      }
    }
  }, []);

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();

      var {
        ContractValidDateFrom,
        ContractValidDateTo,
        ContractSalesType,
        ContractGrade,
        NumberOfPark,
        MonthlyFee,
        OperatingStartTime,
        OperatingFinishTime,
        OperatingType,
        LocationInfo,
        Address,
        ParkingManagerName,
        ParkingManagerMobilePhone,
        ParkingManagerEmailAccount,
        Remark,
        CostCenterCode,
      } = formRef.current.props.formData;
      const { CustomerCode } = addParams;

      ContractValidDateFrom = ContractValidDateFrom ? dateFormat(ContractValidDateFrom) : ContractValidDateFrom;
      ContractValidDateTo = ContractValidDateTo ? dateFormat(ContractValidDateTo) : ContractValidDateTo;

      const result = await parking.updateParkingInfo({
        CostCenter: CostCenterCode,
        ContractValidDateFrom,
        ContractValidDateTo,
        ContractSalesType,
        ContractGrade,
        NumberOfPark,
        MonthlyFee,
        OperatingStartTime,
        OperatingFinishTime,
        OperatingType,
        LocationInfo,
        Address,
        ParkingManagerName,
        ParkingManagerMobilePhone,
        ParkingManagerEmailAccount,
        Remark,
        CustomerCode,
      });
      if (result.isOk) {
        setRefresh(!refresh);
        onClose();
      }
    },
    [refresh, addParams],
  );

  const dateFormat = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onClose = e => {
    setPopupVisibility({ save: false, close: false });
    setParkingData({
      CustomerCorpName: '',
      CustomerCorpRegNumber: '',
    });
    setAddParams({
      CustomerCorpRegNumber: '',
      CustomerCorpName: '',
      NameOfRepresentative: '',
      CustomerCode: '',
    });
    setSelectedCustomInfo([]);
  };

  const handleDateFrom = e => {
    setDateValue(e.value);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <Form
            id="form"
            ref={formRef}
            formData={parkingData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption="계약정보" colCount={4}>
              <Item
                dataField="ContractValidDateFrom"
                editorType="dxDateBox"
                colSpan={2}
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  icon: true,
                  onValueChanged: handleDateFrom,
                }}
              >
                <Label text="계약시작일" />
              </Item>
              <SimpleItem
                dataField="ContractValidDateTo"
                editorType="dxDateBox"
                colSpan={2}
                editorOptions={{
                  type: 'date',
                  useMaskBehavior: true,
                  displayFormat: 'yyyy-MM-dd',
                  min: dateValue,
                  dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                }}
              >
                <Label text="계약종료일" />
                <RangeRule min={dateValue} />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="ContractSalesType"
                editorType="dxSelectBox"
                editorOptions={{
                  items: contractSalesType,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="계약형태" />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="ContractGrade"
                editorType="dxSelectBox"
                editorOptions={{
                  items: contractGrade,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="계약등급" />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="NumberOfPark"
                editorType="dxNumberBox"
                helpText="숫자만 입력가능합니다."
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                }}
              >
                <Label text="주차면수" />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="MonthlyFee"
                editorType="dxNumberBox"
                helpText="숫자만 입력가능합니다."
                editorOptions={{
                  showSpinButtons: false,
                  min: 0,
                  format: '#,##0 원',
                }}
              >
                <Label text="임대료" />
              </SimpleItem>
              <SimpleItem colSpan={2} dataField="CustomerCode" visible={false}>
                <Label text="기업 고객코드" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerCorpRegNumber"
                colSpan={2}
                editorType="dxTextBox"
                editorOptions={{
                  onFocusIn: onFocusIn,
                  buttons: [
                    {
                      name: 'trash',
                      location: 'after',
                      options: {
                        stylingMode: 'text',
                        text: '사업자 선택',
                        onClick: () => {
                          customInfoRef.current.open();
                        },
                      },
                    },
                  ],
                  value: addParams.CustomerCorpRegNumber,
                }}
              >
                <Label text="사업자등록번호" />
              </SimpleItem>
              <SimpleItem
                dataField="CustomerCorpName"
                colSpan={2}
                editorType="dxTextBox"
                editorOptions={{
                  readOnly: true,
                  value: addParams.CustomerCorpName,
                }}
              >
                <Label text="회사명" />
              </SimpleItem>
            </GroupItem>
            <GroupItem caption="운영정보" colCount={4}>
              <SimpleItem
                colSpan={2}
                dataField="OperatingStartTime"
                editorType="dxSelectBox"
                editorOptions={{
                  items: operationTime,
                  valueExpr: 'value',
                  displayExpr: 'id',
                  defaultValue: 0,
                }}
              >
                <Label text="운영시작시간" />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="OperatingFinishTime"
                editorType="dxSelectBox"
                editorOptions={{
                  items: operationTime,
                  valueExpr: 'value',
                  displayExpr: 'id',
                  defaultValue: 24,
                }}
              >
                <Label text="운영종료시간" />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="OperatingType"
                editorType="dxSelectBox"
                editorOptions={{
                  items: operatingType,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="운영방식" />
              </SimpleItem>
              <SimpleItem
                dataField="LocationInfo"
                colSpan={2}
                editorType="dxSelectBox"
                editorOptions={{
                  items: parkingLocationInfo,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="권역" />
              </SimpleItem>
              <SimpleItem colSpan={2} dataField="Address" editorType="dxTextArea">
                <Label text="주소" />
              </SimpleItem>
              <SimpleItem colSpan={2} dataField="Remark" editorType="dxTextArea">
                <Label text="비고" />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption="담당자" colSpan={2} colCount={2}>
              <SimpleItem dataField="ParkingManagerName">
                <Label text="이름" />
              </SimpleItem>
              <SimpleItem dataField="ParkingManagerMobilePhone">
                <Label text="연락처" />
              </SimpleItem>
              <SimpleItem dataField="ParkingManagerEmailAccount">
                <Label text="이메일주소" />
              </SimpleItem>
              <EmptyItem dataField="CostCenterCode" />
            </GroupItem>
            <GroupItem colCount={2}></GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </>
  );
};

export default React.memo(ParkingMngForm);
