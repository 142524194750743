import React, { useState, useRef, useCallback, useImperativeHandle } from 'react';
import { Column, Scrolling, Selection, Paging, Pager } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { aggregation } from 'api';
import $ from 'jquery';

import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';

const PreviewPopup = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [costCenter, setCostCenter] = useState('');

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const open = (data, costCenter) => {
    (async () => {
      const result = await aggregation.getRentalSalesInvoicePredicted(data);
      if (result.isOk) {
        setDataSource(result.data[0]);
        setIsPopup(true);
        setCostCenter(costCenter);
      } else {
        notify(
          {
            message: JSON.parse(result.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    })();
  };

  const close = () => {
    setIsPopup(false);
  };
  const HelpText = () => {
    return (
      <>
        <i className="dx-icon-info" style={{ fontSize: '12px' }} id="title-help">
          &nbsp;마스터 정보를 기반으로 렌탈 매출에 대한 세금계산서가 <b>시스템 자동 생성</b>됩니다.
          <u>한 사업소 내의 계산서구분값이 같으면 합산 발행됩니다.</u>
        </i>
      </>
    );
  };
  const mergeYNCellValue = value => {
    if (value.MergeInvoiceYN === 1) return '합산';
    else return '개별';
  };
  const onCellPreparedInvoice = useCallback(e => {
    cellStatus(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'InvoiceID';
  const mergeColumn = ['InvoiceID'];

  const cellStatus = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };

  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="75%" height={dataSource.length > 11 ? 800 : 600}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <div className="popup-header">{'[' + costCenter + ']' + ' 세금계산서 미리보기'}</div>
        <div>
          <div className="dx-fieldset">
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              stylingMode="outlined"
              isExcel={false}
              hoverStateEnabled={false}
              isHeader={true}
              onCellPrepared={onCellPreparedInvoice}
              height={dataSource.length > 11 ? 550 : 400}
              isNotFilterRow
              headerBefore={[<HelpText />]}
            >
              <Scrolling mode="standard" useNative={true} />
              <Paging enabled={false} />
              <Column dataField={'InvoiceID'} caption={'세금계산서ID'} alignment="center" width={100} />
              <Column dataField={'ManagementName'} caption={'계산서구분자'} alignment="center" width={200} />
              <Column dataField={'SalesDate'} caption={'발행요청일'} alignment="center" width={100} />
              <Column dataField={'CustomerName'} caption={'사업자명'} alignment="center" width={200} />
              {/* <Column dataField={'CustomerCode'}  /> */}
              <Column
                dataField={'TotalInvoicePrice'}
                caption={'계산서발행금액'}
                alignment="center"
                format="#,##0 원"
                width={120}
              />
              {/* <Column dataField={'계산서항목수'} caption={''} /> */}
              <Column
                dataField={'MergeInvoiceYN'}
                caption={'합산여부'}
                alignment="center"
                calculateCellValue={mergeYNCellValue}
                width={100}
              />
              <Column dataField={'HiParkingID'} caption={'P코드'} alignment="center" width={100} />
              <Column dataField={'ItemName'} caption={'항목명'} alignment="center" width={200} />
              <Column
                dataField={'StandardPriceTotal'}
                caption={'공급가'}
                alignment="center"
                format="#,##0 원"
                width={100}
              />
              <Column
                dataField={'GrossPriceTotal'}
                caption={'청구액'}
                alignment="center"
                format="#,##0 원"
                width={100}
              />
            </CommonDataGrid>
          </div>
        </div>
        <div className="popup-footer">
          <div>
            <Button text="닫기" width={'120'} onClick={close} />
          </div>
        </div>
      </div>
    </Popup>
  );
});

export default PreviewPopup;
