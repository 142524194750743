import React, { useMemo, useState, useRef } from 'react';
import { Column, Pager, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import ScrollView from 'devextreme-react/scroll-view';
import { Popup } from 'devextreme-react/popup';
import moment from 'moment';
import { salesPayment } from 'api';
import DetailSalesInfo from './popup/detail-sales-info';
import CommonDataGrid from 'common/default-data-grid';

const ResultGeneralTicket = props => {
  const { inquiryDate, costCenterCode, isRefresh } = props;
  const [isPopup, setIsPopup] = useState(false);
  const detailSalesInfoRef = useRef({});
  const gridRef = useRef({});

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'SalesID',
      loadMode: 'raw',
      load: async () => {
        const result = await salesPayment.getAccountingGeneralSalesSummary({
          InquiryDate: inquiryDate,
          CostCenterCode: costCenterCode,
        });
        return result.data;
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenterCode, isRefresh]);

  const customizeText = cellInfo => {
    return cellInfo.value === '' ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const detailCellRender = e => {
    const { AccountingNo } = e.data;
    return (
      <div className="Active" style={{ cursor: 'pointer' }}>
        <i className="dx-icon-search dx-icon-customicon" onClick={() => onClick(AccountingNo)}></i>
      </div>
    );
  };

  // 팝업 열기
  const onClick = AccountingNo => {
    setIsPopup(true);
    detailSalesInfoRef.current.open(AccountingNo, 'generalTicket');
  };

  const onClose = () => {
    setIsPopup(false);
  };

  return (
    <React.Fragment>
      <div style={{ padding: '17px 0' }}>
        <div className="dx-card">
          <CommonDataGrid
            gridRef={gridRef}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            isHeader={true}
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '일반권 처리결과.xlsx',
            }}
          >
            <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
            <HeaderFilter visible={true} />
            <Column caption={'대사정보'}>
              <Column
                dataField={'key'}
                caption={'상세'}
                alignment={'center'}
                width={50}
                cellRender={detailCellRender}
                allowFiltering={false}
                allowExporting={false}
              />
              <Column dataField={'SalesTypeName'} caption={'매출유형'} alignment={'center'} />
              <Column dataField={'SalesCodeName'} caption={'대사-결제유형'} alignment={'center'} />
              <Column dataField={'TotlaPrice'} caption={'대사-총금액'} format="#,##0 원" alignment={'center'} />
              <Column dataField={'SalesRemark'} caption={'대사-비고'} alignment={'center'} />
              <Column dataField={'SalesRegisterUser'} caption={'작업자'} alignment={'center'} />
            </Column>
            <Column caption={'일반권정보'}>
              {/* <Column dataField={'ParkingType'} caption={'ParkingType'} alignment={'center'} />
            <Column
              dataField={'ParkingInDate'}
              caption={'ParkingInDate'}
              alignment={'center'}
              cellRender={customizeText}
            />
            <Column
              dataField={'ParkingOutDate'}
              caption={'ParkingOutDate'}
              alignment={'center'}
              cellRender={customizeText}
            /> */}
              {/* <Column dataField={'ParkingFee'} caption={'요금'} format="#,##0 원" alignment={'center'} /> */}
              <Column dataField={'ApprovalDate'} caption={'승인일'} alignment={'center'} cellRender={customizeText} />
              <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} alignment={'center'} />
              <Column dataField={'SalesPrice'} caption={'판매금액'} format="#,##0 원" alignment={'center'} />
              {/* <Column dataField={'DiscountPrice'} caption={'할인 금액'} format="#,##0 원" alignment={'center'} /> */}
              {/* <Column dataField={'DiscountType'} caption={'할인 유형'} alignment={'center'} /> */}
              <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} alignment={'center'} />
              <Column dataField={'CreditCardNo'} caption={'카드번호'} alignment={'center'} />
              <Column dataField={'PaymentType'} caption={'결제유형'} alignment={'center'} />
              <Column dataField={'Remark'} caption={'비고-매출'} alignment={'center'} />
              <Column dataField={'RegisterDate'} caption={'등록일'} alignment={'center'} cellRender={customizeText} />
              {/* <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} /> */}
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'SalesID'} caption={'대사ID'} alignment={'center'} />
            </Column>
          </CommonDataGrid>
        </div>
      </div>
      <Popup visible={isPopup} onHiding={onClose} showTitle={false} width="80%" height={737}>
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <DetailSalesInfo ref={detailSalesInfoRef} setIsPopup={setIsPopup} />
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

export default ResultGeneralTicket;
