import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  NumericRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import { parking, error } from 'api';

const CmsPopup = React.forwardRef((props, ref) => {
  const { costCenterCode, bankType, refresh, setRefresh } = props;
  const formData = useRef({});
  const [isPopup, setIsPopup] = useState(false);

  useImperativeHandle(ref, () => ({
    open,
  }));
  const open = () => {
    setIsPopup(true);
  };
  const onClose = () => {
    setIsPopup(false);
    formData.current = {};
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { AccoutNo, BankCode } = formData.current;
      await parking
        .insertCMSAccount({
          CostCenterCode: costCenterCode,
          AccoutNo,
          BankCode,
        })
        .then(res => {
          if (res.isOk) {
            notify(
              {
                message: 'CMS 계좌번호가 등록되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            onClose();
            setRefresh(!refresh);
          } else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [costCenterCode, refresh],
  );
  return (
    <Popup onHiding={onClose} visible={isPopup} showTitle={false} width="22%" height={360}>
      <ScrollView>
        <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
          <form onSubmit={onSubmit}>
            <div className="">
              <Form
                formData={formData.current}
                labelLocation="left"
                labelMode="outside"
                optionalMark="optional"
                stylingMode="outlined"
              >
                <ButtonItem>
                  <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
                </ButtonItem>
                <GroupItem caption="CMS 계좌번호 등록">
                  <SimpleItem dataField="AccoutNo" editorType="dxTextBox">
                    <Label text="계좌번호" location={'top'} />
                    <RequiredRule message="필수값 입니다." />
                    <NumericRule message="숫자만 입력가능합니다." />
                  </SimpleItem>
                  <SimpleItem
                    dataField="BankCode"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: bankType,
                      valueExpr: 'value',
                      displayExpr: 'id',
                      placeholder: '결제 유형을 선택해주세요',
                    }}
                  >
                    <RequiredRule message="필수값 입니다." />
                    <Label text="은행" location={'top'} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colCount={2}>
                  <ButtonItem>
                    <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem horizontalAlignment="left">
                    <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </Form>
            </div>
          </form>
        </div>
      </ScrollView>
    </Popup>
  );
});

export default CmsPopup;
