import React, { useCallback, useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { isEmptyObject, isEmptyString } from 'utils/common';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  StringLengthRule,
  NumericRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import CommonDataGrid from 'common/default-data-grid';
import { Column, Editing } from 'devextreme-react/data-grid';
import { parking, salesPayment, error } from 'api';
import { btob } from 'api';
import { BTOB_COST_CENTER_CODE, csstiUrls } from 'api/btob';
import CostcenterMaster from 'common/popup/costcenter-master';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import { configBySelectedCustomerInfo, configTypeInTaxInvoicePopup, notifyS } from 'common/popup/sales-common-util';
import notify from 'devextreme/ui/notify';

const CsstiAddTaxInvoice = props => {
  const { setIsOpen, selectData, refresh, setLoadPanelVisible, uploadKeyIDListStr, salesMonth } = props;
  const formRef = useRef({});
  const gridRef = useRef({});
  const formData = useRef({ TaxType: '010' });
  const costcenterMasterRef = useRef({});
  const customInfoRef = useRef({});

  // 라디오 버튼
  const [taxIssueType, setTaxIssueType] = useState([]);
  const [taxInvoiceType, setTaxInvoiceType] = useState([]);
  const [taxType, setTaxType] = useState([]);

  // 사업소 & 사업자 선택
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);

  // 하단의 매출 내역 grid
  const [dataSource, setDataSource] = useState([]);

  const [addParams, setAddParams] = useState({
    SalesKeyIDList: [],
    CostCenterCode: BTOB_COST_CENTER_CODE,
    CostCenterName: '',
    SalesPrice: 0,
    CustomerCorpRegNumber: '',
    CustomerCorpName: '',
    NameOfRepresentative: '',
    CustomerManagerGroup: '',
    CustomerManagerName: '',
    CustomerEmail1: '',
    CustomerEmail2: '',
    CustomerTelephoneNo: '',
    TaxPrice: '',
    UnitPrice: '',
    SalesDate: moment().format('YYYY-MM-DD'),
    DerivedCorpRegNumber: '',
  });

  const fetch = useCallback(async (uploadedSelectData, keyIDListStr) => {
    // const unitPrice = Math.round(sales / 1.1);
    // const taxPrice = Math.round(sales - unitPrice);

    const taxInvoicItems = [];
    let sales = 0;

    const res = await btob.doApi(csstiUrls.get, {
      SalesKeyIDList: keyIDListStr,
      TaxType: '',
      InvoiceID: '',
    });
    if (!res.isOk) {
      notifyS(error.errorMsgCheck(res.error.detail));
      return;
    }

    setDataSource(res.data[0]);
    taxInvoicItems.push(...res.data[0]);
    sales = res.data[0].reduce((a, c) => {
      const nextSalesPrice = typeof c.SalesPrice === 'number' ? c.SalesPrice : Number(c.SalesPrice);
      return a + nextSalesPrice;
    }, 0);
    console.log('sales = ', sales);

    const res2 = await salesPayment.getTaxInvoiceCustomerBySales({
      SalesKeyIDList: keyIDListStr,
    });
    if (!res2.isOk) {
      notifyS(error.errorMsgCheck(res.error.detail));
      return;
    }

    const taxPrice = taxInvoicItems.reduce((a, c) => {
      return a + c.TaxPrice;
    }, 0);
    const unitPrice = taxInvoicItems.reduce((a, c) => {
      return a + c.UnitPrice;
    }, 0);
    if (Array.isArray(res2.data) && res2.data[0].length > 0) {
      setAddParams({
        ...addParams,
        CustomerCorpRegNumber: res2.data[0][0].CorpRegNumber,
        CustomerCorpName: res2.data[0][0].CustomerName,
        NameOfRepresentative: res2.data[0][0].NameOfRepresentative,
        CustomerManagerGroup: res2.data[0][0].CustomerManagerGroup,
        CustomerManagerName: res2.data[0][0].CustomerManagerName,
        CustomerEmail1: res2.data[0][0].TaxInvoiceEmail1 === null ? '' : res2.data[0][0].TaxInvoiceEmail1,
        CustomerTelephoneNo: res2.data[0][0].CustomerTelephoneNo,
        CustomerCode: res2.data[0][0].CustomerNo,
        SalesKeyIDList: keyIDListStr,
        CostCenterCode: BTOB_COST_CENTER_CODE,
        CostCenterName: uploadedSelectData[0].CostCenterName,
        SalesPrice: sales,
        TaxPrice: taxPrice,
        UnitPrice: unitPrice,
        DerivedCorpRegNumber: '',
      });
    } else {
      setAddParams({
        ...addParams,
        CustomerCorpRegNumber: '',
        CustomerCorpName: '',
        NameOfRepresentative: '',
        CustomerManagerGroup: '',
        CustomerManagerName: '',
        CustomerEmail1: '',
        CustomerTelephoneNo: '',
        CustomerCode: '',
        SalesKeyIDList: keyIDListStr,
        CostCenterCode: BTOB_COST_CENTER_CODE,
        CostCenterName: uploadedSelectData[0].CostCenterName,
        SalesPrice: sales,
        TaxPrice: taxPrice,
        UnitPrice: unitPrice,
        DerivedCorpRegNumber: '',
      });
    }
  }, []);

  useEffect(() => {
    console.log(selectData);
    if (!isEmptyObject(selectData) && !isEmptyString(uploadKeyIDListStr)) {
      fetch(selectData, uploadKeyIDListStr).then(r => {
        console.log('useEffect then');
      });
    }
  }, [selectData, uploadKeyIDListStr]);

  // 팝업에 들어가는 유형 select items
  const api = useCallback(async () => {
    await configTypeInTaxInvoicePopup(setTaxIssueType, setTaxInvoiceType, setTaxType);
  }, []);

  useEffect(() => {
    if (taxIssueType.length === 0) {
      api();
    }
  }, [taxIssueType]);

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      setAddParams({
        ...addParams,
        CostCenterCode: BTOB_COST_CENTER_CODE,
        CostCenterName: selectedCostCenter[0].CostCenterName,
      });
    }
  }, [selectedCostCenter]);

  useEffect(() => {
    const { SalesDate } = formData.current;
    configBySelectedCustomerInfo(selectedCustomInfo, setAddParams, addParams, SalesDate);
  }, [selectedCustomInfo]);

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();

      const {
        SalesDate,
        DerivedCorpRegNumber,
        TaxIssueType,
        TaxType,
        TaxPrice,
        UnitPrice,
        CustomerEmail2,
        CustomerTelephoneNo,
        TaxInvoiceType,
        ...customInfo
      } = formData.current;
      const { SalesKeyIDList, CostCenterCode, CostCenterName, SalesPrice, CustomerCode, CustomerEmail1, Remark } =
        addParams;

      if (SalesPrice !== TaxPrice + UnitPrice) {
        return alert('금액을 확인해주세요.');
      }

      setLoadPanelVisible(true);
      const params = {
        CostCenterCode,
        CostCenterName,
        TaxInvoiceType,
        TaxIssueType: 'A', // 청구
        SalesKeyIDList,
        SalesPrice,
        SalesDate: SalesDate ? moment(SalesDate).format('YYYY-MM-DD') : '',
        DerivedCorpRegNumber,
        RegisterUser: '',
        CustomerCode,
        CustomerEmail1,
        CustomerEmail2,
        CustomerTelephoneNo,
        TaxType,
        TaxPrice,
        UnitPrice,
        Remark,
        ...customInfo,
        ItemListJson: JSON.stringify(dataSource),
      };
      const res = await btob.doApi(csstiUrls.insert, {
        ...params,
        SalesMonth: moment(salesMonth, 'YYYY/MM/DD').format('YYYYMM'),
        SalesKeyID: uploadKeyIDListStr,
      });

      if (res.isOk) {
        setLoadPanelVisible(false);
        notifyS('처리되었습니다.', 'success');
        onClose();
        refresh();
      } else {
        setLoadPanelVisible(false);
        notifyS(error.errorMsgCheck(res.error.detail));
      }
    },
    [formData, addParams, dataSource],
  );

  const onClose = () => {
    formRef.current.instance.resetValues();
    setSelectedCustomInfo([]);
    setIsOpen(false);
    setAddParams({
      TaxInvoiceType: '',
      TaxIssueType: '',
      SalesKeyIDList: [],
      CostCenterCode: BTOB_COST_CENTER_CODE,
      CostCenterName: '',
      SalesPrice: 0,
      CustomerCorpRegNumber: '',
      CustomerCorpName: '',
      NameOfRepresentative: '',
      CustomerManagerGroup: '',
      CustomerManagerName: '',
      CustomerEmail1: '',
      CustomerEmail2: '',
      CustomerTelephoneNo: '',
      TaxPrice: 0,
      UnitPrice: 0,
      SalesDate: moment().format('YYYY-MM-DD'),
      DerivedCorpRegNumber: '',
    });
    formRef.current.instance.updateData('TaxType', '010');
  };

  // 사업소 변경시 버튼을 통해서만 가능하도록
  const onFocusIn = e => {
    e.event.target.blur();
  };

  const onTaxTypeChanged = e => {
    const { SalesPrice, CustomerManagerGroup, CustomerManagerName, CustomerEmail1, CustomerEmail2 } = formData.current;
    const taxInvoiceItems = [];
    (async () => {
      await salesPayment
        .getTaxInvoiceItems({
          SalesKeyIDList: uploadKeyIDListStr,
          TaxType: e.value,
          InvoiceID: '',
        })
        .then(res => {
          if (res.isOk) {
            setDataSource(res.data[0]);
            taxInvoiceItems.push(...res.data[0]);
          } else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });

      if (e.value === '000' || e.value === '020') {
        setAddParams({
          ...addParams,
          CustomerManagerGroup,
          CustomerManagerName,
          CustomerEmail1,
          CustomerEmail2,
          TaxPrice: 0,
          UnitPrice: SalesPrice,
        });
      } else {
        const taxPrice = taxInvoiceItems.reduce((a, c) => {
          return a + c.TaxPrice;
        }, 0);
        const unitPrice = taxInvoiceItems.reduce((a, c) => {
          return a + c.UnitPrice;
        }, 0);
        setAddParams({
          ...addParams,
          CustomerManagerGroup,
          CustomerManagerName,
          CustomerEmail1,
          CustomerEmail2,
          TaxPrice: taxPrice,
          UnitPrice: unitPrice,
        });
      }
    })();
  };

  const handleDateFrom = e => {
    const { CustomerManagerGroup, CustomerManagerName, CustomerEmail1, CustomerCorpRegNumber, CustomerEmail2 } =
      formData.current;
    setAddParams({
      ...addParams,
      CustomerCorpRegNumber,
      CustomerManagerGroup,
      CustomerManagerName,
      CustomerEmail1,
      CustomerEmail2,
      SalesDate: e.value,
    });
  };

  const onChangesChange = e => {
    if (e.length > 0) {
      gridRef.current.instance.saveEditData();
      const taxPrice = dataSource.reduce((a, c) => {
        return a + c.TaxPrice;
      }, 0);
      const unitPrice = dataSource.reduce((a, c) => {
        return a + c.UnitPrice;
      }, 0);
      setAddParams({
        ...addParams,
        UnitPrice: unitPrice,
        TaxPrice: taxPrice,
      });
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            ref={formRef}
            className={'add-tax-invoice'}
            formData={formData.current}
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
            labelLocation="left"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={'B2B 매출 세금계산서 발행'} colCount={2}>
              <GroupItem>
                <SimpleItem
                  dataField="TaxInvoiceType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: taxInvoiceType,
                    valueExpr: 'value',
                    displayExpr: 'label',
                    layout: 'horizontal',
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="발행 유형" />
                </SimpleItem>
                {/*<SimpleItem*/}
                {/*  dataField="CostCenterName"*/}
                {/*  editorType="dxTextBox"*/}
                {/*  editorOptions={{*/}
                {/*    onFocusIn: onFocusIn,*/}
                {/*    buttons: [*/}
                {/*      {*/}
                {/*        name: 'trash',*/}
                {/*        location: 'after',*/}
                {/*        options: {*/}
                {/*          stylingMode: 'text',*/}
                {/*          text: '사업소 변경',*/}
                {/*          onClick: () => {*/}
                {/*            costcenterMasterRef.current.open();*/}
                {/*          },*/}
                {/*        },*/}
                {/*      },*/}
                {/*    ],*/}
                {/*    value: addParams.CostCenterName,*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <Label text="사업소" />*/}
                {/*</SimpleItem>*/}

                <SimpleItem
                  dataField="TaxType"
                  editorType="dxRadioGroup"
                  shouldRender="condition"
                  editorOptions={{
                    items: taxType,
                    valueExpr: 'value',
                    displayExpr: 'label',
                    layout: 'horizontal',
                    onValueChanged: onTaxTypeChanged,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="과세 유형" />
                </SimpleItem>
                <SimpleItem
                  dataField="UnitPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                    value: addParams.UnitPrice,
                  }}
                >
                  <Label text="공급가액" />
                </SimpleItem>
                <SimpleItem
                  dataField="TaxPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                    value: addParams.TaxPrice,
                  }}
                >
                  <Label text="부가세" />
                </SimpleItem>
                <SimpleItem
                  dataField="SalesPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    readOnly: true,
                    value: addParams.SalesPrice,
                  }}
                >
                  <Label text="계산서 금액" />
                </SimpleItem>
                <SimpleItem
                  dataField="SalesDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    type: 'date',
                    dropDownButtonTemplate: 'dropDownButton',
                    displayFormat: 'yyyy-MM-dd',
                    useMaskBehavior: true,
                    icon: true,
                    placeholder: '요청일을 선택해주세요',
                    value: addParams.SalesDate,
                    onValueChanged: handleDateFrom,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="발행 요청일" />
                </SimpleItem>
                <SimpleItem dataField="Remark" editorType="dxTextArea">
                  <Label text="적요" />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  dataField="CustomerCorpRegNumber"
                  editorType="dxTextBox"
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업자 선택',
                          onClick: () => {
                            customInfoRef.current.open();
                          },
                        },
                      },
                    ],
                    value: addParams.CustomerCorpRegNumber,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="사업자등록번호" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerCorpName"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: addParams.CustomerCorpName,
                  }}
                >
                  <Label text="회사명" />
                </SimpleItem>
                <SimpleItem
                  dataField="NameOfRepresentative"
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: addParams.NameOfRepresentative,
                  }}
                >
                  <Label text="매입자 대표자 성명" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerManagerGroup"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerManagerGroup,
                  }}
                >
                  {/* <RequiredRule message="필수 값입니다." /> */}
                  <Label text="담당부서" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerManagerName"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerManagerName,
                  }}
                >
                  {/* <RequiredRule message="필수 값입니다." /> */}
                  <Label text="담당자" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerEmail1"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerEmail1,
                  }}
                >
                  <RequiredRule message="필수 값입니다." />
                  <Label text="Email" />
                </SimpleItem>
                <SimpleItem
                  dataField="CustomerEmail2"
                  editorType="dxTextBox"
                  editorOptions={{
                    value: addParams.CustomerEmail2,
                  }}
                >
                  <Label text="Email2" />
                </SimpleItem>
                <SimpleItem dataField="CustomerTelephoneNo" editorType="dxTextBox">
                  <RequiredRule message="필수 값입니다." />
                  <Label text="전화번호" />
                </SimpleItem>
                <SimpleItem
                  dataField="DerivedCorpRegNumber"
                  editorType="dxTextBox"
                  editorOptions={{
                    maxLength: 4,
                  }}
                >
                  <Label text="종사업장" />
                  <NumericRule message="숫자만 입력가능합니다." />
                  <StringLengthRule max={4} message="종사업장은 4글자를 초과할 수 없습니다." />
                </SimpleItem>
              </GroupItem>
              <GroupItem colSpan={2}>
                <CommonDataGrid
                  gridRef={gridRef}
                  // className={'dx-card wide-card'}
                  dataSource={dataSource}
                  showBorders={true}
                  allowColumnResizing={true}
                  columnHidingEnabled={false}
                  hoverStateEnabled={true}
                  height={150}
                  isHeader={true}
                  isExcel={false}
                  isNotFilterRow
                  headerBefore={[<span>카셰어링 매출 내역</span>]}
                >
                  <Editing
                    mode="cell"
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={true}
                    onChangesChange={onChangesChange}
                  />
                  <Column
                    dataField={'CostCenterName'}
                    caption={'사업소명'}
                    width={120}
                    alignment={'center'}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'HiParkingID'}
                    caption={'P코드'}
                    width={'100'}
                    alignment={'center'}
                    allowEditing={false}
                    visible={false}
                  />
                  <Column
                    dataField={'SalesTypeName'}
                    caption={'매출유형'}
                    width={100}
                    alignment={'center'}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'DetailSalesTypeName'}
                    caption={'상세매출유형'}
                    width={100}
                    alignment={'center'}
                    allowEditing={false}
                  />
                  <Column
                    dataField={'ItemName'}
                    caption={'항목명'}
                    width={180}
                    alignment={'center'}
                    allowEditing={false}
                    visible={false}
                  />
                  <Column dataField={'FromDate'} visible={false} />
                  <Column dataField={'ToDate'} visible={false} />
                  <Column dataField={'Cnt'} caption={'수량'} width={70} alignment={'center'} />
                  <Column
                    dataField={'SalesUnitPrice'}
                    caption={'단가'}
                    width={80}
                    alignment={'center'}
                    format="#,##0 원"
                  />
                  <Column
                    dataField={'UnitPrice'}
                    caption={'공급가액'}
                    width={110}
                    alignment={'center'}
                    format="#,##0 원"
                  />
                  <Column dataField={'TaxPrice'} caption={'세액'} width={110} alignment={'center'} format="#,##0 원" />
                  <Column
                    dataField={'SalesPrice'}
                    caption={'합계금액'}
                    width={110}
                    alignment={'center'}
                    format="#,##0 원"
                    allowEditing={false}
                  />
                  <Column dataField={'Remark'} caption={'비고'} width={80} alignment={'left'} />
                </CommonDataGrid>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'저장'}></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
      <CostcenterMaster ref={costcenterMasterRef} setSelectedCostCenter={setSelectedCostCenter} />
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </React.Fragment>
  );
};

export default CsstiAddTaxInvoice;
