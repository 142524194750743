import React, { useCallback, useEffect, useRef, useState } from 'react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Column, Lookup, Scrolling, Selection, Editing, MasterDetail } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import { Button } from 'devextreme-react/button';
import { parking, ticket, master } from 'api';
import { notifyS } from 'common/popup/sales-common-util';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import TemplatePopup from './templatePopup';
import { COMPANYCODE } from 'constants/codes';
import { base64forDistributionRate } from './base64/distributionRate';
import { base64forConstantF } from './base64/constantF';
import { base64forConstantG } from './base64/constantG';
import CostGrid from './cost-grid';
import './parking-cost-mgmt.scss';

const FIXED = 'Fixed';
const FLOATING = 'Floating';
const RECOGNITIONTYPE = 'RecognitionType';
const DISBURSEMENT = 'Disbursement';
const CHARGE = 'Charge';
const OTHERS = 'Others';

const DEFAULT_SELECTED_COST_CENTER_CODE = 'BtoB';

const ParkingCostMgmt = () => {
  const centerGridRef = useRef({});
  const fixedRef = useRef({});
  const floatingRef = useRef({});
  const disbursementRef = useRef({});
  const chargeRef = useRef({});
  const recogTypeRef = useRef({});
  const templatePopup = useRef({});
  const othersRef = useRef({});

  const [dataSourceOrg, setDataSourceOrg] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dsFixed, setDsFixed] = useState([]);
  const [dsFloating, setDsFloating] = useState([]);
  const [dsOthers, setDsOthers] = useState([]);
  const [dsDisbursement, setDsDisbursement] = useState([]);
  const [dsCharge, setDsCharge] = useState([]);
  const [dsRecogType, setDsRecogType] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [templatePopupVisible, setTemplatePopupVisible] = useState(false);
  const ProfitDistributionType = {
    store: [
      { ID: 1, DistributionType: '수익' },
      { ID: 2, DistributionType: '매출' },
    ],
  };

  const RevenueRecognitionType = {
    store: [
      { ID: 1, RecognitionType: '입금' },
      { ID: 2, RecognitionType: '발생' },
    ],
  };

  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY/MM/DD'),
    CostCenterCode: DEFAULT_SELECTED_COST_CENTER_CODE,
  });

  const [selectedKeys, setSelectedKeys] = useState([DEFAULT_SELECTED_COST_CENTER_CODE]);
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const handleSalesMonth = e => {
    setSearchData({
      ...searchData,
      SalesMonth: e.value,
    });
    getCostData(e.value, searchData.CostCenterCode);
  };

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        const newDataSource = result.data;
        setDataSourceOrg(newDataSource);
        setDataSource(newDataSource);
        const firstSelectedKeys = newDataSource.length > 0 ? [newDataSource[0].CostCenterCode] : [];
        setSelectedKeys(firstSelectedKeys);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, []);

  const onSelectionChangedCenter = e => {
    if (e.selectedRowsData.length > 0) {
      setSearchData({
        ...searchData,
        CostCenterCode: e.selectedRowKeys[0],
      });
      setSelectedKeys(e.selectedRowKeys);
      getCostData(searchData.SalesMonth, e.selectedRowKeys[0]);
    }
  };

  const getCostData = useCallback(async (salesMonth, costCenterCode) => {
    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: costCenterCode,
      SalesMonth: moment(salesMonth, 'YYYY/MM/DD').format('YYYYMM'),
    };

    const result = await parking.getParkingCostManagementData(params);
    if (!result.isOk) {
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    const tmpData = result.data && result.data[0] ? result.data[0] : [];
    const tmpFixed = [];
    const tmpFloating = [];
    const tmpOthers = [];
    const tmpDisbursement = [];
    const tmpCharge = [];
    const tmpRecogType = [];
    console.log(tmpData);
    tmpData.forEach((row, index) => {
      const tmpTarget =
        row.CostGroupCode === FIXED
          ? tmpFixed
          : row.CostGroupCode === FLOATING
          ? tmpFloating
          : row.CostGroupCode === DISBURSEMENT
          ? tmpDisbursement
          : row.CostGroupCode === CHARGE
          ? tmpCharge
          : row.CostGroupCode === RECOGNITIONTYPE
          ? tmpRecogType
          : tmpOthers;
      tmpTarget.push(row);
    });
    setDsFixed(tmpFixed);
    setDsFloating(tmpFloating);
    setDsOthers(tmpOthers);
    setDsDisbursement(tmpDisbursement);
    setDsCharge(tmpCharge);
    setDsRecogType(tmpRecogType);
    setLoadPanelVisible(false);
  }, []);

  const saveExpense = useCallback(async () => {
    const tmpFixed = fixedRef.current.getDataSource();
    const tmpFloating = floatingRef.current.getDataSource();
    const tmpOthers = othersRef.current.getDataSource();
    const tmpDisbursement = disbursementRef.current.getDataSource();
    const tmpCharge = chargeRef.current.getDataSource();

    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: searchData.CostCenterCode,
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      FixedItemListJson: JSON.stringify(tmpFixed),
      FloatingItemListJson: JSON.stringify(tmpFloating),
      OthersItemListJson: JSON.stringify(tmpOthers),
      DisbursementItemListJson: JSON.stringify(tmpDisbursement),
      ChargeItemListJson: JSON.stringify(tmpCharge),
    };
    const result = await parking.saveParkingCostManagementData(params);
    if (!result.isOk) {
      setLoadPanelVisible(false);
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      return;
    }

    notifyS('저장되었습니다.', 'success');
    setLoadPanelVisible(false);
  }, [searchData]);

  const cellRender = useCallback(e => {
    const random = Math.random() >= 0.3;
    const random1 = Math.random() >= 0.3;
    const random2 = Math.random() >= 0.3;
    const random3 = Math.random() >= 0.3;
    if (random) {
      return (
        <span>
          [매출 - 운영경비 - 최저보장수입] * 배분율 <i>(매출 - 운영경비 &gt; 최저보장수입)</i>
        </span>
      );
    } else if (random1) {
      return <span>[매출 - 운영경비] * 배분율</span>;
    } else if (random2) {
      return <span>[매출] * 배분율 </span>;
    } else if (random3) {
      <span>
        [매출 - 운영경비 - 최저보장수입] * 배분율 <i>(매출 - 운영경비 &gt; 최저보장수입)</i>
      </span>;
    }
  }, []);
  const cellRender2 = useCallback(e => {
    const random = Math.random() >= 0.5;
    if (random) {
      return '수익';
    } else {
      return '매출';
    }
  }, []);
  const HelpText = () => {
    return (
      <>
        {/* <i className="dx-icon-info" style={{ fontSize: '12px' }} id="title-help">
          &nbsp; HI-DMS에서는 <b>사업소 운영 보고서</b> 생성에 다음의 산식을 사용합니다.
        </i> */}
      </>
    );
  };

  return (
    <div>
      <h2 className={'content-block'}>사업소 비용 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings parking-cost-mgmt'} style={{ paddingTop: '0px' }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div style={{ width: '40px' }}>등록월</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
              />
              &nbsp;
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <span style={{ color: 'gray' }}></span>
            </ToolbarItem>

            <ToolbarItem location={'before'}>
            <Button
                text="월별 설정 조회"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  const url = `/#/parking-cost-mgmt/view-month-cost`;
                  window.open(url, '_blank')
                }}
              />
            </ToolbarItem>
            {/* <ToolbarItem location={'after'}>
              <Button
                icon="favorites"
                text="산식 상세"
                width={120}
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  templatePopup.current.open();
                }}
              />
            </ToolbarItem> */}
            <ToolbarItem location={'after'}>
              <Button
                icon="check"
                text="저장"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => saveExpense()}
              />
            </ToolbarItem>
          </Toolbar>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '30%' }}>
              <CommonDataGrid
                gridRef={centerGridRef}
                className={'dx-card wide-card grid-with-status'}
                dataSource={dataSource}
                showBorders={true}
                allowColumnResizing={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={775}
                isHeader={true}
                isExcel={true}
                excelOptions={{
                  sheetName: 'sheet',
                  fileName: '사업소.xlsx',
                  customizeCell: {
                    date: ['RegisterDate', 'LastUpdateDate'],
                  },
                }}
                allowColumnReordering={true}
                headerBefore={[<HelpText />]}
                onSelectionChanged={onSelectionChangedCenter}
                keyExpr="CostCenterCode"
                selectedRowKeys={selectedKeys}
              >
                {/* <Editing
                  mode="row"
                  allowUpdating={true}
                  selectTextOnEditStart={true}
                  useIcons={true}
                  startEditAction={'click'}
                  texts={{
                    saveAllChanges: '저장하기',
                    cancelAllChanges: '되돌리기',
                  }}
                /> */}
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Selection mode="single" selectAllMode="pages" />
                {/* <Column type={'buttons'} width={80}>
                  <Button name="edit" icon="edit" />
                </Column> */}
                <Column
                  dataField={'CostCenterCode'}
                  caption={'P코드'}
                  width={70}
                  visible={false}
                  allowEditing={false}
                />
                <Column dataField={'CostCenterName'} caption={'사업소명'} width={200} allowEditing={false} />
                {/* <Column dataField={'RecognitionType'} caption={'매출인식기준'} cssClass="right-padding">
                  <Lookup
                    dataSource={RevenueRecognitionType}
                    valueExpr="RecognitionType"
                    displayExpr="RecognitionType"
                  />
                </Column> */}
                {/* <Column type={'buttons'} width={80}>
                  <Button name="edit" icon="edit" />
                </Column> */}
                {/* <Column dataField={'DsFomula'} caption={'산식'} /> */}
                {/* <Column
                  dataField={'DistributionType'}
                  caption={'수배기준'}
                  width={90}
                >
                  <Lookup
                    dataSource={ProfitDistributionType}
                    valueExpr="DistributionType"
                    displayExpr="DistributionType"
                  />
                </Column> */}
                {/* <Column dataField={'RecognitionType'} caption={'매출인식기준'} width={90}>
                  <Lookup
                    dataSource={RevenueRecognitionType}
                    valueExpr="RecognitionType"
                    displayExpr="RecognitionType"
                  />
                </Column>
                <Column
                  dataField={'ThresholdSales'}
                  caption={"(D')지정최소금액"}
                  format="#,##0 원"
                  dataType="number"
                  width={130}
                  allowEditing={true}
                /> */}
                {/* <Column
                  dataField={'DeficitPreservation'}
                  caption={'적자보전'}
                  dataType="boolean"
                  width={110}
                  allowEditing={true}
                />
                <Column
                  dataField={'ProfitDistributionFormula'}
                  caption={'시스템 자동 생성 산식'}
                  width={600}
                  allowEditing={true}
                >
                  <Lookup
                    dataSource={ProfitDistributionMathType}
                    valueExpr="ProfitDistributionFormula"
                    displayExpr="ProfitDistributionFormula"
                  />
                </Column>
                <Column dataField={'수배정산방법_수배정산산식'} caption={'수배정산식(원본)'} width={220} /> */}
              </CommonDataGrid>

              <TemplatePopup ref={templatePopup} />
            </div>
            <div style={{ maxWidth: '70%', display: 'flex' }}>
              <div style={{ maxWidth: '50%' }}>
                <CostGrid title={'고정경비(B)'} height={360} dsCost={dsFixed} ref={fixedRef} />
                <CostGrid title={'변동경비(B)'} height={360} dsCost={dsFloating} ref={floatingRef} />
              </div>

              <div style={{ maxWidth: '50%' }}>
                {/* <CostGrid title={`매출인식기준`} height={155} dsCost={dsRecogType} ref={recogTypeRef} /> */}
                <CostGrid
                  title={`배분 설정(C,D,E)`}
                  height={225}
                  dsCost={dsOthers}
                  ref={othersRef}
                  // imageSource={base64forDistributionRate}
                  keyID="Others"
                />
                {/* 2024.09.25 임시 제거 */}
                <CostGrid
                  title={`별도지급(F)`}
                  height={220}
                  dsCost={dsDisbursement}
                  ref={disbursementRef}
                  help={'갑사 청구내역의 수익금으로 포함되는 금액'}
                  imageSource={base64forConstantF}
                  keyID="ConstantF"
                />
                <CostGrid
                  title={`별도청구(G)`}
                  height={220}
                  dsCost={dsCharge}
                  ref={chargeRef}
                  help={'하이파킹 청구서에 포함되는 금액입니다.'}
                  imageSource={base64forConstantG}
                  keyID="ConstantG"
                  visible={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default ParkingCostMgmt;
