import React, { useState, useRef, useMemo, useImperativeHandle, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Column, Summary, TotalItem, Selection, Scrolling } from 'devextreme-react/data-grid';
// import { Popup } from 'devextreme-react/popup';
// import ScrollView from 'devextreme-react/scroll-view';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
// import DetailHistory from './popup/DetailHistory';
import { salesPayment, error } from 'api';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const History = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  //   const detailHistoryRef = useRef({});

  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    SearchCompany: '',
    MemberGroupName: '',
    CustomerName: '',
  });
  // const [text, setText] = useState({
  //   SearchCostCenter: '',
  //   SearchCompany: '',
  //   MemberGroupName: '',
  //   CustomerName: '',
  // });

  //팝업
  // const [isOpen, setIsOpen] = useState(false);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    refresh,
  }));

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  // const onChangeSearchText = e => {
  //   setText({
  //     ...text,
  //     [e.component._label._props.text]: e.value,
  //   });
  // };

  // const onEnterKey = e => {
  //   setSearchData({
  //     ...searchData,
  //     SearchCostCenter: text.SearchCostCenter,
  //     SearchCompany: text.SearchCompany,
  //     MemberGroupName: text.MemberGroupName,
  //     CustomerName: text.CustomerName,
  //   });
  // };

  //   const editCellRender = e => {
  //     return <Button icon="search" type="normal" stylingMode="contained" onClick={() => togglePopup(e.data)} />;
  //   };

  //   const togglePopup = data => {
  //     detailHistoryRef.current.open(data.InvoiceID);
  //     setIsOpen(true);
  //   };

  const refresh = () => {
    gridRef.current.instance.refresh();
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'InvoiceID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getTaxInvoiceList`, { ...searchData, LoginID: userId });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData]);

  const onCancel = useCallback(async e => {
    // 취소 버튼 클릭 시
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    const { InvoiceID } = selectedItems[0];
    const result = confirm('<i> 세금계산서 발행을 취소하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        await salesPayment.cancelTaxInvoiceListByID({ InvoiceID: InvoiceID, RegisterUser: '' }).then(res => {
          if (res.isOk) {
            notify(
              {
                message: '취소되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            gridRef.current.instance.refresh();
          } else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      }
    });
  }, []);

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarItem location={'before'}>
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="FromDate"
            onValueChanged={handleFromDate}
            value={dateValue.fromDate}
          />
        </ToolbarItem>
        <ToolbarItem location={'before'} text="~" />
        <ToolbarItem location={'before'}>
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="ToDate"
            min={dateValue.fromDate}
            value={dateValue.toDate}
            onValueChanged={handleToDate}
            dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
          />
        </ToolbarItem>
        <ToolbarItem location={'after'}>
          <Button text="취소" onClick={onCancel} />
        </ToolbarItem>
      </Toolbar>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card grid-with-status'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={1000}
        onCellPrepared={onCellPrepared}
        isHeader={false}
        isExcel={false}
      >
        <Selection mode="single" selectAllMode="pages" />
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        {/* <Column dataField={'detail'} caption={'상세'} cellRender={editCellRender} width={50} /> */}
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={80} />
        <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
        <Column dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={80} />
        <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
        <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} />
        <Column dataField={'SalesPrice'} caption={'금액'} width={160} format="#,##0 원" />
        <Column dataField={'TaxPrice'} caption={'부가세'} width={80} />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} />
        <Column dataField={'CustomerCode'} caption={'회사'} width={80} />
        <Summary>
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
      {/* <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="42%" height={860}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <DetailHistory ref={detailHistoryRef} setIsOpen={setIsOpen} refresh={refresh} />
          </div>
        </ScrollView>
      </Popup> */}
    </React.Fragment>
  );
});

export default History;
