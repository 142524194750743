import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import DataSource from 'devextreme/data/data_source';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { Popup } from 'devextreme-react/popup';
import { Column, HeaderFilter, MasterDetail, Scrolling, Selection } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import { autoDataSource } from 'pages/sales-mng/api/costCenterData';
import { parking, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import { COMPANYCODE } from 'constants/codes';
import CommonDataGrid from 'common/default-data-grid';
import CmsPopup from './components/popup/cms-popup';
import CmsIgnorePopup from './components/popup/cms-ignore-popup';
import TerminalGrid from './components/terminal-grid';
import ParkingSalesAggregationForm from './components/parking-sales-aggregation-form';

const ParkingSalesAggregationInfo = () => {
  const gridRef = useRef({});
  const cmsRef = useRef({});
  const cmsIgnoreRef = useRef({});
  const terminalRef = useRef({});

  const cmsPopupRef = useRef({});
  const cmsIgnorePopupRef = useRef({});

  // 검색
  const [searchText, setSearchText] = useState('');
  const [costCenterCode, setCostCenterCode] = useState('');
  const [costCenterName, setCostCenterName] = useState('');

  // 데이터
  const [dataSource, setDataSource] = useState([]);
  const [cmsInfo, setCmsInfo] = useState([]);
  const [cmsIgnoreInfo, setCmsIgnoreInfo] = useState([]);
  const [terminalSource, setTerminalSource] = useState([]);

  // select
  const [bankType, setBankType] = useState([]);
  const [cardAgencyType, setCardAgencyType] = useState([]);

  //refresh
  const [isCMSRefresh, setIsCMSRefresh] = useState(false);
  const [isCMSIgnoreRefresh, setIsCMSIgnoreRefresh] = useState(false);
  const [isTerminalRefresh, setIsTerminalRefresh] = useState(false);

  //팝업
  const [popupButton, setPopupButton] = useState({
    save: false,
    close: false,
  });
  const [isAdd, setAdd] = useState(false);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    (async () => {
      await parking
        .searchCMSAccountMaster({
          SeachCostCenter: searchText,
        })
        .then(res => {
          if (res.isOk) {
            setDataSource(res.data);
            setCostCenterCode(res.data.length > 0 ? res.data[0].CostCenterCode : '');
            setCostCenterName(res.data.length > 0 ? res.data[0].CostCenterName : '');
          } else {
            alert(error.errorMsgCheck(res.error.detail));
          }
        });
    })();
  }, [searchText]);

  // 우측 그리드 refresh 상황 분리하여 api 호출함.
  useEffect(() => {
    (async () => {
      await parking
        .searchCMSAccountMaster({
          SeachCostCenter: searchText,
        })
        .then(res => {
          if (res.isOk) {
            setDataSource(res.data);
          } else {
            alert(error.errorMsgCheck(res.error.detail));
          }
        });
    })();
  }, [isCMSRefresh, isCMSIgnoreRefresh, isTerminalRefresh]);

  useEffect(() => {
    (async () => {
      if (costCenterCode !== '') {
        // CMS 계좌 목록
        await parking
          .searchCMSAccountByCostCenter({
            CostCenterCode: costCenterCode,
          })
          .then(res => {
            if (res.isOk) {
              setCmsInfo(res.data);
            } else {
              alert(error.errorMsgCheck(res.error.detail));
            }
          });
      }
    })();
  }, [costCenterCode, isCMSRefresh]);

  useEffect(() => {
    (async () => {
      if (costCenterCode !== '') {
        // 제외 CMS 입금자명
        await parking
          .searchCMSIgnoreSummarytByCostCenter({
            CostCenterCode: costCenterCode,
          })
          .then(res => {
            if (res.isOk) {
              setCmsIgnoreInfo(res.data);
            } else {
              alert(error.errorMsgCheck(res.error.detail));
            }
          });
      }
    })();
  }, [costCenterCode, isCMSIgnoreRefresh]);

  useEffect(() => {
    (async () => {
      if (costCenterCode !== '') {
        // 단말기 정보
        await parking
          .searchSalesAggregationByCostCenter({
            CompanyCode: COMPANYCODE,
            CostCenterCode: costCenterCode,
          })
          .then(res => {
            if (res.isOk) {
              setTerminalSource(res.data);
              const rowInfo = res.data.length > 0 ? res.data[0] : '';
              setRowData(rowInfo);
            } else {
              alert(error.errorMsgCheck(res.error.detail));
            }
          });
      }
    })();
  }, [costCenterName, isTerminalRefresh]);

  useEffect(() => {
    parkingInfoSelectType();
  }, []);
  const parkingInfoSelectType = async () => {
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['CardAgencyType', 'BankType'],
    });
    const cardAgencyType = [];
    const bankType = [];
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'CardAgencyType') {
          return cardAgencyType.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'BankType') {
          return bankType.push({
            id: obj.Code + ' : ' + obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
    }

    setCardAgencyType(cardAgencyType);
    setBankType(bankType);
  };

  const costData = new DataSource({
    store: autoDataSource,
    key: 'CostCenterCode',
  });

  const onSelectionChanged = e => {
    if (e.selectedItem === null) {
      setSearchText('');
    } else {
      const { CostCenterCode } = e.selectedItem;
      setSearchText(CostCenterCode);
    }
  };

  const onCellClick = e => {
    if (e.selectedRowsData.length > 0) {
      setCostCenterCode(e.selectedRowsData[0].CostCenterCode);
      setCostCenterName(e.selectedRowsData[0].CostCenterName);
    }
  };
  const onCellClickTerminal = e => {
    if (e.selectedRowsData.length > 0) {
      setRowData(e.selectedRowsData[0]);
    }
  };

  const onCmsDelete = () => {
    const rowData = cmsRef.current.instance.getSelectedRowsData();
    if (rowData.length === 0) {
      alert('삭제할 대상을 선택하세요.');
    } else {
      const result = confirm('<i>삭제하시겠습니까?</i>', '삭제');
      result.then(dialogResult => {
        if (dialogResult) {
          const accountNos = rowData.map(d => d.AccountNo);
          parking
            .deleteCMSAccount({
              AccountNo: accountNos.join(','),
              CostCenterCode: costCenterCode,
            })
            .then(res => {
              if (res.isOk) {
                notify(
                  {
                    message: '삭제되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                setIsCMSRefresh(!isCMSRefresh);
                cmsRef.current.instance.clearSelection();
              } else {
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const onCmsIgnoreDelete = () => {
    const rowData = cmsIgnoreRef.current.instance.getSelectedRowsData();
    if (rowData.length === 0) {
      alert('삭제할 대상을 선택하세요.');
    } else {
      const result = confirm('<i>삭제하시겠습니까?</i>', '삭제');
      result.then(dialogResult => {
        if (dialogResult) {
          const IgnoreSummary = rowData.map(d => d.IgnoreSummary);
          parking
            .deleteCMSIgnoreSummary({
              IgnoreSummary: IgnoreSummary.join(','),
              CostCenterCode: costCenterCode,
            })
            .then(res => {
              if (res.isOk) {
                notify(
                  {
                    message: '삭제되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                setIsCMSIgnoreRefresh(!isCMSIgnoreRefresh);
                cmsIgnoreRef.current.instance.clearSelection();
              } else {
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const onDelete = () => {
    const rowData = terminalRef.current.instance.getSelectedRowsData();
    if (rowData.length === 0) {
      alert('삭제할 대상을 선택하세요.');
    } else {
      const result = confirm('<i>삭제하시겠습니까?</i>', '삭제');
      result.then(dialogResult => {
        if (dialogResult) {
          const del = rowData.map(d => {
            return {
              CompanyCode: d.CompanyCode,
              CostCenter: d.CostCenterCode,
              CardAgencyType: d.CardAgencyType,
              CardAgencyList: d.CardAgencyList,
            };
          });

          parking.deleteParkingSalesInfo(del[0]).then(res => {
            if (res.isOk) {
              notify(
                {
                  message: '삭제되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              terminalRefresh();
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
        }
      });
    }
  };

  const cellRender = useCallback(e => {
    return (
      <Button
        icon="search"
        width={120}
        text="보기"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup('view', e.data)}
      />
    );
  }, []);

  const onClose = () => [setPopupButton({ ...popupButton, close: false })];

  const togglePopup = (str, e) => {
    if (str === 'view') {
      // 보기 버튼 클릭시
      setAdd(false);
      setRowData(e.row.data);
      setPopupButton({ save: false, close: !popupButton.close });
    } else {
      // 등록 버튼 클릭시
      if (!rowData) {
        setRowData({ CostCenterName: costCenterName, CostCenterCode: costCenterCode });
      }
      setAdd(true);
      setPopupButton({ save: false, close: !popupButton.close });
    }
  };

  const terminalRefresh = () => {
    setIsTerminalRefresh(!isTerminalRefresh);
    terminalRef.current.instance.clearSelection();
  };

  return (
    <div>
      <h2 className={'content-block detail-title'}>
        사업소 매출집계 정보 관리
        <div className="Step">
          <div className="Active">
            <i className="dx-icon-chevrondoubleright dx-con-customicon"></i>
          </div>
          <h3>{'사업소 : ' + '[' + costCenterCode + '] ' + costCenterName + ' '}</h3>
        </div>
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '40%' }}>
              {/* 사업소 목록 */}
              <CommonDataGrid
                gridRef={gridRef}
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={true}
                columnAutoWidth={false}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={350}
                // onCellClick={onCellClick}
                onSelectionChanged={onCellClick}
                stylingMode="outlined"
                keyExpr="CostCenterCode"
                allowColumnResizing={true}
                isExcel={true}
                selectedRowKeys={[costCenterCode]}
                excelOptions={{
                  sheetName: 'Sheet',
                  fileName: '사업소 매출집계 정보.xlsx',
                }}
                isHeader={true}
                headerBefore={[
                  <Lookup
                    labelMode="static"
                    stylingMode="outlined"
                    dataSource={costData}
                    valueExpr="CostCenterName"
                    displayExpr="CostCenterName"
                    searchExpr="CostCenterName"
                    searchEnabled={true}
                    dropDownCentered={false}
                    width={300}
                    placeholder="P-코드/사업소명"
                    onSelectionChanged={onSelectionChanged}
                    mode="search"
                  >
                    <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
                  </Lookup>,
                ]}
              >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Selection mode="single" selectAllMode="pages" />
                <HeaderFilter visible={true} />
                {/* <Column dataField={'CostCenterCode'} caption={'사업소코드'} width={80} /> */}
                <Column dataField={'HiParkingID'} caption={'P코드'} width={70} />
                <Column dataField={'CostCenterName'} caption={'사업소명'} width={150} />
                <Column dataField={'AccountNoList'} caption={'CMS 계좌 리스트'} width={150} />
                <Column dataField={'CMSIgnoreSummaryList'} caption={'제외 CMS 입금자명 리스트'} />
              </CommonDataGrid>
              <div className="tab-box tab-box-top-removal">
                <TabPanel>
                  <TabItem title="CMS 계좌 목록">
                    <CommonDataGrid
                      gridRef={cmsRef}
                      className={'dx-card wide-card header-btn'}
                      dataSource={cmsInfo}
                      showBorders={true}
                      columnAutoWidth={false}
                      columnHidingEnabled={true}
                      hoverStateEnabled={true}
                      height={280}
                      stylingMode="outlined"
                      isExcel={false}
                      isHeader={true}
                      headerAfter={[
                        <Button
                          icon="plus"
                          text="등록"
                          type="normal"
                          stylingMode="contained"
                          onClick={() => cmsPopupRef.current.open()}
                        />,
                        <Button icon="trash" text="삭제" type="normal" stylingMode="contained" onClick={onCmsDelete} />,
                      ]}
                    >
                      <Scrolling mode="virtual" rowRenderingMode="virtual" />
                      <Selection mode="multiple" selectAllMode="pages" />
                      <Column dataField={'AccountNo'} caption={'CMS 계좌번호'} />
                      <Column dataField={'BankName'} caption={'은행'} />
                    </CommonDataGrid>
                  </TabItem>
                  <TabItem title={'제외 CMS 입금자명'}>
                    <CommonDataGrid
                      gridRef={cmsIgnoreRef}
                      className={'dx-card wide-card'}
                      dataSource={cmsIgnoreInfo}
                      showBorders={true}
                      columnAutoWidth={false}
                      columnHidingEnabled={true}
                      hoverStateEnabled={true}
                      height={280}
                      stylingMode="outlined"
                      isHeader={true}
                      isExcel={false}
                      headerAfter={[
                        <Button
                          icon="plus"
                          text="등록"
                          type="normal"
                          stylingMode="contained"
                          onClick={() => cmsIgnorePopupRef.current.open()}
                        />,
                        <Button
                          icon="trash"
                          text="삭제"
                          type="normal"
                          stylingMode="contained"
                          onClick={onCmsIgnoreDelete}
                        />,
                      ]}
                    >
                      <Scrolling mode="virtual" rowRenderingMode="virtual" />
                      <Selection mode="multiple" selectAllMode="pages" />
                      <Column dataField={'IgnoreSummary'} caption={'제외 CMS 입금자명'} />
                    </CommonDataGrid>
                  </TabItem>
                </TabPanel>
              </div>
            </div>
            <div style={{ maxWidth: '60%' }}>
              {/* 단말기 정보 */}
              <CommonDataGrid
                gridRef={terminalRef}
                className={'dx-card wide-card master-grid'}
                dataSource={terminalSource}
                showBorders={true}
                columnAutoWidth={false}
                columnHidingEnabled={true}
                hoverStateEnabled={true}
                height={680}
                keyExpr="KeyID"
                selectedRowKeys={[rowData.KeyID]}
                onSelectionChanged={onCellClickTerminal}
                isHeader={true}
                isExcel={false}
                headerAfter={[
                  <Button
                    id="link1"
                    icon="plus"
                    text="등록"
                    width={80}
                    type="normal"
                    stylingMode="contained"
                    onClick={() => togglePopup('add')}
                  />,
                  <Button
                    icon="trash"
                    text="삭제"
                    width={80}
                    type="normal"
                    stylingMode="contained"
                    onClick={onDelete}
                  />,
                ]}
              >
                <Scrolling mode="standartd" rowRenderingMode="standard" />
                <Selection mode="single" />
                <Column dataField={'CostCenterName'} caption={'사업소명'} width={150} />
                <Column dataField={'CardAgencyTypeName'} caption={'결제대행사유형'} width={120} />
                <Column dataField={'CardAgencyListName'} caption={'결제대행사'} width={100} />
                <Column dataField={'AgencyWebAddress'} caption={'결제사웹주소'} width={120} />
                <Column dataField={'AgencyWebID'} caption={'결제사웹ID'} width={100} />
                <Column dataField={'AgencyWebPWD'} caption={'결제사웹PWD'} width={120} />
                <Column dataField={'BankTypeName'} caption={'실물계좌은행'} width={120} />
                <Column dataField={'BankAccount'} caption={'실물계좌번호'} width={120} />
                <Column
                  dataField={'info'}
                  caption={'매출집계정보'}
                  cellComponent={cellRender}
                  allowExporting={false}
                  width={120}
                />
                <MasterDetail enabled={true} component={TerminalGrid} />
              </CommonDataGrid>
            </div>
          </div>
        </div>
      </div>
      <CmsPopup
        ref={cmsPopupRef}
        bankType={bankType}
        costCenterCode={costCenterCode}
        refresh={isCMSRefresh}
        setRefresh={setIsCMSRefresh}
      />
      <CmsIgnorePopup
        ref={cmsIgnorePopupRef}
        costCenterCode={costCenterCode}
        refresh={isCMSIgnoreRefresh}
        setRefresh={setIsCMSIgnoreRefresh}
      />
      <Popup onHiding={onClose} visible={popupButton.close} showTitle={false} width="40%" height={580}>
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <ParkingSalesAggregationForm
              setPopupButton={setPopupButton}
              closePopup={popupButton.close}
              isAdd={isAdd}
              rowData={rowData}
              refresh={terminalRefresh}
              cardAgencyType={cardAgencyType}
              bankType={bankType}
            />
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
};

export default ParkingSalesAggregationInfo;
