import React, { useCallback, useState, useImperativeHandle, useRef } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { master, error } from 'api';

const CustomerMasterForm = React.forwardRef((props, ref) => {
  const formRef = useRef({});
  const { setPopupVisibility, setLoadPanelVisible, setIsRefresh, isRefresh } = props;
  const [formData, setFormData] = useState({});

  useImperativeHandle(ref, () => ({
    open,
  }));

  // 팝업 오픈
  const open = data => {
    const formObj = Object.assign({}, data);
    setFormData(formObj);
  };

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        CustomerNo,
        CustomerManagerGroup,
        CustomerManagerName,
        CustomerTelephoneNo,
        TaxInvoiceEmail1,
        TaxInvoiceEmail2,
      } = formData;
      setLoadPanelVisible(true);
      await master
        .updateCustomerMaster({
          CustomerNo,
          CustomerManagerGroup,
          CustomerManagerName,
          CustomerTelephoneNo,
          TaxInvoiceEmail1,
          TaxInvoiceEmail2,
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '수정 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            setIsRefresh(!isRefresh);
            onClose();
          } else {
            // error
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [formData],
  );

  const onClose = () => {
    setPopupVisibility({ close: false });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData}
          height={400}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="사업자 정보 변경" colCount={2}>
            <SimpleItem dataField="CustomerCorpName" colSpan={1} editorOptions={{ readOnly: true }}>
              <Label text="회사명" location="left" />
            </SimpleItem>
            <SimpleItem dataField="CorpRegNumber" editorType="dxTextBox" colSpan={1} editorOptions={{ readOnly: true }}>
              <Label text="사업자 등록번호" location="left" />
            </SimpleItem>
            <SimpleItem
              dataField="NameOfRepresentative"
              editorType="dxTextBox"
              colSpan={1}
              editorOptions={{ readOnly: true }}
            >
              <Label text="대표자 성명" location="left" />
            </SimpleItem>
            <SimpleItem dataField="CustomerManagerGroup" editorType="dxTextBox" colSpan={1}>
              <Label text="담당부서" location="left" />
            </SimpleItem>
            <SimpleItem dataField="CustomerManagerName" editorType="dxTextBox" colSpan={1}>
              <Label text="담당자" location="left" />
            </SimpleItem>
            <SimpleItem dataField="CustomerTelephoneNo" editorType="dxTextBox" colSpan={1}>
              <Label text="담당자 전화번호" location="left" />
            </SimpleItem>
            <SimpleItem dataField="TaxInvoiceEmail1" editorType="dxTextBox" colSpan={1}>
              <Label text="담당자 이메일1" location="left" />
            </SimpleItem>
            <SimpleItem dataField="TaxInvoiceEmail2" editorType="dxTextBox" colSpan={1}>
              <Label text="담당자 이메일2" location="left" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'80%'} type={'default'} useSubmitBehavior={true} text="확인"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'80%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default CustomerMasterForm;
