import React, { useImperativeHandle, useRef, useCallback } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';

const ConfirmPopup = React.forwardRef((props, ref) => {
  const { setPopupVisible, handleConfirmSubmit } = props;
  const formData = useRef({});
  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const close = () => {
    setPopupVisible(false);
    formData.current = {};
  };

  const open = () => {
    setPopupVisible(true);
    formData.current = {};
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const isChecked = formData.current.ConfirmCheck;
      handleConfirmSubmit(isChecked);
    },
    [handleConfirmSubmit],
  );

  return (
    <div>
      <form className="add-sales-master-form" onSubmit={onSubmit}>
        <Form
          formData={formData.current}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <GroupItem caption="확인" colCount={2}>
            <SimpleItem colSpan={2}>
              <i>현금영수증을 취소하시겠습니까?</i>
            </SimpleItem>
            <SimpleItem dataField="ConfirmCheck" editorType="dxCheckBox" colSpan={2}>
              <Label text="이 매출에 대해 더 이상 발행하지 않음" location="left" visible={true} />
            </SimpleItem>
            <ButtonItem horizontalAlignment="right">
              <ButtonOptions stylingMode="text" type={'default'} useSubmitBehavior={true} text={'yes'}></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions stylingMode="text" type={'default'} text="no" onClick={close}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
});

export default ConfirmPopup;
