import axios from 'axios';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');
/* 
  정기권 관리
*/

export async function searchCodeMaster(params) {
  try {
    const result = await axios.post(`master/codemaster/searchCodeMaster`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function searchParkingInfoByCostCenter(params) {
  try {
    const result = await axios.post(`seasonticket/searchParkingInfoByCostCenterWithTicket`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchParkingInfoByCostCenterWithTicket(params) {
  try {
    const result = await axios.post(`seasonticket/searchParkingInfoByCostCenterWithTicket`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function searchSeasonTicketMemberMaster(params) {
  try {
    const result = await axios.post(`/seasonticket/searchSeasonTicketMemberMaster`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateSeasonTicketMemberMaster(params) {
  try {
    const result = await axios.post(`/seasonticket/updateSeasonTicketMemberMaster`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertSeasonTicketMemberMaster(params) {
  try {
    const result = await axios.post(`seasonticket/insertSeasonTicketMemberMaster`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function uploadSeasonTicketMemberExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`seasonticket/uploadSeasonTicketMemberExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function searchSeasonTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/searchSeasonTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertSeasonTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/insertSeasonTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateSeasonTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/updateSeasonTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateSeasonTicketIssueHistoryMultiOption(params) {
  try {
    const result = await axios.post(`seasonticket/updateSeasonTicketIssueHistoryMultiOption`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchSeasonTicketMemberGroup(params) {
  try {
    const result = await axios.post(`seasonticket/searchSeasonTicketMemberGroup`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertSeasonTicketMemberGroup(params) {
  try {
    const result = await axios.post(`seasonticket/insertSeasonTicketMemberGroup`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function updateSeasonTicketMemberGroup(params) {
  try {
    const result = await axios.post(`seasonticket/updateSeasonTicketMemberGroup`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function deleteSeasonTicketMemberGroup(params) {
  try {
    const result = await axios.post(`seasonticket/deleteSeasonTicketMemberGroup`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 연장
export async function extendSeasonTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/extendSeasonTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 발행 취소
export async function cancelSeasonTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/cancelSeasonTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 회원 이력조회
export async function getSeasonTicketMemberMasterHistory(params) {
  try {
    const result = await axios.post(`seasonticket/getSeasonTicketMemberMasterHistory `, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 발행 이력조회
export async function getSeasonTicketIssueHistoryHistory(params) {
  try {
    const result = await axios.post(`seasonticket/getSeasonTicketIssueHistoryHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchCostCenterDivisionMasterA(params) {
  try {
    const result = await axios.post(`master/searchCostCenterDivisionMasterA`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 발행 확정
export async function confirmSeasonTicketIssueHistory(params) {
  try {
    const result = await axios.post(`seasonticket/confirmSeasonTicketIssueHistory`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 발행 일괄수정
export async function updateSeasonTicketIssueHistoryMulti(params) {
  try {
    const result = await axios.post(`seasonticket/updateSeasonTicketIssueHistoryMulti`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchCostCenterForAutoComplete(params) {
  try {
    const result = await axios.post(`master/searchCostCenterForAutoComplete`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertSeasonTicketIssueHistoryWithNewMember(params) {
  try {
    const result = await axios.post(`seasonticket/insertSeasonTicketIssueHistoryWithNewMember`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 정기권 그룹정보 가져오기
export async function searchSeasonTicketMemberGroupByGroupID(params) {
  try {
    const result = await axios.post(`seasonticket/searchSeasonTicketMemberGroupByGroupID`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 정기권 회원 삭제
export async function deleteSeasonticketMember(params) {
  try {
    const result = await axios.post(`seasonticket/deleteSeasonticketMember`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadVanIparkingExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadVanIparkingExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadVanP00002Excel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadVanP00002Excel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function searchSeasonTicketIssueHistoryByID(params) {
  try {
    const result = await axios.post(`seasonticket/searchSeasonTicketIssueHistoryByID`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function searchSeasonTicketIssueHistoryByIDs(params) {
  try {
    const result = await axios.post(`seasonticket/searchSeasonTicketIssueHistoryByIDs`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadVanCarPayExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadVanCarPayExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadPGKGInicisExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadPGKGInicisExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadSmartroPGExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadPGSmartroExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadPGEasypayAccountExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadPGEasypayAccountExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadVanHyosungCMSExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadVanHyosungCMSExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadPG_APGExcel(params) {
  try {
    console.log(params);
    const result = await axios.post(`aggregation/uploadPG_APGExcel`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function insertOtherTicketJson(params) {
  try {
    console.log(params);
    const result = await axios.post(`seasonticket/insertOtherTicketJson`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function checkOtherTicketJson(params) {
  try {
    console.log(params);
    const result = await axios.post(`seasonticket/checkOtherTicketJson`, {
      ...params,
      LoginID: userId,
    });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function uploadPGSmartroAccountExcel(params) {
  try {
    const result = await axios.post(`aggregation/uploadPGSmartroAccountExcel`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSeasonTicketCardPaymentHistory(params) {
  try {
    const result = await axios.post(`aggregation/getSeasonTicketCardPaymentHistory`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function searchSeasonTicketIssueHistoryPendingConfirm(params) {
  try {
    const result = await axios.post(`seasonticket/searchSeasonTicketIssueHistoryPendingConfirm`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function checkPaymentStatus(params) {
  try {
    const result = await axios.post(`aggregation/payrequest/checkPaymentStatus`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function getPaysysRequestByID(params) {
  try {
    const result = await axios.post(`aggregation/payrequest/getPayRequestInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getPaysysRequestByDate(params) {
  try {
    const result = await axios.post(`aggregation/payrequest/getPayRequestList`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
export async function registerPayRequestInfo(params) {
  try {
    const result = await axios.post(`aggregation/payrequest/registerPayRequestInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function cancelPayRequestInfo(params) {
  try {
    const result = await axios.post(`aggregation/payrequest/cancelPayRequestInfo`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function getSeasonTicketList(params) {
  try {
    const result = await axios.post(`seasonticket/getSeasonTicketList`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data[0],
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
