import React, { useEffect, useState, useRef } from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter, Pager } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { seasonticketMngManualLink } from 'constants/hyperlink';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Popup } from 'devextreme-react/popup';
import { CheckBox } from 'devextreme-react/check-box';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react';
import { ticket } from 'api';
import moment from 'moment';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import CommonDataGrid from 'common/default-data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import Tickets from './tabs/tickets';
import ConfirmTickets from './tabs/confirm-tickets';
import DeleteTickets from './tabs/delete-tickets';
import TicketIssueForm from './components/season-ticket-mng-form';
import TicketMemberIssueForm from './components/season-ticket-mng-member-form';

const SeasonTicketMngImp = () => {
  const gridRef = useRef({});
  const issueRef = useRef({});
  const mIssueRef = useRef({});
  const lookupRef = useRef({});
  const ticketsRef = useRef({});
  const confirmTicketsRef = useRef({});
  const deleteTicketsRef = useRef({});

  const [withTerminateCheck, setWithTerminateCheck] = useState(true);
  // 검색
  const [searchParam, setSearchParam] = useState({
    CostCenterCode: '',
    MemberText: '',
    SearchGroupCode: '*',
    CostCenterName: '',
  });
  //날짜 검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    toDate: moment().add(3, 'months').endOf('month').format('YYYY-MM-DD'),
  });
  const [cdataSource, setcDataSource] = useState([]); // costcenter data
  const [mdataSource, setmDataSource] = useState([]); // member data
  const [tdataSource, setTDataSource] = useState([]); // ticket data
  const [ctdataSource, setCTDataSource] = useState([]); //confirm ticket data
  const [dtdataSource, setDTDataSource] = useState([]); // delete ticket data
  const [pdataSource, setpDataSource] = useState([]); // pending data

  // 코드 그룹
  const [selectPayType, setSelectPayType] = useState([]);
  const [selectType, setSelectType] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [selectDivision, setSelectDivision] = useState();
  const [selectUseYN, setSelectUseYN] = useState();

  // 선택된 회원
  const [memberID, setMemberID] = useState([]);
  const [text, setText] = useState('');

  // 팝업
  const [issuePopup, setIssuePopup] = useState({
    save: false,
    visible: false,
  });
  // 팝업
  const [mIssuePopup, setmIssuePopup] = useState({
    save: false,
    visible: false,
  });

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [gridDefault, setGridDefault] = useState(false);
  const [allRefresh, setAllRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['PaymentType', 'MemberType', 'MemberStatus', 'UseYN', 'MemberDivision'],
      });
      const PaymentType = [];
      const MemberType = [];
      const MemberStatus = [];
      const UseYN = [];
      const MemberDivision = [];

      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'MemberStatus') {
            MemberStatus.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'UseYN') {
            UseYN.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'MemberDivision') {
            return MemberDivision.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentType') {
            return PaymentType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectPayType(PaymentType);
      setSelectType(MemberType);
      setSelectStatus(MemberStatus);
      setSelectUseYN(UseYN);
      setSelectDivision(MemberDivision);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchParkingInfoByCostCenterWithTicket({
        SearchText: '',
        MemberText: searchParam.MemberText,
      });
      if (result.isOk) {
        setcDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, [searchParam.MemberText]);

  useEffect(() => {
    (async () => {
      const { CostCenterCode, MemberText, SearchGroupCode } = searchParam;
      const result = await ticket.searchSeasonTicketMemberMaster({
        CostCenter: CostCenterCode,
        MemberText,
        SearchGroupCode,
        WithTerminate: withTerminateCheck,
      });
      if (result.isOk) {
        setmDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, [searchParam, allRefresh, withTerminateCheck]);

  useEffect(() => {
    callCostCenter();
  }, [searchParam, withTerminateCheck]);

  const onSelectionChanged = e => {
    setGridDefault(!gridDefault);
    if (e.selectedItem === null) {
      setSearchParam({
        ...searchParam,
        MemberText: '',
      });
    } else {
      const { CostCenterCode, CostCenterName } = e.selectedItem;
      setSearchParam({
        ...searchParam,
        CostCenterCode,
        CostCenterName,
      });
    }
    // 부모 컴포넌트 그리드 설정 초기화
    gridRef.current.instance.clearSelection();
    gridRef.current.instance.clearFilter();
  };

  const onMemberSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    if (selectRows.length > 0) {
      const memberIds = [];
      const vehicleRegistration = [];
      selectRows.forEach(r => {
        memberIds.push(r.MemberID);
        vehicleRegistration.push(r.VehicleRegistration);
      });
      setMemberID(memberIds);
    } else {
      setMemberID([]);
    }
  };

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsSummary') options.totalValue = memberID.length;
    else if (options.name === 'TotalSummary') options.totalValue = mdataSource.length;
  };

  useEffect(() => {
    callTickets();
  }, [memberID, dateValue]);

  const checkDataFormat = data => {
    const regex = /^\d{4}\d{2}\d{2}$/;
    if (regex.test(data)) {
      return data;
    } else {
      return '';
    }
  };

  const callCostCenter = () => {
    (async () => {
      const { CostCenterCode } = searchParam;
      const result = await ticket.searchSeasonTicketIssueHistoryPendingConfirm({
        CostCenter: CostCenterCode,
        WithTerminate: withTerminateCheck,
      });
      if (result.isOk) {
        setpDataSource(result.data);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  };

  const callTickets = () => {
    (async () => {
      try {
        // 발행목록
        setLoadPanelVisible(true);
        const rawData = await ticket.searchSeasonTicketIssueHistory({
          MemberID: memberID,
          Status: '',
          OnlyWait: 'N',
          FromDate: dateValue.fromDate,
          ToDate: dateValue.toDate,
        });
        setLoadPanelVisible(false);
        const result = rawData.data.map(a => ({
          ...a,
          AccountingDate: checkDataFormat(a.AccountingDate),
        }));
        // W 대기 중
        const tData = result.filter(a => a.isEditable === 'Y' || a.SalesStatus === 'W');
        setTDataSource(tData);
        // E 대사 전 취소, W 대기 중
        const ctData = result.filter(a => a.isEditable === 'N' && a.SalesStatus !== 'E' && a.SalesStatus !== 'W');
        setCTDataSource(ctData);
        // E 대사 전 취소
        const dtData = result.filter(a => a.SalesStatus === 'E');
        setDTDataSource(dtData);
      } catch (e) {
        setLoadPanelVisible(false);
        const { type, title, status, detail, instance } = e.response.data;
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    })();
  };

  const refresh = type => {
    if (type === 'all') {
      setAllRefresh(!allRefresh);
    } else {
      callTickets();
      callCostCenter();
    }
  };

  const showIssuePopup = () => {
    if (memberID.length === 0) {
      alert('발행할 정기권을 선택해 주세요');
    } else {
      setIssuePopup({ save: false, visible: true });
      issueRef.current.open(memberID, searchParam.CostCenterName);
    }
  };
  const showMemberIssuePopup = () => {
    if (searchParam.CostCenterCode) {
      setmIssuePopup({ save: false, visible: true });
      mIssueRef.current.open(searchParam.CostCenterCode, searchParam.CostCenterName);
    } else {
      alert('사업소를 선택해 주세요');
    }
  };

  const onClose = () => {
    setIssuePopup({ save: false, visible: false });
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onSearch = () => {
    setSearchParam({ ...searchParam, MemberText: text.trim() });
    lookupRef.current.instance.focus();
    notify(
      {
        message: '검색이 완료되었습니다. 사업소를 확인해주세요',
        width: 230,
        position: {
          at: 'top',
          my: 'top',
          of: '#container',
        },
      },
      'success',
    );
  };

  const onTextValueChanged = e => {
    setText(e.value);
  };

  const onInput = e => {
    if (e.event.originalEvent === undefined) {
      setSearchParam({ ...searchParam, MemberText: '' });
      lookupRef.current.instance.focus();
    }
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const onCheckValueChanged = e => {
    setWithTerminateCheck(e.value);
  };

  return (
    <div>
      <div className="content-block detail-title left-right-header">
        <h2 className={'detail-title'}>
          정기권 발행 관리
          <div className="Step">
            <div className="Active">
              <i className="dx-icon-chevrondoubleright dx-icon-customicon"></i>
            </div>
            <h3>{'사업소 : ' + '[' + searchParam.CostCenterCode + '] ' + searchParam.CostCenterName + ' '}</h3>
          </div>
          <div className="Step">
            <div className="Active">
              <i className="dx-icon-chevrondoubleright dx-icon-customicon"></i>
            </div>
            <h3>{searchParam.MemberText === '' ? '전체 검색' : '검색어 : ' + searchParam.MemberText}</h3>
          </div>
          {pdataSource.length > 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                width: '300px',
                color: '#ff5722',
              }}
            >
              <div style={{ fontSize: '14px' }}>
                <i className="fas fa-exclamation-circle"></i>
              </div>
              <div>
                <div style={{ fontSize: '14px' }}>
                  발행 후 확정되지 않은 정기권이 총 <b>{pdataSource.length}건</b> 존재합니다.
                </div>
              </div>
            </div>
          )}
        </h2>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={seasonticketMngManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '30%' }}>
              <Toolbar>
                <ToolbarItem location={'before'}>
                  <Lookup
                    ref={lookupRef}
                    labelMode="static"
                    stylingMode="outlined"
                    dataSource={cdataSource}
                    valueExpr="CostCenterName"
                    displayExpr="CostCenterNameFull"
                    searchExpr="CostCenterNameFull"
                    searchEnabled={true}
                    dropDownCentered={false}
                    width={270}
                    placeholder="사업소를 선택해주세요"
                    onSelectionChanged={onSelectionChanged}
                    mode="search"
                  >
                    <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
                  </Lookup>
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <CheckBox defaultValue={true} text="종료회원 포함" onValueChanged={onCheckValueChanged} />
                </ToolbarItem>
              </Toolbar>

              <CommonDataGrid
                gridRef={gridRef}
                className={'dx-card wide-card'}
                dataSource={mdataSource}
                showBorders={true}
                columnAutoWidth={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={663}
                onSelectionChanged={onMemberSelectionChanged}
                stylingMode="outlined"
                keyExpr="MemberID"
                allowColumnResizing={true}
                isFilterSelection
                isExcel={true}
                excelOptions={{
                  sheetName: 'Sheet',
                  fileName: '정기권 회원 목록.xlsx',
                }}
                isHeader={true}
                headerBefore={[
                  <TextBox
                    labelMode="static"
                    mode="search"
                    stylingMode="outlined"
                    placeholder="차량번호/회원명/휴대전화"
                    onValueChanged={onTextValueChanged}
                    onInput={onInput}
                    onEnterKey={onSearch}
                    width={190}
                  />,
                ]}
                headerAfter={[
                  <Button icon="user" width={80} text="신규" onClick={() => showMemberIssuePopup()} />,
                  <Button icon="plus" width={80} text="발행" onClick={() => showIssuePopup()} />,
                ]}
              >
                <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
                <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
                <HeaderFilter visible={true} />
                <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />

                <Column dataField={'VehicleRegistration'} width={90} caption={'차량번호'} />
                <Column dataField={'MemberName'} width={80} caption={'회원명'} />
                <Column dataField={'GroupCode'} width={100} visible={false} alignment={'left'} />
                <Column dataField={'GroupName'} width={100} caption={'회원그룹'} alignment={'left'} />
                <Column dataField={'MemberStatusName'} width={80} caption={'상태'} />
                <Column dataField={'MemberTypeName'} width={80} caption={'유형'} />
                <Summary calculateCustomSummary={calculateSelectedRow}>
                  <TotalItem
                    name="SelectedRowsSummary"
                    summaryType="custom"
                    displayFormat="선택 {0}건"
                    showInColumn="VehicleRegistration"
                  />
                  <TotalItem name="TotalSummary" column="MemberName" summaryType="custom" displayFormat="총 {0}건" />
                </Summary>
              </CommonDataGrid>
            </div>
            <div style={{ maxWidth: '70%' }}>
              <div className="date-box">
                <Toolbar>
                  <ToolbarItem location={'before'}>
                    <DateBox
                      type="date"
                      displayFormat="yyyy-MM-dd"
                      stylingMode="outlined"
                      dropDownButtonTemplate={'dropDownButton'}
                      useMaskBehavior={true}
                      icon={true}
                      dataField="FromDate"
                      max={dateValue.toDate}
                      value={dateValue.fromDate}
                      onValueChanged={handleFromDate}
                    />
                  </ToolbarItem>
                  <ToolbarItem location={'before'}>~</ToolbarItem>
                  <ToolbarItem location={'before'}>
                    <DateBox
                      type="date"
                      displayFormat="yyyy-MM-dd"
                      stylingMode="outlined"
                      dropDownButtonTemplate={'dropDownButton'}
                      useMaskBehavior={true}
                      icon={true}
                      dataField="ToDate"
                      min={dateValue.fromDate}
                      value={dateValue.toDate}
                      onValueChanged={handleToDate}
                      dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
                    />
                  </ToolbarItem>
                </Toolbar>
              </div>
              <div className="tab-box">
                <TabPanel>
                  <Item title={'확정'}>
                    <ConfirmTickets
                      ref={confirmTicketsRef}
                      gridDefault={gridDefault}
                      ctdataSource={ctdataSource}
                      searchParam={searchParam}
                      refresh={refresh}
                      setLoadPanelVisible={setLoadPanelVisible}
                    />
                  </Item>
                  <Item title={'발행'}>
                    <Tickets
                      ref={ticketsRef}
                      gridDefault={gridDefault}
                      tdataSource={tdataSource}
                      refresh={refresh}
                      setLoadPanelVisible={setLoadPanelVisible}
                    />
                  </Item>
                  <Item title={'취소'}>
                    <DeleteTickets
                      ref={deleteTicketsRef}
                      gridDefault={gridDefault}
                      dtdataSource={dtdataSource}
                      refresh={refresh}
                      setLoadPanelVisible={setLoadPanelVisible}
                    />
                  </Item>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 정기권 발행 팝업 */}
      <Popup
        onHiding={onClose}
        visible={issuePopup.visible}
        showCloseButton={true}
        showTitle={false}
        width={'40%'}
        height={550}
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <TicketIssueForm
              ref={issueRef}
              selectPayType={selectPayType}
              setPopup={setIssuePopup}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      {/* 정기권 신규 팝업 */}
      <Popup
        onHiding={onClose}
        visible={mIssuePopup.visible}
        showCloseButton={true}
        showTitle={false}
        width={'75%'}
        height={750}
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <TicketMemberIssueForm
              ref={mIssueRef}
              selectPayType={selectPayType}
              selectType={selectType}
              selectStatus={selectStatus}
              selectDivision={selectDivision}
              selectUseYN={selectUseYN}
              setPopup={setmIssuePopup}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <PSLoadPanel
        // shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default SeasonTicketMngImp;
