import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import { locale } from 'devextreme/localization';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import axios from 'axios';

const { host } = window.location;
const isProd = host === 'live.hidms.co.kr';
axios.defaults.baseURL = isProd ? '/api/' : 'http://localhost:3001/api/';
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

function App() {
  const { user, loading } = useAuth();
  useEffect(() => {
    locale(navigator.language);
  }, []);
  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
