import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Iparking from './iparking-payment-upload/iparking-payment-upload';
import MHP from './mhp-payment-upload/mhp-payment-upload';
import BlueWalnut from './bluewalnut-payment-upload/bluewalnut-payment-upload';
import EasyPayAccount from './easypayaccount-payment-upload/easypayaccount-payment-upload';
import HyosungCMS from './hyosungcms-payment-upload/hyosungcms-payment-upload';
import KocesUploadPage from './koces-payment-upload/koces-payment-upload';
import P00002Payment from './P00002-payment-upload/P00002-payment-upload';
import SmartroPG from './smartroPG-payment-upload/smartroPG-payment-upload';
import SmartoPGAccount from './smartroPGAccount-payment-upload/smartroPGAccount-payment-upload';
const PaymentUploadPage = () => {
  return (
    <div>
      <h2 className={'content-block detail-title'}>결제 내역 Excel 업로드</h2>
      <div className={'content-block'}>
        <TabPanel>
          <Item title={'아이파킹'}>
            <Iparking />
          </Item>
          <Item title={'mhp(KG이니시스)'}>
            <MHP />
          </Item>
          <Item title={'Blue Walnut(현대카페이)'}>
            <BlueWalnut />
          </Item>
          <Item title={'세차-효성CMS'}>
            <HyosungCMS />
          </Item>
          <Item title={'이지페이-가상계좌(임시)'}>
            <EasyPayAccount />
          </Item>
          <Item title={'인천공항 KOCES'}>
            <KocesUploadPage />
          </Item>
          <Item title={'아브뉴프랑 정산'}>
            <P00002Payment />
          </Item>
          <Item title={'스마트로PG'}>
            <SmartroPG />
          </Item>
          <Item title={'스마트로PG-가상계좌'}>
            <SmartoPGAccount />
          </Item>
        </TabPanel>
      </div>
    </div>
  );
};

export default PaymentUploadPage;
