import React, { useMemo, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Column, Pager, Paging, Selection, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import { TextBox } from 'devextreme-react/text-box';
import CustomStore from 'devextreme/data/custom_store';
import PgDetailGrid from './components/pg-detail-grid';
import CommonDataGrid from 'common/default-data-grid';
import './pg-sale-aggregation.scss';

const userId = localStorage.getItem('userId');

const PGAggregationData = () => {
  const costCenterGridRef = useRef({});
  const resultGridRef = useRef({});
  const [searchText, setSearchText] = useState('');
  const [text, setText] = useState('');
  const [searchParams, setSearchParams] = useState({
    CostCenter: '',
    FromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const dataSourceAll = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'CostCenterCode',
      loadMode: 'raw',
      load: async () => {
        try {
          const { FromDate, ToDate } = searchParams;
          const result = await axios.post(`aggregation/searchParkingInfoWithPGAggrByCostCenter`, {
            SearchText: searchText,
            FromDate,
            ToDate,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchText, searchParams]);

  const dataSourceOne = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        try {
          const { CostCenterCode, FromDate, ToDate } = searchParams;
          const result = await axios.post(`aggregation/getPGAggregationDataByCostCenterDay`, {
            CostCenterCode,
            FromDate,
            ToDate,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams]);

  const onChangeSearchText = e => {
    setText(e.value);
  };

  const onEnterKey = e => {
    setSearchText(text.trim());
  };

  const onCellClick = e => {
    const { CostCenterCode } = e.row.data;
    setSearchParams({
      ...searchParams,
      CostCenterCode,
    });
  };

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const dateFormet = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onSearch = () => {
    setSearchParams({
      ...searchParams,
      FromDate: dateFormet(dateValue.fromDate),
      ToDate: dateFormet(dateValue.toDate),
    });
  };

  return (
    <div>
      <h2 className={'content-block'}>PG사 매출 집계 데이터 조회</h2>
      <div className={'content-block'}>
        <div className="widget-container">
          <div className={'dx-card responsive-paddings'} style={{ maxWidth: '44%', height: '70%' }}>
            <CommonDataGrid
              gridRef={costCenterGridRef}
              className={'dx-card wide-card'}
              dataSource={dataSourceAll}
              showBorders={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              hoverStateEnabled={true}
              onCellClick={onCellClick}
              height={650}
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: 'PG사 매출 집계 데이터.xlsx',
              }}
              headerBefore={[
                <div className="label">결제일 :</div>,
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  width={110}
                  icon={true}
                  dataField="FromDate"
                  onValueChanged={handleFromDate}
                  value={dateValue.fromDate}
                />,
                <span> ~ </span>,
                <DateBox
                  type="date"
                  displayFormat="yyyy-MM-dd"
                  stylingMode="outlined"
                  dropDownButtonTemplate={'dropDownButton'}
                  useMaskBehavior={true}
                  icon={true}
                  width={110}
                  dataField="ToDate"
                  min={dateValue.fromDate}
                  value={dateValue.toDate}
                  onValueChanged={handleToDate}
                  dateOutOfRangeMessage="거래시작일보다 빠를 수 없습니다."
                />,
                <TextBox
                  labelMode="static"
                  stylingMode="outlined"
                  placeholder="사업소/사업소명/관리ID"
                  width={180}
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
              ]}
              headerAfter={[
                <Button
                  type="normal"
                  stylingMode="contained"
                  icon="search"
                  text="조회"
                  width={80}
                  onClick={onSearch}
                />,
              ]}
            >
              {/* <Paging defaultPageSize={17} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" /> */}
              <Selection mode="single" />
              <Column dataField={'CostCenterName'} caption={'사업소명'} />
              <Column dataField={'HiParkingID'} caption={'P코드'} />
              <Column dataField={'OperatingTypeName'} caption={'운영유형'} />
              <Column dataField={'LocationInfoName'} caption={'권역'} />
              <Column dataField={'SalesPriceSum'} caption={'매출 합계'} format="#,##0 원" />
            </CommonDataGrid>
          </div>

          <div className={'dx-card responsive-paddings'} style={{ maxWidth: '55%' }}>
            <CommonDataGrid
              gridRef={resultGridRef}
              className={'dx-card wide-card'}
              dataSource={dataSourceOne}
              showBorders={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              hoverStateEnabled={true}
              height={650}
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: 'PG사 매출 집계 데이터_상세.xlsx',
              }}
            >
              {/* <Paging defaultPageSize={17} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" /> */}
              {/* <Scrolling mode="infinite" /> */}
              <Column dataField={'HiparkingID'} caption={'P코드'} />
              <Column dataField={'CostCenterName'} caption={'사업소'} />
              <Column dataField={'CardAgencyType'} caption={'결제대행유형'} width={80} />
              <Column dataField={'CardAgencyList'} caption={'결제대행사'} width={80} />
              <Column dataField={'TransactionDate'} caption={'거래일'} customizeText={customizeText} />
              <Column dataField={'CardTypeName'} caption={'결제수단'} />
              <Column dataField={'ApprovalTypeName'} caption={'거래구분'} width={80} />
              <Column dataField={'TerminalID'} caption={'단말기ID'} />
              <Column dataField={'ApprovalNo'} caption={'승인번호'} />
              <Column dataField={'SalesPrice'} caption={'금액'} alignment="left" format="#,##0 원" />
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'AccountingNo'} caption={'대사ID'} />
              <Column dataField={'CostCenterCode'} value={searchParams.CostCenterCode} visible={false} />
              {/* <MasterDetail enabled={true} component={PgDetailGrid} /> */}
            </CommonDataGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PGAggregationData;
