import React, { useCallback, useState, useImperativeHandle } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { ticket, error } from 'api';
import { isWithin3Months } from 'common/validators/validators';

const ExtendTicketForm = React.forwardRef((props, ref) => {
  const { setPopup, refresh, setLoadPanelVisible } = props;
  const nextMonth = moment().add(1, 'M').format('YYYY-MM-DD');
  const [formData, setFormData] = useState({
    TicketID: [],
    FromDate: moment(nextMonth).startOf('month').format('YYYY-MM-DD'),
    ToDate: moment(nextMonth).endOf('month').format('YYYY-MM-DD'),
    PaymentType: '',
    SalesPrice: 0,
  });
  let isConfirmSave = false;
  const taxRadioSelect = [
    {
      label: '발행',
      value: 1,
    },
    {
      label: '발행 안 함',
      value: 0,
    },
  ];
  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = TicketID => {
    setFormData({
      ...formData,
      TicketID: TicketID,
      PaymentType: '',
      SalesPrice: 0,
      TaxYN: 0,
    });
  };
  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { PaymentType, SalesPrice, FromDate, ToDate, TicketID } = formData;
      const confirmYN = isConfirmSave ? 'Y' : 'N';
      const isConfirmed = await isWithin3Months(FromDate, ToDate);
      if (!isConfirmed) {
        return;
      }
      setLoadPanelVisible(true);
      await ticket
        .extendSeasonTicketIssueHistory({
          TicketID,
          FromDate,
          ToDate,
          PaymentType,
          SalesPrice,
          ConfirmYN: confirmYN,
          RegisterUser: '',
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '연장 되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            refresh();
            onClose();
          } else {
            // error
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [formData, isConfirmSave],
  );

  const onClose = () => {
    const nextMonth = moment().add(1, 'M').format('YYYY-MM-DD');
    setPopup({ save: false, visible: false });
    setFormData({
      FromDate: moment(nextMonth).startOf('month').format('YYYY-MM-DD'),
      ToDate: moment(nextMonth).endOf('month').format('YYYY-MM-DD'),
      SalesPrice: 0,
    });
  };

  const handleDateFrom = e => {
    // 계약 시작일 선택 시 해당 월의 마지막 날을 계약 종료일로 자동 선택
    if (e.value) {
      setFormData({
        ...formData,
        ToDate: moment(e.value).endOf('month').format('YYYY-MM-DD'),
      });
    }
  };
  const onTaxTypeChanged = e => {
    if (e.value === 1) {
      setFormData({ ...formData, PaymentType: 'TaxInvoice', TaxYN: e.value });
    } else if (e.value === 0) {
      setFormData({ ...formData, PaymentType: '', TaxYN: e.value });
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="정기권 연장">
            <SimpleItem
              dataField="TaxYN"
              colSpan={2}
              editorType="dxRadioGroup"
              shouldRender="condition"
              editorOptions={{
                items: taxRadioSelect,
                valueExpr: 'value',
                displayExpr: 'label',
                layout: 'horizontal',
                onValueChanged: onTaxTypeChanged,
              }}
            >
              <RequiredRule message="필수 값입니다." />
              <Label text="청구 세금계산서" />
            </SimpleItem>
            <SimpleItem
              dataField="FromDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
                onValueChanged: handleDateFrom,
              }}
            >
              <Label text="계약시작일" location="left" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
            <SimpleItem
              dataField="ToDate"
              editorType="dxDateBox"
              colSpan={1}
              editorOptions={{
                type: 'date',
                dropDownButtonTemplate: 'dropDownButton',
                displayFormat: 'yyyy-MM-dd',
                useMaskBehavior: true,
                icon: true,
                min: formData.FromDate,
                dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
              }}
            >
              <Label text="계약종료일" location="left" />
              <RequiredRule message="필수값 입니다." />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={3}>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'default'}
                text="즉시확정"
                onClick={() => {
                  isConfirmSave = true;
                }}
                useSubmitBehavior={true}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions
                colSpan={1}
                width={'100%'}
                type={'default'}
                text="발행"
                onClick={() => {
                  isConfirmSave = false;
                }}
                useSubmitBehavior={true}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions colSpan={1} width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default ExtendTicketForm;
