export const errorMsgCheck = JsonData => {
  const msgStr = JSON.parse(JsonData).sqlMessage;
  const regex = /Lock wait timeout|Deadlock found/;
  const altMsg = '시스템 오류. 관리자에게 문의 바랍니다.';
  const result = regex.test(msgStr) ? altMsg + ':' + msgStr : msgStr;
  return result;
};

export const errorStateCheck = JsonData => {
  const sqlState = JSON.parse(JsonData).sqlState;
  const result = sqlState;
  return result;
};

export const handleAuthError = (() => {
  let isAlertShown = false;
  return e => {
    if (e.response.data.instance === '/checkAuth' && !isAlertShown) {
      isAlertShown = true;
      alert('세션 시간(12시간)이 종료되었습니다. 다시 로그인해 주세요.');
      window.location.reload();
    }
  };
})();
