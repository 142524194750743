import React, { useCallback, useState, useEffect, useRef } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  PatternRule,
} from 'devextreme-react/form';
import { salesPayment, error } from 'api';
import { Toast, Position } from 'devextreme-react/toast';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import { issueCreditCard } from 'constants/bank';

const CancelEditForm = props => {
  const { refresh, setPopup, editRowdata, refundReason, refundPaymentType, setLoadPanelVisible } = props;
  const [isCash, setIsCash] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);
  const [tempDateValue, setTempDateValue] = useState({ TempFromDate: '' });
  const [formData, setFormData] = useState({
    Idx: '',
    CostCenterCode: '',
    CostCenterName: '',
    SalesType: '',
    SalesKeyID: '',
    CardAgencyListName: '',
    IssueCreditCard: '',
    CreditCardApprovalNo: '',
    TransactionDate: '',
    SalesPrice: '',
    RefundPaymentType: '',
    RefundStatusName: '',
    CustomerName: '',
    TelephoneNo: '',
    VehicleRegistrationNo: '',
    RefundPrice: '',
    RefundReason: '',
    BankName: '',
    BankAccountNo: '',
    AccountHolder: '',
    CustomerInfo: '',
    Remark: '',
    RegisterUser: '',
    CancelFromDate: '',
    CancelToDate: '',

    // 참고 정보
    ApprovalDate: '',
    ApprovedDate: '',
  });
  const cancelTicketFormRef = useRef({});
  useEffect(() => {
    if (Object.keys(editRowdata).length !== 0) {
      editRowdata.RefundPaymentType === 'Cash' ? setIsCash(true) : setIsCash(false);
      editRowdata.CancelFromDate && editRowdata.CancelToDate ? setIsPeriod(true) : setIsPeriod(false);
      setFormData({ ...editRowdata });
    }
  }, [editRowdata]);
  const onClose = () => {
    setPopup({ save: true, visible: false });
    onHiding();
  };

  const onHiding = () => {
    setIsToast(false);
  };

  const contentRender = () => {
    return (
      <div style={{ color: 'black' }}>
        <i className="dx-icon-info icon-style"></i>
        <span>
          {' '}
          100만원 이상 현금 환불 건은 <br /> <u>그룹포탈에 기안 상신 후 </u>매출 취소 요청 부탁 드립니다.
        </span>
        {/* <div>그룹포탈에 기안 상신 후 매출 취소 요청 부탁 드립니다.</div> */}
      </div>
    );
  };

  const onClickRefundPaymentType = e => {
    e.itemData.value === 'Cash' ? setIsCash(true) : setIsCash(false);
    if (e.itemData.value === 'Cash' && formData.RefundPrice >= 1000000) {
      setIsToast(true);
    } else {
      setIsToast(false);
    }
  };
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        Idx,
        SalesKeyID,
        CardAgencyListName,
        IssueCreditCard,
        CreditCardApprovalNo,
        TransactionDate,
        SalesPrice,
        RefundPaymentType,
        CustomerName,
        TelephoneNo,
        VehicleRegistrationNo,
        RefundPrice,
        RefundReason,
        BankName,
        BankAccountNo,
        AccountHolder,
        CustomerInfo,
        CancelFromDate,
        CancelToDate,
        Remark,
        RegisterUser,
      } = formData;
      setLoadPanelVisible(true);
      await salesPayment
        .updateRefundInfo({
          Idx,
          SalesKeyID,
          CardAgencyListName,
          IssueCreditCard,
          CreditCardApprovalNo,
          TransactionDate: moment(TransactionDate).format('YYYY-MM-DD'),
          SalesPrice,
          RefundPaymentType,
          CustomerName,
          TelephoneNo,
          VehicleRegistrationNo,
          RefundPrice,
          RefundReason,
          BankName: BankName.trim(),
          BankAccountNo: BankAccountNo.trim(),
          AccountHolder: AccountHolder.trim(),
          CustomerInfo,
          CancelFromDate,
          CancelToDate,
          Remark,
          RegisterUser,
        })

        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '수정되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
          onClose();
          refresh();
        });
    },
    [formData],
  );

  const onOptionChanged = e => {
    if (formData && e.name === 'value') {
      if (isCash && e.value >= 1000000) {
        setIsToast(true);
      } else {
        setIsToast(false);
      }
    }
  };

  const onValueChanged = e => {
    setTempDateValue({ TempFromDate: e.value });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div id="form-edit-container" className="sales-refund-form">
          <Form
            ref={cancelTicketFormRef}
            formData={formData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem colCount={isCash && isPeriod ? 7 : isCash ? 6 : 4}>
              {/* 매출정보 */}
              <GroupItem colSpan={2}>
                <GroupItem caption="매출 정보">
                  <SimpleItem dataField="CostCenterName" editorOptions={{ readOnly: true }}>
                    <Label text="사업소명" />
                  </SimpleItem>
                  {formData.SalesPrice !== '' && formData.SalesPrice !== null ? (
                    <SimpleItem dataField="SalesPrice" editorOptions={{ readOnly: true, format: '#,##0' }}>
                      <Label text="결제금액" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  ) : null}
                  {formData.IssueCreditCard !== '' && formData.IssueCreditCard !== null ? (
                    <SimpleItem
                      dataField="IssueCreditCard"
                      editorType="dxSelectBox"
                      editorOptions={{
                        items: issueCreditCard,
                        acceptCustomValue: true,
                      }}
                    >
                      <Label text="카드사" />
                      {/* settleCreditCard 정보가 매출대사/매출취소에 등록되면 적용 */}
                      {/* <RequiredRule message="필수값 입니다." /> */}
                    </SimpleItem>
                  ) : null}
                  <SimpleItem dataField="CardAgencyListName" editorOptions={{ readOnly: true }}>
                    <Label text="결제사" />
                  </SimpleItem>
                  {formData.CreditCardApprovalNo !== '' && formData.CreditCardApprovalNo !== null ? (
                    <SimpleItem
                      dataField="CreditCardApprovalNo"
                      editorOptions={{
                        readOnly: true,
                        maxLength: 10,
                      }}
                    >
                      <Label text="카드승인번호" />
                    </SimpleItem>
                  ) : null}
                  {formData.ApprovalDate !== '' ||
                  formData.ApprovalDate !== null ||
                  formData.ApprovedDate !== '' ||
                  formData.ApprovedDate !== null ? (
                    <SimpleItem
                      dataField="TransactionDate"
                      editorType="dxDateBox"
                      editorOptions={{
                        type: 'date',
                        dropDownButtonTemplate: 'dropDownButton',
                        displayFormat: 'yyyy-MM-dd',
                        icon: true,
                        readOnly: true,
                      }}
                    >
                      <Label text="결제일자" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  ) : null}
                  <SimpleItem dataField="CostCenterCode" visible={false} />
                  <SimpleItem dataField="SalesType" visible={false} />
                  <SimpleItem dataField="SalesKeyID" visible={false} />
                  <SimpleItem dataField="Idx" visible={false} />
                </GroupItem>
              </GroupItem>
              {/* 환불정보 */}
              <GroupItem colSpan={isCash && isPeriod ? 3 : 2}>
                <GroupItem caption="환불 정보" colCount={2}>
                  <SimpleItem
                    colSpan={2}
                    dataField="RefundPaymentType"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: refundPaymentType,
                      placeholder: '환불 유형을 선택해주세요',
                      valueExpr: 'value',
                      displayExpr: 'id',
                      onItemClick: onClickRefundPaymentType,
                      readOnly: true,
                    }}
                  >
                    <Label text="환불수단" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="CustomerName">
                    <Label text="고객명" />
                    {/* <RequiredRule message="필수값 입니다." /> */}
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="TelephoneNo">
                    <Label text="고객연락처" />
                    {/* <RequiredRule message="필수값 입니다." /> */}
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="VehicleRegistrationNo">
                    <Label text="차량번호" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  {isPeriod && (
                    <SimpleItem
                      colSpan={1}
                      dataField="CancelFromDate"
                      editorType="dxDateBox"
                      editorOptions={{
                        type: 'date',
                        min: formData.SalesFromDate,
                        max: formData.SalesToDate,
                        dropDownButtonTemplate: 'dropDownButton',
                        displayFormat: 'yyyy-MM-dd',
                        icon: true,
                        onValueChanged: onValueChanged,
                      }}
                    >
                      <Label text="환불대상기간" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  )}
                  {isPeriod && (
                    <SimpleItem
                      colSpan={1}
                      dataField="CancelToDate"
                      editorType="dxDateBox"
                      editorOptions={{
                        type: 'date',
                        dropDownButtonTemplate: 'dropDownButton',
                        min: tempDateValue.TempFromDate,
                        max: formData.SalesToDate,
                        useMaskBehavior: true,
                        dateOutOfRangeMessage: '시작일보다 빠를 수 없습니다.',
                        displayFormat: 'yyyy-MM-dd',
                        icon: true,
                      }}
                    >
                      <Label text="종료일" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  )}
                  <SimpleItem
                    colSpan={2}
                    dataField="RefundPrice"
                    editorType="dxNumberBox"
                    editorOptions={{
                      format: '#,##0',
                      readOnly: editRowdata.SalesType === 'TaxInvoice',
                      onOptionChanged: onOptionChanged,
                    }}
                  >
                    <Label text="환불금액" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={2}
                    dataField="RefundReason"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: refundReason,
                      placeholder: '환불 사유를 선택해주세요',
                      valueExpr: 'value',
                      displayExpr: 'id',
                    }}
                  >
                    <Label text="환불사유" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem colSpan={2} dataField="Remark" editorType="dxTextArea">
                    <Label text="비고" />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              {/* 계좌정보 */}
              {isCash && (
                <GroupItem colSpan={2}>
                  <GroupItem caption="계좌 정보">
                    <SimpleItem dataField="BankName">
                      <Label text="은행명" />
                      <PatternRule
                        pattern={/^[ㄱ-ㅎ가-힣a-zA-Z]+(\s+)?$/}
                        message={'공백/특수문자/숫자는 입력이 불가합니다'}
                      />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="BankAccountNo">
                      <Label text="입금계좌" />
                      <PatternRule pattern={/^[\d-]+(\s+)?$/} message={'숫자와 - 기호만 입력 가능합니다.'} />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="AccountHolder">
                      <Label text="예금주" />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="CustomerInfo">
                      <Label text="고객과의 관계" />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
              )}
            </GroupItem>
            <GroupItem>
              <ButtonItem>
                <ButtonOptions type={'default'} useSubmitBehavior={true} text="수정"></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
        <Toast
          visible={isToast}
          width={230}
          height={50}
          type="custom"
          contentRender={contentRender}
          onHiding={onHiding}
          displayTime={7000}
        >
          <Position my="top" at="top" of="#form-edit-container" offset="0 -15" />
        </Toast>
      </form>
    </>
  );
};

export default CancelEditForm;
