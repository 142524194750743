import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Column, Scrolling, Selection, Editing } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import 'common/popup/popup.scss';

const TemplatePopup = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  useEffect(() => {
    (async () => {
      const testData = [
        {
          no: '기본산식',
          col1: 'B,C',
          col2: '(A-B)*C',
          value: '{A:매출}에서 {B:경비}를 제한 금액(손익금)을 {C:갑사배분율}%로 배분하여 갑사>자사로 청구',
        },
        {
          no: '추가1',
          col1: 'E',
          col2: '(A-B-E)*(C)',
          value: `{E:지정최소금액}이 지정된 경우에는 매출-경비 손익금 중 {E:지정최소금액}을 초과한 금액을 최종손익금으로 계산하여 배분함`,
        },
        {
          no: '추가2',
          col1: 'D,D’',
          col2: 'IF(수익금>D’,수익금*(D),수익금*(C))',
          value: `손익금이 {D’:특정기준금액}을 초과하는 경우, {D:초과갑사배분율을}%로 배분. 이 때 손익금은 추가1이 반영된 최종손익금임`,
        },

        //         {
        //           no: '5',
        //           col1: 'B,C, 수배기준: 매출',
        //           col2: 'A*C',
        //           value: '{A매출}을 {C자사배분율%} 배분',
        //         },
        //         {
        //           no: '6',
        //           col1: 'B,C,D 수배기준: 매출',
        //           col2: 'A*(C|D) ',
        //           value: `{A매출}에서 {B경비}를 제한 금액(손익금)이 {D’초과배분기준금액} 초과 시 매출의 {D자사배분율(초과시)%} 배분
        // {A매출}에서 {B경비}를 제한 금액(손익금)이 {D’초과배분기준금액} 미달 시 매출의 {C자사배분율%} 배분
        // `,
        //         },
        //         {
        //           no: '7',
        //           col1: 'B,C,E, 수배기준: 매출',
        //           col2: '(A-E)*C ',
        //           value: `{A매출}에서 {B경비}를 제한 금액(손익금)이 {E지정최소금액} 초과 시 초과분의 {C자사배분율%} 배분`,
        //         },
      ];
      const testData1 = [
        {
          col1: 'A',
          col2: '매출',
          value: 'Hi-DMS 매출 금액 출력 결과',
        },
        {
          col1: 'B',
          col2: '경비',
          value: `고정비 + 변동비`,
        },
        {
          col1: 'C',
          col2: '갑사배분율',
          value: `수익금 갑사 배분율`,
        },
        {
          col1: 'D',
          col2: '갑사배분율(초과시)',
          value: `특정 배분 기준금액 (D’:초과배분기준금액) 초과 시 적용 비율`,
        },
        {
          col1: 'D’',
          col2: '초과배분기준금액',
          value:
            '다음의 경우 4,000,000 입력: \'매출 400만원 이하 시 70%(갑사):30%(하이파킹), 매출 400만원 초과시 75%(갑사):25%(하이파킹)\'',
        },
        {
          col1: 'E',
          col2: '지정최소금액',
          value: '다음의 경우 79,166,000 입력: \'((매출-최소보장수입79,166,000)-운영비용11,035,000)>0일때 손익의 50% 배분\'',
        },
      ];
      setDataSource1(testData1);
      setDataSource(testData);
    })();
  }, []);

  const open = () => {
    setIsPopup(true);
  };

  const close = () => {
    setIsPopup(false);
  };

  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="70%" height={700}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <span className="popup-header">수배 정산 산식</span>
        <i className="dx-icon-info" style={{ fontSize: '12px' }} id="title-help">
          &nbsp; 사용자 입력값에 따라 시스템 자동 생성되는 산식을 설명합니다.
        </i>
        <div className="popup-content">
          <div className="dx-fieldset">
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource1}
              showBorders={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isNotFilterRow
              isHeader={false}
              height={280}
            >
              <Scrolling mode="standard" />
              <Selection mode="single" selectAllMode="pages" />
              <Column dataField={'col1'} caption={'산식변수'} width={150} allowEditing={false} />
              <Column dataField={'col2'} caption={'구분'} width={150} allowEditing={false} />
              <Column dataField={'value'} caption={'비고'} width={700} allowEditing={false} />
              {/* <Column dataField={'description'} caption={'설명'} width={'200'} allowEditing={false} /> */}
            </CommonDataGrid>
          </div>
          <div className="dx-fieldset">
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isNotFilterRow
              isHeader={false}
              height={230}
            >
              <Editing
                mode="cell"
                allowUpdating={true}
                selectTextOnEditStart={true}
                startEditAction={'click'}
                texts={{
                  saveAllChanges: '저장하기',
                  cancelAllChanges: '되돌리기',
                }}
              />
              <Scrolling mode="standard" />
              <Selection mode="single" selectAllMode="pages" />
              <Column dataField={'no'} caption={'기본'} width={90} allowEditing={false} />
              <Column dataField={'col1'} caption={'관련 입력값'} width={100} allowEditing={false} />
              <Column dataField={'col2'} caption={'배분 산식(자사)'} width={250} allowEditing={false} />
              <Column dataField={'value'} caption={'산식'} width={500} allowEditing={false} />
              {/* <Column dataField={'description'} caption={'설명'} width={'200'} allowEditing={false} /> */}
            </CommonDataGrid>
          </div>
        </div>
        <div className="popup-footer">
          <div>{/* <Button text="선택" width={'120'} onClick={onSave} /> */}</div>
          <div>{/* <Button text="선택 안함" width={'120'} onClick={onCancel} /> */}</div>
          <div>
            <Button text="닫기" width={'120'} onClick={close} />
          </div>
        </div>
      </div>
    </Popup>
  );
});

export default TemplatePopup;
