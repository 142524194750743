import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const GeneralTicket = props => {
  const { dataSource, tGeneralTicketRef, selectTax } = props;

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  useEffect(() => {
    if (dataSource && tGeneralTicketRef.current && selectTax) {
      const gridInstance = tGeneralTicketRef.current.instance;
      gridInstance.selectAll();
    }
  }, [selectTax]);
  return (
    <>
      <CommonDataGrid
        gridRef={tGeneralTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        allowSelectAll={true}
        isNotFilterRow={false}
        allowColumnReordering={true}
      >
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
        <Scrolling mode="standard" rowRenderingMode="standard" />
        <HeaderFilter visible={true} />
        <Column dataField={'HiParkingID'} caption={'P코드'} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} />
        <Column dataField={'AccountingDate'} caption={'대사날짜'} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" alignment="right" />
        <Column dataField={'ApprovalDate'} caption={'매출시간'} customizeText={customizeText} />
        <Column dataField={'PaymentDate'} caption={'결제시간'} customizeText={customizeText} />
        <Column dataField={'PaymentAgency'} caption={'결제사'} />
        <Column dataField={'PaymentCode'} caption={'수금구분'} />
        <Column dataField={'RefundStatusName'} caption={'취소요청상태'} />
        <Column dataField={'StatusName'} caption={'매출상태'} />
        <Column dataField={'SettlementNo'} caption={'세금계산서번호'} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} />
        <Column dataField={'Remark'} caption={'비고'} />
      </CommonDataGrid>
    </>
  );
};

export default React.memo(GeneralTicket);
