import React, { useImperativeHandle, useRef, useCallback } from 'react';
import Form, { GroupItem, SimpleItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';

const ConfirmPopup = React.forwardRef((props, ref) => {
  const { handleConfirmSubmit, accountingNo } = props;
  const formData = useRef({});
  useImperativeHandle(ref, () => ({}));
  const onSubmit = useCallback(
    async buttonType => {
      handleConfirmSubmit(buttonType, accountingNo);
    },
    [handleConfirmSubmit],
  );

  return (
    <div>
      <form className="add-sales-master-form" onSubmit={onSubmit}>
        <Form
          formData={formData.current}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <GroupItem caption="확인" colCount={9}>
            <SimpleItem colSpan={9}>
              <i>
                이미 회계 처리되어 대사를 취소할 수 없습니다. <br />
                보정을 위한 <b>매출/결제 데이터를 추가</b> 하시겠습니까?
              </i>
            </SimpleItem>
            <ButtonItem colSpan={2} horizontalAlignment="right">
              <ButtonOptions
                stylingMode="text"
                type={'default'}
                text={'yes'}
                onClick={() => onSubmit('yes')}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem colSpan={5}>
              <ButtonOptions
                stylingMode="text"
                type={'default'}
                text={'결제데이터만 추가'}
                onClick={() => onSubmit('onlyPayment')}
              ></ButtonOptions>
            </ButtonItem>
            <ButtonItem colSpan={2} horizontalAlignment="left">
              <ButtonOptions
                stylingMode="text"
                type={'default'}
                text="no"
                onClick={() => {
                  onSubmit('close');
                }}
              ></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
});

export default ConfirmPopup;
