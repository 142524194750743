import React, { useRef, useEffect, useState, useCallback } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  EmptyItem,
  ButtonItem,
  ButtonOptions,
  NumericRule,
} from 'devextreme-react/form';
import { Toast, Position } from 'devextreme-react/toast';
import { useYNRadioSelect } from 'common/select/Types';
import { parking } from 'api';

import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import './parking-system-form.scss';

const ParkingSystemForm = ({
  setPopupVisibility,
  costCenter,
  popupVisible,
  serverOS,
  iPType,
  vendorMaster,
  refresh,
  setRefresh,
}) => {
  const formRef = useRef({});
  const [parkingData, setParkingData] = useState({});
  const [vendorSelect, setVendorSelect] = useState([]);

  const [isDBNotRequired, setIsDBNotRequired] = useState(false);
  const [isToast, setIsToast] = useState(false);

  useEffect(() => {
    if (costCenter !== null && popupVisible.close) {
      updateInfo(costCenter);
    }
  }, [costCenter, popupVisible]);

  const updateInfo = useCallback(async costCenter => {
    const result = await parking.getParkingSytemInfo({
      CostCenter: costCenter,
    });
    if (result.isOk) {
      if (result.data[0]) {
        setParkingData(result.data[0]);
        handleVendorSWData(result.data[0].VendorCode);
      } else {
        setParkingData({
          VendorCode: '',
          SWVersion: '',
          OS: '',
          IPType: '',
          IP: '',
          DBAccount: '',
          DBPassword: '',
          DBPort: '',
          UseYN: 'Y',
          CostCenterCode: costCenter,
        });
      }
    }
  }, []);

  const handleVendorSWData = useCallback(async VendorCode => {
    const result = await parking.getParkingSystemVendorSW({
      VendorCode,
    });
    const vendorSw = [];
    if (result.isOk) {
      result.data.forEach(obj => {
        vendorSw.push({
          id: obj.SWVersionCode + ' : ' + obj.SWVersionName,
          value: obj.SWVersionCode,
        });
      });
    }
    setVendorSelect(vendorSw);
  }, []);

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { VendorCode, SWVersion, OS, IPType, IP, DBAccount, DBPassword, DBPort, CostCenterCode, UseYN } =
        formRef.current.props.formData;
      const result = await parking.updateParkingSystemInfo({
        CostCenter: CostCenterCode,
        VendorCode,
        SWVersion,
        OS,
        IPType,
        IP,
        DBAccount,
        DBPassword,
        DBPort,
        UseYN,
      });
      if (result.isOk) {
        setPopupVisibility({ save: true, close: false });
        setRefresh(!refresh);
      }
    },
    [refresh],
  );

  const onClose = e => {
    setPopupVisibility({ save: false, close: false });
  };

  const onClickButton = e => {
    const { VendorCode } = e.itemData;
    handleVendorSWData(VendorCode);
  };

  const onVersionItemClick = e => {
    const isDBNotRequired = e.itemData.id.includes('MHP');
    if (isDBNotRequired) {
      setIsToast(true);
      // setIsDBNotRequired(true);
    }
  };

  const contentRender = () => {
    return (
      <div style={{ color: 'black' }}>
        <i className="dx-icon-info icon-style"></i>
        <span>
          {' '}
          MHP 관제인 경우 IP, DB 정보를 입력하지 않아도 <br /> 관제 데이터가 자동 수집 됩니다
        </span>
      </div>
    );
  };

  const onHiding = () => {
    setIsToast(false);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container" id="form-controlsystem">
          <Form
            id="form"
            ref={formRef}
            formData={parkingData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption="관제시스템 정보" colCount={2}>
              <SimpleItem
                dataField="VendorCode"
                editorType="dxSelectBox"
                colSpan={2}
                editorOptions={{
                  items: vendorMaster,
                  valueExpr: 'VendorCode',
                  displayExpr: 'VendorName',
                  onItemClick: onClickButton,
                }}
              >
                <Label text="주차관제" />
              </SimpleItem>
              <SimpleItem
                dataField="SWVersion"
                editorType="dxSelectBox"
                colSpan={2}
                editorOptions={{
                  items: vendorSelect,
                  valueExpr: 'value',
                  displayExpr: 'id',
                  onItemClick: onVersionItemClick,
                }}
              >
                <Label text="S/W Ver" />
              </SimpleItem>
              <SimpleItem
                dataField="OS"
                editorType="dxSelectBox"
                colSpan={2}
                editorOptions={{
                  items: serverOS,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="OS" />
              </SimpleItem>
              <SimpleItem
                dataField="IPType"
                editorType="dxSelectBox"
                editorOptions={{
                  items: iPType,
                  valueExpr: 'value',
                  displayExpr: 'id',
                  // disabled: isDBNotRequired,
                }}
              >
                <Label text="IP 유형" />
              </SimpleItem>
              <SimpleItem
                dataField="IP"
                editorOptions={
                  {
                    // disabled: isDBNotRequired,
                  }
                }
              >
                <Label text="IP 주소" />
              </SimpleItem>
              <SimpleItem
                dataField="DBAccount"
                editorOptions={
                  {
                    // disabled: isDBNotRequired,
                  }
                }
              >
                <Label text="DB 계정" />
              </SimpleItem>
              <SimpleItem
                dataField="DBPort"
                editorOptions={
                  {
                    // disabled: isDBNotRequired,
                  }
                }
              >
                <Label text="DB 포트" />
                <NumericRule message={'숫자만 입력 가능합니다.'} />
              </SimpleItem>
              <SimpleItem
                dataField="DBPassword"
                editorOptions={
                  {
                    // disabled: isDBNotRequired,
                  }
                }
              >
                <Label text="DB 비밀번호" />
              </SimpleItem>
              <SimpleItem
                dataField="UseYN"
                editorType="dxRadioGroup"
                editorOptions={{
                  items: useYNRadioSelect,
                  valueExpr: 'value',
                  displayExpr: 'label',
                  layout: 'horizontal',
                }}
              >
                <Label text="관제DB 연동" />
              </SimpleItem>
            </GroupItem>
            <EmptyItem dataField="CoseCenterCode" />
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'40%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'40%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
        <Toast
          visible={isToast}
          width={230}
          height={50}
          type="custom"
          contentRender={contentRender}
          onHiding={onHiding}
          displayTime={5000}
        >
          <Position my="top" at="top" of="#form-controlsystem" offset="0 -15" />
        </Toast>
      </form>
    </>
  );
};

export default React.memo(ParkingSystemForm);
