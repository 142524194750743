import React, { useRef, useState, useCallback } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import { TextBox } from 'devextreme-react/text-box';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import PG from './component/pg';
import CMS from './component/cms';
import { salesPayment, error } from 'api';
import './cancel-payment.scss';

const CancelPayment = () => {
  const pgvanRef = useRef({});
  const cmsRef = useRef({});
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  /* TODO : 차 후 개선*/
  const [searchCenter, setSearchCenter] = useState('');
  const [searchApprovalNo, setSearchApprovalNo] = useState('');
  const [searchVehicleNumber, setSearchVehicleNumber] = useState('');

  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCenter: '',
    SearchApprovalNo: '',
    SearchVehicleNumber: '',
  });
  const [pRowdata, setPRowdata] = useState([]);
  const [cRowdata, setCRowdata] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };
  const onChangeCenter = e => {
    setSearchCenter(e.value);
  };
  const onChangeApprovalNo = e => {
    setSearchApprovalNo(e.value);
  };
  const onChangeVehicleNumber = e => {
    setSearchVehicleNumber(e.value);
  };
  const onSearch = () => {
    setSearchData({
      FromDate: dateValue.fromDate,
      ToDate: dateValue.toDate,
      SearchCenter: searchCenter,
      SearchApprovalNo: searchApprovalNo,
      SearchVehicleNumber: searchVehicleNumber,
    });
  };

  const onCancel = () => {
    const params = {};
    if (selectedTabIndex === 0) {
      params.PaymentType = 'PGVAN';
      params.KeyID = pRowdata[0].KeyID;
      params.RegisterUser = '';
    } else {
      params.PaymentType = 'CMS';
      params.KeyID = cRowdata[0].KeyID;
      params.RegisterUser = '';
    }

    const result = confirm('<i> 환불 처리 하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        await salesPayment.cancelPayment(params).then(res => {
          if (res.isOk) {
            notify(
              {
                message: '환불 처리가 완료되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            // 리프레쉬
            if (pgvanRef.current.instance) {
              pgvanRef.current.instance.refresh();
            }
            if (cmsRef.current.instance) {
              cmsRef.current.instance.refresh();
            }
          } else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      }
    });
  };

  const onTabSelectionChanged = useCallback(e => {
    if (e.addedItems[0].title === 'CMS') {
      setSelectedTabIndex(1);
    } else {
      setSelectedTabIndex(0);
    }
  }, []);
  return (
    <React.Fragment>
      <h2 className={'content-block detail-title'}>
        환불 관리
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
        <Button text="환불처리" width={120} onClick={onCancel} />
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />
            </ToolbarItem>
            <ToolbarItem location={'before'} text="~" />
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <TextBox
                name="SearchCenter"
                labelMode="static"
                stylingMode="outlined"
                placeholder="사업소코드/사업소명/P코드"
                onValueChanged={onChangeCenter}
                width={220}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <TextBox
                name="SearchApprovalNo"
                labelMode="static"
                stylingMode="outlined"
                placeholder="승인번호/카드번호/계좌번호"
                onValueChanged={onChangeApprovalNo}
                width={220}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <TextBox
                name="SearchVehicleNumber"
                labelMode="static"
                stylingMode="outlined"
                placeholder="차량번호"
                onValueChanged={onChangeVehicleNumber}
                width={220}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="search" text="조회" width={120} onClick={onSearch} />
            </ToolbarItem>
          </Toolbar>
          <div style={{ paddingTop: '5px' }}>
            <TabPanel selectedIndex={selectedTabIndex} onSelectionChanged={onTabSelectionChanged}>
              <Item title="PG & VAN">
                <PG pgvanRef={pgvanRef} setPRowdata={setPRowdata} searchData={searchData} type="cancel" />
              </Item>
              <Item title="CMS">
                <CMS cmsRef={cmsRef} setCRowdata={setCRowdata} searchData={searchData} type="cancel" />
              </Item>
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CancelPayment;
