import React, { useState, useCallback, useImperativeHandle, useRef, useEffect } from 'react';
import { Column, Pager, Selection, HeaderFilter, Scrolling, Summary, TotalItem } from 'devextreme-react/data-grid';

import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { ticket, error } from 'api';
import notify from 'devextreme/ui/notify';
import ScrollView from 'devextreme-react/scroll-view';
import CommonDataGrid from 'common/default-data-grid';
import EditForm from 'common/popup/season-ticket-mng-edit-form';
import MultEditForm from '../components/multi-edit-ticket-form';
import ExtendForm from '../components/extend-ticket-form';
import 'devextreme-react/text-area';
import '../season-ticket-mng.scss';

const Tickets = React.forwardRef((props, ref) => {
  const { tdataSource, refresh, setLoadPanelVisible, gridDefault } = props;
  // 그리드 레프
  const ticketgridRef = useRef({});
  // 팝업 레프
  const editRef = useRef({});
  const extendRef = useRef({});
  const mEditRef = useRef({});

  // 전체 티켓, 선택된 티켓
  // const [tdataSource, setTDataSource] = useState([]);
  const [ticketID, setTicketID] = useState([]);

  // 코드 그룹
  const [selectMemberType, setSelectMemberType] = useState([]);
  const [selectPayType, setSelectPayType] = useState([]);
  const [selectPayStatus, setSelectPayStatus] = useState([]);

  // 팝업
  const [extendPopup, setExtendPopup] = useState({
    save: false,
    visible: false,
  });
  const [editPopup, setEditPopup] = useState({
    save: false,
    visible: false,
  });
  const [multEditPopup, setMultEditPopup] = useState({
    save: false,
    visible: false,
  });

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    ticketgridRef.current.instance.clearFilter();
    ticketgridRef.current.instance.clearSelection();
  }, [gridDefault]);

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCodeMaster({
        CodeGroup: ['MemberType', 'PaymentType', 'PaymentStatus'],
      });
      const MemberType = [];
      const PaymentType = [];
      const PaymentStatus = [];
      if (result.isOk && result.data.length > 0) {
        result.data.forEach(a => {
          if (a.CodeGroup === 'MemberType') {
            MemberType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentType') {
            PaymentType.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          } else if (a.CodeGroup === 'PaymentStatus') {
            PaymentStatus.push({
              text: a.CodeDesc,
              codeDesc: a.CodeDesc,
              code: a.Code,
            });
          }
        });
      }
      setSelectMemberType(MemberType);
      setSelectPayType(PaymentType);
      setSelectPayStatus(PaymentStatus);
    })();
  }, []);

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsCount') options.totalValue = ticketID.length;
    else if (options.name === 'SelectedRowsSummary') {
      const selectedRows = options.component.getSelectedRowsData();
      const sum = selectedRows.reduce((a, c) => a + c.SalesPrice, 0);
      options.totalValue = sum;
    } else if (options.name === 'TotalCount') options.totalValue = tdataSource.length;
  };

  // 정기권 발행 취소, 만료된 부분 cell style 변경
  const onCellPrepared = useCallback(({ data, cellElement }) => {
    if (data) {
      const { RemainDay } = data;
      const tr = cellElement.parentNode;
      if (RemainDay === '만료' && tr) {
        tr.classList.add('ticket-expiration');
      } else if (RemainDay.includes('취소') && tr) {
        tr.classList.add('ticket-cancel');
      }
    }
  }, []);

  // 정기권 수정 (발행)
  const showEditPopup = () => {
    if (ticketID.length >= 1) {
      setEditPopup({ save: false, visible: !editPopup.visible });
      editRef.current.open(ticketID);
    } else {
      alert('수정할 정기권을 선택해 주세요.');
    }
  };

  // 정기권 연장 (발행, 확정)
  const showMultEditPopup = () => {
    if (ticketID.length === 0) {
      alert('수정할 정기권을 선택해 주세요');
    } else {
      setMultEditPopup({ save: false, visible: !multEditPopup.visible });
      mEditRef.current.open(ticketID);
    }
  };
  // 정기권 연장 (발행, 확정)
  const showExtendPopup = () => {
    if (ticketID.length === 0) {
      alert('연장할 정기권을 선택해 주세요');
    } else {
      setExtendPopup({ save: false, visible: !extendPopup.visible });
      extendRef.current.open(ticketID);
    }
  };

  // 정기권 확정 (발행)
  const onConfirmClick = () => {
    if (ticketID.length === 0) {
      return alert('정기권을 선택해 주세요.');
    }
    const result = confirm('<i>선택된 정기권을 확정하시겠습니까?</i>', '확정');
    result.then(dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        ticket
          .confirmSeasonTicketIssueHistory({
            TicketID: ticketID,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '발행 확정이 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              // setIsRefresh(!isRefresh);
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };

  // 정기권 발행 취소 (발행, 확정)
  const onCancelClick = () => {
    if (ticketID.length === 0) {
      return alert('정기권을 선택해 주세요.');
    }
    const result = confirm('<i>발행된 정기권을 취소하시겠습니까?</i>', '취소');
    result.then(dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        ticket
          .cancelSeasonTicketIssueHistory({
            TicketID: ticketID,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              // 발행 취소 완료
              setLoadPanelVisible(false);
              notify(
                {
                  message: '발행 취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };

  const onSelectionChanged = e => {
    const selectRows = e.selectedRowsData;
    if (selectRows.length > 0) {
      const ticketIds = [];
      // const vehicleRegistration = [];
      selectRows.forEach(r => {
        ticketIds.push(r.TicketID);
        // vehicleRegistration.push(r.VehicleRegistration);
      });
      setTicketID(ticketIds);
    } else {
      setTicketID([]);
    }
  };

  const onClose = () => {
    setEditPopup({ ...editPopup, visible: false });
    setMultEditPopup({ ...multEditPopup, visible: false });
    setExtendPopup({ ...extendPopup, visible: false });
  };

  // const onRowDblClick = e => {
  //   setEditPopup({ save: false, visible: !editPopup.visible });
  //   editRef.current.open(e.data);
  // };
  return (
    <>
      <CommonDataGrid
        gridRef={ticketgridRef}
        width="100%"
        className={'dx-card wide-card ticket-history'}
        dataSource={tdataSource}
        keyExpr="TicketID"
        showBorders={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        onSelectionChanged={onSelectionChanged}
        onCellPrepared={onCellPrepared}
        // onRowDblClick={onRowDblClick}
        allowColumnResizing={true}
        height={663}
        allowColumnReordering={true}
        isExcel={true}
        isFilterSelection
        excelOptions={{
          sheetName: 'Sheet',
          fileName: '정기권 발행목록.xlsx',
        }}
        isHeader={true}
        headerBefore={[]}
        headerAfter={[
          <Button
            icon="edit"
            text="수정"
            width={80}
            type="normal"
            stylingMode="contained"
            onClick={() => showEditPopup()}
          />,
          <Button
            icon="plus"
            text="연장"
            width={80}
            type="normal"
            stylingMode="contained"
            onClick={() => showExtendPopup()}
          />,
          <Button icon="check" width={80} text="확정" type="success" onClick={() => onConfirmClick()} />,
          <Button
            icon="minus"
            text="취소"
            width={80}
            type="danger"
            stylingMode="contained"
            onClick={() => onCancelClick()}
          />,
          <Button
            icon="refresh"
            type="normal"
            stylingMode="text"
            onClick={() => {
              refresh();
            }}
          />,
        ]}
      >
        <HeaderFilter visible={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="onClick" />
        <Pager showPageSizeSelector={false} showInfo={false} visible={false} displayMode="full" />
        <Column dataField={'VehicleRegistration'} width={100} caption={'차량번호'} fixed={true} />
        <Column dataField={'FromDate'} caption={'시작일'} dataType="date" width={100} alignment={'left'} fixed={true} />
        <Column dataField={'ToDate'} caption={'종료일'} dataType="date" width={100} alignment={'left'} fixed={true} />
        <Column dataField={'SalesPrice'} caption={'판매금액'} width={100} alignment={'left'} format="#,##0 원" />
        {/* <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={100} alignment={'left'} /> */}
        <Column
          dataField={'SalesStatusName'}
          caption={'매출상태'}
          width={100}
          alignment={'left'}
          cellRender={e => {
            return e.value;
          }}
        />
        <Column dataField={'PaymentCodeName'} caption={'수금구분'} width={100} alignment={'left'} />
        <Column dataField={'MemberName'} caption={'회원명'} width={80} alignment={'left'} />
        <Column dataField={'GroupCode'} width={80} visible={false} alignment={'left'} />
        <Column dataField={'GroupName'} caption={'회원그룹'} width={100} alignment={'left'} />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={150} />{' '}
        <Column dataField={'ExtendStatusName'} caption={'상태'} width={100} />
        <Column dataField={'RemainDay'} caption={'남은일수'} width={100} alignment={'left'} />
        <Column dataField={'MemberTypeName'} caption={'정기권유형'} width={110} alignment={'left'} />
        <Column dataField={'MemberDivisionName'} caption={'회원구분'} width={110} alignment={'left'} />
        <Column dataField={'SalesDate'} caption={'판매일'} width={110} alignment={'left'} />
        <Column dataField={'Remark'} caption={'비고'} width={80} alignment={'left'} />
        <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={80} alignment={'left'} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={80} alignment={'left'} />
        <Column dataField={'IssueCreditCard'} caption={'발급사'} width={80} alignment={'left'} />
        <Column dataField={'SettleCreditCard'} caption={'매입사'} width={80} alignment={'left'} />
        <Column
          dataField={'RegisterDate'}
          caption={'등록일시'}
          width={100}
          alignment={'left'}
          // customizeText={customizeText}
        />
        <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'left'} />
        <Column dataField={'TicketID'} visible={false} />
        <Summary calculateCustomSummary={calculateSelectedRow}>
          <TotalItem
            name="SelectedRowsCount"
            summaryType="custom"
            displayFormat="선택 {0}건"
            showInColumn="VehicleRegistration"
          />
          <TotalItem showInColumn="FromDate" valueFormat="선택합계:" />
          <TotalItem
            name="SelectedRowsSummary"
            summaryType="custom"
            showInColumn="FromDate"
            displayFormat="{0}"
            valueFormat="#,##0 원"
          />
          <TotalItem name="TotalCount" summaryType="custom" showInColumn="ToDate" displayFormat="총 {0}건" />
          <TotalItem showInColumn="SalesPrice" valueFormat="전체합계:" />
          <TotalItem
            column="SalesPrice"
            summaryType="sum"
            displayFormat="{0}"
            showInColumn="SalesPrice"
            valueFormat="#,##0 원"
          />
        </Summary>
      </CommonDataGrid>
      {/* 정기권 개별+일괄 수정 팝업 */}
      <Popup
        onHiding={onClose}
        visible={editPopup.visible}
        showTitle={true}
        width="30%"
        height={580}
        dragOutsideBoundary={true}
        dragEnabled={true}
        showCloseButton={true}
        title="정기권 정보 수정"
      >
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <EditForm
              ref={editRef}
              setPopup={setEditPopup}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
              selectMemberType={selectMemberType}
            />
          </div>
        </ScrollView>
      </Popup>
      {/* 정기권 연장 팝업 */}
      <Popup onHiding={onClose} visible={extendPopup.visible} showTitle={false} width="25%" height={360}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <ExtendForm
            ref={extendRef}
            setPopup={setExtendPopup}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
      {/* 정기권 일괄수정 팝업 */}
      <Popup onHiding={onClose} visible={multEditPopup.visible} showTitle={false} width="25%" height={350}>
        <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
          <MultEditForm
            ref={mEditRef}
            setPopup={setMultEditPopup}
            selectMemberType={selectMemberType}
            refresh={refresh}
            setLoadPanelVisible={setLoadPanelVisible}
          />
        </div>
      </Popup>
    </>
  );
});

export default React.memo(Tickets);
