import React, { useCallback, useState, useImperativeHandle, useEffect } from 'react';
import Form, { GroupItem, SimpleItem, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { CheckBox } from 'devextreme-react/check-box';
import { system } from '../../api';
import ScrollView from 'devextreme-react/scroll-view';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';

const Notice = React.forwardRef((props, ref) => {
  const { setPopupVisible } = props;
  const [check, setCheck] = useState(false);
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    (async () => {
      const result = await system.readFiles({ FileCategoryCode: 'FC_001' });
      if (result.isOk) {
        const blob = new Blob([result.data], { type: 'text/html' });
        const blobUrl = URL.createObjectURL(blob);
        setBlobUrl(blobUrl);
      }
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = () => {
    setPopupVisible(true);
    setCheck(false);
  };

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (check) {
        let expire = new Date();
        expire = expire.setHours(expire.getHours() + 24);
        localStorage.setItem('lastVisit', expire);
      }
      onClose();
    },
    [check],
  );

  const onClose = () => {
    setPopupVisible(false);
  };

  const onValueChanged = e => {
    setCheck(e.value);
  };

  const onIframeLoad = () => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const paragraphs = iframeDocument.getElementsByTagName('p');
      for (let i = 0; i < paragraphs.length; i++) {
        paragraphs[i].style.margin = '2px';
      }
      const unorderedLists = iframeDocument.getElementsByTagName('ul');
      for (let i = 0; i < unorderedLists.length; i++) {
        unorderedLists[i].style.margin = '0';
      }
      const orderedLists = iframeDocument.getElementsByTagName('ol');
      for (let i = 0; i < orderedLists.length; i++) {
        orderedLists[i].style.margin = '0';
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form labelLocation="left" labelMode="outside" optionalMark="optional" stylingMode="outlined">
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={'공지사항'}>
            <ScrollView height="100%" width="100%" direction="both">
              <iframe
                title="공지사항"
                src={blobUrl}
                style={{ width: '100%', height: '390px', border: 'none' }}
                onLoad={onIframeLoad}
              ></iframe>
            </ScrollView>
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem>
              <CheckBox defaultValue={false} text="오늘 하루 보지 않음" onValueChanged={onValueChanged} />
            </SimpleItem>
            <ButtonItem>
              <ButtonOptions width={'30%'} type={'default'} useSubmitBehavior={true} text={'닫기'}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default Notice;
