import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const SalesTotalSummary = props => {
  const { dataSource, dataSource2 } = props;
  const gridRef = useRef({});
  const gridPaymentRef = useRef({});

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  useEffect(() => {}, []);
  return (
    <div className="content-block left-right-header">
      <div className={'content'}>
        <div >
          <div style={{ marginRight: '5px',  padding: '5px 5px', borderRadius: '0px' }}>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              allowSelectAll={true}
              height={500}
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: 'SAP연동 매출 금액.xlsx',
              }}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" /> <HeaderFilter visible={true} />
              <Column dataField={'유형'} caption={'매출 기준'} />
              <Column dataField={'금액'} caption={'금액'} format="#,##0 원" alignment="right" />
              <Summary>
                <TotalItem column="금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>
      </div>
      <div className={'content'}>
        <div >
          <div style={{ marginRight: '5px',  padding: '5px 5px', borderRadius: '0px' }}>
            <CommonDataGrid
              gridRef={gridPaymentRef}
              className={'dx-card wide-card'}
              dataSource={dataSource2}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              allowSelectAll={true}
              height={500}
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: 'SAP연동 입금 금액.xlsx',
              }}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" /> <HeaderFilter visible={true} />
              <Column dataField={'유형'} caption={'입금 기준'} />
              <Column dataField={'금액'} caption={'금액'} format="#,##0 원" alignment="right" />
              <Summary>
                <TotalItem column="금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SalesTotalSummary);
