import React, { useState, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';

import { Column, Pager, Paging, MasterDetail, Selection } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';

import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';

import CustomStore from 'devextreme/data/custom_store';
import { Autocomplete } from 'devextreme-react/autocomplete';
import ScrollView from 'devextreme-react/scroll-view';
import { COMPANYCODE } from 'constants/codes';
import { parking } from 'api';
import ParkinSalesAggregationForm from './components/parking-sales-aggregation-form';
import TerminalGrid from './components/terminal-grid';
import { confirm } from 'devextreme/ui/dialog';
import CommonDataGrid from 'common/default-data-grid';
import { autoDataSource } from 'pages/sales-mng/api/costCenterData';
import './parking-sales-aggregation.scss';

const userId = localStorage.getItem('userId');

const ParkingSalesAgrregation = () => {
  const datdGridRef = useRef({});
  const autoCompleteRef = useRef({});
  const [popupButton, setPopupButton] = useState({
    save: false,
    close: false,
  });
  const [searchParams, setSearchParams] = useState({
    CompanyCode: COMPANYCODE,
    CostCenter: '',
    SearchText: '',
  });
  const [isAdd, setAdd] = useState(false);
  const [rowData, setRowData] = useState({});
  const [autoText, setAutoText] = useState('');

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        try {
          const { CompanyCode, SearchText } = searchParams;
          const result = await axios.post(`master/parkinginfo/searchSalesAggregationByCostCenter`, {
            CompanyCode,
            SearchText,
            LoginID: userId,
          });
          datdGridRef.current.instance.clearSelection();
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchParams]);

  const togglePopup = (str, e) => {
    if (str === 'view') {
      // 보기 버튼 클릭시
      setAdd(false);
      setRowData(e.row.data);
      setPopupButton({ save: false, close: !popupButton.close });
    } else {
      // 등록 버튼 클릭시
      if (!rowData.CostCenterCode) {
        notify(
          {
            message: '사업소명을 검색후 등록해주세요',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      } else {
        setAdd(true);
        setPopupButton({ save: false, close: !popupButton.close });
      }
    }
  };

  const cellRender = useCallback(e => {
    return (
      <Button
        icon="search"
        width={120}
        text="보기"
        type="normal"
        stylingMode="contained"
        onClick={() => togglePopup('view', e.data)}
      />
    );
  }, []);

  const onEnterKey = e => {
    if (autoText === null) {
      setSearchParams({
        ...searchParams,
        CostCenter: '',
        SearchText: '',
      });
    }
  };

  const onSearch = e => {
    setSearchParams({
      ...searchParams,
      CostCenter: autoText === null ? '' : searchParams.CompanyCode,
      SearchText: autoText === null ? '' : autoText.trim(),
    });
  };

  const handleLastNameChange = e => {
    setAutoText(e.value);
  };
  const onItemClick = e => {
    const { CostCenterCode, CostCenterName } = e.itemData;
    const name = CostCenterName.split(']');
    setSearchParams({
      CompanyCode: COMPANYCODE,
      CostCenter: CostCenterCode,
      SearchText: name[1].trim(),
    });
    setRowData(e.itemData);
  };

  const onDelete = () => {
    const result = confirm('<i>삭제하시겠습니까?</i>', '삭제');
    result.then(dialogResult => {
      if (dialogResult) {
        const rowData = datdGridRef.current.instance.getSelectedRowsData();
        const del = rowData.map(d => {
          return {
            CompanyCode: d.CompanyCode,
            CostCenter: d.CostCenterCode,
            CardAgencyType: d.CardAgencyType,
            CardagencyList: d.CardAgencyList,
          };
        });

        parking.deleteParkingSalesInfo(del[0]).then(res => {
          if (res.isOk) {
            setSearchParams({
              ...searchParams,
              SearchText: '',
            });
          }
        });
      }
    });
  };

  const refresh = () => {
    datdGridRef.current.instance.refresh();
    datdGridRef.current.instance.clearSelection();
  };

  const onClose = () => [setPopupButton({ ...popupButton, close: false })];

  return (
    <>
      <div>
        <h2 className={'content-block'}>사업소 매출집계 정보 관리</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <CommonDataGrid
              gridRef={datdGridRef}
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={true}
              focusedRowEnabled={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              hoverStateEnabled={true}
              height={700}
              isHeader={true}
              isExcel={false}
              isInfiniteScroll={false}
              headerBefore={[
                <Autocomplete
                  ref={autoCompleteRef}
                  labelMode="static"
                  stylingMode="outlined"
                  dataSource={autoDataSource}
                  value={autoText}
                  valueExpr="CostCenterName"
                  searchExpr="CostCenterName"
                  onValueChanged={handleLastNameChange}
                  onEnterKey={onEnterKey}
                  showClearButton={true}
                  width={250}
                  placeholder="사업소명을 입력하세요"
                  onItemClick={onItemClick}
                  mode="search"
                />,
              ]}
              headerAfter={[
                <Button
                  icon="search"
                  text="조회"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={onSearch}
                />,
                <Button
                  id="link1"
                  icon="plus"
                  text="등록"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={() => togglePopup('add')}
                />,
                <Button
                  icon="trash"
                  text="삭제"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={onDelete}
                />,
              ]}
            >
              <Paging defaultPageSize={15} />
              <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
              <Selection mode="single" />
              <Column dataField={'CostCenterName'} caption={'사업소명'} />
              <Column dataField={'CardAgencyTypeName'} caption={'결제대행사유형'} />
              <Column dataField={'CardAgencyListName'} caption={'결제대행사'} />
              <Column dataField={'AgencyWebAddress'} caption={'결제사웹주소'} />
              <Column dataField={'AgencyWebID'} caption={'결제사웹ID'} />
              <Column dataField={'AgencyWebPWD'} caption={'결제사웹PWD'} />
              <Column dataField={'BankTypeName'} caption={'실물계좌은행'} />
              <Column dataField={'BankAccount'} caption={'실물계좌번호'} />
              <Column dataField={'info'} caption={'매출집계정보'} cellComponent={cellRender} allowExporting={false} />
              <MasterDetail enabled={true} component={TerminalGrid} />
            </CommonDataGrid>
          </div>
        </div>
        <Popup onHiding={onClose} visible={popupButton.close} showTitle={false} width="40%" height={580}>
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
              <ParkinSalesAggregationForm
                setPopupButton={setPopupButton}
                closePopup={popupButton.close}
                isAdd={isAdd}
                rowData={rowData}
                refresh={refresh}
              />
            </div>
          </ScrollView>
        </Popup>
      </div>
    </>
  );
};

export default ParkingSalesAgrregation;
