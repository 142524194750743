import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import axios from 'axios';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import { common } from 'api';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [company, setCompany] = useState({});
  const [code, setCode] = useState([]);
  const [menu, setMenu] = useState([]);
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const calculateNextMidnight = () => {
      const now = new Date();
      const nextMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const timeUntilMidnight = nextMidnight - now;
      return timeUntilMidnight;
    };
    const timer = setInterval(() => {
      signOut();
    }, calculateNextMidnight());
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      const roleList = await common.commonData();
      if (result.isOk) {
        const { userInfo, companyInfo, codeInfo, menuInfo } = result.data;

        setUser(userInfo);
        setCompany(companyInfo);
        setCode(codeInfo);
        setMenu(menuInfo);
      }

      if (roleList.isOk) {
        const { role } = roleList.data;
        const roleInfo = role?.map(r => r.key);
        setRole(roleInfo);
      }

      setLoading(false);
    })();
  }, []);

  const fetchUser = useCallback(async () => {
    const result = await getUser();
    if (result.isOk) {
      const { userInfo } = result.data;
      setUser(userInfo);
    }
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      const { userInfo, companyInfo, codeInfo, menuInfo } = result.data;

      setUser(userInfo);
      setCompany(companyInfo);
      setCode(codeInfo);
      setMenu(menuInfo);
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser();
    setCompany({});
    setCode([]);
    setMenu([]);
    setRole([]);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    axios.defaults.headers.common.Authorization = `Bearer `;
  }, []);

  return (
    <AuthContext.Provider value={{ user, company, code, menu, role, signIn, signOut, loading, fetchUser }} {...props} />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
