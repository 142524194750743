import React, { useRef, useEffect, useState, useCallback } from 'react';
import Form, { SimpleItem, GroupItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import { parking } from 'api';
import './parking-sales-aggregation-form.scss';

const ParkinSalesAggregationForm = ({
  setPopupButton,
  closePopup,
  isAdd,
  rowData,
  refresh,
  cardAgencyType,
  bankType,
}) => {
  const formRef = useRef({});
  const [parkingData, setParkingData] = useState({
    VendorCode: '',
    SWVersion: '',
    OS: '',
    IPType: '',
    IP: '',
    WebServiceAddress: '',
    WebServiceID: '',
    WebServicePWD: '',
  });
  const [cardAgencyList, setCardAgencyList] = useState([]);

  useEffect(() => {
    if (!isAdd && rowData && rowData.CostCenterCode) {
      (async () => {
        await parking
          .getParkingSalsesAggregationInfo({
            CostCenter: rowData.CostCenterCode,
            CardAgencyType: rowData.CardAgencyType,
            CardAgencyList: rowData.CardAgencyList,
          })
          .then(res => {
            if (res.isOk && res.data.length > 0) {
              if (res.data[0]) {
                setParkingData(res.data[0]);
                handleCardAgencyType(res.data[0].CardAgencyType);
              }
            }
          });
      })();
    } else {
      setParkingData({
        VendorCode: '',
        SWVersion: '',
        OS: '',
        IPType: '',
        IP: '',
        WebServiceAddress: '',
        WebServiceID: '',
        WebServicePWD: '',
      });
    }
  }, [closePopup]);

  const handleCardAgencyType = CardAgencyType => {
    parking
      .getParkingSalesCardAgencyType({
        CodeGroup: CardAgencyType,
      })
      .then(res => {
        const cardAgencyList = [];
        if (res.isOk) {
          res.data.forEach(obj => {
            cardAgencyList.push({
              id: obj.Code + ' : ' + obj.CodeDesc,
              value: obj.Code,
            });
          });
        }
        setCardAgencyList(cardAgencyList);
      });
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { CardAgencyType, CardAgencyList, AgencyWebAddress, AgencyWebID, AgencyWebPWD, BankType, BankAccount } =
        formRef.current.props.formData;
      const result = await parking.insertSalesAggregation({
        CostCenter: rowData.CostCenterCode,
        CardAgencyType,
        CardAgencyList,
        AgencyWebAddress,
        AgencyWebID,
        AgencyWebPWD,
        BankType,
        BankAccount,
        UserID: '',
      });
      if (result.isOk) {
        setPopupButton({ save: true, close: false });
        refresh();
      }
    },
    [rowData],
  );

  const onClose = e => {
    setPopupButton({ save: false, close: false });
    formRef.current.instance.resetValues();
  };

  const onClickButton = e => {
    const { value } = e.itemData;
    handleCardAgencyType(value);
  };

  const renderLabelItem = e => {
    const { CostCenterName } = rowData;
    return <div style={{ margin: '5px' }}>{CostCenterName}</div>;
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <Form
            id="form"
            ref={formRef}
            formData={parkingData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
            readOnly={!isAdd}
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption="결제대행사 정보" colCount={2}>
              <SimpleItem dataField="CostCenterCode" component={renderLabelItem} colSpan={2}>
                <Label text="사업소명" />
              </SimpleItem>
              <SimpleItem
                dataField="CardAgencyType"
                editorType="dxSelectBox"
                editorOptions={{
                  items: cardAgencyType,
                  valueExpr: 'value',
                  displayExpr: 'id',
                  onItemClick: onClickButton,
                }}
              >
                <Label text="결제대행사 유형" />
              </SimpleItem>
              <SimpleItem
                dataField="CardAgencyList"
                editorType="dxSelectBox"
                editorOptions={{
                  items: cardAgencyList,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="결제대행사" />
              </SimpleItem>
              <SimpleItem dataField="AgencyWebAddress">
                <Label text="결제사 웹 주소" />
              </SimpleItem>
              <SimpleItem dataField="AgencyWebID">
                <Label text="결제사 웹 ID" />
              </SimpleItem>
              <SimpleItem dataField="AgencyWebPWD">
                <Label text="결제사 웹 비밀번호" />
              </SimpleItem>
            </GroupItem>
            <GroupItem caption="실물계좌" colCount={2}>
              <SimpleItem
                dataField="BankType"
                editorType="dxSelectBox"
                editorOptions={{
                  items: bankType,
                  valueExpr: 'value',
                  displayExpr: 'id',
                }}
              >
                <Label text="은행" />
              </SimpleItem>
              <SimpleItem dataField="BankAccount">
                <Label text="계좌번호" />
              </SimpleItem>
            </GroupItem>
            <GroupItem />
            <GroupItem colCount={2}></GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions
                  width={'60%'}
                  type={'default'}
                  useSubmitBehavior={true}
                  disabled={!isAdd}
                  text="저장"
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'60%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
};

export default React.memo(ParkinSalesAggregationForm);
