import { parking } from 'api';
import notify from 'devextreme/ui/notify';

export const configTypeInTaxInvoicePopup = async (setTaxIssueType, setTaxInvoiceType, setTaxType) => {
  const taxIssueType = [];
  const taxType = [];
  const taxInvoiceType = [];
  const result = await parking.getParkingInfoSelectType({
    CodeGroup: ['TaxIssueType', 'TaxType', 'TaxInvoiceType'],
  });
  if (result.isOk && result.data.length > 0) {
    result.data.forEach(obj => {
      if (obj.CodeGroup === 'TaxIssueType') {
        taxIssueType.push({
          label: obj.CodeDesc,
          value: obj.Code,
          type: 'TaxIssueType',
        });
      } else if (obj.CodeGroup === 'TaxType') {
        taxType.push({
          label: obj.CodeDesc,
          value: obj.Code,
          type: 'TaxType',
        });
      } else if (obj.CodeGroup === 'TaxInvoiceType') {
        taxInvoiceType.push({
          label: obj.CodeDesc,
          value: obj.Code,
          type: 'TaxInvoiceType',
        });
      }
    });
    setTaxIssueType(taxIssueType);
    setTaxInvoiceType(taxInvoiceType);
    setTaxType(taxType);
  }
};

export const configBySelectedCustomerInfo = (selectedCustomInfo, setAddParams, addParams, SalesDate) => {
  if (selectedCustomInfo.length > 0) {
    setAddParams({
      ...addParams,
      CustomerCorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
      CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
      NameOfRepresentative: selectedCustomInfo[0].NameOfRepresentative,
      CustomerManagerGroup: selectedCustomInfo[0].CustomerManagerGroup,
      CustomerManagerName: selectedCustomInfo[0].CustomerManagerName,
      CustomerEmail1: selectedCustomInfo[0].Email,
      CustomerTelephoneNo: selectedCustomInfo[0].CustomerTelephoneNo,
      CustomerCode: selectedCustomInfo[0].CustomerNo,
      SalesDate,
    });
  }
};

export const makeTaxInvoiceStatusCellRender = e => {
  const { TaxInvoiceStatusName, SapStatus } = e.data;
  // console.log(e.data);
  let color;

  if (SapStatus === 'BC') {
    color = 'green';
  } else if (SapStatus === 'W') {
    color = 'gray';
  } else if (SapStatus === 'NC') {
    color = 'red';
  } else if (SapStatus === 'Y') {
    color = 'blue';
  } else if (SapStatus === 'AC') {
    color = 'blue';
  } else if (SapStatus === 'N') {
    color = 'red';
  } else if (SapStatus === 'C') {
    color = 'purple';
  }
  return (
    <div style={{ width: 'auto', color: 'black' }} className={`Badge badge-status-${color}`}>
      {TaxInvoiceStatusName}
    </div>
  );
};

export const makeCashbillStatusCellRender = e => {
  const { CashBillStatusName } = e.data;
  let color;
  if (CashBillStatusName === '발행대기') {
    color = 'gray';
  } else if (CashBillStatusName === '발행실패') {
    color = 'red';
  } else if (CashBillStatusName === '신고실패') {
    color = 'red';
  } else if (CashBillStatusName === '신고완료') {
    color = 'blue';
  } else if (CashBillStatusName === '요청취소') {
    color = 'purple';
  }
  return (
    <div style={{ width: 'auto' }} className={`Badge badge-status-${color}`}>
      {CashBillStatusName}
    </div>
  );
};

export const makeCashbillTradeUsageTypeCellRender = e => {
  const { TradeUsage } = e.data;
  let color;
  let text;
  if (TradeUsage === 'A') {
    color = 'lightgreen';
    text = '소득';
  } else if (TradeUsage === 'B') {
    color = 'lightblue';
    text = '지출';
  }
  return (
    <div style={{ width: 'auto', fontSize: '11px', color: '#7b7b7b' }} className={`Badge badge-status-${color}`}>
      {text}
    </div>
  );
};

export const makeCashbillMethodTypeCellRender = e => {
  const { MethodTypeName } = e.data;
  let color;
  let text;
  if (MethodTypeName === '자동') {
    color = 'blue';
    text = MethodTypeName;
  }
  return (
    <div style={{ width: 'auto', fontSize: '11px' }} className={`badge-border-${color}`}>
      {text}
    </div>
  );
};

export const notifyDelayS = (message, messageType = 'error') => {
  notify(
    {
      message: message,
      width: 230,
      position: {
        at: 'top',
        my: 'top',
        of: '#container',
      },
    },
    messageType,
    100000,
  );
};

export const notifyS = (message, messageType = 'error') => {
  notify(
    {
      message: message,
      width: 230,
      position: {
        at: 'top',
        my: 'top',
        of: '#container',
      },
    },
    messageType,
  );
};
