import axios from 'axios';

const userId = localStorage.getItem('userId');

export async function getCompanyInfo(params) {
  try {
    const result = await axios.post(`system/company/select`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 유저 관리 > 유저 등록
export async function addUser(params) {
  try {
    const result = await axios.post(`system/user/insert`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 유저 관리 > 유저 수정
export async function updateUser(params) {
  try {
    const result = await axios.post(`system/user/update`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 유저 관리 > 유저 리스트
export async function userList(params) {
  try {
    const result = await axios.post(`system/user/list`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 메뉴 관리 > 리스트
export async function getMenuList(params) {
  try {
    const result = await axios.post(`system/menu/company/list`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 메뉴 관리 > 상위그룹
export async function getMenuGroupSelect(params) {
  try {
    const result = await axios.post(`system/menu/company/select`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 메뉴 관리 > 메뉴등록
export async function addMenu(params) {
  try {
    const result = await axios.post(`system/menu/company/insert`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 메뉴 관리 > 메뉴수정
export async function updateMenu(params) {
  try {
    const result = await axios.post(`system/menu/company/update`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사용자별 사업소 관리 리스트
export async function searchCostCenterManager(params) {
  try {
    const result = await axios.post(`master/codemaster/searchCostCenterManager`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사용자별 사업소 관리 > 저장
export async function insertCostCenterManager(params) {
  try {
    const result = await axios.post(`master/codemaster/insertCostCenterManager`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
// 사용자별 사업소 관리 > 저장
export async function insertCostCenterManagerList(params) {
  try {
    const result = await axios.post(`master/codemaster/insertCostCenterManagerList`, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 사용자별 사업소 관리 > 삭제
export async function deleteCostCenterManager(params) {
  try {
    const result = await axios.post(`master/codemaster/deleteCostCenterManager`, {
      ...params,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 파일 가져오기
export async function readFiles(param) {
  try {
    const result = await axios.post(`/file/read`, { ...param }, { responseType: 'blob' });
    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

// 파일 카테고리 코드 업데이트
export async function updateFileCategoryCode(params) {
  try {
    const result = await axios.post(`/file/updateCategory`, { ...params });
    return {
      isOk: true,
      data: result.result,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
