import React, { useState, useRef, useImperativeHandle, useCallback } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  CustomRule,
  RequiredRule,
} from 'devextreme-react/form';
import 'common/popup/popup.scss';

const PreviewPopup = React.forwardRef((props, ref) => {
  const formRef = useRef({});
  const [isPopup, setIsPopup] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [formData, setFormData] = useState({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
    close,
  }));

  const open = () => {
    setFormData({});
    setIsPopup(true);
    setIsRefresh(!isRefresh);
  };

  const close = () => {
    setIsPopup(false);
  };

  const replaceSemicolonsWithCommas = str => {
    if (str.includes(';')) return str.replace(/;/g, ',');
    else return str;
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { RecipientEmail, CcEmail, BccEmail } = formData;
      const tmpRecipientEmail = RecipientEmail && replaceSemicolonsWithCommas(RecipientEmail);
      const tmpCcEmail = CcEmail && replaceSemicolonsWithCommas(CcEmail);
      const tmpBccEmail = BccEmail && replaceSemicolonsWithCommas(BccEmail);

      await props.onExporting('email', {
        RecipientEmail: tmpRecipientEmail,
        CcEmail: tmpCcEmail,
        BccEmail: tmpBccEmail,
      });
      close();
    },
    [props.costData, formData],
  );

  const isValidEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validateEmailList = event => {
    const emailArray = event.value.split(';').map(email => email.trim());
    const validEmails = emailArray.filter(Boolean);
    const allEmailsValid = validEmails.every(isValidEmail);
    return allEmailsValid;
  };

  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="25%" height={340}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <div>
          <div className="popup-header">수배정산서 메일 발송</div>
          <i className="dx-icon-info" style={{ fontSize: '12px', marginLeft: '10px' }} id="title-help">
            &nbsp;메일 주소를 복수 입력하려면 <b>세미콜론</b>으로 구분하세요.
          </i>
        </div>
        <div className="popup-content">
          <form onSubmit={onSubmit} className={`tax-detail-form`}>
            <Form
              ref={formRef}
              formData={formData}
              labelMode="outside"
              optionalMark="optional"
              stylingMode="outlined"
              labelLocation="left"
            >
              <SimpleItem
                dataField="RecipientEmail"
                editorType="dxTextBox"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                }}
              >
                <Label text="수신자" />
                <RequiredRule message="필수 값입니다." />
                <CustomRule message={'메일 주소가 올바르지 않습니다.'} validationCallback={validateEmailList} />
              </SimpleItem>
              <SimpleItem
                dataField="CcEmail"
                editorType="dxTextBox"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                }}
              >
                <Label text="참조" />
                <CustomRule message={'메일 주소가 올바르지 않습니다.'} validationCallback={validateEmailList} />
              </SimpleItem>
              <SimpleItem
                dataField="BccEmail"
                editorType="dxTextBox"
                editorOptions={{
                  placeholder: '메일 주소를 입력하세요',
                }}
              >
                <Label text="숨은참조" />
                <CustomRule message={'메일 주소가 올바르지 않습니다.'} validationCallback={validateEmailList} />
              </SimpleItem>

              <GroupItem colCount={2}>
                <ButtonItem>
                  <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
                </ButtonItem>
                <ButtonItem horizontalAlignment="left">
                  <ButtonOptions width={'60%'} type={'normal'} text="닫기" onClick={close}></ButtonOptions>
                </ButtonItem>
              </GroupItem>
            </Form>
          </form>
        </div>
      </div>
    </Popup>
  );
});

export default PreviewPopup;
