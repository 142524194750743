import React, { useRef, useState, useMemo } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Column, Summary, TotalItem, Selection } from 'devextreme-react/data-grid';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { autoDataSource } from '../api/costCenterData';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import CommonDataGrid from 'common/default-data-grid';
import CheckSalesTaxinvoiceForm from './popup/CheckSalesTaxinvoiceForm';
import CMS from '../check-sales-payment/component/paymentTab/cms';
import Van from './Van';

const userId = localStorage.getItem('userId');

const CheckSalesTaxinvoice = () => {
  // ref
  const gridRef = useRef({});
  const cmsRef = useRef({});
  const vanRef = useRef({});
  const popupRef = useRef({});

  const [cRowdata, setCRowdata] = useState([]);
  const [vRowdata, setVRowdata] = useState([]);
  const [selectSum, setSelectSum] = useState(0);

  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(1, 'months').format('YYYY-MM-01'),
    toDate: moment().format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCompany: '',
    CostCenterCode: '',
  });
  const [searchCompany, setSearchCompany] = useState('');
  const [autoText, setAutoText] = useState('');

  //팝업
  const [isOpen, setIsOpen] = useState(false);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'InvoiceID',
      loadMode: 'raw',
      load: async () => {
        try {
          const { FromDate, ToDate, SearchCompany, CostCenterCode } = searchData;
          const params = CostCenterCode
            ? { FromDate, ToDate, SearchCompany, CostCenterCode, IsforTaxAccounting: 'Y' }
            : {};
          const result = await axios.post(`aggregation/getTaxInvoiceList`, { ...params, LoginID: userId });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setSearchCompany({
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchData({
      ...searchData,
      CostCenterCode: autoText === null ? '' : searchData.CostCenterCode,
      SearchCompany: searchCompany,
    });
  };

  const refresh = () => {
    gridRef.current.instance.refresh();

    if (cmsRef.current.instance) {
      cmsRef.current.instance.refresh();
    }

    if (vanRef.current.instance) {
      vanRef.current.instance.refresh();
    }
  };

  /* 자동 완성 */
  const onValueChanged = e => {
    setAutoText(e.value);
  };

  const onItemClick = e => {
    const { CostCenterCode } = e.itemData;
    setSearchData({
      ...searchData,
      CostCenterCode,
    });
  };

  //결제내역대사
  const checkSalesTax = () => {
    const taxData = gridRef.current.instance.getSelectedRowsData();
    const t = taxData.length;
    const c = cRowdata.length;
    const p = vRowdata.length;

    if (t === 0 && c === 0) {
      alert('세금계산서와 결제내역을 각각 선택해주세요');
    } else if (t === 0) {
      alert('세금계산서를 선택해주세요');
    } else if (c === 0 && p === 0) {
      alert('결제내역을 선택해주세요');
    } else {
      //팝업오픈
      setIsOpen(true);
      popupRef.current.open(taxData, vRowdata, cRowdata);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelectionChanged = () => {
    const rowdata = gridRef.current.instance.getSelectedRowsData();
    const sum = rowdata.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const customizeSelectTotal = e => {
    return '선택 합계 : ' + selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const onTabClick = () => {
    const p = vRowdata.length;
    const c = cRowdata.length;

    if (p > 0) vanRef.current.instance.clearSelection();
    if (c > 0) cmsRef.current.instance.clearSelection();
  };

  return (
    <div>
      <h2 className={'content-block detail-title'}>세금계산서 대사</h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />
            </ToolbarItem>
            <ToolbarItem location={'before'} text="~" />
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <Autocomplete
                label="CostCenterCode"
                labelMode="hidden"
                stylingMode="outlined"
                dataSource={autoDataSource}
                value={autoText}
                valueExpr="CostCenterName"
                searchExpr="CostCenterName"
                onValueChanged={onValueChanged}
                onEnterKey={onEnterKey}
                showClearButton={true}
                width={250}
                placeholder="사업소명을 입력하세요"
                onItemClick={onItemClick}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <TextBox
                label="SearchCompany"
                labelMode="hidden"
                stylingMode="outlined"
                placeholder="회사"
                onValueChanged={onChangeSearchText}
                onEnterKey={onEnterKey}
                width={120}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button text="세금계산서/결제내역대사" onClick={checkSalesTax} />
            </ToolbarItem>
          </Toolbar>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '5px', maxWidth: '50%' }}>
              <TabPanel>
                <Item title="세금계산서">
                  <CommonDataGrid
                    gridRef={gridRef}
                    className={'dx-card wide-card'}
                    dataSource={dataSource}
                    showBorders={true}
                    allowColumnResizing={true}
                    columnHidingEnabled={false}
                    hoverStateEnabled={true}
                    onSelectionChanged={onSelectionChanged}
                    height={775}
                    isHeader={true}
                    isExcel={true}
                    excelOptions={{
                      sheetName: 'sheet',
                      fileName: '세금계산서 발행 목록.xlsx',
                    }}
                    headerBefore={[
                      <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                        <i className="dx-icon-info dx-icon-customicon"></i>
                        <p>사업소명을 먼저 검색해주세요</p>
                      </div>,
                    ]}
                  >
                    <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
                    {/* <Column dataField={'CostCenterName'} caption={'사업소명'} width={'150'} /> */}
                    <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
                    <Column dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
                    <Column dataField={'StatusName'} caption={'매출상태'} width={80} />
                    <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
                    <Column dataField={'SalesPrice'} caption={'금액'} format="#,##0 원" width={'160'} />
                    <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
                    <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} />
                    <Column dataField={'CustomerName'} caption={'회사'} width={80} />

                    <Summary>
                      <TotalItem
                        customizeText={customizeSelectTotal}
                        column="CostCenterName"
                        showInColumn="CostCenterName"
                      />
                      <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem
                        column="SalesPrice"
                        summaryType="sum"
                        valueFormat="#,##0 원"
                        displayFormat="합계: {0}"
                      />
                    </Summary>
                  </CommonDataGrid>
                </Item>
              </TabPanel>
            </div>
            <div style={{ maxWidth: '50%' }}>
              <TabPanel onTitleClick={onTabClick}>
                <Item title="VAN" badge={vRowdata.length}>
                  <Van vanRef={vanRef} setvRowdata={setVRowdata} searchData={searchData} />
                </Item>
                <Item title="CMS" badge={cRowdata.length}>
                  <CMS cmsRef={cmsRef} setCRowdata={setCRowdata} searchData={searchData} height={775} isCheckSales />
                </Item>
              </TabPanel>
            </div>
          </div>
          <Popup onHiding={onClose} visible={isOpen} showTitle={false} width="22%" height={560}>
            <ScrollView>
              <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
                <CheckSalesTaxinvoiceForm
                  ref={popupRef}
                  setIsOpen={setIsOpen}
                  CostCenterCode={searchData.CostCenterCode}
                  refresh={refresh}
                />
              </div>
            </ScrollView>
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CheckSalesTaxinvoice);
