import React, { useMemo, useState, useRef } from 'react';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';

import CommonDataGrid from 'common/default-data-grid';

const ResultSummary = props => {
  const { gridRef, dataSource } = props;
  console.log(dataSource);
  return (
    <React.Fragment>
      <div style={{ padding: '17px 0' }}>
        <div className="dx-card">
          <CommonDataGrid
            gridRef={gridRef}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            hoverStateEnabled={true}
            isNotFilterRow
            height={250}
            isHeader={true}
            isExcel={false}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'center'} />
            <Column dataField={'SalesDate'} caption={'대사일'} alignment={'center'} />
            <Column dataField={'SalesStatusName'} caption={'대사상태'} alignment={'center'} />
            <Column dataField={'Remark'} caption={'비고'} alignment={'center'} />
            <Column dataField={'SalesTypeName'} caption={'매출유형'} alignment={'center'} />
            <Column dataField={'DetailSalesTypeName'} caption={'상세매출유형'} alignment={'center'} />
            <Column dataField={'SalesCodeName'} caption={'결제유형'} alignment={'center'} />
            <Column dataField={'TotalSalesPrice'} caption={'총계'} format="#,##0 원" alignment={'right'} />
            <Column dataField={'RegisterUser'} caption={'대사 처리자'} alignment={'center'} />
            <Summary>
              <TotalItem column="TotalSalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResultSummary;
