import React from 'react';
import Bar from './bars/bar';
import SideBySideBar from './bars/sideBySideBar';
import StackedBar from './bars/stackedBar';
import Doughnut from './pies/doughnut';
import Pie from './pies/pie';
import Line from './lines/line';

const Charts = () => {
  return (
    <React.Fragment>
      <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '40%' }}>
          <div className="caption">기본 BAR 차트</div>
          <div style={{ padding: '20px' }}>
            <Bar />
          </div>
        </div>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '60%' }}>
          <div className="caption">비교 BAR 차트</div>
          <div style={{ padding: '20px' }}>
            <SideBySideBar />
          </div>
        </div>
      </div>
      <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '55%' }}>
          <div className="caption">도넛 차트</div>
          <div style={{ padding: '20px' }}>
            <Doughnut />
          </div>
        </div>
        <div className={'dx-card'} style={{ marginBottom: '10px' }}>
          <div className="caption">스택 BAR 차트</div>
          <div style={{ padding: '20px' }}>
            <StackedBar />
          </div>
        </div>
      </div>
      <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '100%' }}>
          <div className="caption">PIE 차트</div>
          <div style={{ padding: '20px' }}>
            <Pie />
          </div>
        </div>
      </div>
      <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
        <div className={'dx-card'} style={{ marginBottom: '10px', width: '100%' }}>
          <div className="caption">LINE 차트</div>
          <div style={{ padding: '20px' }}>
            <Line />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Charts;
