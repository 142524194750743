import React, { useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { COMPANYCODE } from 'constants/codes';
import { ticket, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';

const columns = [
  'NO',
  '회원번호',
  '회원명',
  '최초청구월',
  '청구월',
  '휴대전화',
  '상품',
  '수납상태',
  '결제상태',
  '결제방식',
  '결제수단',
  '약정일',
  '결제예정일',
  '결제일',
  '수납금액',
  '결제취소일',
  '승인번호',
  '회원구분',
  '취소종료일',
  '미수처리상태',
  '청구타입',
  '비고',
  '재결제여부',
  '미납금액',
  '취소금액',
  '환불금액',
];

const HyosungCMSPaymentUploadPage = () => {
  const dataGridRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();
      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = () => {
    if (dataSource.length === 0) {
      alert('엑셀 파일을 업로드 해주세요.');
    } else {
      const result = confirm('<i>등록하시겠습니까?</i>', '등록');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          ticket
            .uploadVanHyosungCMSExcel({
              ItemListJson: JSON.stringify(dataSource),
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '처리되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const handleImport = ExcelOrganization => {
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <h5>
            ★&nbsp; 효성 CSM 결제 이력 업로드 순서 : 효성 CMS 입금 이력 다운로드 &nbsp; ▷ &nbsp;엑셀 파일 업로드 &nbsp;
            ▷ &nbsp; 등록 (합계 행 무시. *표시된 필드만 업로드됩니다.)
          </h5>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <ExcelImport
            fieldColumn={[
              'A',
              'B',
              'C',
              'D',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K',
              'L',
              'M',
              'N',
              'O',
              'P',
              'Q',
              'R',
              'S',
              'T',
              'U',
              'V',
              'W',
              'X',
              'Y',
              'Z',
            ]}
            handleImport={e => handleImport(e.map(importMapFunc))}
            // fieldColumnType={[
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            // ]}
          />
        </div>
        <CommonDataGrid
          gridRef={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          height={700}
          isHeader={true}
          isExcel={true}
          excelOptions={{
            sheetName: 'sheet',
            fileName: '효성CMS이체이력.xlsx',
          }}
          headerAfter={[
            <Button
              icon="check"
              text="등록"
              width={80}
              type="normal"
              stylingMode="contained"
              onClick={() => saveExcel()}
            />,
            // <ExcelExport
            //   excelFields={headerText}
            //   excelData={sampleData}
            //   sheetName="Sheet"
            //   fileName="easypay-02_sample.xls"
            //   buttonName="샘플파일 다운로드"
            // />,
          ]}
          allowColumnReordering={true}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <LoadPanel enabled showPane={showPane} />
          <HeaderFilter visible={true} />

          <Column dataField={'NO'} caption={'NO'} />
          <Column dataField={'회원번호'} caption={'회원번호*'} />
          <Column dataField={'회원명'} caption={'회원명*'} alignment="right" />
          <Column dataField={'최초청구월'} caption={'최초청구월'} />
          <Column dataField={'청구월'} caption={'청구월'} />
          <Column dataField={'휴대전화'} caption={'휴대전화*'} />
          <Column dataField={'상품'} caption={'상품'} />
          <Column dataField={'수납상태'} caption={'수납상태'} />
          <Column dataField={'결제상태'} caption={'결제상태'} />
          <Column dataField={'결제방식'} caption={'결제방식'} />

          <Column dataField={'결제수단'} caption={'결제수단*'} />
          <Column dataField={'약정일'} caption={'약정일'} />
          <Column dataField={'결제예정일'} caption={'결제예정일'} />
          <Column dataField={'결제일'} caption={'결제일*'} />
          <Column dataField={'수납금액'} caption={'수납금액*'} format="#,##0" />
          <Column dataField={'결제취소일'} caption={'결제취소일*'} />
          <Column dataField={'승인번호'} caption={'승인번호*'} />
          <Column dataField={'회원구분'} caption={'회원구분*'} />
          <Column dataField={'취소종료일'} caption={'취소종료일'} />
          <Column dataField={'미수처리상태'} caption={'미수처리상태'} />
          <Column dataField={'청구타입'} caption={'청구타입'} />
          <Column dataField={'비고'} caption={'비고*'} />
          <Column dataField={'재결제여부'} caption={'재결제여부'} />
          <Column dataField={'미납금액'} caption={'미납금액'} format="#,##0" />
          <Column dataField={'취소금액'} caption={'취소금액*'} format="#,##0" />
          <Column dataField={'환불금액'} caption={'환불금액'} format="#,##0" />
          <Summary>
            <TotalItem column="결제일" summaryType="count" displayFormat="총 {0}건" />
            <TotalItem column="결제금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          </Summary>
        </CommonDataGrid>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default HyosungCMSPaymentUploadPage;
