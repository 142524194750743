export const columns = [
  '상점명(상점ID)',
  '요청금액',
  '승인일자',
  '취소일자',
  '승인금액(원)',
  '취소금액(원)',
  '통화구분',
  '발급사',
  '매입사',
  '승인번호',
  '할부개월',
  '할부이자구분',
  '구매자',
  '구매자 E-mail',
  '상품명',
  '주문번호',
  '카드구분',
  '카드번호',
  '자체대표구분',
  '거래ID',
  '거래상태',
];

export const sampleData = [
  {
    '상점명(상점ID)': '주식회사 하이파킹(M20211027104909)',
    요청금액: 20000,
    승인일자: '2023-02-01 00:05:21',
    취소일자: '',
    '승인금액(원)': 20000,
    '취소금액(원)': 0,
    통화구분: 'KRW',
    발급사: '외환은행',
    매입사: '하나카드',
    승인번호: '22207320',
    할부개월: '일시불',
    할부이자구분: '일반',
    구매자: 'H*******g',
    '구매자 E-mail': '@',
    상품명: '',
    주문번호: '000000003803',
    카드구분: '체크카드',
    카드번호: '5320-92**-****-7526',
    자체대표구분: '대표가맹점',
    거래ID: '230201256685924',
    거래상태: '매입완료',
  },
  {
    '상점명(상점ID)': '주식회사 하이파킹(M20211027104909)',
    요청금액: 20000,
    승인일자: '2023-02-01 00:06:14',
    취소일자: '',
    '승인금액(원)': 20000,
    '취소금액(원)': 0,
    통화구분: 'KRW',
    발급사: 'BC카드',
    매입사: 'BC카드',
    승인번호: '36193014',
    할부개월: '일시불',
    할부이자구분: '일반',
    구매자: 'H*******g',
    '구매자 E-mail': '@',
    상품명: '',
    주문번호: '000000006323',
    카드구분: '체크카드',
    카드번호: '5480-20**-****-4481',
    자체대표구분: '대표가맹점',
    거래ID: '230201256685961',
    거래상태: '매입완료',
  },
];
