import React, { useState, useRef, useMemo, useImperativeHandle, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';

import {
  makeCashbillStatusCellRender,
  makeCashbillTradeUsageTypeCellRender,
  makeCashbillMethodTypeCellRender,
} from 'common/popup/sales-common-util';
import { cellStatus4Cashbill } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { Column, Summary, TotalItem, Selection, Scrolling, MasterDetail, Editing } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { error, aggregation } from 'api';
import { CheckBox } from 'devextreme-react/check-box';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import CashbillDetailSales from './component/cashbill-detail-sales.js';
import CommonDataGrid from 'common/default-data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const CashbillListView = () => {
  const detailHistoryRef = useRef({});
  const searchCostCenterRef = useRef({});
  const searchIdentifierRef = useRef({});
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    IdentifierNo: '',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    SearchCompany: '',
    IdentifierNo: '',
  });
  const [ignoreCheck, setAllCheck] = useState('N');
  const [billID, setBillID] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchData({
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      IdentifierNo: text.IdentifierNo,
    });
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'BillID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getCashBillInfoList`, {
            ...searchData,
            IgnoreAutoRegister: ignoreCheck,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData, ignoreCheck, refresh, text]);

  const onCheckValueChanged = e => {
    e.value ? setAllCheck('Y') : setAllCheck('N');
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus4Cashbill(data, displayValue, cellElement);
  }, []);

  const CashbillStatusCellRender = useCallback(e => {
    return makeCashbillStatusCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onSelectionChanged = e => {
    setBillID(e.selectedRowsData[0].BillID);
  };
  const editCellRender = e => {
    return (
      <div style={{ paddingLeft: '8px' }}>
        <Button icon="search" type="normal" stylingMode="contained" onClick={() => handlePopup(e.data)} />
      </div>
    );
  };

  const editDownloadCellRender = e => {
    return <Button icon="download" type="normal" stylingMode="contained" onClick={() => handleDownload(e.data)} />;
  };

  const handleOpenNewWindow = url => {
    const width = 700;
    const height = 900;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(url, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
  };

  const handlePopup = async data => {
    const { BillID } = data;
    await aggregation
      .getCashbillDetailUrl({
        mgtKey: BillID,
      })
      .then(res => {
        if (res.isOk) {
          const url = res.data.result;
          handleOpenNewWindow(url);
          // setImageUrl(url);
        } else {
          notify(
            {
              message: res.error.detail,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
  };
  const handleDownload = async data => {
    const { BillID, ConfirmID, SearchCostCenter } = data;
    try {
      const res = await aggregation.getCashbillPdfUrl({ mgtKey: BillID });
      if (res.isOk) {
        const url = res.data.result;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ConfirmID}_${SearchCostCenter}_현금영수증`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        notify(
          {
            message: res.error.detail,
            width: 230,
            position: { at: 'top', my: 'top', of: '#container' },
          },
          'error',
        );
      }
    } catch (error) {
      notify(
        {
          message: error.message,
          width: 230,
          position: { at: 'top', my: 'top', of: '#container' },
        },
        'error',
      );
    }
  };
  // 현금영수증 상태 - 발행요청/발행완료/전송완료/전송실패
  const cashbillStatusCellRender = useCallback(e => {
    return makeCashbillStatusCellRender(e);
  }, []);

  // 거래구분 - 소득/지출
  const cashbillTradeUsageCellRender = useCallback(e => {
    return makeCashbillTradeUsageTypeCellRender(e);
  }, []);

  // 발행형태 - 자동/수동
  const cashbillMethodTypeCellRender = useCallback(e => {
    return makeCashbillMethodTypeCellRender(e);
  }, []);

  const editCancelTypeCellRender = e => {
    const shortText = e.data.TradeTypeName.replace('거래', '');
    return <div>{shortText}</div>;
  };
  const onRefreshClick = () => {
    const searchCostCenterValue = searchCostCenterRef.current.instance._changedValue;
    const searchIdentifierNoValue = searchIdentifierRef.current.instance._changedValue;
    setSearchData({ ...searchData, SearchCostCenter: searchCostCenterValue, IdentifierNo: searchIdentifierNoValue });
    setRefresh(!refresh);
  };
  const memoizedDatagrid = useMemo(() => {
    return (
      <CommonDataGrid
        gridRef={detailHistoryRef}
        className={'dx-card wide-card grid-with-status minimal-padding'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={670}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '현금영수증 발행 목록.xlsx',
          customizeCell: {
            date: ['RegisterDate', 'LastUpdateDate'],
          },
        }}
        headerBefore={[
          <div className="label">결제일 :</div>,
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="FromDate"
            onValueChanged={handleFromDate}
            value={dateValue.fromDate}
          />,
          <div className="label">~</div>,
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="ToDate"
            min={dateValue.fromDate}
            value={dateValue.toDate}
            onValueChanged={handleToDate}
            dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
          />,
          <TextBox
            ref={searchCostCenterRef}
            label="SearchCostCenter"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="P-코드/사업소코드/사업소명"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
          <TextBox
            ref={searchIdentifierRef}
            label="IdentifierNo"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="식별번호/현금영수증ID"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
          <CheckBox
            defaultValue={false}
            text="의무 신고 제외"
            hint="자동 의무신고 건 제외"
            onValueChanged={onCheckValueChanged}
          />,
        ]}
        headerAfter={[<Button icon="refresh" type="normal" stylingMode="contained" onClick={onRefreshClick} />]}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <Selection mode="single" selectAllMode="pages" />
        <Column
          dataField={'detail'}
          caption={' '}
          cellRender={editCellRender}
          width={40}
          allowFiltering={false}
          allowResizing={false}
          allowExporting={false}
          allowSorting={false}
        />
        <Column
          dataField={'download'}
          caption={' '}
          cellRender={editDownloadCellRender}
          width={40}
          allowFiltering={false}
          allowResizing={false}
          allowExporting={false}
          allowSorting={false}
        />
        <Column
          dataField={'TradeUsageName'}
          caption={'구분'}
          width={50}
          cellRender={cashbillTradeUsageCellRender}
          allowFiltering={false}
          allowResizing={false}
          allowExporting={true}
          allowSorting={false}
        />
        <Column dataField={'CostCenterCode'} caption={'P코드'} width={80} />
        {/* <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} /> */}
        {/* <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} /> */}
        <Column dataField={'ProductName'} caption={'상품명'} width={250} />
        <Column dataField={'TransactionDate'} caption={'결제일자'} width={140} customizeText={customizeText} />
        <Column
          dataField={'MethodTypeName'}
          caption={'자동/수동'}
          width={29}
          cellRender={cashbillMethodTypeCellRender}
          allowFiltering={true}
          allowResizing={false}
          allowExporting={true}
          allowSorting={false}
        />
        <Column dataField={'IdentifierNo'} caption={'식별번호'} width={110} />
        <Column dataField={'TradeTypeName'} caption={'문서형태'} width={80} cellRender={editCancelTypeCellRender} />
        <Column dataField={'SalesPrice'} caption={'금액'} width={80} format="#,##0" />
        <Column
          dataField={'CashBillStatusName'}
          caption={'처리상태'}
          width={110}
          cellRender={CashbillStatusCellRender}
          allowEditing={false}
        />
        <Column dataField={'ConfirmID'} caption={'현금영수증ID'} width={120} />
        <Column dataField={'ConfirmNum'} caption={'현금영수증번호'} width={120} visible={false} />
        <Column dataField={'TradeDate'} caption={'신고거래일'} width={100} />
        <Column dataField={'TradeDateTime'} caption={'신고거래일시'} width={160} customizeText={customizeText} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={100} />
        <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} />
        <Column dataField={'SalesDate'} caption={'DMS매출일자'} width={120} />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} allowEditing={false} format="#,##0" />
        <Column dataField={'TaxPrice'} caption={'세액'} width={80} allowEditing={false} format="#,##0" />
        <Column dataField={'Remark'} caption={'비고'} width={200} />
        <Column dataField={'EmailAddress'} caption={'Email주소'} width={200} />
        <Column dataField={'RegisterDate'} caption={'등록일'} width={120} customizeText={customizeText} />
        <Column dataField={'BillID'} caption={'영수증ID'} width={120} />
        <Column dataField={'NTResultMessage'} caption={'국세청신고메세지'} width={120} />
        <Column dataField={'PopbillResponseMessage'} caption={'팝빌결과메세지'} width={120} />
        <Column dataField={'OrgConfirmID'} caption={'(원)현금영수증ID'} width={90} />
        <Column dataField={'OrgConfirmNum'} caption={'(원)현금영수증번호'} width={120} visible={false} />
        <Column dataField={'OrgTradeDate'} caption={'(원)발행일자'} width={90} />
        <Column dataField={'StatusName'} caption={'신고상태'} width={120} />
        <Column dataField={'EmployeeName'} caption={'등록자'} width={120} alignment="center" />

        <Column dataField={'SalesKeyIDList'} caption={'SalesKeyIDList'} width={120} visible={false} />
        <Summary>
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    );
  }, [refresh, ignoreCheck, searchData, text]);

  return (
    <React.Fragment>
      {' '}
      <h2 className={'content-block detail-title'}>
        현금영수증 상세조회
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <h3 style={{ margin: '1px 5px 7px 7px' }}>현금영수증 발행목록</h3>
          {memoizedDatagrid}
          <div>
            <h3>{'현금영수증 매출 세부내역'}</h3>
          </div>
          <CashbillDetailSales billID={billID} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(CashbillListView);
