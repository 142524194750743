import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';

import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { parking, salesPayment, error } from 'api';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { LoadPanel } from 'devextreme-react/load-panel';
import { statusCode } from './component/common/common';
import { autoDataSource } from '../api/costCenterData';
import { isWithin3Months } from 'common/validators/validators';
import { AuthContext } from 'contexts/auth';
import { ROLE_AUTH_FOR_SUSPENSE_RECEIPT } from 'constants/roleAuth';

import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import CommonDataGrid from 'common/default-data-grid';

import SuspenseReceipt from './component/paymentTab/suspenseReceipt';
import GeneralTicket from './component/salesTab/generalTicket';
import SeasonTicket from './component/salesTab/seasonTicket';
import EtcSales from './component/salesTab/etcSales';
import PG from './component/paymentTab/pg';
import CMS from './component/paymentTab/cms';
import Tax from './component/salesTab/tax';

import ComplexPaymentForm from './component/complex-payment-form';
import PartialPaymentForm from './component/partial-payment-form';
import InputPaymentForm from './component/input-payment-form';
import queryString from 'query-string';
import './detail.scss';
import $ from 'jquery';
import moment from 'moment';
import axios from 'axios';

import ConfirmPopup from './component/common/confirm-popup';
const Detail = props => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const query = queryString.parse(decodeURIComponent(props.location.search));
  const [isOpenbyComplexPayment, setIsOpenbyComplexPayment] = useState(false);
  const [isOpenbyPartialPayment, setIsOpenbyPartialPayment] = useState(false);
  const [isOpenbyInputPayment, setIsOpenbyInputPayment] = useState(false);
  const [gRowdata, setGRowdata] = useState([]);
  const [sRowdata, setSRowdata] = useState([]);
  const [eRowdata, setERowdata] = useState([]);
  const [tRowdata, setTRowdata] = useState([]);
  const [pRowdata, setPRowdata] = useState([]);
  const [cRowdata, setCRowdata] = useState([]);
  const [srRowdata, setSRRowdata] = useState([]);
  const [salesType, setSalesType] = useState([]);
  const [etcType, setEtcType] = useState([]);
  const [salesTypeForManual, setSalesTypeForManual] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState({ visible: false, accountingNo: '' });
  const [status, setStatus] = useState('');
  //검색조건
  const [dateValue, setDateValue] = useState(query.date);
  const [autoText, setAutoText] = useState('');
  const [costCenter, setCostCenter] = useState({ code: query.code, name: '' });

  const [selectedID, setSelectedID] = useState('');
  const [selectedAccountingNo, setSelectedAccountingNo] = useState('');

  const forwardGTicketRef = useRef({});
  const forwardSTicketRef = useRef({});
  const forwardEtcRef = useRef({});
  const forwardTaxRef = useRef({});

  const forwardPgvanRef = useRef({});
  const forwardCmsRef = useRef({});
  const forwardSusprcptRef = useRef({});
  useEffect(() => {
    api();
  }, []);

  const inputPaymentRef = useRef({});
  const complexPaymentRef = useRef({});
  const partialPaymentRef = useRef({});

  // datagrid ref
  const generalTicketRef = useRef({});
  const seasonTicketRef = useRef({});
  const etcSalesRef = useRef({});
  const TaxRef = useRef({});
  const pgvanRef = useRef({});
  const cmsRef = useRef({});
  const susprcptRef = useRef({});
  const confirmPopupRef = useRef({});
  const [dataSourcePayment, SetDataSourcePayment] = useState('');

  // 가수금 권한 구분
  const userRoleArr = user.RoleCodeStr.split('|');
  const isAuthorized = ROLE_AUTH_FOR_SUSPENSE_RECEIPT.some(role => userRoleArr.includes(role));

  // 복합 대사
  const handleComplexPayment = async () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    const p = pRowdata.length;
    const c = cRowdata.length;
    const sr = srRowdata.length;

    const isGState = gRowdata.filter(g => g.Status === 'A');
    const isSState = sRowdata.filter(s => s.Status === 'A');
    const isEState = eRowdata.filter(s => s.Status === 'A');
    const isTState = tRowdata.filter(s => s.Status === 'A');
    const isPState = pRowdata.filter(p => p.Status === 'A');
    const isCState = cRowdata.filter(c => p.Status === 'A');

    if (sr > 0) {
      alert('가수금 건을 대사하는 경우에는 하단 가수금 탭에서 “매출 전환”을 진행해 주세요.');
    } else if (g === 0 && s === 0 && e === 0 && p === 0 && c === 0 && t === 0) {
      alert('매출/결제 내역을 선택해주세요');
    }
    // else if (g === 0 && s === 0 && e === 0 && t === 0) {
    //   alert('매출 내역을 선택해주세요');
    // }
    else if (p === 0 && c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (
      isGState.length > 0 ||
      isSState.length > 0 ||
      isEState.length > 0 ||
      isTState.length > 0 ||
      isPState.length > 0 ||
      isCState.length > 0
    ) {
      alert('매출 상태가 대사 전인 매출만 선택해주세요.');
    } else {
      // 정기권 시작/종료일이 3개월을 벗어난 경우 확인창을 띄움
      if (s > 0) {
        for (const value of sRowdata) {
          const { FromDate, ToDate } = value;
          const isConfirmed = await isWithin3Months(FromDate, ToDate, '선택된 정기권이 최근 3개월을 벗어납니다.');
          if (!isConfirmed) {
            return;
          } else {
            break;
          }
        }
      }
      setIsOpenbyComplexPayment(true);
      complexPaymentRef.current.open(gRowdata, sRowdata, eRowdata, tRowdata, pRowdata, cRowdata);
    }
  };
  // 부분 대사
  const handlePartialPayment = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    const p = pRowdata.length;
    const c = cRowdata.length;
    const sr = srRowdata.length;

    const isGState = gRowdata.filter(g => g.Status === 'A');
    const isSState = sRowdata.filter(s => s.Status === 'A');
    const isEState = eRowdata.filter(s => s.Status === 'A');
    const isTState = tRowdata.filter(s => s.Status === 'A');
    const isPState = pRowdata.filter(p => p.Status === 'A');
    const isCState = cRowdata.filter(c => p.Status === 'A');

    if (sr > 0) {
      alert('가수금 건을 대사하는 경우에는 하단 가수금 탭에서 “매출 전환”을 진행해 주세요.');
    } else if (g === 0 && s === 0 && e === 0 && p === 0 && c === 0 && t === 0) {
      alert('매출/결제 내역을 선택해주세요');
    }
    // else if (g === 0 && s === 0 && e === 0 && t === 0) {
    //   alert('매출 내역을 선택해주세요');
    // }
    else if (g > 0) {
      alert('일반 관제 매출은 반제 불가능합니다.');
    }
    // else if (g + s + e + t > 1) {
    //   alert('부분대사 시에는 매출 내역을 하나만 선택해주세요');
    // }
    else if (p === 0 && c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (
      isGState.length > 0 ||
      isSState.length > 0 ||
      isEState.length > 0 ||
      isTState.length > 0 ||
      isPState.length > 0 ||
      isCState.length > 0
    ) {
      alert('매출 상태가 대사 전, 대사 중인 매출만 선택해주세요.');
    } else {
      setIsOpenbyPartialPayment(true);
      partialPaymentRef.current.open(gRowdata, sRowdata, eRowdata, tRowdata, pRowdata, cRowdata);
    }
  };

  // 수기 대사
  const handleInputPayment = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;
    const p = pRowdata.length;
    const c = cRowdata.length;

    const isPState = pRowdata.filter(p => p.Status === 'A');
    const isCState = cRowdata.filter(c => p.Status === 'A');

    // 세금계산서 전체 데이터 중 대사완료 건들을 조회
    const tRowdataSource = TaxRef.current.instance.getDataSource()._store.__rawData;

    if (g > 0 || s > 0 || e > 0 || t > 0) {
      alert('결제 내역만 선택해주세요');
    } else if (p === 0 && c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (isPState.length > 0 || isCState.length > 0) {
      alert('매출 상태가 대사 전인 매출만 선택해주세요.');
    } else {
      setIsOpenbyInputPayment(true);
      inputPaymentRef.current.open(pRowdata, cRowdata, tRowdataSource);
    }
  };

  const styleName = status => {
    if (status === 'A') {
      return 'end-badge';
    } else if (status === 'O') {
      return 'doing-badge';
    } else {
      return 'before-badge';
    }
  };

  const api = useCallback(async () => {
    const salesType = [];
    const salesTypeForManual = [];
    const etcType = [];

    // 팝업에 들어가는 매출 select items
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['SalesType', 'SalesTypeForManual', 'EtcSalesType'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'SalesType') {
          salesType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'SalesTypeForManual') {
          salesTypeForManual.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        } else if (obj.CodeGroup === 'EtcSalesType') {
          if (obj.Attribute1 !== '수기대사제외') {
            etcType.push({
              id: obj.CodeDesc,
              value: obj.Code,
            });
          }
        }
      });
      setSalesType(salesType);
      setEtcType(etcType);
      setSalesTypeForManual(salesTypeForManual);
    }
  }, []);

  useEffect(() => {
    // 상태코드 & 기간
    salesPayment
      .getSummaryByCostCenter({ FromDate: dateValue, ToDate: dateValue, CostCenterCode: costCenter.code, UserID: '' })
      .then(res => {
        if (res.isOk) {
          const statusCode = res.data[0].CurrentStatus;
          const date = res.data[0].SalesDate;
          const costName = res.data[0].CostCenterName;
          setStatus(statusCode);
          setDateValue(date);
          setCostCenter({ ...costCenter, name: costName });
          setSelectedID('');
          setSelectedAccountingNo('');
          filterRefresh();
        }
      });
  }, [dateValue, costCenter.code]);

  useEffect(() => {
    // 가수금에서 api call시 문제되지 않도록 주석처리
    // SetDataSourcePayment(null);
    generalTicketRef?.current?.instance?.clearSelection();
    seasonTicketRef?.current?.instance?.clearSelection();
    etcSalesRef?.current?.instance?.clearSelection();
    TaxRef?.current?.instance?.clearSelection();
    susprcptRef?.current?.instance?.clearSelection();

    (async () => {
      if (selectedID !== '') {
        await axios
          .post(`aggregation/history/getPaymentHistoryByKeyID`, {
            KeyID: selectedID,
            AccountingDate: dateValue,
          })
          .then(res => {
            SetDataSourcePayment(res.data[0]);
            const accountingNoList = res.data[0].map(a => a.AccountingNo);
            if (accountingNoList.length > 0) {
              forwardPgvanRef.current.focusChange(accountingNoList);
              forwardCmsRef.current.focusChange(accountingNoList);
              forwardSusprcptRef.current.focusChange(accountingNoList);
            } else {
              forwardPgvanRef.current.focusChange([]);
              forwardCmsRef.current.focusChange([]);
              forwardSusprcptRef.current.focusChange([]);
            }
          });
      }
    })();
  }, [selectedID]);

  useEffect(() => {
    pgvanRef?.current?.instance?.clearSelection();
    cmsRef?.current?.instance?.clearSelection();
    susprcptRef.current?.instance?.clearSelection();
    if (selectedAccountingNo !== '') {
      forwardGTicketRef.current.focusChange(selectedAccountingNo);
      forwardSTicketRef.current.focusChange(selectedAccountingNo);
      forwardEtcRef.current.focusChange(selectedAccountingNo);
      forwardTaxRef.current.focusChange(selectedAccountingNo);
    }
    if (selectedAccountingNo !== '' && selectedID !== 'N/A') {
      forwardPgvanRef.current.focusChange(selectedAccountingNo, 'Payment');
      forwardCmsRef.current.focusChange(selectedAccountingNo, 'Payment');
    }
  }, [selectedAccountingNo]);
  // 자동 대사
  const handleAutoCompare = useCallback(async () => {
    const result = confirm('<i> 자동 대사를 진행하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        await salesPayment
          .processAutoAccounting({
            CostCenterCode: costCenter.code,
            InquiryDate: dateValue,
            RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            setStatus('O');
            if (res.isOk) {
              notify(
                {
                  message: '자동대사가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              // 리프레쉬
              generalTicketRef.current.instance.refresh();
              seasonTicketRef.current.instance.refresh();
              etcSalesRef.current.instance.refresh();
              TaxRef.current.instance.refresh();
              cmsRef.current.instance.refresh();
              pgvanRef.current.instance.refresh();
              susprcptRef.current.instance.refresh();
              setSelectedID('');
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  }, [query]);

  // 일 마감 확정
  const confirmWork = useCallback(async () => {
    const result = confirm('<i> 일 마감 확정을 진행하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);

        await salesPayment
          .confirmDailyAccountInfo({
            CostCenterCode: costCenter.code,
            InquiryDate: dateValue,
            RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            if (res.isOk) {
              setStatus('A');
              notify(
                {
                  message: '마감 처리가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  }, [query]);

  // 일 마감 취소
  const cancelAll = useCallback(async () => {
    const result = confirm('<i> 모든 대사이력과 마감 상태를 취소하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        await salesPayment
          .cancelDailyAccountInfo({
            CostCenterCode: costCenter.code,
            InquiryDate: dateValue,
            RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            if (res.isOk) {
              setStatus('O');
              notify(
                {
                  message: '취소 처리가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  }, [query]);

  // 일 마감 취소
  const cancelStatus = useCallback(async () => {
    const result = confirm('<i> 일마감 확정 상태를 취소하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);

        await salesPayment
          .cancelDailyAccountStatus({
            CostCenterCode: costCenter.code,
            InquiryDate: dateValue,
            RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            if (res.isOk) {
              setStatus('O');
              notify(
                {
                  message: '상태가 변경 되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  }, [query]);

  const filterRefresh = () => {
    generalTicketRef.current.instance.clearFilter();
    seasonTicketRef.current.instance.clearFilter();
    etcSalesRef.current.instance.clearFilter();
    TaxRef.current.instance.clearFilter();
    pgvanRef.current.instance.clearFilter();
    cmsRef.current.instance.clearFilter();
    susprcptRef.current.instance.clearFilter();
  };

  const refresh = useCallback(() => {
    // 리프레쉬 및 선택 해제
    if (generalTicketRef.current.instance) {
      generalTicketRef.current.instance.refresh();
      generalTicketRef.current.instance.clearSelection();
    }

    if (seasonTicketRef.current.instance) {
      seasonTicketRef.current.instance.refresh();
      seasonTicketRef.current.instance.clearSelection();
    }

    if (etcSalesRef.current.instance) {
      etcSalesRef.current.instance.refresh();
      etcSalesRef.current.instance.clearSelection();
    }
    if (TaxRef.current.instance) {
      TaxRef.current.instance.refresh();
      TaxRef.current.instance.clearSelection();
    }

    if (pgvanRef.current.instance) {
      pgvanRef.current.instance.refresh();
      pgvanRef.current.instance.clearSelection();
    }

    if (cmsRef.current.instance) {
      cmsRef.current.instance.refresh();
      cmsRef.current.instance.clearSelection();
    }

    if (susprcptRef.current.instance) {
      susprcptRef.current.instance.refresh();
      susprcptRef.current.instance.clearSelection();
    }
    setSelectedID('');
    SetDataSourcePayment('');
  }, []);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onClose = () => {
    setIsOpenbyInputPayment(false);
    setIsOpenbyComplexPayment(false);
    setIsOpenbyPartialPayment(false);
  };

  const handleToDate = e => {
    setDateValue(e.value);
  };

  const onValueChanged = e => {
    setAutoText(e.value);
  };
  const onItemClick = e => {
    const { CostCenterCode, CostCenterName } = e.itemData;
    setCostCenter({
      code: CostCenterCode,
      name: CostCenterName,
    });
  };

  const onCellPrepared = useCallback(e => {
    cellStatus(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'KeyID';
  const mergeColumn = ['HiParkingID', 'CostCenterName'];
  const cellStatus = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };

  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onCancelClick = useCallback(e => {
    const { value } = e.itemData;
    const result = confirm('<i>선택한 대사 이력을 취소하시겠습니까?</i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        // 대사 취소
        await salesPayment
          .cancelCostCenterAccountInfo({
            AccountingNo: value,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              notify(
                {
                  message: '취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
              setStatus('O');
            } else {
              const sqlStatus = error.errorStateCheck(res.error.detail);
              if (sqlStatus === '45001') {
                // 보정 데이터 선택 팝업창 열기
                setConfirmPopup({ visible: true, accountingNo: value });
              } else {
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            }
          });
      }
    });
  }, []);

  const handleConfirmSubmit = (buttonType, accountingNo) => {
    if (buttonType === 'yes' || buttonType === 'onlyPayment') {
      const params = { WithoutSales: buttonType === 'onlyPayment', AccountingNo: accountingNo };
      console.log(params);
      (async () => {
        setLoadPanelVisible(true);
        const res = await salesPayment.registerCostCenterAccountSetOff(params);
        if (res.isOk) {
          notify(
            {
              message: '보정 데이터가 생성 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setLoadPanelVisible(false);
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: error.errorMsgCheck(res.error.detail),
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      })();
    }
    setConfirmPopup({ ...confirmPopup, visible: false });
    refresh();
  };

  const onHiding = () => {
    setConfirmPopup({ ...confirmPopup, visible: false });
  };
  return (
    <div id="sp-loading">
      <h2 className={'content-block detail-title'}>
        매출 대사
        <div className="Step">
          <div className="Active">
            <i className="dx-icon-chevrondoubleright dx-icon-customicon"></i>
          </div>
          <h3>{'사업소 : ' + '[' + costCenter.code + '] ' + costCenter.name + ' '}</h3>
        </div>
        <div className="Step">
          <div className="Active">
            <i className="dx-icon-chevrondoubleright dx-icon-customicon"></i>
          </div>
          <h3>{' 기간 : ' + dateValue}</h3>
        </div>
        {status !== '' ? (
          <div className={styleName(status)}>
            <div className={`${styleName(status)}-text`}>{statusCode(status)}</div>
          </div>
        ) : (
          ''
        )}
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
        <Button
          icon="revert"
          text="전체 대사 취소"
          hint="전체 대사이력이 취소되고 일마감 상태가 대사중으로 변경됩니다."
          style={{ marginRight: '5px !important' }}
          onClick={() => cancelAll()}
          disabled={status !== 'A'}
        />
        <Button
          icon="clearsquare"
          text="일마감 확정 취소"
          hint="일마감 상태가 대사중으로 변경됩니다."
          style={{ marginRight: '5px !important' }}
          onClick={() => cancelStatus()}
          disabled={status !== 'A'}
        />
        <Button
          icon="save"
          text="일마감 확정"
          style={{ marginRight: '5px !important' }}
          onClick={() => confirmWork()}
          disabled={status === 'A'}
        />
        <Button
          icon="back"
          text="목록"
          onClick={() => {
            history.goBack();
            // history.push('/sales-mng/check-sales-payment');
          }}
        />
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div className="label">매출일/확정일 :</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="date"
                value={dateValue}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <Autocomplete
                labelMode="static"
                stylingMode="outlined"
                dataSource={autoDataSource}
                value={autoText}
                valueExpr="CostCenterName"
                searchExpr="CostCenterName"
                onValueChanged={onValueChanged}
                // onEnterKey={onEnterKey}
                showClearButton={true}
                width={250}
                placeholder="사업소명을 입력하세요"
                onItemClick={onItemClick}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="check" text="매출/결제 자동 대사" onClick={handleAutoCompare} disabled={status === 'A'} />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="plus" text="결제 수기 대사" onClick={handleInputPayment} disabled={status === 'A'} />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="plus" text="매출/결제 부분 대사" onClick={handlePartialPayment} disabled={status === 'A'} />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button icon="plus" text="매출/결제 복합 대사" onClick={handleComplexPayment} disabled={status === 'A'} />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button
                icon="refresh"
                type="normal"
                stylingMode="contained"
                onClick={() => {
                  refresh();
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '45%' }}>
              <TabPanel deferRendering={false}>
                <Item title="일반권" badge={gRowdata.length}>
                  <GeneralTicket
                    ref={forwardGTicketRef}
                    generalTicketRef={generalTicketRef}
                    setGRowdata={setGRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                  />
                </Item>
                <Item title="정기권" badge={sRowdata.length}>
                  <SeasonTicket
                    ref={forwardSTicketRef}
                    seasonTicketRef={seasonTicketRef}
                    setSRowdata={setSRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                  />
                </Item>
                <Item title="기타" badge={eRowdata.length}>
                  <EtcSales
                    ref={forwardEtcRef}
                    etcSalesRef={etcSalesRef}
                    setERowdata={setERowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                  />
                </Item>
                <Item title="세금계산서" badge={tRowdata.length}>
                  <Tax
                    ref={forwardTaxRef}
                    TaxRef={TaxRef}
                    setTRowdata={setTRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                  />
                </Item>
              </TabPanel>
            </div>
            <div style={{ maxWidth: '55%' }}>
              <TabPanel deferRendering={false}>
                <Item title="PG & VAN" badge={pRowdata.length}>
                  <PG
                    ref={forwardPgvanRef}
                    pgvanRef={pgvanRef}
                    susprcptRef={susprcptRef}
                    setPRowdata={setPRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    SetDataSourcePayment={SetDataSourcePayment}
                    refresh={refresh}
                    isAuthorized={isAuthorized}
                  />
                </Item>
                <Item title="CMS" badge={cRowdata.length}>
                  <CMS
                    ref={forwardCmsRef}
                    cmsRef={cmsRef}
                    susprcptRef={susprcptRef}
                    setCRowdata={setCRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    SetDataSourcePayment={SetDataSourcePayment}
                    refresh={refresh}
                    isAuthorized={isAuthorized}
                  />
                </Item>
                <Item title="가수금" badge={srRowdata.length}>
                  <SuspenseReceipt
                    ref={forwardSusprcptRef}
                    susprcptRef={susprcptRef}
                    TaxRef={TaxRef}
                    cmsRef={cmsRef}
                    generalTicketRef={generalTicketRef}
                    seasonTicketRef={seasonTicketRef}
                    etcSalesRef={etcSalesRef}
                    pgvanRef={pgvanRef}
                    setSRRowdata={setSRRowdata}
                    inquiryDate={dateValue}
                    costCenter={costCenter.code}
                    isSalesPayment
                    isAuthorized={isAuthorized}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    SetDataSourcePayment={SetDataSourcePayment}
                    refresh={refresh}
                  />
                </Item>{' '}
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
      <div className={'content-block'}>
        <div>
          <div className={'dx-card'} style={{ padding: '20px 40px' }}>
            <div className="label" style={{ padding: '5px 0px' }}>
              매출 수금 내역 상세 조회
            </div>
            <CommonDataGrid
              className={'dx-card wide-card accounting-payment-report'}
              dataSource={dataSourcePayment}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              // onCellPrepared={onCellPrepared}
              height={350}
              isNotFilterRow
              isHeader={false}
              isExcel={false}
              isInfiniteScroll={false}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '수금 내역 상세 조회.xlsx',
              }}
            >
              <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
              <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
              {/* <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} /> */}
              <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} customizeText={customizeText} />
              <Column dataField={'PaymentAmount'} caption={'반제금액'} alignment={'center'} format="#,##0 원" />
              <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
              <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
              <Column dataField={'AccountingType'} caption={'대사구분'} alignment={'center'} />
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
              <Column
                dataField={'RegisterDate'}
                caption={'대사등록일'}
                alignment={'center'}
                customizeText={customizeText}
              />

              <Summary>
                <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
                <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                <TotalItem column="PaymentAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>
      </div>
      <Popup onHiding={onClose} visible={isOpenbyComplexPayment} showTitle={false} width="80%" height={750} id="test">
        <ScrollView>
          <div id="complex-popup" className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <ComplexPaymentForm
              ref={complexPaymentRef}
              setIsOpenbyComplexPayment={setIsOpenbyComplexPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              gRowdata={gRowdata}
              sRowdata={sRowdata}
              eRowdata={eRowdata}
              pRowdata={pRowdata}
              cRowdata={cRowdata}
              tRowdata={tRowdata}
              salesType={salesType}
              date={dateValue}
              costCenter={costCenter.code}
              refresh={refresh}
              setStatus={setStatus}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onHiding={onClose} visible={isOpenbyPartialPayment} showTitle={false} width="80%" height={750} id="test">
        <ScrollView>
          <div id="partial-popup" className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <PartialPaymentForm
              ref={partialPaymentRef}
              setIsOpenbyPartialPayment={setIsOpenbyPartialPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              gRowdata={gRowdata}
              sRowdata={sRowdata}
              eRowdata={eRowdata}
              pRowdata={pRowdata}
              cRowdata={cRowdata}
              tRowdata={tRowdata}
              salesType={salesType}
              date={dateValue}
              costCenter={costCenter.code}
              refresh={refresh}
              setStatus={setStatus}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onHiding={onClose} visible={isOpenbyInputPayment} showTitle={false} width="22%" height={500}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <InputPaymentForm
              ref={inputPaymentRef}
              setIsOpenbyInputPayment={setIsOpenbyInputPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              salesType={salesTypeForManual}
              costCenter={costCenter.code}
              inquiryDate={dateValue}
              refresh={refresh}
              setStatus={setStatus}
              etcType={etcType}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup
        onHiding={onHiding}
        visible={confirmPopup.visible}
        showTitle={false}
        width={350}
        height={200}
        shadingColor="rgba(0,0,0,0.4)"
      >
        <ConfirmPopup
          ref={confirmPopupRef}
          handleConfirmSubmit={handleConfirmSubmit}
          accountingNo={confirmPopup.accountingNo}
        />
      </Popup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={
          isOpenbyComplexPayment
            ? {
                of: '.dx-popup-wrapper',
              }
            : { of: '.content' }
        }
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default Detail;
