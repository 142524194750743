import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import CommonDataGrid from 'common/default-data-grid';

import { getNormalDateFormat } from 'utils/common';
import { AuthContext } from 'contexts/auth';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { Column, TotalItem, Summary } from 'devextreme-react/data-grid';
import { master } from 'api';
import { autoDataSourceWithAll } from '../api/costCenterData';
import { salesPayment } from 'api';
import { salesClosePendingManualLink } from 'constants/hyperlink';
import { CheckBox } from 'devextreme-react/check-box';
import { ROLE_AUTH_FOR_SALES_CLOSEPENDING } from 'constants/roleAuth';

import ScrollView from 'devextreme-react/scroll-view';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';

import GeneralTicket from './component/salesTab/generalTicket';
import SeasonTicket from './component/salesTab/seasonTicket';
import EtcSales from './component/salesTab/etcSales';
import Tax from './component/salesTab/tax';
import PG from './component/paymentTab/pg';
import CMS from './component/paymentTab/cms';
import SuspenseReceipt from './component/paymentTab/suspenseReceipt';

import ComplexPaymentForm from './component/complex-payment-form';
import PartialPaymentForm from './component/partial-payment-form';

import RegisterSalesLossForm from './component/popup/RegisterSalesLossForm';
import RegisterSalesCarryOverForm from './component/popup/RegisterSalesCarryOverForm';

// 기타매출과 컴포넌트 공유
import AddSalesMaster from '../etc-sales-master/component/addSalesMaster';

import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';

const SaleClosepending = () => {
  const { user } = useContext(AuthContext);
  const userRoleArr = user.RoleCodeStr.split('|');
  const isAuthorized = ROLE_AUTH_FOR_SALES_CLOSEPENDING.some(role => userRoleArr.includes(role));

  const [gRowdata, setGRowdata] = useState([]);
  const [sRowdata, setSRowdata] = useState([]);
  const [eRowdata, setERowdata] = useState([]);
  const [tRowdata, setTRowdata] = useState([]);
  const [pRowdata, setPRowdata] = useState([]);
  const [cRowdata, setCRowdata] = useState([]);
  const [srRowdata, setSRRowdata] = useState([]);

  const [isOpenbyComplexPayment, setIsOpenbyComplexPayment] = useState(false);
  const [isOpenbyPartialPayment, setIsOpenbyPartialPayment] = useState(false);

  const [selectedID, setSelectedID] = useState('');
  const [selectedAccountingNo, setSelectedAccountingNo] = useState('');
  const [dataSourcePayment, SetDataSourcePayment] = useState('');

  //팝업
  const [isLossPopup, setIsLossPopup] = useState(false);
  const [isCarryOverPopup, setIsCarryOverPopup] = useState(false);
  const [isEtcPopup, setIsEtcPopup] = useState(false);
  const [isProfitPopup, setIsProfitPopup] = useState(false);

  //검색
  const [autoText, setAutoText] = useState('');
  const [costCenter, setCostCenter] = useState({ code: '', name: '' });
  const thisMonth = moment(new Date()).format('yyyy-MM');
  const [dateValue, setDateValue] = useState(thisMonth);

  const complexPaymentRef = useRef({});
  const partialPaymentRef = useRef({});

  const generalTicketRef = useRef({});
  const seasonTicketRef = useRef({});
  const etcSalesRef = useRef({});
  const TaxRef = useRef({});
  const pgvanRef = useRef({});
  const cmsRef = useRef({});
  const susprcptRef = useRef({});

  const addSaleMasterRef = useRef({});
  const addSaleProfitRef = useRef({});
  const addSaleLossRef = useRef({});
  const addSaleCarryOverRef = useRef({});

  // 기타매출 select items
  const [etcType, setEtcType] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const [paymentType, setPaymentType] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [isInquiryMonthOnly, setIsInquiryMonthOnly] = useState(false);
  const setStatus = () => {};

  useEffect(() => {
    etcSalesType();
  }, []);

  useEffect(() => {
    SetDataSourcePayment(null);
    (async () => {
      if (selectedID !== '') {
        await axios
          .post(`aggregation/history/getPaymentHistoryByKeyID`, {
            KeyID: selectedID,
            AccountingDate: dateValue,
          })
          .then(res => {
            SetDataSourcePayment(res.data[0] ? res.data[0] : null);
          });
      }
    })();
  }, [selectedID]);
  // 기타 매출에 사용하는 selectitems 값 불러오기
  const etcSalesType = async () => {
    await master.searchCodeMaster({ CodeGroup: ['EtcSalesType', 'CustomerType', 'PaymentType'] }).then(res => {
      if (res.isOk) {
        const etcType = [];
        const customerType = [];
        const paymentType = [];

        res.data?.forEach(t => {
          if (t.CodeGroup === 'EtcSalesType') {
            etcType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          } else if (t.CodeGroup === 'CustomerType') {
            customerType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          } else if (t.CodeGroup === 'PaymentType') {
            paymentType.push({
              id: t.CodeDesc,
              value: t.Code,
            });
          }
        });
        setEtcType(etcType);
        setCustomerType(customerType);
        setPaymentType(paymentType);
      }
    });
  };

  // 자동완성
  const onValueChanged = e => {
    setAutoText(e.value);
  };

  // 자동완성 목록 클릭
  const onItemClick = e => {
    const { CostCenterCode, CostCenterName } = e.itemData;
    setCostCenter({
      code: CostCenterCode,
      name: CostCenterName,
    });
  };

  const handleToDate = e => {
    const salesMonth = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setDateValue(salesMonth);
  };

  // 매출 잡손실 처리
  const handleSaleLoss = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    if (costCenter.code === '' || autoText !== costCenter.name) {
      alert('사업소명을 검색해주세요');
    } else if (t > 0) {
      alert('세금계산서는 잡손실 처리할 수 없습니다.');
    } else if (g === 0 && s === 0 && e === 0) {
      alert('미처리 매출 내역을 선택해주세요');
    } else {
      setIsLossPopup(true);
      const data = [];
      if (g > 0) data.push(...gRowdata);
      if (s > 0) data.push(...sRowdata);
      if (e > 0) data.push(...eRowdata);
      addSaleLossRef.current.open(data);
    }
  };

  // 매출 이월
  const handleSaleCarryOver = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    if (costCenter.code === '' || autoText !== costCenter.name) {
      alert('사업소명을 검색해주세요');
    } else if (t > 0) {
      alert('세금계산서는 매출 이월 처리 할 수 없습니다.');
    } else if (g === 0 && s === 0 && e === 0) {
      alert('미처리 매출 내역을 선택해주세요');
    } else {
      setIsCarryOverPopup(true);
      const data = [];
      if (g > 0) data.push(...gRowdata);
      if (s > 0) data.push(...sRowdata);
      if (e > 0) data.push(...eRowdata);
      addSaleCarryOverRef.current.open(data);
    }
  };

  // 기타 매출
  const handleSaleEtc = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    if (costCenter.code === '' || autoText !== costCenter.name) {
      alert('사업소명을 검색해주세요');
    } else if (t > 0) {
      alert('세금계산서는 처리할 수 없습니다.');
    } else if (g === 0 && s === 0 && e === 0) {
      alert('미처리 매출 내역을 선택해주세요');
    } else {
      setIsEtcPopup(true);
      const data = [];
      if (g > 0) data.push(...gRowdata);
      if (s > 0) data.push(...sRowdata);
      if (e > 0) data.push(...eRowdata);
      addSaleMasterRef.current.open(null, data);
    }
  };

  // 결제 잡이익 처리
  const handleSaleProfit = () => {
    const p = pRowdata.length;
    const c = cRowdata.length;

    if (costCenter.code === '' || autoText !== costCenter.name) {
      alert('사업소명을 검색해주세요');
    } else if (p === 0 && c === 0) {
      alert('미처리 결제 내역을 선택해주세요');
    } else {
      setIsProfitPopup(true);
      const data = [];
      if (p > 0) data.push(...pRowdata);
      if (c > 0) data.push(...cRowdata);
      addSaleProfitRef.current.open(data);
    }
  };

  // 부분 대사
  const handlePartialPayment = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    const p = pRowdata.length;
    const c = cRowdata.length;

    const isGState = gRowdata.filter(g => g.Status === 'A');
    const isSState = sRowdata.filter(s => s.Status === 'A');
    const isEState = eRowdata.filter(s => s.Status === 'A');
    const isTState = tRowdata.filter(s => s.Status === 'A');
    const isPState = pRowdata.filter(p => p.Status === 'A');
    const isCState = cRowdata.filter(c => p.Status === 'A');

    if (g === 0 && s === 0 && e === 0 && p === 0 && c === 0 && t === 0) {
      alert('매출/결제 내역을 선택해주세요');
    } else if (g === 0 && s === 0 && e === 0 && t === 0) {
      alert('매출 내역을 선택해주세요');
    } else if (g > 0) {
      alert('일반 관제 매출은 반제 불가능합니다.');
    }
    // else if (g + s + e + t > 1) {
    //   alert('부분대사 시에는 매출 내역을 하나만 선택해주세요');
    // }
    else if (p === 0 && c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (
      isGState.length > 0 ||
      isSState.length > 0 ||
      isEState.length > 0 ||
      isTState.length > 0 ||
      isPState.length > 0 ||
      isCState.length > 0
    ) {
      alert('매출 상태가 대사 전, 대사 중인 매출만 선택해주세요.');
    } else {
      setIsOpenbyPartialPayment(true);
      partialPaymentRef.current.open(gRowdata, sRowdata, eRowdata, tRowdata, pRowdata, cRowdata);
    }
  };

  // 복합 대사
  const handleComplexPayment = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    const p = pRowdata.length;
    const c = cRowdata.length;

    const isGState = gRowdata.filter(g => g.Status === 'A');
    const isSState = sRowdata.filter(s => s.Status === 'A');
    const isEState = eRowdata.filter(s => s.Status === 'A');
    const isTState = tRowdata.filter(s => s.Status === 'A');
    const isPState = pRowdata.filter(p => p.Status === 'A');
    const isCState = cRowdata.filter(c => p.Status === 'A');

    if (g === 0 && s === 0 && e === 0 && p === 0 && c === 0 && t === 0) {
      alert('매출/결제 내역을 선택해주세요');
    } else if (g === 0 && s === 0 && e === 0 && t === 0) {
      alert('매출 내역을 선택해주세요');
    } else if (p === 0 && c === 0) {
      alert('결제 내역을 선택해주세요');
    } else if (
      isGState.length > 0 ||
      isSState.length > 0 ||
      isEState.length > 0 ||
      isTState.length > 0 ||
      isPState.length > 0 ||
      isCState.length > 0
    ) {
      alert('매출 상태가 대사 전인 매출만 선택해주세요.');
    } else {
      setIsOpenbyComplexPayment(true);
      complexPaymentRef.current.open(gRowdata, sRowdata, eRowdata, tRowdata, pRowdata, cRowdata);
    }
  };

  // ESC
  const onClose = () => {
    setIsLossPopup(false);
    setIsEtcPopup(false);
    setIsCarryOverPopup(false);
    setIsProfitPopup(false);
    setIsOpenbyComplexPayment(false);
    setIsOpenbyPartialPayment(false);
  };

  // 리프레쉬 및 선택 해제
  const refresh = () => {
    if (generalTicketRef.current.instance) {
      generalTicketRef.current.instance.refresh();
      generalTicketRef.current.instance.clearSelection();
    }

    if (seasonTicketRef.current.instance) {
      seasonTicketRef.current.instance.refresh();
      seasonTicketRef.current.instance.clearSelection();
    }

    if (etcSalesRef.current.instance) {
      etcSalesRef.current.instance.refresh();
      etcSalesRef.current.instance.clearSelection();
    }

    if (TaxRef.current.instance) {
      TaxRef.current.instance.refresh();
      TaxRef.current.instance.clearSelection();
    }

    if (pgvanRef.current.instance) {
      pgvanRef.current.instance.refresh();
      pgvanRef.current.instance.clearSelection();
    }

    if (cmsRef.current.instance) {
      cmsRef.current.instance.refresh();
      cmsRef.current.instance.clearSelection();
    }

    if (susprcptRef.current.instance) {
      susprcptRef.current.instance.refresh();
      susprcptRef.current.instance.clearSelection();
    }
  };

  // 다른 탭 클릭할때마다 리프레쉬(일반권/정기권/기타)
  const onTabClick = () => {
    const g = gRowdata.length;
    const s = sRowdata.length;
    const e = eRowdata.length;
    const t = tRowdata.length;

    if (g > 0) generalTicketRef.current.instance.clearSelection();
    if (s > 0) seasonTicketRef.current.instance.clearSelection();
    if (e > 0) etcSalesRef.current.instance.clearSelection();
    if (t > 0) TaxRef.current.instance.clearSelection();
  };

  // 다른 탭 클릭할때마다 리프레쉬(PG&VAN/CMS)
  const onTabClick2 = () => {
    const p = pRowdata.length;
    const c = cRowdata.length;
    if (p > 0) pgvanRef.current.instance.clearSelection();
    if (c > 0) cmsRef.current.instance.clearSelection();
  };

  const onCellPrepared = useCallback(e => {
    cellStatus(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'KeyID';
  const mergeColumn = ['HiParkingID', 'CostCenterName'];
  const cellStatus = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };
  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };
  const onCancelClick = useCallback(e => {
    const { value } = e.itemData;
    const result = confirm('<i>선택한 대사 이력을 취소하시겠습니까?</i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        // 대사 취소
        await salesPayment
          .cancelCostCenterAccountInfo({
            AccountingNo: value,
            RegisterUser: '',
          })
          .then(res => {
            if (res.isOk) {
              notify(
                {
                  message: '취소가 완료되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              refresh();
              setStatus('O');
            } else {
              // error
              notify(
                {
                  message: JSON.parse(res.error.detail).sqlMessage,
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  }, []);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onCheckValueChanged = e => {
    setIsInquiryMonthOnly(e.value);
  };

  return (
    <div>
      <div className="content-block detail-title left-right-header">
        <h2>미처리 매출/결제 마감</h2>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={salesClosePendingManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789', paddingRight: 15 }}></i>
        </div>
      </div>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '50%' }}>
              <Toolbar>
                <ToolbarItem location={'before'}>
                  <div className="label">매출월 :</div>
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <DateBox
                    type="date"
                    pickerType="calendar"
                    displayFormat="yyyy-MM"
                    dataField="date"
                    value={dateValue}
                    width={100}
                    onValueChanged={handleToDate}
                    stylingMode="outlined"
                    icon={true}
                    calendarOptions={{
                      maxZoomLevel: 'year',
                    }}
                  />
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <Autocomplete
                    labelMode="static"
                    stylingMode="outlined"
                    dataSource={autoDataSourceWithAll}
                    value={autoText}
                    valueExpr="CostCenterName"
                    searchExpr="CostCenterName"
                    onValueChanged={onValueChanged}
                    // onEnterKey={onEnterKey}
                    showClearButton={true}
                    width={200}
                    placeholder="사업소명을 입력하세요"
                    onItemClick={onItemClick}
                    mode="search"
                  />
                </ToolbarItem>
                <ToolbarItem location={'before'}>
                  <CheckBox text="당월" onValueChanged={onCheckValueChanged} />
                </ToolbarItem>
                <ToolbarItem location={'after'}>
                  <Button text="기타매출전환" onClick={handleSaleEtc} />
                </ToolbarItem>
                <ToolbarItem location={'after'}>
                  <Button text="매출 이월" onClick={handleSaleCarryOver} visible={isAuthorized ? true : false} />
                </ToolbarItem>
                {/* <ToolbarItem location={'after'}>
                  <Button text="잡손실 처리" onClick={handleSaleLoss} />
                </ToolbarItem> */}
              </Toolbar>
              <TabPanel onTitleClick={onTabClick} deferRendering={false}>
                <Item title="일반권" badge={gRowdata.length}>
                  <GeneralTicket
                    generalTicketRef={generalTicketRef}
                    isClosePending
                    setGRowdata={setGRowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                  />
                </Item>
                <Item title="정기권" badge={sRowdata.length}>
                  <SeasonTicket
                    seasonTicketRef={seasonTicketRef}
                    isClosePending
                    sRowdata={sRowdata}
                    setSRowdata={setSRowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                  />
                </Item>
                <Item title="기타" badge={eRowdata.length}>
                  <EtcSales
                    etcSalesRef={etcSalesRef}
                    isClosePending
                    eRowdata={eRowdata}
                    setERowdata={setERowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                  />
                </Item>
                <Item title="세금계산서" badge={tRowdata.length}>
                  <Tax
                    TaxRef={TaxRef}
                    isClosePending
                    tRowdata={tRowdata}
                    setTRowdata={setTRowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    onCancelClick={onCancelClick}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    setLoadPanelVisible={setLoadPanelVisible}
                    refresh={refresh}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                  />
                </Item>
              </TabPanel>
            </div>
            <div style={{ maxWidth: '50%' }}>
              <Toolbar>
                <ToolbarItem location={'after'}>
                  <Button text="결제 잡이익 처리" onClick={handleSaleProfit} />
                </ToolbarItem>
                <ToolbarItem location={'after'}>
                  <Button icon="plus" text="매출/결제 부분 대사" onClick={handlePartialPayment} />
                </ToolbarItem>
                <ToolbarItem location={'after'}>
                  <Button icon="plus" text="매출/결제 복합 대사" onClick={handleComplexPayment} />
                </ToolbarItem>
              </Toolbar>
              <TabPanel onTitleClick={onTabClick2}>
                <Item title="PG & VAN" badge={pRowdata.length}>
                  <PG
                    pgvanRef={pgvanRef}
                    susprcptRef={susprcptRef}
                    isClosePending
                    setPRowdata={setPRowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                  />
                </Item>
                <Item title="CMS" badge={cRowdata.length}>
                  <CMS
                    cmsRef={cmsRef}
                    susprcptRef={susprcptRef}
                    isClosePending
                    setCRowdata={setCRowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                  />
                </Item>
                <Item title="가수금" badge={srRowdata.length}>
                  <SuspenseReceipt
                    generalTicketRef={generalTicketRef}
                    seasonTicketRef={seasonTicketRef}
                    etcSalesRef={etcSalesRef}
                    TaxRef={TaxRef}
                    pgvanRef={pgvanRef}
                    cmsRef={cmsRef}
                    susprcptRef={susprcptRef}
                    setSRRowdata={setSRRowdata}
                    isClosePending
                    // setCRowdata={setCRowdata}
                    costCenter={costCenter.code}
                    inquiryDate={dateValue}
                    setSelectedID={setSelectedID}
                    setSelectedAccountingNo={setSelectedAccountingNo}
                    isInquiryMonthOnly={isInquiryMonthOnly}
                    setLoadPanelVisible={setLoadPanelVisible}
                  />
                </Item>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>

      <div className={'content-block'}>
        <div>
          <div className={'dx-card'} style={{ padding: '20px 40px' }}>
            <div className="label" style={{ padding: '5px 0px' }}>
              매출 수금 내역 상세 조회
            </div>
            <CommonDataGrid
              className={'dx-card wide-card accounting-payment-report'}
              dataSource={dataSourcePayment}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              // onCellPrepared={onCellPrepared}
              height={350}
              isNotFilterRow
              isInfiniteScroll={false}
              isHeader={false}
              isExcel={false}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '수금 내역 상세 조회.xlsx',
              }}
            >
              {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
              {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
              <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
              <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
              {/* <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} /> */}
              <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} customizeText={customizeText} />
              <Column dataField={'PaymentAmount'} caption={'반제금액'} alignment={'center'} format="#,##0 원" />
              <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
              <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
              <Column dataField={'AccountingType'} caption={'대사구분'} alignment={'center'} />
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
              <Column
                dataField={'RegisterDate'}
                caption={'대사등록일'}
                alignment={'center'}
                customizeText={customizeText}
              />
              <Summary>
                <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
                <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
                <TotalItem column="PaymentAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>
      </div>
      <Popup onClose={onClose} visible={isLossPopup} showTitle={false} width="22%" height={460}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <RegisterSalesLossForm
              ref={addSaleLossRef}
              refresh={refresh}
              setIsLossPopup={setIsLossPopup}
              costCenterCode={costCenter.code}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onClose={onClose} visible={isCarryOverPopup} showTitle={false} width="22%" height={460}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <RegisterSalesCarryOverForm
              ref={addSaleCarryOverRef}
              refresh={refresh}
              setIsCarryOverPopup={setIsCarryOverPopup}
              costCenterCode={costCenter.code}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onClose={onClose} visible={isEtcPopup} showTitle={false} width="40%" height={820}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '3px 20px' }}>
            <AddSalesMaster
              ref={addSaleMasterRef}
              setIsOpen={setIsEtcPopup}
              etcType={etcType}
              customerType={customerType}
              paymentType={paymentType}
              CostCenterCode={costCenter.code}
              CostCenterName={costCenter.name}
              refresh={refresh}
              isClosePending
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onClose={onClose} visible={isProfitPopup} showTitle={false} width="22%" height={460}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <RegisterSalesLossForm
              ref={addSaleProfitRef}
              refresh={refresh}
              setIsLossPopup={setIsProfitPopup}
              costCenterCode={costCenter.code}
              isProfitPopup
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>

      <Popup onClose={onClose} visible={isOpenbyComplexPayment} showTitle={false} width="80%" height={750} id="test">
        <ScrollView>
          <div id="complex-popup" className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <ComplexPaymentForm
              ref={complexPaymentRef}
              setIsOpenbyComplexPayment={setIsOpenbyComplexPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              gRowdata={gRowdata}
              sRowdata={sRowdata}
              eRowdata={eRowdata}
              pRowdata={pRowdata}
              cRowdata={cRowdata}
              tRowdata={tRowdata}
              salesType={''}
              date={dateValue}
              costCenter={'*'}
              refresh={refresh}
              setStatus={setStatus}
            />
          </div>
        </ScrollView>
      </Popup>
      <Popup onClose={onClose} visible={isOpenbyPartialPayment} showTitle={false} width="80%" height={750} id="test">
        <ScrollView>
          <div id="partial-popup" className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <PartialPaymentForm
              ref={partialPaymentRef}
              setIsOpenbyPartialPayment={setIsOpenbyPartialPayment}
              setLoadPanelVisible={setLoadPanelVisible}
              gRowdata={gRowdata}
              sRowdata={sRowdata}
              eRowdata={eRowdata}
              pRowdata={pRowdata}
              cRowdata={cRowdata}
              tRowdata={tRowdata}
              salesType={''}
              date={dateValue}
              costCenter={'*'}
              refresh={refresh}
              setStatus={setStatus}
            />
          </div>
        </ScrollView>
      </Popup>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onClose={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default SaleClosepending;
