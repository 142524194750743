import React from 'react';
import { Chart, Series } from 'devextreme-react/chart';
import { dataSource } from './data.js';

const Bar = () => {
  return (
    <div>
      <Chart id="chart" dataSource={dataSource} width={600}>
        <Series valueField="oranges" argumentField="day" name="My oranges" type="bar" color="#ffaa66" />
      </Chart>
    </div>
  );
};

export default Bar;
