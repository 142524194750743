import React, { useState, useMemo, useCallback, useImperativeHandle, useEffect } from 'react';
import moment from 'moment';
import ContextMenu from 'devextreme-react/context-menu';
import { Column, Summary, TotalItem, Selection, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from '../common/index';
import { cellStatus } from '../common/common';
import { salesPayment } from 'api';

const Tax = React.forwardRef((props, ref) => {
  const {
    inquiryDate,
    costCenter,
    setTRowdata,
    TaxRef,
    onCancelClick,
    isSalesPayment,
    isClosePending,
    setSelectedID,
    setSelectedAccountingNo,
  } = props;

  const [selectSum, setSelectSum] = useState(0);
  const [customizedColumns, setCustomizedColumns] = useState([]);
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    const customizedTax = localStorage.getItem('customizedTax');
    if (customizedTax) {
      const tmp_columns = JSON.parse(customizedTax);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));

  const focusChange = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    TaxRef.current.instance.selectRows(KeyIDs);
  };
  const handleParams = (costCenter, inquiryDate) => {
    if (isClosePending) {
      return { CostCenter: costCenter, IsPostponed: 'Y', InquiryMonth: inquiryDate };
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        TaxRef.current.instance.clearSelection();
        const params = handleParams(costCenter, inquiryDate);
        const result = await salesPayment.getTaxInvoiceListForAccounting(params);
        setForceRender(prev => !prev);
        return result.data;
      },
    });
    return customDataSource;
  }, [inquiryDate, costCenter]);

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = TaxRef.current.instance.getSelectedRowsData();
    setTRowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.RemainAmount;
    }, 0);
    setSelectSum(sum);
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { KeyID, AccountingNo } = data;
      setSelectedID(AccountingNo ? KeyID : 'N/A');
      setSelectedAccountingNo('');
      if (AccountingNo) {
        focusChange(AccountingNo);
      }
    }
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedTax', JSON.stringify(dataFieldsArr));
      });
    }
  };

  return (
    <React.Fragment>
      <CommonDataGrid
        gridRef={TaxRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        width={'100%'}
        onContentReady={onContentReady}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={e => onSelectionChanged(e)}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '세금계산서.xlsx',
        }}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        {isClosePending && <Column dataField={'HiParkingID'} caption={'P코드'} width={'70'} />}
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
        {/* <Column dataField={'CostCenterName'} caption={'사업소명'} width={80} /> */}
        <Column
          dataField={'SalesDate'}
          caption={'발행요청일'}
          width={100}
          visible={!customizedColumns.includes('SalesDate')}
        />
        <Column
          dataField={'CustomerName'}
          caption={'사업자명'}
          width={200}
          visible={!customizedColumns.includes('CustomerName')}
        />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={100}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('StatusName')}
        />
        <Column
          dataField={'RemainAmount'}
          caption={'잔액'}
          format="#,##0 원"
          width={104}
          visible={!customizedColumns.includes('RemainAmount')}
        />
        <Column
          dataField={'SalesPrice'}
          caption={'금액'}
          width={160}
          format="#,##0 원"
          visible={!customizedColumns.includes('SalesPrice')}
        />
        <Column
          dataField={'CustomerCorpRegNumber'}
          caption={'사업자번호'}
          width={120}
          visible={!customizedColumns.includes('CustomerCorpRegNumber')}
        />
        <Column
          dataField={'TaxIssueTypeName'}
          caption={'대금유형'}
          width={80}
          visible={!customizedColumns.includes('TaxIssueTypeName')}
        />
        <Column
          dataField={'TaxTypeName'}
          caption={'과세유형'}
          width={80}
          visible={!customizedColumns.includes('TaxTypeName')}
        />
        <Column
          dataField={'TaxInvoiceTypeName'}
          caption={'발행유형'}
          width={100}
          visible={!customizedColumns.includes('TaxInvoiceTypeName')}
        />
        <Column
          dataField={'SalesItemType'}
          caption={'매출품목'}
          width={80}
          visible={!customizedColumns.includes('SalesItemType')}
        />
        <Column
          dataField={'SalesCount'}
          caption={'품목 수'}
          width={80}
          visible={!customizedColumns.includes('SalesCount')}
        />
        <Column
          dataField={'TaxPrice'}
          caption={'부가세'}
          width={100}
          format="#,##0 원"
          visible={!customizedColumns.includes('TaxPrice')}
        />
        <Column
          dataField={'UnitPrice'}
          caption={'공급가액'}
          width={100}
          format="#,##0 원"
          visible={!customizedColumns.includes('UnitPrice')}
        />
        <Column
          dataField={'CustomerCode'}
          caption={'회사코드'}
          width={120}
          visible={!customizedColumns.includes('CustomerCode')}
        />
        <Column
          dataField={'CustomerManagerName'}
          caption={'매입처 담당자명'}
          width={100}
          visible={!customizedColumns.includes('CustomerManagerName')}
        />
        <Column
          dataField={'CustomerEmail1'}
          caption={'이메일'}
          width={120}
          visible={!customizedColumns.includes('CustomerEmail1')}
        />
        <Column
          dataField={'CustomerEmail2'}
          caption={'이메일2'}
          width={120}
          visible={!customizedColumns.includes('CustomerEmail2')}
        />
        {/* <Column
          dataField={'TaxInvoiceStatusName'}
          caption={'처리상태'}
          width={100}
          visible={!customizedColumns.includes('TaxInvoiceStatusName')}
        />
        <Column
          dataField={'TaxInvoiceNo'}
          caption={'승인번호'}
          width={100}
          visible={!customizedColumns.includes('TaxInvoiceNo')}
        /> */}
        <Column
          dataField={'RegisterUser'}
          caption={'등록자'}
          width={80}
          visible={!customizedColumns.includes('RegisterUser')}
        />
        <Column
          dataField={'RegisterDate'}
          caption={'등록일'}
          width={120}
          customizeText={customizeText}
          visible={!customizedColumns.includes('RegisterDate')}
        />
        <Column
          dataField={'LastUpdateDate'}
          caption={'마지막수정일'}
          width={120}
          visible={!customizedColumns.includes('LastUpdateDate')}
        />
        <Column
          dataField={'InvoiceID'}
          caption={'세금계산서ID'}
          width={120}
          visible={!customizedColumns.includes('InvoiceID')}
        />
        <Column
          dataField={'AccountingNo'}
          caption={'대사ID'}
          width={'160'}
          visible={!customizedColumns.includes('AccountingNo')}
        />
        <Summary>
          <TotalItem column="SalesPrice" displayFormat="선택합계 :" showInColumn="SalesDate" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="SalesDate" />
          <TotalItem column="StatusName" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" displayFormat="전체합계 :" showInColumn="SalesPrice" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="RemainAmount" displayFormat="전체합계 :" showInColumn="RemainAmount" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
    </React.Fragment>
  );
});

export default React.memo(Tax);
