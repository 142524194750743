import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { aggregation, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import { Button } from 'devextreme-react/button';

import ContextMenu from 'devextreme-react/context-menu';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from '../common/index';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

const SuspenseReceipt = React.forwardRef((props, ref) => {
  const {
    generalTicketRef,
    seasonTicketRef,
    etcSalesRef,
    TaxRef,
    pgvanRef,
    cmsRef,
    susprcptRef,
    setSRRowdata,
    costCenter,
    searchData,
    inquiryDate,
    isClosePending,
    isCheckSales,
    isInquiryMonthOnly,
    isSalesPayment,
    onCancelClick,
    setLoadPanelVisible,
    setSelectedAccountingNo,
    setSelectedID,
    SetDataSourcePayment,
  } = props;
  const [selectSum, setSelectSum] = useState(0);
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));

  const focusChange = async accountingNoList => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (accountingNoList.includes(obj.AccountingNo)) {
        KeyIDs.push(obj.KeyID);
      }
    });
    susprcptRef.current.instance.selectRows(KeyIDs);
  };

  const focusChangeSelf = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    susprcptRef.current.instance.selectRows(KeyIDs);
  };

  const [customizedColumns, setCustomizedColumns] = useState([]);

  useEffect(() => {
    const customizedSusprcpt = localStorage.getItem('customizedSusprcpt');
    if (customizedSusprcpt) {
      const tmp_columns = JSON.parse(customizedSusprcpt);
      setCustomizedColumns(tmp_columns);
    }
  }, []);

  const onCellPrepared = ({ data, displayValue, cellElement }) => {
    if (data) {
      if (displayValue === '대사 전') {
        cellElement.style.backgroundColor = 'rgba(247,247,247,1)';
        cellElement.style.color = 'rgba(33,33,33,1)';
      } else if (displayValue === '대사 중') {
        cellElement.style.backgroundColor = 'rgba(0, 207, 232,0.12)';
        cellElement.style.color = 'rgb(29 74 74)';
      } else if (displayValue === '대사완료') {
        cellElement.style.backgroundColor = 'rgb(229 248 240)';
        cellElement.style.color = 'rgb(51 202 139)';
      }
    }
  };

  const onSelectionChanged = () => {
    const rowdata = susprcptRef.current.instance.getSelectedRowsData();
    setSRRowdata(rowdata);

    const sum = rowdata.reduce((a, c) => {
      return a + c.TransactionAmount;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      const response = { CostCenter: costCenter, IsPostponed: 'Y' };
      response[isInquiryMonthOnly ? 'InquiryMonthOnly' : 'InquiryMonth'] = inquiryDate;
      return response;
    } else if (isCheckSales) {
      const { FromDate, ToDate, CostCenterCode } = searchData;
      return { FromDate, ToDate, CostCenter: CostCenterCode, IsforTaxAccounting: 'Y' };
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || (searchData && searchData.CostCenterCode)) {
          susprcptRef.current.instance.clearSelection();
          const result = await aggregation.getSuspenseReceiptsDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData, isInquiryMonthOnly]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo } = e.data;
    return StatusName === '대사 중' || StatusName === '대사완료' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { AccountingNo } = data;
      AccountingNo ? setSelectedAccountingNo(AccountingNo) : setSelectedAccountingNo('N/A');
      setSelectedID('');
      if (AccountingNo) {
        focusChangeSelf(AccountingNo);
      }
      SetDataSourcePayment([]);
    }
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedSusprcpt', JSON.stringify(dataFieldsArr));
      });
    }
  };

  const onClick = () => {
    const gselectedData = generalTicketRef.current.instance.getSelectedRowsData();
    const sselectedData = seasonTicketRef.current.instance.getSelectedRowsData();
    const eselectedData = etcSalesRef.current.instance.getSelectedRowsData();
    const tselectedData = TaxRef.current.instance.getSelectedRowsData();
    const srselectedData = susprcptRef.current.instance.getSelectedRowsData();

    if (
      gselectedData.length === 0 &&
      sselectedData.length === 0 &&
      eselectedData.length === 0 &&
      tselectedData.length === 0
    ) {
      return alert('전환할 매출을 선택해 주세요.(일반권/정기권/기타/세금계산서)');
    }
    if (srselectedData.length === 0) {
      return alert('전환할 가수금을 선택해 주세요.');
    }

    const gKeyIDs = gselectedData.map(obj => obj.KeyID + ':' + obj.RemainAmount);
    const sKeyIDs = sselectedData.map(obj => obj.KeyID + ':' + obj.RemainAmount);
    const eKeyIDs = eselectedData.map(obj => obj.KeyID + ':' + obj.RemainAmount);
    const tKeyIDs = tselectedData.map(obj => obj.KeyID + ':' + obj.RemainAmount);
    const srReceiptIDs = srselectedData.map(obj => obj.KeyID);

    const gKeyIDsParam = gKeyIDs.length > 0 ? gKeyIDs.join(';') : '';
    const sKeyIDsParam = sKeyIDs.length > 0 ? sKeyIDs.join(';') : '';
    const eKeyIDsParam = eKeyIDs.length > 0 ? eKeyIDs.join(';') : '';
    const tKeyIDsParam = tKeyIDs.length > 0 ? tKeyIDs.join(';') : '';
    const srKeyIDsParam = srReceiptIDs.length > 0 ? srReceiptIDs.join(';') : '';

    const totalRemainAmount = [...gselectedData, ...sselectedData, ...eselectedData, ...tselectedData].reduce(
      (acc, obj) => acc + obj.RemainAmount,
      0,
    );

    const result = confirm('<i> 매출 전환 하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        await aggregation
          .confirmAccountInfoByReceipt({
            CostCenter: costCenter,
            InquiryDate: inquiryDate,
            // SalesType: '',
            // DetailSalesType: '',
            SalesPrice: totalRemainAmount,
            GeneralSalesKeyID: gKeyIDsParam,
            SeasonTicketKeyID: sKeyIDsParam,
            EtcSalesKeyID: eKeyIDsParam,
            TaxInvoiceKeyID: tKeyIDsParam,
            ReceiptID: srKeyIDsParam,
            Remark: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            if (res.isOk) {
              notify(
                {
                  message: '매줄 전환 되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              generalTicketRef.current.instance.refresh();
              seasonTicketRef.current.instance.refresh();
              etcSalesRef.current.instance.refresh();
              TaxRef.current.instance.refresh();
              pgvanRef.current.instance.refresh();
              cmsRef.current.instance.refresh();
              susprcptRef.current.instance.refresh();
            } else {
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
  };

  const onSRCancelClick = async e => {
    const srselectedData = susprcptRef.current.instance.getSelectedRowsData();
    // 차후 복수 선택 취소기능 개발 되면 아래 조건문만 삭제
    if (srselectedData.length > 1) return alert('취소할 가수금을 1건만 선택하세요');
    const keyIDList = srselectedData.map(d => d.KeyID).join(';');
    const result = confirm('<i> 가수금 등록을 취소하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        await aggregation.cancelSuspenseReceiptByID({ ReceiptID: keyIDList }).then(res => {
          if (res.isOk) {
            notify(
              {
                message: '취소되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            pgvanRef.current.instance.refresh();
            cmsRef.current.instance.refresh();
            susprcptRef.current.instance.refresh();
            susprcptRef.current.instance.clearSelection();
          } else {
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      }
    });
  };

  return (
    <>
      <CommonDataGrid
        gridRef={susprcptRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        onContentReady={onContentReady}
        width={'100%'}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        headerAfter={[
          <Button text="매출전환" width={90} onClick={onClick} />,
          <Button text="등록취소" width={90} onClick={onSRCancelClick} />,
        ]}
        headerBefore={[
          isCheckSales ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              <i className="dx-icon-info dx-icon-customicon"></i>
              <p>사업소명을 먼저 검색해주세요</p>
            </div>
          ) : (
            ''
          ),
        ]}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'SuspenseReceipt.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column
          dataField={'TransactionDate'}
          caption={'결제일'}
          customizeText={customizeText}
          width={137}
          visible={!customizedColumns.includes('TransactionDate')}
        />
        {/* <Column
          dataField={'KeyID'}
          caption={'가수금ID'}
          width={100}
          visible={!customizedColumns.includes('KeyID')}
        />
        <Column
          dataField={'PaymentID'}
          caption={'결제ID'}
          width={100}
          visible={!customizedColumns.includes('PaymentID')}
        /> */}
        <Column
          dataField={'HiParkingID'}
          caption={'P코드'}
          width={80}
          visible={!customizedColumns.includes('HiParkingID')}
        />
        <Column
          dataField={'CostCenterName'}
          caption={'사업소명'}
          width={100}
          visible={!customizedColumns.includes('CostCenterName')}
        />
        <Column
          dataField={'PaymentCodeName'}
          caption={'수금구분'}
          width={80}
          visible={!customizedColumns.includes('PaymentCodeName')}
        />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={82}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('Status')}
        />
        <Column
          dataField={'RemainAmount'}
          caption={'잔액'}
          format="#,##0 원"
          width={100}
          visible={!customizedColumns.includes('RemainAmount')}
        />
        <Column
          dataField={'TransactionAmount'}
          caption={'가수금금액'}
          format="#,##0 원"
          width={112}
          visible={!customizedColumns.includes('TransactionAmount')}
        />
        <Column dataField={'AccountingNo'} caption={'대사ID'} width={100} visible={false} />
        <Column
          dataField={'SalesPrice'}
          caption={'매출금액'}
          format="#,##0 원"
          width={100}
          visible={!customizedColumns.includes('SalesPrice')}
        />
        <Column
          dataField={'RefundPrice'}
          caption={'환불금액'}
          format="#,##0 원"
          width={100}
          visible={!customizedColumns.includes('RefundPrice')}
        />
        <Column dataField={'Remark'} caption={'비고'} width={100} visible={!customizedColumns.includes('Remark')} />
        <Column
          dataField={'PaymentAgencyName'}
          caption={'결제사'}
          width={100}
          visible={!customizedColumns.includes('PaymentAgencyName')}
        />
        <Column
          dataField={'CardTypeName'}
          caption={'카드유형'}
          width={100}
          visible={!customizedColumns.includes('CardTypeName')}
        />
        {/* <Column
          dataField={'RegisterDate'}
          caption={'등록일'}
          width={100}
          visible={!customizedColumns.includes('RegisterDate')}
        />
        <Column
          dataField={'RegisterUser'}
          caption={'등록자'}
          width={100}
          visible={!customizedColumns.includes('RegisterUser')}
        /> */}

        <Summary>
          <TotalItem column="TransactionAmount" displayFormat="선택금액 :" showInColumn="TransactionDate" />
          <TotalItem customizeText={customizeSelectTotal} column="TransactionAmount" showInColumn="TransactionDate" />
          <TotalItem column="Status" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="TransactionAmount" displayFormat="전체합계 :" showInColumn="TransactionAmount" />
          <TotalItem column="TransactionAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
    </>
  );
});

export default React.memo(SuspenseReceipt);
