import React, { useEffect, useState, useRef } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { COMPANYCODE } from 'constants/codes';
import { ticket, error } from 'api';
import { confirm } from 'devextreme/ui/dialog';
import CommonDataGrid from 'common/default-data-grid';
import ExcelImport from 'common/excel-import';
import ExcelExport from 'common/excel-export';
import notify from 'devextreme/ui/notify';

const columns = [
  '일련번호',
  '가맹점ID',
  '가맹점명',
  '입금일시',
  '은행명',
  '계좌번호',
  '입금금액',
  '입금자명',
  '상품명',
  '상태',
  '거래번호(입금)',
  '메모',
];

const EasypayAccountPaymentUploadPage = () => {
  const dataGridRef = useRef({});
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  useEffect(() => {
    result();
  }, []);

  const result = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();
      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const importMapFunc = a => {
    const returnValue = {};
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const saveExcel = () => {
    if (dataSource.length === 0) {
      alert('엑셀 파일을 업로드 해주세요.');
    } else {
      const result = confirm('<i>등록하시겠습니까?</i>', '등록');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          ticket
            .uploadPGEasypayAccountExcel({
              ItemListJson: JSON.stringify(dataSource),
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '처리되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
              }
            });
        }
      });
    }
  };

  const handleImport = ExcelOrganization => {
    setShowPane(true);
    setDataSource(ExcelOrganization);
    setShowPane(false);
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <h5>
            ★&nbsp; 이지페이 가상계좌 입금 이력 업로드 순서 : 이지페이 가상계좌 이력 다운로드 &nbsp; ▷ &nbsp;엑셀 파일
            업로드 &nbsp; ▷ &nbsp; 등록 (합계행은 무시) ▷ &nbsp; 결과 : 입금자명이 업데이트 됩니다.
          </h5>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <ExcelImport
            fieldColumn={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']}
            handleImport={e => handleImport(e.map(importMapFunc))}
            // fieldColumnType={[
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            //   'string',
            // ]}
          />
        </div>
        <CommonDataGrid
          gridRef={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          height={700}
          isHeader={true}
          isExcel={true}
          excelOptions={{
            sheetName: 'sheet',
            fileName: 'easypay-가상계좌업로드.xlsx',
          }}
          headerAfter={[
            <Button
              icon="check"
              text="등록"
              width={80}
              type="normal"
              stylingMode="contained"
              onClick={() => saveExcel()}
            />,
            // <ExcelExport
            //   excelFields={headerText}
            //   excelData={sampleData}
            //   sheetName="Sheet"
            //   fileName="easypay-02_sample.xls"
            //   buttonName="샘플파일 다운로드"
            // />,
          ]}
          allowColumnReordering={true}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <LoadPanel enabled showPane={showPane} />
          <HeaderFilter visible={true} />
          <Column dataField={'일련번호'} caption={'일련번호'} hidingPriority={4} />
          <Column dataField={'가맹점ID'} caption={'가맹점ID'} hidingPriority={4} />
          <Column dataField={'가맹점명'} caption={'가맹점명'} hidingPriority={4} alignment="right" format="#,##0 원" />
          <Column dataField={'입금일시'} caption={'입금일시'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'은행명'} caption={'은행명'} hidingPriority={4} />
          <Column dataField={'계좌번호'} caption={'계좌번호'} hidingPriority={4} />
          <Column dataField={'입금금액'} caption={'입금금액'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'입금자명'} caption={'입금자명'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'상품명'} caption={'상품명'} hidingPriority={4} format="#,##0 원" />
          <Column dataField={'상태'} caption={'상태'} hidingPriority={4} />
          <Column dataField={'거래번호(입금)'} caption={'거래번호'} hidingPriority={4} />
          <Column dataField={'메모'} caption={'메모'} hidingPriority={4} />
          <Summary>
            <TotalItem column="가맹점명" summaryType="count" displayFormat="총 {0}건" />
            <TotalItem column="입금금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          </Summary>
        </CommonDataGrid>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default EasypayAccountPaymentUploadPage;
