import React, { useRef, useImperativeHandle, useState, useCallback, useMemo } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import { ticket } from 'api';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

const SeasonTicketMngForm = React.forwardRef((props, ref) => {
  const {
    setPopup,
    setIsRefresh,
    selectMember,
    isRefresh,
    selectMemberType,
    selectPayType,
    selectPayStatus,
    setLoadPanelVisible,
  } = props;
  const [formData, setFormData] = useState({});
  const [dateValue, setDateValue] = useState();
  const [isTaxInvoice, setIsTaxInvoice] = useState(false);
  const formRef = useRef({});
  const taxRadioSelect = [
    {
      label: '발행',
      value: 1,
    },
    {
      label: '발행 안 함',
      value: 0,
    },
  ];
  let isConfirmSave = false;
  const selectPayTypeForTax = useMemo(() => {
    const res = selectPayType.filter(a => a.code === 'TaxInvoice');
    return res;
  }, [selectPayType]);

  const selectPayTypeExceptTax = useMemo(() => {
    const res = selectPayType.filter(a => a.code !== 'TaxInvoice');
    return res;
  }, [selectPayType]);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    open,
  }));

  // 팝업 오픈
  const open = data => {
    setFormData(data);
  };

  const onClose = () => {
    setPopup({ save: false, visible: false });
    setIsTaxInvoice(false);
  };

  const handleDateFrom = e => {
    setDateValue(e.value);
    // 계약 시작일 선택 시 해당 월의 마지막 날을 계약 종료일로 자동 선택
    if (e.value) {
      setFormData({
        ...formData,
        ToDate: moment(e.value).endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  const dateFormat = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onTaxTypeChanged = e => {
    if (e.value === 1) {
      setFormData({ ...formData, PaymentType: '', PaymentStatus: 'A' });
      setIsTaxInvoice(true);
    } else {
      setIsTaxInvoice(false);
    }
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { MemberType, PaymentType, PaymentStatus, SalesPrice, FromDate, ToDate, Remark, RegisterUser } =
        formRef.current.props.formData;
      const paymentType = isTaxInvoice ? selectPayTypeForTax[0].code : PaymentType;
      const confirmYN = isConfirmSave ? 'Y' : 'N';
      const fromDate = dateFormat(FromDate);
      const toDate = dateFormat(ToDate);
      if (isConfirmSave) {
        setLoadPanelVisible(true);
        const result = await ticket.insertSeasonTicketIssueHistory({
          MemberID: selectMember.MemberID,
          MemberType: '',
          PaymentType: paymentType,
          PaymentStatus,
          SalesPrice: 0,
          FromDate: fromDate,
          ToDate: toDate,
          Remark,
          UserID: RegisterUser,
          ConfirmYN: confirmYN,
        });
        if (result.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '확정처리 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setPopup({ save: true, visible: false });
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: JSON.parse(result.error.detail).sqlMessage,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      } else {
        setLoadPanelVisible(true);
        const result = await ticket.insertSeasonTicketIssueHistory({
          MemberID: selectMember.MemberID,
          MemberType: '',
          PaymentType: paymentType,
          PaymentStatus,
          SalesPrice: 0,
          FromDate: fromDate,
          ToDate: toDate,
          Remark,
          UserID: RegisterUser,
        });
        if (result.isOk) {
          setLoadPanelVisible(false);
          notify(
            {
              message: '저장 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          setPopup({ save: true, visible: false });
        } else {
          setLoadPanelVisible(false);
          notify(
            {
              message: JSON.parse(result.error.detail).sqlMessage,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      }
      setIsRefresh(!isRefresh);
    },
    [selectMember, isRefresh, formData, isConfirmSave, isTaxInvoice],
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <Form
            id="form"
            ref={formRef}
            formData={formData}
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={'정기권 발행 추가'} colCount={2}>
              <SimpleItem
                dataField="TaxYN"
                colSpan={2}
                editorType="dxRadioGroup"
                shouldRender="condition"
                editorOptions={{
                  items: taxRadioSelect,
                  valueExpr: 'value',
                  displayExpr: 'label',
                  layout: 'horizontal',
                  onValueChanged: onTaxTypeChanged,
                }}
              >
                <RequiredRule message="필수 값입니다." />
                <Label text="청구 세금계산서" />
              </SimpleItem>
              <SimpleItem dataField="CostCenterName" colSpan={1} editorOptions={{ readOnly: true }}>
                <Label text="사업소명" />
              </SimpleItem>
              <SimpleItem
                dataField="Count"
                colSpan={1}
                editorOptions={{
                  readOnly: true,
                }}
              >
                <Label text="선택건수" />
              </SimpleItem>
              <Item
                dataField="MemberType"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{
                  items: selectMemberType,
                  displayExpr: 'text',
                  valueExpr: 'code',
                }}
                visible={false}
              >
                <Label text="정기권 유형" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              {/* <SimpleItem dataField="VehicleRegistration" colSpan={1} editorOptions={{ readOnly: true }}>
                <Label text="차량번호" />
              </SimpleItem> */}
              <Item
                dataField="PaymentType"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{
                  items: selectPayTypeExceptTax,
                  displayExpr: 'text',
                  valueExpr: 'code',
                  disabled: isTaxInvoice,
                }}
              >
                <Label text="결제유형" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              {/* <Item
                dataField="PaymentStatus"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{
                  items: selectPayStatus,
                  displayExpr: 'text',
                  valueExpr: 'code',
                  disabled: isTaxInvoice,
                }}
              >
                <Label text="결제상태" />
                <RequiredRule message="필수값 입니다." />
              </Item> */}
              <Item
                dataField="FromDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  icon: true,
                  onValueChanged: handleDateFrom,
                }}
              >
                <Label text="계약시작일" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              <SimpleItem dataField="Remark" colSpan={1} editorType="dxTextArea">
                <Label text="비고" />
              </SimpleItem>
              <Item
                dataField="ToDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'date',
                  useMaskBehavior: true,
                  displayFormat: 'yyyy-MM-dd',
                  min: dateValue,
                  dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                }}
              >
                <Label text="계약종료일" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              <Item
                visible={false}
                dataField="SalesPrice"
                colSpan={1}
                editorType="dxNumberBox"
                helpText="발행시에는 개인 기준금액 혹은 그룹에 설정된 금액으로 자동 적용 됩니다."
                editorOptions={{
                  showSpinButtons: true,
                  step: 1000,
                  min: 0,
                  format: '#,##0 원',
                }}
              >
                <Label text="결제금액" />
              </Item>
            </GroupItem>
            <GroupItem colCount={3}>
              <ButtonItem horizontalAlignment="center">
                <ButtonOptions
                  width={'80%'}
                  colSpan={1}
                  type={'default'}
                  text="즉시확정"
                  onClick={() => {
                    isConfirmSave = true;
                  }}
                  useSubmitBehavior={true}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="center">
                <ButtonOptions
                  colSpan={1}
                  width={'80%'}
                  type={'default'}
                  text="저장"
                  onClick={() => {
                    isConfirmSave = false;
                  }}
                  useSubmitBehavior={true}
                ></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="center">
                <ButtonOptions width={'80%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
});

export default SeasonTicketMngForm;
