import React, { useEffect, useState } from 'react';
import PieChart, { Series, Label, Legend, Export, Font, Connector, Size } from 'devextreme-react/pie-chart';
import '../chart/chart.scss';
import { isEmptyString } from '../../../utils/common';

const SALES_TYPE_NAME = {
  GeneralSales: '일반권',
  SeasonTicket: '정기권',
  EtcSales: '기타매출',
  B2BSales: 'B2B매출',
};

const PieBySalesType = ({ dataSource }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const tmpChartData = [];
    for (let i = 0; i < dataSource.length; i++) {
      const item = dataSource[i];
      tmpChartData.push({
        SalesType: item.GeneralSales,
        SalesTypeName: SALES_TYPE_NAME[item.GeneralSales],
        SumSalesPrice: isEmptyString(item['SumSalesPrice']) ? 0 : item['SumSalesPrice'],
      });
    }
    setChartData(tmpChartData);
  }, [dataSource]);

  const customizeText = arg => `${arg.value.toLocaleString()}원 (${arg.percentText})`;

  return (
    <PieChart id="pie" palette="Bright" dataSource={chartData}>
      <Size height={400} width={'90%'} />
      <Series argumentField="SalesTypeName" valueField="SumSalesPrice">
        <Label visible={true} position="columns" customizeText={customizeText}>
          <Font size={13} />
          <Connector visible={true} width={0.5} />
        </Label>
      </Series>
      <Legend
        orientation="horizontal"
        itemTextPosition="right"
        horizontalAlignment="center"
        verticalAlignment="bottom"
        columnCount={4}
      />
      <Export enabled={true} />
    </PieChart>
  );
};

export default PieBySalesType;
