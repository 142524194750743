export const columns = [
  '가맹점상호',
  'MID',
  'MID명',
  '거래구분',
  '거래 일자',
  '거래 시간',
  '거래금액',
  '은행명',
  '가상계좌번호',
  '입금자명',
  '구매자명',
  '상품명',
  '주문번호',
  'TID',
  '요청일자',
  '입금마감일',
  '입금일자',
  '입금시간',
  '채번취소일자',
  '채번취소시간',
  '거래상태',
  '제휴사',
];

export const sampleData = [
  {
    가맹점상호: '㈜하이파킹',
    MID: 'turuO0441m',
    MID명: '서래마을공영',
    거래구분: '일괄채번',
    '거래 일자': '2023/09/27',
    '거래 시간': '10:58:45',
    거래금액: '120000',
    은행명: '국민은행',
    가상계좌번호: '42209085852777',
    입금자명: '166허1182',
    구매자명: '㈜하이파킹',
    상품명: '미채번 가상계좌',
    주문번호: 'turuO0441m03042309271058457560',
    TID: '2023/09/27',
    요청일자: '2023/09/27',
    입금마감일: '2023/09/27',
    입금일자: '10:58:45',
    입금시간: '',
    채번취소일자: '',
    채번취소시간: '',
    거래상태: '입금',
    제휴사: 'KIBNET',
  },
  {
    가맹점상호: '㈜하이파킹',
    MID: 'turuO0441m',
    MID명: '서래마을공영',
    거래구분: '일괄채번',
    '거래 일자': '2023/09/27',
    '거래 시간': '10:58:45',
    거래금액: '120000',
    은행명: '국민은행',
    가상계좌번호: '42209085852777',
    입금자명: '166허1182',
    구매자명: '㈜하이파킹',
    상품명: '미채번 가상계좌',
    주문번호: 'turuO0441m03042309271058457560',
    TID: '2023/09/27',
    요청일자: '2023/09/27',
    입금마감일: '',
    입금일자: '',
    입금시간: '',
    채번취소일자: '',
    채번취소시간: '',
    거래상태: '입금요청',
    제휴사: 'KIBNET',
  },
];
