import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import {
  Column,
  Selection,
  HeaderFilter,
  Scrolling,
  Summary,
  TotalItem,
  Button as GridButton,
  Editing,
} from 'devextreme-react/data-grid';
import { salesPayment } from 'api';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import DetailHistoryToCancelView from '../../cancel-sales-confirm/component/DetailHistoryToCancelView';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';
import moment from 'moment-timezone';
import '../../../tmp/tmp.scss';

const CancelConfirmReqTicket = props => {
  const {
    setCRowdata,
    cancelTicketReqRef,
    searchData,
    setPopup,
    setEditRowdata,
    headerBefore,
    headerAfter,
    height,
    isHistory,
    isColor,
    isRefresh,
    isNotSearch,
    isCancelSalesConfirm,
    isInputSalesCancel,
    setLoadPanelVisible,
  } = props;
  // datagrid Ref
  const detailHistoryViewRef = useRef({});
  // popup
  const [isOpenTaxDetail, setIsOpenTaxDetail] = useState(false);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'Idx',
      loadMode: 'raw',
      load: async () => {
        const rawResult = await salesPayment.getRefundInfo({});
        const utcToKst = utc => {
          const utcDate = moment.utc(utc);
          const kstDate = utcDate.tz('Asia/Seoul');
          return kstDate;
        };
        const result = rawResult.data.map(a => {
          const transactionDate =
            a.TransactionDate === null ? a.TransactionDate : utcToKst(a.TransactionDate).format('YYYY-MM-DD');
          const approvalDate = a.ApprovalDate === null ? a.ApprovalDate : utcToKst(a.ApprovalDate).format('YYYY-MM-DD');
          const confirmDate = a.ConfirmDate === null ? a.ConfirmDate : utcToKst(a.ConfirmDate).format('YYYY-MM-DD');
          const registerDate =
            a.RegisterDate === null ? a.RegisterDate : utcToKst(a.RegisterDate).format('YYYY-MM-DD HH:mm:ss');
          return {
            ...a,
            TransactionDate: transactionDate,
            ApprovalDate: approvalDate,
            ConfirmDate: confirmDate,
            RegisterDate: registerDate,
          };
        });
        return result;
      },
    });
    return customDataSource;
  }, [searchData, isNotSearch, isRefresh]);

  const onSelectionChanged = e => {
    const result = cancelTicketReqRef.current.instance.getSelectedRowsData();
    setCRowdata(result);
  };

  const onCellPrepared = useCallback(
    ({ data, cellElement }) => {
      if (isColor) {
        if (data) {
          const { RefundStatusName } = data;
          const tr = cellElement;
          if (RefundStatusName && RefundStatusName.trim() === '확정' && tr) {
            tr.classList.add('sales-confirm');
          } else if (RefundStatusName === '반려' && tr) {
            tr.classList.add('sales-cancel');
          }
        }
      }
    },
    [isColor],
  );

  const originTaxCellRender = useCallback(e => {
    const data = e.data;
    if (data.SalesType === 'TaxInvoice') {
      return (
        <Button
          icon="search"
          width={50}
          type="normal"
          stylingMode="contained"
          onClick={() => {
            detailHistoryViewRef.current.open(data.SalesKeyID);
            setIsOpenTaxDetail(true);
          }}
        />
      );
    }
  }, []);

  const refundTaxCellRender = useCallback(e => {
    const data = e.data;
    if (data.SalesType === 'TaxInvoice') {
      return (
        <Button
          icon="search"
          width={50}
          type="normal"
          stylingMode="contained"
          onClick={() => {
            detailHistoryViewRef.current.open(data.RefundID);
            setIsOpenTaxDetail(true);
          }}
        />
      );
    }
  }, []);
  const allowUpdating = e => {
    const isEditable = e.row?.data?.IsEditable === 'Y';
    return isEditable;
  };

  return (
    <>
      <CommonDataGrid
        gridRef={cancelTicketReqRef}
        className={'dx-card wide-card sales-confirm-ticket'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={height || 650}
        onSelectionChanged={e => onSelectionChanged(e)}
        isHeader={true}
        isInfiniteScroll={false}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소요청.xlsx',
          customizeCell: { date: ['ApprovalDate', 'TransactionDate', 'RegisterDate'] },
        }}
        headerBefore={headerBefore}
        headerAfter={headerAfter}
        allowColumnReordering={true}
        onCellPrepared={onCellPrepared}
      >
        <Selection mode="multiple" showCheckBoxesMode="onClick" />
        <Editing mode="row" useIcons={true} allowUpdating={allowUpdating} />
        <HeaderFilter visible={true} />
        {/* <Column dataType="string" type="buttons" width={30}>
          <GridButton
            name="edit"
            type="normal"
            style={{ color: 'black' }}
            stylingMode="contained"
            onClick={e => {
              setPopup({ save: false, visible: true });
              setEditRowdata(e.row.data);
            }}
          />
        </Column> */}
        <Column dataField={'Idx'} caption={'ID'} width={60} />
        {isCancelSalesConfirm && <Column dataField={'CostCenterCode'} width={80} caption={'P코드'} />}
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} />
        <Column dataField={'RefundStatusName'} caption={'처리상태'} width={90} />
        <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={100} />
        <Column dataField={'CustomerName'} caption={'고객명'} width={100} />
        <Column dataField={'TransactionDate'} caption={'결제일'} width={100} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={100} />
        <Column dataField={'RefundPrice'} caption={'환불금액'} format="#,##0 원" width={100} />
        <Column dataField={'CreditCardApprovalNo'} caption={'카드승인번호'} width={100} />
        <Column dataField={'CardAgencyListName'} caption={'결제사'} width={80} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={100} />
        <Column dataField={'RegisterUser'} caption={'접수자'} width={80} />
        <Column dataField={'RegisterDate'} caption={'접수일'} width={100} />
        <Column dataField={'RefundPaymentTypeName'} caption={'환불수단'} width={90} />
        <Column dataField={'RefundReasonName'} caption={'환불사유'} width={100} />
        <Column dataField={'Remark'} caption={'비고'} width={200} />
        <Column dataField={'SalesPeriod'} caption={'매출대상기간'} width={180} />
        <Column dataField={'CancelPeriod'} caption={'환불대상기간'} width={180} />
        <Column
          dataField={'originTax'}
          caption={'원세금계산서'}
          width={30}
          cellRender={originTaxCellRender}
          cssClass="cell-remove-padding"
        />
        <Column
          dataField={'refundTax'}
          caption={'환불세금계산서'}
          width={30}
          cellRender={refundTaxCellRender}
          cssClass="cell-remove-padding"
        />
        <Column dataField={'RefundID'} caption={'환불세금계산서번호'} width={140} />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
        <Column dataField={'ConfirmDate'} caption={'확정대상일'} width={100} />
        <Column dataField={'ApprovalDate'} caption={'확정/반려일'} width={100} />
        <Column dataField={'ApprovalUser'} caption={'승인자'} width={100} />
        <Column dataField={'ApprovalComment'} caption={'승인메세지'} width={100} />
        <Column dataField={'BankName'} caption={'은행명'} width={100} />
        <Column dataField={'BankAccountNo'} caption={'입금계좌'} width={100} />
        <Column dataField={'AccountHolder'} caption={'예금주'} width={100} />
        <Column dataField={'CustomerInfo'} caption={'고객과의 관계'} width={100} />
        <Column dataField={'TelephoneNo'} caption={'고객연락처'} width={100} />
        <Column dataField={'SalesFromDate'} caption={'매출시작일'} visible={false} />
        <Column dataField={'SalesToDate'} caption={'매출종료일'} visible={false} />
        <Summary>
          <TotalItem
            column={isCancelSalesConfirm ? 'Remark' : isInputSalesCancel ? 'SalesPrice' : 'IssueCreditCard'}
            summaryType="count"
            displayFormat="총 {0}건"
          />
        </Summary>
      </CommonDataGrid>
      <Popup visible={isOpenTaxDetail} SHOWCLOSEButton={true} showTitle={false} width="50%" height={890}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
            <DetailHistoryToCancelView
              ref={detailHistoryViewRef}
              setIsOpen={setIsOpenTaxDetail}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
    </>
  );
};

export default React.memo(CancelConfirmReqTicket);
