import axios from 'axios';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');

// 카쉐어링 엑셀 업로드 유효성 확인

export const csstiUrls = {
  check: 'aggregation/btob/checkCarsharingData',
  upload: 'aggregation/btob/uploadCarsharingData',
  insert: 'aggregation/insertTaxInvoiceBtoB',
  get: 'aggregation/getTaxInvoiceItems',
};

export const BTOB_COST_CENTER_CODE = 'BtoB';

export const chartsUrls = {
  summaryAllSalesByCategoryDay: 'report/getSummaryAllSalesByCategoryDay',
  summaryGeneralSalesByDay: 'report/getSummaryGeneralSalesByDay',
  summaryGeneralSalesByCenterDay: 'report/getSummaryGeneralSalesByCenterDay',
};

export const doApi = async (api, params) => {
  // console.log(api);
  // console.log(params);
  try {
    const result = await axios.post(api, {
      ...params,
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    console.log(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
};
