import React from 'react';
import './dashboard.scss';

const Dashboard = () => {
  return (
    <>
      <h2 className={'content-block'}>Dashboard</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>Put your content here</div>
      </div>
    </>
  );
};

export default Dashboard;
