import React from 'react';
import { Button } from 'devextreme-react/button';
import { utils, writeFile } from 'xlsx';

const excelExport = ({ excelFields, excelData, sheetName, fileName, buttonName }) => {
  const excelExport = (header, data, ws_name, file_name) => {
    // const header = ['Header-1', 'Header-2', 'Header-3', 'Header-4'];
    // const data = [[1, 2, 3, 4], [5, 6, 7, 8]];
    // const ws_name = 'SheetJS';
    // const file_name = 'abc.xlsx';

    const { book_new, aoa_to_sheet, book_append_sheet } = utils;
    const sheetName = ws_name === null ? 'Sheet' : ws_name;

    const wb = book_new();
    const ws = aoa_to_sheet([header, ...data]);
    book_append_sheet(wb, ws, sheetName);
    writeFile(wb, file_name);
  };
  return (
    <Button
      icon="exportxlsx"
      text={buttonName}
      // type="default"
      stylingMode="contained"
      onClick={() => {
        const excelHeaderText = excelFields.map(a => a.label);
        const excelHeaderKey = excelFields.map(a => a.key);
        const makeOneRow = row => {
          return excelHeaderKey.map(field => row[field]);
        };

        const allExcelData =
          excelData &&
          (Array.isArray(excelData)
            ? excelData.map(makeOneRow)
            : excelData.__rawData && excelData.__rawData.map(makeOneRow));
        excelExport(excelHeaderText, allExcelData, sheetName, fileName);
      }}
    />
  );
};

export default excelExport;
