import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import moment from 'moment/moment';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import { Column, Scrolling, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import { salesPayment, error } from 'api';
import DateBox from 'devextreme-react/date-box';
import CustomStore from 'devextreme/data/custom_store';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { BTOB_COST_CENTER_CODE } from 'api/btob';
import { makeTaxInvoiceStatusCellRender, notifyS } from 'common/popup/sales-common-util';
import { isEmptyObject } from 'utils/common';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import DetailHistory from 'pages/sales-mng/sales-taxinvoice/popup/DetailHistory';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const CsstiHistory = forwardRef(({ refreshTaxInvoice, setLoadPanelVisible }, ref) => {
  const gridRef = useRef({});
  const detailHistoryRef = useRef({});

  //검색
  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY/MM/DD'),
    SearchCompany: '',
    CostCenterCode: BTOB_COST_CENTER_CODE,
  });
  const [searchKey, setSearchKey] = useState(0);

  //상세보기 팝업
  const [isOpen, setIsOpen] = useState(false);

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    refresh,
  }));

  const handleSalesMonth = e => {
    setSearchData({
      ...searchData,
      SalesMonth: e.value,
    });
  };

  const onChangeSearchText = e => {
    console.log('onChangeSearchText');
    console.log(e.component._label._props.text);
    setSearchData({
      ...searchData,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    console.log('onEnterKey');
    setSearchKey(prev => prev + 1);
  };

  const editCellRender = e => {
    console.log('editCellRender...');
    return <Button icon="search" type="normal" stylingMode="contained" onClick={() => togglePopup(e.data)} />;
  };

  const togglePopup = data => {
    console.log('...togglePopup');
    console.log(data);
    detailHistoryRef.current.open(data.InvoiceID);
    setIsOpen(true);
  };

  const refresh = () => {
    gridRef.current.instance.refresh();
  };

  const dataSource = useMemo(() => {
    console.log('dataSource = useMemo(() => {...');
    const customDataSource = new CustomStore({
      key: 'InvoiceID',
      loadMode: 'raw',
      load: async () => {
        try {
          const params = {
            ...searchData,
            SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
            LoginID: userId,
          };
          console.log(params);
          console.log('searchKey = ' + searchKey);
          const result = await axios.post(`aggregation/getTaxInvoiceList`, params);
          console.log(result);
          return result.data;
        } catch (e) {
          console.log(e);
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    console.log('dataSource = useMemo(() => {... end');
    return customDataSource;
  }, [searchData, searchKey]);

  const onCancel = useCallback(async e => {
    // 취소 버튼 클릭 시
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    console.log(selectedItems);
    console.log(typeof selectedItems);
    if (isEmptyObject(selectedItems)) {
      notifyS('선택하신 항목이 없습니다.');
      return;
    }

    const { InvoiceID } = selectedItems[0];
    const result = confirm('<i> 세금계산서 발행을 취소하시겠습니까? </i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        await salesPayment.cancelTaxInvoiceListByID({ InvoiceID: InvoiceID, RegisterUser: '' }).then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notifyS('취소되었습니다.', 'success');
            gridRef.current.instance.refresh();
            refreshTaxInvoice();
          } else {
            setLoadPanelVisible(false);
            notifyS(error.errorMsgCheck(res.error.detail), 'error');
          }
        });
      }
    });
  }, []);

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const onClickSearch = () => {
    setSearchKey(prev => prev + 1);
  };

  const onHiding = () => {
    setIsOpen(false);
  };

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card grid-with-status'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={650}
        onCellPrepared={onCellPrepared}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'B2B 세금계산서 발행 목록.xlsx',
          customizeCell: {
            date: ['RegisterDate', 'LastUpdateDate'],
          },
        }}
        allowColumnReordering={true}
        headerBefore={[
          <div className="label">매출 등록월 :</div>,
          <DateBox
            type="date"
            pickerType="calendar"
            displayFormat="yyyy-MM"
            // dataField="date"
            stylingMode="outlined"
            icon={true}
            calendarOptions={{
              maxZoomLevel: 'year',
            }}
            onValueChanged={handleSalesMonth}
            value={searchData.SalesMonth}
          />,
          <TextBox
            label="SearchCompany"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="P-코드/사업소코드/사업소명"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
        ]}
        headerAfter={[<Button text="조회" onClick={onClickSearch} />, <Button text="취소" onClick={onCancel} />]}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Selection mode="single" selectAllMode="pages" />
        <Column dataField={'detail'} caption={'상세'} cellRender={editCellRender} width={80} allowExporting={false} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} />
        <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
        <Column
          dataField={'TaxInvoiceStatusName'}
          caption={'처리상태'}
          width={110}
          cellRender={taxInvoiceStatusCellRender}
        />
        <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={80} />

        <Column dataField={'SalesPrice'} caption={'금액'} width={100} format="#,##0 원" />
        <Column dataField={'RemainAmount'} caption={'잔액'} width={100} format="#,##0 원" />
        <Column dataField={'CustomerName'} caption={'사업자명'} width={200} />
        <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={120} />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
        <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
        <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
        <Column dataField={'SalesCount'} caption={'품목 수'} width={80} />
        <Column dataField={'TaxPrice'} caption={'부가세'} width={80} />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} />
        <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} />
        <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} />
        <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
        <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
        <Column dataField={'RegisterUser'} caption={'등록자'} width={80} />
        <Column dataField={'RegisterDate'} caption={'등록일'} width={120} cellRender={customizeText} />
        <Column dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} cellRender={customizeText} />
        <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
        <Summary>
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
      <Popup onHiding={onHiding} visible={isOpen} showTitle={false} width="50%" height={890}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
            <DetailHistory
              ref={detailHistoryRef}
              setIsOpen={setIsOpen}
              refresh={refresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
    </>
  );
});

export default CsstiHistory;
