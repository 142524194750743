import React, { useCallback, useEffect, useRef, useState } from 'react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Column, Lookup, Scrolling, Selection, Editing, MasterDetail } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import { Button } from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import { parking, ticket, master } from 'api';
import { notifyS } from 'common/popup/sales-common-util';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { PivotGrid, FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import moment from 'moment';
import TemplatePopup from './templatePopup';
import { COMPANYCODE } from 'constants/codes';
import './parking-cost-mgmt.scss';

const DEFAULT_SELECTED_COST_CENTER_CODE = 'BtoB';

const ParkingCostViewByMonth = () => {
  const centerGridRef = useRef({});
  const fixedRef = useRef({});
  const floatingRef = useRef({});
  const disbursementRef = useRef({});
  const chargeRef = useRef({});
  const templatePopup = useRef({});
  const othersRef = useRef({});

  const [dataSource, setDataSource] = useState([]);
  const [costData, setCostData] = useState([]);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [searchData, setSearchData] = useState({
    CostCenterCode: DEFAULT_SELECTED_COST_CENTER_CODE,
  });

  const [selectedKeys, setSelectedKeys] = useState([DEFAULT_SELECTED_COST_CENTER_CODE]);
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  useEffect(() => {
    (async () => {
      const result = await ticket.searchCostCenterForAutoComplete({ CompanyCode: COMPANYCODE, SearchText: '' });
      if (result.isOk) {
        const newDataSource = result.data;
        setDataSource(newDataSource);
        const firstSelectedKeys = newDataSource.length > 0 ? [newDataSource[0].CostCenterCode] : [];
        setSelectedKeys(firstSelectedKeys);
      } else {
        alert(JSON.parse(result.error.detail).sqlMessage);
      }
    })();
  }, []);

  const customPivotDataSource = new PivotGridDataSource({
    fields: [
      {
        caption: '항목그룹',
        dataField: 'CostGroupCodeName',
        width: 150,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '항목',
        dataField: 'CostCodeName',
        width: 150,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '월',
        dataField: 'SalesMonth',
        width: 80,
        area: 'column',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '사용여부',
        dataField: 'UseYN',
        width: 60,
        dataType: 'string',
        summaryType: 'max',
        area: 'data',
      },
      {
        caption: '값',
        dataField: 'Value',
        width: 80,
        dataType: 'string',
        format: '#,##0',
        summaryType: 'max',
        area: 'data',
      },
    ],

    store: new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData;
      },
    }),
  });

  const onSelectionChangedCenter = e => {
    if (e.selectedRowsData.length > 0) {
      setSearchData({
        ...searchData,
        CostCenterCode: e.selectedRowKeys[0],
      });
      setSelectedKeys(e.selectedRowKeys);
      getCostData(e.selectedRowKeys[0]);
    }
  };

  const getCostData = useCallback(async ( costCenterCode) => {
    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: costCenterCode,
    };

    const result = await parking.getParkingCostManagementDataAllMonth(params);
    if (!result.isOk) {
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    const tmpData = result.data && result.data[0] ? result.data[0] : [];
    setCostData(tmpData);
    
    setLoadPanelVisible(false);
  }, []);

  const inquiry = useCallback(async () => {
    const tmpFixed = fixedRef.current.getDataSource();
    const tmpFloating = floatingRef.current.getDataSource();
    const tmpOthers = othersRef.current.getDataSource();
    const tmpDisbursement = disbursementRef.current.getDataSource();
    const tmpCharge = chargeRef.current.getDataSource();

    setLoadPanelVisible(true);
    const params = {
      CostCenterCode: searchData.CostCenterCode,
      FixedItemListJson: JSON.stringify(tmpFixed),
      FloatingItemListJson: JSON.stringify(tmpFloating),
      OthersItemListJson: JSON.stringify(tmpOthers),
      DisbursementItemListJson: JSON.stringify(tmpDisbursement),
      ChargeItemListJson: JSON.stringify(tmpCharge),
    };
    const result = await parking.saveParkingCostManagementData(params);
    if (!result.isOk) {
      setLoadPanelVisible(false);
      notifyS(JSON.parse(result.error.detail).sqlMessage);
      return;
    }

    notifyS('저장되었습니다.', 'success');
    setLoadPanelVisible(false);
  }, [searchData]);

  return (
    <div>
      <h2 className={'content-block'}>사업소 월별 비용 조회</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ paddingTop: '0px' }}>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div style={{ marginRight: '5px', maxWidth: '25%' }}>
              <CommonDataGrid
                gridRef={centerGridRef}
                className={'dx-card wide-card grid-with-status'}
                dataSource={dataSource}
                showBorders={true}
                allowColumnResizing={true}
                columnHidingEnabled={false}
                hoverStateEnabled={true}
                height={775}
                isHeader={true}
                isExcel={true}
                excelOptions={{
                  sheetName: 'sheet',
                  fileName: '사업소월별비용.xlsx',
                }}
                allowColumnReordering={true}
                onSelectionChanged={onSelectionChangedCenter}
                keyExpr="CostCenterCode"
                selectedRowKeys={selectedKeys}
              >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Selection mode="single" selectAllMode="pages" />
                <Column
                  dataField={'CostCenterCode'}
                  caption={'P코드'}
                  width={70}
                  visible={false}
                  allowEditing={false}
                />
                <Column dataField={'CostCenterName'} caption={'사업소명'} width={200} allowEditing={false} />
              </CommonDataGrid>

              <TemplatePopup ref={templatePopup} />
            </div>
            <div style={{ width: '75%', display: 'flex', marginTop: '38px'}}>
                <PivotGrid
                  className={'dx-card wide-card payment-pivot'}
                  dataSource={customPivotDataSource}
                  showBorders={true}
                  showColumnTotals={false}
                  showColumnGrandTotals={false}
                  showRowTotals={false}
                  showRowGrandTotals={false}
                  hoverStateEnabled={true}
                  isExcel={true}
                  height={775}
                >
                  {/* <Scrolling mode="virtual" showScrollbar="always" /> */}
                  <FieldPanel visible={false} allowFieldDragging={false} showFilterFields={false} />
                  <FieldChooser enabled={false} />
                </PivotGrid>
            </div>
          </div>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

export default ParkingCostViewByMonth;
