import React, { useMemo, useRef, useCallback, useEffect, useState, useImperativeHandle } from 'react';
import html2canvas from 'html2canvas';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';
import { Button } from 'devextreme-react/button';
import { encodedImage } from './base64';
import { jsPDF } from 'jspdf';

import 'devextreme-react/text-area';
import 'devextreme-react/date-box';

const PdfExport = React.forwardRef((props, ref) => {
  const { setPopupVisible } = props;
  const [costCenter, setCostCenter] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const gridContainerRef = useRef(null);

  const onClose = () => {
    setPopupVisible(false);
  };
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  const open = (costcenterName, dataSource) => {
    setCostCenter(costcenterName);
    setDataSource(dataSource);
    setPopupVisible(true);
  };

  const customPivotDataSource = new PivotGridDataSource({
    fields: [
      {
        caption: '구분1',
        dataField: '구분1',
        width: 100,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '구분2',
        dataField: '구분2',
        width: 100,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '구분3',
        dataField: '구분3',
        width: 100,
        area: 'row',
        expanded: true,
        sortBy: 'none',
      },
      {
        caption: '실적',
        dataField: '실적',
        width: 200,
        dataType: 'string',
        format: '#,##0',
        summaryType: 'sum',
        area: 'data',
      },
      {
        caption: '산출근거',
        dataField: '산출근거',
        width: 300,
        dataType: 'string',
        summaryType: 'max',
        area: 'data',
      },
    ],

    store: new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return dataSource;
      },
    }),
  });

  const onPdfExportClick = useCallback(async e => {
    const doc = new jsPDF('p', 'mm', 'a4');
    const gridContainer = gridContainerRef.current;
    const canvas = await html2canvas(gridContainer, { scale: 1 });
    const imgData = canvas.toDataURL('image/png');
    doc.addImage(imgData, 'jpeg', 10, 10, 190, 0);
    doc.save('test.pdf');
  }, []);

  return (
    <div className="responsive-paddings">
      <div className="align-row-reverse">
        <Button width={'3%'} icon="close" onClick={onClose} />
      </div>

      <div ref={gridContainerRef} className="preview-pdf-export">
        <div className="long-title left-right-header">
          <h3 style={{ fontWeight: 'bold' }}> {costCenter + ' ' + '주차장 운영 보고서'}</h3>
          <img width="10%" height="100%" alt="이미지없음" src={encodedImage}></img>
        </div>
        <PivotGrid
          className={'dx-card wide-card payment-pivot'}
          dataSource={customPivotDataSource}
          showBorders={true}
          showColumnTotals={true}
          showRowTotals={true}
          showRowGrandTotals={true}
          hoverStateEnabled={true}
          // height={900}
        >
          {/* <Scrolling mode="virtual" showScrollbar="always" /> */}
          <FieldPanel visible={false} allowFieldDragging={false} showFilterFields={false} />
          <FieldChooser enabled={false} />
        </PivotGrid>
      </div>
      <div className="align-row-reverse">
        <Button width={150} type={'default'} onClick={onPdfExportClick} text={'PDF 다운로드'} />
      </div>
    </div>
  );
});

export default PdfExport;
