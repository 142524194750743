import axios from 'axios';
import { COMPANYCODE } from 'constants/codes';
import { handleAuthError } from './error';
const userId = localStorage.getItem('userId');

export async function commonData() {
  try {
    const result = await axios.post(`system/common/select`, {});

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function costcenterData() {
  try {
    const result = await axios.post(`master/searchCostCenterForAutoComplete`, {
      CompanyCode: COMPANYCODE,
      SearchText: '',
      LoginID: userId,
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}

export async function costcenterDataAll() {
  try {
    const result = await axios.post(`master/searchCostCenterForAutoComplete`, {
      CompanyCode: COMPANYCODE,
      SearchText: '',
      LoginID: userId,
      isWithALL: 'Y',
    });

    return {
      isOk: true,
      data: result.data,
    };
  } catch (e) {
    handleAuthError(e);
    return {
      isOk: false,
      error: e.response.data,
    };
  }
}
