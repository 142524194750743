import React, { useState, useMemo, useCallback, useImperativeHandle } from 'react';
import { Column, Summary, TotalItem, Selection, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { cellStatus } from '../component/common/common';
import { salesPayment } from 'api';
import ContextMenu from 'devextreme-react/context-menu';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';
import moment from 'moment';

const Tax = React.forwardRef((props, ref) => {
  const { inquiryDate, costCenter, setTRowdata, TaxRef, onCancelClick, setSelectedID, setSelectedAccountingNo } = props;
  const [selectSum, setSelectSum] = useState(0);
  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const inquirydate = inquiryDate.replace('-', '');
        const result = await salesPayment.getTaxInvoiceListForAccounting({
          SalesMonth: inquirydate,
          CostCenter: costCenter,
        });
        return result.data;
      },
    });
    return customDataSource;
  }, [inquiryDate, costCenter]);
  useImperativeHandle(ref, () => ({
    focusChange,
  }));
  const focusChange = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    TaxRef.current.instance.selectRows(KeyIDs);
  };

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = TaxRef.current.instance.getSelectedRowsData();
    setTRowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return '선택 합계 : ' + selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { KeyID, AccountingNo } = data;
      setSelectedID(AccountingNo ? KeyID : 'N/A');
      setSelectedAccountingNo('');
      if (AccountingNo) {
        focusChange(AccountingNo);
      }
    }
  };

  return (
    <React.Fragment>
      <CommonDataGrid
        gridRef={TaxRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={e => onSelectionChanged(e)}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '세금계산서.xlsx',
        }}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
        <Column dataField={'CustomerName'} caption={'사업자명'} width={120} />
        <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={100} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} cellRender={editCellRender} />
        <Column dataField={'RemainAmount'} caption={'잔액'} format="#,##0 원" width={'104'} />
        <Column dataField={'SalesPrice'} caption={'금액'} width={120} format="#,##0 원" />
        <Column dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
        <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
        <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
        <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
        <Column dataField={'SalesItemType'} caption={'매출품목'} width={100} />
        <Column dataField={'SalesCount'} caption={'품목 수'} width={80} />
        <Column dataField={'TaxPrice'} caption={'부가세'} width={80} format="#,##0 원" />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} format="#,##0 원" />
        <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} />
        <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} />
        <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
        <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
        <Column dataField={'RegisterUser'} caption={'등록자'} width={80} />
        <Column dataField={'RegisterDate'} caption={'등록일'} width={120} customizeText={customizeText} />
        <Column dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} />
        <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
        <Summary>
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="SalesDate" />
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
          <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
    </React.Fragment>
  );
});

export default React.memo(Tax);
