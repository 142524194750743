import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';

import CommonDataGrid from 'common/default-data-grid';
import moment from 'moment';

const GeneralTicket = props => {
  const { dataSource, generalTicketRef, setGRowdata } = props;
  // const [dataSource, setDataSource] = useState([]);
  const [selectSum, setSelectSum] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    const selectedData = generalTicketRef.current.instance.getSelectedRowsData();
    const gLength = selectedData.length;
    if (selectedData && gLength > 0) {
      const sumCashPrice = selectedData.reduce((sum, a) => sum + a.CashPrice, 0);
      const sumSalesPrice = selectedData.reduce((sum, a) => sum + a.SalesPrice, 0);
      setSelectSum([sumCashPrice, sumSalesPrice]);
    } else {
      setSelectSum([]);
    }
    setGRowdata(gLength);
  };

  const customizeText = cellInfo => {
    const value = cellInfo.value;
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
  };

  const customizeSelectCashTotal = e => {
    const value = selectSum[0] || 0;
    return value.toLocaleString('ko-KR') + ' 원';
  };

  const customizeSelectSalesTotal = e => {
    const value = selectSum[1] || 0;
    return value.toLocaleString('ko-KR') + ' 원';
  };

  const onHiding = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  const refresh = () => {
    generalTicketRef.current.instance.refresh();
  };

  return (
    <>
      <CommonDataGrid
        gridRef={generalTicketRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={595}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        loadPanel={{ enabled: true }}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '일반권.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        {/* <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="onClick" /> */}
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />
        <Column dataField={'CashLastPayDate'} caption={'현금수금일'} customizeText={customizeText} width={'100'} />
        <Column dataField={'ApprovalDate'} caption={'매출시간'} customizeText={customizeText} width={'160'} />
        <Column dataField={'CashBillID'} caption={'현금영수증ID'} width={110} />
        {/* <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} /> */}
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={'100'} />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
        <Column dataField={'CreditCardApprovalNo'} caption={'승인번호'} width={'100'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={'140'} />
        <Column dataField={'CashPrice'} caption={'현금수금액'} format="#,##0 원" width={'150'} />
        <Column dataField={'AccountingNo'} caption={'대사ID'} width={170} />
        <Column dataField={'AccountingRegisterDate'} caption={'대사날짜'} customizeText={customizeText} width={160} />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} width={'100'} />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={'100'} />
        <Column dataField={'Remark'} caption={'비고'} width={'150'} />
        <Summary>
          <TotalItem showInColumn="CashLastPayDate" displayFormat="선택 매출액:" />
          <TotalItem customizeText={customizeSelectSalesTotal} column="SalesPrice" showInColumn="CashLastPayDate" />
          <TotalItem showInColumn="ApprovalDate" displayFormat="현금 수금액:" />
          <TotalItem customizeText={customizeSelectCashTotal} column="CashPrice" showInColumn="ApprovalDate" />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(GeneralTicket);
