export const columns = [
'가맹점상호',
'MID',
'MID명',
'거래상태',
'거래 일시',
'거래 시간',
'금액',
'부가세',
'합계',
'포인트사용',
'카드명',
'카드번호',
'매입사명',
'신용/체크',
'가맹점번호',
'승인번호',
'할부',
'구매자명',
'상품명',
'주문번호',
'인증',
'TID',
'승인일시',
'승인시간',
'취소일시',
'취소시간',
];

export const sampleData = [
  {
    가맹점상호: '(주)하이파킹',
    MID: 'turu00024m',
    MID명: '잠원동방음언덕형공영',
    거래상태: '승인',
    '거래 일자': '2023/04/05',
    '거래 시간': '12:50:51',
    상품명: '',
    거래금액: '70',
    결제서비스: '신용카드',
    주문번호: 'turu00024m2304051250510006',
    TID: 'turu00024m01032304051250510007',
    승인일자: '2023/04/05',
    승인시간: '12:50:51',
    취소일자: ' ',
    취소시간: '',
    구매자명: '',
    메모: '',
    
  },
  {
    가맹점상호: '(주)하이파킹',
MID: 'turu00024m',
MID명: '잠원동방음언덕형공영',
거래상태: '전취소',
'거래 일자': '2023/04/05',
'거래 시간': '12:46:09',
상품명: '',
거래금액: '-750',
결제서비스: '신용카드',
주문번호: 'turu00024m2304051245490731',
TID: 'turu00024m01032304051245495654',
승인일자: '2023/04/05',
승인시간: '12:45:49',
취소일자: '2023/04/05',
취소시간: '12:46:09',
구매자명: '',
메모: '',

  },
];
