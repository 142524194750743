import React, { useCallback, useMemo, useState, useImperativeHandle, useEffect, useRef } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { cellStatus } from '../common/common';
import { salesPayment, aggregation, error } from 'api';
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import { Popup } from 'devextreme-react/popup';

import RemarkPopup from '../common/remark-popup';
import CustomStore from 'devextreme/data/custom_store';
import ContextMenu from 'devextreme-react/context-menu';
import CommonDataGrid from '../common/index';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

/* 히스토리 : pg, van 사가 나뉘어 있었지만 현재 pg탭에 합쳐짐. 차후 pg tab 네이밍 변경예정*/
/*
isSalesPayment : 매출 대사 관리
isTaxinvoice, isSearch : 세금계산서 발행
isClosePending : 미처리 매출/결제 마감
*/
const PG = React.forwardRef((props, ref) => {
  const {
    setPRowdata,
    susprcptRef,
    inquiryDate,
    costCenter,
    pgvanRef,
    isClosePending,
    isSalesPayment,
    onCancelClick,
    setSelectedAccountingNo,
    setSelectedID,
    SetDataSourcePayment,
    isInquiryMonthOnly,
  } = props;
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectSum, setSelectSum] = useState(0);
  const [customizedColumns, setCustomizedColumns] = useState([]);
  const popupRef = useRef({});
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    focusChange,
  }));

  const focusChange = async accountingNoList => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (accountingNoList.includes(obj.AccountingNo)) {
        KeyIDs.push(obj.KeyID);
      }
    });
    pgvanRef.current.instance.selectRows(KeyIDs);
  };

  const focusChangeSelf = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    pgvanRef.current.instance.selectRows(KeyIDs);
  };

  useEffect(() => {
    const customizedPG = localStorage.getItem('customizedPG');
    if (customizedPG) {
      const tmp_columns = JSON.parse(customizedPG);
      setCustomizedColumns(tmp_columns);
    }
  }, []);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = e => {
    const rowdata = pgvanRef.current.instance.getSelectedRowsData();
    setPRowdata(rowdata);

    // 합계
    const sum = rowdata.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      const response = { CostCenter: costCenter, IsPostponed: 'Y' };
      response[isInquiryMonthOnly ? 'InquiryMonthOnly' : 'InquiryMonth'] = inquiryDate;
      return response;
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate);
        if (costCenter) {
          pgvanRef.current.instance.clearSelection();
          const result = await salesPayment.getPaymentDataDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, isClosePending, isInquiryMonthOnly]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { AccountingNo } = data;
      AccountingNo ? setSelectedAccountingNo(AccountingNo) : setSelectedAccountingNo('N/A');
      setSelectedID('');
      if (AccountingNo) {
        focusChangeSelf(AccountingNo);
      }
      SetDataSourcePayment([]);
    }
  };

  const onContentReady = e => {
    var columnChooserView = e.component.getView('columnChooserView');
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.on('hiding', () => {
        const rawData = columnChooserView.getColumns();
        const dataFieldsArr = rawData.map(column => column.dataField);
        localStorage.setItem('customizedPG', JSON.stringify(dataFieldsArr));
      });
    }
  };

  const handleRemarkSubmit = async remark => {
    const selectedData = pgvanRef.current.instance.getSelectedRowsData();
    const KeyIDs = [];
    setPopupVisible(false);
    selectedData.forEach(obj => {
      KeyIDs.push(obj.KeyID);
    });
    const keyIDsParam = KeyIDs.length > 0 ? KeyIDs.join(';') : '';
    setLoadPanelVisible(true);
    try {
      const res = await aggregation.registerSuspenseReceipts({
        CostCenterCode: costCenter,
        InquiryDate: inquiryDate,
        KeyID: keyIDsParam,
        Remark: remark,
      });
      setLoadPanelVisible(false);
      if (res.isOk) {
        notify(
          {
            message: '가수금으로 등록되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        pgvanRef.current.instance.refresh();
        susprcptRef.current.instance.refresh();
      } else {
        notify(
          {
            message: error.errorMsgCheck(res.error.detail),
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    } catch (error) {
      setLoadPanelVisible(false);
      console.error('오류', error);
    }
  };

  const onClick = e => {
    const selectedData = pgvanRef.current.instance.getSelectedRowsData();
    if (selectedData.length === 0) {
      return alert('결제를 선택해주세요');
    }
    setPopupVisible(true);
  };

  const onClose = () => {
    setPopupVisible(false);
  };
  return (
    <>
      <CommonDataGrid
        gridRef={pgvanRef}
        className={'dx-card wide-card minimum-padding'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        onContentReady={onContentReady}
        width={'100%'}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        onCellClick={onCellClick}
        isHeader={true}
        isExcel={true}
        headerAfter={[<Button text="가수금 등록" onClick={onClick} />]}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'PG&VAN.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        {isClosePending && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        {isClosePending && <Column dataField={'HiParkingID'} caption={'P코드'} width={'100'} />}
        <Column
          dataField={'TransactionDate'}
          caption={'결제시간'}
          customizeText={customizeText}
          width={'134'}
          visible={!customizedColumns.includes('TransactionDate')}
        />
        <Column
          dataField={'CardTypeName'}
          caption={'결제방식'}
          width={'71'}
          visible={!customizedColumns.includes('CardTypeName')}
        />
        <Column
          dataField={'ApprovalTypeName'}
          caption={'결제유형'}
          width={'71'}
          visible={!customizedColumns.includes('ApprovalTypeName')}
        />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'85'}
          cellRender={isSalesPayment ? editCellRender : ''}
          visible={!customizedColumns.includes('StatusName')}
        />
        <Column
          dataField={'ApprovalNo'}
          caption={'승인번호'}
          width={'87'}
          visible={!customizedColumns.includes('ApprovalNo')}
        />
        <Column
          dataField={'SalesPrice'}
          caption={'결제금액'}
          format="#,##0 원"
          width={'104'}
          visible={!customizedColumns.includes('SalesPrice')}
        />
        <Column dataField={'Remark1'} caption={'비고'} width={'80'} visible={!customizedColumns.includes('Remark1')} />
        <Column
          dataField={'CreditCardNo'}
          caption={'카드번호'}
          width={'87'}
          visible={!customizedColumns.includes('CreditCardNo')}
        />
        {/* settleCreditCard 정보가 매출대사/매출취소에 등록되면 적용 */}
        {/* <Column dataField={'SettlementCreditCard'} caption={'매입사'} width={'80'} /> */}
        <Column
          dataField={'CardAgencyListName'}
          caption={'결제사'}
          width={'77'}
          visible={!customizedColumns.includes('CardAgencyListName')}
        />
        <Column
          dataField={'TerminalID'}
          caption={'단말기ID'}
          width={'100'}
          visible={!customizedColumns.includes('TerminalID')}
        />
        <Summary>
          <TotalItem column="SalesPrice" displayFormat="선택합계 :" showInColumn="TransactionDate" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="TransactionDate" />
          <TotalItem column="ApprovalNo" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="SalesPrice" displayFormat="전체합계 :" showInColumn="SalesPrice" />
          <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" />
        </Summary>
      </CommonDataGrid>
      <Popup onHiding={onClose} visible={popupVisible} showTitle={false} width="25%" height={310}>
        <RemarkPopup ref={popupRef} setPopupVisible={setPopupVisible} handleRemarkSubmit={handleRemarkSubmit} />
      </Popup>
    </>
  );
});

export default React.memo(PG);
