import React, { useEffect, useState } from 'react';
import PieBySalesType from './pie-by-sales-type';
import BarBySalesDate from './bar-by-sales-date';
import DrillDownChartBySalesDate from './drill-down-chart-by-sales-date';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/moment';
import { chartsUrls, doApi } from 'api/btob';
import { notifyDelayS } from 'common/popup/sales-common-util';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';

const SalesCharts = () => {
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);

  const [dateValue, setDateValue] = useState({
    // fromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    // toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
   });

  const [dsSummaryAllSalesByCategoryDay, setDsSummaryAllSalesByCategoryDay] = useState([]);
  const [dsSummaryAllSalesByCategoryDayConfirmed, setDsSummaryAllSalesByCategoryDayConfirmed] = useState([]);
  const [dsSummaryGeneralSalesByDay, setDsSummaryGeneralSalesByDay] = useState([]);
  const [dsSummaryGeneralSalesByCenterDay, setDsSummaryGeneralSalesByCenterDay] = useState([]);

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
  };

  const onSearch = async () => {
    setFirstLoaded(prev => false);
    await fetchChartData(dateValue).finally(() => {
      setFirstLoaded(prev => true);
    });
  };

  const fetchChartData = async searchParam => {
    setLoadPanelVisible(true);
    const param = {
      FromDate: moment(searchParam.fromDate).format('YYYY-MM-DD'),
      ToDate: moment(searchParam.toDate).format('YYYY-MM-DD'),
    };
  
    // const res1 = { isOk: true };
    const res1 = await doApi(chartsUrls.summaryAllSalesByCategoryDay, param);
    if (!res1.isOk) {
      notifyDelayS(JSON.parse(res1.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    setDsSummaryAllSalesByCategoryDay(res1.data[0]);
    setDsSummaryAllSalesByCategoryDayConfirmed(res1.data[1]);

    // const res2 = { isOk: true };
    const res2 = await doApi(chartsUrls.summaryGeneralSalesByDay, param);
    
    if (!res2.isOk) {
      notifyDelayS(JSON.parse(res2.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    setDsSummaryGeneralSalesByDay(res2.data[0]);
    // setDsSummaryGeneralSalesByDay(exampleDsSummaryGeneralSalesByCenterDay);

    // const res3 = { isOk: true };
    const res3 = await doApi(chartsUrls.summaryGeneralSalesByCenterDay, param);
    
    if (!res3.isOk) {
      notifyDelayS(JSON.parse(res3.error.detail).sqlMessage);
      setLoadPanelVisible(false);
      return;
    }
    setDsSummaryGeneralSalesByCenterDay(res3.data[0]);
    // setDsSummaryGeneralSalesByCenterDay(exampleDsSummaryGeneralSalesByCenterDay);

    setLoadPanelVisible(false);
    setFirstLoaded(true);
  };

  useEffect(() => {
    fetchChartData(dateValue);
  }, []);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  return (
    <div>
      <h2 className={'content-block'}>유형별 매출 집계</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ paddingTop: '10px' }}>
          <Toolbar>
            <Item location={'before'}>
              <div className="label">매출일 :</div>
            </Item>
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.fromDate}
              />
            </Item>
            <Item location={'before'} text="~" />
            <Item location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.fromDate}
                value={dateValue.toDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </Item>
            <Item location={'before'}>
              <Button type="normal" stylingMode="contained" icon="search" text="조회" width={120} onClick={onSearch} />
            </Item>
          </Toolbar>

          {firstLoaded && (
            <div>
              <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
                <div className={'dx-card'} style={{ marginBottom: '10px', width: '50%' }}>
                  <div className="caption">유형별 매출(전체)</div>
                  <div style={{ padding: '20px' }}>
                    <PieBySalesType dataSource={dsSummaryAllSalesByCategoryDay} />
                  </div>
                </div>
                <div className={'dx-card'} style={{ marginBottom: '10px', width: '50%' }}>
                  <div className="caption">유형별 매출(수금완료)</div>
                  <div style={{ padding: '20px' }}>
                    <PieBySalesType dataSource={dsSummaryAllSalesByCategoryDayConfirmed} />
                  </div>
                </div>
              </div>
              {/* <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
                <div className={'dx-card'} style={{ marginBottom: '10px', width: '100%' }}>
                  <div className="caption">일반권 매출 - 일자별</div>
                  <div style={{ padding: '20px', textAlign: 'center' }}>
                    <BarBySalesDate dataSource={dsSummaryGeneralSalesByDay} />
                  </div>
                </div>
              </div> */}
              <div className={'content-block'} style={{ display: 'flex', gap: '20px' }}>
                <div className={'dx-card chart-card'} style={{ marginBottom: '10px', width: '100%' }}>
                  <div className="caption">일반권 매출 - 일자별, 사업소 별 
                    : 더블 클릭하여 사업소별 매출 확인, 일별 집계로 이동 (Back버튼) 가능</div>
                  <div style={{ padding: '20px' }}>
                    <DrillDownChartBySalesDate dataSource={dsSummaryGeneralSalesByCenterDay} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
    </div>
  );
};

const exampleDsSummaryGeneralSalesByCenterDay = [
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0004]아크플레이스',
    SalesPrice: 69000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0010]사당K스퀘어',
    SalesPrice: 29000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0016]KAIT타워',
    SalesPrice: 23000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0018]KT&G분당',
    SalesPrice: 73000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0045]강남역BNK디지털타워',
    SalesPrice: 41000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0055]HD인텔렉스빌딩',
    SalesPrice: 15000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0058]반포쇼핑타운',
    SalesPrice: 45000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0060]충정로센트럴플레이스',
    SalesPrice: 5000,
  },
  {
    SalesDate: '2023-01-01',
    CostCenterName: '[C0063]남산스퀘어',
    SalesPrice: 588000,
  },

  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0004]아크플레이스',
    SalesPrice: 69000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0010]사당K스퀘어',
    SalesPrice: 29000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0016]KAIT타워',
    SalesPrice: 23000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0018]KT&G분당',
    SalesPrice: 73000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0045]강남역BNK디지털타워',
    SalesPrice: 41000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0055]HD인텔렉스빌딩',
    SalesPrice: 15000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0058]반포쇼핑타운',
    SalesPrice: 145000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0060]충정로센트럴플레이스',
    SalesPrice: 5000,
  },
  {
    SalesDate: '2023-01-02',
    CostCenterName: '[C0063]남산스퀘어',
    SalesPrice: 588000,
  },

  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0004]아크플레이스',
    SalesPrice: 69000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0010]사당K스퀘어',
    SalesPrice: 29000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0016]KAIT타워',
    SalesPrice: 23000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0018]KT&G분당',
    SalesPrice: 73000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0045]강남역BNK디지털타워',
    SalesPrice: 41000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0055]HD인텔렉스빌딩',
    SalesPrice: 15000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0058]반포쇼핑타운',
    SalesPrice: 145000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0060]충정로센트럴플레이스',
    SalesPrice: 5000,
  },
  {
    SalesDate: '2023-01-03',
    CostCenterName: '[C0063]남산스퀘어',
    SalesPrice: 88000,
  },
];

export default SalesCharts;
