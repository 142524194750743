import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Column, Selection, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';

const CMS = props => {
  const { setCRowdata, searchData, cmsRef } = props;
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = () => {
    const rowdata = cmsRef.current.instance.getSelectedRowsData();
    setCRowdata(rowdata);
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const result = await salesPayment.getCPaymentDataDetail({
          ...searchData,
        });
        return result.data;
      },
    });

    return customDataSource;
  }, [searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <>
      <CommonDataGrid
        gridRef={cmsRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={700}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'StatusName'} caption={'상태'} />
        <Column dataField={'TransactionDate'} caption={'입금시간'} customizeText={customizeText} />
        <Column dataField={'TransactionSummary'} caption={'Summary'} />
        <Column dataField={'TransactionAmount'} caption={'입금금액'} format="#,##0 원" />
        <Column dataField={'BankName'} caption={'거래은행'} />
        <Column dataField={'Branch'} caption={'Branch'} />
        <Column dataField={'GiroCode'} caption={'지로'} />
        <Column dataField={'ExtraField1'} caption={'비고'} />
        <Column dataField={'BankAccountNo'} caption={'계좌'} />
      </CommonDataGrid>
    </>
  );
};

export default React.memo(CMS);
