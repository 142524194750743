import React, { useCallback, useRef } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import { salesPayment, error } from 'api';

const AddGeneralForm = props => {
  const { refresh, costCenterCode, inquiryDate, setIsOpen, setLoadPanelVisible } = props;
  const formData = useRef({});
  const formRef = useRef({});
  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { SalsPrice, Remark } = formData.current;
      setLoadPanelVisible(true);
      // 등록
      await salesPayment
        .insertGeneralSalesInfo({
          CostCenterCode: costCenterCode,
          InquiryDate: inquiryDate,
          SalsPrice,
          Remark,
          RegisterUser: '',
        })
        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '등록되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            onClose();
            refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [costCenterCode, inquiryDate],
  );

  const onClose = () => {
    setIsOpen(false);
    formRef.current.instance.resetValues();
  };
  return (
    <form className="add-sales-master-form" onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData.current}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={'일반 매출 등록'}>
            <SimpleItem
              dataField="SalsPrice"
              editorType="dxNumberBox"
              editorOptions={{
                showSpinButtons: false,
                min: 0,
                format: '#,##0',
              }}
            >
              <RequiredRule message="매출 금액을 입력해주세요." />
              <Label text="매출금액" location="top" />
            </SimpleItem>
            <SimpleItem dataField="Remark" editorType="dxTextArea">
              <Label text="비고" location="top" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'60%'} type={'default'} useSubmitBehavior={true} text={'확정'}></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'60%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
};

export default AddGeneralForm;
