import React, { useState, useRef, useMemo, useImperativeHandle, useCallback } from 'react';
import { Column, Summary, TotalItem, Selection, Scrolling } from 'devextreme-react/data-grid';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { cellStatus4Cashbill } from 'pages/sales-mng/check-sales-payment/component/common/common';
import {
  makeCashbillStatusCellRender,
  makeCashbillTradeUsageTypeCellRender,
  makeCashbillMethodTypeCellRender,
} from 'common/popup/sales-common-util';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { confirm } from 'devextreme/ui/dialog';
import { salesPayment, error, aggregation } from 'api';
import ConfirmPopup from './popup/confirm-popup';

import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import axios from 'axios';

import 'styles/CommonStyles.scss';
import './cashbillHistory.scss';
const userId = localStorage.getItem('userId');

const CashbillHistory = React.forwardRef((props, ref) => {
  const gridRef = useRef({});
  const IdentifierNoRef = useRef({});
  const SearchCostCenterRef = useRef({});
  const popupRef = useRef({});

  const [popupVisible, setPopupVisible] = useState(false);
  //검색
  const [dateValue, setDateValue] = useState({
    fromDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(0, 'day').format('YYYY-MM-DD'),
  });
  const [searchData, setSearchData] = useState({
    FromDate: dateValue.fromDate,
    ToDate: dateValue.toDate,
    SearchCostCenter: '',
    IdentifierNo: '',
  });
  const [text, setText] = useState({
    SearchCostCenter: '',
    IdentifierNo: '',
  });
  const [ignoreCheck, setAllCheck] = useState('N');

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    refresh,
  }));

  const handleFromDate = e => {
    setDateValue({ ...dateValue, fromDate: e.value });
    setSearchData({
      ...searchData,
      FromDate: e.value,
    });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, toDate: e.value });
    setSearchData({
      ...searchData,
      ToDate: e.value,
    });
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = () => {
    setSearchData({
      ...searchData,
      SearchCostCenter: text.SearchCostCenter,
      IdentifierNo: text.IdentifierNo,
    });
  };

  const editCellRender = e => {
    return (
      <div style={{ paddingLeft: '8px' }}>
        <Button icon="search" type="normal" stylingMode="contained" onClick={() => handlePopup(e.data)} />
      </div>
    );
  };

  const editCancelTypeCellRender = e => {
    const shortText = e.data.TradeTypeName.replace('거래', '');
    return <div>{shortText}</div>;
  };

  const editDownloadCellRender = e => {
    return <Button icon="download" type="normal" stylingMode="contained" onClick={() => handleDownload(e.data)} />;
  };

  const handleOpenNewWindow = url => {
    const width = 700;
    const height = 900;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(url, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
  };

  const handlePopup = async data => {
    const { BillID } = data;
    await aggregation
      .getCashbillDetailUrl({
        mgtKey: BillID,
      })
      .then(res => {
        if (res.isOk) {
          const url = res.data.result;
          handleOpenNewWindow(url);
          // setImageUrl(url);
        } else {
          notify(
            {
              message: res.error.detail,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
  };
  const handleDownload = async data => {
    const { BillID, ConfirmNum, SearchCostCenter } = data;
    try {
      const res = await aggregation.getCashbillPdfUrl({ mgtKey: BillID });
      if (res.isOk) {
        const url = res.data.result;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ConfirmNum}_${SearchCostCenter}_현금영수증`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        notify(
          {
            message: res.error.detail,
            width: 230,
            position: { at: 'top', my: 'top', of: '#container' },
          },
          'error',
        );
      }
    } catch (error) {
      notify(
        {
          message: error.message,
          width: 230,
          position: { at: 'top', my: 'top', of: '#container' },
        },
        'error',
      );
    }
  };

  const refresh = () => {
    const searchCostCenterValue = SearchCostCenterRef.current.instance._changedValue;
    const searchIdentifierNoValue = IdentifierNoRef.current.instance._changedValue;
    setSearchData({
      ...searchData,
      SearchCostCenter: searchCostCenterValue,
      IdentifierNo: searchIdentifierNoValue,
    });
    gridRef.current.instance.refresh();
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'BillID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await axios.post(`aggregation/getCashBillInfoList`, {
            ...searchData,
            IgnoreAutoRegister: ignoreCheck,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [searchData, ignoreCheck]);

  const onHiding = () => {
    setPopupVisible(false);
  };

  const handleConfirmSubmit = useCallback(async isChecked => {
    // 취소 버튼 클릭 시
    let isError = false;
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    const {
      BillID,
      SalesKeyIDList,
      TransactionDate,
      TradeUsage,
      IdentifierNo,
      ProductName,
      SalesPrice,
      EmailAddress,
      ConfirmNum,
      TradeDate,
      ConfirmID,
      // EmailAddress, // 취소 현금영수증 발행시에도 email 전송
    } = selectedItems[0]; // 수정

    props.setLoadPanelVisible(true);
    const params = {
      BillID,
      SalesKeyIDList,
      TradeDateTime: moment(TransactionDate).format('YYYY-MM-DD HH:mm:ss'),
      TradeType: 'C',
      TradeUsage,
      IdentifierNo,
      ProductName,
      SalesPrice,
      EmailAddress,
      OrgConfirmNum: ConfirmNum,
      OrgTradeDate: TradeDate,
      //EmailAddress, // 취소 현금영수증 발행시에도 email 전송
    }; // 수정

    await aggregation.issueCashBillInfo(params).then(res => {
      if (res.isOk) {
        props.setLoadPanelVisible(false);
        notify(
          {
            message: '처리 되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        gridRef.current.instance.refresh();
        props.refresh();
        onHiding();
      } else {
        isError = true;
        props.setLoadPanelVisible(false);
        return notify(
          {
            message: res.error.detail,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    });

    if (isChecked && !isError) {
      await aggregation.registerCashBillRegisterDummy({ ConfirmID }).then(res => {
        if (res.isOk) {
          props.setLoadPanelVisible(false);
          notify(
            {
              message: '처리 되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          gridRef.current.instance.refresh();
          props.refresh();
          onHiding();
        } else {
          props.setLoadPanelVisible(false);
          notify(
            {
              message: res.error.detail,
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'error',
          );
        }
      });
    }
  }, []);

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus4Cashbill(data, displayValue, cellElement);
  }, []);

  // 현금영수증 상태 - 발행요청/발행완료/전송완료/전송실패
  const cashbillStatusCellRender = useCallback(e => {
    return makeCashbillStatusCellRender(e);
  }, []);

  // 거래구분 - 소득/지출
  const cashbillTradeUsageCellRender = useCallback(e => {
    return makeCashbillTradeUsageTypeCellRender(e);
  }, []);

  // 발행형태 - 자동/수동
  const cashbillMethodTypeCellRender = useCallback(e => {
    return makeCashbillMethodTypeCellRender(e);
  }, []);

  const customizeText = cellInfo => {
    const res = cellInfo.value && moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
    return res;
  };

  const onCheckValueChanged = e => {
    e.value ? setAllCheck('Y') : setAllCheck('N');
  };

  const onCancelClick = () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    selectedItems[0]?.BillID ? popupRef.current.open() : alert('취소할 현금영수증을 선택해주세요');
  };

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarItem location={'before'}>
          <div className="label">결제일 :</div>
        </ToolbarItem>
        <ToolbarItem location={'before'}>
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="FromDate"
            onValueChanged={handleFromDate}
            value={dateValue.fromDate}
          />
        </ToolbarItem>
        <ToolbarItem location={'before'} text="~" />
        <ToolbarItem location={'before'}>
          <DateBox
            type="date"
            displayFormat="yyyy-MM-dd"
            stylingMode="outlined"
            dropDownButtonTemplate={'dropDownButton'}
            useMaskBehavior={true}
            icon={true}
            dataField="ToDate"
            min={dateValue.fromDate}
            value={dateValue.toDate}
            onValueChanged={handleToDate}
            dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
          />
        </ToolbarItem>
        <ToolbarItem location={'after'}>
          <Button text="취소" onClick={onCancelClick} />
        </ToolbarItem>
      </Toolbar>
      <CommonDataGrid
        gridRef={gridRef}
        className={'dx-card wide-card grid-with-status minimal-padding'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={670}
        onCellPrepared={onCellPrepared}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '현금영수증 발행 목록.xlsx',
          customizeCell: {
            date: ['RegisterDate', 'LastUpdateDate'],
          },
        }}
        headerBefore={[
          <TextBox
            ref={SearchCostCenterRef}
            label="SearchCostCenter"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="P-코드/사업소코드/사업소명"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
          <TextBox
            ref={IdentifierNoRef}
            label="IdentifierNo"
            labelMode="hidden"
            stylingMode="outlined"
            placeholder="식별번호/현금영수증ID"
            onValueChanged={onChangeSearchText}
            onEnterKey={onEnterKey}
            width={220}
            mode="search"
          />,
          <CheckBox
            defaultValue={false}
            text="의무 신고 제외"
            hint="자동 의무신고 건 제외"
            onValueChanged={onCheckValueChanged}
          />,
        ]}
        headerAfter={[
          <Button
            icon="refresh"
            type="normal"
            stylingMode="contained"
            onClick={() => {
              refresh();
            }}
          />,
        ]}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <Selection mode="single" selectAllMode="pages" />
        <Column
          dataField={'detail'}
          caption={' '}
          cellRender={editCellRender}
          width={32}
          allowFiltering={false}
          allowResizing={false}
          allowExporting={false}
          allowSorting={false}
          allowReordering={false}
        />
        <Column
          dataField={'download'}
          caption={' '}
          cellRender={editDownloadCellRender}
          width={32}
          allowFiltering={false}
          allowResizing={false}
          allowExporting={false}
          allowSorting={false}
          allowReordering={false}
        />
        <Column
          dataField={'TradeUsageName'}
          caption={'구분'}
          width={50}
          cellRender={cashbillTradeUsageCellRender}
          allowFiltering={false}
          allowResizing={false}
          allowExporting={true}
          allowSorting={false}
        />
        <Column dataField={'CostCenterCode'} caption={'P코드'} width={0} />
        <Column dataField={'ProductName'} caption={'상품명'} width={160} />
        <Column dataField={'TransactionDate'} caption={'결제일자'} width={80} customizeText={customizeText} />
        <Column
          dataField={'MethodTypeName'}
          caption={'자동/수동'}
          width={32}
          cellRender={cashbillMethodTypeCellRender}
          allowFiltering={true}
          allowResizing={false}
          allowExporting={true}
          allowSorting={false}
        />
        <Column dataField={'IdentifierNo'} caption={'식별번호'} width={110} />
        <Column dataField={'TradeTypeName'} caption={'문서형태'} width={38} cellRender={editCancelTypeCellRender} />
        <Column dataField={'SalesPrice'} caption={'금액'} width={60} format="#,##0" />
        <Column
          dataField={'CashBillStatusName'}
          caption={'처리상태'}
          width={90}
          alignment="center"
          cellRender={cashbillStatusCellRender}
        />

        <Column dataField={'ConfirmID'} caption={'현금영수증ID'} width={120} />
        <Column dataField={'ConfirmNum'} caption={'현금영수증번호'} width={120} visible={false} />
        <Column dataField={'TradeDate'} caption={'신고거래일'} width={100} />
        <Column dataField={'TradeDateTime'} caption={'신고거래일시'} width={150} customizeText={customizeText} />
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={100} />
        <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} />
        <Column dataField={'SalesDate'} caption={'DMS매출일자'} width={120} />
        <Column dataField={'UnitPrice'} caption={'공급가액'} width={120} format="#,##0" />
        <Column dataField={'TaxPrice'} caption={'세액'} width={120} format="#,##0" />
        <Column dataField={'Remark'} caption={'비고'} width={200} />
        <Column dataField={'EmailAddress'} caption={'Email주소'} width={200} />
        <Column dataField={'RegisterDate'} caption={'등록일'} width={120} customizeText={customizeText} />
        <Column dataField={'BillID'} caption={'영수증ID'} width={120} />
        <Column dataField={'NTResultMessage'} caption={'국세청신고메세지'} width={120} />
        <Column dataField={'PopbillResponseMessage'} caption={'팝빌결과메세지'} width={120} />
        <Column dataField={'OrgConfirmID'} caption={'(원)현금영수증ID'} width={120} />
        <Column dataField={'OrgConfirmNum'} caption={'(원)현금영수증번호'} width={90} visible={false} />
        <Column dataField={'OrgTradeDate'} caption={'(원)발행일자'} width={90} />
        <Column dataField={'StatusName'} caption={'신고상태'} width={120} />
        <Column dataField={'EmployeeName'} caption={'등록자'} width={120} alignment="center" />
        <Column dataField={'TradeUsate'} caption={'거래유형코드'} width={60} visible={false} />
        <Column dataField={'HiParkingID'} caption={'사업소코드'} width={60} visible={false} />
        <Column dataField={'SalesKeyIDList'} caption={'SalesKeyIDList'} width={120} visible={false} />
        <Column dataField={'InterfaceDate'} caption={'InterfaceDate'} width={120} visible={false} />
        <Column dataField={'InterfaceTime'} caption={'InterfaceTime'} width={120} visible={false} />
        <Summary>
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" />
          {/* <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" /> */}
          <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="RemainAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
      <Popup
        onHiding={onHiding}
        visible={popupVisible}
        showTitle={false}
        width={340}
        height={200}
        shadingColor="rgba(0,0,0,0.4)"
      >
        <ConfirmPopup ref={popupRef} setPopupVisible={setPopupVisible} handleConfirmSubmit={handleConfirmSubmit} />
      </Popup>
    </React.Fragment>
  );
});

export default React.memo(CashbillHistory);
