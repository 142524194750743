import React, { useState, useEffect, useMemo, useRef } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Autocomplete } from 'devextreme-react/autocomplete';
import DateBox from 'devextreme-react/date-box';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { Button } from 'devextreme-react/button';
import { salesPayment } from 'api';
import { statusCode } from '../check-sales-payment/component/common/common';
import { useHistory } from 'react-router-dom';
import ResultGeneralTicket from './components/result-general-ticket';
import ResultSeasonTicket from './components/result-season-ticket';
import ResultEctSales from './components/result-etcsales';
import ResultTaxInvoice from './components/result-taxinvoice';
import ResultSummary from './components/result-summary';
import ResultHistory from './components/result-history';
import Remain from './components/remain';
import { autoDataSource } from '../api/costCenterData';
import '../check-sales-payment/detail.scss';

const Detail = props => {
  const history = useHistory();
  const query = queryString.parse(decodeURIComponent(props.location.search));
  const [dateValue, setDateValue] = useState(query.date);
  const [autoText, setAutoText] = useState('');
  const [status, setStatus] = useState('');
  const [isRefresh, setIsRefresh] = useState(false);
  const [costCenter, setCostCenter] = useState({ code: query.code, name: '' });
  const [register, setRegister] = useState({
    date: '',
    user: '',
  });
  const gridRef = useRef({});
  console.log(costCenter);
  useEffect(() => {
    // 상태코드 & 기간
    salesPayment
      .getSummaryByCostCenter({
        FromDate: dateValue,
        ToDate: dateValue,
        CostCenterCode: costCenter.code,
        SearchText: costCenter.name,
        UserID: '',
      })
      .then(res => {
        if (res.isOk) {
          const statusCode = res.data[0].CurrentStatus;
          const date = res.data[0].SalesDate;
          const costName = res.data[0].CostCenterName;
          setStatus(statusCode);
          setDateValue(date);
          setRegister({
            date: res.data[0].RegisterDate,
            user: res.data[0].RegisterUser,
          });
          setCostCenter({ ...costCenter, name: costName });
        }
      });
  }, [dateValue, costCenter.code]);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      //   key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        try {
          const result = await salesPayment.getAccountingSummary({
            CostCenterCode: costCenter.code,
            InquiryDate: dateValue,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [costCenter, dateValue, isRefresh]);

  const styleName = status => {
    if (status === 'A') {
      return 'end-badge';
    } else if (status === 'O') {
      return 'doing-badge';
    } else {
      return 'before-badge';
    }
  };

  const handleToDate = e => {
    setDateValue(e.value);
  };

  const onValueChanged = e => {
    setAutoText(e.value);
  };

  const extractName = str => {
    return str.replace(/\[[^\]]+\]/, '').trim();
  };

  const onItemClick = e => {
    const { CostCenterCode, CostCenterName } = e.itemData;
    const costCenterName = extractName(CostCenterName);
    setCostCenter({
      code: CostCenterCode,
      name: costCenterName,
    });
  };

  return (
    <React.Fragment>
      <h2 className={'content-block detail-title'}>
        매출 대사 이력 조회
        <div className="Step">
          <div className="Active">
            <i className="dx-icon-chevrondoubleright dx-icon-customicon"></i>
          </div>
          <h3>{'사업소 : ' + '[' + costCenter.code + '] ' + costCenter.name + ' '}</h3>
        </div>
        <div className="Step">
          <div className="Active">
            <i className="dx-icon-chevrondoubleright dx-icon-customicon"></i>
          </div>
          <h3>{' 기간 : ' + dateValue}</h3>
        </div>
        {status === 'N' ? (
          <>
            <div className={styleName(status)}>
              <div className={`${styleName(status)}-text`}>{statusCode(status)}</div>
            </div>
            <div style={{ flexGrow: 1 }}>&nbsp;</div>
          </>
        ) : (
          <>
            {status !== '' && (
              <div className={styleName(status)}>
                <div className={`${styleName(status)}-text`}>{statusCode(status)}</div>
              </div>
            )}
            <div style={{ flexGrow: 1 }}>&nbsp;</div>
            <div style={{ fontSize: '18px', fontWeight: '400' }}>
              마지막 대사일 : {moment(register.date).format('YYYY-MM-DD HH:mm:ss')} <br />
              최근 수정자: {register.user}
            </div>
            <div>&nbsp;</div>
          </>
        )}
        <Button
          icon="back"
          text="목록"
          onClick={() => {
            history.goBack();
          }}
        />
      </h2>
      <div className={'content-block'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <Toolbar>
            <ToolbarItem location={'before'}>
              <div className="label">매출일/확정일 :</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="date"
                value={dateValue}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <Autocomplete
                labelMode="static"
                stylingMode="outlined"
                dataSource={autoDataSource}
                value={autoText}
                valueExpr="CostCenterName"
                searchExpr="CostCenterName"
                onValueChanged={onValueChanged}
                // onEnterKey={onEnterKey}
                showClearButton={true}
                width={250}
                placeholder="사업소명을 입력하세요"
                onItemClick={onItemClick}
                mode="search"
              />
            </ToolbarItem>
            <ToolbarItem location={'after'}>
              <Button
                icon="refresh"
                type="normal"
                width={100}
                text="새로고침"
                stylingMode="contained"
                onClick={() => {
                  setIsRefresh(!isRefresh);
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <div className="tab-box-detail">
            <TabPanel>
              <Item title="대사작업 요약">
                <ResultSummary gridRef={gridRef} dataSource={dataSource} />
              </Item>
              <Item title="마감처리 이력">
                <ResultHistory inquiryDate={dateValue} costCenterCode={costCenter.code} isRefresh={isRefresh} />
              </Item>
            </TabPanel>
          </div>
          <div className={'tab-box-detail'}>
            {/* <div style={{ marginTop: '15px' }}> */}
            <TabPanel>
              <Item title="일반권 처리결과">
                <ResultGeneralTicket inquiryDate={dateValue} costCenterCode={costCenter.code} isRefresh={isRefresh} />
              </Item>
              <Item title="정기권 처리결과">
                <ResultSeasonTicket inquiryDate={dateValue} costCenterCode={costCenter.code} isRefresh={isRefresh} />
              </Item>
              <Item title="기타매출 처리결과">
                <ResultEctSales inquiryDate={dateValue} costCenterCode={costCenter.code} isRefresh={isRefresh} />
              </Item>
              <Item title="세금계산서 처리결과">
                <ResultTaxInvoice inquiryDate={dateValue} costCenterCode={costCenter.code} isRefresh={isRefresh} />
              </Item>
              <Item title="미처리 일반권/결제">
                <Remain inquiryDate={dateValue} costCenterCode={costCenter.code} isRefresh={isRefresh} />
              </Item>
            </TabPanel>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

export default Detail;
