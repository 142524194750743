import React, { useRef, useImperativeHandle, useState, useCallback } from 'react';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  Item,
  RequiredRule,
  ButtonItem,
  ButtonOptions,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './season-ticket-mng-form.scss';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { ticket } from 'api';

const SeasonTicketMngEditForm = React.forwardRef((props, ref) => {
  const {
    setPopup,
    setIsRefresh,
    selectMember,
    isRefresh,
    selectMemberType,
    selectPayType,
    selectPayStatus,
    setLoadPanelVisible,
  } = props;
  const [formData, setFormData] = useState({});

  const formRef = useRef({});

  const [dateValue, setDateValue] = useState();

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    open,
  }));

  // 팝업 오픈
  const open = data => {
    setFormData(data);
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { MemberID, MemberType, PaymentType, PaymentStatus, SalesPrice, FromDate, ToDate, Remark, RegisterUser } =
        formRef.current.props.formData;
      const fromDate = dateFormat(FromDate);
      const toDate = dateFormat(ToDate);
      setLoadPanelVisible(true);
      const result = await ticket.updateSeasonTicketIssueHistory({
        MemberID,
        MemberType,
        PaymentType,
        PaymentStatus,
        SalesPrice,
        FromDate: fromDate,
        ToDate: toDate,
        Remark,
        UserID: RegisterUser,
        TicketID: formData.TicketID,
      });
      if (result.isOk) {
        setLoadPanelVisible(false);
        notify(
          {
            message: '수정 되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        setPopup({ save: true, visible: false });
      } else {
        setLoadPanelVisible(false);
        notify(
          {
            message: JSON.parse(result.error.detail).sqlMessage,
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
      setIsRefresh(!isRefresh);
    },
    [selectMember, isRefresh, formData],
  );

  const onClose = () => {
    setPopup({ save: false, visible: false });
  };

  const handleDateFrom = e => {
    setDateValue(e.value);
  };

  const dateFormat = date => {
    return moment(date).format('YYYY-MM-DD');
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <Form
            id="form"
            ref={formRef}
            formData={formData}
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption={'정기권 발행 변경'} colCount={2}>
              <SimpleItem dataField="CostCenterName" colSpan={1} editorOptions={{ readOnly: true }}>
                <Label text="사업소명" />
              </SimpleItem>
              <Item
                dataField="MemberType"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{
                  items: selectMemberType,
                  displayExpr: 'text',
                  valueExpr: 'code',
                }}
                visible={false}
              >
                <Label text="정기권 유형" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              <SimpleItem dataField="VehicleRegistration" colSpan={1} editorOptions={{ readOnly: true }}>
                <Label text="차량번호" />
              </SimpleItem>
              <Item
                dataField="PaymentType"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{
                  items: selectPayType,
                  displayExpr: 'text',
                  valueExpr: 'code',
                }}
              >
                <Label text="결제유형" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              {/* <Item
                dataField="PaymentStatus"
                editorType="dxSelectBox"
                colSpan={1}
                editorOptions={{
                  items: selectPayStatus,
                  displayExpr: 'text',
                  valueExpr: 'code',
                }}
              >
                <Label text="결제상태" />
                <RequiredRule message="필수값 입니다." />
              </Item> */}
              <Item
                dataField="FromDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'date',
                  dropDownButtonTemplate: 'dropDownButton',
                  displayFormat: 'yyyy-MM-dd',
                  useMaskBehavior: true,
                  icon: true,
                  onValueChanged: handleDateFrom,
                }}
              >
                <Label text="계약시작일" />
                <RequiredRule message="필수값 입니다." />
              </Item>
              <Item
                dataField="SalesPrice"
                colSpan={1}
                editorType="dxNumberBox"
                helpText="발행시에는 개인 기준금액 혹은 그룹에 설정된 금액으로 자동 적용 됩니다."
                editorOptions={{
                  showSpinButtons: true,
                  step: 1000,
                  min: 0,
                  format: '#,##0 원',
                }}
              >
                <Label text="결제금액" />
              </Item>
              <Item
                dataField="ToDate"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'date',
                  useMaskBehavior: true,
                  displayFormat: 'yyyy-MM-dd',
                  min: dateValue,
                  dateOutOfRangeMessage: '계약시작일보다 빠를 수 없습니다.',
                }}
              >
                <Label text="계약종료일" />
                <RequiredRule message="필수값 입니다." />
              </Item>

              <SimpleItem dataField="Remark" colSpan={2} editorType="dxTextArea">
                <Label text="비고" />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={2}>
              <ButtonItem>
                <ButtonOptions width={'40%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions width={'40%'} type={'normal'} text="닫기" onClick={onClose}></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
});

export default SeasonTicketMngEditForm;
