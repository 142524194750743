export const columns = [
  '가맹점명',
  'M I D',
  '거래일자',
  '거래시간',
  '취소일자',
  '취소시간',
  '매입일자',
  '거래금액',
  '원거래금액',
  '부분취소',
  '컵보증금',
  '매입사',
  '발급사',
  '카드번호',
  '승인번호',
  '무이자구분',
  '할부',
  '구매자명',
  '사용자ID',
  '상품명',
  '주문번호',
  '인 증',
  'TID',
  '거래상태',
  '매입상태',
  '팩토링구분',
  '서브몰아이디',
];

export const sampleData = [
  {
    가맹점명: '주식회사 하이파킹',
    'M I D': 'hipark001m',
    거래일자: '2023-01-28',
    거래시간: '06:03:54',
    취소일자: '2023-01-31',
    취소시간: '10:21:33',
    매입일자: '2023-01-29',
    거래금액: 30000,
    원거래금액: 0,
    부분취소: 0,
    컵보증금: 0,
    매입사: '07',
    발급사: '현대',
    카드번호: '401762******8056',
    승인번호: '00895486',
    무이자구분: '일반',
    할부: '0',
    구매자명: '하이*킹',
    사용자ID: '0',
    상품명: '주차비',
    주문번호: 'KRDE2023100001666267',
    '인 증': 'CarPay',
    TID: 'hipark001m01012301281803544917',
    거래상태: '승인',
    매입상태: '승인매입',
    팩토링구분: '일반',
    서브몰아이디: 'P0450',
  },
  {
    가맹점명: '주식회사 하이파킹',
    'M I D': 'hipark002m',
    거래일자: '2023-01-28',
    거래시간: '06:03:54',
    취소일자: '2023-01-31',
    취소시간: '10:21:33',
    매입일자: '2023-01-29',
    거래금액: 30000,
    원거래금액: 0,
    부분취소: 0,
    컵보증금: 0,
    매입사: '07',
    발급사: '현대',
    카드번호: '401762******8057',
    승인번호: '00895487',
    무이자구분: '일반',
    할부: '0',
    구매자명: '하이*킹',
    사용자ID: '0',
    상품명: '주차비',
    주문번호: 'KRDE2023100001666268',
    '인 증': 'CarPay',
    TID: 'hipark001m01012301281803544918',
    거래상태: '승인',
    매입상태: '승인매입',
    팩토링구분: '일반',
    서브몰아이디: 'P0450',
  },
];
