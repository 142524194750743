import React, { useCallback, useState, useImperativeHandle, useRef } from 'react';
import Form, { SimpleItem, GroupItem, Label, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { salesPayment, error } from 'api';
import { cellStatus } from './common/common';
import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import './complex-payment-form.scss';

const ComplexPaymentForm = React.forwardRef((props, ref) => {
  const { setIsOpenbyComplexPayment, setLoadPanelVisible, cRowdata, date, costCenter, refresh, setStatus } = props;
  const [formData, setFormData] = useState({
    SaleTotal: 0,
    PaymentTotal: 0,
    SalesType: '',
    PaymentType: '',
    Diff: 0,
    CMSPaymentKeyID: '',
    SalesKeyID: '',
  });

  const [sdata, setSData] = useState([]);
  const sGridRef = useRef({});

  console.log('formData>', formData);
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
  }));

  // 팝업이 open되면 매출금액, 결제금액, 차이 계산
  const open = (sRowdata, cRowdata) => {
    const CMSPaymentKeyID = [];
    const SalesKeyID = [];
    let paymentTotal = 0;
    let saleTotal = 0;
    let remainpaymentTotal = 0;

    if (cRowdata && cRowdata.length > 0) {
      cRowdata.forEach(c => {
        paymentTotal += c.TransactionAmount;
        CMSPaymentKeyID.push(c.KeyID);
      });
    }
    remainpaymentTotal = paymentTotal;

    if (sRowdata && sRowdata.length > 0) {
      sRowdata.forEach(s => {
        saleTotal += s.RemainAmount;
        s.PaymentPrice = remainpaymentTotal > 0 ? Math.min(s.RemainAmount, remainpaymentTotal) : 0;
        remainpaymentTotal = remainpaymentTotal - s.RemainAmount;
        SalesKeyID.push(s.KeyID+":"+s.PaymentPrice);
      });
      setSData(sRowdata);
    }

    setFormData({
      SaleTotal: saleTotal,
      PaymentTotal: paymentTotal,
      Diff: saleTotal - paymentTotal,
      SalesKeyID: SalesKeyID.length > 0 ? SalesKeyID.join(';') : '',
      CMSPaymentKeyID: CMSPaymentKeyID.length > 0 ? CMSPaymentKeyID.join(';') : '',
    });
  };

  // 확정 클릭시
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { SaleTotal, SalesKeyID, CMSPaymentKeyID, Diff, Remark } = formData;
      if (Diff !== 0) {
        alert('매출금액과 결제금액은 같아야 합니다.');
      } else {
        const salesMonth = date.replace('-', '');
        setLoadPanelVisible(true);
        await salesPayment
          .confirmAccountInfoDetailBtoB({
            CostCenter: costCenter,
            SalesMonth: salesMonth,
            SalesPrice: SaleTotal,
            SalesKeyID,
            TaxInvoiceKeyID: '',
            CMSPaymentKeyID,
            Remark,
            // RegisterUser: '',
          })
          .then(res => {
            setLoadPanelVisible(false);
            setStatus('O');
            if (res.isOk) {
              notify(
                {
                  message: '확정되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              //닫기 및 전체 그리드 리프레쉬
              onClose();
              refresh();
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    },
    [formData, costCenter, date, refresh],
  );

  // 닫기
  const onClose = () => {
    setIsOpenbyComplexPayment(false);

    if (sGridRef.current.instance) {
      sGridRef.current.instance.refresh();
      sGridRef.current.instance.clearFilter();
    }
  };

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            formData={formData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem caption="매출/결제 내역 복합대사" alignItemLabels colCount={5}>
              <GroupItem colSpan={4}>
                <GroupItem>
                  <CommonDataGrid
                    gridRef={sGridRef}
                    className={'dx-card wide-card'}
                    dataSource={sdata}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr="KeyID"
                    height={200}
                    onCellPrepared={onCellPrepared}
                    isHeader={true}
                    isExcel={false}
                    headerBefore={[<span>{'매출 > 공유서비스 매출/매입'}</span>]}
                  >
                    <Column dataField={'KeyID'} caption={'키'} width={100} visible={false} />
                    <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={120} />
                    <Column dataField={'CorpRegNumber'} caption={'사업자코드'} width={100} />
                    <Column dataField={'CostCenterName'} caption={'사업소명'} width={100} />
                    <Column dataField={'SalesDate'} caption={'매출일'} width={100} />
                    <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
                    <Column dataField={'RemainAmount'} caption={'잔액'} width={150} format="#,##0 원" />
                    <Column dataField={'SalesPrice'} caption={'매출액(정산액)'} width={150} format="#,##0 원" />
                    <Column dataField={'UnitPrice'} caption={'원매출'} width={100} format="#,##0 원" />
                    <Column dataField={'Commission'} caption={'수수료'} width={100} format="#,##0 원" />
                    <Column dataField={'Quantity'} caption={'매출수량'} width={100} />
                    <Column dataField={'Remark'} caption={'비고'} width={100} />
                    <Column dataField={'HiparkingID'} caption={'P코드'} width={100} />
                    <Summary>
                      <TotalItem column="RemainAmount" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem
                        column="RemainAmount"
                        summaryType="sum"
                        valueFormat="#,##0 원"
                        displayFormat="합계: {0}"
                      />
                    </Summary>
                  </CommonDataGrid>
                </GroupItem>

                <SimpleItem itemType="empty" cssClass="division" />

                <GroupItem>
                  <CommonDataGrid
                    // gridRef={gridRef} 엑셀기능 필요시 생성
                    className={'dx-card wide-card'}
                    dataSource={cRowdata}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr="KeyID"
                    height={200}
                    onCellPrepared={onCellPrepared}
                    isHeader={true}
                    isExcel={false}
                    headerBefore={[<span>{'결제 > CMS 사'}</span>]}
                  >
                    <Column dataField={'TransactionDate'} caption={'입금시간'} customizeText={customizeText} />
                    <Column dataField={'StatusName'} caption={'상태'} />
                    <Column dataField={'TransactionSummary'} caption={'Summary'} />
                    <Column dataField={'TransactionAmount'} caption={'입금금액'} format="#,##0 원" />
                    <Column dataField={'BankName'} caption={'거래은행'} />
                    <Column dataField={'Branch'} caption={'Branch'} />
                    <Column dataField={'GiroCode'} caption={'지로'} />
                    <Column dataField={'ExtraField1'} caption={'비고'} />
                    <Column dataField={'BankAccountNo'} caption={'계좌'} />
                    <Summary>
                      <TotalItem column="TransactionAmount" summaryType="count" displayFormat="총 {0}건" />
                      <TotalItem
                        column="TransactionAmount"
                        summaryType="sum"
                        valueFormat="#,##0 원"
                        displayFormat="합계: {0}"
                      />
                    </Summary>
                  </CommonDataGrid>
                </GroupItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  dataField="SaleTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="매출금액" location="top" />
                </SimpleItem>
                <SimpleItem
                  dataField="PaymentTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="결제금액" location="top" />
                </SimpleItem>
                <SimpleItem
                  dataField="Diff"
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    showSpinButtons: false,
                    min: 0,
                    format: '#,##0',
                  }}
                >
                  <Label text="차이" location="top" />
                </SimpleItem>
                <SimpleItem dataField="Remark" editorType="dxTextBox">
                  <Label text="비고" location="top" />
                </SimpleItem>
                <GroupItem>
                  <ButtonItem>
                    <ButtonOptions type={'default'} useSubmitBehavior={true} text="확정"></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
});

export default React.memo(ComplexPaymentForm);
