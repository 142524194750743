import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import ContextMenu from 'devextreme-react/context-menu';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { salesPayment } from 'api';
import { cellStatus } from '../check-sales-payment/component/common/common';
import CommonDataGrid from 'common/default-data-grid';

const EtcSales = props => {
  const {
    setERowdata,
    inquiryDate,
    costCenter,
    etcSalesRef,
    isClosePending,
    isTaxinvoice,
    searchData,
    isSearch,
    isSalesPayment,
    onCancelClick,
  } = props;
  const [selectSum, setSelectSum] = useState(0);

  // 매출 상태 css
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  // 체크박스 클릭한 row
  const onSelectionChanged = e => {
    // 선택 row
    const result = etcSalesRef.current.instance.getSelectedRowsData();
    setERowdata(result);

    // 합계
    const sum = result.reduce((a, c) => {
      return a + c.SalesPrice;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate, searchData) => {
    if (isClosePending) {
      return { CostCenter: costCenter, IsPostponed: 'Y', InquiryMonth: inquiryDate };
    } else if (isTaxinvoice) {
      return isSearch ? searchData : {};
    } else {
      return { InquiryDate: inquiryDate, CostCenter: costCenter };
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate, searchData);
        if (costCenter || isSearch) {
          const result = await salesPayment.getEtcSalesDataDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter, searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O'? (
      <>
        <div id={'status-cancel-' + AccountingNo}>{StatusName}</div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  return (
    <>
      <CommonDataGrid
        gridRef={etcSalesRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={595}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={e => onSelectionChanged(e)}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '기타매출.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'CustomerCorpName'} caption={'사업자명'} width={'100'} />
        <Column dataField={'CustomerName'} caption={'고객명'} width={'100'} />
        {isTaxinvoice && <Column dataField={'CostCenterName'} caption={'사업소명'} width={'100'} />}
        {isTaxinvoice && <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={'110'} />}
        <Column dataField={'SalesDate'} caption={'매출일'} customizeText={customizeText} width={'160'} />
        <Column
          dataField={'StatusName'}
          caption={'매출상태'}
          width={'100'}
          cellRender={isSalesPayment ? editCellRender : ''}
        />

        <Column dataField={'EtcSalesTypeName'} caption={'매출 상세 유형'} width={'100'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} format="#,##0 원" width={'150'} />
        <Column dataField={'CorpRegNumber'} caption={'사업자번호'} width={'100'} />
        <Column dataField={'PaymentTypeName'} caption={'결제유형'} width={'100'} />
        <Column dataField={'VehicleRegistrationNo'} caption={'시간권차량번호'} width={100} />
        <Column dataField={'Remark'} caption={'비고'} width={'100'} />
        <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} width={'150'} />
        <Summary>
          <TotalItem showInColumn="CustomerCorpName" displayFormat="선택 합계:" />
          <TotalItem customizeText={customizeSelectTotal} column="SalesPrice" showInColumn="CustomerCorpName" />
          <TotalItem column="SalesPrice" summaryType="count" displayFormat="총 {0}건" showInColumn="SalesDate" />
          <TotalItem
            column="SalesPrice"
            summaryType="sum"
            valueFormat="#,##0 원"
            displayFormat="{0}"
            showInColumn="SalesDate"
          />
        </Summary>
      </CommonDataGrid>
    </>
  );
};

export default React.memo(EtcSales);
