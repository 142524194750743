import React, { useCallback, useState, useEffect, useRef } from 'react';
import Form, { SimpleItem, GroupItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import { salesPayment, error } from 'api';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';

const CancelEditForm = props => {
  const { refresh, setPopup, editRowdata, refundReason, refundPaymentType, setLoadPanelVisible } = props;
  const [isCash, setIsCash] = useState(false);
  const [formData, setFormData] = useState({
    Idx: '',
    CostCenterCode: '',
    CostCenterName: '',
    SalesType: '',
    SalesKeyID: '',
    CardAgencyListName: '',
    IssueCreditCard: '',
    CreditCardApprovalNo: '',
    TransactionDate: '',
    SalesPrice: '',
    RefundPaymentType: '',
    RefundStatusName: '',
    CustomerName: '',
    TelephoneNo: '',
    VehicleRegistrationNo: '',
    RefundPrice: '',
    RefundReason: '',
    BankName: '',
    BankAccountNo: '',
    AccountHolder: '',
    CustomerInfo: '',
    Remark: '',
    RegisterUser: '',

    // 참고 정보
    ApprovalDate: '',
    ApprovedDate: '',
  });
  const cancelTicketFormRef = useRef({});

  useEffect(() => {
    if (Object.keys(editRowdata).length !== 0) {
      editRowdata.RefundPaymentType === 'Cash' ? setIsCash(true) : setIsCash(false);
      setFormData({ ...editRowdata });
    }
  }, [editRowdata]);
  const onClose = () => {
    setPopup({ save: true, visible: false });
  };
  const onClickRefundPaymentType = e => {
    e.itemData.value === 'Cash' ? setIsCash(true) : setIsCash(false);
  };
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        Idx,
        SalesKeyID,
        CardAgencyListName,
        IssueCreditCard,
        CreditCardApprovalNo,
        TransactionDate,
        SalesPrice,
        RefundPaymentType,
        CustomerName,
        TelephoneNo,
        VehicleRegistrationNo,
        RefundPrice,
        RefundReason,
        BankName,
        BankAccountNo,
        AccountHolder,
        CustomerInfo,

        Remark,
        RegisterUser,
      } = formData;
      setLoadPanelVisible(true);
      await salesPayment
        .updateRefundInfo({
          Idx,
          SalesKeyID,
          CardAgencyListName,
          IssueCreditCard,
          CreditCardApprovalNo,
          TransactionDate: moment(TransactionDate).format('YYYY-MM-DD'),
          SalesPrice,
          RefundPaymentType,
          CustomerName,
          TelephoneNo,
          VehicleRegistrationNo,
          RefundPrice,
          RefundReason,
          BankName,
          BankAccountNo,
          AccountHolder,
          CustomerInfo,
          Remark,
          RegisterUser,
        })

        .then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '수정되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            onClose();
            refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
    },
    [formData],
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="">
          <Form
            ref={cancelTicketFormRef}
            formData={formData}
            labelLocation="left"
            labelMode="outside"
            optionalMark="optional"
            stylingMode="outlined"
          >
            <ButtonItem>
              <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
            </ButtonItem>
            <GroupItem colCount={isCash ? 3 : 2}>
              {/* 매출정보 */}
              <GroupItem>
                <GroupItem caption="매출 정보">
                  <SimpleItem dataField="CostCenterName" editorOptions={{ readOnly: true }}>
                    <Label text="사업소명" />
                  </SimpleItem>
                  {formData.SalesPrice !== '' && formData.SalesPrice !== null ? (
                    <SimpleItem dataField="SalesPrice" editorOptions={{ readOnly: true }}>
                      <Label text="결제금액" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  ) : null}
                  {formData.IssueCreditCard !== '' && formData.IssueCreditCard !== null ? (
                    <SimpleItem dataField="IssueCreditCard">
                      <Label text="카드사" />
                    </SimpleItem>
                  ) : null}
                  <SimpleItem
                    dataField="CardAgencyListName"
                    editorType="dxSelectBox"
                    editorOptions={{ items: ['PG', 'VAN', 'CMS'] }}
                  >
                    <Label text="결제사" />
                  </SimpleItem>
                  {formData.CreditCardApprovalNo !== '' && formData.CreditCardApprovalNo !== null ? (
                    <SimpleItem dataField="CreditCardApprovalNo" editorOptions={{ readOnly: true }}>
                      <Label text="카드승인번호" />
                    </SimpleItem>
                  ) : null}
                  {formData.ApprovalDate !== '' ||
                  formData.ApprovalDate !== null ||
                  formData.ApprovedDate !== '' ||
                  formData.ApprovedDate !== null ? (
                    <SimpleItem
                      dataField="TransactionDate"
                      editorType="dxDateBox"
                      editorOptions={{
                        type: 'date',
                        dropDownButtonTemplate: 'dropDownButton',
                        displayFormat: 'yyyy-MM-dd',
                        icon: true,
                      }}
                    >
                      <Label text="결제일자" />
                      <RequiredRule message="필수값 입니다." />
                    </SimpleItem>
                  ) : null}
                  <SimpleItem dataField="CostCenterCode" visible={false} />
                  <SimpleItem dataField="SalesType" visible={false} />
                  <SimpleItem dataField="SalesKeyID" visible={false} />
                  <SimpleItem dataField="Idx" visible={false} />
                </GroupItem>
              </GroupItem>
              {/* 환불정보 */}
              <GroupItem>
                <GroupItem caption="환불 정보">
                  <SimpleItem
                    dataField="RefundPaymentType"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: refundPaymentType,
                      placeholder: '환불 유형을 선택해주세요',
                      valueExpr: 'value',
                      displayExpr: 'id',
                      onItemClick: onClickRefundPaymentType,
                    }}
                  >
                    <Label text="환불수단" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem dataField="CustomerName">
                    <Label text="고객명" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem dataField="TelephoneNo">
                    <Label text="고객연락처" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem dataField="VehicleRegistrationNo">
                    <Label text="차량번호" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem dataField="RefundPrice" editorType="dxNumberBox">
                    <Label text="환불금액" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem
                    dataField="RefundReason"
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: refundReason,
                      placeholder: '환불 사유를 선택해주세요',
                      valueExpr: 'value',
                      displayExpr: 'id',
                    }}
                  >
                    <Label text="환불사유" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                  <SimpleItem dataField="Remark" editorType="dxTextArea">
                    <Label text="비고" />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              {/* 계좌정보 */}
              {isCash && (
                <GroupItem>
                  <GroupItem caption="계좌 정보">
                    <SimpleItem dataField="BankName">
                      <Label text="은행명" />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="BankAccountNo">
                      <Label text="입금계좌" />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="AccountHolder">
                      <Label text="예금주" />
                      {isCash && <RequiredRule message="필수값 입니다." />}
                    </SimpleItem>
                    <SimpleItem dataField="CustomerInfo">
                      <Label text="고객과의 관계" />
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
              )}
            </GroupItem>
            <GroupItem>
              <ButtonItem>
                <ButtonOptions type={'default'} useSubmitBehavior={true} text="수정"></ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </div>
      </form>
    </>
  );
};

export default CancelEditForm;
