import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import {
  Column,
  Selection,
  HeaderFilter,
  Scrolling,
  Summary,
  TotalItem,
  Button as GridButton,
  Editing,
  Paging,
} from 'devextreme-react/data-grid';
import { salesPayment } from 'api';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import DetailHistoryToCancelView from './DetailHistoryToCancelView';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from './index';
import moment from 'moment-timezone';
import '../../../tmp/tmp.scss';

/*

1. isCancelSalesConfirm = 매출 취소 확정
2. isInputSalesCancel = 매출 수기 등록 (2022 매출 취소)

*/
const CancelConfirmTicket = props => {
  const {
    setCRowdata,
    cancelTicketRef,
    searchData,
    setPopup,
    setEditRowdata,
    headerBefore,
    headerAfter,
    height,
    isHistory,
    isColor,
    isNotSearch,
    isCancelSalesConfirm,
    isInputSalesCancel,
    setLoadPanelVisible,
  } = props;
  const detailHistoryViewRef = useRef({});
  // popup
  const [isOpenTaxDetail, setIsOpenTaxDetail] = useState(false);

  const onSelectionChanged = e => {
    const selectedRows = e.selectedRowsData;
    if (!isInputSalesCancel) {
      setCRowdata(selectedRows);
    }
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'Idx',
      loadMode: 'raw',
      load: async () => {
        if (searchData.FromDate !== null && searchData.FromDate !== '') {
          const rawResult = await salesPayment.getRefundInfo({ ...searchData });
          const utcToKst = utc => {
            const utcDate = moment.utc(utc);
            const kstDate = utcDate.tz('Asia/Seoul');
            return kstDate;
          };
          const result = rawResult.data.map(a => {
            const transactionDate =
              a.TransactionDate === null ? a.TransactionDate : utcToKst(a.TransactionDate).format('YYYY-MM-DD');
            const approvalDate =
              a.ApprovalDate === null ? a.ApprovalDate : utcToKst(a.ApprovalDate).format('YYYY-MM-DD');
            const confirmDate = a.ConfirmDate === null ? a.ConfirmDate : utcToKst(a.ConfirmDate).format('YYYY-MM-DD');
            const registerDate =
              a.RegisterDate === null ? a.RegisterDate : utcToKst(a.RegisterDate).format('YYYY-MM-DD HH:mm:ss');
            return {
              ...a,
              TransactionDate: transactionDate,
              ApprovalDate: approvalDate,
              ConfirmDate: confirmDate,
              RegisterDate: registerDate,
            };
          });
          return result;
        } else {
          return [];
        }
      },
    });
    return customDataSource;
  }, [searchData, isNotSearch]);

  const onCellPrepared = useCallback(
    ({ data, cellElement }) => {
      if (isColor) {
        if (data) {
          const { RefundStatusName } = data;
          const tr = cellElement;
          if (RefundStatusName && RefundStatusName.trim() === '확정' && tr) {
            tr.classList.add('sales-confirm');
          } else if (RefundStatusName === '반려' && tr) {
            tr.classList.add('sales-cancel');
          }
        }
      }
    },
    [isColor],
  );

  const originTaxCellRender = useCallback(e => {
    const data = e.data;
    if (data.SalesType === 'TaxInvoice') {
      return (
        <Button
          icon="search"
          width={50}
          type="normal"
          stylingMode="contained"
          onClick={() => {
            detailHistoryViewRef.current.open(data.SalesKeyID);
          }}
        />
      );
    }
  }, []);

  const refundTaxCellRender = useCallback(e => {
    const data = e.data;
    if (data.SalesType === 'TaxInvoice') {
      return (
        <Button
          icon="search"
          width={50}
          type="normal"
          stylingMode="contained"
          onClick={() => {
            detailHistoryViewRef.current.open(data.RefundID);
          }}
        />
      );
    }
  }, []);

  const allowUpdating = e => {
    const isEditable = e.row?.data?.IsEditable === 'Y';
    return isEditable;
  };

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsSummary') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      const isRowSelected = options.component.isRowSelected(options.value?.Idx);
      if (options.summaryProcess === 'calculate' && isRowSelected) {
        options.totalValue += options.value.RefundPrice;
      }
    }
    if (options.name === 'SelectedRowsCount') {
      console.log(options);
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      const isRowSelected = options.component.isRowSelected(options.value?.Idx);
      if (options.summaryProcess === 'calculate' && isRowSelected) {
        options.totalValue += 1;
      }
    }
  };

  const formatCurrency = value => {
    return value.toLocaleString('ko-KR') + '원';
  };
  const formatCount = value => {
    return value + '건';
  };

  const customizeSelectCount = e => {
    const result = cancelTicketRef.current.instance.getSelectedRowsData();
    const formattedTotal = '선택건수 ' + result.length + ' 건';
    return formattedTotal;
  };
  const customizeSelectTotal = e => {
    const result = cancelTicketRef.current.instance.getSelectedRowsData();
    const sum = result.reduce((total, item) => total + item.RefundPrice, 0);
    const formattedTotal = '선택합계 ' + sum.toLocaleString() + ' 원';
    return formattedTotal;
  };
  return (
    <>
      <CommonDataGrid
        gridRef={cancelTicketRef}
        className={'dx-card wide-card sales-confirm-ticket'}
        dataSource={dataSource}
        showBorders={true}
        remoteOperations={false}
        repaintChangesOnly={true}
        columnAutoWidth={true}
        columnHidingEnabled={false}
        loadPanel={{ enabled: false }}
        hoverStateEnabled={true}
        height={height || 700}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: '매출취소확정.xlsx',
          customizeCell: { date: ['ApprovalDate', 'TransactionDate', 'RegisterDate'] },
        }}
        headerBefore={headerBefore}
        headerAfter={headerAfter}
        allowColumnReordering={true}
        onCellPrepared={onCellPrepared}
        // onContentReady={handleContentReady}
      >
        <Editing mode="row" useIcons={true} allowUpdating={allowUpdating} />
        <Paging enabled={true} defaultPageSize={100} />
        <Scrolling mode="standard" showScrollbar="always" />
        <Selection mode="multiple" showCheckBoxesMode="onClick" />
        <HeaderFilter visible={true} />
        <Column dataField={'Idx'} caption={'ID'} width={60} dataType="string" />
        {isCancelSalesConfirm && <Column dataField={'CostCenterCode'} width={80} caption={'P코드'} dataType="string" />}
        <Column dataField={'CostCenterName'} caption={'사업소명'} width={120} dataType="string" />
        <Column dataField={'RefundStatusName'} caption={'처리상태'} width={90} dataType="string" />
        <Column dataField={'SalesTypeName'} caption={'매출유형'} width={80} dataType="string" />
        <Column dataField={'VehicleRegistrationNo'} caption={'차량번호'} width={100} dataType="string" />
        <Column dataField={'CustomerName'} caption={'고객명'} width={100} dataType="string" />
        <Column dataField={'TransactionDate'} caption={'결제일'} width={100} dataType="date" />
        <Column
          dataField={'SalesPrice'}
          caption={'매출금액'}
          format="#,##0 원"
          width={200}
          dataType="string"
          alignment="right"
        />
        <Column
          dataField={'RefundPrice'}
          caption={'환불금액'}
          format="#,##0 원"
          alignment="right"
          width={200}
          dataType="string"
        />
        <Column dataField={'CreditCardApprovalNo'} caption={'카드승인번호'} width={100} dataType="string" />
        <Column dataField={'CardAgencyListName'} caption={'결제사'} width={80} dataType="string" />
        <Column dataField={'TerminalID'} caption={'단말기ID'} width={100} dataType="string" />
        <Column dataField={'IssueCreditCard'} caption={'카드사'} width={100} dataType="string" />
        <Column dataField={'RegisterUser'} caption={'접수자'} width={80} dataType="string" />
        <Column dataField={'RegisterDate'} caption={'접수일'} width={100} dataType="date" />
        <Column dataField={'RefundPaymentTypeName'} caption={'환불수단'} width={90} dataType="string" />
        <Column dataField={'RefundReasonName'} caption={'환불사유'} width={100} dataType="string" />
        <Column dataField={'Remark'} caption={'비고'} width={200} dataType="string" />
        <Column dataField={'SalesPeriod'} caption={'매출대상기간'} width={180} dataType="string" />
        <Column dataField={'CancelPeriod'} caption={'환불대상기간'} width={180} dataType="string" />
        <Column
          dataField={'originTax'}
          dataType="string"
          caption={'원세금계산서'}
          width={30}
          cellRender={originTaxCellRender}
          cssClass="cell-remove-padding"
        />
        <Column
          dataField={'refundTax'}
          dataType="string"
          caption={'환불세금계산서'}
          width={30}
          cellRender={refundTaxCellRender}
          cssClass="cell-remove-padding"
        />
        <Column dataField={'RefundID'} caption={'환불세금계산서번호'} width={140} dataType="string" />
        <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} dataType="string" />
        <Column dataField={'ConfirmDate'} caption={'확정대상일'} width={100} dataType="string" />
        <Column dataField={'ApprovalDate'} caption={'확정/반려일'} width={100} dataType="string" />
        <Column dataField={'ApprovalUser'} caption={'승인자'} width={100} dataType="string" />
        <Column dataField={'ApprovalComment'} caption={'승인메세지'} width={100} dataType="string" />
        <Column dataField={'BankName'} caption={'은행명'} width={100} dataType="string" />
        <Column dataField={'BankAccountNo'} caption={'입금계좌'} width={100} dataType="string" />
        <Column dataField={'AccountHolder'} caption={'예금주'} width={100} dataType="string" />
        <Column dataField={'CustomerInfo'} caption={'고객과의 관계'} width={100} dataType="string" />
        <Column dataField={'TelephoneNo'} caption={'고객연락처'} width={100} dataType="string" />
        {/* <Column dataField={'AdminRemark'} caption={'관리자비고'} width={100} dataType="string" /> */}
        <Summary calculateCustomSummary={calculateSelectedRow}>
          <TotalItem customizeText={customizeSelectCount} column="RefundPrice" showInColumn="RefundPrice" />
          <TotalItem customizeText={customizeSelectTotal} column="RefundPrice" showInColumn="RefundPrice" />
          <TotalItem
            summaryType="count"
            valueFormat={formatCount}
            displayFormat="조회건수: {0}"
            showInColumn="SalesPrice"
          />
          <TotalItem
            column="RefundPrice"
            summaryType="sum"
            valueFormat={formatCurrency}
            displayFormat="조회합계: {0}"
            showInColumn="SalesPrice"
          />
        </Summary>
      </CommonDataGrid>
      <Popup visible={isOpenTaxDetail} showCloseButton={true} showTitle={false} width="50%" height={890}>
        <ScrollView>
          <div className={'dx-card responsive-paddings'} style={{ padding: '0px 20px' }}>
            <DetailHistoryToCancelView
              ref={detailHistoryViewRef}
              setIsOpen={setIsOpenTaxDetail}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </ScrollView>
      </Popup>
    </>
  );
};

export default React.memo(CancelConfirmTicket);
