import React, { useImperativeHandle } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import DataGrid, { FilterRow, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Button } from 'devextreme-react/button';

const CommonDataGrid = React.forwardRef((props, ref) => {
  const {
    className,
    dataSource,
    children,
    isHeader,
    headerBefore,
    headerAfter,
    gridRef,
    isExcel,
    excelOptions,
    isNotFilterRow,
    isFilterSelection,
    isInfiniteScroll,
    ...options
  } = props;
  // const [headerText, setHeaderText] = useState([]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  // useEffect(() => {
  //   result(gridRef);
  // }, [gridRef]);

  // const result = () => {
  //   if (gridRef && gridRef.current.instance) {
  //     const tmp = gridRef.current.instance.getVisibleColumns();

  //     const excelFields = tmp
  //       .map(a => {
  //         return {
  //           key: a.dataField,
  //           label: a.caption,
  //         };
  //       })
  //       .filter(e => e.key !== undefined);
  //     setHeaderText(excelFields);
  //   }
  // };

  const onExporting = e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelOptions.sheetName);
    exportDataGrid({
      component: gridRef.current.instance,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (
          excelOptions &&
          excelOptions.customizeCell &&
          excelOptions.customizeCell.date &&
          excelOptions.customizeCell.date.length > 0
        ) {
          if (gridCell.rowType === 'data') {
            excelOptions.customizeCell.date.forEach(column => {
              if (gridCell.column.dataField === column) {
                excelCell.value = gridCell.value === null ? '' : moment(gridCell.value).format('YYYY-MM-DD HH:mm:ss');
              }
            });

            // 엑셀 파싱하고 싶은 것들 여기 아래 붙여넣기
          }
        }
      },
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), excelOptions.fileName);
      });
    });
    // e.cancel = true;
  };

  const onOptionChanged = e => {
    if (isFilterSelection && e.name === 'columns' && e.value) {
      if (typeof e.value === 'object') {
        (async () => {
          await gridRef.current.instance.clearSelection();
        })();
      }
    }
  };

  return (
    <div>
      {isHeader && (
        <Toolbar>
          {headerBefore.map((content, ind) => (
            <Item location={'before'} key={ind}>
              {content}
            </Item>
          ))}
          {headerAfter.map((content, ind) => (
            <Item location={'after'} key={ind}>
              {content}
            </Item>
          ))}
          {isExcel && (
            <Item location={'after'}>
              {
                <Button icon="exportxlsx" onClick={onExporting} stylingMode="text" />
                // <ExcelExport
                //   buttonName={excelOptions.buttonName}
                //   sheetName={excelOptions.sheetName}
                //   fileName={excelOptions.fileName}
                //   excelFields={headerText}
                //   excelData={dataSource}
                // />
              }
            </Item>
          )}
        </Toolbar>
      )}
      <DataGrid
        // onContentReady={}
        ref={gridRef}
        className={className}
        dataSource={dataSource}
        {...options}
        allowColumnResizing={true}
        onOptionChanged={onOptionChanged}
        allowColumnReordering={true}
      >
        {!isNotFilterRow && <FilterRow visible={true} />}
        {isInfiniteScroll && <Scrolling mode="infinite" />}
        <HeaderFilter visible={true} />
        {children}
      </DataGrid>
    </div>
  );
});

CommonDataGrid.defaultProps = {
  isInfiniteScroll: true,
  isHeader: false,
  headerBefore: [],
  headerAfter: [],
  isExcel: true,
  excelOptions: {
    buttonName: null,
    sheetName: 'Sheet',
    fileName: 'sample.xlsx',
    customizeCell: { date: [] },
  },
};

CommonDataGrid.propTypes = {
  isInfiniteScroll: PropTypes.bool,
  isHeader: PropTypes.bool,
  headerBefore: PropTypes.oneOfType([PropTypes.array]),
  headerAfter: PropTypes.oneOfType([PropTypes.array]),
  isExcel: PropTypes.bool,
};

export default React.memo(CommonDataGrid);
