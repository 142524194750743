import React, { useMemo, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

const userId = localStorage.getItem('userId');

const PgDetailGrid = props => {
  const [uniqueKey, setUniqueKey] = useState(null);
  const gridRef = useRef({});
  useEffect(() => {
    const { key } = props.data;
    setUniqueKey(key);
  }, [props]);

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        try {
          const { CostCenterCode, CardType, ApprovalType, TransactionDate } = props.data.data;
          const result = await axios.post(`aggregation/getPGAggregationDataByCostCenterDayDetail`, {
            CostCenterCode,
            CardType,
            ApprovalType,
            TransactionDate,
            LoginID: userId,
          });
          return result.data;
        } catch (e) {
          const { type, title, status, detail, instance } = e.response.data;
          throw Error(JSON.stringify({ type, title, status, detail, instance }));
        }
      },
    });

    return customDataSource;
  }, [uniqueKey]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        isHeader={true}
        isExcel={true}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'PG사 매출 데이터 상세.xlsx',
        }}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={'TransactionDate'} caption={'거래일'} customizeText={customizeText} />
        <Column dataField={'TransactionID'} caption={'거래ID'} />
        <Column dataField={'CardTypeName'} caption={'카드유형'} />
        <Column dataField={'TerminalID'} caption={'단말기ID'} />
        <Column dataField={'ApprovalTypeName'} caption={'승인유형'} />
        <Column dataField={'ApprovalNo'} caption={'승인번호'} />
        <Column dataField={'OrderID'} caption={'주문ID'} />
        <Column dataField={'SalesPrice'} caption={'매출금액'} alignment="left" customizeText={customizeText} />
        <Column dataField={'MonthlyInstallmentsType'} caption={'설치유형(월)'} />
        <Column dataField={'CreditCardNo'} caption={'신용카드번호'} />
        <Column dataField={'Remark1'} caption={'비고1'} />
        <Column dataField={'Remark2'} caption={'비고2'} />
      </CommonDataGrid>
    </>
  );
};

export default PgDetailGrid;
