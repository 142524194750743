import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Column, Selection, Scrolling, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { confirm } from 'devextreme/ui/dialog';
import { ticket, error } from 'api';
import { sortByKor } from 'utils/common';
import { seasonticketMemberMngManualLink } from 'constants/hyperlink';
import { CheckBox } from 'devextreme-react/check-box';
import SeasonTicketMemberForm from './components/season-ticket-member-form';
import SeasonTicketMemberGroupForm from './components/season-ticket-member-group-form';
import ScrollView from 'devextreme-react/scroll-view';
import CommonDataGrid from 'common/default-data-grid';
import notify from 'devextreme/ui/notify';
import './season-ticket-member-mng.scss';
import 'devextreme-react/text-area';

const SeasonTicketMemberMng = () => {
  const [text, setText] = useState('');
  const [selectItemCode, setSelectItemCode] = useState({
    CostCenterCode: '',
    CostCenterName: '',
  });
  const [costcenterParam, setCostcenterParam] = useState({
    SearchText: '',
    MemberText: '',
  });
  const [costcenters, setCostcenters] = useState();
  const [memberParam, setMemberParam] = useState({
    CostCenter: '',
    MemberText: '',
    SearchGroupCode: '*',
  });
  const [members, setMembers] = useState();
  const [formData, setFormData] = useState({
    CarType: '',
    CostCenterCode: '',
    CostCenterName: '',
    EmailAddress: '',
    GroupCode: '',
    GroupName: '',
    MemberDivision: '',
    MemberDivisionName: '',
    MemberID: '',
    MemberName: '',
    MemberStatusName: '',
    MemberType: '',
    MemberTypeName: '',
    MobliePhoneNo: '',
    RegisterDate: '',
    RegisterUser: '',
    Status: '',
    TagRentPrice: 0,
    TelephoneNo: '',
    UseTag: '',
    UseTagName: '',
    VehicleRegistration: '',
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const [withTerminateCheck, setWithTerminateCheck] = useState(true);
  const [popup, setPopup] = useState({
    save: false,
    visible: false,
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [memberText, setMemberText] = useState('');
  const [groupPopup, setGroupPopup] = useState({
    visible: false,
  });

  // 회원 그룹 items 추가
  const [groupType, setGroupType] = useState([]);
  const [groupTypeItems, setGroupTypeItems] = useState([]);
  const [isGroupItemsUpdate, setGroupItemsUpdate] = useState(false);
  const gridRef = useRef({});

  // selectBox ref
  const selectRef = useRef({});
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  useEffect(() => {
    (async () => {
      const result = await ticket.searchParkingInfoByCostCenterWithTicket(costcenterParam);
      if (result.isOk) {
        setCostcenters(result.data);
        setMemberParam({
          ...memberParam,
          CostCenter: result.data.length > 0 ? result.data[0].CostCenterCode : '',
          MemberText: memberText.trim(),
        });
        setSelectItemCode({
          CostCenterCode: result.data.length > 0 ? result.data[0].CostCenterCode : '',
          CostCenterName: result.data.length > 0 ? result.data[0].CostCenterName : '',
        });
      }
    })();
  }, [costcenterParam]);

  useEffect(() => {
    if (memberParam.CostCenter) {
      (async () => {
        await ticket
          .searchSeasonTicketMemberGroup({
            CostCenterCode: selectItemCode.CostCenterCode,
          })
          .then(res => {
            if (res.isOk) {
              setGroupType(res.data);
              const groupFree = { codeDesc: '(그룹없음)', code: '' };
              const group = res.data.map(a => {
                return {
                  codeDesc: a.GroupName,
                  code: a.GroupCode,
                };
              });
              const rawData = [groupFree, ...group];
              // const sortedRawData = sortByKor(rawData, 'codeDesc');
              const result = [
                {
                  key: '전체',
                  items: [{ code: '*', codeDesc: '전체' }],
                },
                {
                  key: '회원그룹',
                  items: sortByKor(rawData, 'codeDesc'),
                },
              ];
              setGroupTypeItems(result);
            }
          });
      })();
    } else {
      setGroupType([]);
      setGroupTypeItems([]);
    }
  }, [selectItemCode, isGroupItemsUpdate]);

  useEffect(() => {
    if (memberParam.CostCenter) {
      (async () => {
        await ticket.searchSeasonTicketMemberMaster({ ...memberParam, WithTerminate: withTerminateCheck }).then(res => {
          if (res.isOk) {
            setMembers(res.data);
          }
        });
      })();
    } else {
      setMembers([]);
    }
  }, [memberParam, isRefresh, withTerminateCheck]);
  const onValueChanged = e => {
    setText(e.value);
  };

  const onMemberValueChanged = e => {
    setMemberText(e.value);
  };

  const calculateSelectedRow = options => {
    if (options.name === 'SelectedRowsCount') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (
          options.component.isRowSelected(options.value.MemberID) ||
          options.component.isRowSelected(options.value.TicketID)
        ) {
          options.totalValue += 1;
        }
      }
    }
  };

  const onSearch = () => {
    setCostcenterParam({
      ...costcenterParam,
      SearchText: text.trim(),
      MemberText: memberText.trim(),
    });
  };

  const onSelectionChanged = e => {
    if (e.selectedRowsData.length > 0) {
      setSelectItemCode({
        CostCenterCode: e.selectedRowsData[0].CostCenterCode,
        CostCenterName: e.selectedRowsData[0].CostCenterName,
      });
      setMemberParam({
        ...memberParam,
        CostCenter: e.selectedRowsData[0].CostCenterCode,
        MemberText: memberText.trim(),
      });
      selectRef.current.instance.reset();
    }
  };

  const showPopup = (type, data) => {
    setIsUpdate(type === 'update' ? true : false);
    setFormData({ ...data, CustomerCorpRegNumber: data.CorpRegNumber });
    setPopup({ save: false, visible: !popup.visible });
  };

  const showGroupPopup = () => {
    setGroupPopup({ visible: !popup.visible });
  };

  const editCellRender = e => {
    return (
      <Button
        icon="edit"
        width={50}
        // text="수정"
        type="normal"
        stylingMode="contained"
        onClick={() => showPopup('update', e.data)}
      />
    );
  };

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD');
  };

  const onClose = () => {
    setGroupPopup({ visible: false });
    setPopup({ ...popup, visible: false });
  };

  const onClickButton = e => {
    const { code } = e.itemData;
    setMemberParam({
      ...memberParam,
      SearchGroupCode: code,
    });
  };

  const onDelete = useCallback(async () => {
    const selectedItems = gridRef.current.instance.getSelectedRowsData();
    if (selectedItems.length === 0) {
      alert('삭제할 회원을 선택해주세요.');
    } else {
      const result = confirm('<i>삭제하시겠습니까?</i>', '삭제');
      result.then(dialogResult => {
        if (dialogResult) {
          setLoadPanelVisible(true);
          const p_MemberIDs = selectedItems.map(s => s.MemberID);
          ticket
            .deleteSeasonticketMember({
              MemberID: p_MemberIDs.join(','),
            })
            .then(res => {
              if (res.isOk) {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: '삭제 되었습니다.',
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'success',
                );
                setIsRefresh(!isRefresh);
              } else {
                setLoadPanelVisible(false);
                notify(
                  {
                    message: error.errorMsgCheck(res.error.detail),
                    width: 230,
                    position: {
                      at: 'top',
                      my: 'top',
                      of: '#container',
                    },
                  },
                  'error',
                );
                setIsRefresh(!isRefresh);
              }
            });
        }
      });
    }
  }, [isRefresh]);

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onCheckValueChanged = e => {
    setWithTerminateCheck(e.value);
  };
  return (
    <>
      <div>
        <div className={'content-block detail-title left-right-header'}>
          <div>
            <h2>정기권 회원 관리[ {selectItemCode.CostCenterName} ]</h2>
          </div>
          <div>
            <a
              style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
              href={seasonticketMemberMngManualLink}
              target="blank"
            >
              메뉴얼 바로가기
            </a>
            <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
          </div>
        </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <CommonDataGrid
              // gridRef={pgvanRef} 엑셀 추가시 사용
              className={'dx-card wide-card'}
              dataSource={costcenters}
              selectedRowKeys={[memberParam.CostCenter]}
              showBorders={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              hoverStateEnabled={true}
              onSelectionChanged={onSelectionChanged}
              keyExpr="CostCenterCode"
              allowColumnReordering={true}
              isHeader={true}
              isExcel={false}
              height={190}
              headerBefore={[
                <TextBox
                  stylingMode="outlined"
                  mode="search"
                  placeholder="P코드/사업소명"
                  onValueChanged={onValueChanged}
                  onEnterKey={onSearch}
                  width={220}
                />,
                <TextBox
                  placeholder="차량번호/회원명/휴대전화"
                  stylingMode="outlined"
                  mode="search"
                  onValueChanged={onMemberValueChanged}
                  onEnterKey={onSearch}
                  width={220}
                />,
              ]}
              headerAfter={[
                <Button
                  icon="search"
                  text="조회"
                  width={120}
                  type="normal"
                  stylingMode="contained"
                  onClick={onSearch}
                />,
              ]}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
              <Selection mode="single" />
              <HeaderFilter visible={true} />
              <Column dataField={'HiParkingID'} caption={'P코드'} width={200} hidingPriority={3} />
              <Column dataField={'CostCenterName'} caption={'사업소명'} width={200} hidingPriority={4} />
              <Column dataField={'OperatingTypeName'} caption={'운영유형'} width={100} hidingPriority={2} />
              <Column dataField={'TotalCnt'} caption={'총 회원'} hidingPriority={1} />
              <Column dataField={'NoGroupCnt'} caption={'개인회원'} hidingPriority={1} />
              <Column dataField={'GroupCnt'} caption={'그룹회원'} hidingPriority={1} />
            </CommonDataGrid>
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card minimal-padding'}
              dataSource={members}
              showBorders={true}
              columnAutoWidth={true}
              columnHidingEnabled={false}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              height={420}
              isHeader={true}
              isExcel={true}
              excelOptions={{
                sheetName: 'sheet',
                fileName: '정기권 회원정보.xlsx',
              }}
              headerBefore={[
                <SelectBox
                  ref={selectRef}
                  labelMode="static"
                  placeholder="회원그룹을 선택해주세요."
                  dropDownButtonTemplate="dropDownButton"
                  stylingMode="outlined"
                  dataSource={groupTypeItems}
                  onItemClick={onClickButton}
                  valueExpr="code"
                  displayExpr="codeDesc"
                  width={250}
                  grouped={true}
                />,
                <span />,
                <CheckBox defaultValue={true} text="종료회원 포함" onValueChanged={onCheckValueChanged} />,
              ]}
              headerAfter={[
                <Button
                  icon="plus"
                  text="정기권 그룹 설정"
                  width={160}
                  type="normal"
                  stylingMode="contained"
                  onClick={() => {
                    showGroupPopup();
                  }}
                />,
                <Button
                  icon="plus"
                  text="정기권 회원 추가"
                  width={160}
                  type="normal"
                  stylingMode="contained"
                  onClick={() => {
                    showPopup('add', {
                      CostCenterName: selectItemCode.CostCenterName,
                      UseTag: 'N',
                      Status: 'A',
                      MemberType: 'A',
                      DefaultTradeUsage: 'A',
                      UseDefaultInfo: { data: { 0: false } },
                    });
                  }}
                />,
                <Button type="normal" text="회원 삭제" stylingMode="contained" onClick={onDelete} />,
                <Button
                  icon="refresh"
                  type="normal"
                  stylingMode="contained"
                  onClick={() => {
                    setIsRefresh(!isRefresh);
                  }}
                />,
              ]}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" showScrollbar="always" />
              <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
              <HeaderFilter visible={true} />
              <Column
                dataField={' '}
                width={45}
                cellRender={editCellRender}
                allowExporting={false}
                allowResizing={false}
                allowFiltering={false}
                allowSorting={false}
              />
              <Column dataField={'MemberStatusName'} caption={'회원상태'} width={90} />
              <Column dataField={'VehicleRegistration'} alignment="left" caption={'차량번호'} width={100} />
              <Column dataField={'MemberTypeName'} caption={'정기권유형'} width={100} />
              <Column
                dataField={'StandardPrice'}
                caption={'기준금액'}
                width={110}
                alignment={'right'}
                format="#,##0 원"
              />
              <Column dataField={'MemberDivisionName'} width={110} caption={'회원구분'} alignment="center" />
              <Column dataField={'MemberID'} width={110} caption={'회원ID'} visible={false} />
              <Column dataField={'MemberName'} width={150} caption={'회원명'} />
              <Column dataField={'CustomerCorpName'} caption={'개인 사업자명'} width={150} />
              <Column dataField={'GroupCode'} caption={'회원그룹 코드'} width={110} visible={false} />
              <Column dataField={'GroupCorpName'} caption={'그룹 사업자명'} width={150} />
              <Column
                dataField={'GroupStandardPrice'}
                caption={'그룹금액'}
                width={110}
                alignment={'left'}
                format="#,##0 원"
              />
              {/* <Column dataField={'UseTagName'} caption={'태그사용 유무'} width={120} />
              <Column
                dataField={'TagRentPrice'}
                caption={'태그비용'}
                alignment={'left'}
                width={110}
                format="#,##0 원"
              /> */}

              <Column dataField={'MobliePhoneNo'} caption={'핸드폰번호'} width={110} />
              <Column dataField={'TelephoneNo'} caption={'전화번호'} width={110} />
              <Column dataField={'CarType'} caption={'차종'} width={120} />
              <Column dataField={'EmailAddress'} caption={'이메일'} width={150} />

              <Column dataField={'UseDefaultInfoYN'} caption={'현금영수증 자동발행 여부'} width={160} />
              <Column dataField={'DefaultTradeUsageName'} caption={'거래구분(소득공제용/지출증빙용)'} width={170} />
              <Column dataField={'DefaultIdentifierNo'} caption={'현금영수증 식별번호'} width={130} />
              <Column dataField={'DefaultEmailAddress'} caption={'현금영수증 발행 이메일'} width={150} />
              <Column dataField={'UseAutoIssueYN'} caption={'정기권 자동발행결제 여부'} width={150} />
              <Column dataField={'AutoPaymentRequestDDay'} caption={'자동결제요청일(월말일기준D-Day)'} width={190} />

              <Column dataField={'RegisterUser'} caption={'등록자'} width={120} />
              <Column dataField={'RegisterDate'} caption={'등록일'} customizeText={customizeText} />
              <Summary calculateCustomSummary={calculateSelectedRow}>
                <TotalItem column="MemberStatusName" summaryType="count" displayFormat="총 {0}건" />
              </Summary>
            </CommonDataGrid>
          </div>
        </div>
        <Popup
          onHiding={onClose}
          visible={groupPopup.visible}
          showCloseButton={true}
          showTitle={false}
          width="45%"
          height={750}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <SeasonTicketMemberGroupForm
                setGroupPopup={setGroupPopup}
                setGroupItemsUpdate={setGroupItemsUpdate}
                selectItemCode={selectItemCode}
                groupType={groupType}
                groupPopup={groupPopup}
                isGroupItemsUpdate={isGroupItemsUpdate}
                setLoadPanelVisible={setLoadPanelVisible}
              />
            </div>
          </ScrollView>
        </Popup>
        <Popup
          onHiding={onClose}
          visible={popup.visible}
          showCloseButton={true}
          showTitle={false}
          width="40%"
          height={780}
        >
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <SeasonTicketMemberForm
              setPopup={setPopup}
              setIsRefresh={setIsRefresh}
              isUpdate={isUpdate}
              formData={formData}
              selectItemCode={selectItemCode}
              groupType={groupType}
              popup={popup}
              groupPopup={groupPopup}
              isRefresh={isRefresh}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </Popup>
      </div>
    </>
  );
};

export default SeasonTicketMemberMng;
