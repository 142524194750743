import React, { useRef, useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import CustomInfoPopup from 'pages/sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  EmptyItem,
  Item,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  CustomRule,
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';

import { aggregation, parking } from 'api';
import { salesPayment, error } from '../../../../api';
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { days } from 'constants/timeType';

import CostcenterMaster from 'common/popup/costcenter-master';
import ManagementNamePopup from './ManagementNamePopup';
import PreviewPopup from './Preiview';
import notify from 'devextreme/ui/notify';
import moment from 'moment';

/*
  Invoker : 'Create', 'Copy', 'Update'
*/

const AddRentalMaster = forwardRef((props, ref) => {
  const { refresh, setLoadPanelVisible } = props;

  const [selectedCostCenter, setSelectedCostCenter] = useState([]);
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);
  const [selectedManagementName, setSelectedManagementName] = useState([]);
  const [contractStatusType, setContractStatusType] = useState([]);
  const [managementNameType, setManagementNameType] = useState([]);

  const [isPopup, setIsPopup] = useState(false);
  const [isMerge, setIsMerge] = useState(false);
  const [isStop, setIsStop] = useState(false);

  const [formData, setFormData] = useState({});

  const costcenterMasterRef = useRef({});
  const customInfoRef = useRef({});
  const formRef = useRef({});
  const preViewRef = useRef({});
  const managementNameRef = useRef({});

  const [calculatedPrices, setCalculatedPrices] = useState({});
  const [isCalculated, setIsCalculated] = useState(false);

  useEffect(() => {
    if (selectedCostCenter.length > 0) {
      setFormData({
        ...formData,
        CostCenterCode: selectedCostCenter[0].CostCenterCode,
        CostCenterName: selectedCostCenter[0].CostCenterName,
      });
    } else {
      setFormData({ ...formData, CostCenterCode: '', CostCenterName: '' });
    }
  }, [selectedCostCenter]);

  useEffect(() => {
    if (selectedCustomInfo.length > 0) {
      setFormData({
        ...formData,
        CorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerName: selectedCustomInfo[0].CustomerCorpName,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
      });
    } else {
      setFormData({ ...formData, CorpRegNumber: '', CustomerName: '' });
    }
  }, [selectedCustomInfo]);

  useEffect(() => {
    if (selectedManagementName.length > 0) {
      setFormData({
        ...formData,
        ManagementName: selectedManagementName[0].ManagementName,
      });
    } else {
      setFormData({ ...formData, ManagementName: '' });
    }
  }, [selectedManagementName]);

  const getManagementNames = useCallback(async CostCenterCode => {
    const result = await aggregation.getRentalSalesMasterManagementName({
      CostCenterCode,
    });
    if (result.isOk) {
      setManagementNameType(result.data);
    } else {
      setManagementNameType([]);
    }
  }, []);

  useEffect(() => {
    getCodes();
    if (formRef.current && formData.ManagementID === '') {
      // 폼이 렌더링된 후 ManagementName 필드에 포커스
      // setTimeout(() => {
      //   const formInstance = formRef.current.instance;
      //   formInstance.getEditor('ManagementName')?.focus();
      // }, 0);
    }
  }, []);

  const getCodes = useCallback(async () => {
    const tmpContractStatusType = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['RentalStatus'],
    });
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'RentalStatus') {
          tmpContractStatusType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
      setContractStatusType(tmpContractStatusType);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const open = (selectData, Invoker) => {
    const defaultData = [];
    if (Invoker === 'Create') {
      defaultData.push({
        LastSeq: 0,
        StandardPriceDeviceRental: 0,
        StandardPriceConstruction: 0,
        StandardPriceMaintenance: 0,
        StandardPriceCallCenter: 0,
        IsStop: 0,
        ManagementName: '기본계산서',
        Invoker: Invoker,
        IsReadOnly: false,
      });
    } else {
      const { MergeInvoiceYN, CostCenterCode, ContractStatus, IsStop, ManagementName } = selectData[0];
      getManagementNames(CostCenterCode);
      MergeInvoiceYN && setIsMerge(true);
      IsStop && setIsStop(true);
      if (ContractStatus === 'D') {
        Invoker === 'Update' && defaultData.push({ ...selectData[0], Invoker, IsReadOnly: true });
        Invoker === 'Copy' && defaultData.push({ ...selectData[0], Invoker, IsReadOnly: false, ContractStatus: '' });
      } else {
        defaultData.push({
          ...selectData[0],
          Invoker,
          IsReadOnly: false,
          OriginManagementName: ManagementName,
          OriginMergeInvoiceYN: MergeInvoiceYN,
        });
      }
    }
    setFormData(defaultData[0]);
    setIsPopup(true);
  };
  const close = e => {
    formRef.current.instance.resetValues();
    setFormData({});
    setSelectedCustomInfo([]);
    setSelectedCostCenter([]);
    setManagementNameType([]);
    setIsMerge(false);
    setIsPopup(false);
    setIsStop(false);
  };
  const handleFieldChange = useCallback((fieldName, value) => {
    setFormData(prevData => {
      const newData = { ...prevData, [fieldName]: value };

      // 가격 관련 필드가 변경된 경우 총액 계산
      if (
        [
          'StandardPriceDeviceRental',
          'StandardPriceConstruction',
          'StandardPriceMaintenance',
          'StandardPriceCallCenter',
        ].includes(fieldName)
      ) {
        const total = [
          'StandardPriceDeviceRental',
          'StandardPriceConstruction',
          'StandardPriceMaintenance',
          'StandardPriceCallCenter',
        ].reduce((sum, field) => sum + (newData[field] || 0), 0);

        newData.StandardPriceTotal = total;
        newData.GrossPriceTotal = total * 1.1;
      }

      return newData;
    });
  }, []);

  const getValue = useCallback(
    (fieldName, rowData) => {
      if (isCalculated && calculatedPrices[fieldName] !== undefined) {
        return calculatedPrices[fieldName];
      }
      return rowData[fieldName];
    },
    [isCalculated, calculatedPrices],
  );

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {
        CostCenterCode,
        ProjectRemark,
        ContractStatus,
        IsStop,
        StopReason,
        CustomerCode,
        TaxEmailAddress,
        SalesDate,
        ContractStartDate,
        ContractEndDate,
        CanceledDate,
        GrossPriceTotal,
        StandardPriceTotal,
        StandardPriceDeviceRental,
        StandardPriceConstruction,
        StandardPriceMaintenance,
        StandardPriceCallCenter,
        DepositPrice,
        ManagementID,
        ManagementName,
        ItemName,
        LastSeq,
        ExpireSeq,
        MonthlySalesDay,
        CMSPayerNumber,
        CMSInfo,
        PaymentInfo,
        InvoiceRemark,
        Remark,
        Invoker,
        IsReadOnly,
      } = formRef.current.props.formData;
      const param = {
        CostCenterCode,
        ProjectRemark,
        ContractStatus,
        IsStop,
        StopReason: IsStop ? StopReason : '',
        CustomerCode,
        TaxEmailAddress,
        SalesDate,
        ContractStartDate: ContractStartDate ? moment(ContractStartDate).format('YYYY-MM-DD') : '',
        ContractEndDate: ContractEndDate ? moment(ContractEndDate).format('YYYY-MM-DD') : '',
        CanceledDate,
        GrossPriceTotal,
        StandardPriceTotal,
        StandardPriceDeviceRental,
        StandardPriceConstruction,
        StandardPriceMaintenance,
        StandardPriceCallCenter,
        DepositPrice,
        ManagementID,
        ManagementName,
        ItemName,
        LastSeq,
        ExpireSeq,
        MonthlySalesDay,
        CMSPayerNumber,
        CMSInfo,
        PaymentInfo,
        InvoiceRemark,
        Remark,
      };
      if (!IsReadOnly && ContractStatus === 'D') {
        const result = await confirm(
          '<i>계약이 해지되는 경우 계산서 발행이 중지되고, 재개가 불가능합니다. 해지 확정 하시겠습니까?</i>',
          '확인',
        );
        if (result === false) return;
      }
      if (Invoker === 'Create' || Invoker === 'Copy') {
        // 등록
        setLoadPanelVisible(true);
        await salesPayment.insertRentalMaster(param).then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '등록되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            close();
            refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      } else {
        // 수정
        setLoadPanelVisible(true);
        await salesPayment.updateRentalMaster(param).then(res => {
          if (res.isOk) {
            setLoadPanelVisible(false);
            notify(
              {
                message: '수정되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            close();
            refresh();
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
        });
      }
    },
    [refresh],
  );
  const onFocusIn = e => {
    e.event.target.blur();
  };
  const onFocusInActive = e => {
    e.event.target.focus();
  };

  const onValueChanged = e => {
    const { value, previousValue, event } = e;
    const isClickEvent = event?.type === 'dxclick';

    setIsMerge(value);

    if (isClickEvent) {
      if (!previousValue && value) {
        setFormData({ ...formData, ManagementName: '' });
      } else if (previousValue && !value) {
        const newManagementName = formData.OriginMergeInvoiceYN !== 1 ? formData.OriginManagementName : '';
        setFormData({ ...formData, ManagementName: newManagementName });
      }
    }
  };

  const handlePreviewBtn = () => {
    const formInstance = formRef.current.instance;
    const res = formInstance.validate();
    if (!res.isValid) {
      return notify(
        {
          message: '입력값을 확인해주세요',
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
    const {
      ManagementID,
      CostCenterName,
      CostCenterCode,
      ContractStatus,
      IsStop,
      CustomerCode,
      MonthlySalesDay,
      ManagementName,
      ItemName,
      StandardPriceTotal,
    } = formData;
    const param = {
      ManagementID,
      CostCenterCode,
      ContractStatus,
      IsStop,
      CustomerCode,
      MonthlySalesDay,
      ManagementName,
      ItemName,
      StandardPriceTotal,
    };
    preViewRef.current.open(param, CostCenterName);
  };

  const checkDuplicate = ({ formItem, value }) => {
    const arr = managementNameType.map(a => a.ManagementName);
    const arrExceptMe = arr.filter(a => a !== value);
    if (formData.Invoker === 'Update') {
      const duplicated = arrExceptMe.includes(value);
      return duplicated ? false : true;
    } else {
      const duplicated = arr.includes(value);
      return duplicated ? false : true;
    }
  };

  const handleIsStop = e => {
    e.value ? setIsStop(true) : setIsStop(false);
  };

  return (
    <Popup
      onHiding={close}
      visible={isPopup}
      showTitle={true}
      title={formData.Invoker === 'Update' ? '렌탈 마스터 수정' : '렌탈 마스터 신규 추가'}
      width="60%"
      height={810}
      dragOutsideBoundary={true}
      dragEnabled={true}
    >
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-container detail-history">
            <Form
              id="form"
              ref={formRef}
              formData={formData}
              labelLocation="top"
              labelMode="outside"
              readOnly={formData.IsReadOnly}
              optionalMark="optional"
              stylingMode="outlined"
              onFieldDataChanged={e => handleFieldChange(e.dataField, e.value)}
            >
              {/* 기본 정보 */}
              <GroupItem colCount={4}>
                <SimpleItem
                  dataField="CostCenterName"
                  editorType="dxTextBox"
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    readOnly: formData.Invoker === 'Update' ? true : false,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업소 선택',
                          onClick: () => {
                            costcenterMasterRef.current.open();
                          },
                        },
                      },
                    ],
                    value: formData.CostCenterName,
                  }}
                >
                  <Label text="사업소명" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>

                <SimpleItem dataField="CostCenterCode" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                  <Label text="프로젝트코드" />
                </SimpleItem>

                <SimpleItem
                  dataField="Remark"
                  editorType="dxTextArea"
                  colSpan={2}
                  editorOptions={{
                    height: 75, // 픽셀 단위로 높이 지정
                    readOnly: false,
                  }}
                >
                  <Label text="비고" />
                </SimpleItem>
              </GroupItem>

              {/* 계약 정보 */}
              <GroupItem caption="계약 정보" colCount={5}>
                <SimpleItem
                  dataField="ContractStatus"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: contractStatusType,
                    valueExpr: 'value',
                    displayExpr: 'id',
                    placeholder: '계약상태를 선택해주세요',
                  }}
                >
                  <Label text="계약상태" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>

                <SimpleItem
                  dataField="ContractStartDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    type: 'date',
                    displayFormat: 'yyyy-MM-dd',
                    placeholder: 'YYYY-MM-DD',
                  }}
                >
                  <Label text="렌탈개시일" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>

                <SimpleItem
                  dataField="ContractEndDate"
                  editorType="dxDateBox"
                  editorOptions={{
                    type: 'date',
                    displayFormat: 'yyyy-MM-dd',
                    placeholder: 'YYYY-MM-DD',
                    min: formData.ContractStartDate,
                  }}
                >
                  <Label text="계약종료일" />
                </SimpleItem>

                <SimpleItem
                  dataField="SalesDate"
                  editorType="dxTextBox"
                  editorOptions={{
                    placeholder: 'YYYY-MM-DD',
                  }}
                  // editorType="dxDateBox"
                  // editorOptions={{
                  //   type: 'date',
                  //   displayFormat: 'yyyy-MM-dd',
                  //   placeholder: 'YYYY-MM-DD',
                  // }}
                >
                  <Label text="매출인식일" />
                </SimpleItem>

                <SimpleItem
                  dataField="CanceledDate"
                  editorType="dxTextBox"
                  editorOptions={{
                    placeholder: 'YYYY-MM-DD',
                  }}
                  // editorType="dxDateBox"
                  // editorOptions={{
                  //   type: 'date',
                  //   displayFormat: 'yyyy-MM-dd',
                  //   placeholder: 'YYYY-MM-DD',
                  // }}
                >
                  <Label text="중도해지일" />
                </SimpleItem>

                {/* <Item>
                  <Label text="마지막 발행회차는 자동 증가됩니다. 강제로 변경 원하는 경우만 수정하면 됩니다." />
                </Item> */}
                <SimpleItem
                  dataField="LastSeq"
                  editorType="dxNumberBox"
                  editorOptions={{
                    showSpinButtons: true,
                    placeholder: 'ex) 0',
                    stylingMode: 'outlined',
                    inputAttr: {
                      style: 'background-color: #ffebee;',
                    },
                  }}
                >
                  <Label text={'마지막 발행 회차'} />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>

                <SimpleItem
                  dataField="ExpireSeq"
                  editorType="dxNumberBox"
                  editorOptions={{
                    showSpinButtons: true,
                    placeholder: 'ex) 60',
                    stylingMode: 'outlined',
                    inputAttr: {
                      style: 'background-color: #ffebee;',
                    },
                  }}
                >
                  <Label text="만기 회차" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                <SimpleItem
                  dataField="IsStop"
                  cssClass="item-vertical-center"
                  editorType="dxCheckBox"
                  editorOptions={{ onValueChanged: handleIsStop }}
                >
                  <Label text="발행 중지" location="left" />
                </SimpleItem>
                <SimpleItem dataField="StopReason" editorType="dxTextBox" colSpan={2} visible={isStop}>
                  <Label text="발행 중지 사유" />
                </SimpleItem>
                <EmptyItem colSpan={2} visible={!isStop}></EmptyItem>
                <SimpleItem dataField="ProjectRemark">
                  <Label text="프로젝트 정보" />
                </SimpleItem>
                <SimpleItem
                  dataField="DepositPrice"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                  }}
                >
                  <Label text="보증금" />
                </SimpleItem>
                <SimpleItem dataField="CMSPayerNumber">
                  <Label text="CMS납부자 번호" />
                </SimpleItem>

                <SimpleItem dataField="PaymentInfo">
                  <Label text="입금정보" />
                </SimpleItem>
                <SimpleItem dataField="CMSInfo">
                  <Label text="CMS" />
                </SimpleItem>
              </GroupItem>

              {/* 세금계산서 발행 정보 */}
              <GroupItem caption="세금계산서 발행 정보" colCount={4}>
                <SimpleItem
                  dataField="CorpRegNumber"
                  editorType="dxTextBox"
                  editorOptions={{
                    onFocusIn: onFocusIn,
                    buttons: [
                      {
                        name: 'trash',
                        location: 'after',
                        options: {
                          stylingMode: 'text',
                          text: '사업자 선택',
                          onClick: () => {
                            customInfoRef.current.open();
                          },
                        },
                      },
                    ],
                    value: formData.CorpRegNumber,
                  }}
                >
                  <Label text="사업자등록번호" />
                  <RequiredRule message="필수 값입니다." />
                </SimpleItem>

                <SimpleItem dataField="CustomerName" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                  <Label text="사업자명" />
                </SimpleItem>
                <SimpleItem dataField="CustomerCode" visible={false}>
                  <Label text="SAP사업자" />
                </SimpleItem>
                <SimpleItem dataField="TaxEmailAddress">
                  <Label text="세금계산서 발송 메일" />
                  <EmailRule message="이메일 형식이 올바르지 않습니다." />
                  <RequiredRule message="필수 값입니다." />
                </SimpleItem>

                <SimpleItem
                  dataField="MonthlySalesDay"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: days,
                    placeholder: '정기발행일을 선택해주세요',
                  }}
                >
                  <Label text="정기발행일" />
                  <RequiredRule message="필수값 입니다." />
                </SimpleItem>
                {isMerge ? (
                  <SimpleItem
                    dataField="ManagementName"
                    editorType="dxTextBox"
                    editorOptions={{
                      placeholder: '계산서를 선택해주세요',
                      onFocusIn: onFocusIn,
                      stylingMode: 'outlined',
                      inputAttr: {
                        style: 'background-color: transparent;',
                      },
                      buttons: [
                        {
                          name: 'trash',
                          location: 'after',
                          readOnly: !isMerge,
                          options: {
                            stylingMode: 'text',
                            text: '계산서 선택',

                            onClick: () => {
                              managementNameRef.current.open();
                            },
                          },
                        },
                      ],
                      value: formData.ManagementName,
                    }}
                  >
                    <Label text="계산서구분 선택" />
                    <RequiredRule message="필수값 입니다." />
                  </SimpleItem>
                ) : (
                  <SimpleItem
                    dataField="ManagementName"
                    editorType="dxTextBox"
                    editorOptions={{
                      placeholder: '합산/별도의 식별자를 입력해주세요',
                      stylingMode: 'outlined',
                      onFocusIn: onFocusInActive,
                      inputAttr: {
                        style: 'background-color: #f8ebb8;',
                      },
                      buttons: [],
                      value: formData.ManagementName,
                    }}
                  >
                    <Label text="계산서구분" />
                    {/* {formData.Invoker !== 'Update' && ( */}
                    <CustomRule message={'이미 사용중인 구분값입니다.'} validationCallback={checkDuplicate} />
                    {/* )} */}
                    <RequiredRule message="필수 값입니다." />
                  </SimpleItem>
                )}
                <SimpleItem dataField="ManagementID" editorType="dxTextBox" visible={false}>
                  <Label text="ManagementID" />
                  <RequiredRule message="필수 값입니다." />
                </SimpleItem>
                <SimpleItem
                  dataField="ItemName"
                  colSpan={1}
                  editorOptions={{
                    placeholder: 'ex) 장비렌탈료',
                    stylingMode: 'outlined',
                    inputAttr: {
                      style: 'background-color: #f8ebb8;',
                    },
                  }}
                >
                  <Label text="계산서항목명" />
                  <RequiredRule message="필수 값입니다." />
                </SimpleItem>
                <SimpleItem
                  dataField="MergeInvoiceYN"
                  editorType="dxCheckBox"
                  cssClass="item-vertical-center"
                  editorOptions={{ onValueChanged: onValueChanged }}
                >
                  <Label text="합산 발행" location="left" />
                </SimpleItem>
                <EmptyItem></EmptyItem>

                <SimpleItem
                  dataField="StandardPriceDeviceRental"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                  }}
                >
                  <Label text="장비렌탈료" />
                </SimpleItem>

                <SimpleItem
                  dataField="StandardPriceConstruction"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                  }}
                >
                  <Label text="공사료" />
                </SimpleItem>

                <SimpleItem
                  dataField="StandardPriceMaintenance"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                  }}
                >
                  <Label text="유지보수료" />
                </SimpleItem>

                <SimpleItem
                  dataField="StandardPriceCallCenter"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                  }}
                >
                  <Label text="콜센터료" />
                </SimpleItem>

                <SimpleItem
                  dataField="StandardPriceTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                    readOnly: true,
                    stylingMode: 'outlined',
                    inputAttr: {
                      style: 'background-color: #f8ebb8;',
                    },
                  }}
                  calculateCellValue={rowData => getValue('StandardPriceTotal', rowData)}
                >
                  <Label text="총공급가(자동계산)" />
                </SimpleItem>
                <SimpleItem
                  dataField="GrossPriceTotal"
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: '#,##0',
                    placeholder: '0',
                    readOnly: true,
                    stylingMode: 'outlined',
                    inputAttr: {
                      style: 'background-color: #f8ebb8;',
                    },
                  }}
                  calculateCellValue={rowData => getValue('GrossPriceTotal', rowData)}
                >
                  <Label text="총발행금액(VAT포함,자동계산)" />
                </SimpleItem>

                <SimpleItem dataField="InvoiceRemark" colSpan={2}>
                  <Label text="계산서 적요" />
                </SimpleItem>
              </GroupItem>

              {/* 버튼 그룹 */}
              <GroupItem colCount={3}>
                <ButtonItem horizontalAlignment="left">
                  <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    text="세금계산서 미리보기"
                    onClick={handlePreviewBtn}
                  ></ButtonOptions>
                </ButtonItem>
                <EmptyItem />
                <GroupItem colCount={2}>
                  <ButtonItem horizontalAlignment="right">
                    <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
                  </ButtonItem>
                  <ButtonItem horizontalAlignment="right">
                    <ButtonOptions width={'100%'} type={'normal'} text="닫기" onClick={close}></ButtonOptions>
                  </ButtonItem>
                </GroupItem>
              </GroupItem>
            </Form>
          </div>
        </form>
      </div>
      <ManagementNamePopup ref={managementNameRef} setSelectedManagementName={setSelectedManagementName} />
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
      <CostcenterMaster ref={costcenterMasterRef} setSelectedCostCenter={setSelectedCostCenter} />
      <PreviewPopup ref={preViewRef} />
    </Popup>
  );
});
export default React.memo(AddRentalMaster);
