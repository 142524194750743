import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Column, Selection, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { cellStatus } from 'pages/sales-mng/check-sales-payment/component/common/common';
import { salesPayment } from 'api';
import CommonDataGrid from 'common/default-data-grid';

/* 히스토리 : pg, van 사가 나뉘어 있었지만 현재 pg탭에 합쳐짐. 차후 pg tab 네이밍 변경예정*/
const PG = props => {
  const { setPRowdata, searchData, pgvanRef } = props;

  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  const onSelectionChanged = e => {
    setPRowdata(e.selectedRowsData);
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const result = await salesPayment.getPaymentDataDetail({ ...searchData });
        return result.data;
      },
    });

    return customDataSource;
  }, [searchData]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <>
      <CommonDataGrid
        gridRef={pgvanRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        height={700}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Selection mode="single" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column dataField={'TransactionDate'} caption={'결제시간'} customizeText={customizeText} />
        <Column dataField={'CardTypeName'} caption={'결제방식'} />
        <Column dataField={'ApprovalTypeName'} caption={'결제유형'} />
        <Column dataField={'StatusName'} caption={'매출상태'} />
        <Column dataField={'ApprovalNo'} caption={'승인번호'} />
        <Column dataField={'SalesPrice'} caption={'결제금액'} format="#,##0 원" />
        <Column dataField={'CreditCardNo'} caption={'카드번호'} />
        <Column dataField={'Remark1'} caption={'비고'} />
      </CommonDataGrid>
    </>
  );
};

export default React.memo(PG);
