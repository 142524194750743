import React, { useState, useRef, useEffect, useImperativeHandle, useCallback } from 'react';
import { Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { system, error } from 'api';
import CommonDataGrid from 'common/default-data-grid';
import { useAuth } from 'contexts/auth';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

const UserList = React.forwardRef((props, ref) => {
  const { setLoadPanelVisible } = props;
  const { role } = useAuth();
  const gridRef = useRef();
  const [isPopup, setIsPopup] = useState(false);
  const [userList, setUserList] = useState([]);
  const [CostCenterCode, setCostCenterCode] = useState('');
  const [isRefresh, setIsRefresh] = useState(false);
  const [searchParams, setSearchParams] = useState({
    EmployeeName: '',
    EmployeeEnName: '',
    NickName: '',
    IsActive: 1,
    RoleCodeList: role,
  });
  const [text, setText] = useState({
    EmployeeName: '',
    EmployeeEnName: '',
    NickName: '',
    IsActive: 1,
    RoleCodeList: role,
  });

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    open,
    close,
  }));

  useEffect(() => {
    (async () => {
      await system.userList(searchParams).then(res => {
        if (res.isOk) {
          setUserList(res.data);
        }
      });
    })();
  }, [searchParams, isRefresh]);

  const open = CostCenterCode => {
    setIsPopup(true);
    setCostCenterCode(CostCenterCode);
    gridRef.current.instance.refresh();
    gridRef.current.instance.clearSelection();
  };

  const close = () => {
    setIsPopup(false);
  };

  const onChangeSearchText = e => {
    setText({
      ...text,
      [e.component._label._props.text]: e.value,
    });
  };

  const onEnterKey = e => {
    setSearchParams(text);
  };

  const onSave = useCallback(async () => {
    const seletedItems = gridRef.current.instance.getSelectedRowsData();
    const userIds = seletedItems.map(s => s.UserID);
    const result = confirm('<i>저장하시겠습니까?</i>', '확인');
    result.then(async dialogResult => {
      if (dialogResult) {
        setLoadPanelVisible(true);
        await system
          .insertCostCenterManagerList({
            CostCenterCode: CostCenterCode,
            ManagerID: userIds.join(','),
          })
          .then(res => {
            if (res.isOk) {
              setLoadPanelVisible(false);
              notify(
                {
                  message: '저장되었습니다.',
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'success',
              );
              // setSelectedCostCenter([]);
              setIsRefresh(!isRefresh);
            } else {
              setLoadPanelVisible(false);
              notify(
                {
                  message: error.errorMsgCheck(res.error.detail),
                  width: 230,
                  position: {
                    at: 'top',
                    my: 'top',
                    of: '#container',
                  },
                },
                'error',
              );
            }
          });
      }
    });
    // 참고
    // console.log('저장시 보내주는 costCenterCode >', CostCenterCode);
    // console.log('저장시 보내주는 userIds >', userIds.join(','));
  }, [CostCenterCode, isRefresh]);
  return (
    <Popup onHiding={close} visible={isPopup} showTitle={false} width="30%" height={760}>
      <div className="popup">
        <div className="popup-close-button">
          <i className="dx-icon-close dx-icon-customicon dx-state-hover" onClick={close}></i>
        </div>
        <div className="popup-header">담당자 선택</div>
        <div className="popup-content">
          <div className="dx-fieldset">
            <CommonDataGrid
              gridRef={gridRef}
              className={'dx-card wide-card'}
              dataSource={userList}
              showBorders={true}
              hoverStateEnabled={true}
              stylingMode="outlined"
              isExcel={false}
              isHeader={true}
              height={500}
              headerBefore={[
                <TextBox
                  label="EmployeeName"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="이름"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
                <TextBox
                  label="NickName"
                  labelMode="hidden"
                  stylingMode="outlined"
                  placeholder="표기명"
                  onValueChanged={onChangeSearchText}
                  onEnterKey={onEnterKey}
                  mode="search"
                />,
              ]}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Selection mode="multiple" selectAllMode="pages" />
              <Column dataField={'UserID'} caption={'ID'} alignment={'center'} />
              <Column dataField={'EmployeeName'} caption={'이름'} />
              <Column dataField={'NickName'} caption={'표기명'} />
            </CommonDataGrid>
          </div>
        </div>
        <div className="popup-footer">
          <div>
            <Button text="선택" width={'120'} onClick={onSave} />
          </div>
          <div>
            <Button text="닫기" width={'120'} onClick={close} />
          </div>
        </div>
      </div>
    </Popup>
  );
});

export default UserList;
