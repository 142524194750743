import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Column, Summary, TotalItem, Scrolling, HeaderFilter, LoadPanel } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';
import ExcelExport from 'common/excel-export';
import { Button } from 'devextreme-react/button';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import hideToasts from 'devextreme/ui/toast/hide_toasts';
import { btob, parking, error } from 'api';
import { csstiUrls } from 'api/btob';
import ExcelImportInDataGrid from './excel-import-in-data-grid';
import { notifyDelayS, notifyS } from 'common/popup/sales-common-util';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { SelectBox } from 'devextreme-react/select-box';
import CustomInfoPopup from '../sales-mng/sales-taxinvoice/popup/CustomInfoPopup';
import { TextBox } from 'devextreme-react/text-box';
import { isEmptyString } from 'utils/common';
import './acquired-sales-tax-invoice-issuance.scss';

const PCMSalesUploader = () => {
  const dataGridRef = useRef({});
  const customInfoRef = useRef({});
  const selectBoxB2BSalesTypeRef = useRef({});

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  // 상단 업로드조건
  const [b2bSalesTypeCodes, setB2bSalesTypeCodes] = useState([]);
  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY/MM/DD'),
    B2BSalesType: '',
    CustomerCode: '',
    CorpRegNumber: '',
    CustomerCorpName: '',
  });
  const [selectedCustomInfo, setSelectedCustomInfo] = useState([]);

  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [showPane, setShowPane] = useState(false);
  const [headerText, setHeaderText] = useState([]);

  const [uploadKeyIDListStr, setUploadKeyIDListStr] = useState('');

  const getCodes = useCallback(async () => {
    const tmpB2bSalesType = [];
    const result = await parking.getParkingInfoSelectType({
      CodeGroup: ['B2BSalesTypeUpload'],
    });
    console.log(result);
    if (result.isOk && result.data.length > 0) {
      result.data.forEach(obj => {
        if (obj.CodeGroup === 'B2BSalesTypeUpload') {
          tmpB2bSalesType.push({
            id: obj.CodeDesc,
            value: obj.Code,
          });
        }
      });
      console.log(tmpB2bSalesType);
      setB2bSalesTypeCodes(tmpB2bSalesType);
    }
  }, []);

  const handleChangedB2BSalesType = e => {
    console.log(e);
    const { value } = e.itemData;
    setSearchData({ ...searchData, B2BSalesType: value });
  };

  useEffect(() => {
    console.log(selectedCustomInfo);
    if (selectedCustomInfo.length > 0) {
      setSearchData({
        ...searchData,
        CustomerCode: selectedCustomInfo[0].CustomerNo,
        CorpRegNumber: selectedCustomInfo[0].CorpRegNumber,
        CustomerCorpName: selectedCustomInfo[0].CustomerCorpName,
      });
    }
  }, [selectedCustomInfo]);

  useEffect(() => {
    getCodes();
    makeExcelHeaderText();
  }, []);

  // 컬럼 정의
  const columns = ['CostCenterCode', 'Remark', 'SalesDate', 'Quantity', 'SalesPrice', 'IsTaxFree'];

  const sampleData = [
    {
      CostCenterCode: 'P0274',
      Remark: '모두의주차장 에이플러스에셋타워 선불권',
      SalesDate: '2023-01-31',
      Quantity: 15,
      SalesPrice: '2600000',
      IsTaxFree: 'Y'
    },
    {
      CostCenterCode: 'P0450',
      Remark: '카카오T 광화문D타워 자동결제',
      SalesDate: '2023-01-31',
      Quantity: 4,
      SalesPrice: 1000000,
      IsTaxFree: 'Y'
    },
    {
      CostCenterCode: 'C0145',
      Remark: '파킹박 아이콘역삼 정기권',
      SalesDate: '2023-01-31',
      Quantity: 15,
      SalesPrice: 2200000,
      Commission: 400000,
    },
  ];

  const makeExcelHeaderText = () => {
    if (dataGridRef && dataGridRef.current.instance) {
      const tmp = dataGridRef.current.instance.getVisibleColumns();

      const excelFields = tmp
        .map(a => {
          return {
            key: a.dataField,
            label: a.caption,
          };
        })
        .filter(e => e.key !== undefined);
      setHeaderText(excelFields);
    }
  };

  const handleSalesMonth = e => {
    setSearchData({
      ...searchData,
      SalesMonth: e.value,
    });
  };

  const importMapFunc = a => {
    const returnValue = {};
    console.log(columns);
    for (let i = 0; i < columns.length; i++) {
      returnValue[columns[i]] = a[i];
    }
    return returnValue;
  };

  const handleImport = async excelDataSource => {
    console.log(excelDataSource);
    setLoadPanelVisible(true);
    setDataSource(excelDataSource);
    setConfirmDisabled(true);

    const params = {
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      // SalesMonth: searchData.SalesMonth,
      ItemListJson: JSON.stringify(excelDataSource),
    };
    const res = await btob.doApi(csstiUrls.check, params);

    console.log('res...');
    console.log(res);
    setLoadPanelVisible(false);
    if (!res.isOk) {
      console.log('check error...');
      notifyDelayS(error.errorMsgCheck(res.error.detail));
      setConfirmDisabled(true);
      return;
    }
    setConfirmDisabled(false);
    console.log('check success...');
    hideToasts();
  };

  const confirmUplodedData = async () => {
    hideToasts();

    // if (isEmptyString(searchData.B2BSalesType)) {
    //   notifyS('매출 유형을 선택하십시오.');
    //   return;
    // }
    if (isEmptyString(searchData.CorpRegNumber)) {
      notifyS('사업자를 선택하십시오.');
      return;
    }

    setLoadPanelVisible(true);
    const params = {
      SalesMonth: moment(searchData.SalesMonth, 'YYYY/MM/DD').format('YYYYMM'),
      // SalesMonth: searchData.SalesMonth,
      ItemListJson: JSON.stringify(dataSource),
      SalesType: searchData.B2BSalesType,
      CustomerCode: searchData.CustomerCode,
      SalesCategory: 'PCMSales',
    };
    const res = await btob.doApi(csstiUrls.upload, params);
    setLoadPanelVisible(false);
    if (!res.isOk) {
      console.log('check error...');
      notifyS(error.errorMsgCheck(res.error.detail));
      return;
    }
    notifyS('처리되었습니다.', 'success');

    const keyIDListStr = res.data[0][0].KeyIDList;
    console.log(keyIDListStr);
    setUploadKeyIDListStr(keyIDListStr);
    refresh();
  };

  const refresh = () => {
    setDataSource([]);
    setConfirmDisabled(true);
    setUploadKeyIDListStr('');
    // hideToasts();
    setSearchData({
      ...searchData,
      B2BSalesType: '',
      CustomerCode: '',
      CorpRegNumber: '',
      CustomerCorpName: '',
    });
    selectBoxB2BSalesTypeRef.current.instance.option('value', '');
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onEnterKey = e => {
    console.log('onEnterKey');
    // setSearchKey(prev => prev + 1);
  };

  return (
    <div className="aquired-service">
      <h2 className={'content-block'}>카드무료주차 매출 관리</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings aquired-service'} style={{ paddingTop: '10px' }}>
          {/* <h5 style={{ marginBottom: '1em' }}>
            {' '}
            ★엑셀 파일 업로드 순서 : 샘플파일을 다운로드 &nbsp; ▷ &nbsp; 내용 추가 &nbsp; ▷ &nbsp;엑셀 파일 업로드
            &nbsp; ▷ &nbsp; 확정
          </h5> */}

          {/*
          <form onSubmit={onEnterKey}>
            <div className="form-container">
              <Form formData={searchData} stylingMode="outlined">
                <SimpleItem
                  stylingMode="outlined"
                  dataField="B2BSalesType"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: b2bSalesTypeCodes,
                    valueExpr: 'value',
                    displayExpr: 'id',
                    placeholder: '매출 유형을 선택해주세요',
                    onValueChanged: handleChangedB2BSalesType,
                    stylingMode: 'outlined',
                  }}
                >
                  <RequiredRule message="매출 유형을 선택해주세요" />
                  <Label text="매출유형" />
                </SimpleItem>
              </Form>
            </div>
          </form>
          */}

          <Toolbar id={'toolbar-menu'}>
            <ToolbarItem location={'before'}>
              <div style={{ width: '70px' }}>&nbsp;매출 등록월</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
                // label={'매출등록월'}
              />
              &nbsp;
            </ToolbarItem>
            {/* <ToolbarItem location={'before'}>
              <div style={{ width: '70px' }}>&nbsp;&nbsp;&nbsp;&nbsp;매출유형</div>
            </ToolbarItem>
            <ToolbarItem location={'before'}>
              <SelectBox
                ref={selectBoxB2BSalesTypeRef}
                labelMode="static"
                placeholder="매출유형을 선택해주세요"
                // label={'매출유형'}
                dropDownButtonTemplate="dropDownButton"
                stylingMode="outlined"
                dataSource={[
                  // {
                  //   key: '전체',
                  //   items: [{ value: '', id: '매출유형 전체' }],
                  // },
                  {
                    key: '매출유형',
                    items: b2bSalesTypeCodes,
                  },
                ]}
                onItemClick={handleChangedB2BSalesType}
                valueExpr="value"
                displayExpr="id"
                width={200}
                grouped={true}
              />
            </ToolbarItem> */}
          </Toolbar>
          <Toolbar id={'toolbar-menu'}></Toolbar>
          <Toolbar id={'toolbar-menu'}>
            {/* <ToolbarItem location={'before'}>
              <div style={{ width: '120px' }}>사업자등록번호</div>
            </ToolbarItem> */}
            <ToolbarItem location={'before'}>
              <Button
                id={'sel-cost-center'}
                text="사업자 선택"
                onClick={() => {
                  // console.log('aaa');
                  customInfoRef.current.open();
                }}
              />
            </ToolbarItem>
            <ToolbarItem location={'before'} cssClass="ti-pr-zero" elementAttr={{ class: 'ti-pr-zero' }}>
              <TextBox
                stylingMode="outlined"
                editorType="dxTextBox"
                labelMode="static"
                placeholder="사업자등록번호"
                // label="사업자등록번호"
                readOnly={true}
                value={searchData.CorpRegNumber}
              ></TextBox>
            </ToolbarItem>

            <ToolbarItem location={'before'} cssClass="ti-pr-zero" elementAttr={{ class: 'ti-pr-zero' }}>
              <TextBox
                visible={false}
                stylingMode="outlined"
                editorType="dxTextBox"
                labelMode="static"
                placeholder="고객코드"
                // label="사업자등록번호"
                readOnly={true}
                value={searchData.CustomerNo}
              ></TextBox>
            </ToolbarItem>

            <ToolbarItem location={'before'}>
              <TextBox
                labelMode="static"
                stylingMode="outlined"
                placeholder="회사명"
                // label={'회사명'}
                readOnly={true}
                onEnterKey={onEnterKey}
                value={searchData.CustomerCorpName}
                width={300}
              />
            </ToolbarItem>
          </Toolbar>
          <CommonDataGrid
            gridRef={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={500}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '카드무료주차 매출 관리.xlsx',
            }}
            headerBefore={[
              <ExcelImportInDataGrid
                fieldColumn={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']}
                handleImport={e => handleImport(e.map(importMapFunc))}
                fieldColumnType={[
                  'string',
                  'string',
                  'date',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                  'string',
                ]}
              />,
            ]}
            headerAfter={[
              <Button
                icon="check"
                text="확정"
                width={80}
                type="normal"
                stylingMode="contained"
                onClick={() => confirmUplodedData()}
                disabled={confirmDisabled}
              />,
              <ExcelExport
                excelFields={headerText}
                sheetName="Sheet"
                fileName="sample-카드무료주차매출업로드.xlsx"
                excelData={sampleData}
                buttonName="샘플파일 다운로드"
              />,
            ]}
            // headerAfter={[<Button icon="plus" text="매출 수기 등록" onClick={onOpen} />]}
            allowColumnReordering={true}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <LoadPanel enabled showPane={showPane} />
            <HeaderFilter visible={true} />
            <Column dataField={'CostCenterCode'} caption={'영업점코드'} hidingPriority={4} />
            <Column dataField={'Remark'} caption={'적요'} hidingPriority={4} />
            <Column dataField={'SalesDate'} caption={'매출 일자'} hidingPriority={4} />
            <Column dataField={'Quantity'} caption={'매출 수량'} hidingPriority={4} />
            <Column dataField={'SalesPrice'} caption={'매출액'} hidingPriority={3} format="#,##0 원" />
            <Column dataField={'IsTaxFree'} caption={'부가세 없음'} hidingPriority={4} format="#,##0 원" />
            <Summary>
              <TotalItem column="CostCenterCode" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="Quantity" summaryType="count" displayFormat="합계: {0}" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <PSLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '.content' }}
        onHiding={hideLoadPanel}
        visible={loadPanelVisible}
      />
      <CustomInfoPopup ref={customInfoRef} setSelectedCustomInfo={setSelectedCustomInfo} />
    </div>
  );
};
export default PCMSalesUploader;
