import React, { useCallback, useState, useRef, useImperativeHandle, useEffect } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import { salesPayment, error } from 'api';
import { confirmManualRegistration } from 'common/validators/validators';

const InputPaymentForm = React.forwardRef((props, ref) => {
  const { setIsOpenbyInputPayment, setLoadPanelVisible, costCenter, salesType, etcType, inquiryDate, setStatus } =
    props;
  const [formData, setFormData] = useState({
    SaleTotal: 0,
    SalesType: '',
    DetailSalesType: '',
    Remark: '',
    GeneralSalesKeyID: '',
    SeasonTicketKeyID: '',
    EtcSalesKeyID: '',
    PGVANPaymentKeyID: '',
    CMSPaymentKeyID: '',
  });
  const formRef = useRef({});
  const [isGeneral, setIsGeneral] = useState(false);
  const [tRowdataSource, setTRowdataSource] = useState([]);
  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = (pRowdata, cRowdata, tdataSource) => {
    let paymentTotal = 0;
    const PGVANPaymentKeyID = [];
    const CMSPaymentKeyID = [];
    if (pRowdata && pRowdata.length > 0) {
      pRowdata.forEach(p => {
        paymentTotal += p.SalesPrice;
        PGVANPaymentKeyID.push(p.KeyID);
      });
    }
    if (cRowdata && cRowdata.length > 0) {
      cRowdata.forEach(c => {
        paymentTotal += c.TransactionAmount;
        CMSPaymentKeyID.push(c.KeyID);
      });
    }
    setTRowdataSource(tdataSource);
    setFormData({
      ...formData,
      SaleTotal: paymentTotal,
      PGVANPaymentKeyID: PGVANPaymentKeyID.length > 0 ? PGVANPaymentKeyID.join(';') : '',
      CMSPaymentKeyID: CMSPaymentKeyID.length > 0 ? CMSPaymentKeyID.join(';') : '',
    });
  };

  // 저장
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { SaleTotal, SalesType, DetailSalesType, Remark, PGVANPaymentKeyID, CMSPaymentKeyID } = formData;
      const checkStatus = ['N', 'O'];
      if (SaleTotal > 500000 && SalesType === 'EtcSales') {
        const isDuplicate = tRowdataSource.some(a => checkStatus.includes(a.Status) && a.RemainAmount === SaleTotal);
        if (isDuplicate && !(await confirmManualRegistration())) {
          return;
        }
      }

      setLoadPanelVisible(true);
      await salesPayment
        .confirmManualAccountInfo({
          CostCenter: costCenter,
          InquiryDate: inquiryDate,
          SalesPrice: SaleTotal,
          SalesType,
          DetailSalesType: SalesType === 'GeneralSales' ? null : DetailSalesType,
          PGVANPaymentKeyID,
          CMSPaymentKeyID,
          Remark,
          SalesKeyID: '',
          RegisterUser: '',
        })
        .then(res => {
          setLoadPanelVisible(false);
          setStatus('O');
          if (res.isOk) {
            notify(
              {
                message: '확정되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
          } else {
            setLoadPanelVisible(false);
            notify(
              {
                message: error.errorMsgCheck(res.error.detail),
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'error',
            );
          }
          //닫기 및 전체 그리드 리프레쉬
          onClose();
          props.refresh();
        });
    },
    [formData, costCenter, inquiryDate],
  );

  const onClose = () => {
    setIsOpenbyInputPayment(false);
    formRef.current.instance.resetValues();
  };

  const onClickSaleType = e => {
    const { value } = e.itemData;
    if (value === 'GeneralSales') {
      setIsGeneral(false);
    } else {
      setIsGeneral(true);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData}
          labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption="결제 내역 수기 대사">
            <SimpleItem
              dataField="SaleTotal"
              editorType="dxNumberBox"
              editorOptions={{
                readOnly: true,
                showSpinButtons: false,
                min: 0,
                format: '#,##0',
              }}
            >
              <Label text="매출금액" location="top" />
            </SimpleItem>
            <SimpleItem
              dataField="SalesType"
              editorType="dxSelectBox"
              editorOptions={{
                items: salesType,
                valueExpr: 'value',
                displayExpr: 'id',
                onItemClick: onClickSaleType,
                placeholder: '매출 유형을 선택해주세요',
              }}
            >
              <RequiredRule message="매출 유형을 선택해주세요" />
              <Label text="매출유형" location="top" />
            </SimpleItem>
            <SimpleItem
              dataField="DetailSalesType"
              editorType="dxSelectBox"
              editorOptions={{
                items: etcType,
                disabled: !isGeneral,
                value: null,
                valueExpr: 'value',
                displayExpr: 'id',
                placeholder: '결제 유형을 선택해주세요',
              }}
            >
              <RequiredRule message="결제 유형을 선택해주세요" />
              <Label text="매출 세부 유형" location="top" />
            </SimpleItem>
            <SimpleItem dataField="Remark" editorType="dxTextBox">
              <Label text="비고" location="top" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="확정"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
});

export default InputPaymentForm;
