import React, { useCallback, useMemo, useState, useImperativeHandle, useRef } from 'react';
import { Column, Selection, Summary, TotalItem, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { cellStatus } from '../component/common/common';
import { salesPayment, error, aggregation } from 'api';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import ContextMenu from 'devextreme-react/context-menu';
import CustomStore from 'devextreme/data/custom_store';
import CommonDataGrid from 'common/default-data-grid';
import RemarkPopup from './common/remark-popup';
import moment from 'moment';
import notify from 'devextreme/ui/notify';

const CMS = React.forwardRef((props, ref) => {
  const {
    setCRowdata,
    inquiryDate,
    costCenter,
    cmsRef,
    onCancelClick,
    setLoadPanelVisible,
    setSelectedAccountingNo,
    SetDataSourcePayment,
    setSelectedID,
    refresh,
  } = props;
  const [selectSum, setSelectSum] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const onCellPrepared = useCallback(({ data, displayValue, cellElement }) => {
    cellStatus(data, displayValue, cellElement);
  }, []);

  useImperativeHandle(ref, () => ({
    focusChange,
  }));

  const popupRef = useRef({});
  const focusChange = async accountingNoList => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();

    rawdata.forEach(obj => {
      if (accountingNoList.includes(obj.AccountingNo)) {
        KeyIDs.push(obj.KeyID);
      }
    });
    cmsRef.current.instance.selectRows(KeyIDs);
  };

  const focusChangeSelf = async accountingNo => {
    const KeyIDs = [];
    const rawdata = await dataSource.load();
    rawdata.forEach(obj => {
      if (obj.AccountingNo === accountingNo) {
        KeyIDs.push(obj.KeyID);
      }
    });
    cmsRef.current.instance.selectRows(KeyIDs);
  };
  const onSelectionChanged = () => {
    const rowdata = cmsRef.current.instance.getSelectedRowsData();
    setCRowdata(rowdata);

    const sum = rowdata.reduce((a, c) => {
      return a + c.TransactionAmount;
    }, 0);
    setSelectSum(sum);
  };

  const handleParams = (costCenter, inquiryDate) => {
    const inquirydate = inquiryDate.replace('-', '');
    return { InquiryDate: inquirydate, CostCenter: costCenter };
  };

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'KeyID',
      loadMode: 'raw',
      load: async () => {
        const params = handleParams(costCenter, inquiryDate);
        if (costCenter) {
          const result = await salesPayment.getCPaymentDataDetail(params);
          return result.data;
        } else {
          return [];
        }
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenter]);

  const customizeText = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const customizeSelectTotal = e => {
    return '선택 합계 : ' + selectSum.toLocaleString('ko-KR') + ' 원';
  };

  const editCellRender = e => {
    const { StatusName, AccountingNo, Status } = e.data;

    return Status === 'A' || Status === 'O' ? (
      <>
        <div id={'status-cancel-' + AccountingNo} className={'pointer-cursor'}>
          {StatusName}
        </div>
        <ContextMenu
          dataSource={[
            {
              text: '취소',
              value: AccountingNo,
            },
          ]}
          width={200}
          target={'#status-cancel-' + AccountingNo}
          onItemClick={onCancelClick}
        />
      </>
    ) : (
      <div>{StatusName}</div>
    );
  };

  const handleDate = cellInfo => {
    return moment(cellInfo.value).format('YYYY-MM-DD');
  };

  const onCellClick = e => {
    const { data, column } = e;
    if (data && column.caption === '매출상태') {
      const { AccountingNo } = data;
      AccountingNo ? setSelectedAccountingNo(AccountingNo) : setSelectedAccountingNo('N/A');
      setSelectedID('');
      if (AccountingNo) {
        focusChangeSelf(AccountingNo);
      }
      SetDataSourcePayment([]);
    }
  };
  const handleRemarkSubmit = async remark => {
    const selectedData = cmsRef.current.instance.getSelectedRowsData();
    const KeyIDs = [];
    setPopupVisible(false);
    selectedData.forEach(obj => {
      KeyIDs.push(obj.KeyID);
    });
    const keyIDsParam = KeyIDs.length > 0 ? KeyIDs.join(';') : '';
    setLoadPanelVisible(true);
    try {
      const res = await aggregation.registerSuspenseReceipts({
        CostCenterCode: costCenter,
        InquiryDate: inquiryDate,
        KeyID: keyIDsParam,
        Remark: remark,
      });
      setLoadPanelVisible(false);
      if (res.isOk) {
        notify(
          {
            message: '가수금으로 등록되었습니다.',
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'success',
        );
        refresh();
        // cmsRef.current.instance.refresh();
        // susprcptRef.current.instance.refresh();
      } else {
        notify(
          {
            message: error.errorMsgCheck(res.error.detail),
            width: 230,
            position: {
              at: 'top',
              my: 'top',
              of: '#container',
            },
          },
          'error',
        );
      }
    } catch (error) {
      setLoadPanelVisible(false);
      console.error('오류', error);
    }
  };
  const onClick = e => {
    const selectedData = cmsRef.current.instance.getSelectedRowsData();
    if (selectedData.length === 0) {
      return alert('결제를 선택해주세요');
    }
    setPopupVisible(true);
  };

  const onClose = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <CommonDataGrid
        gridRef={cmsRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnHidingEnabled={false}
        hoverStateEnabled={true}
        focusedRowEnabled={false}
        height={480}
        onCellPrepared={onCellPrepared}
        onCellClick={onCellClick}
        onSelectionChanged={onSelectionChanged}
        isHeader={true}
        isExcel={true}
        headerAfter={[<Button text="가수금 등록" onClick={onClick} stylingMode="contained" type="default" />]}
        excelOptions={{
          sheetName: 'sheet',
          fileName: 'CMS.xlsx',
        }}
        allowColumnReordering={true}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <HeaderFilter visible={true} />
        <Selection mode="multiple" selectAllMode="pages" showCheckBoxesMode="always" />
        <Column
          dataField={'TransactionDateShort'}
          name="TransactionDate1"
          caption={'입금날짜'}
          customizeText={handleDate}
          width={'100'}
        />
        <Column
          dataField={'TransactionDate'}
          name="TransactionDate2"
          caption={'입금시간'}
          customizeText={customizeText}
          width={'160'}
        />
        <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} cellRender={editCellRender} />
        <Column dataField={'TransactionAmount'} caption={'입금금액'} format="#,##0 원" width={'150'} />
        <Column dataField={'TransactionSummary'} caption={'Summary'} width={'100'} />
        <Column dataField={'BankName'} caption={'거래은행'} width={'100'} />
        <Column dataField={'Branch'} caption={'Branch'} width={'100'} />
        <Column dataField={'GiroCode'} caption={'지로'} width={'100'} />
        <Column dataField={'ExtraField1'} caption={'비고'} width={'100'} />
        <Column dataField={'BankAccountNo'} caption={'계좌'} width={'100'} />
        <Summary>
          <TotalItem customizeText={customizeSelectTotal} column="TransactionAmount" showInColumn="TransactionDate2" />
          <TotalItem column="TransactionAmount" summaryType="count" displayFormat="총 {0}건" />
          <TotalItem column="TransactionAmount" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
        </Summary>
      </CommonDataGrid>
      <Popup onHiding={onClose} visible={popupVisible} showTitle={false} width="25%" height={310}>
        <RemarkPopup ref={popupRef} setPopupVisible={setPopupVisible} handleRemarkSubmit={handleRemarkSubmit} />
      </Popup>
    </>
  );
});

export default React.memo(CMS);
