import React, { useCallback, useRef } from 'react';
import Form, { GroupItem, SimpleItem, Label, ButtonItem, ButtonOptions, CompareRule } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useAuth } from 'contexts/auth';
import { system } from 'api';

const User = props => {
  const { setIsPopup } = props;
  const { user, fetchUser } = useAuth();
  const formRef = useRef({});
  const formData = useRef({});

  const onClose = () => {
    setIsPopup(false);
    formRef.current.instance.resetValues();
  };

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { EmployeeName, EmployeeEnName, NickName, Password } = formData.current;
      const RoleCodeList = user.RoleCodeStr.split('|');
      await system
        .updateUser({
          EmployeeName,
          EmployeeEnName,
          NickName,
          Password,
          Email: user.Email,
          UserID: user.UserID,
          IsActive: user.IsActive,
          IsExternal: user.IsExternal === null ? 0 : user.IsExternal,
          OfficeMobilePhoneNumber: user.OfficeMobilePhoneNumber,
          RoleCodeList,
        })
        .then(res => {
          if (res.isOk) {
            notify(
              {
                message: '수정되었습니다.',
                width: 230,
                position: {
                  at: 'top',
                  my: 'top',
                  of: '#container',
                },
              },
              'success',
            );
            onClose();
            fetchUser();
          }
        });
    },
    [user],
  );

  const passwordComparison = () => {
    if (formData.current.Password) {
      return formData.current.Password;
    } else {
      return '';
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <Form
          ref={formRef}
          formData={formData.current}
          //   labelLocation="left"
          labelMode="outside"
          optionalMark="optional"
          stylingMode="outlined"
        >
          <ButtonItem>
            <ButtonOptions width={'3%'} icon="close" onClick={onClose}></ButtonOptions>
          </ButtonItem>
          <GroupItem caption={'내 정보 변경'}>
            {/* 현재 하이파킹만 이용하므로 차후 회사가 추가될 경우에 개발 */}
            <SimpleItem dataField="EmployeeName" editorType="dxTextBox" editorOptions={{ value: user.EmployeeName }}>
              <Label text="이름(한글)" />
            </SimpleItem>
            <SimpleItem
              dataField="EmployeeEnName"
              editorType="dxTextBox"
              editorOptions={{ value: user.EmployeeEnName }}
            >
              <Label text="이름(영문)" />
            </SimpleItem>
            <SimpleItem dataField="NickName" editorType="dxTextBox" editorOptions={{ value: user.NickName }}>
              <Label text="표기명" />
            </SimpleItem>
            <SimpleItem
              dataField="Password"
              editorType="dxTextBox"
              editorOptions={{
                //  placeholder: isUpdate ? '미 입력시 변경되지 않습니다.' : '',
                mode: 'password',
              }}
            >
              <Label text="비밀번호" />
            </SimpleItem>
            <SimpleItem
              dataField="PasswordConfirm"
              editorType="dxTextBox"
              editorOptions={{
                mode: 'password',
              }}
            >
              <Label text="비밀번호 확인" />
              <CompareRule message="비밀번호가 일치하지 않습니다." comparisonTarget={passwordComparison} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true} text="저장"></ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions width={'100%'} type={'normal'} text="취소" onClick={onClose}></ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </div>
    </form>
  );
};

export default User;
