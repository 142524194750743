import React, { useMemo, useState, useRef } from 'react';
import { Column, Pager, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import ScrollView from 'devextreme-react/scroll-view';
import { Popup } from 'devextreme-react/popup';
import moment from 'moment';
import { salesPayment } from 'api';
import DetailSalesInfo from './popup/detail-sales-info';
import CommonDataGrid from 'common/default-data-grid';

const ResultTaxInvoice = props => {
  const { inquiryDate, costCenterCode, isRefresh } = props;
  const [isPopup, setIsPopup] = useState(false);
  const detailSalesInfoRef = useRef({});
  const gridRef = useRef({});

  const dataSource = useMemo(() => {
    const customDataSource = new CustomStore({
      key: 'SalesID',
      loadMode: 'raw',
      load: async () => {
        const result = await salesPayment.getAccountingTaxInvoiceSummary({
          InquiryDate: inquiryDate,
          CostCenterCode: costCenterCode,
        });
        return result.data;
      },
    });

    return customDataSource;
  }, [inquiryDate, costCenterCode, isRefresh]);

  const customizeText = cellInfo => {
    return cellInfo.value === null ? '' : moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const detailCellRender = e => {
    const { AccountingNo } = e.data;
    return (
      <div className="Active" style={{ cursor: 'pointer' }}>
        <i className="dx-icon-search dx-icon-customicon" onClick={() => onClick(AccountingNo)}></i>
      </div>
    );
  };

  // 팝업 열기
  const onClick = AccountingNo => {
    setIsPopup(true);
    detailSalesInfoRef.current.open(AccountingNo, 'TaxInvoice');
  };

  const onClose = () => {
    setIsPopup(false);
  };

  return (
    <React.Fragment>
      <div style={{ padding: '17px 0' }}>
        <div className="dx-card">
          <CommonDataGrid
            gridRef={gridRef}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={700}
            isHeader={true}
            isExcel={true}
            isInfiniteScroll={false}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '세금계산서 처리결과.xlsx',
            }}
          >
            <Pager showPageSizeSelector={true} showInfo={true} visible={true} displayMode="full" />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column caption={'대사정보'}>
              <Column
                dataField={'key2'}
                caption={'상세'}
                alignment={'center'}
                width={50}
                cellRender={detailCellRender}
                allowFiltering={false}
                allowExporting={false}
              />
              <Column dataField={'SalesTypeName'} caption={'매출유형'} alignment={'center'} />
              <Column dataField={'DetailSalesTypeName'} caption={'상세매출유형'} alignment={'center'} />
              <Column dataField={'SalesCodeName'} caption={'대사-결제유형'} alignment={'center'} />
              <Column dataField={'TotlaPrice'} caption={'대사-총금액'} format="#,##0 원" alignment={'center'} />
              <Column dataField={'SalesRemark'} caption={'대사-비고'} alignment={'center'} />
              <Column dataField={'SalesRegisterUser'} caption={'작업자'} alignment={'center'} />
            </Column>
            <Column caption={'세금계산서 정보'}>
              <Column dataField={'SalesDate'} caption={'발행요청일'} width={100} />
              <Column dataField={'CustomerName'} caption={'사업자명'} width={120} />
              <Column dataField={'CustomerCorpRegNumber'} caption={'사업자번호'} width={100} />
              <Column dataField={'StatusName'} caption={'매출상태'} width={'100'} />
              <Column dataField={'SalesPrice'} caption={'금액'} width={120} format="#,##0 원" />
              <Column dataField={'TaxInvoiceStatusName'} caption={'처리상태'} width={100} />
              <Column dataField={'TaxInvoiceNo'} caption={'승인번호'} width={100} />
              <Column dataField={'TaxIssueTypeName'} caption={'대금유형'} width={80} />
              <Column dataField={'TaxTypeName'} caption={'과세유형'} width={80} />
              <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
              <Column dataField={'SalesCount'} caption={'품목 수'} width={80} />
              <Column dataField={'TaxPrice'} caption={'부가세'} width={80} format="#,##0 원" />
              <Column dataField={'UnitPrice'} caption={'공급가액'} width={80} format="#,##0 원" />
              <Column dataField={'CustomerCode'} caption={'회사코드'} width={120} />
              <Column dataField={'CustomerManagerName'} caption={'매입처 담당자명'} width={100} />
              <Column dataField={'CustomerEmail1'} caption={'이메일'} width={120} />
              <Column dataField={'CustomerEmail2'} caption={'이메일2'} width={120} />
              <Column dataField={'RegisterUser'} caption={'등록자'} width={80} />
              <Column dataField={'RegisterDate'} caption={'등록일'} width={120} />
              <Column dataField={'LastUpdateDate'} caption={'마지막수정일'} width={120} />
              <Column dataField={'InvoiceID'} caption={'세금계산서ID'} width={120} />
              <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
              <Column dataField={'SalesID'} caption={'대사ID'} alignment={'center'} />
            </Column>
          </CommonDataGrid>
        </div>
      </div>
      <Popup onHiding={onClose} visible={isPopup} showTitle={false} width="80%" height={737}>
        <ScrollView width="100%" height="100%">
          <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
            <DetailSalesInfo ref={detailSalesInfoRef} setIsPopup={setIsPopup} />
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

export default ResultTaxInvoice;
